import { useField } from "formik";
import React, { memo, useEffect, useRef, useState } from "react";
import { Platform, StyleProp, Text, View, ViewStyle } from "react-native";
import DropDownPicker, {
  DropDownDirectionType,
} from "react-native-dropdown-picker";
import { theme } from "../../../constants";
import ArrowFiveSvg from "../../../svg/ArrowFiveSvg";
import ArrowFourSvg from "../../../svg/ArrowFourSvg";
import CheckTwo from "../../../svg/CheckTwo";
import { ApText } from "../typography";
import OutsideClickHandler from "react-outside-click-handler";
interface IProps {
  dropDownDirection?: DropDownDirectionType;
  // defaultValue?: any;
  noBorder?: boolean;
  onChange?: any;
  name: string;
  label?: string;
  labelIcon?: React.ReactNode;
  placeholder?: string;
  labelTranslateKey?: string;
  items: { label: string; value: string }[];
  style?: StyleProp<ViewStyle>;
  labelStyle?: StyleProp<ViewStyle>;
  contPropsHeight?: number;
  useContPropsHeight?: boolean;
  dropDownContainerStyle?: StyleProp<ViewStyle>;
  containerStyle?: StyleProp<ViewStyle>;
}

export const ApDropdown: React.FC<IProps> = memo(
  ({
    dropDownDirection,
    contPropsHeight,
    dropDownContainerStyle,
    useContPropsHeight,
    style,
    containerStyle,
    // defaultValue,
    labelIcon,
    noBorder,
    onChange,
    name,
    label,
    items,
    labelStyle,
    placeholder,
    labelTranslateKey,
  }) => {
    const [opened, setOpened] = useState(false);
    const [field, meta, fHelper] = useField(name);

    const [value, setValue] = useState(field?.value || null);

    useEffect(() => {
      fHelper?.setValue(value);
    }, [value]);

    const viewRef = useRef<View>(null);

    useEffect(() => {
      if (Platform.OS !== "web") return;
      viewRef.current.setNativeProps({ ["word-break"]: "break-word" });
    }, [viewRef]);

    return (
      <OutsideClickHandler
        onOutsideClick={() => {
          setOpened(false);
        }}
      >
        <View style={{ zIndex: opened ? 999 : 1, elevation: opened ? 999 : 1 }}>
          <View
            ref={viewRef}
            style={[
              {
                zIndex: opened ? 999 : 1,
                flexDirection: "row",
                flexWrap: "wrap",
                flexShrink: 20,
                alignItems: "center",
                borderBottomWidth: noBorder ? 0 : 1,
                paddingTop: 10,
                paddingBottom: 10,
                justifyContent: "space-between",
                borderBottomColor: "#ced6e182",
              },
              containerStyle
            ]}
          >
            {label && (
              <View
                style={[
                  { flexDirection: "row", alignItems: "center", width: "auto" },
                  labelStyle,
                ]}
              >
                <ApText
                  translateKey={labelTranslateKey}
                  style={{
                    ...theme.FONTS.H6,
                    color: theme.COLORS.mainDark,
                  }}
                >
                  {label}
                </ApText>

                {labelIcon && (
                  <View style={{ marginLeft: 8 }}>{labelIcon}</View>
                )}
              </View>
            )}

            <View style={{ flexWrap: 'nowrap', flexDirection: 'column', width: "auto", zIndex: opened ? 999 : 1 }}>
              <DropDownPicker
                containerProps={{
                  style: { zIndex: 0 },
                }}
                dropDownDirection="BOTTOM"
                onSelectItem={(e) => {
                  if (onChange) onChange(e.value);
                }}
                style={[
                  {
                    zIndex: opened ? 999 : 1,
                    width: "100%",
                    minWidth: 100,
                    backgroundColor: "#FF8B7E",
                    borderWidth: 0,
                    minHeight: 25,
                    borderRadius: 6,
                  },
                  style,
                ]}
                open={opened}
                value={field?.value}
                arrowIconStyle={{
                  width: 11,
                  height: 11,
                  borderColor: "white",
                }}
                ArrowDownIconComponent={({ style }) => <ArrowFourSvg />}
                TickIconComponent={({ style }) => <CheckTwo />}
                items={items}
                setOpen={setOpened}
                setValue={setValue}
                textStyle={{
                  fontSize: 12,
                  color: "white",
                  textAlign: "center",
                }}
                ListEmptyComponent={() => (
                  <ApText
                    size="xs"
                    color="white"
                    style={{ padding: 5 }}
                    translateKey="DropdownEmptyMessage"
                  />
                )}
                placeholder={placeholder}
                ArrowUpIconComponent={({ style }) => <ArrowFiveSvg />}
                dropDownContainerStyle={[
                  {
                    paddingVertical: 5,
                    borderWidth: 0,
                    backgroundColor: "#FF8B7E",
                    zIndex: opened ? 999 : 1,
                  },
                  dropDownContainerStyle,
                ]}
                zIndex={3000}
                zIndexInverse={1000}
              />
            </View>
          </View>
          <>
            {meta.touched && meta.error && (
              <Text
                style={{
                  fontSize: 14,
                  color: "red",
                  marginBottom: 14,
                  marginTop: 14,
                }}
              >
                {meta.error}
              </Text>
            )}
          </>
        </View>
      </OutsideClickHandler>
    );
  }
);
