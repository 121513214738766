import React, { useEffect, useState } from "react";
import { ScrollView, View } from "react-native";
import { RefreshControl } from "react-native-web-refresh-control";
import { components } from "../components";
import { ApEmptyState, ApLoader, ApSafeAreaView } from "../components/v1";
import { ApText } from "../components/v1/typography";
import { usePackageState } from "../context";
const PackageRoi: React.FC = ({ navigation, route }: any) => {
  const { getPackageRoi, loading, packageRoi } = usePackageState();
  const [refreshing, setRefreshing] = useState(false);
  const id = route.params.mp_id;

  useEffect(() => {
    getPackageRoi(id);
  }, []);

  const handleRefresh = () => {
    setRefreshing(true);
    getPackageRoi(id).finally(() => {
      setRefreshing(false);
    });
  };

  const renderHeader = () => {
    return (
      <components.Header
        title="Package Roi"
        goBack={true}
        titleTranlateKey="ReleaseDetails"
      />
    );
  };

  const renderContent = () => {
    return (
      <ScrollView
        contentContainerStyle={{ flexGrow: 1, paddingHorizontal: 20 }}
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={handleRefresh} />
        }
      >
        {/* <View
          style={{
            backgroundColor: "#FFD9CF",
            padding: 12,
            borderRadius: 10,
            marginTop: 15,
            marginBottom: 5,
            flexDirection: "row",
          }}
        >
          <ApText translateKey="TradePageNote" style={{ color: "#F37021", width: "80%", fontSize: 13 }}>
            It is a long established fact that a reader will be distracted by
            the readable content of a page when looking at its layout.
          </ApText>
          <Image
            source={require("../assets/icons/account-hint.png")}
            style={{ width: 86, height: 57 }}
          />
        </View> */}
        <View style={{ padding: 0, marginTop: 25 }}></View>

        {loading && <ApLoader />}
        {!loading && (
          <>
            {" "}
            {packageRoi.length > 0 ? (
              packageRoi?.map((pkg, i) => (
                <View
                  key={i}
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    backgroundColor: "#fff5f0ba",
                    borderRadius: 10,
                    padding: 15,
                    marginTop: i === 0 ? 10 : 0,
                    marginBottom: 10,
                  }}
                >
                  <View>
                    <ApText style={{ color: "#222", marginBottom: 10 }}>
                      {pkg.payout_date}
                    </ApText>
                    <View style={{ flexDirection: "row" }}>
                      <ApText style={{ color: "#222" }} translateKey="Round" />
                      <ApText style={{ color: "#222", marginLeft: 5 }}>
                        #{pkg.release_count}
                      </ApText>
                    </View>
                  </View>

                  <View>
                    <ApText style={{ color: "#F37021" }}>
                      {pkg.amount} {pkg.wallet_type}
                    </ApText>
                  </View>
                </View>
              ))
            ) : (
              <ApEmptyState />
            )}
          </>
        )}
      </ScrollView>
    );
  };

  return (
    <ApSafeAreaView>
      {renderHeader()}
      {renderContent()}
    </ApSafeAreaView>
  );
};

export default PackageRoi;
