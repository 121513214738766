import {
  Mulish_300Light,
  Mulish_400Regular,
  Mulish_500Medium,
  Mulish_600SemiBold,
  Mulish_700Bold,
} from "@expo-google-fonts/mulish";
import { NavigationContainer } from "@react-navigation/native";
import { useFonts } from "expo-font";
import { SafeAreaProvider } from "react-native-safe-area-context";
import Toast, { BaseToast, ErrorToast } from "react-native-toast-message";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import StackNavigator from "./src/navigation/StackNavigator";
import { persistor, store } from "./src/store/store";

import AsyncStorage from "@react-native-async-storage/async-storage";
import * as Linking from "expo-linking";
import { changeLanguage } from "i18next";
import React, { useEffect, useState } from "react";
import "./i18n";
import { ApLoader } from "./src/components/v1";
import { AppContextProvider, useConfigState } from "./src/context";

const url = Linking.createURL("/");

const linking = {
  prefixes: [url, "https://app.techbasemlm.com/"],
  config: {
    screens: {
      SignUp: {
        path: "registration",
      },
    },
  },
};

const toastConfig = {

  success: (props: any) => (
    <BaseToast
      {...props}
      text2NumberOfLines={2000}
      style={{
        borderLeftColor: "pink",
        backgroundColor: "green",
        height: "auto",
        paddingTop: 5,
        paddingBottom: 5,
      }}
      contentContainerStyle={{ paddingHorizontal: 15, height: "auto" }}
      text1Style={{
        fontSize: 17,
        color: "white",
      }}
      text2Style={{
        fontSize: 15,
        color: "white",
      }}
    />
  ),
  /*
    Overwrite 'error' type,
    by modifying the existing `ErrorToast` component
  */
  error: (props: any) => (
    <ErrorToast
      {...props}
      text2NumberOfLines={2000}
      style={{
        backgroundColor: "red",
        height: "auto",
        paddingTop: 5,
        paddingBottom: 5,
      }}
      contentContainerStyle={{ paddingHorizontal: 15, height: "auto" }}
      text1Style={{
        fontSize: 17,
        color: "white",
      }}
      text2Style={{
        fontSize: 15,
        color: "white",
      }}
    />
  ),
};

const ApMain = () => {
  const { getConfig } = useConfigState();

  useEffect(() => {
    // getConfig();
  }, []);

  return <StackNavigator />;
};

const App: React.FC = () => {
  const [loading, setLoading] = useState(false);
  let [fontsLoaded] = useFonts({
    Mulish_300Light,
    Mulish_400Regular,
    Mulish_500Medium,
    Mulish_600SemiBold,
    Mulish_700Bold,
  });

  setTimeout(() => {
    setLoading(true);
  }, 4500);

  useEffect(() => {
    (async () => {
      var language = await AsyncStorage.getItem("language");
      if (language) {
        changeLanguage(language);
      }
    })();
  }, []);

  if (fontsLoaded) {
    return (
      <>
        <NavigationContainer linking={linking}>
          <SafeAreaProvider>
            <AppContextProvider>
              <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                  <ApMain />
                </PersistGate>
              </Provider>
            </AppContextProvider>
          </SafeAreaProvider>
        </NavigationContainer>
        <Toast config={toastConfig} topOffset={20} />
      </>
    );
  } else {
    return <ApLoader />;
  }
};

export default App;
