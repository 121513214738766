import React, {useEffect} from "react";
import {View} from "react-native";
import {components} from "../components";
import {
  ApEmptyState,
  ApLoader,
  ApSafeAreaView,
} from "../components/v1";
import {useTransactionState} from "../context";
import {useTradingContext, useConfigState} from "../context";
import { useCalcPnl } from "../hooks/master";
import { BotExecutor } from "../screens/Trade/TradingBots/components";


const TradingBotHistory: React.FC = ({route}: any) => {
  const market = route.params.market;
  const { loading} = useTransactionState();
  const {getBotExecutors, botExecutors} = useTradingContext();
  const { calcPNLAmount, calcPNLPerc } = useCalcPnl();
  const { priceList, getPriceList } = useConfigState();

  useEffect(() => {
    getPriceList();
    getBotExecutors(market, true);
  }, []);

  const renderHeader = () => {
    return (
      <components.Header
        title={"TradingBotHistory"}
        titleTranlateKey="TradingBotHistory"
        goBack={true}
      />
    );
  };

  const renderContent = () => {
    return (
      <View style={{flex: 1, margin:15}}>
        <View>
          <View>
            {botExecutors.filter((item) => item.market === market).length === 0 ? (
              <ApEmptyState />
            ) : (
              <>
              {botExecutors.filter((item) =>
                      item.market === market && item.status === "2"
                    )
                    .map((e, i) => (
                      <BotExecutor
                        key={`ss_${i}`}
                        calculatePNLPerc={calcPNLPerc}
                        calculatePNLAmount={calcPNLAmount}
                        market={market}
                        priceList={priceList}
                        e={e}
                      />
                    ))}
                    </>
            )}
          </View>
        </View>
      </View>
    );
  };

  return (
    <ApSafeAreaView>
      {renderHeader()}
      {loading && (
        <View style={{paddingTop: 10}}>
          <ApLoader />
        </View>
      )}
      {!loading && renderContent()}
    </ApSafeAreaView>
  );
};

export default TradingBotHistory;
