import React from "react";
import ContentLoader from "react-content-loader";
import { Rect } from "react-native-svg";

export const ApTradeLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={476}
      height={600}
      viewBox="0 0 476 600"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <Rect x="6" y="35" rx="3" ry="3" width="338" height="75" />
      <Rect x="5" y="120" rx="6" ry="6" width="338" height="74" />
      <Rect x="6" y="202" rx="6" ry="6" width="338" height="75" />
      <Rect x="5" y="285" rx="6" ry="6" width="338" height="75" />
      <Rect x="6" y="368" rx="6" ry="6" width="338" height="75" />
      <Rect x="5" y="453" rx="6" ry="6" width="338" height="75" />
    </ContentLoader>
  );
};
