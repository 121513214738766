import { useNavigation } from "@react-navigation/native";
import React, { Dispatch, SetStateAction, useEffect } from "react";
import { Modal, View } from "react-native";
import { ApSafeAreaView } from "./v1";

interface IProps {
  children: React.ReactNode;
  visible: boolean;
  onDismiss?: Dispatch<SetStateAction<boolean>>;
  transparent?: boolean;
  fallbackRoute?: string;
}

export const ApModalAlt: React.FC<IProps> = ({
  children,
  visible,
  onDismiss,
  transparent,
  fallbackRoute,
}) => {
  const navigation = useNavigation();

  useEffect(() => {
    const backAction = (e) => {
      e.preventDefault();
      if (visible) {
        if (fallbackRoute) navigation.navigate(fallbackRoute as never);
        // onDismiss(false);
      }
    };
    window.onpopstate = backAction;
    return () => {
      window.onpopstate = null;
    }; // Cleanup
  }, []);

  return (
    <Modal
      style={{ flex: 1 }}
      presentationStyle="fullScreen"
      animationType="slide"
      transparent={transparent}
      visible={visible}
      onRequestClose={() => {
        onDismiss && onDismiss(false);
      }}
    >
      <ApSafeAreaView>
        {transparent ? (
          <>
            <View
              style={{
                paddingHorizontal: 15,
                position: "relative",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <View
                style={{
                  opacity: 0.5,
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  backgroundColor: "black",
                }}
              ></View>

              <View
                style={{
                  width: "100%",
                  borderRadius: 10,
                  backgroundColor: "#fff",
                }}
              >
                {children}
              </View>
            </View>
          </>
        ) : (
          <>{children}</>
        )}
      </ApSafeAreaView>
    </Modal>
  );
};
