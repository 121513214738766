import { View, Text, TouchableOpacity, Image, FlatList } from "react-native";
import React from "react";

import TeofinShadow from "../components/TeofinShadow";
import { theme } from "../constants";
import { BASE_URL } from "../constants/master";

type Props = {
  amount: string;
  transactionType: string;
  paymentType: string;
  onPress?: () => void;
  containerStyle?: any;
  deposit?: boolean;
  type?: string;
  latestPrice?: string;
};

const Transaction: React.FC<Props> = ({
  amount,
  transactionType,
  paymentType,
  onPress,
  containerStyle,
  deposit,
  type,
  latestPrice,
}) => {
  return (
    <TouchableOpacity
      style={{
        ...containerStyle,
        height: 40,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        borderRadius: 10,
      }}
    >
      <View style={{ flexDirection: "row", width: "35%" }}>
        <Image
          source={{ uri: `${BASE_URL}/img/symbols/` + type + ".png" }}
          style={{ width: 25, height: 25, marginRight: 5 }}
          defaultSource={require("../../assets/NoImage.png")}
        />

        <Text
          style={{
            ...theme.FONTS.H6,
            color: "theme.COLORS.mainDark",
            fontWeight: "bold",
            alignSelf: "center",
          }}
        >
          {paymentType.split("USDT")[0].toUpperCase()}
        </Text>
        <Text style={{ alignSelf: "center", fontSize: 13, color: "#a0a0a0" }}>
          /USDT
        </Text>
        {/* <Text
          style={{
            ...theme.FONTS.H6,
            color: "#bcbcbc",
            fontWeight: "500",
            alignSelf: "center",
          }}
        >
          /{transactionType}
        </Text> */}
      </View>
      <View style={{ width: "35%" }}>
        <Text
          style={{
            textAlign: "right",
            fontWeight: "500",
            alignSelf: "right",
          }}
        >
          {parseFloat(latestPrice)}
        </Text>
      </View>
      {/* <View style={{flex:1}}>
                <Text style={{textAlign:"right",...theme.FONTS.H6,}}>123</Text>
            </View> */}
      <View style={{ width: "30%", borderRadius: 10, alignItems: "flex-end" }}>
        <View
          style={{
            ...theme.FONTS.H6,
            backgroundColor: deposit ? "#FA5252" : "#12B886",
            borderRadius: 4,
            paddingVertical: 6,
            width: "80%",
            marginRight: 0,
          }}
        >
          <Text style={{ color: "#fff", textAlign: "center", fontSize: 13 }}>
            {parseFloat(amount) > 0
              ? "+" + parseFloat(amount).toFixed(2) + "%"
              : parseFloat(amount).toFixed(2) + "%"}
          </Text>
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default Transaction;
