import React, { useEffect } from "react";
import { ScrollView, View } from "react-native";

import AsyncStorage from "@react-native-async-storage/async-storage";
import { useTranslation } from "react-i18next";
import { components } from "../components";
import { ApSafeAreaView } from "../components/v1";
import { svg } from "../svg";
import ApiCall from "../utils/ApiCall";

const AccountSetting: React.FC = ({ navigation }: any) => {
  const { i18n, t } = useTranslation();

  useEffect(() => {
    ApiCall.post("/profile/me").then((response) => {});
  }, []);

  const signout = async () => {
    await AsyncStorage.clear();
    navigation.navigate("SignIn");
  }

  const renderHeader = () => {
    return <components.Header title={t("AccountSetting")} goBack={true} />;
  };

  const renderProfileCategory = () => {
    return (
      <View style={{ marginTop: 20, marginHorizontal: 20, borderRadius: 10, paddingHorizontal: 20, paddingVertical: 20, backgroundColor: "#FCF8F6" }}>
        <View style={{marginBottom: 10}}>
          <components.ProfileCategory
            borderRadius={10}
            title={t("AllowedDeduction")}
            rightElement={<svg.ArrowOneSvg />}
            onPress={() => navigation.navigate("AllowedDeduction")}
          />
        </View>

        <View style={{marginBottom: 10}}>
          <components.ProfileCategory
            borderRadius={10}
            title={t("CreditAlert")}
            rightElement={<svg.ArrowOneSvg />}
            onPress={() => navigation.navigate("CreditAlert")}
          />
        </View>

        <View style={{marginBottom: 10}}>
          <components.ProfileCategory
            borderRadius={10}
            title={t("ChangePassword")}
            rightElement={<svg.ArrowOneSvg />}
            onPress={() => navigation.navigate("ChangePassword")}
          />
        </View>

        <View style={{marginBottom: 10}}>
          <components.ProfileCategory
            borderRadius={10}
            title={t("ChangeSecurityPin")}
            rightElement={<svg.ArrowOneSvg />}
            onPress={() => navigation.navigate("ChangeSecurityPin")}
          />
        </View>

        <components.ProfileCategory borderRadius={10} title={t("ContactUs")} rightElement={<svg.ArrowOneSvg />} />
        {/* <components.ProfileCategory
          onPress={async () => {
            await AsyncStorage.clear();
            navigation.navigate("SignIn");
          }}
          icon={<svg.LogOutSvg />}
        /> */}
        {/* <ApButton
          label="Sign Out"
          round="xl"
          type="primary"
          onPress={signout}
        /> */}
      </View>
    );
  };

  const renderContent = () => {
    return <ScrollView contentContainerStyle={{ marginBottom: 70, flexGrow: 1 }}>{renderProfileCategory()}</ScrollView>;
  };

  return (
    <ApSafeAreaView style={{ flex: 1, backgroundColor: "theme.COLORS.bgColor "}}>
      {renderHeader()}
      {renderContent()}
    </ApSafeAreaView>
  );
};

export default AccountSetting;
