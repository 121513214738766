import AsyncStorage from "@react-native-async-storage/async-storage";
import React, { useEffect, useState } from "react";
import { StyleProp, Text, View, ViewStyle } from "react-native";
import DropDownPicker, { DropDownDirectionType } from "react-native-dropdown-picker";
import { theme } from "../../../constants";
import ArrowFiveSvg from "../../../svg/ArrowFiveSvg";
import ArrowFourSvg from "../../../svg/ArrowFourSvg";
import CheckTwo from "../../../svg/CheckTwo";
import { ApText } from "../typography";
import OutsideClickHandler from "react-outside-click-handler";
import { LANGUAGES, LANGUAGES_TYPES } from "../../../constants/master";
import { useTranslation } from "react-i18next";
import { useIsFocused } from "@react-navigation/native";

interface IProps {
  defaultLang?: any
  setLang?: any
}

export const ApLangDropdown: React.FC<IProps> = ({
  setLang, defaultLang
}) => {
  const [opened, setOpened] = useState(false);
  const [value, setValue] = useState<any>();
  const { i18n, t } = useTranslation();
  const isFocused = useIsFocused();

  const languageSwitch = (item: any) => {  
    i18n.changeLanguage(item);
    AsyncStorage.setItem("language", item);
    defaultLang && setValue(item)
  };

  useEffect(() => {
    defaultLang && languageSwitch(LANGUAGES.filter(item => item.languageCode === defaultLang)[0].id)
  },[])

  useEffect(() => {
    AsyncStorage.getItem("language").then(rs => {
      if(rs === null)
      {
        setValue(LANGUAGES_TYPES[0].value)
      }
      else{ setValue(rs) }
    })
  },[value, isFocused])

  return (
    <View>
      <DropDownPicker
          dropDownDirection="BOTTOM"
          onChangeValue={(e: any) => languageSwitch(e)}
          style={{
              zIndex: opened ? 999 : 1,
              width: "100%",
              minWidth: 100,
              backgroundColor: "#FF8B7E",
              borderWidth: 0,
              minHeight: 25,
              borderRadius: 6,
          }}
          open={opened}
          value={value}
          arrowIconStyle={{
          width: 11,
          height: 11,
          borderColor: "white",
          }}
          ArrowDownIconComponent={({ style }) => <ArrowFourSvg />}
          TickIconComponent={({ style }) => <CheckTwo />}
          items={LANGUAGES_TYPES}
          setOpen={setOpened}
          setValue={setValue}
          textStyle={{
          fontSize: 12,
          color: "white",
          textAlign: "center",
          }}
          ArrowUpIconComponent={({ style }) => <ArrowFiveSvg />}
          dropDownContainerStyle=
          {{
              paddingVertical: 5,
              borderWidth: 0,
              backgroundColor: "#FF8B7E",
              zIndex: opened ? 999 : 1,
          }}
          zIndex={3000}
          zIndexInverse={1000}
      />
    </View>
  );
};
