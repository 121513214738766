import React from "react";
import ContentLoader, { Rect, Circle, Path } from "react-content-loader/native";

export const ApAssetLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={476}
      height={600}
      viewBox="0 0 476 600"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <Rect x="14" y="235" rx="0" ry="0" width="340" height="335" />
      <Rect x="19" y="47" rx="0" ry="0" width="84" height="3" />
      <Rect x="136" y="47" rx="0" ry="0" width="84" height="3" />
      <Rect x="250" y="48" rx="0" ry="0" width="84" height="3" />
      <Rect x="15" y="163" rx="0" ry="0" width="340" height="61" />
      <Rect x="16" y="70" rx="0" ry="0" width="340" height="84" />
    </ContentLoader>
  );
};
