import * as Clipboard from "expo-clipboard";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  TouchableOpacity,
  View
} from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { components } from "../components";
import { ApEmptyState, ApEmptyStateSvg, ApLoader, ApSafeAreaView } from "../components/v1";
import { ApText } from "../components/v1/typography";
import { useProfileState, useTransactionState } from "../context";
import { IWithdrawHistory } from "../models/transaction.interface";
import { svg } from "../svg";

const WithdrawHistory: React.FC = ({ navigation, route }: any) => {
  const wallet_type = route.params?.wallet_type && route.params?.wallet_type;
  const { profileData, getProfileData } = useProfileState();
  const { withdrawHistory, getWithdrawHistory, loading, selectedWallet } =
    useTransactionState();
  const [sortWithdrawHistory, setSortWithdrawHistory] = useState([]);
  const { i18n, t } = useTranslation();
  const [selected, setSelected] = useState("CreditBalance");
  //const [profileData, setProfileData] = useState({});
  const dispatch = useDispatch();
  const hideProfit = useSelector(
    (state: any) => state.hideProfitSlice.hideProfit
  );
  const [sort, setSort] = useState<Record<string, IWithdrawHistory[]>>({});
  

  useEffect(() => {
    getWithdrawHistory().then((res: any[]) => {      
      // wallet_type ? 
      setSort(sortWithdrawHistoryByDate(
        res.filter(item => item.wallet_type === selectedWallet) as unknown as IWithdrawHistory[]
      ))
      // : setSort(sortWithdrawHistoryByDate(res as unknown as IWithdrawHistory[]));
    });
  }, []);

  const sortWithdrawHistoryByDate = (
    dataObjects: IWithdrawHistory[]
  ): Record<string, IWithdrawHistory[]> => {
    const sortedDataObjects: Record<string, IWithdrawHistory[]> = {};

    dataObjects.forEach((dataObject) => {
      dataObject.date = new Date(dataObject.created_at);
      // Get the year, month, and day of the current data object's date property
      const year = dataObject.date.getFullYear().toString();
      const month = (dataObject.date.getMonth() + 1)
        .toString()
        .padStart(2, "0");
      const day = dataObject.date.getDate().toString().padStart(2, "0");
      const dateKey = `${year}-${month}-${day}`;

      // If the sortedDataObjects object doesn't have an array for this date, create one
      if (!sortedDataObjects[dateKey]) {
        sortedDataObjects[dateKey] = [];
      }

      // Add the current data object to the array for this date
      sortedDataObjects[dateKey].push(dataObject);
    });

    return sortedDataObjects;
  };

  const status = (isApproved: string, isRejected: string) => {
    if (isApproved === "1" && isRejected === "0") {
      return (
        <ApText
          style={{
            color: "#06A94D",
            fontSize: 12,
            lineHeight: 16,
            paddingTop: 2,
          }}
        >
          Approved
        </ApText>
      );
    } else if (isRejected === "1" && isApproved === "0") {
      return (
        <ApText
          style={{
            color: "#FF2400",
            fontSize: 12,
            lineHeight: 16,
            paddingTop: 2,
          }}
        >
          Rejected
        </ApText>
      );
    } else {
      return (
        <ApText
          style={{
            color: "#FFAA00",
            fontSize: 12,
            lineHeight: 16,
            paddingTop: 2,
          }}
        >
          Pending
        </ApText>
      );
    }
  };

  useEffect(() => {
    const dates = Object.keys(sort);
    setSortWithdrawHistory(dates as any);
  }, [sort]);

  const renderHeader = () => {
    return <components.Header title={"Withdraw History"} titleTranlateKey="WithdrawHistory" goBack={true} screenName="MyAssets"/>;
  };

  const renderContent = () => {
    return (
      <View style={{ flex: 1 }}>
        {sortWithdrawHistory.length > 0 ? (
          sortWithdrawHistory.map((date) => (
            <View key={date}>
              <ApText
                style={{
                  color: "#F37021",
                  fontSize: 17,
                  padding: 15,
                  fontWeight: "500",
                  paddingTop: 25,
                }}
              >
                {date}
              </ApText>
              <View
                style={{
                  paddingHorizontal: 15,
                  backgroundColor: "#FCF8F6",
                  margin: 10,
                  borderRadius: 10,
                  marginTop: 0,
                }}
              >
                {sort[date].map((e, index) => (
                  <View key={e.id}>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        paddingVertical: 15,
                        borderBottomWidth: index + 1 === sort[date].length ? 0 : 1,
                        borderColor: "#e4e4e499",
                      }}
                    >
                      <View style={{ width: "40%" }}>
                        <ApText style={{ fontWeight: "500" }}>{e.created_at.split(" ")[1]}</ApText>
                        <ApText
                          style={{
                            color: "#ababab",
                            fontSize: 12,
                            lineHeight: 16,
                            paddingTop: 4,
                          }}
                        >
                          Actual Amount:
                        </ApText>

                        {/* <ApText
                          style={{
                            color: "#ababab",
                            fontSize: 12,
                            lineHeight: 16,
                            paddingTop: 2,
                          }}
                        >
                          After Fee:
                        </ApText> */}
                        <ApText
                          style={{
                            color: "#ababab",
                            fontSize: 12,
                            lineHeight: 16,
                            paddingTop: 3,
                          }}
                        >
                          Receiving Address:
                        </ApText>
                        {
                          e?.tx_hash !== "" ? 
                          <ApText
                            style={{
                              color: "#ababab",
                              fontSize: 12,
                              lineHeight: 16,
                              paddingTop: 3,
                            }}
                          >
                            Transaction Hash:
                          </ApText> : ""
                        }

                        <ApText
                          style={{
                            color: "#ababab",
                            fontSize: 12,
                            lineHeight: 16,
                            paddingTop: 2,
                          }}
                        >
                         Status:
                        </ApText>

                        {
                          e?.remark !== "" ?
                          <ApText
                            style={{
                              color: "#ababab",
                              fontSize: 12,
                              lineHeight: 16,
                              paddingTop: 2,
                            }}
                          >
                            Remark:
                          </ApText> : ""
                        }
                      </View>
                      <View style={{ width: "55%", alignItems: "flex-end" }}>
                        <ApText
                          style={{
                            fontWeight: "500",
                            color: "#222",
                            textAlign: "right",
                          }}
                        >
                          {e.amount} {selectedWallet === "ddb_wallet"
                            ? "DDC" : "USDT"
                          } 
                        </ApText>

                        <ApText
                          numberOfLines={1}
                          style={{
                            fontWeight: "500",
                            color: "#222",
                            lineHeight: 16,
                            paddingTop: 2,
                          }}
                        >
                          {e.withdrawal_fee} 
                          {selectedWallet === "ddb_wallet"
                            ? " DDC" : " USDT"
                          } 
                        </ApText>

{/* 
                        <ApText
                          numberOfLines={1}
                          style={{
                            color: "#ababab",
                            fontSize: 12,
                            lineHeight: 16,
                            paddingTop: 2,
                          }}
                        >
                          {parseFloat(e.amount) - parseFloat(e.withdrawal_fee)} 
                          {selectedWallet === "ddb_wallet"
                            ? " DDC" : " USDT"
                          } 
                        </ApText> */}

                        <ApText
                          numberOfLines={1}
                          style={{
                            color: "#ababab",
                            fontSize: 12,
                            lineHeight: 16,
                            paddingTop: 2
                          }}
                        >
                            {e.usdt_address.slice(0, 4)}.....
                            {e.usdt_address.slice(-4)}
                          <TouchableOpacity
                            onPress={() =>
                              Clipboard.setStringAsync(e.usdt_address)
                            }
                            style={{ paddingLeft: 5, }}
                          >
                            <svg.CopySvg />
                          </TouchableOpacity>
                        </ApText>

                        {
                          e?.tx_hash !== "" ? 
                          <ApText
                          numberOfLines={1}
                          style={{
                            color: "#ababab",
                            fontSize: 12,
                            lineHeight: 16,
                            paddingTop: 2,
                          }}
                          >
                            {
                              e.tx_hash.slice(0, 4) + "....." +
                              e.tx_hash.slice(-4)
                            }

                            <TouchableOpacity
                              onPress={() =>
                                Clipboard.setStringAsync(e.tx_hash)
                              }
                              style={{ paddingLeft: 5 }}
                            >
                              <svg.CopySvg />
                            </TouchableOpacity>
                          </ApText> : ""
                        }

                        {status(e.is_approved, e.is_rejected)}

                        {
                          e?.remark !== "" ?
                          <ApText
                            numberOfLines={1}
                            style={{
                              color: "#ababab",
                              fontSize: 12,
                              lineHeight: 16,
                              paddingTop: 2,
                            }}
                          >
                            {e.remark}
                          </ApText> : ""
                        }
                      </View>
                    </View>
                  </View>
                ))}
              </View>
            </View>
          ))
        ) : (
          <ApEmptyState
            svg={<ApEmptyStateSvg.List />}
            heading="No Result Found"
            message="Withdraw History is empty"
          />
        )}
      </View>
    );
  };

  return (
    <ApSafeAreaView>
      {renderHeader()}
      {loading && <View style={{paddingTop: 10}}><ApLoader/></View>}
      {!loading && renderContent()}
    </ApSafeAreaView>
  );
};

export default WithdrawHistory;
