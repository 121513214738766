import * as React from "react";
import Svg, { G, Path } from "react-native-svg";

function PurchaseSvg(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width={21.551} height={21.116} viewBox="0 0 21.551 21.116" {...props}>
      <G strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5}>
        <Path data-name="Path 76" d="M7.783 21.102a.824.824 0 11-.823.824.825.825 0 01.823-.824z" fill="rgba(0,0,0,0)" stroke="#7a8390" fillRule="evenodd" transform="translate(-1.883 -2.383)" />
        <Path data-name="Path 77" d="M19.907 21.102a.824.824 0 11-.824.824.825.825 0 01.824-.824z" fill="rgba(0,0,0,0)" stroke="#7a8390" fillRule="evenodd" transform="translate(-1.883 -2.383)" />
        <Path data-name="Path 78" d="M2.75 3.25l2.241.388L6.028 16a1.942 1.942 0 001.936 1.781H19.72a1.944 1.944 0 001.923-1.666l1.022-7.065a1.444 1.444 0 00-1.429-1.65H5.351" fill="none" stroke="#7a8390" transform="translate(-1.883 -2.383)" />
        <Path data-name="Path 79" d="M10.291 11.379h7.7" fill="none" stroke="#F37021" transform="translate(-1.883 -2.383)" />
      </G>
    </Svg>
  );
}

export default PurchaseSvg;
