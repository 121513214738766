import { useEffect, useState } from "react";

var ENV_TYPES: "browser" | "native";

export const useCheckEnv = () => {
  const [env, setEnv] = useState<typeof ENV_TYPES>("browser");

  useEffect(() => {
    if (typeof window !== "undefined") {
      // running in a browser environment
      setEnv("browser");
    } else {
      // running in a React Native environment
      setEnv("native");
    }
  }, []);

  return {
    isBrowser: env === "browser",
    isNative: env === "native",
  };
};
