import * as React from "react";
import Svg, { Path, Defs } from "react-native-svg";

type Props = {
    color?: string;
};

const WalletSvg: React.FC<Props> = ({ color = "#4C4C60" }) => (
    <Svg width={24} height={24} fill="none">
        <Path
            d="M20.453 5.891 17.393.592a1.177 1.177 0 0 0-.72-.551 1.177 1.177 0 0 0-.9.117L5.846 5.891H3.579a2.504 2.504 0 0 0-2.5 2.501v10.95a2.504 2.504 0 0 0 2.5 2.502h.68a5.149 5.149 0 0 0 8.378 0h7.784a2.504 2.504 0 0 0 2.5-2.502V8.392a2.504 2.504 0 0 0-2.468-2.5Zm-9.001 0 6.1-3.523.528.914-4.52 2.609h-2.108Zm7.003-1.96 1.131 1.96h-4.525l3.394-1.96ZM16.149.808a.433.433 0 0 1 .33-.043c.113.03.207.102.265.202l.433.752L9.952 5.89H7.345L16.149.808ZM8.448 23.25a4.407 4.407 0 0 1-4.402-4.402 4.407 4.407 0 0 1 4.402-4.402 4.407 4.407 0 0 1 4.402 4.402 4.407 4.407 0 0 1-4.402 4.402Zm11.973-2.156h-7.337c.33-.68.516-1.441.516-2.246a5.158 5.158 0 0 0-5.152-5.152 5.158 5.158 0 0 0-5.152 5.152c0 .805.185 1.567.516 2.246h-.233a1.753 1.753 0 0 1-1.75-1.752V8.392c0-.966.785-1.751 1.75-1.751H20.42c.966 0 1.752.785 1.752 1.751v2.724h-5.818a2.754 2.754 0 0 0-2.752 2.751 2.754 2.754 0 0 0 2.752 2.751h5.818v2.724c0 .966-.786 1.752-1.752 1.752Zm1.75-9.228v4.002h-5.817a2.004 2.004 0 0 1-2.002-2c0-1.104.898-2.002 2.002-2.002h5.818Zm-6.977 2.001a1.161 1.161 0 1 0 1.16-1.16c-.64 0-1.16.52-1.16 1.16Zm1.57 0a.41.41 0 1 1-.82 0 .41.41 0 0 1 .82 0ZM9.96 19.38a1.28 1.28 0 0 0-.904 2.182c.242.241.563.374.904.374.342 0 .663-.133.904-.375a1.27 1.27 0 0 0 .374-.904 1.27 1.27 0 0 0-.375-.903 1.27 1.27 0 0 0-.903-.374Zm.373 1.651a.525.525 0 0 1-.902-.373c0-.291.237-.528.529-.528a.525.525 0 0 1 .528.528.525.525 0 0 1-.154.373Zm-3.397-2.714a1.28 1.28 0 0 0 .903-2.183 1.28 1.28 0 0 0-2.182.905 1.28 1.28 0 0 0 1.279 1.278Zm-.529-1.279c0-.29.237-.528.528-.528a.525.525 0 0 1 .529.528.529.529 0 0 1-1.057 0Zm4.065-.345-3.385 4.715a.375.375 0 1 1-.609-.438l3.385-4.715a.375.375 0 1 1 .61.438Z"
            fill={color}
        />
        <Defs>
            <Path fill="#fff" d="M0 0h24v24H0z" />
        </Defs>
    </Svg>
);

export default WalletSvg;
