import * as React from "react";
import Svg, { Path } from "react-native-svg";

function ArrowSixSvg(props) {
  return (
    <Svg fill="#F37021" width="20px" height="20px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path d="M759.2 419.8L697.4 358 512 543.4 326.6 358l-61.8 61.8L512 667z" />
    </Svg>
  );
}

export default ArrowSixSvg;
