import { useNavigation } from "@react-navigation/native";
import { LinearGradient } from "expo-linear-gradient";
import React, { useState } from "react";
import {
  Image,
  ScrollView,
  TextInput,
  TouchableOpacity,
  View
} from "react-native";

import { components } from "../components";
import { ApText } from "../components/v1/typography";
import { theme } from "../constants";
import { svg } from "../svg";
import { ApSafeAreaView } from "../components/v1";

const cards = [
  {
    id: "1",
    title: "Visa",
    number: "**** **** **** 7895",
    amount: "4 863.27",
    icon: ""//require("../../assets/cards/06.png"),
  },
  {
    id: "2",
    title: "Visa",
    number: "**** **** **** 3964",
    amount: "4 863.27",
    icon: ""//require("../../assets/cards/06.png"),
  },
  {
    id: "3",
    title: "Visa",
    number: "**** **** **** 1045",
    amount: "4 863.27",
    icon: ""//require("../../assets/cards/06.png"),
  },
];
const renderHeader = () => {
  return (
    <components.Header
      title="Bot Strategy"
      titleTranlateKey="BotStrategy"
      goBack={true}
    />
  );
};
const depositPeriods = [
  {
    id: "1",
    title: "3 month",
  },
  {
    id: "2",
    title: "6 month",
  },
  {
    id: "3",
    title: "12 month",
  },
  {
    id: "4",
    title: "18 month",
  },
  {
    id: "5",
    title: "24 month",
  },
  {
    id: "6",
    title: "36 month",
  },
];

const Deposits: React.FC = () => {
  const [deposits, setDeposits] = useState(true);
  const [selected, setSelected] = useState("Future");
  const [openDeposit, setOpenDeposit] = useState(false);
  const [openMoneyBox, setOpenMoneyBox] = useState(false);
  const [masterOrVisa, setMasterOrVisa] = useState("Visa");
  const [toggle, setToggle] = useState(true);
  const [currency, setCurrency] = useState("USD");
  const [depositPeriod, setDepositPeriod] = useState("3 month");
  const [moneyBoxPerDay, setMoneyBoxPerDay] = useState(true);
  const [dollarPerTransaction, setDollarPerTransaction] = useState(false);
  const [tenDollarPerTransaction, setTenDollarPerTransaction] = useState(false);

  const navigation: any = useNavigation();

  const renderDeposit = () => {
    return (
      <View style={{ flex: 1 }}>
        <ScrollView
          contentContainerStyle={{
            flexGrow: 1,
            justifyContent: "space-between",
          }}
          showsVerticalScrollIndicator={false}
        >
          <View style={{ paddingHorizontal: 20 }}>
            <View style={{ paddingVertical: 20 }}>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  borderRadius: 5,
                  padding: 2,
                  marginBottom: 5,
                }}
              >
                <TouchableOpacity onPress={() => setSelected("Future")}>
                  <LinearGradient
                    colors={[
                      selected === "Future" ? "none" : "none",
                      selected === "Future" ? "none" : "none",
                    ]}
                    style={{
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 5,
                      paddingVertical: 3,
                    }}
                    start={{ x: 0, y: 0 }}
                    end={{ x: 1, y: 0 }}
                  >
                    <ApText
                      style={{
                        fontWeight: "500",
                        fontSize: 14,
                        color: selected === "Future" ? "#F37021" : "#D0CFCE",
                        lineHeight: 14 * 1.6,
                        borderRightWidth: 2,
                        borderRightColor: "#D0CFCE",
                        paddingRight: 15,
                      }}
                      translateKey="Future"
                    >
                      Future (1)
                    </ApText>
                  </LinearGradient>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => setSelected("Spot")}>
                  <LinearGradient
                    colors={[
                      selected === "Spot" ? "none" : "none",
                      selected === "Future" ? "none" : "none",
                    ]}
                    style={{
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 5,
                      paddingVertical: 3,
                    }}
                    start={{ x: 0, y: 0 }}
                    end={{ x: 1, y: 0 }}
                  >
                    <ApText
                      style={{
                        fontWeight: "500",
                        fontSize: 14,
                        color: selected === "Spot" ? "#F37021" : "#D0CFCE",
                        lineHeight: 14 * 1.6,
                        paddingLeft: 15,
                      }}
                      translateKey="Spot"
                    >
                      Spot (0)
                    </ApText>
                  </LinearGradient>
                </TouchableOpacity>
              </View>
              {selected == "Future" ? (
                <>
                  <View style={{ paddingVertical: 10 }}>
                    <ApText
                      style={{
                        fontWeight: "500",
                        paddingTop: 6,
                      }}
                    >
                      <ApText
                        style={{ color: "#F37021", fontSize: 15 }}
                        translateKey="FixedFutureStrategies"
                      />
                    </ApText>
                    <TouchableOpacity>
                      <View
                        style={{
                          width: "100%",
                          backgroundColor: "#fff5f0ba",
                          marginTop: 10,
                          borderRadius: 10,
                          flexDirection: "row",
                          alignItems: "center",
                          paddingHorizontal: 10,
                          paddingVertical: 20,
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            width: "85%",
                          }}
                        >
                          <ApText
                            style={{
                              color: theme.COLORS.mainDark,
                              fontWeight: "500",
                            }}
                            translateKey="StableStrategies"
                          />
                        </View>
                        <TouchableOpacity
                          style={{ width: "15%", alignItems: "center" }}
                        >
                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <Image
                              source={require("../../assets/icons/delete.png")}
                              style={{ width: 8, height: 12 }}
                            />
                          </View>
                        </TouchableOpacity>
                        {/* <View
                style={{
                  width: "30%",
                }}
              >
                <TouchableOpacity
                  style={{
                    borderRadius: 4,
                    paddingVertical: 5,
                    paddingHorizontal: 2,
                    borderColor: "#05B491",
                    borderWidth: 1,
                  }}
                  onPress={() => {
                    navigation.navigate("UpdateAPIBinding", {
                      title: item.title,
                    });
                  }}
                >
                  <ApText style={{ color: "#05B491", textAlign: "center" }}>Bound</ApText>
                </TouchableOpacity>
              </View> */}
                      </View>
                    </TouchableOpacity>
                    <TouchableOpacity>
                      <View
                        style={{
                          width: "100%",
                          backgroundColor: "#fff5f0ba",
                          marginTop: 10,
                          borderRadius: 10,
                          flexDirection: "row",
                          alignItems: "center",
                          paddingHorizontal: 10,
                          paddingVertical: 20,
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            width: "85%",
                          }}
                        >
                          <ApText
                            style={{
                              color: theme.COLORS.mainDark,
                              fontWeight: "500",
                            }}
                            translateKey="HighProfitStrategy"
                          />
                        </View>
                        <TouchableOpacity
                          style={{ width: "15%", alignItems: "center" }}
                        >
                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <Image
                              source={require("../../assets/icons/delete.png")}
                              style={{ width: 8, height: 12 }}
                            />
                          </View>
                        </TouchableOpacity>
                      </View>
                    </TouchableOpacity>
                  </View>
                  <View style={{ paddingVertical: 10 }}>
                    <ApText
                      style={{
                        fontWeight: "500",
                        paddingTop: 6,
                      }}
                    >
                      <ApText
                        style={{ color: "#F37021", fontSize: 15 }}
                        translateKey="MyFutureStrategy"
                      />
                    </ApText>
                    <TouchableOpacity>
                      <View
                        style={{
                          width: "100%",
                          backgroundColor: "#fff5f0ba",
                          marginTop: 10,
                          borderRadius: 10,
                          flexDirection: "row",
                          alignItems: "center",
                          paddingHorizontal: 10,
                          paddingVertical: 20,
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            width: "85%",
                          }}
                        >
                          <ApText
                            style={{
                              color: theme.COLORS.mainDark,
                              fontWeight: "500",
                            }}
                            translateKey="BootsStrategy"
                          />
                        </View>
                        <TouchableOpacity
                          style={{ width: "15%", alignItems: "center" }}
                        >
                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <Image
                              source={require("../../assets/icons/delete.png")}
                              style={{ width: 8, height: 12 }}
                            />
                          </View>
                        </TouchableOpacity>
                      </View>
                    </TouchableOpacity>
                    <TouchableOpacity>
                      <View
                        style={{
                          width: "100%",
                          backgroundColor: "#fff5f0ba",
                          marginTop: 10,
                          borderRadius: 10,
                          flexDirection: "row",
                          alignItems: "center",
                          paddingHorizontal: 10,
                          paddingVertical: 20,
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            width: "85%",
                          }}
                        >
                          <ApText
                            style={{
                              color: theme.COLORS.mainDark,
                              fontWeight: "500",
                            }}
                            translateKey="SmartStrategy"
                          />
                        </View>
                        <TouchableOpacity
                          style={{ width: "15%", alignItems: "center" }}
                        >
                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <Image
                              source={require("../../assets/icons/delete.png")}
                              style={{ width: 8, height: 12 }}
                            />
                          </View>
                        </TouchableOpacity>
                      </View>
                    </TouchableOpacity>
                  </View>
                </>
              ) : selected == "Spot" ? (
                <>
                  <components.SmallHeader
                    title="No Data"
                    containerStyle={{ marginBottom: 6 }}
                  />
                </>
              ) : (
                <>
                  <components.SmallHeader
                    title="Executing Bots"
                    containerStyle={{ marginBottom: 6 }}
                  />
                </>
              )}
            </View>
          </View>
          <View
            style={{
              flexDirection: "row",
              paddingHorizontal: 20,
              paddingVertical: 40,
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <TouchableOpacity
              style={{
                backgroundColor: "#F37021",
                width: "100%",
                height: 40,
                borderRadius: 8,
                alignItems: "center",
                justifyContent: "center",
              }}
              onPress={() => navigation.navigate("TradingBot")}
            >
              <ApText
                style={{ color: theme.COLORS.white, fontWeight: "500" }}
                translateKey="ViewAllStrategy"
              />
            </TouchableOpacity>
          </View>
        </ScrollView>
      </View>
    );
  };

  const renderOpenDeposit = () => {
    return (
      <ApSafeAreaView style={{ flex: 1, backgroundColor: theme.COLORS.bgColor }}>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            height: 47,
          }}
        >
          <View
            style={{
              position: "absolute",
              left: 0,
              alignItems: "center",
            }}
          >
            <TouchableOpacity
              style={{
                paddingHorizontal: 20,
                paddingVertical: 12,
              }}
              onPress={() => {
                setOpenDeposit(false);
                setDeposits(true);
                setOpenMoneyBox(false);
              }}
            >
              <svg.GoBackSvg />
            </TouchableOpacity>
          </View>
          <ApText
            style={{
              textAlign: "center",
              ...theme.FONTS.H4,
              color: theme.COLORS.mainDark,
            }}
            translateKey="OpenDeposit"
          />
        </View>
        <ScrollView
          contentContainerStyle={{
            flexGrow: 1,
            paddingHorizontal: 20,
          }}
          showsVerticalScrollIndicator={false}
        >
          <components.SmallHeader
            title="Choose currency:"
            containerStyle={{ marginBottom: 6, marginTop: 20 }}
          />
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginBottom: 14,
            }}
          >
            <TouchableOpacity
              style={{
                paddingHorizontal: 20,
                paddingVertical: 8,
                backgroundColor:
                  currency === "USD"
                    ? theme.COLORS.mainDark
                    : theme.COLORS.white,
                borderRadius: 10,
                marginRight: 14,
              }}
              onPress={() => setCurrency("USD")}
            >
              <ApText
                style={{
                  ...theme.FONTS.H5,
                  color:
                    currency === "USD"
                      ? theme.COLORS.white
                      : theme.COLORS.mainDark,
                }}
                translateKey="USD"
              />
            </TouchableOpacity>
            <TouchableOpacity
              style={{
                paddingHorizontal: 20,
                paddingVertical: 8,
                backgroundColor:
                  currency === "EUR"
                    ? theme.COLORS.mainDark
                    : theme.COLORS.white,
                borderRadius: 10,
              }}
              onPress={() => setCurrency("EUR")}
            >
              <ApText
                style={{
                  ...theme.FONTS.H5,
                  color:
                    currency === "EUR"
                      ? theme.COLORS.white
                      : theme.COLORS.mainDark,
                }}
                translateKey="EUR"
              />
            </TouchableOpacity>
          </View>
          <components.SmallHeader
            title="Choose deposit period:"
            containerStyle={{ marginBottom: 6 }}
          />
          <View
            style={{
              flexDirection: "row",
              flexWrap: "wrap",
              marginBottom: 4,
            }}
          >
            {depositPeriods.map((item, index) => {
              return (
                <TouchableOpacity
                  key={index}
                  style={{
                    backgroundColor:
                      depositPeriod === item.title
                        ? theme.COLORS.mainDark
                        : theme.COLORS.white,
                    paddingHorizontal: 20,
                    paddingVertical: 8,
                    borderRadius: 10,
                    marginBottom: 10,
                    marginRight: 10,
                  }}
                  onPress={() => setDepositPeriod(item.title)}
                >
                  <ApText
                    style={{
                      ...theme.FONTS.Mulish_400Regular,
                      fontSize: 16,
                      lineHeight: 16 * 1.6,
                      color:
                        depositPeriod === item.title
                          ? theme.COLORS.white
                          : theme.COLORS.bodyTextColor,
                    }}
                  >
                    {item.title}
                  </ApText>
                </TouchableOpacity>
              );
            })}
          </View>
          <components.SmallHeader
            title="Amount:"
            containerStyle={{ marginBottom: 6 }}
          />
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginBottom: 14,
            }}
          >
            <View
              style={{
                backgroundColor: theme.COLORS.white,
                borderRadius: 10,
                paddingHorizontal: 20,
                paddingVertical: 14,
                marginRight: 14,
                width: theme.SIZES.width / 2 - 20,
              }}
            >
              <TextInput
                placeholder="$1 000"
                style={{
                  ...theme.FONTS.Mulish_500Medium,
                  fontSize: 28,
                  color: theme.COLORS.mainDark,
                  textAlign: "left",
                }}
                numberOfLines={1}
                textAlign="left"
              />
            </View>
            <ApText
              style={{
                ...theme.FONTS.Mulish_400Regular,
                fontSize: 12,
                color: theme.COLORS.bodyTextColor,
              }}
              translateKey="YourRateis8%"
            />
          </View>
          <components.SmallHeader
            title="Use card:"
            containerStyle={{ marginBottom: 6 }}
          />
          <View style={{ marginBottom: 30 }}>
            <ScrollView
              horizontal={true}
              showsHorizontalScrollIndicator={false}
            >
              {cards.map((item, index) => {
                return (
                  <TouchableOpacity
                    key={index}
                    style={{
                      backgroundColor: theme.COLORS.white,
                      flexDirection: "row",
                      alignItems: "center",
                      padding: 12,
                      marginRight: 14,
                    }}
                  >
                    <Image
                      source={item.icon}
                      style={{
                        width: 72,
                        height: 46,
                        marginRight: 14,
                      }}
                    />
                    <View>
                      <ApText
                        style={{
                          ...theme.FONTS.Mulish_400Regular,
                          fontSize: 12,
                          color: theme.COLORS.bodyTextColor,
                        }}
                      >
                        {item.number}
                      </ApText>
                      <ApText
                        style={{
                          ...theme.FONTS.H6,
                          color: theme.COLORS.mainDark,
                        }}
                      >
                        {item.amount}
                      </ApText>
                    </View>
                  </TouchableOpacity>
                );
              })}
            </ScrollView>
          </View>

          <View
            style={{
              marginBottom: theme.SIZES.height * 0.08,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottomWidth: 1,
              borderBottomColor: "#CED6E1",
              paddingBottom: 20,
            }}
          >
            <ApText
              style={{
                ...theme.FONTS.H5,
                color: theme.COLORS.mainDark,
              }}
              translateKey="EarlyLoanRepayment"
            />
            <TouchableOpacity
              style={{
                width: 40,
                backgroundColor: toggle
                  ? theme.COLORS.green
                  : theme.COLORS.grey1,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: toggle ? "flex-end" : "flex-start",
                padding: 2,
                borderRadius: 20,
              }}
              onPress={() => setToggle(!toggle)}
              activeOpacity={0.8}
            >
              <View
                style={{
                  width: 20,
                  height: 20,
                  borderRadius: 12,
                  backgroundColor: theme.COLORS.white,
                }}
              />
            </TouchableOpacity>
          </View>
          <components.Button
            title="Open deposit"
            containerStyle={{ marginBottom: 20 }}
            onPress={() => {
              setDeposits(true);
              setOpenMoneyBox(false);
              setOpenDeposit(false);
            }}
          />
        </ScrollView>
      </ApSafeAreaView>
    );
  };

  const renderOpenMoneybox = () => {
    return (
      <ApSafeAreaView style={{ flex: 1, backgroundColor: theme.COLORS.bgColor }}>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            height: 47,
          }}
        >
          <View
            style={{
              position: "absolute",
              left: 0,
              alignItems: "center",
            }}
          >
            <TouchableOpacity
              style={{
                paddingHorizontal: 20,
                paddingVertical: 12,
              }}
              onPress={() => {
                setOpenDeposit(false);
                setDeposits(true);
                setOpenMoneyBox(false);
              }}
            >
              <svg.GoBackSvg />
            </TouchableOpacity>
          </View>
          <ApText
            style={{
              textAlign: "center",
              ...theme.FONTS.H4,
              color: theme.COLORS.mainDark,
            }}
            translateKey="OpenMoneyBox"
          />
        </View>
        <View>
          <ScrollView
            contentContainerStyle={{ marginBottom: 70, flexGrow: 1 }}
            showsVerticalScrollIndicator={false}
          >
            <components.SmallHeader
              title="The amount you want to achieve:"
              containerStyle={{
                marginTop: 20,
                paddingHorizontal: 20,
                marginBottom: 6,
              }}
            />
            <View
              style={{
                backgroundColor: theme.COLORS.white,
                borderRadius: 10,
                paddingHorizontal: 20,
                paddingVertical: 14,
                marginRight: 14,
                width: theme.SIZES.width / 2 - 20,
                marginHorizontal: 20,
                marginBottom: 14,
              }}
            >
              <TextInput
                placeholder="$1200"
                style={{
                  ...theme.FONTS.Mulish_500Medium,
                  fontSize: 28,
                  color: theme.COLORS.mainDark,
                  textAlign: "left",
                }}
                numberOfLines={1}
                textAlign="left"
              />
            </View>
            <components.SmallHeader
              title="Use card:"
              containerStyle={{
                paddingHorizontal: 20,
                marginBottom: 6,
              }}
            />
            <View style={{ marginBottom: 30 }}>
              <ScrollView
                horizontal={true}
                showsHorizontalScrollIndicator={false}
                contentContainerStyle={{ paddingLeft: 20 }}
              >
                {cards.map((item, index) => {
                  return (
                    <TouchableOpacity
                      key={index}
                      style={{
                        backgroundColor: theme.COLORS.white,
                        flexDirection: "row",
                        alignItems: "center",
                        padding: 12,
                        marginRight: 14,
                      }}
                    >
                      <Image
                        source={item.icon}
                        style={{
                          width: 72,
                          height: 46,
                          marginRight: 14,
                        }}
                      />
                      <View>
                        <ApText
                          style={{
                            ...theme.FONTS.Mulish_400Regular,
                            fontSize: 12,
                            color: theme.COLORS.bodyTextColor,
                          }}
                        >
                          {item.number}
                        </ApText>
                        <ApText
                          style={{
                            ...theme.FONTS.H6,
                            color: theme.COLORS.mainDark,
                          }}
                        >
                          {item.amount}
                        </ApText>
                      </View>
                    </TouchableOpacity>
                  );
                })}
              </ScrollView>
            </View>
          </ScrollView>
        </View>
        <View style={{ paddingHorizontal: 20, marginBottom: 20 }}>
          <TouchableOpacity
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginBottom: 10,
            }}
            onPress={() => {
              setMoneyBoxPerDay(true);
              setDollarPerTransaction(false);
              setTenDollarPerTransaction(false);
            }}
          >
            <View
              style={{
                width: 18,
                height: 18,
                borderWidth: 2,
                borderRadius: 18 / 2,
                borderColor: "#CED6E1",
                justifyContent: "center",
                alignItems: "center",
                marginRight: 14,
              }}
            >
              {moneyBoxPerDay && (
                <View
                  style={{
                    width: 10,
                    height: 10,
                    borderRadius: 10 / 2,
                    backgroundColor: theme.COLORS.green,
                  }}
                />
              )}
            </View>
            <View
              style={{
                paddingHorizontal: 17,
                paddingVertical: 14,
                backgroundColor: theme.COLORS.white,
                borderRadius: 10,
                marginRight: 10,
              }}
            >
              <ApText
                style={{
                  ...theme.FONTS.Mulish_400Regular,
                  fontSize: 14,
                  lineHeight: 14 * 1.6,
                  color: theme.COLORS.mainDark,
                }}
              >
                10.00
              </ApText>
            </View>
            <ApText
              style={{
                ...theme.FONTS.Mulish_400Regular,
                fontSize: 16,
                lineHeight: 16 * 1.6,
                color: theme.COLORS.bodyTextColor,
              }}
              translateKey="USDPerDay"
            />
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginBottom: 2,
            }}
            onPress={() => {
              setMoneyBoxPerDay(false);
              setDollarPerTransaction(true);
              setTenDollarPerTransaction(false);
            }}
          >
            <View
              style={{
                width: 18,
                height: 18,
                borderWidth: 2,
                borderRadius: 18 / 2,
                borderColor: "#CED6E1",
                justifyContent: "center",
                alignItems: "center",
                marginRight: 14,
              }}
            >
              {dollarPerTransaction && (
                <View
                  style={{
                    width: 10,
                    height: 10,
                    borderRadius: 10 / 2,
                    backgroundColor: theme.COLORS.green,
                  }}
                />
              )}
            </View>
            <ApText
              style={{
                ...theme.FONTS.Mulish_400Regular,
                fontSize: 16,
                lineHeight: 16 * 1.6,
                color: theme.COLORS.bodyTextColor,
              }}
              translateKey="RoundingPer1Transaction"
            />
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              flexDirection: "row",
              alignItems: "center",
            }}
            onPress={() => {
              setMoneyBoxPerDay(false);
              setDollarPerTransaction(false);
              setTenDollarPerTransaction(true);
            }}
          >
            <View
              style={{
                width: 18,
                height: 18,
                borderWidth: 2,
                borderRadius: 18 / 2,
                borderColor: "#CED6E1",
                justifyContent: "center",
                alignItems: "center",
                marginRight: 14,
              }}
            >
              {tenDollarPerTransaction && (
                <View
                  style={{
                    width: 10,
                    height: 10,
                    borderRadius: 10 / 2,
                    backgroundColor: theme.COLORS.green,
                  }}
                />
              )}
            </View>
            <ApText
              style={{
                ...theme.FONTS.Mulish_400Regular,
                fontSize: 16,
                lineHeight: 16 * 1.6,
                color: theme.COLORS.bodyTextColor,
              }}
              translateKey="RoundingPer10Transaction"
            />
          </TouchableOpacity>
        </View>
        <View
          style={{
            paddingHorizontal: 20,
            marginBottom: theme.SIZES.height * 0.1,
          }}
        >
          <components.InputField placeholder="Enter your goal" />
        </View>
        <components.Button
          title="Open Moneybox"
          containerStyle={{ paddingHorizontal: 20 }}
          onPress={() => {
            setOpenDeposit(false);
            setDeposits(true);
            setOpenMoneyBox(false);
          }}
        />
      </ApSafeAreaView>
    );
  };

  return (
    <View style={{ flex: 1, backgroundColor: theme.COLORS.bgColor }}>
      {renderHeader()}
      {deposits && renderDeposit()}
      {openDeposit && renderOpenDeposit()}
      {openMoneyBox && renderOpenMoneybox()}
    </View>
  );
};

export default Deposits;
