import { Formik, FormikProps } from "formik";
import React, {
  Dispatch,
  SetStateAction,
  memo,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { ScrollView, StyleSheet, TouchableOpacity, View } from "react-native";
import { ApModalAlt } from "../components/Modal";
import { ApButton } from "../components/v1";
import { ApDropdown, ApSettingsInput } from "../components/v1/input";
import { ApText } from "../components/v1/typography";
import { theme } from "../constants";
import { useTradingContext } from "../context";
import { svg } from "../svg";
import { formatAmt } from "../utils";
import { useMasterData } from "../hooks/master";

interface IProps {
  setVisible?: Dispatch<SetStateAction<boolean>>;
  visible?: boolean;
}

const MacdModeSetting: React.FC<IProps> = memo(({ visible, setVisible }) => {
  const { i18n, t } = useTranslation();
  const formRef = useRef<FormikProps<any>>();
  // const [initValues, setInitValues] = useState<any>();
  const { MasterData } = useMasterData();
  const {
    saveOriginal,
    setMacdModeSettings,
    macdModeSettings,
    set_entry_macd_type,
  } = useTradingContext();

  // useEffect(() => {
  //   let obj: any;
  //   macdModeSettings.map((item: any) => {
  //     obj = {
  //       ...obj,
  //       [item.name]:
  //         item.entry_macd_type !== null
  //           ? formatAmt(parseFloat(item.entry_macd_type) * 100)
  //           : item.entry_tp_perc,
  //       [item.traillingTakeProfitName]:
  //         item.entry_tp_trailing_perc !== null
  //           ? formatAmt(parseFloat(item.entry_tp_trailing_perc) * 100)
  //           : item.entry_tp_trailing_perc,
  //     };
  //   });
  //   setInitValues(obj);
  //   //console.log(obj);
  // }, []);

  const renderHeader = () => {
    return (
      <View
        style={{
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          height: 47,
          backgroundColor: "#FCF8F6",
        }}
      >
        <View
          style={{
            position: "absolute",
            left: 0,
            alignItems: "center",
          }}
        >
          <TouchableOpacity
            style={{
              paddingHorizontal: 20,
              paddingVertical: 12,
            }}
            onPress={() => setVisible(false)}
          >
            <svg.GoBackSvg goBackColor="#F37021" />
          </TouchableOpacity>
        </View>

        <ApText
          translateKey="MACDModeSettings"
          style={{
            textAlign: "center",
            fontSize: 16,
            color: "#F37021",
          }}
        >
          MACD Mode Settings
        </ApText>
      </View>
    );
  };

  const handleSave = (val: any) => {
    let itms = [...val.items];
    let macdModeVal: any = [];
    itms?.forEach((item: any) => {
      item.entry_macd_type = item[`${item.name}`];
      macdModeVal = [...macdModeVal, item.entry_macd_type];
    });

    setMacdModeSettings(itms);
    set_entry_macd_type(macdModeVal);

    saveOriginal({
      MacdModeSettings: itms,
      entry_macd_type: macdModeVal,
    });
    setVisible(false);
  };

  const renderContent = () => {
    return (
      <View style={{ flex: 1 }}>
        <View
          style={{
            margin: 15,
            borderRadius: 10,
            marginTop: 15,
            flex: 0.92,
          }}
        >
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              paddingBottom: 5,
              alignItems: "center",
              width: "100%",
            }}
          >
            <ApText
              translateKey="OrderList"
              style={{ color: "#959595", fontSize: 13 }}
            >
              Order List
            </ApText>
            <ApText
              translateKey="MACDMode"
              style={{ color: "#959595", fontSize: 13, textAlign: "center" }}
            />
          </View>

          <Formik
            enableReinitialize
            innerRef={formRef as any}
            //validationSchema={FormSchema}
            initialValues={{
              items: macdModeSettings.map((item: any) => ({
                ...item,
                [item.name]: item.entry_macd_type,
              })),
            }}
            onSubmit={(val) => handleSave(val)}
          >
            {(props: FormikProps<any>) => (
              <>
                <View>
                  {props?.values?.items?.map((item: any, index: number) => {
                    return (
                      <View
                        key={index}
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          paddingVertical: 5,
                          borderBottomColor: "#e6e6e6",
                          borderBottomWidth: 1,
                          zIndex: 4000 - index,
                          elevation: 4000 - index,
                        }}
                      >
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <ApText
                            translateKey="Order"
                            style={{ color: "#F37021", fontSize: 12 }}
                          >
                            Order
                          </ApText>
                          <ApText style={{ color: "#F37021", fontSize: 12 }}>
                            {" "}
                            #{item?.orderList}
                          </ApText>
                        </View>

                        <View style={{ zIndex: 4000 - index,  }}>
                          <ApDropdown
                            style={{ width: 150 }}
                            containerStyle={{
                              justifyContent: "flex-end",
                            }}
                            contPropsHeight={220}
                            useContPropsHeight={
                              index + 1 === macdModeSettings.length
                                ? true
                                : false
                            }
                            noBorder
                            name={`items[${index}].${item?.name}`}
                            items={MasterData?.MACD_TYPES}
                          />
                        </View>
                      </View>
                    );
                  })}
                </View>

                <View style={{ marginTop: 20, zIndex: -99 }}>
                  <ApButton
                    round="lg"
                    label="Save"
                    labelTranslateKey="Save"
                    onPress={() => props.handleSubmit()}
                  />
                </View>
              </>
            )}
          </Formik>
        </View>
      </View>
    );
  };

  return (
    <ApModalAlt fallbackRoute="EditStrategy" visible={visible}>
      <ScrollView style={{ flex: 1 }}>
        {renderHeader()}
        {renderContent()}
      </ScrollView>
    </ApModalAlt>
  );
});

export default MacdModeSetting;

const style = StyleSheet.create({
  inputLabel: {
    ...theme.FONTS.H6,
    color: theme.COLORS.mainDark,
    // width: "50%",
  },
  textInput: {
    backgroundColor: "#DFE3EA",
    width: 80,
    color: "#F37021",
    textAlign: "center",
    fontSize: 13,
    padding: 3,
    borderRadius: 6,
  },
});
