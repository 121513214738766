import React, { useEffect, useState } from "react";
import { ScrollView, TouchableOpacity, View } from "react-native";
import { RefreshControl } from "react-native-web-refresh-control";
import { components } from "../components";
import { ApEmptyState, ApLoader, ApSafeAreaView } from "../components/v1";
import { ApText } from "../components/v1/typography";
import { usePackageState, useProfileState } from "../context";
import { toCurrency } from "../utils";
const MyPackages: React.FC = ({ navigation }: any) => {
  const { getMyPackages, myPackages, loading } = usePackageState();
  const [refreshing, setRefreshing] = useState(false);
  const { profileData, getProfileData } = useProfileState();

  useEffect(() => {
    getProfileData();
    getMyPackages();
  }, []);

  const handleRefresh = () => {
    setRefreshing(true);
    getMyPackages().finally(() => {
      setRefreshing(false);
    });
  };

  const renderHeader = () => {
    return (
      <components.Header
        title="My Duobo Package"
        goBack={true}
        titleTranlateKey="MyDBCard"
      />
    );
  };

  const renderContent = () => {
    return (
      <ScrollView
        contentContainerStyle={{ flexGrow: 1, paddingHorizontal: 20 }}
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={handleRefresh} />
        }
      >
        {/* <View
          style={{
            backgroundColor: "#FFD9CF",
            padding: 12,
            borderRadius: 10,
            marginTop: 15,
            marginBottom: 5,
            flexDirection: "row",
          }}
        >
          <ApText
            translateKey="TradePageNote"
            style={{ color: "#F37021", width: "80%", fontSize: 13 }}
          >
            It is a long established fact that a reader will be distracted by
            the readable content of a page when looking at its layout.
          </ApText>
          <Image
            source={require("../assets/icons/account-hint.png")}
            style={{ width: 86, height: 57 }}
          />
        </View> */}
        <View style={{ padding: 0, marginTop: 10 }}></View>

        {myPackages?.length > 0 ? (
          myPackages?.map((pkg, i) => (
            <View
              key={i}
              style={{
                backgroundColor: "#fff5f0ba",
                borderRadius: 10,
                padding: 15,
                marginTop: i === 0 ? 10 : 0,
                marginBottom: 20,
              }}
            >
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    width: "85%",
                  }}
                >
                  <ApText
                    style={{
                      color: "#F37021",
                      fontWeight: "500",
                    }}
                  >
                    {profileData.preferred_lang === "simplified-chinese"
                      ? pkg.name_cn
                      : profileData.preferred_lang === "english"
                      ? pkg.name_en
                      : pkg.name_cnt}
                  </ApText>
                </View>
              </View>

              <View style={{ paddingTop: 5 }}>
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "flex-end",
                    justifyContent: "space-between",
                  }}
                >
                  <View>
                    <View style={{ flexDirection: "row" }}>
                      <ApText
                        translateKey="DatePurchased"
                        style={{ color: "#222", fontSize: 12 }}
                      />
                      <ApText style={{ color: "#222", fontSize: 12 }}>
                        {" : " + pkg.created_at.split(" ")[0]}
                      </ApText>
                    </View>

                    <View style={{ flexDirection: "row" }}>
                      <ApText
                        translateKey="Fee"
                        style={{ color: "#222", fontSize: 12 }}
                      />
                      <ApText style={{ color: "#222", fontSize: 12 }}>
                        {" : "}
                        {`${pkg.cost} USDT`}
                      </ApText>
                    </View>

                    <View style={{ flexDirection: "row" }}>
                      <ApText
                        translateKey="DDCoinRemainingRound"
                        style={{ color: "#222", fontSize: 12 }}
                      />
                      <ApText style={{ color: "#222", fontSize: 12 }}>
                        {" : " + pkg.ddb_roi_remaining}
                      </ApText>
                    </View>

                    <View style={{ display: "flex", flexDirection: "row" }}>
                      <ApText
                        translateKey="TotalReleasedDDCoin"
                        style={{ color: "#222", fontSize: 12 }}
                      />
                      <ApText style={{ color: "#222", fontSize: 12 }}>
                        {" : " + toCurrency(parseFloat(pkg.total_received_ddb))}
                      </ApText>
                    </View>

                    <View style={{ display: "flex", flexDirection: "row" }}>
                      <ApText
                        translateKey="DLPointRemainingRound"
                        style={{ color: "#222", fontSize: 12 }}
                      />
                      <ApText style={{ color: "#222", fontSize: 12 }}>
                        {" : " + pkg.doli_roi_remaining}
                      </ApText>
                    </View>

                    <View style={{ display: "flex", flexDirection: "row" }}>
                      <ApText
                        translateKey="TotalReleasedDLPoint"
                        style={{ color: "#222", fontSize: 12 }}
                      />
                      <ApText style={{ color: "#222", fontSize: 12 }}>
                        {" : " +
                          toCurrency(parseFloat(pkg.total_received_doli))}
                      </ApText>
                    </View>
                  </View>
                </View>

                <View
                  style={{
                    flexDirection: "row",
                    marginTop: 10,
                    alignItems: "flex-end",
                    justifyContent: "flex-end",
                  }}
                >
                  <TouchableOpacity
                    style={{
                      borderRadius: 4,
                      paddingVertical: 3,
                      paddingHorizontal: 10,
                      backgroundColor: "#05B491",
                      borderColor: "#05B491",
                      minWidth: 80,
                      borderWidth: 1,
                    }}
                    onPress={() => {
                      navigation.navigate("PackageRoi", { mp_id: pkg.id });
                    }}
                  >
                    <ApText
                      translateKey="ViewRoi"
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        fontSize: 12,
                      }}
                    />
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          ))
        ) : (
          <ApEmptyState />
        )}
      </ScrollView>
    );
  };

  return (
    <ApSafeAreaView>
      {renderHeader()}
      {loading && <ApLoader />}
      {!loading && renderContent()}
    </ApSafeAreaView>
  );
};

export default MyPackages;
