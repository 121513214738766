import React, { ComponentProps, FC } from "react";
import { AuthContextProvider } from "./auth";
import { ConfigContextProvider } from "./config";
import { StrategyContextProvider } from "./strategy";
import { TradingContextProvider } from "./trading";
import { ProfileContextProvider } from './profile';
import { PackageContextProvider } from "./package";
import { TransactionContextProvider } from "./transaction";
import { BusinessContextProvider } from "./business";
import { MembershipContextProvider } from "./membership";
import { ApiBindingContextProvider } from "./apiBinding";
import { PnlContextProvider } from "./pnl";
import { FollowContextProvider } from "./follow";
import { CopyContextProvider } from "./copy";
// import { RootSiblingParent } from "react-native-root-siblings";

export const combineContext = (...components: FC[]): FC<any> => {
  return components.reduce(
    (AccumulatedComponents: any, CurrentComponent: any) => {
      return ({ children }: ComponentProps<FC<any>>): JSX.Element => {
        return (
          // <RootSiblingParent>
          <AccumulatedComponents>
            <CurrentComponent>{children}</CurrentComponent>
          </AccumulatedComponents>
          // </RootSiblingParent>
        );
      };
    },
    ({ children }) => <>{children}</>
  );
};

const providers = [
  ConfigContextProvider,
  StrategyContextProvider,
  AuthContextProvider,
  TradingContextProvider,
  PackageContextProvider,
  ProfileContextProvider,
  TransactionContextProvider,
  BusinessContextProvider,
  MembershipContextProvider,
  ApiBindingContextProvider,
  PnlContextProvider,
  FollowContextProvider,
  CopyContextProvider
] as any;

export const AppContextProvider = combineContext(...providers);
