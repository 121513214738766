import * as React from "react";
import Svg, { Path } from "react-native-svg";

function VerifySvg(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width={12.431} height={11.969} viewBox="0 0 12.431 11.969" {...props}>
      <Path
        d="M13.6 6.708a2.739 2.739 0 01-.252-.5 2.4 2.4 0 01-.108-.557 2.523 2.523 0 00-.384-1.295 2.547 2.547 0 00-1.121-.767 2.859 2.859 0 01-.509-.258 3.063 3.063 0 01-.384-.4 2.517 2.517 0 00-1.079-.839 2.529 2.529 0 00-1.319.042 2.541 2.541 0 01-1.163 0 2.529 2.529 0 00-1.343-.042 2.517 2.517 0 00-1.091.839 3.063 3.063 0 01-.4.4 2.859 2.859 0 01-.509.258 2.547 2.547 0 00-1.127.767 2.523 2.523 0 00-.366 1.295 2.4 2.4 0 01-.108.557 2.739 2.739 0 01-.252.491A2.577 2.577 0 001.63 8a2.577 2.577 0 00.462 1.3 2.4 2.4 0 01.252.491 2.463 2.463 0 01.09.569 2.511 2.511 0 00.384 1.295 2.547 2.547 0 001.121.767 2.859 2.859 0 01.509.258 3.063 3.063 0 01.4.4 2.469 2.469 0 001.079.839 1.8 1.8 0 00.521.078 3.992 3.992 0 00.8-.108 2.4 2.4 0 011.163 0 2.6 2.6 0 001.343.036 2.469 2.469 0 001.079-.839 3.063 3.063 0 01.4-.4 2.859 2.859 0 01.509-.258 2.547 2.547 0 001.121-.767 2.511 2.511 0 00.384-1.295 2.463 2.463 0 01.09-.569 2.739 2.739 0 01.252-.491A2.577 2.577 0 0014.061 8a2.577 2.577 0 00-.461-1.292z"
        fill="#05b491"
        transform="translate(-1.63 -2.022)"
      />
      <Path d="M9.4 13.194a.6.6 0 01-.426-.174l-1.8-1.8a.6.6 0 01.851-.851l1.375 1.38 2.571-2.577a.6.6 0 11.851.851l-3 3a.6.6 0 01-.422.171z" transform="translate(-1.63 -2.022) translate(-2.15 -2.794)" fill="#fff" />
    </Svg>
  );
}

export default VerifySvg;
