import * as React from "react";
import Svg, { G, Path, Circle } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width={40} height={40.001} viewBox="0 0 40 40.001" {...props}>
      <G transform="translate(-6.5 -6.499)">
        <Path data-name="Path 41" d="M36.2 19.266H15.776a1.277 1.277 0 01-.9-2.18l10.21-10.212a1.277 1.277 0 011.806 0L37.1 17.086a1.277 1.277 0 01-.9 2.18zm-17.342-2.553H33.12l-7.131-7.131z" transform="translate(-1.191)" fill="#F37021" />
        <Path
          data-name="Path 42"
          d="M38.415 48.287H11.181A4.686 4.686 0 016.5 43.606V23.181a4.686 4.686 0 014.681-4.681h27.234a4.686 4.686 0 014.685 4.681v20.425a4.686 4.686 0 01-4.685 4.681zM11.181 21.053a2.128 2.128 0 00-2.128 2.128v20.425a2.128 2.128 0 002.128 2.128h27.234a2.128 2.128 0 002.128-2.128V23.181a2.128 2.128 0 00-2.128-2.128z"
          transform="translate(0 -1.787)"
          fill="#7a8390"
        />
        <Path data-name="Path 43" d="M41.106 30h8.511v10.213h-8.511A5.106 5.106 0 0136 35.106 5.106 5.106 0 0141.106 30z" transform="translate(-4.394 -3.5)" fill="#fff" />
        <Path
          data-name="Path 44"
          d="M49.394 41.266h-8.511a6.383 6.383 0 010-12.766h8.511a1.277 1.277 0 011.277 1.277v10.212a1.277 1.277 0 01-1.277 1.277zm-8.511-10.213a3.83 3.83 0 100 7.66h7.234v-7.66z"
          transform="translate(-4.17 -3.277)"
          fill="#7a8390"
        />
        <Circle data-name="Ellipse 26" cx={1.5} cy={1.5} r={1.5} transform="translate(35 30)" fill="#7a8390" />
        <Path
          data-name="Path 45"
          d="M7.777 44.883A1.277 1.277 0 016.5 43.606V23.181a4.686 4.686 0 014.681-4.681h27.234a1.277 1.277 0 110 2.553H11.181a2.128 2.128 0 00-2.128 2.128v20.425a1.277 1.277 0 01-1.276 1.277z"
          transform="translate(0 -1.787)"
          fill="#7a8390"
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
