import * as React from "react";
import Svg, { G, Path } from "react-native-svg";

function DuobaoSvg(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width={24} height={16} viewBox="0 0 24 16" {...props}>
      <G transform="translate(0 -4)">
        <G data-name="Group 126">
          <G data-name="Group 125">
            <Path data-name="Path 73" d="M23 20H1a.945.945 0 01-1-1V5a.945.945 0 011-1h22a.945.945 0 011 1v14a.945.945 0 01-1 1zM2 18h20V6H2z" fill="#7a8390" />
          </G>
        </G>
        <Path data-name="Rectangle 204" transform="translate(2 8)" fill="#F37021" d="M0 0H20V3H0z" />
      </G>
    </Svg>
  );
}

export default DuobaoSvg;
