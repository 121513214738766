import * as React from "react";
import Svg, { Rect, Path } from "react-native-svg";

const TwitterSvg: React.FC = () => (
    <Svg width={51} height={50} fill="none">
        <Rect x={0.5} width={50} height={50} rx={25} fill="#55ACEE" />
        <Path
            d="M35.105 18.523a7.797 7.797 0 0 1-.995.362c.402-.455.709-.99.896-1.575a.332.332 0 0 0-.486-.386 7.83 7.83 0 0 1-2.314.915 4.438 4.438 0 0 0-3.091-1.26 4.421 4.421 0 0 0-4.38 4.986 11.306 11.306 0 0 1-7.76-4.116.331.331 0 0 0-.544.042 4.415 4.415 0 0 0-.598 2.22c0 1.065.38 2.074 1.051 2.863a3.746 3.746 0 0 1-.591-.264.332.332 0 0 0-.493.283v.059c0 1.588.854 3.018 2.161 3.797a3.838 3.838 0 0 1-.335-.048.332.332 0 0 0-.379.427 4.414 4.414 0 0 0 3.235 2.96 7.812 7.812 0 0 1-4.176 1.192c-.313 0-.627-.019-.935-.055a.332.332 0 0 0-.218.609 11.91 11.91 0 0 0 6.44 1.887c4.496 0 7.308-2.12 8.876-3.899 1.955-2.217 3.076-5.153 3.076-8.053 0-.122-.002-.244-.005-.366a8.586 8.586 0 0 0 1.976-2.093.332.332 0 0 0-.41-.487Z"
            fill="#fff"
        />
    </Svg>
);

export default TwitterSvg;
