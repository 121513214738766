import React, { useRef } from "react";
import { ImageBackground, View } from "react-native";

import { Formik, FormikProps } from "formik";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import * as Yup from "yup";
import { ApButton, ApSafeAreaView } from "../components/v1";
import {
  ApLangDropdown,
  ApPasswordInput,
  ApTacInput,
  ApTextInput,
} from "../components/v1/input";
import { ApText } from "../components/v1/typography";
import { theme } from "../constants";
import { ERROR_MSG } from "../constants/errors";
import { useAuthState } from "../context/auth";
import { svg } from "../svg";

const FormSchema = Yup.object().shape({
  account_name: Yup.string()
    .email(ERROR_MSG.InvalidEmail)
    .required(ERROR_MSG.EmailRequired),
  tac: Yup.string()
    .min(6, ERROR_MSG.VerificationCodeMin)
    .required(ERROR_MSG.VerificationCodeRequired),
  new_password: Yup.string().required(ERROR_MSG.PasswordRequired),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("new_password"), null], ERROR_MSG.PasswordMismatch)
    .required(ERROR_MSG.ConfirmPasswordRequired),
});

const ForgotPassword: React.FC = ({ navigation }: any) => {
  const { resetMemberPassword } = useAuthState();

  const formRef = useRef<FormikProps<any>>();

  const handleReset = (values: any) => {
    resetMemberPassword({
      ...values,
      tac: values.tac,
      type: "rp",
    }).then((res) => {
      navigation.navigate("SignIn");
      formRef.current.resetForm();
    });
  };

  const renderContent = () => {
    return (
      <ImageBackground
        style={{
          width: theme.SIZES.width,
          height: theme.SIZES.height,
          justifyContent: "flex-end",
        }}
        source={require("../../assets/bg-05.png")}
      >
        <View style={{ paddingHorizontal: 35, paddingBottom: 43 }}>
          <ApText
            style={{
              fontSize: 22,
              color: "white",
              fontWeight: "bold",
              paddingBottom: 5,
            }}
            translateKey="Hello"
          />
          <ApText
            style={{ fontSize: 22, color: "white", fontWeight: "bold" }}
            translateKey="WelcomeToUseBDDAI"
          />
        </View>

        <View
          style={{
            backgroundColor: "white",
            paddingTop: theme.SIZES.height * 0.05,
            paddingBottom: theme.SIZES.height * 0.07,
            paddingHorizontal: 15,
            borderTopLeftRadius: 35,
            borderTopRightRadius: 35,
            height: 500,
          }}
        >
          <View
            style={{
              zIndex: 999,
              alignSelf: "flex-start",
              position: "absolute",
              top: 20,
              right: 20,
            }}
          >
            <ApLangDropdown />
          </View>

          <View style={{ marginTop: 10 }}>
            <Formik
              innerRef={formRef as any}
              validationSchema={FormSchema}
              initialValues={{
                tac: "",
                account_name: "",
                new_password: "",
                confirm_password: "",
              }}
              onSubmit={handleReset}
            >
              {(props: FormikProps<any>) => (
                <KeyboardAwareScrollView
                  contentContainerStyle={{
                    flexGrow: 1,
                    paddingHorizontal: 20,
                    paddingBottom: 20,
                  }}
                >
                  <View>
                    <ApTextInput
                      name="account_name"
                      label="Email"
                      labelTranslateKey="Email"
                      placeholder="Your registered email address"
                      placeHolderTranslateKey="RegisteredEmailAddress"
                      icon={<svg.CheckSvg />}
                      containerStyle={{
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                      }}
                      inputStyle={{
                        paddingLeft: 7,
                        paddingRight: 7,
                      }}
                    />

                    <ApTacInput
                      type="rp"
                      props={props}
                      name="tac"
                      propKey="account_name"
                    />

                    <ApPasswordInput
                      label="New Login Password"
                      name="new_password"
                      labelTranslateKey="NewLoginPassword"
                      placeholder="Enter new login password"
                      placeHolderTranslateKey="EnterNewLoginPassword"
                    />

                    {/* <ApTextInput
                      label="New Login Password"
                      name="new_password"
                      labelTranslateKey="NewLoginPassword"
                      placeholder="Enter new login password"
                      placeHolderTranslateKey="EnterNewLoginPassword"
                      secureTextEntry
                      icon={
                        <TouchableOpacity>
                          <svg.EyeOffSvg />
                        </TouchableOpacity>
                      }
                      containerStyle={{
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                      }}
                      inputStyle={{
                        paddingLeft: 7,
                        paddingRight: 7,
                      }}
                    /> */}

                    <ApPasswordInput
                      label="Confirm Login Password"
                      name="confirm_password"
                      labelTranslateKey="ConfirmLoginPassword"
                      placeholder="Re-enter new password"
                      placeHolderTranslateKey="ReEnterNewPassword"
                    />

                    {/* <ApTextInput
                      label="Confirm Login Password"
                      name="confirm_password"
                      labelTranslateKey="ConfirmLoginPassword"
                      placeholder="Re-enter new password"
                      placeHolderTranslateKey="ReEnterNewPassword"
                      secureTextEntry
                      icon={
                        <TouchableOpacity>
                          <svg.EyeOffSvg />
                        </TouchableOpacity>
                      }
                      containerStyle={{
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                      }}
                      inputStyle={{
                        paddingLeft: 7,
                        paddingRight: 7,
                      }}
                    /> */}

                    <ApButton
                      label="Reset Login Password"
                      round="xxl"
                      type="primary"
                      labelTranslateKey="ResetLoginPassword"
                      onPress={props.handleSubmit}
                    />

                    <ApButton
                      label="Back"
                      round="xxl"
                      labelTranslateKey="Back"
                      type="light"
                      style={{
                        marginTop: 20,
                      }}
                      onPress={() => navigation.navigate("SignIn")}
                    />
                  </View>
                </KeyboardAwareScrollView>
              )}
            </Formik>
          </View>
        </View>
      </ImageBackground>
    );
  };

  return (
    <ApSafeAreaView>
      {/* {renderHeader()} */}
      {renderContent()}
    </ApSafeAreaView>
  );
};

export default ForgotPassword;
