import React, { useRef, useState } from "react";
import {
  Image,
  ImageBackground,
  ScrollView,
  TouchableOpacity,
  View,
} from "react-native";

import * as ImagePicker from "expo-image-picker";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { components } from "../components";
import { ApButton, ApSafeAreaView } from "../components/v1";
import { ApTextInput } from "../components/v1/input";
import { useProfileState } from "../context";
import { svg } from "../svg";

const FormSchema = Yup.object().shape({
  nickname: Yup.string(),
});

const EditPersonalInfo: React.FC = ({ navigation }: any) => {
  const [imageSource, setImageSource] = useState(null);
  const formRef = useRef<FormikProps<any>>();
  const { profileData, uploadProfilePic, editProfile, getProfileData } =
    useProfileState();
  const [image, setImage] = useState<any>(null);

  const pickImage = async () => {
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      // allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });

    if (!result.canceled) {
      setImage(result.assets[0].uri);
    }
  };

  const updatePhoto = () => {
    if (!image) return Promise.resolve();
    return uploadProfilePic({ img_file_b64: image });
  };

  const updateProfile = (value) => {
    return editProfile(value).then((rs) => {
      formRef.current?.resetForm();
      return rs;
    });
  };

  const handleSubmit = (value: any) => {
    Promise.all([updatePhoto(), updateProfile(value)]).then(() => {
      navigation.navigate("Home");
      getProfileData();
    });
  };

  const renderHeader = () => {
    return (
      <components.Header
        title="Edit Profile"
        titleTranlateKey="EditProfile"
        goBack={true}
      />
    );
  };

  const renderUserPhoto = () => {
    return (
      <View>
        {image ? (
          <View
            style={{
              width: 110,
              height: 110,
              alignSelf: "center",
              marginVertical: 20,
              flexDirection: "row",
            }}
          >
            <Image
              source={image}
              style={{
                width: "100%",
                height: "100%",
                borderRadius: 55,
              }}
            />

            <TouchableOpacity onPress={() => setImage(null)}>
              <svg.CancelSvg />
            </TouchableOpacity>
          </View>
        ) : (
          <TouchableOpacity
            onPress={() => pickImage()}
            style={{
              width: 110,
              height: 110,
              alignSelf: "center",
              marginVertical: 20,
            }}
          >
            <ImageBackground
              source={
                imageSource
                  ? (imageSource as any)
                  : require("../../assets/users/08.png")
              }
              style={{
                width: "100%",
                height: "100%",
                borderRadius: 55,
              }}
            >
              <View
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(27, 29, 77, 0.5)",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 50,
                }}
              >
                <svg.EditPhotoSvg />
              </View>
            </ImageBackground>
          </TouchableOpacity>
        )}
      </View>
    );
  };

  const renderInputFields = () => {
    return (
      <Formik
        innerRef={formRef as any}
        validationSchema={FormSchema}
        initialValues={{ nickname: profileData.nickname || "" }}
        onSubmit={handleSubmit}
      >
        {(props: FormikProps<any>) => (
          <View>
            <ApTextInput
              name="nickname"
              label="Nickname"
              placeholder="Enter your nickname"
              labelTranslateKey="NickNamePlaceHolder"
              icon={<svg.CheckSvg />}
            />
            <ApButton
              label="Save"
              round="lg"
              type="primary"
              labelTranslateKey="Save"
              onPress={props.handleSubmit}
            />
          </View>
        )}
      </Formik>
    );
  };

  const renderContent = () => {
    return (
      <ScrollView
        contentContainerStyle={{ flexGrow: 1, paddingHorizontal: 20 }}
      >
        {renderUserPhoto()}
        {renderInputFields()}
      </ScrollView>
    );
  };

  return (
    <ApSafeAreaView>
      {renderHeader()}
      {renderContent()}
    </ApSafeAreaView>
  );
};

export default EditPersonalInfo;
