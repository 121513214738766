import { t } from "i18next";
import React from "react";
import { ScrollView, TouchableOpacity } from "react-native";
import { theme } from "../../constants";
import { TRADING_PLATFORMS } from "../../constants/master";
import { ApText } from "./typography";

interface IProps {
  selected: string;
  onSelect?: (val: any) => void;
}

export const ApPlatformTabs: React.FC<IProps> = ({ selected, onSelect }) => {
  return (
    <ScrollView
      horizontal={true}
      showsHorizontalScrollIndicator={false}
      style={{ display: "flex", flexDirection: "row", paddingVertical: 5 }}
    >
      {TRADING_PLATFORMS.map((p, index) => (
        <TouchableOpacity
          key={`${index}`}
          disabled={!p.enabled}
          onPress={() => onSelect(p.value)}
          style={{
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 50,
            paddingVertical: 3,
            marginRight: 30
          }}
        >
          <ApText
            style={{
              ...theme.FONTS.Mulish_400Regular,
              fontSize: 14,
              color: p.enabled ? "#F37021" : "grey",
              lineHeight: 14 * 1.6,
              fontWeight: selected === p.value ? "bold" : "normal",
              borderBottomWidth: selected === p.value ? 2 : 2,
              borderBottomColor: selected === p.value ? "#F37021" : "#fff",
            }}
          >
            {t(p.label)}
          </ApText>
        </TouchableOpacity>
      ))}
    </ScrollView>
  );
};
