import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ScrollView, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { components } from "../components";
import { ApEmptyState, ApLoader, ApSafeAreaView } from "../components/v1";
import { ApEmptyStateSvg } from "../components/v1/empty";
import { ApText } from "../components/v1/typography";
import { useProfileState, useTransactionState } from "../context";
import { IConvertHistory } from "../models/transaction.interface";

const ConverHistory: React.FC = ({ navigation, route }: any) => {
  const wallet_type = route.params?.wallet_type && route.params?.wallet_type;
  const { profileData, getProfileData } = useProfileState();
  const { convertHistory, getConvertHistory, loading, selectedWallet } =
    useTransactionState();
  const [sortConvertHistory, setSortConvertHistory] = useState([]);
  const [sort, setSort] = useState<Record<string, IConvertHistory[]>>({});
  const { i18n, t } = useTranslation();
  const [selected, setSelected] = useState("CreditBalance");
  const dispatch = useDispatch();
  const hideProfit = useSelector(
    (state: any) => state.hideProfitSlice.hideProfit
  );

  useEffect(() => {
    getConvertHistory({ wallet_type: selectedWallet }).then((res) => {
      setSort(sortConvertHistoryByDate(res as unknown as IConvertHistory[]));
    });
  }, []);

  const sortConvertHistoryByDate = (
    dataObjects: IConvertHistory[]
  ): Record<string, IConvertHistory[]> => {
    const sortedDataObjects: Record<string, IConvertHistory[]> = {};

    dataObjects.forEach((dataObject) => {
      dataObject.date = new Date(dataObject.created_at);
      // Get the year, month, and day of the current data object's date property
      const year = dataObject.date.getFullYear().toString();
      const month = (dataObject.date.getMonth() + 1)
        .toString()
        .padStart(2, "0");
      const day = dataObject.date.getDate().toString().padStart(2, "0");
      const dateKey = `${year}-${month}-${day}`;

      // If the sortedDataObjects object doesn't have an array for this date, create one
      if (!sortedDataObjects[dateKey]) {
        sortedDataObjects[dateKey] = [];
      }

      // Add the current data object to the array for this date
      sortedDataObjects[dateKey].push(dataObject);
    });

    return sortedDataObjects;
  };

  useEffect(() => {
    const dates = Object.keys(sort);
    setSortConvertHistory(dates as any);
  }, [sort]);

  const renderHeader = () => {
    return (
      <components.Header
        title={t("Convert History")}
        titleTranlateKey={
          selectedWallet === "ddb_wallet"
            ? "StakeHistory"
            : selectedWallet === "stake_wallet"
            ? "UnStakeHistory"
            : "ConvertHistory"
        }
        goBack={true}
        screenName="MyAssets"
      />
    );
  };

  const renderContent = () => {
    return (
      <ScrollView
        contentContainerStyle={{
          flexGrow: 1,
          paddingBottom: 20,
        }}
      >
        <View style={{ flex: 1 }}>
          {sortConvertHistory.length > 0 ? (
            sortConvertHistory.map((date) => (
              <View key={date}>
                <ApText
                  style={{
                    color: "#F37021",
                    fontSize: 17,
                    padding: 15,
                    fontWeight: "500",
                    paddingTop: 25,
                  }}
                >
                  {date}
                </ApText>
                <View
                  style={{
                    paddingHorizontal: 15,
                    backgroundColor: "#FCF8F6",
                    margin: 15,
                    borderRadius: 10,
                    marginTop: 0,
                    marginBottom: 0,
                  }}
                >
                  {sort[date].map((e, index) => (
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        paddingVertical: 15,
                        borderBottomWidth:
                          index + 1 === sort[date].length ? 0 : 1,
                        borderColor: "#e4e4e499",
                      }}
                    >
                      <View style={{ width: "40%" }}>
                        <ApText style={{ fontWeight: "500", fontSize: 14 }}>
                          {e.created_at.split(" ")[1]}
                        </ApText>
                      </View>
                      <View style={{ width: "55%", alignItems: "flex-end" }}>
                        <ApText
                          style={{
                            fontWeight: "500",
                            color: "#222",
                            textAlign: "right",
                            fontSize: 14,
                          }}
                        >
                          -{e.amount}
                          {selectedWallet === "ddb_wallet" ||
                          selectedWallet === "stake_wallet"
                            ? " DDC"
                            : selectedWallet === "cash_wallet"
                            ? " USDT"
                            : " DL Point"}
                        </ApText>
                      </View>
                    </View>
                  ))}
                </View>
              </View>
            ))
          ) : (
            <ApEmptyState
              svg={<ApEmptyStateSvg.List />}
              heading="No Result Found"
              message="Conversion history is empty"
            />
          )}
        </View>
      </ScrollView>
    );
  };

  return (
    <ApSafeAreaView>
      {renderHeader()}
      {loading && (
        <View style={{ paddingTop: 10 }}>
          <ApLoader />
        </View>
      )}
      {!loading && renderContent()}
    </ApSafeAreaView>
  );
};

export default ConverHistory;
