import * as React from "react";
import Svg, { Defs, LinearGradient, Stop, Path } from "react-native-svg";

function RecyleBinSvg(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width={13} height={14.95} viewBox="0 0 13 14.95" {...props}>
      <Defs>
        <LinearGradient id="a" x1={0.5} x2={0.5} y2={1} gradientUnits="objectBoundingBox">
          <Stop offset={0} stopColor="#ff1900" />
          <Stop offset={1} stopColor="#e45f50" />
        </LinearGradient>
      </Defs>
      <Path data-name="Path 62" d="M16.35 1.65H11.8a.65.65 0 00-.65-.65h-1.3a.65.65 0 00-.65.65H4.65a.65.65 0 000 1.3h11.7a.65.65 0 100-1.3z" transform="translate(-4 -1)" fill="#ff1900" />
      <Path data-name="Path 63" d="M17.4 9a.65.65 0 00-.65.65v10.4H8.3V9.65a.65.65 0 10-1.3 0V20.7a.65.65 0 00.65.65h9.75a.65.65 0 00.65-.65V9.65A.65.65 0 0017.4 9z" transform="translate(-6.025 -6.4)" fill="#ff1900" />
      <Path data-name="Path 64" d="M18.3 23.5v-5.85a.65.65 0 10-1.3 0v5.85a.65.65 0 001.3 0z" transform="translate(-12.775 -11.8)" fill="#ff1900" />
      <Path data-name="Path 65" d="M28.3 23.5v-5.85a.65.65 0 10-1.3 0v5.85a.65.65 0 001.3 0z" transform="translate(-19.525 -11.8)" fill="#ff1900" />
    </Svg>
  );
}

export default RecyleBinSvg;