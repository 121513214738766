import { Formik, FormikProps } from "formik";
import React, {
  Dispatch,
  SetStateAction,
  memo,
  useEffect,
  useRef,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import {
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View
} from "react-native";
import { ApModalAlt } from "../components/Modal";
import { ApButton } from "../components/v1";
import {
  ApSettingsInput,
  ApToggleInput
} from "../components/v1/input";
import { ApText } from "../components/v1/typography";
import { theme } from "../constants";
import { useTradingContext } from "../context";
import { svg } from "../svg";

interface IProps {
  setVisible?: Dispatch<SetStateAction<boolean>>;
  visible?: boolean;
  // trendMarginSettings: any
  // setTrendMarginSettings: any,
  // set_ontrend_init_fund: any
}

const OnTrendMargin: React.FC<IProps> = memo(({ setVisible, visible }) => {
  const { i18n, t } = useTranslation();
  const formRef = useRef<FormikProps<any>>();
  const [err, setErr] = useState<boolean>(false);
  const [disableMultiplier, setDisableMultiplier] = useState<boolean>(false);

  const {
    saveOriginal,
    evalOnTrendValues,
    setEvalOnTrendValues,
    set_ontrend_init_fund,
    trendMarginSettings,
    setTrendMarginSettings,
    ontrend_init_fund,
    setEntryOrderErr,
  } = useTradingContext();

  useEffect(() => {
    setDisableMultiplier(
      ontrend_init_fund.filter((item) => item != "1").length == 0 ? true : false
    );
  }, []);

  const renderHeader = () => {
    return (
      <View
        style={{
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          height: 47,
          backgroundColor: "#FCF8F6",
        }}
      >
        <View
          style={{
            position: "absolute",
            left: 0,
            alignItems: "center",
          }}
        >
          <TouchableOpacity
            style={{
              paddingHorizontal: 20,
              paddingVertical: 12,
            }}
            onPress={() => setVisible(false)}
          >
            <svg.GoBackSvg goBackColor="#F37021" />
          </TouchableOpacity>
        </View>

        <ApText
          translateKey="OnTrendMarginSettings"
          style={{
            textAlign: "center",
            fontSize: 16,
            // fontWeight: 500,
            color: "#F37021",
          }}
        >
          On Trend Margin Settings
        </ApText>
      </View>
    );
  };

  const handleSave = (val: any) => {
    setEntryOrderErr(false);
    setErr(false);

    setTrendMarginSettings(
      val?.items?.map((v, i) => ({ ...v, multiplier: v[v.name] }))
    );
    set_ontrend_init_fund(val?.items?.map((v, i) => v[v.name]));

    saveOriginal({
      TrendMarginSettings: val?.items?.map((v, i) => ({
        ...v,
        multiplier: v[v.name].toString(),
      })),
      ontrend_init_fund: val?.items?.map((v, i) => v[v.name].toString()),
    });
    setVisible(false);
  };

  const handelEvalValues = (e) => {
    if (ontrend_init_fund?.filter((item) => item != "1").length == 0) {
      if (e == true) {
        setEvalOnTrendValues(
          trendMarginSettings.map((item: any, index) => ({
            ...item,
            [item.name]: index + 2,
          }))
        );
      } else {
        setDisableMultiplier(true);
        setEvalOnTrendValues(
          trendMarginSettings.map((item: any) => ({
            ...item,
            [item.name]: item.multiplier,
          }))
        );
      }
    } else {
      if (e == false) {
        setDisableMultiplier(true);
        setEvalOnTrendValues(
          trendMarginSettings.map((item: any) => ({ ...item, [item.name]: 1 }))
        );
      } else {
        setEvalOnTrendValues(
          trendMarginSettings.map((item: any) => ({
            ...item,
            [item.name]: item.multiplier,
          }))
        );
      }
    }
  };

  const renderContent = () => {
    return (
      <View style={{ flex: 1, position: "relative" }}>
        <View
          style={{
            margin: 15,
            borderRadius: 10,
            marginTop: 0,
            flex: 0.92,
          }}
        >
          <Formik
            enableReinitialize
            innerRef={formRef as any}
            //validationSchema={FormSchema}
            initialValues={{
              // trm_multiplier_1: null,
              // ...initValues,
              items: evalOnTrendValues
                ? evalOnTrendValues
                : trendMarginSettings.map((item: any) => ({
                    ...item,
                    [item.name]: item.multiplier,
                  })),
            }}
            onSubmit={(val) => handleSave(val)}
          >
            {(props: FormikProps<any>) => (
              <>
                <ApToggleInput
                  // disabled={disableMultiplier}
                  onToggle={(e) => {
                    setDisableMultiplier(e == true ? false : true);
                    handelEvalValues(e);
                  }}
                  noBorder={true}
                  labelTranslateKey="OnTrendMarginControl"
                  defaultValue={
                    ontrend_init_fund.filter((item) => item != "1").length == 0
                      ? false
                      : true
                  }
                  label="On Trend Margin Control"
                  name="on_trend_margin_control"
                  labelStyle={style.inputLabel}
                />

                {/* <View
                    style={{
                      marginTop: 10,
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      paddingBottom: 5,
                    }}
                  >
                    <ApText
                      translateKey="OrderList"
                      style={{ color: "#959595", fontSize: 13 }}
                    >
                      Order List
                    </ApText>
                    <ApText
                      style={{ color: "#959595", fontSize: 13, textAlign: 'center' }}
                      translateKey="OnTrendMarginMultiplier"
                    >
                      On Trend Margin Multiplier
                    </ApText>
                  </View> */}

                <View style={{}}>
                  {props?.values?.items?.map((item: any, index: number) => (
                    <View
                      key={item?.orderList}
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        paddingVertical: 20,
                        borderBottomColor: "#e6e6e6",
                        borderBottomWidth: 1,
                      }}
                    >
                      <View style={{}}>
                        {index == 0 && (
                          <ApText
                            translateKey="OrderList"
                            style={{
                              color: "#959595",
                              fontSize: 13,
                              marginBottom: 20,
                              alignSelf: "center",
                            }}
                          >
                            Order List
                          </ApText>
                        )}

                        <View style={{ display: "flex", flexDirection: "row" }}>
                          <ApText
                            translateKey="Order"
                            style={{ color: "#F37021", fontSize: 12 }}
                          >
                            Order
                          </ApText>
                          <ApText style={{ color: "#F37021", fontSize: 12 }}>
                            {" "}
                            #{parseInt(item?.orderList) + 1}
                          </ApText>
                        </View>
                      </View>

                      <View style={{}}>
                        {index == 0 && (
                          <ApText
                            style={{
                              color: "#959595",
                              fontSize: 13,
                              marginBottom: 20,
                              alignSelf: "center",
                            }}
                            translateKey="OnTrendMarginMultiplier"
                          >
                            On Trend Margin Multiplier
                          </ApText>
                        )}

                        <View style={{ alignSelf: "flex-end" }}>
                          <ApSettingsInput
                            disabled={disableMultiplier == false ? true : false}
                            width={100}
                            height={25}
                            rightIcon={
                              <ApText
                                translateKey="Times"
                                style={{
                                  color: "#F37021",
                                  fontSize: 12,
                                  marginLeft: 10,
                                  textTransform: "lowercase",
                                  // width:"60%"
                                }}
                              >
                                times
                              </ApText>
                            }
                            // defaultValue={item?.multiplier}
                            noBorder
                            //labelRight="Volatility Price Percentage"
                            //labelStyle={style.inputLabel}
                            name={`items[${index}].${item?.name}`}
                            inputStyle={style.textInput}
                          />
                        </View>
                      </View>
                    </View>
                  ))}
                </View>

                <View style={{ marginTop: 20 }}>
                  <ApButton
                    round="lg"
                    label="Save"
                    labelTranslateKey="Save"
                    onPress={() => props.handleSubmit()}
                  />
                </View>

                {/* <TouchableOpacity
                      style={{
                        backgroundColor: "#F37021",
                        width: "100%",
                        height: 40,
                        borderRadius: 8,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onPress={() => props.handleSubmit()}
                      //onPress={() => navigation.navigate("")}
                    >
                      <ApText
                        style={{ color: theme.COLORS.white, fontWeight: "500" }}
                      >
                        Save
                      </ApText>
                    </TouchableOpacity> */}
              </>
            )}
          </Formik>
        </View>
      </View>
    );
  };

  return (
    <ApModalAlt
      fallbackRoute="EditStrategy"
      visible={visible}
      onDismiss={() => {
        setVisible(false);
      }}
    >
      <ScrollView style={{ flex: 1 }}>
        {renderHeader()}
        {renderContent()}
      </ScrollView>
    </ApModalAlt>
  );
});

export default OnTrendMargin;

const style = StyleSheet.create({
  inputLabel: {
    ...theme.FONTS.H6,
    color: theme.COLORS.mainDark,
    // width: "50%",
  },
  textInput: {
    backgroundColor: "#DFE3EA",
    width: 80,
    color: "#F37021",
    textAlign: "center",
    fontSize: 13,
    padding: 3,
    borderRadius: 6,
  },
});
