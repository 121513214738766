import * as React from "react";
import Svg, { G, Path } from "react-native-svg";

function InportSvg(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width={22.415} height={22.414} viewBox="0 0 22.415 22.414" {...props}>
      <G fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}>
        <Path data-name="Path 125" d="M12 2a10 10 0 1010 10" strokeMiterlimit={10} transform="translate(-1 -.586)" />
        <G data-name="Group 2838">
          <Path data-name="Path 126" d="M22 2l-8.2 8.2" transform="translate(-1 -.586)" />
          <Path data-name="Path 127" d="M13 6.17V11h4.83" transform="translate(-1 -.586)" />
        </G>
      </G>
    </Svg>
  );
}

export default InportSvg;
