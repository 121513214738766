import { Formik, FormikProps } from "formik";
import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { View } from "react-native";
import { components } from "../components";
import { ApButton, ApCheckInput, ApSafeAreaView } from "../components/v1";
import { ApText } from "../components/v1/typography";
import { useProfileState } from "../context";

const AllowedDeduction = () => {
  const formRef = useRef<FormikProps<any>>();
  const renderHeader = () => {
    return <components.Header title={t("AllowedDeduction")} goBack={true} />;
  };

  const { profileData, updateAllowedDeductionWallets, loading } =
    useProfileState();
  const [initValues, setInitValues] = useState<any>();
  useEffect(() => {
    let obj = {};
    profileData.allowed_deduction_wallets.split(",").forEach((item) => {
      obj = {
        ...obj,
        [item]: true,
        credit_wallet: true,
        bonus_wallet: true,
      };
    });
    setInitValues(obj);
  }, []);

  const handleSave = (val: any) => {
    let names = Object.keys(val);
    updateAllowedDeductionWallets({
      allowed_deduction_wallets: names
        .filter((item) => val[item] === true)
        .toString(),
    });
  };

  return (
    <ApSafeAreaView>
      {renderHeader()}
      <View style={{ paddingVertical: 20, paddingHorizontal: 20 }}>
        <ApText style={{ fontSize: 16 }} translateKey="TradeFeeDeductionMsg" />
        <View>
          <Formik
            enableReinitialize
            innerRef={formRef as any}
            initialValues={{ ...initValues }}
            onSubmit={handleSave}
          >
            {(props: FormikProps<any>) => (
              <View style={{ marginTop: 40 }}>
                <View style={{ marginBottom: 30 }}>
                  <View style={{ marginBottom: 30 }}>
                    <ApCheckInput
                      defaultValue={
                        profileData.allowed_deduction_wallets
                          .split(",")
                          .includes("ddb_wallet")
                          ? true
                          : false
                      }
                      noBorder
                      name="ddb_wallet"
                      label={`${t("DDToken")} (${profileData.ddb_wallet})`}
                      labelStyle={{ fontSize: 15, marginLeft: 10 }}
                    />
                  </View>

                  <View style={{ marginBottom: 30 }}>
                    <ApCheckInput
                      disabled
                      defaultValue={
                        // profileData.allowed_deduction_wallets
                        //   .split(",")
                        //   .includes("bonus_wallet")
                        //   ? true
                        //   : false
                        true
                      }
                      noBorder
                      name="bonus_wallet"
                      label={`${t("BonusPoint")} (${profileData.bonus_wallet})`}
                      labelStyle={{ fontSize: 15, marginLeft: 10 }}
                      labelTranslateKey=""
                    />
                  </View>

                  <View style={{ marginBottom: 30 }}>
                    <ApCheckInput
                      disabled
                      defaultValue={
                        // profileData.allowed_deduction_wallets
                        //   .split(",")
                        //   .includes("credit_wallet")
                        //   ? true
                        //   : false
                        true
                      }
                      noBorder
                      name="credit_wallet"
                      label={`${t("CreditPoint")} (${
                        profileData.credit_wallet
                      })`}
                      labelStyle={{ fontSize: 15, marginLeft: 10 }}
                    />
                  </View>

                  <View style={{ marginBottom: 30 }}>
                    <ApCheckInput
                      defaultValue={
                        profileData.allowed_deduction_wallets
                          .split(",")
                          .includes("cash_wallet")
                          ? true
                          : false
                      }
                      noBorder
                      name="cash_wallet"
                      label={`USDT (${profileData.cash_wallet})`}
                      labelStyle={{ fontSize: 15, marginLeft: 10 }}
                    />
                  </View>
                </View>

                <ApButton
                  loading={loading}
                  disabled={loading}
                  label="Save"
                  round="lg"
                  type="primary"
                  labelTranslateKey="Save"
                  onPress={props.handleSubmit}
                />
              </View>
            )}
          </Formik>
        </View>
      </View>
    </ApSafeAreaView>
  );
};

export default AllowedDeduction;
