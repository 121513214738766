import React from "react";
import {
  Image,
  ScrollView,
  View
} from "react-native";

import { components } from "../components";
import { ApSafeAreaView } from "../components/v1";
import { ApText } from "../components/v1/typography";
import { theme } from "../constants";

const ForgotPasswordSentEmail: React.FC = ({ navigation }: any) => {
  const renderContent = () => {
    return (
      <ScrollView contentContainerStyle={{ marginBottom: 70, flexGrow: 1 }}>
        <Image
          source={require("../../assets/bg-02.png")}
          style={{ width: "100%", height: 530, position: "absolute" }}
        />
        <View
          style={{
            paddingTop: theme.SIZES.height * 0.2,
            paddingHorizontal: 20,
          }}
        >
          <Image
            source={require("../../assets/reset.png")}
            style={{
              width: 161,
              height: 111,
              alignSelf: "center",
              marginBottom: 30,
            }}
          />
          <ApText
            style={{
              textAlign: "center",
              ...theme.FONTS.H2,
              color: theme.COLORS.mainDark,
              marginBottom: 20,
            }}
          >
            Your password{"\n"}has been reset!
          </ApText>
          <ApText
            style={{
              textAlign: "center",
              ...theme.FONTS.Mulish_400Regular,
              fontSize: 16,
              color: theme.COLORS.bodyTextColor,
              lineHeight: 16 * 1.6,
              marginBottom: theme.SIZES.height * 0.2,
            }}
          >
            Qui ex aute ipsum duis. Incididunt{"\n"}adipisicing voluptate
            laborum
          </ApText>
          <components.Button
            title="Done"
            onPress={() => navigation.navigate("SignIn")}
          />
        </View>
      </ScrollView>
    );
  };

  return <ApSafeAreaView>{renderContent()}</ApSafeAreaView>;
};

export default ForgotPasswordSentEmail;
