import { Formik, FormikProps } from "formik";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { ScrollView, StyleSheet, TouchableOpacity, View } from "react-native";
import { RefreshControl } from "react-native-web-refresh-control";
import * as Yup from "yup";
import { components } from "../components";
import {
  ApEmptyState,
  ApLoader,
  ApModal,
  ApSafeAreaView,
} from "../components/v1";
import { ApDatePicker } from "../components/v1/input";
import { ApText } from "../components/v1/typography";
import { theme } from "../constants";

import { useRoute } from "@react-navigation/native";
import {
  ConfigContextProvider,
  PnlContextProvider,
  useConfigState,
  usePnlState,
} from "../context";
import { svg } from "../svg";
import { toCurrency } from "../utils";
import TokenList from "./TokenList";

const DailyProfit: React.FC = ({ navigation }: any) => {
  const { params }: any = useRoute();
  const [toggleFilter, setToggleFilter] = useState(false);
  const { loading, getPnlDetail, pnlDetail, filter, setFilter } = usePnlState();
  const [refreshing, setRefreshing] = useState(false);
  useEffect(() => {
    getPnlDetail({
      ...params,
      page: 1,
      limit: 2000,
    });

    return () => {
      setFilter(null);
    };
  }, []);

  useEffect(() => {
    if (filter) {
      getPnlDetail({
        ...filter,
        symbol: !!filter?.symbols?.length ? filter.symbols[0].value : "",
        market: params?.market,
        page: 1,
        limit: 2000,
      }).finally(() => {
        setRefreshing(false);
      });
    }
  }, [filter]);

  const handleRefresh = () => {
    setRefreshing(true);
    getPnlDetail({
      market: params?.market,
      page: 1,
      limit: 2000,
    }).finally(() => {
      setRefreshing(false);
    });
  };

  const renderHeader = () => {
    return (
      <components.Header
        title="Profit Details"
        titleTranlateKey="ProfitDetails"
        goBack={true}
        profitFilter={true}
        toggleFilter={toggleFilter}
        setToggleFilter={() => setToggleFilter(!toggleFilter)}
      />
    );
  };

  const renderContent = () => {
    return (
      <>
        <View style={{ flex: 1 }}>
          <View style={{ backgroundColor: "#fff" }}>
            <TouchableOpacity
              style={{ padding: 15, alignItems: "flex-end" }}
              onPress={() => {
                setToggleFilter(!toggleFilter);
              }}
            >
              <svg.FilterSvg />
            </TouchableOpacity>

            {loading && <ApLoader />}

            {!loading && pnlDetail && !Object.keys(pnlDetail).length && (
              <ApEmptyState heading="PNL" message="No pnl record found" />
            )}

            {!loading &&
              pnlDetail &&
              !!Object.keys(pnlDetail).length &&
              Object.keys(pnlDetail).map((key, index) => {
                const pnl = pnlDetail[key];
                return (
                  <View
                    key={key}
                    style={{
                      padding: 15,
                      backgroundColor: "#FCF8F6",
                      marginHorizontal: 15,
                      borderRadius: 10,
                      marginBottom: 10,
                    }}
                  >
                    <ApText style={{ color: "#F37021", fontSize: 14 }}>
                      {key}
                    </ApText>
                    {pnl.map((pn, index2) => (
                      <View
                        key={index2}
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                          paddingVertical: 10,
                          borderBottomWidth: index2 + 1 !== pnl.length ? 2 : 0,
                          borderColor: "#e4e4e499",
                        }}
                      >
                        <View style={{ width: "40%" }}>
                          <View style={{flexDirection: "row"}}>
                            <ApText style={{ fontWeight: "500", marginRight:5 }}>
                              {pn.symbol}
                            </ApText>
                            {pn.direction ? (
                              <>
                                (
                                <ApText
                                  translateKey={
                                    pn.market === "1" ? (pn.direction === "1" ? "USDT-based" : "Token-based") : (pn.direction === "1" ? "Long" : "Short")
                                  }
                                  style={{
                                    fontWeight: "500",
                                    fontSize: 15,
                                  }}
                                />
                                )
                              </>
                            ) : (
                              <></>
                            )}
                          </View>
                          <ApText
                            style={{
                              marginTop: 5,
                              color: "#ababab",
                              fontSize: 12,
                              fontWeight: "500",
                              lineHeight: 16,
                            }}
                          >
                            {moment(pn.created_at).format("HH:mm:ss")}
                          </ApText>
                          <ApText
                            style={{
                              marginTop: 5,
                              color: "#ababab",
                              fontSize: 12,
                              fontWeight: "500",
                              lineHeight: 16,
                            }}
                          >
                            {pn.label_code}
                          </ApText>
                        </View>

                        <ApText
                          style={{
                            width: "50%",
                            fontWeight: "bold",
                            color: "#222",
                            alignSelf: "center",
                            fontSize: 13,
                            textAlign: "right",
                          }}
                        >
                          {toCurrency(+pn.pnl_after_fee, "")} USDT
                        </ApText>
                      </View>
                    ))}
                  </View>
                );
              })}

            <RenderFilter
              show={toggleFilter}
              navigation={navigation}
              toggle={() => {
                setToggleFilter(!toggleFilter);
              }}
            />
          </View>
        </View>
      </>
    );
  };

  return (
    <ApSafeAreaView>
      {renderHeader()}
      <ScrollView
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={handleRefresh} />
        }
      >
        {renderContent()}
      </ScrollView>
    </ApSafeAreaView>
  );
};

export default DailyProfit;

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    justifyContent: "space-around",
    // marginHorizontal: 8,
  },
  inputContainer: {
    marginHorizontal: 8,
  },
  small: {
    flex: 1,
  },
  formContainer: {
    flex: 1,
  },
  form: {
    paddingVertical: 24,
    paddingHorizontal: 20,
  },
  textInput: {
    borderWidth: 1,
    borderRadius: 4,
    borderColor: "rgba(0, 0, 0, 0.16)",
    paddingHorizontal: 8,
    height: 46,
    fontSize: 16,
    textAlignVertical: "center",
    marginBottom: 20,
  },
});

const RenderFilter: React.FC<{
  show: boolean;
  navigation: any;
  toggle: () => void;
}> = ({ navigation, toggle, show }) => {
  const { params }: any = useRoute();

  const [symbols, setSymbols] = useState<any[]>(
    params.symbol && [{ value: params.symbol, label: params.symbol }]
  );
  const { filter, setFilter } = usePnlState();
  const [toggleSymbols, setToggleSymbols] = useState(false);
  return (
    <ApModal
      show={show}
      onDimiss={() => {
        toggleSymbols ? setToggleSymbols(!toggleSymbols) : toggle();
      }}
      dialogStyle={
        toggleSymbols && { width: "100%", flex: 1, overflow: "scroll" }
      }
    >
      <ConfigContextProvider>
        <PnlContextProvider>
          <FilterContent
            filter={filter}
            setFilter={setFilter}
            toggle={toggle}
            symbols={symbols}
            setSymbols={setSymbols}
            toggleSymbols={toggleSymbols}
            setToggleSymbols={setToggleSymbols}
          />
        </PnlContextProvider>
      </ConfigContextProvider>
    </ApModal>
  );
};

const FormSchema = Yup.object().shape({
  to_date: Yup.string().nullable(),
  from_date: Yup.string().nullable(),
});

const FilterContent = ({
  toggle,
  filter,
  setFilter,
  symbols,
  setSymbols,
  toggleSymbols,
  setToggleSymbols,
}: any) => {
  const { getConfig } = useConfigState();

  const formRef = useRef<FormikProps<any>>();

  useEffect(() => {
    getConfig();
  }, []);

  return (
    <>
      {!toggleSymbols ? (
        <Formik
          innerRef={formRef as any}
          validationSchema={FormSchema}
          initialValues={{
            from_date: filter?.from_date,
            to_date: filter?.to_date,
          }}
          onSubmit={(values) => {
            toggle();
            setFilter({ ...values, symbols });
          }}
        >
          {(props: FormikProps<any>) => (
            <View
              style={{
                alignItems: "center",
                paddingHorizontal: 6,
                flex: 1,
                flexDirection: "column",
              }}
            >
              <ApText
                style={{
                  color: "#F37021",
                  fontWeight: "500",
                  marginBottom: 10,
                  fontSize: 18,
                }}
                translateKey="Filter"
              />

              <View
                style={{
                  ...styles.row,
                  borderBottomColor: "#e3e3e3",
                  paddingBottom: 15,
                  marginBottom: 15,
                  marginTop: 10,
                  borderBottomWidth: 1,
                }}
              >
                <ApDatePicker
                  label="From"
                  translateKey="From"
                  containerStyle={{ marginRight: 10 }}
                  onConfirm={() => {}}
                  onCancel={() => {}}
                  name="from_date"
                />

                <ApDatePicker
                  label="To"
                  translateKey="To"
                  onConfirm={() => {}}
                  onCancel={() => {}}
                  name="to_date"
                />
              </View>

              <View style={{ alignSelf: "flex-start", width: "100%" }}>
                <View style={{ flexDirection: "row" }}>
                  <ApText
                    style={{ color: "#737373", marginBottom: 10 }}
                    translateKey="TokenList"
                  />
                  <ApText style={{ color: "#737373", marginBottom: 10 }}>
                    :
                  </ApText>
                </View>
                <TouchableOpacity
                  onPress={() => {
                    setToggleSymbols(true);
                  }}
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundColor: "#FCF8F6",
                    padding: 10,
                    borderRadius: 10,
                  }}
                >
                  <ApText>
                    {symbols?.map((s: any) => s.value)?.toString() || "All"}
                  </ApText>
                  <svg.ArrowThreeSvg />
                </TouchableOpacity>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <TouchableOpacity
                  style={{
                    borderWidth: 1,
                    borderColor: "#F37021",
                    width: theme.SIZES.width / 2.05 - 115,
                    height: 38,
                    borderRadius: 5,
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: 15,
                    marginRight: 10,
                  }}
                  onPress={() => {
                    props.setValues({ from_date: "", to_date: "" });
                    setSymbols([]);
                  }}
                >
                  <ApText style={{ fontWeight: "500" }} translateKey="Reset" />
                </TouchableOpacity>
                <TouchableOpacity
                  style={{
                    backgroundColor: "#F37021",
                    width: theme.SIZES.width / 2.05 - 115,
                    height: 38,
                    borderRadius: 5,
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: 15,
                    marginLeft: 10,
                  }}
                  onPress={() => {
                    props.handleSubmit();
                  }}
                >
                  <ApText
                    style={{ color: theme.COLORS.white, fontWeight: "500" }}
                    translateKey="Confirm"
                  />
                </TouchableOpacity>
              </View>
            </View>
          )}
        </Formik>
      ) : (
        <TokenList
          multiSelect={false}
          selectedItems={symbols?.map((s) => ({
            key: s.value,
            value: s.value,
          }))}
          onDimiss={(symbols) => {
            setSymbols(symbols);
            setToggleSymbols(false);
          }}
        />
      )}
    </>
  );
};
