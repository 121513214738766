import AsyncStorage from "@react-native-async-storage/async-storage";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Dimensions,
  Image,
  ImageBackground,
  ScrollView,
  TouchableOpacity,
  View,
} from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import Modal from "react-native-modal";
import * as Progress from "react-native-progress";
import { useDispatch } from "react-redux";
import { components } from "../components";
import { ApEmptyStateSvg, ApLoader, ApSafeAreaView } from "../components/v1";
import { ApText } from "../components/v1/typography";
import { theme } from "../constants";
import { useConfigState, useProfileState } from "../context";
import { IRank } from "../models";
import { IProfileRank } from "../models/profile.interface";
import ApiCall from "../utils/ApiCall";
const screenWidth = Dimensions.get("window");
const Ranking: React.FC = () => {
  const {
    getProfileData,
    profileData,
    getRankProgressInfo,
    rankProgressInfo,
    loading,
  } = useProfileState();
  const { config, getConfig } = useConfigState();
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [selectedCard, setSelectedCard] = useState(i18n.language);
  //const [profileData, setProfileData] = useState({});
  const [ranks, setRanks] = useState<IProfileRank[]>([]);
  const [progress, setProgress] = useState<number>();
  const [myRank, setMyRank] = useState<IRank>();
  useEffect(() => {
    getProfileData();
    getRankProgressInfo();
    getConfig();
  }, []);

  useEffect(() => {
    const myRank = config?.ranks?.find((r) => r.id === profileData.rank);
    const nextRank = config?.ranks?.find(
      (r) => +r.id === +(profileData?.rank as any) + 1
    );

    const ranks: IProfileRank[] = [];
    if (nextRank !== undefined) {
      if (myRank?.id == "0") {
        ranks.push({
          type: "membership",
          label: `Purchase Membership | 购买年卡`,
          en: "Purchase Membership",
          zh_CN: "购买年卡",
          progress:
            +(profileData?.self_invt_amt as any) >= +nextRank.req_self_invt
              ? 1
              : 0,
          requirement: 1,
          passed:
            +(profileData?.self_invt_amt as any) >= +nextRank.req_self_invt,
        });
      }

      if (+nextRank.req_ds > 0) {
        ranks.push({
          type: "direct_sponsor",
          label: `${nextRank.req_ds} Direct Referral | ${nextRank.req_ds} 位直属粉丝`,
          en: `${nextRank.req_ds} Direct Referral`,
          zh_CN: `${nextRank.req_ds} 位直属粉丝`,
          progress: +(rankProgressInfo.ds as any),
          requirement: +nextRank.req_ds,
          passed: +(rankProgressInfo?.ds as number) >= nextRank.req_ds,
        });
      }

      if (nextRank.req_prev_rank_team > 0) {
        ranks.push({
          type: "prev_rank",
          label: `${nextRank.req_prev_rank_team} Group with at least Rank ${myRank?.id} | ${nextRank.req_prev_rank_team} 个团队达到至少${myRank?.id}等级`,
          en: `${nextRank.req_prev_rank_team} Group with at least Rank ${myRank?.id}`,
          zh_CN: `${nextRank.req_prev_rank_team} 个团队达到至少${myRank?.id}等级`,
          progress: +(rankProgressInfo?.group_rank as any),
          requirement: nextRank.req_prev_rank_team,
          passed:
            +(rankProgressInfo?.group_rank as number) >=
            +nextRank.req_prev_rank_team,
        });
      }
    }
    const progress = ranks?.length
      ? ranks
          .map((r) => (r.progress / r.requirement) * 50)
          .reduce((a, b) => a + b)
      : 0;

    setRanks(ranks);
    setMyRank(myRank);
    setProgress(progress / 100);
  }, [rankProgressInfo, config]);

  const profilePic = () => {
    if (profileData.profile_img) {
      return (
        <Image
          source={{ uri: profileData.profile_img }}
          style={{
            width: 70,
            height: 70,
            borderRadius: 35,
            marginRight: 16,
          }}
        />
      );
    } else {
      return (
        <Image
          source={require("../../assets/users/08.png")}
          style={{
            width: 70,
            height: 70,
            borderRadius: 35,
            marginRight: 16,
          }}
        />
      );
    }
  };

  const renderHeader = () => {
    return (
      <components.Header
        title="Ranking"
        titleTranlateKey="Ranking"
        goBack={true}
      />
    );
  };

  const languageSwitch = async (item: any) => {
    if (selectedCard !== item.id) {
      setSelectedCard(item.id);
      i18n.changeLanguage(item.id);
      AsyncStorage.setItem("language", item.id);

      const body = new FormData();
      body.append("language", item.languageCode);

      ApiCall.post("/profile/setLanguage", body).then((response) => {});
    }
    setShowInfoModal(false);
  };

  const renderUserInfo = () => {
    return (
      <View
        style={{
          paddingHorizontal: 20,
          paddingVertical: 20,
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {profilePic()}
        <View>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <ApText
              numberOfLines={1}
              style={{
                fontWeight: "bold",
                color: "#222",
                fontSize: 14,
                paddingRight: 10,
                marginBottom: 5,
              }}
            >
              {profileData?.fname}
            </ApText>
          </View>

          <View style={{ flexDirection: "row" }}>
            <View style={{ flexDirection: "row" }}>
              <ApText
                numberOfLines={1}
                style={{
                  fontWeight: "500",
                  color: "#999999",
                  fontSize: 12,
                  lineHeight: 12 * 1.4,
                }}
                translateKey="UID"
              />
              <ApText
                numberOfLines={1}
                style={{
                  fontWeight: "500",
                  color: "#999999",
                  fontSize: 12,
                  lineHeight: 12 * 1.4,
                  paddingRight: 5,
                }}
              >
                :
              </ApText>
            </View>
            <ApText
              numberOfLines={1}
              style={{
                fontWeight: "500",
                color: "#999999",
                fontSize: 12,
                lineHeight: 12 * 1.4,
              }}
            >
              {profileData?.id}
            </ApText>
          </View>

          <View style={{ flexDirection: "row" }}>
            <View style={{ flexDirection: "row" }}>
              <ApText
                numberOfLines={1}
                style={{
                  fontWeight: "500",
                  color: "#999999",
                  fontSize: 12,
                  lineHeight: 12 * 1.4,
                }}
                translateKey="Nickname"
              />
              <ApText
                numberOfLines={1}
                style={{
                  fontWeight: "500",
                  color: "#999999",
                  fontSize: 12,
                  lineHeight: 12 * 1.4,
                  paddingRight: 5,
                }}
              >
                :
              </ApText>
            </View>
            <ApText
              numberOfLines={1}
              style={{
                fontWeight: "500",
                color: "#999999",
                fontSize: 12,
                lineHeight: 12 * 1.4,
              }}
            >
              {profileData?.nickname}
            </ApText>
          </View>
          <View style={{ flexDirection: "row" }}>
            <View style={{ flexDirection: "row" }}>
              <ApText
                numberOfLines={1}
                style={{
                  fontWeight: "500",
                  color: "#999999",
                  fontSize: 12,
                  lineHeight: 12 * 1.4,
                }}
                translateKey="MembershipUntil"
              />
              <ApText
                numberOfLines={1}
                style={{
                  fontWeight: "500",
                  color: "#999999",
                  fontSize: 12,
                  lineHeight: 12 * 1.4,
                  paddingRight: 5,
                }}
              >
                :
              </ApText>
            </View>
            <ApText
              numberOfLines={1}
              style={{
                fontWeight: "500",
                color: "#999999",
                fontSize: 12,
                lineHeight: 12 * 1.4,
              }}
            >
              {profileData?.subscription_expiry ? (
                profileData?.subscription_expiry?.split(" ")[0]
              ) : (
                <ApText translateKey="NotAvailable" />
              )}
            </ApText>
          </View>
        </View>
      </View>
    );
  };

  const languages = [
    {
      id: "en",
      language: "English",
      cardNumber: "**** **** **** 7895",
      cardAmount: "4 863.27",
      cardCurrency: "USD",
      cardIcon: require("../../assets/cards/06.png"),
      languageCode: "english",
    },
    {
      id: "zh_CN",
      language: "简体中文",
      cardNumber: "**** **** **** 8456",
      cardAmount: "2 156.35",
      cardCurrency: "EUR",
      cardIcon: require("../../assets/cards/04.png"),
      languageCode: "simplified-chinese",
    },
    {
      id: "zh_TW",
      language: "繁體中文",
      cardNumber: "**** **** **** 8456",
      cardAmount: "2 156.35",
      cardCurrency: "EUR",
      cardIcon: require("../../assets/cards/04.png"),
      languageCode: "traditional-chinese",
    },
  ];

  const renderInfoModal = () => {
    return (
      <Modal
        isVisible={showInfoModal}
        onBackdropPress={() => setShowInfoModal(false)}
        hideModalContentWhileAnimating={true}
        backdropTransitionOutTiming={0}
        style={{ margin: 0 }}
      >
        <View
          style={{
            backgroundColor: theme.COLORS.bgColor,
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
            position: "absolute",
            width: theme.SIZES.width,
            bottom: 0,
            paddingHorizontal: 20,
            paddingTop: 30,
            paddingBottom: 54,
          }}
        >
          <ApText
            style={{
              textTransform: "capitalize",
              marginBottom: 20,
              textAlign: "center",
              ...theme.FONTS.H4,
              color: theme.COLORS.mainDark,
            }}
          >
            {t("Language")}
          </ApText>
          {languages.map((item, index, array) => {
            return (
              <TouchableOpacity
                style={{
                  backgroundColor: theme.COLORS.white,
                  borderRadius: 10,
                  padding: 12,
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: array.length - 1 === index ? 0 : 6,
                }}
                key={index}
                onPress={() => languageSwitch(item)}
              >
                {/* <Image
                  source={require("../../assets/cards/06.png")}
                  style={{
                    width: 72,
                    height: 46,
                    marginRight: 14,
                  }}
                /> */}
                <View style={{ marginRight: "auto" }}>
                  <ApText
                    style={{
                      ...theme.FONTS.Mulish_600SemiBold,
                      fontSize: 14,
                      lineHeight: 14 * 1.6,
                      color: theme.COLORS.mainDark,
                    }}
                  >
                    {item.language}
                  </ApText>
                </View>
                <View
                  style={{
                    width: 18,
                    height: 18,
                    borderWidth: 2,
                    borderRadius: 9,
                    borderColor: "#CED6E1",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {selectedCard === item.id && (
                    <View
                      style={{
                        width: 10,
                        height: 10,
                        borderRadius: 10 / 2,
                        backgroundColor: theme.COLORS.green,
                      }}
                    />
                  )}
                </View>
              </TouchableOpacity>
            );
          })}
        </View>
      </Modal>
    );
  };

  const renderProfileCategory = () => {
    return (
      <View style={{ paddingHorizontal: 15, paddingTop: 0 }}>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            paddingBottom: 6,
          }}
        >
          <ApText
            style={{
              fontSize: 14,
              fontWeight: "bold",
              color: "#FE7F00",
              width: "10%",
            }}
            translateKey={`Ranks.${profileData.rank ? profileData.rank : 0}`}
          />
          <Progress.Bar
            style={{ height: "fit-content", width: "80%" }}
            progress={progress}
            /// width={screenWidth}
            color={"#FE7F00"}
            unfilledColor={"#FFDFD6"}
            borderWidth={0}
            borderRadius={10}
          />
          <ApText
            style={{
              fontSize: 14,
              fontWeight: "bold",
              color: "#FE7F00",
              width: "10%",
              textAlign: "right",
            }}
            translateKey={`Ranks.${
              profileData.rank ? parseInt(profileData.rank as string) + 1 : 0
            }`}
          />
        </View>

        <View style={{ paddingTop: 35 }}>
          <View
            style={{
              borderBottomWidth: 1,
              borderColor: "#d7d7d7",
              paddingBottom: 16,
            }}
          >
            <ApText
              style={{
                textAlign: "center",
                fontWeight: "500",
                fontSize: 17,
                paddingBottom: 15,
              }}
              translateKey="MembershipBenefits"
            />
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                paddingBottom: 18,
              }}
            >
              <View
                style={{
                  borderRadius: 8,
                  borderWidth: 1,
                  borderColor: "#FE7F00",
                  padding: 3,
                  marginRight: 15,
                }}
              >
                <Image
                  source={require("../../assets/icons/Insurance.png")}
                  style={{ width: 30, height: 30 }}
                />
              </View>
              <View style={{ display: "flex" }}>
                <ApText
                  style={{
                    color: "grey",
                    fontSize: 12,
                    lineHeight: 12,
                    paddingTop: 10,
                  }}
                  translateKey="RankingBonus"
                />

                <ApText>{myRank?.rank_bonus_perc * 100}%</ApText>
              </View>
            </View>

            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                paddingBottom: 18,
              }}
            >
              <View
                style={{
                  borderRadius: 8,
                  borderWidth: 1,
                  borderColor: "#FE7F00",
                  padding: 3,
                  marginRight: 15,
                }}
              >
                <Image
                  source={require("../../assets/icons/Insurance.png")}
                  style={{ width: 30, height: 30 }}
                />
              </View>
              <View style={{ display: "flex" }}>
                <ApText
                  style={{
                    color: "grey",
                    fontSize: 12,
                    lineHeight: 12,
                    paddingTop: 10,
                  }}
                  translateKey="PeerBonus"
                />

                <ApText>{myRank?.peer_bonus_perc * 100}%</ApText>
              </View>
            </View>

            {/* render pools */}
            <RenderPools />
          </View>

          <View style={{ paddingTop: 20 }}>
            <ApText
              style={{
                textAlign: "left",
                fontWeight: "500",
                fontSize: 17,
                paddingBottom: 15,
              }}
              translateKey="PromotionConditions"
            />

            {ranks.map((r, i) => (
              <View>
                <View
                  style={{
                    borderRadius: 8,
                    paddingHorizontal: 8,
                    paddingVertical: 10,
                    backgroundColor: "#f4f4f4",
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: 15,
                  }}
                >
                  <View
                    style={{
                      borderRadius: 8,
                      borderWidth: 1,
                      borderColor: "#FE7F00",
                      padding: 3,
                      marginRight: 8,
                    }}
                  >
                    <Image
                      source={require("../../assets/icons/Insurance.png")}
                      style={{ width: 30, height: 30 }}
                    />
                  </View>

                  <View
                    style={{
                      flex: 1,
                      width: "100%",
                      flexDirection: "column",
                      marginTop: 4,
                      justifyContent: "space-between",
                      height: "100%",
                    }}
                  >
                    <ApText
                      style={{
                        flex: 1,
                        color: "grey",
                        fontSize: 9,
                        lineHeight: 12,
                      }}
                    >
                      {r[i18n?.language]}
                    </ApText>

                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <ApText style={{ marginRight: 10, fontSize: 10 }}>
                        ({r.progress} / {r.requirement})
                      </ApText>

                      <ApText
                        style={{
                          backgroundColor: r.passed ? "green" : "red",
                          color: "white",
                          fontSize: 10,
                          lineHeight: 12,
                          paddingHorizontal: 5,
                          paddingVertical: 2,
                          borderRadius: 100,
                        }}
                      >
                        {r.passed ? (
                          <ApText translateKey="Complete" />
                        ) : (
                          <ApText translateKey="InComplete" />
                        )}
                      </ApText>
                    </View>
                  </View>
                </View>
              </View>
            ))}
          </View>
        </View>
      </View>
    );
  };

  const RenderPools = () => {
    return (
      <>
        {rankProgressInfo?.pool &&
          Object.keys(rankProgressInfo?.pool)
            .reverse()
            .map((key) => {
              const pool = (rankProgressInfo?.pool as any)[key];

              return (
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    paddingBottom: 18,
                  }}
                  key={key}
                >
                  <View
                    style={{
                      borderRadius: 8,
                      borderWidth: 1,
                      borderColor: "#FE7F00",
                      padding: 3,
                      marginRight: 15,
                    }}
                  >
                    <Image
                      source={require("../../assets/icons/Insurance.png")}
                      style={{ width: 30, height: 30 }}
                    />
                  </View>
                  <View style={{ display: "flex" }}>
                    <ApText
                      style={{
                        color: "grey",
                        fontSize: 12,
                        lineHeight: 12,
                        paddingTop: 10,
                      }}
                    >
                      {t("Pool")} {key} ({pool.qualified_dl_count}/
                      {myRank?.pool_req_ds})
                    </ApText>

                    <ApText>
                      {pool.eligibility ? (
                        <ApText translateKey="Eligible" />
                      ) : (
                        <ApText translateKey="NotEligible" />
                      )}
                    </ApText>
                  </View>
                </View>
              );
            })}
      </>
    );
  };

  const renderContent = () => {
    return (
      <ImageBackground
        source={require("../../assets/bg/08.png")}
        imageStyle={{
          width: "100%",
          // backgroundColor: "#fff5f0ba",
        }}
        style={{ flex: 1 }}
      >
        <KeyboardAwareScrollView
          contentContainerStyle={{
            flexGrow: 1,
            paddingHorizontal: 20,
            justifyContent: "center",
            marginTop: 35,
            marginBottom: 70,
          }}
        >
          <View
            style={{
              backgroundColor: theme.COLORS.white,
              borderRadius: 10,
            }}
          >
            {!profileData ? (
              <ApEmptyStateSvg.RankingComponentSvg />
            ) : (
              <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
                {renderUserInfo()}
                {renderProfileCategory()}
              </ScrollView>
            )}
          </View>
        </KeyboardAwareScrollView>
      </ImageBackground>
    );
  };

  return (
    <ApSafeAreaView>
      {renderHeader()}
      {loading && <ApLoader />}
      {!loading && renderContent()}
      {renderInfoModal()}
    </ApSafeAreaView>
  );
};

export default Ranking;
