import * as React from "react";

export const StrategyComponentSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 245.508 245.508"
    width={64}
    height={64}
    fill="#d9d9d9"
    stroke="#d9d9d9"
    strokeWidth={0.001}
    xmlSpace="preserve"
  >
    <path d="M40.922 80.258c17.644 0 31.999-14.354 31.999-31.999S58.567 16.26 40.922 16.26 8.923 30.614 8.923 48.259s14.355 31.999 31.999 31.999zm164.095 18.813c-17.696 0-32.094 14.397-32.094 32.094 0 17.696 14.397 32.093 32.094 32.093 17.696 0 32.093-14.396 32.093-32.093 0-17.696-14.397-32.094-32.093-32.094zm-54.729 114.104 19.529-19.53a7.498 7.498 0 0 0-.001-10.606 7.5 7.5 0 0 0-10.606.001l-19.528 19.529-19.53-19.529a7.498 7.498 0 0 0-10.606.001 7.498 7.498 0 0 0 .001 10.606l19.529 19.528-19.529 19.53a7.498 7.498 0 0 0 .001 10.606 7.475 7.475 0 0 0 5.303 2.196 7.478 7.478 0 0 0 5.304-2.197l19.528-19.529 19.53 19.529a7.477 7.477 0 0 0 5.303 2.196 7.5 7.5 0 0 0 5.303-12.804l-19.531-19.527zm40.944-152.053a7.499 7.499 0 0 0 2.298 10.354 7.497 7.497 0 0 0 10.354-2.298l22.411-35.198a7.499 7.499 0 0 0-2.298-10.354c-.412-.263-35.525-22.451-35.525-22.451a7.5 7.5 0 0 0-8.057 12.652L200.479 26.6a275.842 275.842 0 0 0-121.324 73.972C43.35 138.025 18.935 184.819 8.55 235.897a7.5 7.5 0 1 0 14.7 2.988c9.82-48.299 32.901-92.543 66.749-127.947a260.846 260.846 0 0 1 113.896-69.703l-12.663 19.887z" />
  </svg>
);
