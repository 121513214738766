import React, { useEffect, useState } from "react";
import {
  ScrollView,
  View
} from "react-native";
// import Swiper from "react-native-swiper";
import { components } from "../components";
import { ApLoader, ApSafeAreaView } from "../components/v1";
import { ApText } from "../components/v1/typography";
import { useTradingContext } from "../context";

const BotActionLog: React.FC = ({ navigation, route }: any) => {
  const bex_id = route.params.bex_id;
  const [botactionlog, setbotactionlog] = useState([]);
  const { loading, actionLogs, getActionLogs } = useTradingContext();

  useEffect(() => {
    getActionLogs(bex_id);
  }, []);

  const renderHeader = () => {
    return (
      <components.Header
        title="Bot Action Log"
        titleTranlateKey="BotActionLog"
        goBack={true}
      />
    );
  };

  const renderContent = () => {
    return (
      <View
        style={{
          paddingHorizontal: 15,
          borderRadius: 10,
          paddingVertical: 10,
        }}
      >
        {loading ? (
          <ApLoader />
        ) : (
          <ScrollView>
            {actionLogs.map((e: any) => (
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingVertical: 15,
                  borderBottomWidth: 1,
                  borderColor: "#e4e4e499",
                }}
              >
                <View >
                  <ApText style={{ fontSize: 13 }}>{e.created_at}</ApText>
                </View>

                <View
                  style={{
                    // width: "20%",
                    marginLeft: "auto",
                    alignSelf: "center",
                    alignItems: "flex-end",
                  }}
                >
                  <ApText style={{ color: "#F37021", fontSize: 13 }}>
                    {e?.label}
                  </ApText>
                </View>
              </View>
            ))}
          </ScrollView>
        )}
      </View>
    );
  };

  return (
    <ApSafeAreaView>
      <ScrollView
        contentContainerStyle={{
          flexGrow: 1,
        }}
        showsVerticalScrollIndicator={false}
        bounces={false}
      >
        {renderHeader()}
        {renderContent()}
      </ScrollView>
    </ApSafeAreaView>
  );
};

export default BotActionLog;
