import React from "react";
import { TouchableOpacity, View } from "react-native";
import { ApModalAlt } from "../../../../components/Modal";
import { ApText } from "../../../../components/v1/typography";
import { theme } from "../../../../constants";
import { useTradingContext } from "../../../../context";
import { svg } from "../../../../svg";

export const TerminateBotModal = ({
  setShowTermModal,
  showTermModal,
  bex_id,
}) => {
  const { botExecutorStrategySummary, terminateBot, getBotExecutorDetails } = useTradingContext();

  const termBot = (payload) => {
    terminateBot(payload)
      .then((rs) => {
        getBotExecutorDetails(bex_id);
      })
      .catch((err) => {
        getBotExecutorDetails(bex_id);
      });
  };

  return (
    <ApModalAlt transparent={true} visible={showTermModal}>
      <>
        <TouchableOpacity
          onPress={() => {
            setShowTermModal(false);
          }}
          style={{ alignItems: "flex-end", padding: 15, paddingBottom: 0 }}
        >
          <svg.closeSvg />
        </TouchableOpacity>

        <View
          style={{
            paddingTop: 0,
            paddingVertical: 20,
            alignItems: "center",
            paddingHorizontal: 20,
          }}
        >
          <ApText
            size="lg"
            style={{
              marginTop: -5,
              color: "#F37021",
              fontWeight: "bold",
              marginBottom: 5,
            }}
            translateKey="Notice"
          />
          <ApText
            size="sm"
            style={{ textAlign: "center" }}
            translateKey="ConfirmTerminate"
          />

            
          {botExecutorStrategySummary.plan_type == '1' && (
            <ApText
              size="sm"
              style={{ textAlign: "center", marginTop: 5 }}
              translateKey="TerminateHint"
            />
          )}

          <View
            style={{
              width: "100%",
              marginTop: 30,
              flexDirection: "row",
              //paddingVertical: 20,
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <TouchableOpacity
              style={{
                marginRight: 10,
                borderWidth: 1,
                borderColor: "#F37021",
                width: "47%",
                height: 38,
                borderRadius: 5,
                alignItems: "center",
                justifyContent: "center",
              }}
              onPress={() => {
                setShowTermModal(false);
              }}
            >
              <ApText
                style={{ color: "#F37021", fontWeight: "500" }}
                translateKey="Cancel"
              />
            </TouchableOpacity>

            <TouchableOpacity
              style={{
                backgroundColor: "#F37021",
                width: "47%",
                height: 38,
                borderRadius: 5,
                alignItems: "center",
                justifyContent: "center",
              }}
              onPress={() => {
                termBot({ bex_ids: bex_id });
                setShowTermModal(false);
              }}
            >
              <ApText
                style={{ color: theme.COLORS.white, fontWeight: "500" }}
                translateKey="Confirm"
              />
            </TouchableOpacity>
          </View>
        </View>
      </>
    </ApModalAlt>
  );
};
