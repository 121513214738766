import { isArray } from "underscore";
import { IBotPlan } from "../models";

export class TradeSettingSvc {
  public static orderSettings(index: number, item?: IBotPlan) {
    const set_sizes_1 = isArray(item?.set_sizes_1)
    ? item.set_sizes_1
    : item?.set_sizes_1.split(",");
    const set_sizes_2 = isArray(item?.set_sizes_2)
      ? item?.set_sizes_2
      : item?.set_sizes_2.split(",");
    const set_return = isArray(item?.set_return)
      ? item?.set_return
      : item?.set_return.split(",");

    const outOfScope = index > set_sizes_1.length - 1;

    return {
      orderList: index + 1,
      set_sizes_1_name: `set_sizes_1_val_${index + 1}`,
      set_sizes_2_name: `set_sizes_2_val_${index + 1}`,
      set_return_name: `set_return_val_${index + 1}`,
      set_sizes_1: outOfScope 
      ? set_sizes_1[set_sizes_1.length - 1]
      : set_sizes_1[index],
      set_sizes_2: outOfScope
      ? set_sizes_2[set_sizes_2.length - 1]
      : set_sizes_2[index],
      set_return: outOfScope
      ? set_return[set_return.length - 1] 
      : set_return[index],
    };
  }

  public static trendMarginSettings(index: number, item?: IBotPlan) {
    return {
      orderList: index + 1,
      name: `trm_multiplier_${index + 1}`,
      multiplier: item?.ontrend_init_fund?.split(",")[index + 1],
    };
  }

  public static marginSettings(index: number, item?: IBotPlan) {
    const entry_price_diff = isArray(item?.entry_price_diff)
      ? item.entry_price_diff
      : item?.entry_price_diff.split(",");
    const entry_trailing = isArray(item?.entry_trailing)
      ? item?.entry_trailing
      : item?.entry_trailing.split(",");
    const entry_size = isArray(item?.entry_size)
      ? item?.entry_size
      : item?.entry_size.split(",");
    const outOfScope = index > entry_price_diff.length - 1;

    // debugger;

    return {
      orderList: index + 1,
      m_name: `m_multiplier_${index + 1}`,
      pd_name: `m_price_difference_${index + 1}`,
      tr_name: `m_trailling_reentry_${index + 1}`,
      multiplier: outOfScope
        ? entry_size[entry_size.length - 1]
        : entry_size[index + 1],
      priceDifference: outOfScope
        ? entry_price_diff[entry_price_diff.length - 1]
        : entry_price_diff[index + 1],
      traillingReentry: outOfScope
        ? entry_trailing[entry_trailing.length - 1]
        : entry_trailing[index + 1],
    };
  }

  public static macdModeSettings(index: number, item?: IBotPlan) {
    const entry_macd_type = isArray(item?.entry_macd_type)
      ? item.entry_macd_type
      : item?.entry_macd_type.split(",");

    const outOfScope = index > entry_macd_type.length - 1;

    return {
      orderList: index + 1,
      name: `macd_mode_${index + 1}`,
      entry_macd_type: outOfScope
        ? entry_macd_type[entry_macd_type.length - 1]
        : entry_macd_type[index],
    };
  }

  public static klingIntervalSettings(index: number, item?: IBotPlan) {
    const entry_ta_ema4 = isArray(item?.entry_ta_ema4)
      ? item.entry_ta_ema4
      : item?.entry_ta_ema4.split(",");
    const entry_ta_macd = isArray(item?.entry_ta_macd)
      ? item?.entry_ta_macd
      : item?.entry_ta_macd.split(",");

    const outOfScope = index > entry_ta_ema4.length - 1;

    return {
      orderList: index + 1,
      EMA4_name: `EMA4_name_${index + 1}`,
      MACD_name: `MACD_name_${index + 1}`,
      EMA4: outOfScope
        ? entry_ta_ema4[entry_ta_ema4.length - 1]
        : entry_ta_ema4[index],
      MACD: outOfScope
        ? entry_ta_macd[entry_ta_macd.length - 1]
        : entry_ta_macd[index],
    };
  }

  public static takeProfitModeSettings(index: number, item?: IBotPlan) {
    const entry_tp_mode = isArray(item?.entry_tp_mode)
      ? item.entry_tp_mode
      : item?.entry_tp_mode.split(",");

    const outOfScope = index > entry_tp_mode.length - 1;

    // console.log('outOfScope',outOfScope, entry_tp_mode,'entry_tp_modeentry_tp_mode')

    return {
      orderList: index + 1,
      name: `take_profit_mode_${index + 1}`,
      entry_tp_mode: outOfScope
        ? entry_tp_mode[entry_tp_mode.length - 1]
        : entry_tp_mode[index],
    };
  }

  public static takeProfileSettings(index: number, item?: IBotPlan) {
    const entry_tp_perc = isArray(item?.entry_tp_perc)
      ? item.entry_tp_perc
      : item?.entry_tp_perc.split(",");

    const entry_tp_trailing_perc = isArray(item?.entry_tp_trailing_perc)
      ? item.entry_tp_trailing_perc
      : item?.entry_tp_trailing_perc.split(",");

    const outOfScope = index > entry_tp_perc.length - 1;

    return {
      orderList: index + 1,
      takeProfitName: `take_profit_percentage_${index + 1}`,
      traillingTakeProfitName: `trailling_take_profit_${index + 1}`,
      entry_tp_perc: outOfScope
        ? entry_tp_perc[entry_tp_perc.length - 1]
        : entry_tp_perc[index],
        entry_tp_trailing_perc: outOfScope
        ? entry_tp_trailing_perc[entry_tp_trailing_perc.length - 1]
        : entry_tp_trailing_perc[index],
    };
  }

  public static rsiSettings(index: number, item?: IBotPlan) {
    const entry_ta_rsi = isArray(item?.entry_ta_rsi)
      ? item.entry_ta_rsi
      : item?.entry_ta_rsi.split(",");

    const outOfScope = index > entry_ta_rsi.length - 1;

    return {
      orderList: index + 1,
      name: `rsi_${index + 1}`,
      entry_ta_rsi: outOfScope
        ? entry_ta_rsi[entry_ta_rsi.length - 1]
        : entry_ta_rsi[index],
    };
  }

  public static emaSettings(index: number, item?: IBotPlan) {
    const entry_ta_ema = isArray(item?.entry_ta_ema)
      ? item.entry_ta_ema
      : item?.entry_ta_ema.split(",");

    const outOfScope = index > entry_ta_ema.length - 1;

    return {
      orderList: index + 1,
      name: `rsi_${index + 1}`,
      entry_ta_ema: outOfScope
        ? entry_ta_ema[entry_ta_ema.length - 1]
        : entry_ta_ema[index],
    };
  }

  public static kdjSettings(index: number, item?: IBotPlan) {
    const entry_ta_kdj = isArray(item?.entry_ta_kdj)
      ? item.entry_ta_kdj
      : item?.entry_ta_kdj.split(",");

    const outOfScope = index > entry_ta_kdj.length - 1;

    return {
      orderList: index + 1,
      name: `rsi_${index + 1}`,
      entry_ta_kdj: outOfScope
        ? entry_ta_kdj[entry_ta_kdj.length - 1]
        : entry_ta_kdj[index],
    };
  }
}
