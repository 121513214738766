import { useFocusEffect, useNavigation } from "@react-navigation/native";
import React, { useEffect, useState } from "react";
import { ScrollView, TouchableOpacity, View } from "react-native";
import { RefreshControl } from "react-native-web-refresh-control";
import { components } from "../components";
import { ApEmptyState, ApLoader, ApSafeAreaView } from "../components/v1";
import { ApSearchInput } from "../components/v1/input";
import { ApHtmlText, ApText } from "../components/v1/typography";
import { useProfileState } from "../context";
import { svg } from "../svg";

const TutorialList: React.FC = () => {
  const navigation: any = useNavigation();
  const [tutorials, setTutorials] = useState([]);
  const { getTutorialList, tutorialList, loading } = useProfileState();
  const [refreshing, setRefreshing] = useState(false);
  useEffect(() => {
    getTutorialList();
  }, []);

  useFocusEffect(
    React.useCallback(() => {
      getTutorialList();

      return () => {};
    }, [])
  );

  useEffect(() => {
    setTutorials(tutorialList);
  }, [tutorialList]);

  const handleRefresh = () => {
    setRefreshing(true);
    getTutorialList().finally(() => {
      setRefreshing(false);
    });
  };

  const handleSearch = (val) => {
    if (!val) setTutorials(tutorialList);
    else
      setTutorials(
        tutorialList.filter((t) => t.title?.toLowerCase()?.search(val) >= 1)
      );
  };

  const renderHeader = () => {
    return (
      <components.Header
        title="Tutorial List"
        titleTranlateKey="TutorialList"
        goBack={true}
      />
    );
  };

  const renderContent = () => {
    return (
      <ScrollView
        refreshControl={
          <RefreshControl refreshing={loading} onRefresh={handleRefresh} />
        }
      >
        <View
          style={{
            padding: 10,
            paddingTop: 20,
          }}
        >
          <ApSearchInput leftIcon={<svg.SearchSvg />} onChange={handleSearch} />
        </View>

        <View
          style={{
            flex: 1,
            marginHorizontal: 8,
            paddingHorizontal: 15,
            backgroundColor: "#fff5f0ba",
            borderRadius: 10,
            marginVertical: 8,
          }}
        >
          {!!tutorials.length ? (
            tutorials.map((e: any, index) => (
              <TouchableOpacity
                onPress={() => {
                  navigation.navigate("TutorialDetails", {
                    id: e?.content,
                  });
                }}
                key={e?.id}
              >
                <View
                  style={{
                    paddingTop: 15,
                    paddingBottom: 15,
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderBottomWidth: index !== tutorials.length - 1 && 2,
                    borderColor: "#e4e4e499",
                  }}
                  key={e.id}
                >
                  <View>
                    <ApText numberOfLines={1} style={{ fontWeight: "600" }}>
                      {e?.title}
                    </ApText>
                  </View>

                  <View
                    style={{
                      marginLeft: "auto",
                      alignSelf: "center",
                      alignItems: "flex-end",
                      flexDirection: "row",
                    }}
                  >
                    <svg.ArrowOneSvg />
                  </View>
                </View>
              </TouchableOpacity>
            ))
          ) : (
            <View style={{marginBottom: 20}}>
              <ApEmptyState heading="Tutorial" message="No new tutorial added" />
            </View>
          )}
        </View>
      </ScrollView>
    );
  };

  return (
    <ApSafeAreaView>
      {renderHeader()}
      {loading ? <ApLoader /> : renderContent()}
    </ApSafeAreaView>
  );
};

export default TutorialList;
