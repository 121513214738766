import { StyleProp, TextStyle, useWindowDimensions } from "react-native";
import RenderHTML from "react-native-render-html";
import React, { memo } from 'react'

interface IHtmlProps {
  content: any;
  width?: number;
  style?: StyleProp<TextStyle>;
}

export const ApHtmlText: React.FC<IHtmlProps> = memo(({ content, width }) => {
  if (!content) return <></>;

  const dm = useWindowDimensions();

  return (
    <RenderHTML
      source={{ html: /<\/?[a-z][\s\S]*>/i.test(content) ? content : content }}
      // containerStyle={{width: width || dm.width}}
      contentWidth={width || dm.width}
    />
  );
});
