import React, { useEffect, useState } from "react";
import {
  StyleProp,
  Text,
  TextInput,
  TextStyle,
  View,
  ViewStyle,
} from "react-native";
import { theme } from "../../../constants";
import { FormikProps, useField } from "formik";
import { ApText } from "../typography";
import Slider from "@react-native-community/slider";
import { ApRow } from "../grid";

type Props = {
  innerText?: string | number;
  border?: boolean;
  width?: string | number;
  height?: string | number;
  textArea?: boolean;
  disabled?: boolean;
  defaultValue?: any;
  value?: any;
  onChange?: any;
  name: string;
  label?: string;
  suffix?: string;
  placeholder?: string;
  secureTextEntry?: boolean;
  icon?: any;
  keyboardType?: "default" | "email-address" | "numeric" | "phone-pad";
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  showSoftInputOnFocus?: boolean;
  containerStyle?: StyleProp<ViewStyle>;
  inputStyle?: StyleProp<TextStyle>;
  labelStyle?: TextStyle;
  labelTranslateKey?: string;
};

export const ApLeverageInput: React.FC<Props> = ({
  innerText,
  border,
  width,
  height,
  textArea,
  disabled,
  defaultValue,
  onChange,
  name,
  label,
  value,
  suffix,
  placeholder,
  containerStyle,
  secureTextEntry,
  keyboardType,
  leftIcon,
  rightIcon,
  inputStyle,
  labelStyle,
  labelTranslateKey,
  showSoftInputOnFocus,
}) => {
  const [isFocused, setIsFocused] = useState(0);
  const [field, meta, { setValue }] = useField(name);
  const [sliderVal, setSliderVal] = useState(10);

  const setSlider = (e: string) => {
    if (e !== "") {
      setSliderVal(parseInt(e));
    }
  };

  return (
    <>
      <View
        style={[
          {
            borderBottomWidth: border ? 1 : 0,
            paddingVertical: 15,
            borderBottomColor: "#ced6e182",
            width: "100%",
          },
          containerStyle,
        ]}
      >
        <View
          style={{
            flexDirection: border ? "row" : "column",
            alignItems: border ? "center" : "flex-start",
            justifyContent: border ? "space-between" : "flex-start",
            width: "100%",
          }}
        >
          {border ? (
            <ApText
              size="sm"
              style={labelStyle}
              translateKey={labelTranslateKey}
            >
              {label}
            </ApText>
          ) : (
            <View style={{ width: "100%" }}>
              <View
                style={{
                  width: "100%",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <ApText
                  size="sm"
                  style={labelStyle}
                  translateKey={labelTranslateKey}
                >
                  {label}
                </ApText>
                {meta.touched && meta.error && (
                  <ApText
                    size="xs"
                    style={{
                      color: "red",
                    }}
                  >
                    {meta.error}
                  </ApText>
                )}
              </View>
            </View>
          )}

          <View
            style={[
              {
                backgroundColor: "#FCF8F6",
                width: width ? width : "100%",
                height: height ? height : 45,
                borderRadius: 5,
                //borderWidth: 1,
                //borderColor: '#eee',
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              },
            ]}
          >
            {leftIcon && leftIcon}
            <TextInput
              editable={disabled}
              selectTextOnFocus={disabled}
              placeholder={placeholder}
              style={[
                {
                  paddingLeft: border ? 0 : 7,
                  paddingRight: border ? 0 : 7,
                  color: "#F37021",
                  flex: 1,
                  height: "100%",
                  width: "100%",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  fontSize: 14,
                  lineHeight: 16 * 1,
                  ...theme.FONTS.Mulish_400Regular,
                },
                inputStyle,
              ]}
              secureTextEntry={secureTextEntry}
              placeholderTextColor={"#c6c6c6"}
              keyboardType={keyboardType}
              multiline={textArea}
              numberOfLines={textArea ? 5 : 1}
              onFocus={() => setIsFocused(1)}
              onBlur={() => setIsFocused(0)}
              onChangeText={(e) => {
                setSlider(e);
                setValue(suffix ? e?.replace(suffix, "") : e);
                onChange && onChange(e);
              }}
              value={`${field.value}${suffix || ""}`}
              defaultValue={defaultValue}
              showSoftInputOnFocus={showSoftInputOnFocus}
            />

            {rightIcon && rightIcon}
          </View>
        </View>

        <View>
          {field.value > 20 ?
           <ApText
           translateKey="EvaluatePositionRisk"
           style={{
             ...theme.FONTS.H6,
             color: 'red',
             marginTop: 10,
           }}
         />
         : null
        }
          {/* <Slider
            value={sliderVal}
            onValueChange={(value) => setValue(value.toFixed(0))}
            thumbTintColor="#F37021"
            style={{ width: "100%", height: 40 }}
            minimumValue={1}
            maximumValue={100}
            minimumTrackTintColor="#F37021"
            maximumTrackTintColor="#FFDAC4"
          /> */}

          {/* <ApRow>
            <ApText style={{ fontSize: 12, color: "#F37021" }} translateKey="MaximumFund" />
            <ApText style={{ fontSize: 12, color: "#F37021" }}>
              : 3600.00 USDT
            </ApText>
          </ApRow> */}
        </View>
      </View>
    </>
  );
};
