import React, { useEffect, useRef, useState } from "react";
import {
  ImageBackground,
  StatusBar,
  TouchableOpacity,
  View,
} from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

import AsyncStorage from "@react-native-async-storage/async-storage";
import { Form, Formik, FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import {
  ApButton,
  ApCheckInputAlt,
  ApLangDropdown,
  ApPasswordInput,
  ApSafeAreaView,
  ApTacInput,
  ApTextInput,
} from "../components/v1";
import { ApText } from "../components/v1/typography";
import { theme } from "../constants";
import { ERROR_MSG } from "../constants/errors";
import { LANGUAGES } from "../constants/master";
import { useAuthState } from "../context/auth";
import { svg } from "../svg";
import { COLORS } from "../constants/theme";

const FormSchema = Yup.object().shape({
  account_name: Yup.string()
    .email(ERROR_MSG.InvalidEmail)
    .required(ERROR_MSG.EmailRequired),
  tac: Yup.string()
    .min(6, ERROR_MSG.VerificationCodeMin)
    .required(ERROR_MSG.VerificationCodeRequired),
  sec_password: Yup.string()
    .min(6, ERROR_MSG.MinSecurityPass)
    .required(ERROR_MSG.SecurityPassRequired),
  confirm_sec_password: Yup.string()
    .min(6, ERROR_MSG.MinSecurityPass)
    .required(ERROR_MSG.ConfirmSecurityPassRequired)
    .oneOf([Yup.ref("sec_password")], ERROR_MSG.SecurityPassMismatch),
  password: Yup.string().required(ERROR_MSG.PasswordRequired),
  confirm_password: Yup.string()
    .required(ERROR_MSG.ConfirmPasswordRequired)
    .min(6, ERROR_MSG.MinPassword)
    .oneOf([Yup.ref("password")], ERROR_MSG.PasswordMismatch),
  referral_code: Yup.string(),
  agree: Yup.boolean().oneOf([true], ERROR_MSG.AgreeTermsAndPolicies),
});

const TacFormSchema = Yup.object().shape({
  tac: Yup.string().required(ERROR_MSG.VerificationCodeRequired),
});

const SignUp: React.FC = ({ navigation, route }: any) => {
  const lang = route.params?.language && route.params?.language;
  const { signUp, loading, generateTac } = useAuthState();
  const [user, setUser] = useState<any>();
  const [show, setShow] = useState<boolean>(false);
  const formRef = useRef<FormikProps<any>>();
  const { i18n, t } = useTranslation();

  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(
        LANGUAGES.filter((item) => item.languageCode === lang)[0].id
      );
      AsyncStorage.setItem(
        "language",
        LANGUAGES.filter((item) => item.languageCode === lang)[0].id
      );
    }
  }, []);

  const handleSignUp = async (values: any) => {
    AsyncStorage.getItem("language").then((rs) => {
      if (rs === null) {
        delete values.agree
        signUp({
          language: "english",
          ...values,
        }).then((rs: any) => {
          navigation.navigate("SignIn");
          formRef.current?.resetForm();
        });
      } else {
        delete values.agree
        signUp({
          language: LANGUAGES.filter((item) => item.id === rs)[0].languageCode,
          ...values,
        }).then((rs: any) => {
          navigation.navigate("SignIn");
          formRef.current?.resetForm();
        });
      }
    });
  };

  const renderContent = () => {
    return (
      <ImageBackground
        style={{
          width: theme.SIZES.width,
          height: theme.SIZES.height,
          justifyContent: "flex-end",
        }}
        source={require("../../assets/bg-05.png")}
      >
        <View style={{ paddingHorizontal: 35, paddingBottom: 43 }}>
          <ApText
            style={{
              fontSize: 22,
              color: "white",
              fontWeight: "bold",
              paddingBottom: 5,
            }}
            translateKey="Hello"
          />
          <ApText
            style={{ fontSize: 22, color: "white", fontWeight: "bold" }}
            translateKey="Welcome"
          />
        </View>
        <View
          style={{
            backgroundColor: "white",
            paddingTop: theme.SIZES.height * 0.05,
            paddingBottom: theme.SIZES.height * 0.07,
            paddingHorizontal: 20,
            borderTopLeftRadius: 35,
            borderTopRightRadius: 35,
            maxHeight: "75%",
          }}
        >
          <View
            style={{
              zIndex: 999,
              alignSelf: "flex-start",
              position: "absolute",
              top: 20,
              right: 20,
            }}
          >
            <ApLangDropdown defaultLang={lang} />
          </View>

          <KeyboardAwareScrollView
            style={{ marginTop: 10 }}
            contentContainerStyle={{
              flexGrow: 1,
              paddingHorizontal: 20,
            }}
          >
            {show === false ? (
              <Formik
                innerRef={formRef as any}
                validationSchema={FormSchema}
                initialValues={{
                  tac: "",
                  account_name: user?.account_name || "",
                  referral_code:
                    user?.referral_code || (route?.params as any)?.ref || "",
                  password: user?.password || "",
                  confirm_password: "",
                  sec_password: "",
                  confirm_sec_password: "",
                  agree: false
                }}
                onSubmit={handleSignUp}
              >
                {(props: FormikProps<any>) => (
                  <Form autoComplete="off">
                    <ApTextInput
                      name="account_name"
                      label="Email"
                      labelTranslateKey="Email"
                      placeholder="mail@example.com"
                      placeHolderTranslateKey="EnterEmailAddress"
                      icon={<svg.CheckSvg />}
                      keyboardType={"email-address"}
                      containerStyle={{
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                      }}
                      inputStyle={{
                        paddingLeft: 7,
                        paddingRight: 7,
                        width: "100%",
                      }}
                    />

                    <ApTacInput
                      type="nd"
                      props={props}
                      name="tac"
                      propKey="account_name"
                    />

                    <ApTextInput
                      label="Security Password"
                      name="sec_password"
                      labelTranslateKey="SecPassword"
                      placeholder="••••••"
                      secureTextEntry
                      icon={
                        <TouchableOpacity>
                          <svg.EyeOffSvg />
                        </TouchableOpacity>
                      }
                      containerStyle={{
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                      }}
                      inputStyle={{
                        paddingLeft: 7,
                        paddingRight: 7,
                      }}
                    />

                    <ApTextInput
                      label="Confirm Security Password"
                      name="confirm_sec_password"
                      labelTranslateKey="ConfirmSecPassword"
                      placeholder="••••••"
                      secureTextEntry
                      icon={
                        <TouchableOpacity>
                          <svg.EyeOffSvg />
                        </TouchableOpacity>
                      }
                      containerStyle={{
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                      }}
                      inputStyle={{
                        paddingLeft: 7,
                        paddingRight: 7,
                      }}
                    />

                    <ApPasswordInput
                      label="Password"
                      name="password"
                      labelTranslateKey="Password"
                    />

                    {/* <ApTextInput
                      label="Password"
                      name="password"
                      labelTranslateKey="Password"
                      placeholder="••••••"
                      secureTextEntry
                      icon={
                        <TouchableOpacity>
                          <svg.EyeOffSvg />
                        </TouchableOpacity>
                      }
                      containerStyle={{
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                      }}
                      inputStyle={{
                        paddingLeft: 7,
                        paddingRight: 7,
                      }}
                    /> */}

                    <ApPasswordInput
                      label="Confirm Password"
                      name="confirm_password"
                      labelTranslateKey="ConfirmPassword"
                    />

                    {/* <ApTextInput
                      label="Confirm Password"
                      name="confirm_password"
                      labelTranslateKey="ConfirmPassword"
                      placeholder="••••••"
                      secureTextEntry
                      icon={
                        <TouchableOpacity>
                          <svg.EyeOffSvg />
                        </TouchableOpacity>
                      }
                      containerStyle={{
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                      }}
                      inputStyle={{
                        paddingLeft: 7,
                        paddingRight: 7,
                      }}
                    /> */}

                    <ApTextInput
                      name="referral_code"
                      label="Invitation Code"
                      labelTranslateKey="InvitationCode"
                      placeholder="abc123"
                      placeHolderTranslateKey="EnterInvitationCode"
                      keyboardType={"numeric"}
                      containerStyle={{
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                      }}
                      inputStyle={{
                        paddingLeft: 7,
                        paddingRight: 7,
                      }}
                    />

                    <View style={{marginVertical: 10}}>
                      <ApCheckInputAlt
                        name="agree"
                        label="I agree to the terms and policies"
                        labelTranslateKey="AgreeTermsAndPolicyLabel"
                        noBorder
                        labelStyle={{
                          fontSize: 11,
                          color: "#F37021",
                          marginLeft: "10px",
                        }}
                      />
                    </View>

                    <ApButton
                      label="Register"
                      round="xxl"
                      type="primary"
                      labelTranslateKey="Register"
                      onPress={props.handleSubmit}
                    />

                    <ApButton
                      label="Back"
                      round="xxl"
                      type="light"
                      labelTranslateKey="Back"
                      style={{
                        marginTop: 15,
                        backgroundColor: "#F5F7FB",
                      }}
                      onPress={() => navigation.navigate("SignIn")}
                    />
                  </Form>
                )}
              </Formik>
            ) : (
              <View>
                <Formik
                  innerRef={formRef as any}
                  validationSchema={TacFormSchema}
                  initialValues={{
                    tac: "",
                  }}
                  onSubmit={handleSignUp}
                >
                  {(props: FormikProps<any>) => (
                    <Form>
                      <ApTextInput
                        name="tac"
                        label="Tac"
                        placeholder="34934"
                        labelTranslateKey="VerificationCode"
                        containerStyle={{
                          flexDirection: "column",
                          alignItems: "flex-start",
                          justifyContent: "flex-start",
                        }}
                        inputStyle={{
                          paddingLeft: 7,
                          paddingRight: 7,
                        }}
                      />

                      <ApButton
                        label="Confirm"
                        round="xxl"
                        type="primary"
                        labelTranslateKey="Confirm"
                        onPress={props.handleSubmit}
                      />
                    </Form>
                  )}
                </Formik>
                <ApButton
                  label="Back"
                  round="xxl"
                  type="light"
                  labelTranslateKey="Back"
                  style={{
                    marginTop: 5,
                  }}
                  onPress={() => setShow(false)}
                />
              </View>
            )}
            
            <View 
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                paddingHorizontal: 20,
                marginTop: 20
              }}
            >
              <TouchableOpacity
                onPress={() =>
                  navigation.navigate("Policy" as never)
                }
              >
                  <ApText
                    color={COLORS.primary}
                    style={{
                      fontSize: 13,
                      borderBottomColor: COLORS.primary,
                      borderBottomWidth: 1
                    }}
                    translateKey="PrivacyPolicy"
                  />
              </TouchableOpacity>

              <TouchableOpacity
                onPress={() =>
                  navigation.navigate("TermsAndConditions" as never)
                }
              >
                  <ApText
                    color={COLORS.primary}
                    style={{
                      fontSize: 13,
                      borderBottomColor: COLORS.primary,
                      borderBottomWidth: 1
                    }}
                    translateKey="TermsAndConditions"
                  />
              </TouchableOpacity>
            </View>
          </KeyboardAwareScrollView>
        </View>
      </ImageBackground>
    );
  };

  return (
    <ApSafeAreaView>
      <StatusBar backgroundColor="#E56152" barStyle="light-content" />
      {/* {renderHeader()} */}
      {renderContent()}
    </ApSafeAreaView>
  );
};

export default SignUp;
