import { createStackNavigator } from "@react-navigation/stack";
import React, { useEffect, useState } from "react";
import { screens } from "../screens";
import { APP_ROUTE } from "../constants/route";
import { useConfigState } from "../context";
import { useAuthState } from "../context/auth";
import TabNavigator from "./TabNavigator";

const Stack = createStackNavigator();

const StackNavigator: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const { getConfig } = useConfigState();
  const { isAuthenticated, authenticated } = useAuthState();
  const [initRoute, setInitRoute] = useState(APP_ROUTE.SIGNIN);

  useEffect(() => {
    // getConfig();

    (async () => {
      if (await isAuthenticated()) {
        setInitRoute(APP_ROUTE.HOME);
      }

      setLoading(false);
    })();
  }, []);

  if (loading) return null;

  return (
    <Stack.Navigator
      initialRouteName={initRoute}
      screenOptions={{
        gestureEnabled: false,
        cardStyle: { backgroundColor: "#fff", flex: 1 },
      }}
    >
      <Stack.Screen
        name="SignIn"
        component={screens.SignIn}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="SignUp"
        component={screens.SignUp}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="SignInCode"
        component={screens.SignInCode}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="ForgotPassword"
        component={screens.ForgotPassword}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="ForgotPasswordSentEmail"
        component={screens.ForgotPasswordSentEmail}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="TermsAndConditions"
        component={screens.TermsAndConditions}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="Policy"
        component={screens.Policy}
        options={{ headerShown: false }}
      />

      {authenticated && (
        <>
          <Stack.Screen
            name="Onboarding"
            component={screens.Onboarding}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="Home"
            component={TabNavigator}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="Profile"
            component={screens.Profile}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="Trade"
            component={screens.Trade}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="EditPersonalInfo"
            component={screens.EditPersonalInfo}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="ExchangeRates"
            component={screens.ExchangeRates}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="CardMenu"
            component={screens.CardMenu}
            options={{ headerShown: false }}
          />

          <Stack.Screen
            name="SignUpAccountCreated"
            component={screens.SignUpAccountCreated}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="Statistics"
            component={screens.Statistics}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="StatisticsChooseCard"
            component={screens.StatisticsChooseCard}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="TransactionDetails"
            component={screens.TransactionDetails}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="TransactionHistory"
            component={screens.TransactionHistory}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="BotSettings"
            component={screens.BotSettings}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="FXTradingAccounts"
            component={screens.FXTradingAccounts}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="FXTradingAccount"
            component={screens.FXTradingAccount}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="CopyTradeDetails"
            component={screens.CopyTradeDetails}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="CopyTradeActionLog"
            component={screens.CopyTradeActionLog}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="OpenAccount"
            component={screens.OpenAccount}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="FollowStrategy"
            component={screens.FollowStrategy}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="CopyTrading"
            component={screens.CopyTrading}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="FXTradingCopyTrade"
            component={screens.FXTradingCopyTrade}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="FXTradingCopyTradeDetail"
            component={screens.FXTradingCopyTradeDetail}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="IncomeRanking"
            component={screens.IncomeRanking}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="ContractManual"
            component={screens.ContractManual}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="Follow"
            component={screens.Follow}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="BotDetails"
            component={screens.BotDetails}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="Profit"
            component={screens.Profit}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="ProfitDetails"
            component={screens.ProfitDetails}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="BotActionLog"
            component={screens.BotActionLog}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="BotStrategy"
            component={screens.BotStrategy}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="BotOrder"
            component={screens.BotOrder}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="TradeHistory"
            component={screens.TradeHistory}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="EditStrategy"
            component={screens.EditStrategy}
            options={{ headerShown: false }}
          />
         <Stack.Screen
            name="StrategyDetails"
            component={screens.StrategyDetails}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="TransactionSetting"
            component={screens.TransactionSetting}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="MyEarnings"
            component={screens.MyEarnings}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="VerifyYourPhoneNumber"
            component={screens.VerifyYourPhoneNumber}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="ConfirmationCode"
            component={screens.ConfirmationCode}
            options={{ headerShown: false }}
          />
        
          <Stack.Screen
            name="FAQ"
            component={screens.FAQ}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="Ranking"
            component={screens.Ranking}
            options={{ headerShown: false }}
          />

          <Stack.Screen
            name="FundTransfer"
            component={screens.FundTransfer}
            options={{ headerShown: false }}
          />
      
          <Stack.Screen
            name="NewPassword"
            component={screens.NewPassword}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="OpenMoneybox"
            component={screens.OpenMoneybox}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="PaymentFailed"
            component={screens.PaymentFailed}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="Payments"
            component={screens.Payments}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="PrivacyPolicy"
            component={screens.PrivacyPolicy}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="Transfer"
            component={screens.Transfer}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="Convert"
            component={screens.Convert}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="AllowedDeduction"
            component={screens.AllowedDeduction}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="CreditAlert"
            component={screens.CreditAlert}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="Withdraw"
            component={screens.Withdraw}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="Stake"
            component={screens.Stake}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="Deposit"
            component={screens.Deposit}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="MyAssets"
            component={screens.MyAssets}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="Commission"
            component={screens.Commission}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="AnnouncementList"
            component={screens.AnnouncementList}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="AnnouncementDetails"
            component={screens.AnnouncementDetails}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="UpdateAPIBinding"
            component={screens.UpdateAPIBinding}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="Referral"
            component={screens.Referral}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="Tutorials"
            component={screens.Tutorials}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="Materials"
            component={screens.Materials}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="MaterialsList"
            component={screens.MaterialList}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="API"
            component={screens.API}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="AccountSetting"
            component={screens.AccountSetting}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="MaterialList"
            component={screens.MaterialList}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="TutorialList"
            component={screens.TutorialList}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="TutorialDetails"
            component={screens.TutorialDetails}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="Duobao"
            component={screens.Duobao}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="MyPackages"
            component={screens.MyPackages}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="PackageRoi"
            component={screens.PackageRoi}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="Community"
            component={screens.Community}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="Log"
            component={screens.Log}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="ChangePassword"
            component={screens.ChangePassword}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="ChangeSecurityPin"
            component={screens.ChangeSecurityPin}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="TradingBots"
            component={screens.TradingBots}
            options={{ headerShown: false,  }}
          />
          <Stack.Screen
            name="TradingBotHistory"
            component={screens.TradingBotHistory}
            options={{ headerShown: false,  }}
          />
          <Stack.Screen
            name="TokenList"
            component={screens.TokenList}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="OnTrendMargin"
            component={screens.OnTrendMargin}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="MarginSetting"
            component={screens.MarginSetting}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="TakeProfitModeSetting"
            component={screens.TakeProfitModeSetting}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="TakeProfitSettings"
            component={screens.TakeProfitSettings}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="KlineSetting"
            component={screens.KlineSetting}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="ProfitMode"
            component={screens.ProfitMode}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="ProfitSetting"
            component={screens.ProfitSetting}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="Membership"
            component={screens.Membership}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="MemberPurchase"
            component={screens.MemberPurchase}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="MemberPurchase2"
            component={screens.MemberPurchase2}
            options={{ headerShown: false }}
          />

          <Stack.Screen
            name="StrategyProfit"
            component={screens.StrategyProfit}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="PurchaseHistory"
            component={screens.PurchaseHistory}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="DepositHistory"
            component={screens.DepositHistory}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="TransferHistory"
            component={screens.TransferHistory}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="ConverHistory"
            component={screens.ConverHistory}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="WithdrawHistory"
            component={screens.WithdrawHistory}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="StartBotDetails"
            component={screens.StartBotDetails}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="CommissionDetails"
            component={screens.CommissionDetails}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="PurchaseSuccess"
            component={screens.PurchaseSuccess}
            options={{ headerShown: false }}
          />
        </>
      )}
    </Stack.Navigator>
  );
};

export default StackNavigator;