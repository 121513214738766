import React from "react";
import {  ScrollView, View } from "react-native";
import { components } from "../components";
import { ApSafeAreaView } from "../components/v1";
import { ApText } from "../components/v1/typography";
import { theme } from "../constants";

const TermsAndConditions: React.FC = () => {
  const renderHeader = () => {
    return (
      <components.Header title="Terms And Conditions" titleTranlateKey="TermsAndConditions" goBack={true} />
    );
  };    

  const renderContent = () => {
    return (
      <ScrollView
        contentContainerStyle={{
          flexGrow: 1,
          paddingHorizontal: 20,
          paddingVertical: 20
        }}
      >
        <ApText
            translateKey="TermsAndConditionsTitle1"
            size="sm"
            font="normal"
            style={{
                lineHeight: 20,
                marginBottom: 10
            }}
        />
        <ApText
            translateKey="TermsAndConditions1.1"
            size="sm"
            font="normal"
            style={{
                lineHeight: 20,
                marginBottom: 10
            }}
        />
        <ApText
            translateKey="TermsAndConditions1.2"
            size="sm"
            font="normal"
            style={{
                lineHeight: 20,
                marginBottom: 10
            }}
        />
        <ApText
            translateKey="TermsAndConditions1.3"
            size="sm"
            font="normal"
            style={{
                lineHeight: 20,
                marginBottom: 10
            }}
        />
        <ApText
            translateKey="TermsAndConditions1.4"
            size="sm"
            font="normal"
            style={{
                lineHeight: 20,
                marginBottom: 10
            }}
        />
        <ApText
            translateKey="TermsAndConditions1.5"
            size="sm"
            font="normal"
            style={{
                lineHeight: 20,
                marginBottom: 10
            }}
        />
        <ApText
            translateKey="TermsAndConditions1.6"
            size="sm"
            font="normal"
            style={{
                lineHeight: 20,
                marginBottom: 20
            }}
        />
        <ApText
            translateKey="TermsAndConditionsTitle2"
            size="sm"
            font="normal"
            style={{
                lineHeight: 20,
                marginBottom: 10
            }}
        />
        <ApText
            translateKey="TermsAndConditions2.1"
            size="sm"
            font="normal"
            style={{
                lineHeight: 20,
                marginBottom: 10
            }}
        />
        <ApText
            translateKey="TermsAndConditions2.2"
            size="sm"
            font="normal"
            style={{
                lineHeight: 20,
                marginBottom: 10
            }}
        />
        <ApText
            translateKey="TermsAndConditions2.3"
            size="sm"
            font="normal"
            style={{
                lineHeight: 20,
                marginBottom: 10
            }}
        />
        <ApText
            translateKey="TermsAndConditions2.4"
            size="sm"
            font="normal"
            style={{
                lineHeight: 20,
                marginBottom: 20
            }}
        />
        <ApText
            translateKey="TermsAndConditionsTitle3"
            size="sm"
            font="normal"
            style={{
                lineHeight: 20,
                marginBottom: 10
            }}
        />
        <ApText
            translateKey="TermsAndConditionsIntro3"
            size="sm"
            font="normal"
            style={{
                lineHeight: 20,
                marginBottom: 10
            }}
        />
        <ApText
            translateKey="TermsAndConditions3.1"
            size="sm"
            font="normal"
            style={{
                lineHeight: 20,
                marginBottom: 10
            }}
        />
        <ApText
            translateKey="TermsAndConditions3.2"
            size="sm"
            font="normal"
            style={{
                lineHeight: 20,
                marginBottom: 10
            }}
        />
        <ApText
            translateKey="TermsAndConditions3.3"
            size="sm"
            font="normal"
            style={{
                lineHeight: 20,
                marginBottom: 10
            }}
        />
        <ApText
            translateKey="TermsAndConditions3.4"
            size="sm"
            font="normal"
            style={{
                lineHeight: 20,
                marginBottom: 10
            }}
        />
      </ScrollView>
    );
  };

  return (
    <ApSafeAreaView style={{ flex: 1, backgroundColor: theme.COLORS.bgColor }}>
      {renderHeader()}
      {renderContent()}
    </ApSafeAreaView>
  );
};

export default TermsAndConditions;