import React, { useState } from "react";
import { Image, ScrollView, TouchableOpacity, View } from "react-native";
import Modal from "react-native-modal";

import { useTranslation } from "react-i18next";
import { components } from "../components";
import { ApSafeAreaView } from "../components/v1";
import { ApText } from "../components/v1/typography";
import { theme } from "../constants";
import { svg } from "../svg";

const SystemSetting: React.FC = ({ navigation }: any) => {
  const { i18n, t } = useTranslation();
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [selectedCard, setSelectedCard] = useState(i18n.language);
  const paymentTypes = [
    {
      id: "1",
      type: "My Assets",
      bgColor: "#F37021",
      icon: <svg.TypeCardSvg />,
      onclick: () => navigation.navigate("OpenAccount"),
    },
    {
      id: "2",
      type: "Ranking",
      bgColor: "#FF8A71",
      icon: <svg.HomeTestSvg />,
    },
    {
      id: "3",
      type: "Community",
      bgColor: "#55ACEE",
      icon: <svg.TypeCardSvg />,
      onclick: () => navigation.navigate("Community"),
    },
  ];
  const renderHeader = () => {
    return <components.Header title="Profile" goBack={true} />;
  };

  const renderUserInfo = () => {
    return (
      <View
        style={{
          paddingHorizontal: 20,
          paddingVertical: 20,
          borderBottomWidth: 1,
          borderBottomColor: "#CED6E1",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Image
          source={{
            uri: "https://dl.dropbox.com/s/g61a6dbx2t5adiv/01.jpg?dl=0",
          }}
          style={{
            width: 70,
            height: 70,
            borderRadius: 35,
            marginRight: 16,
          }}
        />
        <View>
          <ApText
            numberOfLines={1}
            style={{
              ...theme.FONTS.H4,
              color: theme.COLORS.mainDark,
              marginBottom: 2,
            }}
          >
            Cristina Wolf
          </ApText>
          <ApText
            numberOfLines={1}
            style={{
              ...theme.FONTS.Mulish_400Regular,
              color: theme.COLORS.bodyTextColor,
              fontSize: 16,
              lineHeight: 16 * 1.6,
            }}
          >
            +1 954 621 7845
          </ApText>
        </View>
      </View>
    );
  };

  const languages = [
    {
      id: "en",
      language: "English",
      cardNumber: "**** **** **** 7895",
      cardAmount: "4 863.27",
      cardCurrency: "USD",
      cardIcon: require("../../assets/cards/06.png"),
    },
    {
      id: "zh_CN",
      language: "简体中文",
      cardNumber: "**** **** **** 8456",
      cardAmount: "2 156.35",
      cardCurrency: "EUR",
      cardIcon: require("../../assets/cards/04.png"),
    },
    {
      id: "zh_TW",
      language: "繁體中文",
      cardNumber: "**** **** **** 8456",
      cardAmount: "2 156.35",
      cardCurrency: "EUR",
      cardIcon: require("../../assets/cards/04.png"),
    },
  ];

  const renderInfoModal = () => {
    return (
      <Modal
        isVisible={showInfoModal}
        onBackdropPress={() => setShowInfoModal(false)}
        hideModalContentWhileAnimating={true}
        backdropTransitionOutTiming={0}
        style={{ margin: 0 }}
      >
        <View
          style={{
            backgroundColor: theme.COLORS.bgColor,
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
            position: "absolute",
            width: theme.SIZES.width,
            bottom: 0,
            paddingHorizontal: 20,
            paddingTop: 30,
            paddingBottom: 54,
          }}
        >
          <ApText
            style={{
              textTransform: "capitalize",
              marginBottom: 20,
              textAlign: "center",
              ...theme.FONTS.H4,
              color: theme.COLORS.mainDark,
            }}
          >
            Choose Language
          </ApText>
          {languages.map((item, index, array) => {
            return (
              <TouchableOpacity
                style={{
                  backgroundColor: theme.COLORS.white,
                  borderRadius: 10,
                  padding: 12,
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: array.length - 1 === index ? 0 : 6,
                }}
                key={index}
                onPress={() => {
                  if (selectedCard !== item.id) {
                    setSelectedCard(item.id);
                    i18n.changeLanguage(item.id);
                  }
                }}
              >
                {/* <Image
                  source={require("../../assets/cards/06.png")}
                  style={{
                    width: 72,
                    height: 46,
                    marginRight: 14,
                  }}
                /> */}
                <View style={{ marginRight: "auto" }}>
                  <ApText
                    style={{
                      ...theme.FONTS.Mulish_600SemiBold,
                      fontSize: 14,
                      lineHeight: 14 * 1.6,
                      color: theme.COLORS.mainDark,
                    }}
                  >
                    {item.language}
                  </ApText>
                </View>
                <View
                  style={{
                    width: 18,
                    height: 18,
                    borderWidth: 2,
                    borderRadius: 9,
                    borderColor: "#CED6E1",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {selectedCard === item.id && (
                    <View
                      style={{
                        width: 10,
                        height: 10,
                        borderRadius: 10 / 2,
                        backgroundColor: theme.COLORS.green,
                      }}
                    />
                  )}
                </View>
              </TouchableOpacity>
            );
          })}
        </View>
      </Modal>
    );
  };

  const renderProfileCategory = () => {
    return (
      <View style={{ paddingHorizontal: 20, paddingVertical: 20 }}>
        <View
          style={{
            top: -10,
            padding: 10,
            paddingHorizontal: 33,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {paymentTypes.map((item, index) => {
            return (
              <TouchableOpacity
                key={index}
                activeOpacity={0.8}
                onPress={item.onclick}
              >
                <View
                  style={{
                    width: 60,
                    height: 60,
                    backgroundColor: item.bgColor,
                    borderRadius: 60 / 2,
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: 8,
                  }}
                >
                  {item.icon}
                </View>
                <ApText
                  style={{
                    textAlign: "center",
                    ...theme.FONTS.Mulish_600SemiBold,
                    fontSize: 10,
                    lineHeight: 10 * 1.2,
                    color: theme.COLORS.bodyTextColor,
                  }}
                >
                  {item.type}
                </ApText>
              </TouchableOpacity>
            );
          })}
        </View>
        {/* <components.ProfileCategory title="Personal Info" icon={<svg.UserOneSvg />} rightElement={<svg.ArrowOneSvg />} onPress={() => navigation.navigate("EditPersonalInfo")} />
        <components.ProfileCategory title="Notifications" icon={<svg.MessageSvg />} toggleButton={true} />
        <components.ProfileCategory title="Face ID" icon={<svg.FaceIdSvg />} toggleButton={true} /> */}
        <components.ProfileCategory
          title="System Setting"
          icon={<svg.FaceIdSvg />}
          rightElement={<svg.ArrowOneSvg />}
          onPress={() => navigation.navigate("SystemSetting")}
        />
        <components.ProfileCategory
          onPress={() => setShowInfoModal(true)}
          title="Language"
          icon={<svg.LanguageSvg />}
          rightElement={
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <ApText
                style={{
                  ...theme.FONTS.Mulish_400Regular,
                  fontSize: 12,
                  color: theme.COLORS.mainDark,
                  lineHeight: 12 * 1.6,
                  marginRight: 11,
                }}
              >
                {languages.find((e) => e.id === i18n.language)?.language}
              </ApText>
              <svg.ArrowOneSvg />
            </View>
          }
        />
        <components.ProfileCategory icon={<svg.LogOutSvg />} />
      </View>
    );
  };

  const renderContent = () => {
    return (
      <ScrollView contentContainerStyle={{ marginBottom: 70, flexGrow: 1 }}>
        {renderUserInfo()}
        {renderProfileCategory()}
      </ScrollView>
    );
  };

  return (
    <ApSafeAreaView>
      {renderHeader()}
      {renderContent()}
      {renderInfoModal()}
    </ApSafeAreaView>
  );
};

export default SystemSetting;
