import { Formik, FormikProps } from "formik";
import React, {
  Dispatch,
  SetStateAction,
  memo,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { ScrollView, StyleSheet, TouchableOpacity, View } from "react-native";
import { ApModalAlt } from "../components/Modal";
import { ApButton } from "../components/v1";
import { ApDropdown, ApSettingsInput } from "../components/v1/input";
import { ApText } from "../components/v1/typography";
import { theme } from "../constants";
import { useTradingContext } from "../context";
import { svg } from "../svg";
import { formatAmt } from "../utils";
import { useMasterData } from "../hooks/master";
import { MasterData } from "../constants/master";

interface IProps {
  setVisible?: Dispatch<SetStateAction<boolean>>;
  visible?: boolean;
  mainForm: React.MutableRefObject<FormikProps<any>>
}

const RsiSetting: React.FC<IProps> = memo(({ visible, setVisible, mainForm }) => {
  const formRef = useRef<FormikProps<any>>();
  const { MasterData } = useMasterData();

  const {
    saveOriginal,
    setRsiSettings,
    rsiSettings,
    set_entry_ta_rsi,
  } = useTradingContext();

  const renderHeader = () => {
    return (
      <View
        style={{
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          height: 47,
          backgroundColor: "#FCF8F6",
        }}
      >
        <View
          style={{
            position: "absolute",
            left: 0,
            alignItems: "center",
          }}
        >
          <TouchableOpacity
            style={{
              paddingHorizontal: 20,
              paddingVertical: 12,
            }}
            onPress={() => setVisible(false)}
          >
            <svg.GoBackSvg goBackColor="#F37021" />
          </TouchableOpacity>
        </View>

        <ApText
          translateKey="RSI"
          style={{
            textAlign: "center",
            fontSize: 16,
            color: "#F37021",
          }}
        >
          RSI
        </ApText>
      </View>
    );
  };

  const handleSave = (val: any) => {
    let itms = [...val.items];
    let rsiVal: any = [];
    itms?.forEach((item: any) => {
      item.entry_ta_rsi = item[`${item.name}`];
      rsiVal = [...rsiVal, item.entry_ta_rsi];
    })

    setRsiSettings(itms);
    set_entry_ta_rsi(rsiVal);
    mainForm?.current?.setValues({
      ...mainForm?.current?.values,
      rsi_type: val?.rsi_type,
      rsi_low: val?.rsi_low,
      rsi_high: val?.rsi_high,
    })
    saveOriginal({
      RsiSettings: itms,
      entry_ta_rsi: rsiVal,
    });
    setVisible(false);
  };

  const renderContent = () => {
    return (
      <View style={{ flex: 1, paddingHorizontal: 15, paddingVertical: 10 }}>
        <Formik
          enableReinitialize
          innerRef={formRef as any}
          initialValues={{
            rsi_type: mainForm?.current?.values?.rsi_type,
            rsi_low: mainForm?.current?.values?.rsi_low,
            rsi_high: mainForm?.current?.values?.rsi_high,
            items: rsiSettings.map((item: any) => ({
              ...item,
              [item.name]: item.entry_ta_rsi,
            })),
          }}
          onSubmit={(val) => handleSave(val)}
        >
          {(props: FormikProps<any>) => (
            <>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 5,
                  zIndex: 9999 
                }}
              >
                <ApText
                  translateKey="Mode"
                  style={{ color: "#F37021", fontSize: 12 }}
                >
                  Mode
                </ApText>

                <View style={{ zIndex: 9000 }}>
                  <ApDropdown
                    style={{ width: 150 }}
                    containerStyle={{
                      justifyContent: "flex-end",
                    }}
                    contPropsHeight={220}
                    noBorder
                    name="rsi_type"
                    items={MasterData?.RSI_TYPES}
                  />
                </View>
              </View>

              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 15,
                  zIndex: -99 
                }}
              >
                <ApText
                  translateKey="UpperBoundary"
                  style={{ color: "#F37021", fontSize: 12 }}
                >
                  Upper Boundary
                </ApText>

                <View>
                    <ApSettingsInput
                        height={25}
                        noBorder
                        name='rsi_high'
                        inputStyle={style.textInput}
                    />
                </View>
              </View>

              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 15,
                  zIndex: -99 
                }}
              >
                <ApText
                  translateKey="LowerBoundary"
                  style={{ color: "#F37021", fontSize: 12 }}
                >
                  Lower Boundary
                </ApText>

                <View>
                    <ApSettingsInput
                        height={25}
                        noBorder
                        name='rsi_low'
                        inputStyle={style.textInput}
                    />
                </View>
              </View>

              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingBottom: 5,
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <ApText
                  translateKey="OrderList"
                  style={{ color: "#959595", fontSize: 13 }}
                >
                  Order List
                </ApText>
                <ApText
                  translateKey="RSIMode"
                  style={{ color: "#959595", fontSize: 13, textAlign: "center" }}
                />
              </View>

              <View>
                {props?.values?.items?.map((item: any, index: number) => {
                  return (
                    <View
                      key={index}
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingVertical: 5,
                        borderBottomColor: "#e6e6e6",
                        borderBottomWidth: 1,
                        zIndex: 4000 - index,
                        elevation: 4000 - index,
                      }}
                    >
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <ApText
                          translateKey="Order"
                          style={{ color: "#F37021", fontSize: 12 }}
                        >
                          Order
                        </ApText>
                        <ApText style={{ color: "#F37021", fontSize: 12 }}>
                          {" "}
                          #{item?.orderList}
                        </ApText>
                      </View>

                      <View style={{ zIndex: 4000 - index,  }}>
                        <ApDropdown
                          style={{ width: 150 }}
                          containerStyle={{
                            justifyContent: "flex-end",
                          }}
                          contPropsHeight={220}
                          useContPropsHeight={
                            index + 1 === rsiSettings.length
                              ? true
                              : false
                          }
                          noBorder
                          name={`items[${index}].${item?.name}`}
                          items={MasterData?.INTERVALS}
                        />
                      </View>
                    </View>
                  );
                })}
              </View>

              <View style={{ marginTop: 20, zIndex: -99 }}>
                <ApButton
                  round="lg"
                  label="Save"
                  labelTranslateKey="Save"
                  onPress={() => props.handleSubmit()}
                />
              </View>
            </>
          )}
        </Formik>
      </View>
    );
  };

  return (
    <ApModalAlt fallbackRoute="EditStrategy" visible={visible}>
      <ScrollView style={{ flex: 1 }}>
        {renderHeader()}
        {renderContent()}
      </ScrollView>
    </ApModalAlt>
  );
});

export default RsiSetting;


const style = StyleSheet.create({
    inputLabel: {
      ...theme.FONTS.H6,
      color: theme.COLORS.mainDark,
      // width: "50%",
    },
  
    textInput: {
      backgroundColor: "#DFE3EA",
      width: 100,
      color: "#F37021",
      textAlign: "center",
      fontSize: 13,
      // padding: 3,
      borderRadius: 6,
    },
});