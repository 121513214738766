import { Formik, FormikProps } from "formik";
import React, { Dispatch, SetStateAction, memo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { ScrollView, StyleSheet, TouchableOpacity, View } from "react-native";
import { ApModalAlt } from "../components/Modal";
import { ApDropdown } from "../components/v1/input";
import { ApText } from "../components/v1/typography";
import { theme } from "../constants";
// import { MasterData } from "../constants/master";
import { ApButton } from "../components/v1";
import { useTradingContext } from "../context";
import { useMasterData } from "../hooks/master";
import { svg } from "../svg";

interface IProps {
  setVisible?: Dispatch<SetStateAction<boolean>>;
  visible?: boolean;
  // klineIntervalSettings: any
  // setKlineIntervalSettings: any
  // set_entry_ta_macd: any
  // set_entry_ta_ema4: any
}

const KlineSetting: React.FC<IProps> = memo(({ setVisible, visible }) => {
  const { i18n, t } = useTranslation();
  const formRef = useRef<FormikProps<any>>();
  const { MasterData } = useMasterData();
  const {
    saveOriginal,
    setExtraSettings,
    klineIntervalSettings,
    setKlineIntervalSettings,
    getBotExecutorStrategySummary,
    active_MACD,
    set_entry_ta_macd,
    set_entry_ta_ema4,
  } = useTradingContext();

  const renderHeader = () => {
    return (
      <View
        style={{
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          height: 47,
          backgroundColor: "#FCF8F6",
        }}
      >
        <View
          style={{
            position: "absolute",
            left: 0,
            alignItems: "center",
          }}
        >
          <TouchableOpacity
            style={{
              paddingHorizontal: 20,
              paddingVertical: 12,
            }}
            onPress={() => setVisible(false)}
          >
            <svg.GoBackSvg goBackColor="#F37021" />
          </TouchableOpacity>
        </View>

        <ApText
          translateKey="KlineIntervalSettings"
          style={{
            textAlign: "center",
            fontSize: 16,
            color: "#F37021",
          }}
        >
          Kline Interval Settings
        </ApText>
      </View>
    );
  };

  const handleSave = (val: any) => {
    let MACD_val: any = [];
    let EMA4_val: any = [];

    val?.items?.forEach((item: any) => {
      item.EMA4 = item[`${item.EMA4_name}`];
      EMA4_val = [...EMA4_val, item.EMA4];

      item.MACD = item[`${item?.MACD_name}`];
      MACD_val = [...MACD_val, item.MACD];
    });

    setKlineIntervalSettings(val?.items);
    set_entry_ta_macd(MACD_val);
    set_entry_ta_ema4(EMA4_val);
    saveOriginal({
      KlineIntervalSettings: val?.items,
      entry_ta_macd: MACD_val,
      entry_ta_ema4: EMA4_val,
    });
    setVisible(false);
  };

  const renderContent = () => {
    return (
      <View style={{ flex: 1 }}>
        <View
          style={{
            margin: 15,
            borderRadius: 10,
            marginTop: 15,
            flex: 0.92,
          }}
        >
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              paddingBottom: 5,
              alignItems: "center",
              width: "100%",
            }}
          >
            <ApText
              translateKey="OrderList"
              style={{ color: "#959595", fontSize: 13 }}
            >
              Order List
            </ApText>
            <ApText
              translateKey="MACD"
              style={{ color: "#959595", fontSize: 13, textAlign: "center" }}
            >
              MACD
            </ApText>
            <ApText
              translateKey="EMA4"
              style={{ color: "#959595", fontSize: 13, textAlign: "center" }}
            >
              EMA4
            </ApText>
          </View>

          <Formik
            innerRef={formRef as any}
            //validationSchema={FormSchema}
            initialValues={{
              items: klineIntervalSettings?.map((item) => ({
                [item.MACD_name]: item?.MACD
                  ? item?.MACD
                  : MasterData?.INTERVALS[0].value,
                [item.EMA4_name]: item?.EMA4
                  ? item?.EMA4
                  : MasterData?.INTERVALS[0].value,
                ...item,
              })),
            }}
            onSubmit={(val) => handleSave(val)}
          >
            {(props: FormikProps<any>) => (
              <>
                <View>
                  {props?.values?.items?.map((item: any, index: number) => {
                    return (
                      <View
                        key={index}
                        style={{
                          flexDirection: "row",
                          alignItems: "flex-start",
                          justifyContent: "space-between",
                          //paddingVertical: 8,
                          paddingBottom: 8,
                          borderBottomColor: "#e6e6e6",
                          borderBottomWidth: 1,
                          zIndex: 4000 - index,
                          elevation: 4000 - index,
                        }}
                      >
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: 18,
                          }}
                        >
                          <ApText
                            translateKey="Order"
                            style={{ color: "#F37021", fontSize: 12 }}
                          >
                            Order
                          </ApText>
                          <ApText style={{ color: "#F37021", fontSize: 12 }}>
                            {" "}
                            #{item?.orderList}
                          </ApText>
                        </View>

                        <View style={{ zIndex: 4000 - index, marginTop: 10 }}>
                          <ApDropdown
                            contPropsHeight={220}
                            useContPropsHeight={
                              index + 1 === klineIntervalSettings.length ||
                              index + 1 === klineIntervalSettings.length - 1
                                ? true
                                : false
                            }
                            noBorder
                            name={`items[${index}].MACD_name_${index + 1}`}
                            items={MasterData?.INTERVALS}
                          />
                        </View>
                        <View style={{ zIndex: 4000 - index, marginTop: 10 }}>
                          <ApDropdown
                            contPropsHeight={220}
                            useContPropsHeight={
                              index + 1 === klineIntervalSettings.length ||
                              index + 1 === klineIntervalSettings.length - 1
                                ? true
                                : false
                            }
                            noBorder
                            name={`items[${index}].EMA4_name_${index + 1}`}
                            items={MasterData?.INTERVALS}
                          />
                        </View>
                      </View>
                    );
                  })}
                </View>

                <View style={{ marginTop: 20, zIndex: -99 }}>
                  <ApButton
                    round="lg"
                    label="Save"
                    labelTranslateKey="Save"
                    onPress={() => props.handleSubmit()}
                  />
                </View>
              </>
            )}
          </Formik>
        </View>
      </View>
    );
  };

  return (
    <ApModalAlt fallbackRoute="EditStrategy" visible={visible}>
      <ScrollView style={{ flex: 1 }}>
        {renderHeader()}
        {renderContent()}
      </ScrollView>
    </ApModalAlt>
  );
});

export default KlineSetting;
const style = StyleSheet.create({
  inputLabel: {
    ...theme.FONTS.H6,
    color: theme.COLORS.mainDark,
    // width: "50%",
  },
  textInput: {
    backgroundColor: "#DFE3EA",
    width: 100,
    color: "#F37021",
    textAlign: "center",
    fontSize: 13,
    padding: 3,
    borderRadius: 6,
  },
});
