import React, { useCallback, useEffect, useRef, useState } from "react";
import { Keyboard, TouchableWithoutFeedback, View } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { useDispatch } from "react-redux";
import { components } from "../components";
import { theme } from "../constants";
import { svg } from "../svg";

import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { ApButton, ApSafeAreaView } from "../components/v1";
import { ApTacInput, ApTextInput } from "../components/v1/input";
import { ApText } from "../components/v1/typography";
import { ERROR_MSG } from "../constants/errors";
import {
  useConfigState,
  useProfileState,
  useTransactionState,
} from "../context";
import { useAuthState } from "../context/auth";

const FormSchema = Yup.object().shape({
  tac: Yup.string().required(ERROR_MSG.VerificationCodeRequired),
  wallet_address: Yup.string().required(ERROR_MSG.WalletAddressRequired),
  amount: Yup.string()
    // .min(10, "Min withdraw amount is 10")
    .required(ERROR_MSG.AmountRequired),
  sec_password: Yup.string().required(ERROR_MSG.SecurityPassRequired),
});

const SignIn: React.FC = ({ navigation, route }: any) => {
  const wallet = route.params?.wallet && route.params?.wallet;
  const { profileData, getProfileData } = useProfileState();
  const { withdrawRequest, loading, selectedWallet, getDDCoin, getUSDT } =
    useTransactionState();
  const { generateTac } = useAuthState();
  const formRef = useRef<FormikProps<any>>();
  const { config, getConfig } = useConfigState();
  const [user, setUser] = useState<any>();
  const [show, setShow] = useState<boolean>(false);
  const [amount, setAmount] = useState("0");
  const [arrival, setArrival] = useState<number>(0);
  const [wFee, setWfee] = useState<number>(0);
  const [load, setLoad] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    getProfileData();
    getConfig();
  }, []);

  useEffect(() => {
    if (selectedWallet === "ddb_wallet") {
      if (amount === "") {
        setWfee(config.ddb_wr_fee_amount + config.ddb_wr_fee * 0);
      } else {
        setWfee(
          config.ddb_wr_fee_amount + config.ddb_wr_fee * parseInt(amount)
        );
      }
    } else {
      if (amount === "") {
        setWfee(config.wr_fee_amount + config.wr_fee * 0);
      } else {
        setWfee(config.wr_fee_amount + config.wr_fee * parseInt(amount));
      }
    }
  }, [amount]);

  const arrivalQuantity = useCallback(() => {
    if (amount && parseInt(amount) > 10) {
      return (
        parseInt(amount) -
        (wallet === "ddcoin"
          ? (config.ddb_wr_fee_amount as number)
          : (config.wr_fee_amount as number))
      );
    } else {
      return 0;
    }
  }, [amount]);

  const handleGenerateTac = (values: any) => {
    // setLoad(true)
    setUser(values);
    generateTac({
      account_name: values.account_name,
      lang: "english",
      type: "wr",
    })
      .then((res: any) => {
        // setLoad(false)
        setShow(true);
      })
      .catch((err) => {
        // setLoad(false)
      });
  };

  const handleWithdrawRequest = async (value: any) => {
    withdrawRequest({
      amount: value.amount,
      sec_password: value.sec_password,
      tac: value.tac,
      wallet_address: value.wallet_address,
      wallet_type: selectedWallet,
    }).then((rs) => {
      getProfileData();
      selectedWallet === "ddb_wallet" ? getDDCoin() : getUSDT();
    });
  };

  const renderHeader = () => {
    return (
      <components.Header
        titleTranlateKey="Withdraw"
        title="Withdraw"
        goBack={true}
        withdrawHistory={true}
      />
    );
  };

  const renderContent = () => {
    return (
      <View style={{ width: "100%" }}>
        {/* {show ? ( */}
        <Formik
          innerRef={formRef as any}
          validationSchema={FormSchema}
          initialValues={{
            wallet_address: "",
            amount: "",
            sec_password: "",
            tac: "",
            account_name: profileData.email,
          }}
          onSubmit={handleWithdrawRequest}
        >
          {(props: FormikProps<any>) => (
            <KeyboardAwareScrollView
              contentContainerStyle={{ flexGrow: 1, paddingHorizontal: 20 }}
            >
              <View
                style={{
                  paddingTop: theme.SIZES.height * 0.03,
                  paddingBottom: theme.SIZES.height * 0.03,
                  maxWidth: "100%",
                }}
              >
                <ApText
                  translateKey={
                    selectedWallet === "ddb_wallet" ? "DDToken" : "USDTTRC20"
                  }
                  size="lg"
                  font="bold"
                  style={{
                    color: "#F37021",
                    // fontSize: 20,
                    paddingBottom: 10,
                    fontWeight: "500",
                  }}
                />

                <ApTextInput
                  name="wallet_address"
                  placeHolderTranslateKey="WithdrawalAddressPlaceholder"
                  icon={<svg.CheckSvg />}
                  labelTranslateKey="WithdrawalAddress"
                  keyboardType={"default"}
                />

                <ApTextInput
                  onMaxClick={setAmount}
                  max
                  maxAmount={profileData[selectedWallet]}
                  containerStyle={{ paddingBottom: 0 }}
                  name="amount"
                  labelTranslateKey="Amount"
                  placeHolderTranslateKey="AmountPlaceholder"
                  icon={<svg.CheckSvg />}
                  // label="Amount"
                  keyboardType={"numeric"}
                  onChange={(e: any) => {
                    setAmount(e);
                  }}
                />
                <ApText
                  size="xs"
                  style={{
                    paddingLeft: 5,
                    marginBottom: 10,
                    marginTop: 10,
                    color: "#F37021",
                  }}
                >
                  <ApText size="xs" translateKey="Balance" />
                  {" : "}
                  {profileData[selectedWallet]}
                </ApText>

                <ApText
                  translateKey="HandlingFee"
                  size="sm"
                  style={{ marginBottom: 3, marginTop: 10 }}
                />
                <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
                  <components.InputField
                    value={
                      wFee.toString()
                      // selectedWallet === 'ddb_wallet' ?
                      // config.ddb_wr_fee_amount
                      // ? config.ddb_wr_fee_amount?.toString()
                      // : "Fetching" :
                      // config.wr_fee_amount
                      //   ? config.wr_fee_amount?.toString()
                      //   : "Fetching"
                    }
                    containerStyle={{ marginBottom: 14 }}
                    icon={<svg.CheckSvg />}
                    rightIcon={
                      <ApText
                        translateKey={
                          selectedWallet === "ddb_wallet" ? "DDC" : "USDT"
                        }
                        style={{
                          marginRight: 5,
                          alignItems: "center",
                          alignSelf: "center",
                          textAlignVertical: "center",
                          height: 45,
                          display: "flex",
                          color: "#d8d8d8",
                          fontWeight: "500",
                        }}
                      />
                    }
                  />
                </TouchableWithoutFeedback>

                <ApTextInput
                  secureTextEntry
                  placeHolderTranslateKey="SecurityPasswordPlaceholder"
                  labelTranslateKey="SecurityPassword"
                  name="sec_password"
                  icon={<svg.CheckSvg />}
                  keyboardType="default"
                />

                <ApTacInput
                  type="wr"
                  props={props}
                  name="tac"
                  propKey="account_name"
                />

                <ApText
                  size="sm"
                  style={{ marginBottom: 3, marginTop: 10 }}
                  translateKey="ArrivalQuantity"
                />
                <components.InputField
                  value={
                    (parseInt(amount) > wFee
                      ? parseInt(amount) - wFee
                      : 0
                    ).toString()
                    // selectedWallet === 'ddb_wallet' ?
                    // config.ddb_wr_fee_amount
                    //   ? arrivalQuantity().toString()
                    //   : "Calculating" :
                    // config.wr_fee_amount
                    // ? arrivalQuantity().toString()
                    // : "Calculating"
                  }
                  containerStyle={{ marginBottom: 14 }}
                  icon={<svg.CheckSvg />}
                />

                {/* <View
                  style={{
                    backgroundColor: "#FFD9CF",
                    padding: 12,
                    borderRadius: 10,
                    marginTop: 15,
                    marginBottom: 20,
                    flexDirection: "row",
                  }}
                >
                  {selectedWallet === "ddb_wallet" ? (
                    <>
                    <ApText
                      style={{ color: "#F37021", width: "80%", fontSize: 13 }}
                      translateKey="WithdrawDDCPAgeNote1"
                      translateKey="WithdrawDDCPAgeNote2"
                      translateKey="WithdrawDDCPAgeNote3"
                    />
                    </>
                  ) : (
                    <>
                    <ApText
                      style={{ color: "#F37021", width: "80%", fontSize: 13 }}
                      translateKey="WithdrawUSDTPAgeNote1"
                      translateKey="WithdrawUSDTPAgeNote2"
                      translateKey="WithdrawUSDTPAgeNote3"
                    />
                    </>
                  )}
                </View> */}

                <View
                  style={{
                    backgroundColor: "#FFD9CF",
                    padding: 12,
                    borderRadius: 10,
                    marginTop: 15,
                    marginBottom: 20,
                    flexDirection: "column",
                  }}
                >
                  {selectedWallet === "ddb_wallet" ? (
                    <>
                      <ApText
                        style={{ color: "#F37021", width: "90%", fontSize: 13 }}
                        translateKey="WithdrawDDCPageNote1"
                      />
                      <ApText
                        style={{ color: "#F37021", width: "90%", marginTop: 5, fontSize: 13 }}
                        translateKey="WithdrawDDCPageNote2"
                      />
                      <ApText
                        style={{ color: "#F37021", width: "90%", marginTop: 5, fontSize: 13 }}
                        translateKey="WithdrawDDCPageNote3"
                      />
                    </>
                  ) : (
                    <>
                      <ApText
                        style={{ color: "#F37021", width: "90%", fontSize: 13 }}
                        translateKey="WithdrawUSDTPageNote1"
                      />
                      <ApText
                        style={{ color: "#F37021", width: "90%", marginTop: 5, fontSize: 13 }}
                        translateKey="WithdrawUSDTPageNote2"
                      />
                      <ApText
                        style={{ color: "#F37021", width: "90%", marginTop: 5, fontSize: 13 }}
                        translateKey="WithdrawUSDTPageNote3"
                      />
                    </>
                  )}
                </View>


                <ApButton
                  labelTranslateKey="Withdraw"
                  loading={loading}
                  disabled={loading}
                  round="lg"
                  type="primary"
                  onPress={props.handleSubmit}
                />
              </View>
            </KeyboardAwareScrollView>
          )}
        </Formik>
        {/* ) : (
          <Formik
            innerRef={formRef as any}
            validationSchema={TacFormSchema}
            initialValues={{ tac: "" }}
            onSubmit={handleWithdrawRequest}
          >
            {(props: FormikProps<any>) => (
              <KeyboardAwareScrollView
                contentContainerStyle={{ flexGrow: 1, paddingHorizontal: 20 }}
              >
                <View style={{ paddingTop: theme.SIZES.height * 0.03 }}>
                  <ApText
                    style={{
                      color: "#F37021",
                      fontSize: 20,
                      paddingBottom: 17,
                      fontWeight: "500",
                    }}
                  >
                    USDT-TRC20
                  </ApText>
                  <components.SmallHeader
                    title="Verification Code"
                    containerStyle={{ marginBottom: 6 }}
                  />
                  <ApTextInput
                    name="tac"
                    placeholder="Enter the verification code"
                    icon={<svg.CheckSvg />}
                    label="Tac"
                    keyboardType={"default"}
                  />

                  <View
                    style={{
                      backgroundColor: "#FFD9CF",
                      padding: 12,
                      borderRadius: 10,
                      marginTop: 15,
                      marginBottom: 20,
                      flexDirection: "row",
                    }}
                  >
                    <ApText
                      style={{ color: "#F37021", width: "80%", fontSize: 13 }}
                    >
                      It is a long established fact that a reader will be
                      distracted by the readable content of a page when looking
                      at its layout.
                    </ApText>
                  </View>

                  <ApButton
                    label="Withdraw"
                    round="xl"
                    type="primary"
                    onPress={props.handleSubmit}
                  />
                </View>
              </KeyboardAwareScrollView>
            )}
          </Formik>
        )} */}
      </View>
    );
  };

  return (
    <ApSafeAreaView>
      {renderHeader()}
      {renderContent()}
    </ApSafeAreaView>
  );
};

export default SignIn;
