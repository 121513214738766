import List from "./listSvg";
import { RankingComponentSvg } from "./rank";
import { StrategyComponentSvg } from "./strategySvg";

export * from "./state";
export const ApEmptyStateSvg = {
  List,
  StrategyComponentSvg,
  RankingComponentSvg,
};
