import React from "react";
import { TouchableOpacity } from "react-native";
import Dialog, {
  DialogContent,
  DialogProps,
  SlideAnimation,
} from "react-native-popup-dialog";
import { svg } from "../../../svg";
interface IProps extends DialogProps {
  show: boolean;
  onDimiss?: () => void;
  children?: React.ReactNode;
  zIndex?: number;
}

export const ApModal: React.FC<IProps> = (props: IProps) => {
  const { show, onDimiss, children, zIndex } = props;
  return (
    <Dialog
      visible={show}
      dialogAnimation={
        new SlideAnimation({
          slideFrom: "bottom",
        })
      }
      {...props}
    >
      <TouchableOpacity
        onPress={() => {
          if (onDimiss) onDimiss();
        }}
        style={{ alignItems: "flex-end", padding: 15, paddingBottom: 0 }}
      >
        <svg.closeSvg />
      </TouchableOpacity>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};
