import { LinearGradient } from "expo-linear-gradient";
import React, { useEffect, useState } from "react";
import {
  Image,
  ImageBackground,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { components } from "../components";
import {
  ApEmptyState,
  ApEmptyStateSvg,
  ApLoader,
  ApSafeAreaView,
} from "../components/v1";
import { ApText } from "../components/v1/typography";
import { useProfileState, useTransactionState } from "../context";
import {
  IWalletTransactionSummary,
  TWalletType,
} from "../models/transaction.interface";
import { setHideProfit } from "../store/hideProfitSlice";
import { svg } from "../svg";

const Commission: React.FC = ({ navigation }: any) => {
  const { profileData, getProfileData } = useProfileState();
  const [selected, setSelected] = useState<TWalletType>("cash_wallet");
  const {
    getWalletTransactionSummary,
    loading,
    incomeSummary,
    getIncomeSummary,
  } = useTransactionState();
  const [walletTransaction, setWalletTransaction] = useState<
    IWalletTransactionSummary[]
  >([]);

  const dispatch = useDispatch();
  const hideProfit = useSelector(
    (state: any) => state.hideProfitSlice.hideProfit
  );

  useEffect(() => {
    getProfileData();
    getIncomeSummary(selected);
    getWalletTransactionSummary({}, selected, "active").then((res) => {
      setWalletTransaction(res as unknown as IWalletTransactionSummary[]);
    });
  }, [selected]);

  const renderHeader = () => {
    return (
      <components.Header
        title={"Commission"}
        titleTranlateKey="Commission"
        goBack={true}
      />
    );
  };

  const renderContent = () => {
    return (
      <View style={{ flex: 1 }}>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            borderRadius: 5,
            paddingHorizontal: 20,
            paddingTop: 20,
          }}
        >
          <TouchableOpacity onPress={() => setSelected("cash_wallet")}>
            <LinearGradient
              colors={[
                selected === "cash_wallet" ? "none" : "none",
                selected === "cash_wallet" ? "none" : "none",
              ]}
              style={{
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 5,
                paddingVertical: 3,
              }}
              start={{ x: 0, y: 0 }}
              end={{ x: 1, y: 0 }}
            >
              <View style={{ flexDirection: "row" }}>
                <ApText
                  style={{
                    fontWeight: "500",
                    fontSize: 14,
                    color: selected === "cash_wallet" ? "#F37021" : "#D0CFCE",
                    lineHeight: 14 * 1.6,
                  }}
                  translateKey="USDT"
                />
                <ApText
                  style={{
                    fontWeight: "500",
                    fontSize: 14,
                    color: selected === "cash_wallet" ? "#F37021" : "#D0CFCE",
                    lineHeight: 14 * 1.6,
                    borderRightWidth: 2,
                    borderRightColor: "#D0CFCE",
                    paddingRight: 15,
                    paddingLeft: 3,
                  }}
                >
                  {/* ({genealogyData.direct_dls?.length}) */}
                </ApText>
              </View>
            </LinearGradient>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => setSelected("ddb_wallet")}>
            <LinearGradient
              colors={[
                selected === "ddb_wallet" ? "none" : "none",
                selected === "cash_wallet" ? "none" : "none",
              ]}
              style={{
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 5,
                paddingVertical: 3,
              }}
              start={{ x: 0, y: 0 }}
              end={{ x: 1, y: 0 }}
            >
              <ApText
                style={{
                  fontWeight: "500",
                  fontSize: 14,
                  color: selected === "ddb_wallet" ? "#F37021" : "#D0CFCE",
                  lineHeight: 14 * 1.6,
                  paddingLeft: 15,
                }}
                translateKey="DDToken"
              />
            </LinearGradient>
          </TouchableOpacity>
        </View>
        <View>
          <View
            style={{
              marginHorizontal: 15,
              marginVertical: 10,
              alignItems: "flex-end",
            }}
          >
            <TouchableOpacity
              onPress={() => {
                dispatch(setHideProfit(true));
              }}
            >
              {hideProfit ? <svg.EyeOffSvg /> : <svg.EyeOnSvg />}
            </TouchableOpacity>
          </View>
          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <View style={{ width: "33%" }}>
              <ImageBackground
                style={{ margin: 10 }}
                source={require("../../assets/bg-04.png")}
                imageStyle={{
                  borderRadius: 12,
                  width: "100%",
                  backgroundColor: "#fff5f0ba",
                }}
              >
                <View
                  style={{
                    width: "100%",
                    borderRadius: 10,
                    padding: 10,
                  }}
                >
                  <ApText
                    style={{
                      textAlign: "center",
                      fontSize: 9,
                      fontWeight: "500",
                    }}
                    translateKey="Today'sComm(USDT)"
                  />

                  <ApText
                    style={{
                      textAlign: "center",
                      color: "#F37021",
                      fontWeight: "bold",
                      fontSize: 16,
                      paddingTop: 5,
                    }}
                  >
                    {hideProfit ? "*****" : incomeSummary?.tdy_income}
                  </ApText>
                </View>
              </ImageBackground>
            </View>
            <View style={{ width: "33%" }}>
              <ImageBackground
                style={{ margin: 10 }}
                source={require("../../assets/bg-04.png")}
                imageStyle={{
                  borderRadius: 12,
                  width: "100%",
                  backgroundColor: "#fff5f0ba",
                }}
              >
                <View
                  style={{
                    width: "100%",
                    borderRadius: 10,
                    padding: 10,
                  }}
                >
                  <ApText
                    style={{
                      textAlign: "center",
                      fontSize: 9,
                      fontWeight: "500",
                    }}
                    translateKey="Yesterday'sComm(USDT)"
                  />
                  <ApText
                    style={{
                      textAlign: "center",
                      color: "#F37021",
                      fontWeight: "bold",
                      fontSize: 16,
                      paddingTop: 5,
                    }}
                  >
                    {hideProfit ? "*****" : incomeSummary?.ytd_income}
                  </ApText>
                </View>
              </ImageBackground>
            </View>
            <View style={{ width: "33%" }}>
              <ImageBackground
                style={{ margin: 10 }}
                source={require("../../assets/bg-04.png")}
                imageStyle={{
                  borderRadius: 12,
                  width: "100%",
                  backgroundColor: "#fff5f0ba",
                }}
              >
                <View
                  style={{
                    width: "100%",
                    borderRadius: 10,
                    padding: 10,
                  }}
                >
                  <ApText
                    style={{
                      textAlign: "center",
                      fontSize: 9,
                      fontWeight: "500",
                    }}
                    translateKey="30Day'sComm(USDT)"
                  />
                  <ApText
                    style={{
                      textAlign: "center",
                      color: "#F37021",
                      fontWeight: "bold",
                      fontSize: 16,
                      paddingTop: 5,
                    }}
                  >
                    {hideProfit ? "*****" : incomeSummary?.total_income}
                  </ApText>
                </View>
              </ImageBackground>
            </View>
          </View>

          <ApText
            style={{
              color: "#F37021",
              fontSize: 17,
              padding: 15,
              fontWeight: "500",
              paddingTop: 25,
            }}
            translateKey="History"
          />
          <View
            style={{
              paddingHorizontal: 15,
              backgroundColor: "#FCF8F6",
              margin: 15,
              borderRadius: 10,
              paddingBottom: 20,
              marginTop: 0,
            }}
          >
            {walletTransaction.length > 0 ? (
              walletTransaction.map((e, index) => (
                <TouchableOpacity
                  onPress={() =>
                    navigation.navigate("CommissionDetails", { date: e.date })
                  }
                  key={e.daily_sum}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      paddingVertical: 15,
                      borderBottomWidth:
                        index + 1 !== walletTransaction.length && 1,
                      borderColor: "#e4e4e499",
                    }}
                  >
                    <View style={{ width: "40%" }}>
                      <Text style={{ fontWeight: "500" }}>{e.date}</Text>
                    </View>

                    <ApText
                      style={{
                        width: "55%",
                        fontWeight: "500",
                        color: "#05B491",
                        alignSelf: "center",
                        fontSize: 15,
                        textAlign: "right",
                      }}
                    >
                      +{e.daily_sum}
                    </ApText>
                    <View
                      style={{
                        marginLeft: "auto",
                        alignSelf: "center",
                        alignItems: "flex-end",
                      }}
                    >
                      <Image
                        source={require("../../assets/other-icons/14.png")}
                        style={{ width: 6, height: 9 }}
                      />
                    </View>
                  </View>
                </TouchableOpacity>
              ))
            ) : (
              <ApEmptyState
                svg={<ApEmptyStateSvg.List />}
                heading="No Result Found"
                message="Commission history is empty"
              />
            )}
          </View>
        </View>
      </View>
    );
  };

  return (
    <ApSafeAreaView>
      {renderHeader()}
      {loading && <ApLoader />}
      {!loading && renderContent()}
    </ApSafeAreaView>
  );
};

export default Commission;
