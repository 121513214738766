import React from "react";
import ContentLoader, { Rect, Circle, Path } from "react-content-loader/native";

export const ApFlexBoxLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={476}
      height={600}
      viewBox="0 0 476 600"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <Rect x="21" y="24" rx="0" ry="0" width="101" height="84" />
      <Rect x="21" y="151" rx="0" ry="0" width="338" height="413" />
      <Rect x="140" y="22" rx="0" ry="0" width="101" height="84" />
      <Rect x="258" y="23" rx="0" ry="0" width="101" height="84" />
    </ContentLoader>
  );
};
