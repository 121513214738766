import React, { useState } from "react";
import { IConvert, ICreateMembership } from "../models/business.interface";
import { ApErrorToast, ApSuccessToast, ApiService } from "../services";
import { IMembershipSubscription } from "../models/profile.interface";

interface IMembershipState {
  loading: boolean;
  membershipSubscriptions: IMembershipSubscription[];
  getMembership: () => Promise<void>;
  createMembership: (payload: ICreateMembership) => Promise<void>;
}

const MembershipContext = React.createContext<IMembershipState>({
  loading: false,
  membershipSubscriptions: [],
  getMembership(payload: IConvert) {},
  createMembership(payload: IConvert) {},
} as unknown as IMembershipState);

const useMembershipState = () => {
  const context = React.useContext(MembershipContext);
  if (context === undefined) {
    throw new Error("app dispatch must be used within app global provider");
  }
  return context;
};

interface IProps {
  children: React.ReactNode;
}

const MembershipContextProvider: React.FC<IProps> = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [membershipSubscriptions, setMembershipSubscriptions] = useState<
    IMembershipSubscription[]
  >([]);

  const getMembership = async () => {
    setLoading(true);
    ApiService.get(`/profile/getMembershipSubscription`)
      .then((rs) => {
        setMembershipSubscriptions(rs?.data?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const createMembership = async (
    payload: ICreateMembership
  ): Promise<void> => {
    await ApiService.post("/business/createMembershipSubscription", payload)
      .then((rs) => {
        ApSuccessToast("Membership", rs?.data?.msg);
      })
      .catch((err) => {
        ApErrorToast("Membership Error", err);
        throw err;
      });
  };

  return (
    <MembershipContext.Provider
      value={{
        loading,
        membershipSubscriptions,
        getMembership,
        createMembership,
      }}
    >
      {children}
    </MembershipContext.Provider>
  );
};

export { MembershipContextProvider, useMembershipState };
