import * as React from "react";
import Svg, { G, Path, Ellipse } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width={18.962} height={24.05} viewBox="0 0 18.962 24.05" {...props}>
      <G transform="translate(-5 -2)">
        <Path data-name="Path 80" d="M6 4.7A1.7 1.7 0 017.7 3h13.565a1.7 1.7 0 011.7 1.7v18.654a1.7 1.7 0 01-1.7 1.7H7.7a1.7 1.7 0 01-1.7-1.7z" fill="none" stroke="#7a8390" strokeLinejoin="round" strokeWidth={2} />
        <Path data-name="Path 81" d="M9.392 8.088h3.392m-3.392 5.937h10.177M9.392 17.417h10.177M9.392 20.809h3.392" fill="none" stroke="#F37021" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} />
        <Ellipse data-name="Ellipse 36" cx={1} cy={0.5} rx={1} ry={0.5} transform="translate(18 7.55)" fill="#F37021" />
      </G>
    </Svg>
  );
}

export default SvgComponent;
