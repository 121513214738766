import React, { useState } from "react";
import { ISymbol } from "../models";
import { ApiService } from "../services/ApiService";

export const OStrategyProviders = "facebook" || "google";

interface IStrategyState {
  loading: boolean;
  error: any;
  strategy: any;
  symbols: ISymbol[],
  setSymbols: (symbols:ISymbol[])=>void;
  setStrategy:(strategy: any)=>void;
}

const StrategyContext = React.createContext<IStrategyState>({
  loading: true,
  error: "",
  strategy: {},
  symbols: [],
  setSymbols (symbols:ISymbol[]){},
  setStrategy(strategy) {
      
  },
} as IStrategyState);

const useStrategyState = () => {
  const context = React.useContext(StrategyContext);
  if (context === undefined) {
    throw new Error("app dispatch must be used within app global provider");
  }

  return context;
};

interface IProps {
  children: React.ReactNode;
}

const StrategyContextProvider: React.FC<IProps> = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState();
  const [strategy, setStrategy] = useState();
  const [symbols, setSymbols] = useState<ISymbol[]>([]);


  return (
    <StrategyContext.Provider
      value={{
        loading,
        error,
        symbols,
        setSymbols,
        strategy,
        setStrategy
      }}
    >
      {children}
    </StrategyContext.Provider>
  );
};

export { StrategyContextProvider, useStrategyState };