import * as React from "react";
import Svg, { G, Path } from "react-native-svg";

function ExportSvg(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} viewBox="0 0 22 22" {...props}>
      <G fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}>
        <Path data-name="Path 122" d="M10 0A10 10 0 110 10" strokeMiterlimit={10} transform="translate(1 1)" />
        <G data-name="Group 2837">
          <Path data-name="Path 123" d="M9 9L.8.8" transform="translate(1 1)" />
          <Path data-name="Path 124" d="M0 4.83V0h4.83" transform="translate(1 1)" />
        </G>
      </G>
    </Svg>
  );
}

export default ExportSvg;
