import React, { useEffect } from "react";
import {
  Image,
  ScrollView,
  TouchableOpacity,
  View
} from "react-native";
import { components } from "../components";
import { ApEmptyState, ApLoader } from "../components/v1";
import { ApHtmlText, ApText } from "../components/v1/typography";
import { useProfileState } from "../context";

const Tutorials: React.FC = () => {
  const { announcementList, getAnnouncementList, loading } = useProfileState();

  useEffect(() => {
    getAnnouncementList();
  }, []);

  const renderHeader = () => {
    return <components.Header title="Tutorials" goBack={true} />;
  };

  const renderContent = () => {
    return (
      <View
        style={{
          marginHorizontal: 15,
          paddingHorizontal: 15,
          backgroundColor: "#fff5f0ba",
          borderRadius: 10,
          marginVertical: 25,
          paddingVertical: 15,
        }}
      >
        {!!announcementList?.length ? (
          announcementList.map((e: any) => (
              <TouchableOpacity>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingVertical: 15,
                    borderBottomWidth: 1,
                    borderColor: "#e4e4e499",
                  }}
                  key={e?.id}
                >
                  <View style={{ width: "90%" }}>
                    <ApText numberOfLines={1} style={{ fontWeight: "bold" }}>
                      {e?.title}
                    </ApText>
                    <ApHtmlText
                      style={{
                        // color: "#8e8e8e",
                        fontSize: 14,
                        lineHeight: 18,
                        paddingTop: 5,
                      }}
                      content={e?.content}
                    />
                  </View>

                  <View
                    style={{
                      width: "10%",
                      marginLeft: "auto",
                      alignSelf: "center",
                      alignItems: "flex-end",
                    }}
                  >
                    <Image
                      source={require("../../assets/other-icons/14.png")}
                      style={{ width: 6, height: 9 }}
                    />
                  </View>
                </View>
              </TouchableOpacity>
          ))
        ) : (
          <ApEmptyState heading="Tutorial" message="No tutorial added" />
        )}
      </View>
    );
  };

  return (
    <ScrollView
      contentContainerStyle={{
        flexGrow: 1,
      }}
      showsVerticalScrollIndicator={false}
      bounces={false}
    >
      {renderHeader()}
      <ScrollView>
      {loading && <ApLoader />}
      {!loading && renderContent()}
      </ScrollView>
      
    </ScrollView>
  );
};

export default Tutorials;
