import React, { useState } from "react";
import { IMyPackage, IPackage, IPackageRoi, IPurchasePackage } from "../models";
import { ApErrorToast, ApSuccessToast, ApiService } from "../services";
import { useNavigation } from "@react-navigation/native";

interface IPackageState {
  loading: boolean;
  packageLoading: boolean;
  packages: IPackage[];
  packageRoi: IPackageRoi[];
  purchaseError: any;
  myPackages: IMyPackage[];
  purchasedPackage: IPurchasePackage;
  getPackages: () => Promise<void>;
  getMyPackages: () => Promise<void>;
  getPackageRoi: (id?: string) => Promise<void>;
  purchasePackage: (_id: string) => Promise<void>;
}

const PackageContext = React.createContext<IPackageState>({
  loading: false,
  packageLoading: false,
  packages: [],
  packageRoi: [],
  purchasedPackage: {},
  purchaseError: {},
  myPackages: [],
  getPackages() {
    return null;
  },
  getMyPackages() {
    return null;
  },
  getPackageRoi(id) {
    return null;
  },
  purchasePackage(_id) {
    return null;
  },
});

export const usePackageState = () => {
  const context = React.useContext(PackageContext);
  if (context === undefined) {
    throw new Error("app dispatch must be used within app global provider");
  }

  return context;
};

interface IProps {
  children: React.ReactNode;
}

export const PackageContextProvider: React.FC<IProps> = ({ children }) => {
  const [packages, setPackages] = useState<IPackage[]>([]);
  const [myPackages, setMyPackages] = useState<IMyPackage[]>([]);
  const [packageRoi, setPackageRoi] = useState<IPackageRoi[]>([]) as any;
  const [purchaseError, setPurchaseError] = useState<any>({});
  const [purchasedPackage, setPurchasedPackage] = useState<IPurchasePackage>({
    msg: "",
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [packageLoading, setPackageLoading] = useState<boolean>(false);
  const navigation: any = useNavigation()

  const getPackages = (): Promise<void> => {
    setPackageLoading(true);
    const url = "/package/getPackages";
    return ApiService.get(url)
      .then((res) => {
        setPackages(res.data?.data);
        return res.data?.data;
      })
      .finally(() => {
        setPackageLoading(false);
      });
  };

  const getMyPackages = (): Promise<void> => {
    setLoading(true);
    const url = "/package/getMyPackages";
    return ApiService.get(url)
      .then((res) => {
        setMyPackages(res.data?.data);
        return res.data?.data;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getPackageRoi = (id?: string): Promise<void> => {
    setLoading(true);
    const url = `package/getPackageRoi?mp_id=${id}`;
    return ApiService.get(url)
      .then((res) => {
        setPackageRoi(res.data?.data);
        return res.data?.data;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const purchasePackage = (_id: string) => {
    setLoading(true);
    const url = `/package/purchasePackage`;
    return ApiService.post(url, { package_id: _id })
      .then((res) => {
        setPurchasedPackage(res?.data);
        ApSuccessToast("success", "Purchased package successfully");
        getPackages()
        navigation.navigate("MyPackages")
        return res.data?.data;
      })
      .catch((err) => {
        ApErrorToast("Error", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <PackageContext.Provider
      value={{
        loading,
        packageLoading,
        getMyPackages,
        packages,
        packageRoi,
        purchaseError,
        purchasePackage,
        myPackages,
        getPackages,
        getPackageRoi,
        purchasedPackage,
      }}
    >
      {children}
    </PackageContext.Provider>
  );
};
