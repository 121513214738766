import React from "react";
import {  ScrollView, View } from "react-native";
import { components } from "../components";
import { ApSafeAreaView } from "../components/v1";
import { ApText } from "../components/v1/typography";
import { theme } from "../constants";

const PrivacyPolicy: React.FC = () => {
  const renderHeader = () => {
    return (
      <components.Header title="Privacy Policy" titleTranlateKey="PrivacyPolicy" goBack={true} />
    );
  };    

  const renderContent = () => {
    return (
      <ScrollView
        contentContainerStyle={{
          flexGrow: 1,
          paddingHorizontal: 20,
          paddingVertical: 20
        }}
      >
        <ApText
            translateKey="PrivacyPolicyIntro"
            size="sm"
            font="normal"
            style={{
                lineHeight: 20,
                marginBottom: 10
            }}
        />

        <View style={{marginBottom: 10}}>            
            <ApText
                translateKey="PrivacyPolicyTitleOne"
                size="sm"
                font="normal"
                style={{
                    marginBottom: 5
                }}
            />
            <ApText
                translateKey="PrivacyPolicyContentOne"
                size="sm"
                font="normal"
                style={{
                    lineHeight: 20,
                }}
            />
        </View>

        <View style={{marginBottom: 10}}>            
            <ApText
                translateKey="PrivacyPolicyTitleTwo"
                size="sm"
                font="normal"
                style={{
                    marginBottom: 5
                }}
            />
            <ApText
                translateKey="PrivacyPolicyContentTwo"
                size="sm"
                font="normal"
                style={{
                    lineHeight: 20,
                }}
            />
        </View>

        <View style={{marginBottom: 10}}>            
            <ApText
                translateKey="PrivacyPolicyTitleThree"
                size="sm"
                font="normal"
                style={{
                    marginBottom: 5
                }}
            />
            <ApText
                translateKey="PrivacyPolicyContentThree"
                size="sm"
                font="normal"
                style={{
                    lineHeight: 20,
                }}
            />
        </View>

        <View style={{marginBottom: 10}}>            
            <ApText
                translateKey="PrivacyPolicyTitleFour"
                size="sm"
                font="normal"
                style={{
                    marginBottom: 5
                }}
            />
            <ApText
                translateKey="PrivacyPolicyContentFour"
                size="sm"
                font="normal"
                style={{
                    lineHeight: 20,
                }}
            />
        </View>

        <View style={{marginBottom: 10}}>            
            <ApText
                translateKey="PrivacyPolicyTitleFive"
                size="sm"
                font="normal"
                style={{
                    marginBottom: 5
                }}
            />
            <ApText
                translateKey="PrivacyPolicyContentFive"
                size="sm"
                font="normal"
                style={{
                    lineHeight: 20,
                }}
            />
        </View>

        <View style={{marginBottom: 10}}>            
            <ApText
                translateKey="ProvisosTitle"
                size="sm"
                font="normal"
                style={{
                    marginBottom: 5
                }}
            />
            <ApText
                translateKey="ProvisosOne"
                size="sm"
                font="normal"
                style={{
                    lineHeight: 20,
                }}
            />
            <ApText
                translateKey="ProvisosTwo"
                size="sm"
                font="normal"
                style={{
                    lineHeight: 20,
                }}
            />
            <ApText
                translateKey="ProvisosThree"
                size="sm"
                font="normal"
                style={{
                    lineHeight: 20,
                }}
            />
            <ApText
                translateKey="ProvisosFour"
                size="sm"
                font="normal"
                style={{
                    lineHeight: 20,
                }}
            />
            <ApText
                translateKey="ProvisosFive"
                size="sm"
                font="normal"
                style={{
                    lineHeight: 20,
                }}
            />
        </View>

        <View>            
            <ApText
                translateKey="BenefitYourInterests"
                size="sm"
                font="normal"
                style={{
                    marginBottom: 5
                }}
            />
            <ApText
                translateKey="BenefitYourInterestsContent"
                size="sm"
                font="normal"
                style={{
                    lineHeight: 20,
                }}
            />
        </View>
      </ScrollView>
    );
  };

  return (
    <ApSafeAreaView style={{ flex: 1, backgroundColor: theme.COLORS.bgColor }}>
      {renderHeader()}
      {renderContent()}
    </ApSafeAreaView>
  );
};

export default PrivacyPolicy;