import { useNavigation, useRoute } from "@react-navigation/native";
import React, { useEffect, useState } from "react";
import {
  Image,
  Linking,
  ScrollView,
  TouchableOpacity,
  View,
} from "react-native";
import { components } from "../components";
import {
  ApEmptyState,
  ApEmptyStateSvg,
  ApLoader,
  ApRow,
} from "../components/v1";
import { ApText } from "../components/v1/typography";
import { useProfileState } from "../context";
import { IMaterial } from "../models/profile.interface";

const MaterialList: React.FC = ({}) => {
  const { params } = useRoute();
  const [items, setItems] = useState<IMaterial[]>([]);
  const { materialList, getMaterialList, loading } = useProfileState();

  useEffect(() => {
    getMaterialList();
  }, []);

  useEffect(() => {
    if (!!materialList.length)
      setItems(
        materialList?.filter(
          (m) =>
            m.folder?.toLowerCase() === (params as any)?.folder?.toLowerCase()
        )
      );
  }, [materialList]);

  const renderHeader = () => {
    return (
      <components.Header
        title="Materials List"
        titleTranlateKey="MaterialsList"
        goBack={true}
      />
    );
  };

  const renderContent = () => {
    return (
      <View style={{}}>
        <View
          style={{
            // marginHorizontal: 15,
            paddingHorizontal: 15,
            // backgroundColor: "#fff5f0ba",
            borderRadius: 10,
            marginVertical: 4,
            paddingVertical: 8,
          }}
        >
          {!!items.length ? (
            items.map((e) => (
              <TouchableOpacity
                key={e.title}
                onPress={() => {
                  Linking.openURL(e.file_url);
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingVertical: 15,
                    paddingHorizontal: 8,
                    backgroundColor: "#fff5f0ba",
                    borderColor: "#e4e4e499",
                    marginVertical: 4,
                    // marginHorizontal: 15,
                    borderRadius: 10,
                  }}
                >
                  <View
                    style={{
                      width: "17%",
                      alignSelf: "center",
                      alignItems: "center",
                    }}
                  >
                    <Image
                      source={require("../../assets/pdf.png")}
                      style={{ width: 26, height: 30 }}
                    />
                  </View>
                  <View style={{ width: "73%" }}>
                    <ApText numberOfLines={1} style={{ fontWeight: "bold" }}>
                      {e.title}
                    </ApText>

                    <ApRow>
                      <ApText
                        numberOfLines={1}
                        style={{
                          color: "#8e8e8e",
                          fontSize: 13,
                          lineHeight: 18,
                          paddingTop: 3,
                        }}
                        translateKey="LastUpdateDate"
                      />

                      <ApText
                        numberOfLines={1}
                        style={{
                          color: "#8e8e8e",
                          fontSize: 13,
                          lineHeight: 18,
                          paddingTop: 3,
                        }}
                      >
                        : {e.updated_at}
                      </ApText>
                    </ApRow>
                  </View>

                  <View
                    style={{
                      width: "10%",
                      marginLeft: "auto",
                      alignSelf: "center",
                      alignItems: "flex-end",
                    }}
                  >
                    <Image
                      source={require("../../assets/download.png")}
                      style={{ width: 19, height: 16 }}
                    />
                  </View>
                </View>
              </TouchableOpacity>
            ))
          ) : (
            <ApEmptyState />
          )}
        </View>
      </View>
    );
  };

  return (
    <ScrollView
      contentContainerStyle={{
        flexGrow: 1,
      }}
      showsVerticalScrollIndicator={false}
      bounces={false}
    >
      {renderHeader()}
      {loading && <ApLoader />}
      {!loading && renderContent()}
    </ScrollView>
  );
};

export default MaterialList;
