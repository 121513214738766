import { useNavigation } from "@react-navigation/native";
import React, { useEffect, useState } from "react";
import { Image, ScrollView, TouchableOpacity, View } from "react-native";
// import Swiper from "react-native-swiper";
import { RefreshControl } from "react-native-web-refresh-control";
import { components } from "../components";
import { ApEmptyState, ApLoader, ApSafeAreaView } from "../components/v1";
import { ApText } from "../components/v1/typography";
import { useProfileState } from "../context";

const Materials: React.FC = () => {
  const [refreshing, setRefreshing] = useState(false);
  const navigation: any = useNavigation();
  const [materials, setMaterials] = useState([]) as any;
  const { getMaterialList, materialList, loading } = useProfileState();

  useEffect(() => {
    getMaterialList();
  }, []);

  useEffect(() => {
    const uniqueFolders = new Set();
    materialList.forEach((material) => uniqueFolders.add(material.folder));
    setMaterials([...uniqueFolders]);
  }, [materialList]);

  const handleRefresh = () => {
    setRefreshing(true);
    getMaterialList().finally(() => {
      setRefreshing(false);
    });
  };

  const renderHeader = () => {
    return (
      <components.Header
        title="Materials"
        titleTranlateKey="Materials"
        goBack={true}
      />
    );
  };

  const renderCategories = () => {
    return (
      <View style={{ marginHorizontal: 15, borderTopColor: "white" }}>
        <View
          style={{
            paddingVertical: 15,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap"
          }}
        >
          {loading && <ApLoader />}
          {!!materialList?.length ? (
            materials?.map((e: any) => (
              // <View style={{ width: "47.5%" }} key={e}>
                <TouchableOpacity
                  key={e}
                  style={{
                    width: "47.5%",
                    borderRadius: 10,
                    padding: 20,
                    alignItems: "center",
                    backgroundColor: "#FCF8F6",
                    marginBottom: 15
                  }}
                  onPress={() => {
                    navigation.navigate("MaterialList", {
                      folder: e,
                    });
                  }}
                >
                  <Image
                    source={require("../../assets/material-folder.png")}
                    style={{ width: 59, height: 47, marginBottom: 12 }}
                  />
                  <ApText
                    style={{
                      textAlign: "center",
                      fontSize: 14,
                      fontWeight: "500",
                    }}
                  >
                    {e}
                  </ApText>
                </TouchableOpacity>
              // </View>
            ))
          ) : (
            <ApEmptyState
              heading="Materials"
              message="Material list is empty"
            />
          )}
        </View>
      </View>
    );
  };

  return (
    <ApSafeAreaView>
      {renderHeader()}

      <ScrollView
        style={{
          minHeight: "100%",
        }}
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={handleRefresh} />
        }
      >
        {loading && <ApLoader />}
        {!loading && renderCategories()}
      </ScrollView>
    </ApSafeAreaView>
  );
};

export default Materials;
