import React, { useState } from "react";
import { Image, View } from "react-native";
import { ApText } from "./typography";

interface IProps {
  translateKey: string;
  icon?: string;
}

export const PageHint: React.FC<IProps> = ({ translateKey, icon }) => {
  const [show, setShow] = useState(true);

  return (
    <View
      style={{
        backgroundColor: "#FFD9CF",
        padding: 12,
        borderRadius: 10,
        // marginBottom: 10,
        flexDirection: "row",
      }}
    >
      <ApText
        style={{ color: "#F37021", width: "80%", fontSize: 13 }}
        translateKey={translateKey}
      />

        <Image
          source={require("../../../assets/icons/account-hint.png")}
          style={{ width: 86, height: 57 }}
        />
    </View>
  );
};
