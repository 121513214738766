import React from "react";
import { View, StyleSheet } from "react-native";
import { ApText } from "../../../../components/v1/typography";
import { theme } from "../../../../constants";
import { useTradingContext } from "../../../../context";
import { directionList } from "../../../../utils/listing";
import moment from "moment";

export const FirstInfo = ({ }) => {
  const { botExecutorStrategySummary, botExecutorByID } = useTradingContext();

  return (
    <>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <View style={{ flex: 1, width: "80%" }}>
          <ApText
            style={{
              fontSize: 16,
              fontWeight: "500",
            }}
          >
            <ApText
              style={{
                color: "#F37021",
                fontSize: 17,
                fontWeight: "bold",
              }}
            >
              {botExecutorByID?.symbol?.split("USDT")[0]}
            </ApText>
            <ApText style={{ fontSize: 14, color: "#222" }}> / USDT</ApText>
          </ApText>
        </View>

        <View style={{ flex: 1, width: "20%" }}>
          <ApText
            style={{
              fontSize: 11,
              textAlign: "right",
              color: "#a1a1a1",
            }}
          >
            {moment(botExecutorStrategySummary?.created_at).format(
              "YYYY-MM-DD HH:mm:ss"
            )}
          </ApText>
        </View>
      </View>

      <View
        style={{
          flexDirection: "row",
          paddingTop: 5,
          paddingBottom: 10,
        }}
      >
        {botExecutorStrategySummary.market === "1" ? (
          <></>
        ) : (
          <ApText
            style={{
              borderRadius: 3,
              fontSize: 11,
              marginRight: 3,
              paddingHorizontal: 10,
              backgroundColor: "#F37021",
              color: "white",
            }}
          >
            {botExecutorByID.leverage}X
          </ApText>
        )}
        {botExecutorStrategySummary.market === "1" ? (
          <ApText
            style={{
              marginRight: 3,
              borderRadius: 3,
              fontSize: 11,
              //paddingVertical: 1,
              paddingHorizontal: 8,
              backgroundColor:
                botExecutorStrategySummary.pos_side == "1"
                  ? theme.COLORS.green
                  : "#FF8B7E",
              color: "white",
            }}
          >
            {botExecutorStrategySummary.pos_side == "1" ? (
              <ApText size="xs" translateKey="USDT-based" />
            ) : (
              <ApText size="xs" translateKey="Token-based" />
            )}
          </ApText>
        ) : (
          <ApText
            style={{
              marginHorizontal: 3,
              borderRadius: 3,
              fontSize: 11,
              //paddingVertical: 1,
              paddingHorizontal: 8,
              backgroundColor:
                botExecutorStrategySummary.pos_side == "1"
                  ? theme.COLORS.green
                  : "#FF8B7E",
              color: "white",
            }}
          >
            {botExecutorStrategySummary.pos_side == "1" ? (
              <ApText size="xs" translateKey="Long" />
            ) : (
              <ApText size="xs" translateKey="Short" />
            )}
          </ApText>
        )}
        <ApText
          style={{
            marginHorizontal: 3,
            borderRadius: 3,
            fontSize: 11,
            // paddingVertical: 1,
            paddingHorizontal: 8,
            backgroundColor:
              botExecutorByID.status === "0" ? "#F37021" : "#05B491",
            color: "white",
          }}
        >
          {botExecutorByID.status === "0" ? (
            <ApText size="xs" translateKey="Paused" />
          ) : (
            <ApText size="xs" translateKey="Running" />
          )}
        </ApText>

        {botExecutorStrategySummary?.plan_type !== "1" &&
          <ApText
            style={{
              marginHorizontal: 3,
              borderRadius: 3,
              fontSize: 11,
              //  paddingVertical: 1,
              paddingHorizontal: 8,
              backgroundColor:
                botExecutorStrategySummary.is_reentry === "0" ? "red" : "blue",
              color: "white",
            }}
          >
            {botExecutorStrategySummary.is_reentry === "0" ? (
              <ApText size="xs" translateKey="ReEntryOff" />
            ) : (
              <ApText size="xs" translateKey="ReEntryOn" />
            )}
          </ApText>}

        {botExecutorStrategySummary.is_peer_bp === "1" ? (
          <ApText
            style={{
              marginHorizontal: 3,
              borderRadius: 3,
              fontSize: 11,
              //  paddingVertical: 1,
              paddingHorizontal: 8,
              backgroundColor: "#BF40BF",
              color: "white",
            }}
          >
            <ApText size="xs" translateKey="Follow" />
          </ApText>
        ) : (
          <></>
        )}

        {botExecutorStrategySummary.plan_type == "1" && (
        <View style={styles.botPlanTypeOneLabel}>
          <ApText size="xs" color="#fff" translateKey="BotPlanTypeOneLabel" />
        </View>)}
      </View>

      <View
        style={{
          width: "100%",
          flexDirection: "row",
          justifyContent: "space-between",
          borderBottomWidth: 1,
          borderBottomColor: "#CED6E1",
          display: "flex",
        }}
      >
        <View style={{ width: "25%" }}>
          <ApText
            style={{
              ...theme.FONTS.Mulish_400Regular,
              fontSize: 12,
              lineHeight: 12 * 1.6,
              // color: theme.COLORS.bodyApTextColor,
            }}
            translateKey="Strategy"
          />
          <ApText
            style={{
              marginBottom: 12,
              fontWeight: "500",
              color: theme.COLORS.mainDark,
              fontSize: 13,
              flex: 1,
              flexWrap: "wrap",
            }}
          >
            {botExecutorByID?.label_code}
          </ApText>
        </View>

        <View style={{ width: "40%", marginHorizontal: 10 }}>
          <ApText
            style={{
              ...theme.FONTS.Mulish_400Regular,
              fontSize: 12,
              lineHeight: 12 * 1.6,
              // color: theme.COLORS.bodyApTextColor,
            }}
            translateKey={
              botExecutorStrategySummary?.plan_type === "1"
                ? "RemainingTargetProfile"
                : "ExecutionsRemaining"
            }
          />
          <ApText
            style={{
              marginBottom: 12,
              fontWeight: "500",
              color: theme.COLORS.mainDark,
              fontSize: 13,
            }}
          >
            {botExecutorStrategySummary?.plan_type === "1"
              ? +botExecutorStrategySummary?.target_pnl_max -
              +botExecutorStrategySummary?.target_pnl_count
              : +botExecutorByID?.exec_max - +botExecutorByID?.exec_count}
          </ApText>
        </View>

        <View style={{ width: "25%" }}>
          <ApText
            style={{
              ...theme.FONTS.Mulish_400Regular,
              fontSize: 12,
              lineHeight: 12 * 1.6,
              // color: theme.COLORS.bodyApTextColor,
            }}
            translateKey="RealisedP&L"
          />
          <ApText
            style={{
              marginBottom: 12,
              ...theme.FONTS.H6,
              color: theme.COLORS.mainDark,
            }}
          >
            <ApText
              style={{
                color: "#00a753",
                fontWeight: "bold",
                paddingRight: 5,
                fontSize: 13,
              }}
            >
              {botExecutorByID.realised_pnl}
            </ApText>
            <ApText
              style={{ fontWeight: "500", fontSize: 12 }}
              translateKey="USDT"
            />
          </ApText>
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  botPlanTypeOneLabel: {
    marginLeft: 5,
    backgroundColor: 'red',
    paddingHorizontal: 15,
    borderRadius: 50,
    paddingVertical: 3
  }
})