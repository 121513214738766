import axios from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";



axios.interceptors.request.use(
  async function (config) {
    const accessToken = await AsyncStorage.getItem("accessToken");
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    return Promise.reject(error);
  }
);


export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  patch: axios.patch,
};
