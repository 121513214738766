import React, { useState } from "react";
import { View, TouchableOpacity, Image, TextInput, StyleSheet } from "react-native";
import { ApButton } from "../../../../components/v1";
import { ApOperationsInput } from "../../../../components/v1/input/operationsInput";
import { ApText } from "../../../../components/v1/typography";
import { useTradingContext } from "../../../../context";
import { ApModalAlt } from "../../../../components/Modal";
import { svg } from "../../../../svg";
import { theme } from "../../../../constants";

export const Operations = ({
  setSelectedStrategy,
  openOperation,
  setOpenOperation,
  setShowTermModal,
  market,
  selectedStrategy,
  isSearching,
  botExecutors,
  query,
  showLive,
  status,
  initFund
}) => {
  const [operation, setOperation] = useState<"all" | "long" | "short">();
  const selectAll = (v: boolean) => {
    setOperation("all");
    if (isSearching) {
      if (v === false) {
        setSelectedStrategy([]);
      } else {
        let all: any[] = [];
        botExecutors
          .filter((item) => 
            showLive ? item.market === market && parseFloat(item.bep.pos_size) != 0 
            : item.market === market && item.status === status
          )
          .filter((item) =>
            item?.symbol.toLowerCase().includes(query.toLowerCase())
          )
          .forEach((item) => {
            all.push(item);
          });
        setSelectedStrategy(all);
      }
    } else {
      if (v === false) {
        setSelectedStrategy([]);
      } else {
        let all: any[] = [];
        botExecutors
          .filter((item) => 
            showLive ? item.market === market && parseFloat(item.bep.pos_size) != 0 
            : item.market === market && item.status === status
          )
          .forEach((item) => {
            all.push(item);
          });
        setSelectedStrategy(all);
      }
    }
  };
  const selectLong = (v: boolean) => {
    setOperation("long");
    if (isSearching) {
      if (v === false && operation === "long") {
        setSelectedStrategy([]);
      } else {
        let all: any[] = [];
        botExecutors
          .filter((item) => 
            showLive ? item.market === market && parseFloat(item.bep.pos_size) != 0 
            : item.market === market && item.status === status
          )
          .filter((item) =>
            item?.symbol.toLowerCase().includes(query.toLowerCase())
          )
          .filter((item) => item.pos_side === "1")
          .forEach((i) => {
            all.push(i);
          });
        setSelectedStrategy(all);
      }
    } else {
      if (v === false && operation === "long") {
        setSelectedStrategy([]);
      } else {
        let all: any[] = [];
        botExecutors
          .filter((item) => 
            showLive ? item.market === market && parseFloat(item.bep.pos_size) != 0 
            : item.market === market && item.status === status
          )
          ?.filter((item) => item.pos_side === "1")
          .forEach((i) => {
            all.push(i);
          });
        setSelectedStrategy(all);
      }
    }
  };
  const selectShort = (v: boolean) => {
    setOperation("short");
    if (isSearching) {
      if (v === false && operation === "short") {
        setSelectedStrategy([]);
      } else {
        let all: any[] = [];
        botExecutors
          .filter((item) => 
            showLive ? item.market === market && parseFloat(item.bep.pos_size) != 0 
            : item.market === market && item.status === status
          )
          .filter((item) =>
            item?.symbol.toLowerCase().includes(query.toLowerCase())
          )
          .filter((item) => item.pos_side === "-1")
          .forEach((i) => {
            all.push(i);
          });
        setSelectedStrategy(all);
      }
    } else {
      if (v === false && operation === "short") {
        setSelectedStrategy([]);
      } else {
        let all: any[] = [];
        botExecutors
          .filter((item) => 
            showLive ? item.market === market && parseFloat(item.bep.pos_size) != 0 
            : item.market === market && item.status === status
          )
          ?.filter((item) => item.pos_side === "-1")
          .forEach((i) => {
            all.push(i);
          });
        setSelectedStrategy(all);
      }
    }
  };

  const {
    updateBotExecutorStatus,
    updateBotExecutorReentryStatus,
    getBotExecutors,
    updateBotExecutorRamaining,
    updateBotExecutorInitialFund,
  } = useTradingContext();
  const [showModal, setShowModal] = useState(false);
  const [loops, setLoops] = useState(0);
  const [ModalText, setModalText] = useState("");
  const [editInitFund, setEditInitFund] = useState(initFund);

  const handleChangeText = (text) => {
    if (!text || /^\d+$/.test(text)) {
        setLoops(text);
      }
  };

  const handleChangeInitFundText = (text) => {
      setEditInitFund(text);
  };

  return (
    <View
      style={{
        elevation: 10,
        shadowOpacity: 10,
        paddingHorizontal: 15,
        paddingVertical: 10,
        // padding: openOperation ? 20 : 20,
        // alignItems: "center",
        // justifyContent: "space-between",
      }}
    >
      {openOperation && (
        <View
          style={{
            marginBottom: 10,
            borderRadius: 6,
            backgroundColor: "#FFF8F4",
            padding: 10,
            width: "100%",
          }}
        >
          <View
            style={{
              marginBottom: 10,
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <ApText
              style={{
                color: "#F37021",
                fontSize: 12,
                fontWeight: "500",
              }}
              translateKey="PolicyOperations"
            />

            <View style={{ display: "flex", flexDirection: "row" }}>
              <ApText
                style={{
                  fontSize: 12,
                  color: "#F37021",
                  fontWeight: "500",
                  textAlign: "right",
                }}
                translateKey="SelectedBot"
              />
              <ApText> </ApText>
              <ApText
                style={{
                  fontSize: 12,
                  color: "#F37021",
                  fontWeight: "500",
                  textAlign: "right",
                }}
              >
                {selectedStrategy.length}
              </ApText>
            </View>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <TouchableOpacity
              onPress={() => {
                updateBotExecutorStatus({
                  bex_ids: selectedStrategy.map(i => i?.bex_id).toString(),
                  status: "1",
                }).then(() => getBotExecutors(market));
              }}
              style={{flex:1}}>
              <View style={{ alignItems: "center" }}>
                <Image
                  source={require("../../../../../assets/icons/Running.png")}
                  style={{ width: 36, height: 36 }}
                />
                <ApText
                  style={{ fontSize: 11, paddingTop: 1 }}
                  translateKey="Run"
                />
              </View>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                updateBotExecutorStatus({
                  bex_ids: selectedStrategy.map(i => i?.bex_id).toString(),
                  status: "0",
                }).then(() => getBotExecutors(market));
              }}
              style={{flex:1}}>
              <View style={{ alignItems: "center" }}>
                <Image
                  source={require("../../../../../assets/icons/Paused.png")}
                  style={{ width: 36, height: 36 }}
                />
                <ApText
                  style={{ fontSize: 11, paddingTop: 1 }}
                  translateKey="Pause"
                />
              </View>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                updateBotExecutorReentryStatus({
                  bex_ids: selectedStrategy.map(i => i?.bex_id).toString(),
                  is_reentry: "1",
                }).then(() => getBotExecutors(market));
              }}
              style={{flex:1}}>
              <View style={{ alignItems: "center" }}>
                <Image
                  source={require("../../../../../assets/icons/TurnOn.png")}
                  style={{ width: 36, height: 36 }}
                />
                <ApText
                  style={{ fontSize: 11, paddingTop: 1 }}
                  translateKey="TurnOn"
                />
              </View>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                updateBotExecutorReentryStatus({
                  bex_ids: selectedStrategy.map(i => i?.bex_id).toString(),
                  is_reentry: "0",
                }).then(() => getBotExecutors(market));
              }}
              style={{flex:1}}>
              <View style={{ alignItems: "center" }}>
                <Image
                  source={require("../../../../../assets/icons/TurnOff.png")}
                  style={{ width: 36, height: 36 }}
                />
                <ApText
                  style={{ fontSize: 11, paddingTop: 1 }}
                  translateKey="TurnOff"
                />
              </View>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => (setShowModal(true), setModalText('FirstOrderText'), setEditInitFund(initFund))} style={{flex:1}}>
                <View style={{ alignItems: "center" }}>
                  <Image
                    source={require("../../../../../assets/icons/icon_coins.png")}
                    style={{ width: 36, height: 36 }}
                  />
                  <ApText
                    style={{ fontSize: 11, paddingTop: 1 }}
                    translateKey="FirstOrderMargin"
                  />
                </View>
            </TouchableOpacity>
          </View>
          <View
            style={{
              marginVertical: 10,
              flexDirection: "row",
              // justifyContent: "space-between",
            }}
          >
              <TouchableOpacity onPress={()=>(setShowModal(true), setModalText('SingleText'), setLoops(0))} style={{flex:1}}>
                <View style={{ alignItems: "center" }}>
                  <Image
                    source={require("../../../../../assets/icons/icon_one.png")}
                    style={{ width: 36, height: 36 }}
                  />
                  <ApText
                    style={{ fontSize: 11, paddingTop: 1 }}
                    translateKey="Single"
                  />
                </View>
              </TouchableOpacity>
              <TouchableOpacity onPress={()=>(setShowModal(true), setModalText('CycleText'), setLoops(9999))} style={{flex:1}}>
                <View style={{ alignItems: "center" }}>
                  <Image
                    source={require("../../../../../assets/icons/icon_repeat.png")}
                    style={{ width: 36, height: 36 }}
                  />
                  <ApText
                    style={{ fontSize: 11, paddingTop: 1 }}
                    translateKey="Cycle"
                  />
                </View>
              </TouchableOpacity> 
              <TouchableOpacity onPress={() => setShowTermModal(true)} style={{flex:1}}>
              <View style={{ alignItems: "center" }}>
                <Image
                  source={require("../../../../../assets/icons/Terminate.png")}
                  style={{ width: 36, height: 36 }}
                />
                <ApText
                  style={{ fontSize: 11, paddingTop: 1 }}
                  translateKey="Terminate"
                />
              </View>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => (setShowModal(true), setModalText('DeleteAllText'))} style={{flex:1}}>
                <View style={{ alignItems: "center" }}>
                <Image
                    source={require("../../../../../assets/icons/icon_trash.png")}
                    style={{ width: 36, height: 36 }}
                />
                <ApText
                    style={{ fontSize: 11, paddingTop: 1 }}
                    translateKey="DeleteAll"
                />
                </View>
            </TouchableOpacity>
            <View  style={{flex:1}}/>
          </View>
          <ApOperationsInput
            market={market}
            selectShort={selectShort}
            selectLong={selectLong}
            selectAll={selectAll}
          />
        </View>
      )}

      <ApButton
        style={{ width: "100%", marginTop: 0 }}
        round="lg"
        labelTranslateKey={openOperation ? "Done" : "BatchOperation"}
        label={openOperation ? "Done" : "BatchOperation"}
        onPress={() => {
          openOperation ? setSelectedStrategy([]) : "";
          setOpenOperation(!openOperation);
        }}
      />

      {showModal && (
        <ApModalAlt transparent={true} visible={showModal}>
          <>
            <TouchableOpacity
              onPress={() => {
                setShowModal(false);
              }}
              style={{ alignItems: "flex-end", padding: 15, paddingBottom: 0 }}
            >
              <svg.closeSvg />
            </TouchableOpacity>

            <View
              style={{
                paddingTop: 0,
                paddingVertical: 20,
                alignItems: "center",
                paddingHorizontal: 20,
              }}
            >
              <ApText
                size="lg"
                style={{
                  marginTop: -5,
                  color: "#F37021",
                  fontWeight: "bold",
                  marginBottom: 5,
                }}
                translateKey="Notice"
              >
              </ApText>
              <ApText
                size="sm"
                style={{ textAlign: "center" }}
                translateKey={ModalText}
              />
              { ModalText == 'CycleText' && 
                <TextInput
                textAlign="center"
                placeholderTextColor={theme.COLORS.white}
                style={{...style.textInput}}
                value={loops.toString()}
                onChangeText={handleChangeText}
                />
                }
                { ModalText == 'FirstOrderText' && 
                <TextInput
                textAlign="center"
                placeholderTextColor={theme.COLORS.white}
                style={{...style.textInput}}
                value={editInitFund.toString()}
                onChangeText={handleChangeInitFundText}
                />
                }
              <View
                style={{
                  width: "100%",
                  marginTop: 30,
                  flexDirection: "row",
                  //paddingVertical: 20,
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <TouchableOpacity
                  style={{
                    marginRight: 10,
                    borderWidth: 1,
                    borderColor: "#F37021",
                    width: "47%",
                    height: 38,
                    borderRadius: 5,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onPress={() => {
                    setShowModal(false);
                  }}
                >
                  <ApText
                    style={{ color: "#F37021", fontWeight: "500" }}
                    translateKey="Cancel"
                  />
                </TouchableOpacity>

                <TouchableOpacity
                  style={{
                    backgroundColor: "#F37021",
                    width: "47%",
                    height: 38,
                    borderRadius: 5,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onPress={() => {
                    { ModalText == 'DeleteAllText' ?
                    updateBotExecutorStatus({
                        bex_ids: selectedStrategy.map(i => i?.bex_id).toString(),
                        status: "2",
                      }).then(() => getBotExecutors(market))
                    : ModalText == 'FirstOrderText' ?
                    updateBotExecutorInitialFund({
                          bex_ids: selectedStrategy.map(i => i?.bex_id).toString(),
                          init_fund: editInitFund,
                        }).then(() => getBotExecutors(market))
                    :
                    updateBotExecutorRamaining({
                        bex_ids: selectedStrategy.map(i => i?.bex_id).toString(),
                        exec_remaining: loops,
                      }).then(() => getBotExecutors(market))
                    }
                    setShowModal(false);
                  }}
                >
                  <ApText
                    style={{ color: theme.COLORS.white, fontWeight: "500" }}
                    translateKey="Confirm"
                  />
                </TouchableOpacity>
              </View>
            </View>
          </>
        </ApModalAlt>
      )}
    </View>
  );
};

const style = StyleSheet.create({
  textInput: {
    backgroundColor: "#DFE3EA",
    color: "#F37021",
    textAlign: "center",
    fontSize: 18,
    borderRadius: 6,
    padding:5,
    marginTop:5,
  },
});