import AsyncStorage from "@react-native-async-storage/async-storage";
import { StackActions, useNavigation } from "@react-navigation/native";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Image,
  ImageBackground,
  ScrollView,
  TouchableOpacity,
  View,
} from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import Modal from "react-native-modal";
import { useDispatch } from "react-redux";
import { components } from "../components";
import { ApLoader, ApRow, ApSafeAreaView } from "../components/v1";
import { ApText } from "../components/v1/typography";
import { theme } from "../constants";
import { LANGUAGES } from "../constants/master";
import { useProfileState } from "../context";
import { setScreen } from "../store/tabSlice";
import { svg } from "../svg";

const Profile: React.FC = () => {
  const { getProfileData, setLang, profileData, loading, newNickName } =
    useProfileState();
  const { i18n, t } = useTranslation();
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [selectedCard, setSelectedCard] = useState(i18n.language);
  const navigation = useNavigation();
  const dispatch = useDispatch();

  useEffect(() => {
    getProfileData();
  }, []);

  const profilePic = () => {
    if (profileData.profile_img) {
      return (
        <Image
          source={{ uri: profileData.profile_img }}
          style={{
            width: 70,
            height: 70,
            borderRadius: 35,
            marginRight: 16,
          }}
        />
      );
    } else {
      return (
        <Image
          source={require("../../assets/users/08.png")}
          style={{
            width: 70,
            height: 70,
            borderRadius: 35,
            marginRight: 16,
          }}
        />
      );
    }
  };

  const paymentTypes = [
    {
      id: "1",
      type: t("MyAssets"),
      // bgColor: "#F37021",
      icon: <svg.AssetSvg />,
      onclick: () => navigation.navigate("MyAssets" as never),
    },
    {
      id: "2",
      type: t("Commission"),
      // bgColor: "#FF8A71",
      icon: <svg.CommissionSvg />,
      onclick: () => navigation.navigate("Commission" as never),
    },
    {
      id: "3",
      type: t("Community"),
      // bgColor: "#55ACEE",
      icon: <svg.TeamSvg />,
      onclick: () => navigation.navigate("Community" as never),
    },
  ];

  const languageSwitch = async (item: any) => {
    if (selectedCard !== item.id) {
      setSelectedCard(item.id);
      i18n.changeLanguage(item.id);
      AsyncStorage.setItem("language", item.id);
      setLang(item.languageCode).then((rs) => {
        setShowInfoModal(false);
      });

      // const body = new FormData();
      // body.append("language", item.languageCode);

      // ApiCall.post("/profile/setLanguage", body).then((response) => {});
    }
    // setShowInfoModal(false);
  };

  const renderUserInfo = () => {
    return (
      <View
        style={{
          paddingHorizontal: 20,
          paddingVertical: 20,
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {profilePic()}
        <View style={{ width: "80%" }}>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",

              justifyContent: "space-between",
            }}
          >
            <TouchableOpacity
              style={{
                display: "flex",
                flexDirection: "row",
                maxWidth: "70%",
                justifyContent: "center",
                alignItems: "center",
                marginRight: 10,
              }}
              onPress={() => navigation.navigate("EditPersonalInfo" as never)}
            >
              {newNickName ? (
                <ApText
                  numberOfLines={1}
                  style={{
                    fontWeight: "bold",
                    color: "#222",
                    fontSize: 15,
                    lineHeight: 16 * 1.6,
                    paddingRight: 10,
                  }}
                >
                  {newNickName}
                </ApText>
              ) : (
                <ApText
                  numberOfLines={1}
                  style={{
                    fontWeight: "bold",
                    color: "#222",
                    fontSize: 15,
                    lineHeight: 16 * 1.6,
                    paddingRight: 10,
                  }}
                >
                  {profileData?.nickname ||
                    profileData?.fname ||
                    profileData?.email}
                </ApText>
              )}
              <svg.ArrowThreeSvg />
            </TouchableOpacity>
            <View
              style={{
                paddingVertical: 2,
                paddingHorizontal: 10,
                backgroundColor: profileData?.active_profile
                  ? "#BEE7DC"
                  : "#da5d5d",
                borderBottomLeftRadius: 15,
                borderTopLeftRadius: 15,
                position: "absolute",
                right: 5,
              }}
            >
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                {profileData?.active_profile && (
                  <svg.VerifySvg style={{ paddingRight: 5 }} />
                )}
                <ApText
                  style={{
                    color: profileData?.active_profile ? "#009a7b" : "white",
                    fontWeight: "500",
                    fontSize: 11,
                  }}
                  translateKey={
                    profileData?.active_profile ? `Active` : `Inactive`
                  }
                />
              </View>
            </View>
          </View>

          <ApRow justify="flex-start">
            <ApText
              numberOfLines={1}
              style={{
                fontWeight: "500",
                color: "#999999",
                fontSize: 13,
                lineHeight: 14 * 1.4,
              }}
              translateKey="UID"
            />

            <ApText
              numberOfLines={1}
              style={{
                fontWeight: "500",
                color: "#999999",
                fontSize: 13,
                lineHeight: 14 * 1.4,
              }}
            >
              : {profileData?.id}
            </ApText>
          </ApRow>

          <ApRow justify="flex-start">
            <ApText
              numberOfLines={1}
              style={{
                fontWeight: "500",
                color: "#999999",
                fontSize: 13,
                lineHeight: 14 * 1.4,
              }}
              translateKey="MembershipUntil"
            />

            <ApText
              numberOfLines={1}
              style={{
                fontWeight: "500",
                color: "#999999",
                fontSize: 13,
                lineHeight: 14 * 1.4,
              }}
            >
              :{" "}
              {profileData?.subscription_expiry
                ? moment(profileData?.subscription_expiry).format("YYYY-MM-DD")
                : "Not Available"}
            </ApText>
          </ApRow>
        </View>
      </View>
    );
  };

  const renderInfoModal = () => {
    return (
      <Modal
        isVisible={showInfoModal}
        onBackdropPress={() => setShowInfoModal(false)}
        hideModalContentWhileAnimating={true}
        backdropTransitionOutTiming={0}
        style={{ margin: 0 }}
      >
        <View
          style={{
            backgroundColor: theme.COLORS.bgColor,
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
            position: "absolute",
            width: theme.SIZES.width,
            bottom: 0,
            paddingHorizontal: 20,
            paddingTop: 30,
            paddingBottom: 54,
          }}
        >
          <ApText
            style={{
              textTransform: "capitalize",
              marginBottom: 20,
              textAlign: "center",
              ...theme.FONTS.H4,
              color: theme.COLORS.mainDark,
            }}
          >
            {t("Language")}
          </ApText>
          {LANGUAGES.map((item, index, array) => {
            return (
              <TouchableOpacity
                style={{
                  backgroundColor: theme.COLORS.white,
                  borderRadius: 10,
                  padding: 12,
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: array.length - 1 === index ? 0 : 6,
                }}
                key={index}
                onPress={() => languageSwitch(item)}
              >
                {/* <Image
                  source={require("../../assets/cards/06.png")}
                  style={{
                    width: 72,
                    height: 46,
                    marginRight: 14,
                  }}
                /> */}
                <View style={{ marginRight: "auto" }}>
                  <ApText
                    style={{
                      ...theme.FONTS.Mulish_600SemiBold,
                      fontSize: 14,
                      lineHeight: 14 * 1.6,
                      color: theme.COLORS.mainDark,
                    }}
                  >
                    {item.language}
                  </ApText>
                </View>
                <View
                  style={{
                    width: 18,
                    height: 18,
                    borderWidth: 2,
                    borderRadius: 9,
                    borderColor: "#CED6E1",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {selectedCard === item.id && (
                    <View
                      style={{
                        width: 10,
                        height: 10,
                        borderRadius: 10 / 2,
                        backgroundColor: theme.COLORS.green,
                      }}
                    />
                  )}
                </View>
              </TouchableOpacity>
            );
          })}
        </View>
      </Modal>
    );
  };

  const renderProfileCategory = () => {
    return (
      <View
        style={{ paddingHorizontal: 20, paddingVertical: 20, paddingTop: 0 }}
      >
        <TouchableOpacity
          onPress={() => navigation.navigate("Ranking" as never)}
        >
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              backgroundColor: "#FF8B7E",
              borderRadius: 25,
              paddingVertical: 4,
              paddingHorizontal: 20,
            }}
          >
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <svg.StarSvg style={{ paddingRight: 10 }} />
              <ApText
                style={{ color: "white", fontSize: 14 }}
                translateKey={`Ranks.${profileData.rank}`}
              />
            </View>

            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <ApText
                style={{ color: "white", paddingRight: 10, fontSize: 14 }}
                translateKey="View"
              />
              <svg.ArrowTwoSvg />
            </View>
          </View>
        </TouchableOpacity>
        <View
          style={{
            paddingHorizontal: 10,
            paddingVertical: 18,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {paymentTypes.map((item, index) => {
            return (
              <TouchableOpacity
                key={index}
                activeOpacity={0.8}
                onPress={item.onclick}
              >
                <View
                  style={{
                    width: 45,
                    height: 45,
                    borderRadius: 60 / 2,
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "auto",
                    paddingBottom: 6,
                  }}
                >
                  {item.icon}
                </View>
                <ApText
                  style={{
                    textAlign: "center",
                    fontWeight: "500",
                    fontSize: 12,
                    lineHeight: 12 * 1.2,
                    color: theme.COLORS.bodyTextColor,
                  }}
                >
                  {item.type}
                </ApText>
              </TouchableOpacity>
            );
          })}
        </View>
        <components.ProfileCategory
          title={t("Log")}
          icon={<svg.LogSvg />}
          rightElement={<svg.ArrowOneSvg />}
          onPress={() => navigation.navigate("Log" as never)}
        />
        <components.ProfileCategory
          title={t("Referral")}
          icon={<svg.ReferralSvg />}
          rightElement={<svg.ArrowOneSvg />}
          onPress={() => navigation.navigate("Referral" as never)}
        />
        <components.ProfileCategory
          onPress={() => setShowInfoModal(true)}
          title={t("Language")}
          icon={<svg.LanguageSvg />}
          rightElement={
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <ApText
                style={{
                  ...theme.FONTS.Mulish_400Regular,
                  fontSize: 12,
                  color: theme.COLORS.mainDark,
                  lineHeight: 12 * 1.6,
                  marginRight: 11,
                }}
              >
                {LANGUAGES.find((e) => e.id === i18n.language)?.language}
              </ApText>
              <svg.ArrowOneSvg />
            </View>
          }
        />
        <components.ProfileCategory
          title={t("HelpCenter")}
          icon={<svg.HelpSvg />}
          rightElement={<svg.ArrowOneSvg />}
          onPress={() => {
            window.open('https://lin.ee/rgW8Pz8', '_blank');
          }}
        />
        <components.ProfileCategory
          title={t("AccountSetting")}
          icon={<svg.SettingSvg />}
          rightElement={<svg.ArrowOneSvg />}
          onPress={() => {
            navigation.navigate("AccountSetting" as never);
          }}
        />

        <TouchableOpacity
          style={{
            paddingLeft: 10,
            paddingTop: 15,
            flexDirection: "row",
            alignItems: "center",
          }}
          onPress={async () => {
            // Important! Kindly don't remove/clear the emails on AsyncStorage It is show for email input suggest
            const keys = await AsyncStorage.getAllKeys();
            const keysToRemove = keys.filter((key) => key !== "emails");
            await AsyncStorage.multiRemove(keysToRemove);
            // await AsyncStorage.clear();
            dispatch(setScreen("Dashboard"));
            navigation.dispatch(StackActions.replace("SignIn"));
          }}
        >
          <svg.LogOutSvg />
          <ApText
            style={{ color: "#F37021", marginLeft: 15, fontSize: 14 }}
            translateKey="Logout"
          />
        </TouchableOpacity>
      </View>
    );
  };

  const renderContent = () => {
    return (
      <ImageBackground
        source={require("../../assets/bg/08.png")}
        imageStyle={{
          width: "100%",
          // backgroundColor: "#fff5f0ba",
        }}
        style={{ flex: 1 }}
      >
        <KeyboardAwareScrollView
          contentContainerStyle={{
            marginTop: 20,
            marginBottom: 90,
            flexGrow: 1,
            paddingHorizontal: 20,
            justifyContent: "center",
          }}
        >
          <View
            style={{
              backgroundColor: theme.COLORS.white,
              borderRadius: 10,
            }}
          >
            <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
              {renderUserInfo()}
              {renderProfileCategory()}
            </ScrollView>
          </View>
        </KeyboardAwareScrollView>
      </ImageBackground>
    );
  };

  return (
    <ApSafeAreaView>
      {/* {renderHeader()} */}
      {loading && <ApLoader />}
      {!loading && renderContent()}
      {renderInfoModal()}
    </ApSafeAreaView>
  );
};

export default Profile;
