import { LinearGradient } from "expo-linear-gradient";
import React, { useCallback, useState } from "react";
import { TouchableOpacity, View, useWindowDimensions } from "react-native";
import DropDownPicker from "react-native-dropdown-picker";
import { SceneMap } from "react-native-tab-view";
import { components } from "../components";
import { ApSafeAreaView } from "../components/v1";
import { ApText } from "../components/v1/typography";
import { theme } from "../constants";
const FirstRoute = () => <View style={{ flex: 1, backgroundColor: "#fff" }} />;
const SecondRoute = () => <View style={{ flex: 1, backgroundColor: "#fff" }} />;

const renderScene = SceneMap({
  first: FirstRoute,
  second: SecondRoute,
});

const Payments: React.FC = ({ navigation }: any) => {
  const [selected, setSelected] = useState("All");
  const [masterOrVisa, setMasterOrVisa] = useState("Visa");
  const [paymentOpen, setPaymentOpen] = useState(false);
  const layout = useWindowDimensions();
  const [index, setIndex] = React.useState(0);
  const [paymentValue, setPaymentValue] = useState(null);
  const [paymentType, setPaymentType] = useState([
    {
      value: "1",
      label: "Binance",
      //   icon: require("../assets/icons/09.png"),
    },
    {
      value: "2",
      label: "Ouyi",
      // icon: require("../assets/icons/10.png"),
    },
  ]);

  const [routes] = React.useState([
    { key: "first", title: "Whole" },
    { key: "second", title: "In execution" },
  ]);
  const renderHeader = () => {
    return <components.Header title="Martin Trend" goBack={true} />;
  };

  const onPaymentOpen = useCallback(() => {
    setPaymentOpen(false);
  }, []);

  const renderContent = () => {
    return (
      <View style={{ flex: 1, padding: 20 }}>
        <DropDownPicker
          //   style={styles.dropdown}
          style={{ marginBottom: 12 }}
          open={paymentOpen}
          value={paymentValue}
          items={paymentType}
          setOpen={setPaymentOpen}
          setValue={setPaymentValue}
          setItems={setPaymentType}
          placeholder="Select Payment"
          //   placeholderStyle={styles.placeholderStyles}
          //   onOpen={onPaymentOpen}
          //   onChangeValue={onChange}
          zIndex={3000}
          zIndexInverse={1000}
        />
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: 5,
            padding: 2,
            marginTop: 20,
            backgroundColor: theme.COLORS.white,
            marginBottom: 30,
          }}
        >
          <TouchableOpacity
            style={{
              width: "24%",
            }}
            onPress={() => setSelected("All")}
          >
            <LinearGradient
              colors={[
                selected === "All" ? "#F37021" : theme.COLORS.white,
                selected === "All" ? "#F37021" : theme.COLORS.white,
              ]}
              style={{
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 5,
                paddingVertical: 3,
              }}
              start={{ x: 0, y: 0 }}
              end={{ x: 1, y: 0 }}
            >
              <ApText
                style={{
                  ...theme.FONTS.Mulish_400Regular,
                  fontSize: 14,
                  color:
                    selected === "All"
                      ? theme.COLORS.white
                      : theme.COLORS.bodyTextColor,
                  lineHeight: 14 * 1.6,
                }}
                translateKey="Entire"
              />
            </LinearGradient>
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              width: "24%",
            }}
            onPress={() => setSelected("Runing")}
          >
            <LinearGradient
              colors={[
                selected === "Runing" ? "#F37021" : theme.COLORS.white,
                selected === "Runing" ? "#F37021" : theme.COLORS.white,
              ]}
              style={{
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 5,
                paddingVertical: 3,
              }}
              start={{ x: 0, y: 0 }}
              end={{ x: 1, y: 0 }}
            >
              <ApText
                style={{
                  ...theme.FONTS.Mulish_400Regular,
                  fontSize: 14,
                  color:
                    selected === "Runing"
                      ? theme.COLORS.white
                      : theme.COLORS.bodyTextColor,
                  lineHeight: 14 * 1.6,
                }}
                translateKey="Execute"
              />
            </LinearGradient>
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              width: "24%",
            }}
            onPress={() => setSelected("Pending")}
          >
            <LinearGradient
              colors={[
                selected === "Pending" ? "#F37021" : theme.COLORS.white,
                selected === "Pending" ? "#F37021" : theme.COLORS.white,
              ]}
              style={{
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 5,
                paddingVertical: 3,
              }}
              start={{ x: 0, y: 0 }}
              end={{ x: 1, y: 0 }}
            >
              <ApText
                style={{
                  ...theme.FONTS.Mulish_400Regular,
                  fontSize: 14,
                  color:
                    selected === "Pending"
                      ? theme.COLORS.white
                      : theme.COLORS.bodyTextColor,
                  lineHeight: 14 * 1.6,
                }}
                translateKey="NotStarted"
              />
            </LinearGradient>
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              width: "24%",
            }}
            onPress={() => setSelected("History")}
          >
            <LinearGradient
              colors={[
                selected === "History" ? "#F37021" : theme.COLORS.white,
                selected === "History" ? "#F37021" : theme.COLORS.white,
              ]}
              style={{
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 5,
                paddingVertical: 3,
              }}
              start={{ x: 0, y: 0 }}
              end={{ x: 1, y: 0 }}
            >
              <ApText
                style={{
                  ...theme.FONTS.Mulish_400Regular,
                  fontSize: 14,
                  color:
                    selected === "History"
                      ? theme.COLORS.white
                      : theme.COLORS.bodyTextColor,
                  lineHeight: 14 * 1.6,
                }}
                translateKey="ExecutionHistory"
              />
            </LinearGradient>
          </TouchableOpacity>
        </View>
        {selected == "All" ? (
          <>
            <components.SmallHeader
              title="Executing Bots"
              containerStyle={{ marginBottom: 6 }}
            />
            <View
              style={{
                padding: 20,
                paddingTop: 18,
                marginBottom: 20,
                backgroundColor: theme.COLORS.white,
                borderRadius: 10,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: 14,
                  paddingBottom: 2,
                  borderBottomWidth: 1,
                  borderBottomColor: "#CED6E1",
                }}
              >
                <View style={{ flex: 1 }}>
                  <ApText
                    style={{
                      fontSize: 16,
                      paddingBottom: 8,
                      fontWeight: "500",
                    }}
                  >
                    AVAX/USDT13.59
                  </ApText>
                </View>
                <View style={{ flex: 1 }}>
                  <ApText
                    style={{
                      fontSize: 12,
                      paddingBottom: 8,
                      textAlign: "right",
                      color: "red",
                    }}
                    translateKey="Paused"
                  />
                </View>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: 14,
                  paddingBottom: 2,
                  borderBottomWidth: 1,
                  borderBottomColor: "#CED6E1",
                }}
              >
                <View style={{ flex: 1 }}>
                  <ApText style={{ fontSize: 12, paddingBottom: 8 }}>
                    DCA Moderate Cycle Binance
                  </ApText>
                  <ApText
                    style={{ fontSize: 12, paddingBottom: 8, color: "grey" }}
                  >
                    Started Since 246D 16H 33M ago
                  </ApText>
                </View>
                <View style={{ flex: 1 }}>
                  <ApText
                    style={{
                      fontSize: 12,
                      paddingBottom: 8,
                      textAlign: "right",
                      color: "red",
                    }}
                  >
                    -274.9892USDT
                  </ApText>
                  <ApText
                    style={{
                      fontSize: 12,
                      paddingBottom: 8,
                      textAlign: "right",
                      color: "red",
                    }}
                  >
                    (-75.73%)
                  </ApText>
                </View>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "flex-start",
                }}
              >
                <View style={{ flex: 1 }}>
                  <ApText
                    style={{
                      ...theme.FONTS.Mulish_400Regular,
                      fontSize: 12,
                      lineHeight: 12 * 1.6,
                      color: theme.COLORS.bodyTextColor,
                    }}
                    translateKey="AveragePrice"
                  />
                  <ApText
                    style={{
                      marginBottom: 16,
                      ...theme.FONTS.H6,
                      color: theme.COLORS.mainDark,
                    }}
                  >
                    55.9512
                  </ApText>
                  <ApText
                    style={{
                      ...theme.FONTS.Mulish_400Regular,
                      fontSize: 12,
                      lineHeight: 12 * 1.6,
                      color: theme.COLORS.bodyTextColor,
                    }}
                    translateKey="Spent"
                  />
                  <ApText
                    style={{
                      marginBottom: 16,
                      ...theme.FONTS.H6,
                      color: theme.COLORS.mainDark,
                    }}
                  >
                    363.12USDT
                  </ApText>
                </View>
                <View style={{ flex: 1, alignItems: "center" }}>
                  <ApText
                    style={{
                      ...theme.FONTS.Mulish_400Regular,
                      fontSize: 12,
                      lineHeight: 12 * 1.6,
                      color: theme.COLORS.bodyTextColor,
                    }}
                    translateKey="Qty"
                  />
                  <ApText
                    style={{
                      marginBottom: 16,
                      ...theme.FONTS.H6,
                      color: theme.COLORS.mainDark,
                    }}
                  >
                    6.49000
                  </ApText>
                  <ApText
                    style={{
                      ...theme.FONTS.Mulish_400Regular,
                      fontSize: 12,
                      lineHeight: 12 * 1.6,
                      color: theme.COLORS.bodyTextColor,
                    }}
                    translateKey="NoOfPos"
                  />
                  <ApText
                    style={{
                      marginBottom: 16,
                      ...theme.FONTS.H6,
                      color: theme.COLORS.mainDark,
                    }}
                  >
                    5
                  </ApText>
                </View>
                <View style={{ flex: 1, alignItems: "flex-end" }}>
                  <ApText
                    style={{
                      ...theme.FONTS.Mulish_400Regular,
                      fontSize: 12,
                      lineHeight: 12 * 1.6,
                      color: theme.COLORS.bodyTextColor,
                    }}
                    translateKey="NextBuyPrice"
                  />
                  <ApText
                    style={{
                      marginBottom: 16,
                      ...theme.FONTS.H6,
                      color: theme.COLORS.mainDark,
                    }}
                  >
                    0.0000
                  </ApText>
                  <ApText
                    style={{
                      ...theme.FONTS.Mulish_400Regular,
                      fontSize: 12,
                      lineHeight: 12 * 1.6,
                      color: theme.COLORS.bodyTextColor,
                    }}
                    translateKey="NextSellPrice"
                  />
                  <ApText
                    style={{
                      marginBottom: 16,
                      ...theme.FONTS.H6,
                      color: theme.COLORS.mainDark,
                    }}
                  >
                    56.7903
                  </ApText>
                </View>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <TouchableOpacity
                  onPress={() => navigation.navigate("EditStrategy")}
                  style={{
                    backgroundColor: "#56B700",
                    height: 40,
                    borderRadius: 5,
                    alignItems: "center",
                    width: "33.33%",
                    justifyContent: "center",
                  }}
                >
                  <ApText
                    style={{ color: theme.COLORS.white }}
                    translateKey="EditingStrategy"
                  />
                </TouchableOpacity>
                <TouchableOpacity
                  style={{
                    backgroundColor: "#5347B2",
                    height: 40,
                    borderRadius: 5,
                    alignItems: "center",
                    justifyContent: "center",
                    width: "33.33%",
                    marginHorizontal: 12,
                  }}
                  onPress={() => navigation.navigate("TradeHistory")}
                >
                  <ApText
                    style={{ color: theme.COLORS.white }}
                    translateKey="Log"
                  />
                </TouchableOpacity>
                <TouchableOpacity
                  // onPress={() => navigation.navigate("TransactionSetting")}
                  style={{
                    backgroundColor: "#F44336",
                    height: 40,
                    borderRadius: 5,
                    alignItems: "center",
                    width: "33.33%",
                    justifyContent: "center",
                  }}
                >
                  <ApText
                    style={{ color: theme.COLORS.white }}
                    translateKey="Delete"
                  />
                </TouchableOpacity>
              </View>
            </View>
            <View
              style={{
                padding: 20,
                paddingTop: 18,
                marginBottom: 20,
                backgroundColor: theme.COLORS.white,
                borderRadius: 10,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: 14,
                  paddingBottom: 2,
                  borderBottomWidth: 1,
                  borderBottomColor: "#CED6E1",
                }}
              >
                <View style={{ flex: 1 }}>
                  <ApText
                    style={{
                      fontSize: 16,
                      paddingBottom: 8,
                      fontWeight: "500",
                    }}
                  >
                    DOT/USDT5.159
                  </ApText>
                </View>
                <View style={{ flex: 1 }}>
                  <ApText
                    style={{
                      fontSize: 12,
                      paddingBottom: 8,
                      textAlign: "right",
                      color: "red",
                    }}
                    translateKey="Paused"
                  />
                </View>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: 14,
                  paddingBottom: 2,
                  borderBottomWidth: 1,
                  borderBottomColor: "#CED6E1",
                }}
              >
                <View style={{ flex: 1 }}>
                  <ApText
                    style={{ fontSize: 12, paddingBottom: 8 }}
                    translateKey="DCAModerateCycleBinance"
                  />
                  <ApText
                    style={{ fontSize: 12, paddingBottom: 8, color: "grey" }}
                  >
                    Started Since 246D 16H 33M ago
                  </ApText>
                </View>
                <View style={{ flex: 1 }}>
                  <ApText
                    style={{
                      fontSize: 12,
                      paddingBottom: 8,
                      textAlign: "right",
                      color: "red",
                    }}
                  >
                    -274.9892USDT
                  </ApText>
                  <ApText
                    style={{
                      fontSize: 12,
                      paddingBottom: 8,
                      textAlign: "right",
                      color: "red",
                    }}
                  >
                    (-75.73%)
                  </ApText>
                </View>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "flex-start",
                }}
              >
                <View style={{ flex: 1 }}>
                  <ApText
                    style={{
                      ...theme.FONTS.Mulish_400Regular,
                      fontSize: 12,
                      lineHeight: 12 * 1.6,
                      color: theme.COLORS.bodyTextColor,
                    }}
                    translateKey="AveragePrice"
                  />
                  <ApText
                    style={{
                      marginBottom: 16,
                      ...theme.FONTS.H6,
                      color: theme.COLORS.mainDark,
                    }}
                  >
                    55.9512
                  </ApText>
                  <ApText
                    style={{
                      ...theme.FONTS.Mulish_400Regular,
                      fontSize: 12,
                      lineHeight: 12 * 1.6,
                      color: theme.COLORS.bodyTextColor,
                    }}
                    translateKey="Spent"
                  />

                  <ApText
                    style={{
                      marginBottom: 16,
                      ...theme.FONTS.H6,
                      color: theme.COLORS.mainDark,
                    }}
                  >
                    363.12USDT
                  </ApText>
                </View>
                <View style={{ flex: 1, alignItems: "center" }}>
                  <ApText
                    style={{
                      ...theme.FONTS.Mulish_400Regular,
                      fontSize: 12,
                      lineHeight: 12 * 1.6,
                      color: theme.COLORS.bodyTextColor,
                    }}
                    translateKey="Qty"
                  />

                  <ApText
                    style={{
                      marginBottom: 16,
                      ...theme.FONTS.H6,
                      color: theme.COLORS.mainDark,
                    }}
                  >
                    6.49000
                  </ApText>
                  <ApText
                    style={{
                      ...theme.FONTS.Mulish_400Regular,
                      fontSize: 12,
                      lineHeight: 12 * 1.6,
                      color: theme.COLORS.bodyTextColor,
                    }}
                    translateKey="NoOfPos."
                  />
                  <ApText
                    style={{
                      marginBottom: 16,
                      ...theme.FONTS.H6,
                      color: theme.COLORS.mainDark,
                    }}
                  >
                    5
                  </ApText>
                </View>
                <View style={{ flex: 1, alignItems: "flex-end" }}>
                  <ApText
                    style={{
                      ...theme.FONTS.Mulish_400Regular,
                      fontSize: 12,
                      lineHeight: 12 * 1.6,
                      color: theme.COLORS.bodyTextColor,
                    }}
                    translateKey="NextBuyPrice"
                  />
                  <ApText
                    style={{
                      marginBottom: 16,
                      ...theme.FONTS.H6,
                      color: theme.COLORS.mainDark,
                    }}
                  >
                    0.0000
                  </ApText>
                  <ApText
                    style={{
                      ...theme.FONTS.Mulish_400Regular,
                      fontSize: 12,
                      lineHeight: 12 * 1.6,
                      color: theme.COLORS.bodyTextColor,
                    }}
                    translateKey="NextSellPrice"
                  />
                  <ApText
                    style={{
                      marginBottom: 16,
                      ...theme.FONTS.H6,
                      color: theme.COLORS.mainDark,
                    }}
                  >
                    56.7903
                  </ApText>
                </View>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <TouchableOpacity
                  style={{
                    backgroundColor: "#56B700",
                    height: 40,
                    borderRadius: 5,
                    alignItems: "center",
                    width: "33.33%",
                    justifyContent: "center",
                  }}
                >
                  <ApText
                    style={{ color: theme.COLORS.white }}
                    translateKey="EditingStrategy"
                  />
                </TouchableOpacity>
                <TouchableOpacity
                  style={{
                    backgroundColor: "#5347B2",
                    height: 40,
                    borderRadius: 5,
                    alignItems: "center",
                    justifyContent: "center",
                    width: "33.33%",
                    marginHorizontal: 12,
                  }}
                >
                  <ApText
                    style={{ color: theme.COLORS.white }}
                    translateKey="Log"
                  />
                </TouchableOpacity>
                <TouchableOpacity
                  // onPress={() => navigation.navigate("TransactionSetting")}
                  style={{
                    backgroundColor: "#F44336",
                    height: 40,
                    borderRadius: 5,
                    alignItems: "center",
                    width: "33.33%",
                    justifyContent: "center",
                  }}
                >
                  <ApText
                    style={{ color: theme.COLORS.white }}
                    translateKey="Delete"
                  />
                </TouchableOpacity>
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              <TouchableOpacity
                // onPress={() => navigation.navigate("EditStrategy")}
                style={{
                  backgroundColor: "#fff",
                  height: 45,
                  borderColor: "#EC7366",
                  borderWidth: 1,
                  borderRadius: 5,
                  alignItems: "center",
                  width: "48%",
                  justifyContent: "center",
                }}
              >
                <ApText
                  style={{ color: "#EC7366" }}
                  translateKey="BatchOperation"
                />
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => navigation.navigate("EditStrategy")}
                style={{
                  backgroundColor: "#EC7366",
                  height: 45,
                  borderRadius: 5,
                  alignItems: "center",
                  width: "48%",
                  justifyContent: "center",
                }}
              >
                <ApText
                  style={{ color: theme.COLORS.white }}
                  translateKey="CreateContract"
                />
              </TouchableOpacity>
            </View>
          </>
        ) : selected == "Runing" ? (
          <>
            <components.SmallHeader
              title="Executing Bots"
              containerStyle={{ marginBottom: 6 }}
            />
          </>
        ) : (
          <>
            <components.SmallHeader
              title="Executing Bots"
              containerStyle={{ marginBottom: 6 }}
            />
          </>
        )}
      </View>
    );
  };

  return (
    <ApSafeAreaView>
      {renderHeader()}
      {renderContent()}
    </ApSafeAreaView>
  );
};

export default Payments;
