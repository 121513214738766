import React from "react";
import ContentLoader from "react-content-loader";
import { useWindowDimensions } from "react-native";
import { Rect } from "react-native-svg";

export const ApHorizontalLoader = () => {
  const dim = useWindowDimensions();
  const width = dim.width - 30;
  return (
    <ContentLoader
      speed={2}
      // width={476}
      height={600}
      // viewBox="0 0 476 600"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <Rect x="15" y="21" rx="10" ry="10" width={width} height="58" />
      <Rect x="15" y="83" rx="10" ry="10" width={width} height="58" />
      <Rect x="14" y="342" rx="10" ry="10" width={width} height="58" />
      <Rect x="14" y="277" rx="10" ry="10" width={width} height="58" />
      <Rect x="15" y="146" rx="10" ry="10" width={width} height="58" />
      <Rect x="14" y="211" rx="10" ry="10" width={width} height="58" />
      <Rect x="15" y="407" rx="10" ry="10" width={width} height="58" />
      <Rect x="18" y="471" rx="10" ry="10" width={width} height="58" />
      <Rect x="18" y="535" rx="10" ry="10" width={width} height="58" />
    </ContentLoader>
  );
};
