// import { Toast, Popup, SPSheet } from "react-native-popup-confirm-toast";
import Toast from 'react-native-toast-message';

export const ApSuccessToast = (title: string, message: string) => {
  Toast.show({
    type: 'success',
    text1: title,
    text2: message,
      
  });
};

export const ApErrorToast = (title: string, message: string) => {
  Toast.show({
    type: 'error',
    text1: title,
    text2: message,
  })
};
