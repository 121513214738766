import React from "react";
import { ActivityIndicator, Text, View } from "react-native";
import { ApListThumbnailLoader } from "./list-thumbnail";
import { ApPostLoader } from "./post";
import { ApFolderLoader } from "./folder";
import { ApHorizontalLoader } from "./horizontal";
import { ApBlockLoader } from "./block";
import { ApFlexBoxLoader } from "./flex-box";
import { ApAssetLoader } from "./asset";
import { ApProfileLoader } from "./profle";
import { ApDoubouLoader } from "./doubou";
import { ApIncomeRankingLoader } from "./income-ranking";
import { ApTradeLoader } from "./trade";
export type ApLoaderTypes =
  | "ListLeftThumbnail"
  | "Post"
  | "Folder"
  | "Horizontal"
  | "Block"
  | "FlexBox"
  | "Asset"
  | "Profile"
  | "Doubou"
  | "IncomeRanking"
  | "Trade";

interface IProps {
  count?: number;
  type?: ApLoaderTypes;
}

export const ApLoader: React.FC<IProps> = ({ count = 1, type }) => {
  const Render = () => {
    switch (type) {
      case "ListLeftThumbnail":
        return <ApListThumbnailLoader />;
      case "Post":
        return <ApPostLoader />;
      case "Folder":
        return <ApFolderLoader />;
      case "Horizontal":
        return <ApHorizontalLoader />;
      case "Block":
        return <ApBlockLoader />;
      case "FlexBox":
        return <ApFlexBoxLoader />;
      case "Asset":
        return <ApAssetLoader />;
      case "Profile":
        return <ApProfileLoader />;
      case "Doubou":
        return <ApDoubouLoader />;
      case "IncomeRanking":
        return <ApIncomeRankingLoader />;
      case "Trade":
        return <ApTradeLoader />;
      default:
        return (
          <View
            style={{
              alignItems: "center",
              justifyContent: "center",
              flex: 1,
              marginTop: 30,
            }}
          >
            <ActivityIndicator size="large" color="#F37021" />
          </View>
        );
    }
  };

  return (
    <View>
      {Array.from({ length: count }, (v, i) => i + 1).map((m, i) => (
        <Render key={i} />
      ))}
    </View>
  );
};
