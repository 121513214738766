import * as React from "react";
import Svg, { G, Path, Circle } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" style={{marginLeft: -2}} width={20} height={20} viewBox="0 0 19.25 19.25" {...props}>
      <G data-name="Group 129">
        <Path
          data-name="Path 87"
          d="M26.716 32.079a1.879 1.879 0 01-1.512-.708 1.993 1.993 0 01-.4-1.547l.419-2.666-.1.05a.986.986 0 01-.9-1.753l1.822-.937a.985.985 0 011.424 1.029l-.716 4.558a2.874 2.874 0 001.2-.584.985.985 0 011.125 1.618 4.487 4.487 0 01-2.256.937c-.032.003-.069.003-.106.003z"
          transform="translate(-4.5 -4.5) translate(11.217 11.468) translate(-23.692 -24.408)"
          fill="#F37021"
        />
      </G>
      <G data-name="Group 130">
        <Circle data-name="Ellipse 37" cx={1.1} cy={1.1} r={1.1} fill="#F37021" transform="translate(-4.5 -4.5) translate(13.306 8.557)" />
      </G>
      <G data-name="Group 132">
        <G data-name="Group 131">
          <Path
            data-name="Path 88"
            d="M14.125 23.75a9.625 9.625 0 119.625-9.625 9.636 9.636 0 01-9.625 9.625zm0-17.279a7.654 7.654 0 107.654 7.654 7.662 7.662 0 00-7.654-7.654z"
            transform="translate(-4.5 -4.5) translate(4.5 4.5) translate(-4.5 -4.5)"
            fill="#7a8390"
          />
        </G>
      </G>
    </Svg>
  );
}

export default SvgComponent;
