import React from "react";
import { View, TouchableOpacity } from "react-native";
import { LinearGradient } from "react-native-svg";
import { ApText } from "./typography";
import { MARKET_TYPES } from "../../constants/master";

interface IProps {
  selected: string;
  onSelect?: (val: any) => void;
}

export const ApMarketType: React.FC<IProps> = ({ selected, onSelect }) => {
  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        borderRadius: 5,
        padding: 2,
        marginBottom: 5,
      }}
    >
      {MARKET_TYPES.map((m, i) => (
        <TouchableOpacity
          key={i}
          onPress={() => onSelect(m.value)}
          style={{
            display:"flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 5,
            paddingVertical: 3,
          }}
        >
          <ApText
            translateKey={m.labelKey}
            style={{
              fontWeight: "500",
              fontSize: 14,
              color: m.value === selected ? "#F37021" : "#D0CFCE",
              lineHeight: 14 * 1.6,
            }}
          >
            {m.label}
          </ApText>
          {(i + 1) !== MARKET_TYPES.length && <ApText style={{padding: 10, color:"#D0CFCE"}}>|</ApText>}
        </TouchableOpacity>
      ))}
    </View>
  );
};
