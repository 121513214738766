import React from "react";
import { Image, ScrollView, TouchableOpacity, View } from "react-native";

import { components } from "../components";
import { ApSafeAreaView } from "../components/v1";
import { ApText } from "../components/v1/typography";
import { theme } from "../constants";

const PaymentFailed: React.FC = ({ navigation }: any) => {
  const renderBackground = () => {
    return (
      <View>
        <Image
          source={require("../../assets/bg/05.png")}
          style={{
            width: "100%",
            height: 530,
            position: "absolute",
            top: 90,
          }}
        />
      </View>
    );
  };

  const renderContent = () => {
    return (
      <ScrollView
        contentContainerStyle={{ marginBottom: 70, flexGrow: 1 }}
        showsVerticalScrollIndicator={false}
      >
        <View style={{ marginTop: 190 }}>
          <Image
            source={require("../../assets/other-icons/22.png")}
            style={{ width: 161, height: 150, alignSelf: "center" }}
          />
          <ApText
            style={{
              textAlign: "center",
              ...theme.FONTS.H2,
              color: theme.COLORS.linkColor,
              marginBottom: 20,
            }}
            translateKey="Oops"
          />
          <ApText
            style={{
              textAlign: "center",
              ...theme.FONTS.Mulish_700Bold,
              fontSize: 22,
              color: theme.COLORS.mainDark,
              marginBottom: 20,
            }}
          >
            $ 364.00
          </ApText>
          <ApText
            style={{
              textAlign: "center",
              ...theme.FONTS.Mulish_400Regular,
              fontSize: 16,
              color: theme.COLORS.bodyTextColor,
              marginBottom: theme.SIZES.height * 0.17,
            }}
            translateKey="SomethingWentWrong"
          />
          <View style={{ marginHorizontal: 20 }}>
            <TouchableOpacity
              style={{
                height: 50,
                width: "100%",
                borderWidth: 1,
                borderRadius: 10,
                justifyContent: "center",
                alignItems: "center",
                borderColor: "#CED6E1",
                marginBottom: 14,
              }}
              onPress={() => navigation.navigate("Home")}
            >
              <ApText
                style={{
                  ...theme.FONTS.Mulish_600SemiBold,
                  fontSize: 14,
                  color: theme.COLORS.mainDark,
                }}
                translateKey="BackToDashboard"
              />
            </TouchableOpacity>
          </View>

          <components.Button
            title="Try Again"
            containerStyle={{ paddingHorizontal: 20 }}
          />
        </View>
      </ScrollView>
    );
  };

  return (
    <View style={{ flex: 1, backgroundColor: theme.COLORS.bgColor }}>
      {renderBackground()}
      <ApSafeAreaView style={{ flex: 1 }}>{renderContent()}</ApSafeAreaView>
    </View>
  );
};

export default PaymentFailed;
