import { useNavigation } from "@react-navigation/native";
import { Form, Formik, FormikProps } from "formik";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Image, ScrollView, TouchableOpacity, View } from "react-native";
import Toast, { BaseToast, ErrorToast } from "react-native-toast-message";
import * as Yup from "yup";
import { components } from "../components";
import { ApModalAlt } from "../components/Modal";
import { ApButton, ApSafeAreaView } from "../components/v1";
import { ApCheckInputAlt, ApTextInput } from "../components/v1/input";
import { ApText } from "../components/v1/typography";
import { theme } from "../constants";
import { ERROR_MSG } from "../constants/errors";
import { useConfigState, useProfileState } from "../context";
import { useMembershipState } from "../context/membership";
import { svg } from "../svg";
import { toCurrency } from "../utils";

const FormSchema = Yup.object().shape({
  sec_password: Yup.string().required(ERROR_MSG.SecurityPassRequired),
});
const PuchaseFormSchema = Yup.object().shape({
  agree: Yup.bool().isTrue(ERROR_MSG.AgreeTerms).required(ERROR_MSG.AgreeTerms),
});
const MemberPurchase2: React.FC = () => {
  const navigation = useNavigation();
  const { createMembership, getMembership } = useMembershipState();
  const { getConfig, config } = useConfigState();
  const { profileData, getProfileData } = useProfileState();
  const [showBindModal, setShowBindModal] = useState(false);

  const formRef = useRef<FormikProps<any>>();

  useEffect(() => {
    getProfileData();
    getConfig();
  }, []);

  const handleConfirm = (values: any) => {
    createMembership(values).then((rs) => {
      setShowBindModal(false);
      getMembership();
      getProfileData();
      navigation.navigate("Membership" as never);
    });
  };

  const toastConfig = {
    success: (props: any) => (
      <BaseToast
        {...props}
        text2NumberOfLines={2000}
        style={{
          borderLeftColor: "green",
          backgroundColor: "green",
          height: "auto",
          paddingTop: 5,
          paddingBottom: 5,
        }}
        contentContainerStyle={{ paddingHorizontal: 15, height: "auto" }}
        text1Style={{
          fontSize: 17,
          color: "white",
        }}
        text2Style={{
          fontSize: 15,
          color: "white",
        }}
      />
    ),
    /*
      Overwrite 'error' type,
      by modifying the existing `ErrorToast` component
    */
    error: (props: any) => (
      <ErrorToast
        {...props}
        text2NumberOfLines={2000}
        style={{
          borderLeftColor: "red",
          backgroundColor: "red",
          height: "auto",
          paddingTop: 5,
          paddingBottom: 5,
        }}
        contentContainerStyle={{ paddingHorizontal: 15, height: "auto" }}
        text1Style={{
          fontSize: 17,
          color: "white",
        }}
        text2Style={{
          fontSize: 15,
          color: "white",
        }}
      />
    ),
  };

  const renderHeader = () => {
    return (
      <components.Header
        title="Membership Purchase"
        goBack={true}
        purchaseHistory={true}
        titleTranlateKey="AccountActivation"
      />
    );
  };

  const renderContent = () => {
    return (
      <View style={{ flex: 1 }}>
        <ScrollView
          contentContainerStyle={{ flexGrow: 1, paddingHorizontal: 20 }}
        >
          <View
            style={{
              backgroundColor: "#FFD9CF",
              padding: 12,
              borderRadius: 10,
              marginTop: 15,
              marginBottom: 5,
              flexDirection: "row",
            }}
          >
            <ApText
              style={{
                color: "#F37021",
                width: "80%",
                fontSize: 12,
                lineHeight: 20,
              }}
              translateKey="TradePageNote"
            />
            <Image
              source={require("../../assets/icons/account-hint.png")}
              style={{ width: 86, height: 57 }}
            />
          </View>
          <View
            style={{
              borderRadius: 12,
              backgroundColor: "#FCF8F6",
              marginTop: 15,
            }}
          >
            <Image
              source={require("../../assets/purchase-bg.png")}
              style={{
                // opacity: "0.6",
                width: 115,
                height: 104,
                position: "absolute",
                bottom: 0,
                right: 0,
              }}
            />
            <View style={{ padding: 15 }}>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingVertical: 10,
                }}
              >
                <ApText
                  style={{ fontWeight: "500" }}
                  translateKey="ValidityPeriod"
                />
                <ApText style={{ color: "#F37021" }} translateKey="1Year" />
              </View>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingVertical: 10,
                }}
              >
                <ApText
                  style={{ fontWeight: "500" }}
                  translateKey="NextExpiryDate"
                />
                <ApText style={{ color: "#F37021" }}>
                  {moment(profileData?.subscription_expiry || moment())
                    .add(1, "year")
                    .format("YYYY-MM-DD")}
                </ApText>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingVertical: 10,
                }}
              >
                <ApText style={{ fontWeight: "500" }} translateKey="Fee" />
                <ApText style={{ color: "#F37021" }}>
                  {config?.subscription_cost} USDT
                </ApText>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingVertical: 10,
                }}
              >
                <ApText
                  style={{ fontWeight: "500" }}
                  translateKey="USDTBalance"
                />
                <ApText style={{ color: "#F37021" }}>
                  {toCurrency(parseFloat(profileData?.cash_wallet))}
                </ApText>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingVertical: 10,
                }}
              >
                <ApText style={{ fontWeight: "500" }} translateKey="Bonus" />
                <ApText style={{ color: "#F37021" }}>
                  {config?.subscription_cost *
                    parseInt(config.subscription_bonus)}
                </ApText>
              </View>
            </View>
          </View>
        </ScrollView>

        <Formik
          innerRef={formRef as any}
          validationSchema={PuchaseFormSchema}
          initialValues={{ agree: "" }}
          onSubmit={() => {
            setShowBindModal(!showBindModal);
          }}
        >
          {(props: FormikProps<any>) => (
            <Form>
              <View
                style={{
                  paddingHorizontal: 20,
                  paddingVertical: 20,
                  width: "100%",
                  flex: 0.08,
                  position: "absolute",
                  bottom: 0,
                  backgroundColor: "white",
                }}
              >
                <ApCheckInputAlt
                  noBorder
                  name="agree"
                  labelTranslateKey="NotRefundableMsg"
                  checkBoxStyle={{ marginRight: 15 }}
                  labelStyle={{
                    fontSize: 10,
                    color: "#F37021",
                    padding: 15,
                  }}
                />

                <ApButton
                  round="lg"
                  onPress={() => props.handleSubmit()}
                  label={"Confirm Purchase"}
                  labelTranslateKey="ConfirmPurchase"
                  // disabled={!props.values.agree}
                />
              </View>
            </Form>
          )}
        </Formik>

        {/* <Dialog
          dialogStyle={{width: "90%"}}
          visible={showBindModal}
          dialogAnimation={
            new SlideAnimation({
              slideFrom: "bottom",
            })
          }
        >
          <View style={{position: 'absolute', top: 0}}><Toast/></View>
          <RenderConfirmDialog />
        </Dialog> */}
      </View>
    );
  };

  const RenderConfirmDialog = () => (
    <>
      <TouchableOpacity
        onPress={() => {
          setShowBindModal(false);
        }}
        style={{ alignItems: "flex-end", padding: 15, paddingBottom: 0 }}
      >
        <Toast config={toastConfig} topOffset={20} />
        <svg.closeSvg />
      </TouchableOpacity>
      {/* <DialogContent> */}
      <View
        style={{
          alignItems: "center",
          paddingHorizontal: 20,
          paddingBottom: 20,
        }}
      >
        <ApText
          style={{
            color: "#F37021",
            fontWeight: "bold",
            marginBottom: 5,
            fontSize: 18,
          }}
          translateKey="Notice"
        />

        <ApText
          style={{ fontSize: 14, fontWeight: "500", marginBottom: 10 }}
          translateKey="ConfirmMemberShipPurchase"
        />

        <Formik
          innerRef={formRef as any}
          validationSchema={FormSchema}
          initialValues={{ sec_password: "" }}
          onSubmit={handleConfirm}
        >
          {(props: FormikProps<any>) => (
            <>
              <ApTextInput
                label="Security Password"
                name="sec_password"
                placeholder="••••••"
                labelTranslateKey="SecurityPassword"
                secureTextEntry
                icon={
                  <TouchableOpacity>
                    <svg.EyeOffSvg />
                  </TouchableOpacity>
                }
                containerStyle={{
                  width: "100%",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              />

              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                  marginTop: 10,
                }}
              >
                <TouchableOpacity
                  style={{
                    borderColor: "#F37021",
                    borderWidth: 1,
                    width: "47%",
                    height: 38,
                    borderRadius: 5,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onPress={() => {
                    setShowBindModal(false);
                  }}
                >
                  <ApText
                    style={{ color: "#F37021", fontWeight: "500" }}
                    translateKey="No"
                  />
                </TouchableOpacity>
                <TouchableOpacity
                  style={{
                    backgroundColor: "#F37021",
                    width: "47%",
                    height: 38,
                    borderRadius: 5,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onPress={() => {
                    props.handleSubmit();
                  }}
                >
                  <ApText
                    style={{ color: theme.COLORS.white, fontWeight: "500" }}
                    translateKey="Yes"
                  />
                </TouchableOpacity>
              </View>
            </>
          )}
        </Formik>
      </View>
      {/* </DialogContent> */}
    </>
  );

  return (
    <ApSafeAreaView>
      {renderHeader()}
      {renderContent()}

      <ApModalAlt transparent={true} visible={showBindModal}>
        {RenderConfirmDialog()}
      </ApModalAlt>
    </ApSafeAreaView>
  );
};

export default MemberPurchase2;
