import { applyMiddleware, combineReducers, configureStore, createStore } from "@reduxjs/toolkit";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { persistStore, persistReducer } from "redux-persist";
import thunk from "redux-thunk";

import cartReducer from "./cartSlice";
import wishlistReducer from "./wishlistSlice";
import tabReducer from "./tabSlice";
import authReducer from "./authSlice";
import hideProfitSlice from "./hideProfitSlice";

// const store = configureStore({
//   reducer: {
//     cart: cartReducer,
//     wishlist: wishlistReducer,
//     tab: tabReducer,
//     auth: authReducer,
//     hideProfitSlice: hideProfitSlice,
//   },
// });

const persistConfig = {
  key: "root",
  storage: AsyncStorage,
  whitelist: ["hideProfit"],
};

const rootReducer = combineReducers({
  cart: cartReducer,
  wishlist: wishlistReducer,
  tab: tabReducer,
  auth: authReducer,
  hideProfitSlice: persistReducer(persistConfig, hideProfitSlice),
});

export const store = createStore(rootReducer, applyMiddleware(thunk));
export const persistor = persistStore(store);
