import { LinearGradient } from "expo-linear-gradient";
import React, { useEffect, useState } from "react";
import { Image, TouchableOpacity, View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { RefreshControl } from "react-native-web-refresh-control";
import { components } from "../components";
import { ApLoader, ApSafeAreaView } from "../components/v1";
import { ApText } from "../components/v1/typography";
import { theme } from "../constants";
import { useProfileState } from "../context";
import { toCurrency } from "../utils";
const IncomeRanking: React.FC = ({ navigation }: any) => {
  const { leadershipBoard, getLeadershipBoard, loading } = useProfileState();
  const [selected, setSelected] = useState("Daily");
  const [refreshing, setRefreshing] = useState(false);
  useEffect(() => {
    getLeadershipBoard();
  }, []);

  const handleRefresh = () => {
    setRefreshing(true);
    getLeadershipBoard().finally(() => {
      setRefreshing(false);
    });
  };

  const renderHeader = () => {
    return (
      <components.Header
        title="Income Ranking"
        titleTranlateKey="IncomeRanking"
        goBack={true}
      />
    );
  };

  const renderContent = () => {
    return (
      <ScrollView
        refreshControl={
          <RefreshControl refreshing={loading} onRefresh={handleRefresh} />
        }
      >
        <View style={{ flex: 1 }}>
          <View
            style={{ backgroundColor: "#F37021", padding: 20, height: 285 }}
          >
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                borderRadius: 50,
                padding: 3,
                marginTop: 20,
                backgroundColor: theme.COLORS.white,
                marginBottom: 30,
              }}
            >
              <TouchableOpacity
                style={{
                  width: "50%",
                }}
                onPress={() => setSelected("Daily")}
              >
                <LinearGradient
                  colors={[
                    selected === "Daily" ? "#F37021" : theme.COLORS.white,
                    selected === "Daily" ? "#F37021" : theme.COLORS.white,
                  ]}
                  style={{
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 50,
                    paddingVertical: 3,
                  }}
                  start={{ x: 0, y: 0 }}
                  end={{ x: 1, y: 0 }}
                >
                  <ApText
                    style={{
                      ...theme.FONTS.Mulish_400Regular,
                      fontSize: 14,
                      color:
                        selected === "Daily" ? theme.COLORS.white : "#F37021",
                      lineHeight: 14 * 1.6,
                    }}
                    translateKey="DailyLeaderboard"
                  />
                </LinearGradient>
              </TouchableOpacity>
              <TouchableOpacity
                style={{
                  width: "50%",
                }}
                onPress={() => setSelected("Monthly")}
              >
                <LinearGradient
                  colors={[
                    selected === "Monthly" ? "#F37021" : theme.COLORS.white,
                    selected === "Monthly" ? "#F37021" : theme.COLORS.white,
                  ]}
                  style={{
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 50,
                    paddingVertical: 3,
                  }}
                  start={{ x: 0, y: 0 }}
                  end={{ x: 1, y: 0 }}
                >
                  <ApText
                    style={{
                      ...theme.FONTS.Mulish_400Regular,
                      fontSize: 14,
                      color:
                        selected === "Monthly" ? theme.COLORS.white : "#F37021",
                      lineHeight: 14 * 1.6,
                    }}
                    translateKey="MonthlyRanking"
                  />
                </LinearGradient>
              </TouchableOpacity>
            </View>
            {selected == "Daily" ? (
              <>
                {!!leadershipBoard?.day?.length && (
                  <View
                    style={{
                      marginBottom: 25,
                      borderRadius: 10,
                      flexDirection: "row",
                      justifyContent: "space-between",
                      paddingHorizontal: 10,
                      alignContent: "flex-end",
                      alignItems: "flex-end",
                    }}
                  >
                    <View style={{ alignItems: "center" }}>
                      <Image
                        source={require("../../assets/users/Silver.png")}
                        style={{ width: 23, height: 16, bottom: -1 }}
                      />
                      <Image
                        source={require("../../assets/users/09.png")}
                        style={{ width: 55, height: 55 }}
                      />
                      <ApText
                        style={{
                          fontWeight: "400",
                          paddingTop: 5,
                          fontSize: 13,
                          color: "#fff",
                          width: 80,
                          textAlign: "center",
                        }}
                        numberOfLines={1}
                      >
                        {leadershipBoard?.day[1]?.nickname}
                      </ApText>
                      <ApText
                        style={{
                          fontWeight: "bold",
                          paddingTop: 3,
                          fontSize: 13,
                          color: "#fff",
                        }}
                      >
                        {toCurrency(
                          +leadershipBoard.day[1]?.today_self_pnl_amt
                        )}
                      </ApText>
                    </View>
                    <View style={{ alignItems: "center" }}>
                      <Image
                        source={require("../../assets/users/Champion.png")}
                        style={{ width: 25, height: 18, bottom: -1 }}
                      />
                      <Image
                        source={require("../../assets/users/08.png")}
                        style={{ width: 75, height: 75 }}
                      />
                      <ApText
                        style={{
                          fontWeight: "400",
                          paddingTop: 5,
                          fontSize: 13,
                          color: "#fff",
                          width: 80,
                          textAlign: "center",
                        }}
                        numberOfLines={1}
                      >
                        {leadershipBoard?.day[0]?.nickname}
                      </ApText>
                      <ApText
                        style={{
                          fontWeight: "bold",
                          paddingTop: 3,
                          fontSize: 13,
                          color: "#fff",
                        }}
                      >
                        {toCurrency(
                          +leadershipBoard?.day[0]?.today_self_pnl_amt
                        )}
                      </ApText>
                    </View>
                    <View style={{ alignItems: "center" }}>
                      <Image
                        source={require("../../assets/users/Bronze.png")}
                        style={{ width: 23, height: 16, bottom: -1 }}
                      />
                      <Image
                        source={require("../../assets/users/10.png")}
                        style={{ width: 55, height: 55 }}
                      />
                      <ApText
                        style={{
                          fontWeight: "400",
                          paddingTop: 5,
                          fontSize: 13,
                          color: "#fff",
                          width: 80,
                          textAlign: "center",
                        }}
                        numberOfLines={1}
                      >
                        {leadershipBoard?.day[2]?.nickname}
                      </ApText>
                      <ApText
                        style={{
                          fontWeight: "bold",
                          paddingTop: 3,
                          fontSize: 13,
                          color: "#fff",
                        }}
                      >
                        {toCurrency(
                          +leadershipBoard?.day[2]?.today_self_pnl_amt
                        )}
                      </ApText>
                    </View>
                  </View>
                )}
              </>
            ) : selected == "Monthly" ? (
              <>
                {!!leadershipBoard?.month?.length && (
                  <View
                    style={{
                      marginBottom: 25,
                      borderRadius: 10,
                      flexDirection: "row",
                      justifyContent: "space-between",
                      paddingHorizontal: 10,
                      alignContent: "flex-end",
                      alignItems: "flex-end",
                    }}
                  >
                    <View style={{ alignItems: "center" }}>
                      <Image
                        source={require("../../assets/users/Silver.png")}
                        style={{ width: 23, height: 16, bottom: -1 }}
                      />
                      <Image
                        source={require("../../assets/users/09.png")}
                        style={{ width: 55, height: 55 }}
                      />
                      <ApText
                        style={{
                          fontWeight: "400",
                          paddingTop: 5,
                          fontSize: 13,
                          color: "#fff",
                          width: 80,
                          textAlign: "center",
                        }}
                        numberOfLines={1}
                      >
                        {leadershipBoard?.month[1]?.nickname}
                      </ApText>
                      <ApText
                        style={{
                          fontWeight: "bold",
                          paddingTop: 3,
                          fontSize: 13,
                          color: "#fff",
                        }}
                      >
                        {toCurrency(
                          +leadershipBoard?.month[1]?.month_self_pnl_amt
                        )}
                      </ApText>
                    </View>
                    <View style={{ alignItems: "center" }}>
                      <Image
                        source={require("../../assets/users/Champion.png")}
                        style={{ width: 25, height: 18, bottom: -1 }}
                      />
                      <Image
                        source={require("../../assets/users/08.png")}
                        style={{ width: 75, height: 75 }}
                      />
                      <ApText
                        style={{
                          fontWeight: "400",
                          paddingTop: 5,
                          fontSize: 13,
                          color: "#fff",
                          width: 80,
                          textAlign: "center",
                        }}
                        numberOfLines={1}
                      >
                        {leadershipBoard?.month[0]?.nickname}
                      </ApText>
                      <ApText
                        style={{
                          fontWeight: "bold",
                          paddingTop: 3,
                          fontSize: 13,
                          color: "#fff",
                        }}
                      >
                        {toCurrency(
                          +leadershipBoard?.month[0]?.month_self_pnl_amt
                        )}
                      </ApText>
                    </View>
                    <View style={{ alignItems: "center" }}>
                      <Image
                        source={require("../../assets/users/Bronze.png")}
                        style={{ width: 23, height: 16, bottom: -1 }}
                      />
                      <Image
                        source={require("../../assets/users/10.png")}
                        style={{ width: 55, height: 55 }}
                      />
                      <ApText
                        style={{
                          fontWeight: "400",
                          paddingTop: 5,
                          fontSize: 13,
                          color: "#fff",
                          width: 80,
                          textAlign: "center",
                        }}
                        numberOfLines={1}
                      >
                        {leadershipBoard?.month[2]?.nickname}
                      </ApText>
                      <ApText
                        style={{
                          fontWeight: "bold",
                          paddingTop: 3,
                          fontSize: 13,
                          color: "#fff",
                        }}
                      >
                        {toCurrency(
                          +leadershipBoard?.month[2]?.month_self_pnl_amt
                        )}
                      </ApText>
                    </View>
                  </View>
                )}
              </>
            ) : (
              <></>
            )}
          </View>
          {selected == "Daily" ? (
            <View style={{ padding: 20 }}>
              {leadershipBoard?.day?.map((e, index) => {
                return (
                  <View
                    key={`${index}_day`}
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      paddingVertical: 8,
                      borderBottomWidth: 1,
                      borderColor: "#e4e4e499",
                    }}
                  >
                    <ApText
                      style={{
                        width: "10%",
                        color: "#F37021",
                        fontWeight: "500",
                      }}
                    >
                      {index + 1}
                    </ApText>
                    <ApText style={{ width: "50%", fontWeight: "500" }}>
                      {e.nickname}
                    </ApText>
                    <ApText
                      style={{
                        width: "30%",
                        fontWeight: "500",
                        color: "#F37021",
                      }}
                    >
                      {toCurrency(+e.today_self_pnl_amt)}
                    </ApText>
                    <ApText style={{ width: "10%" }}>
                      {index == 0 ? (
                        <Image
                          source={require("../../assets/users/Champion.png")}
                          style={{ width: 25, height: 18 }}
                        />
                      ) : index == 1 ? (
                        <Image
                          source={require("../../assets/users/Silver.png")}
                          style={{ width: 25, height: 18 }}
                        />
                      ) : (
                        <Image
                          source={require("../../assets/users/Bronze.png")}
                          style={{ width: 25, height: 18 }}
                        />
                      )}
                    </ApText>
                  </View>
                );
              })}
            </View>
          ) : (
            <View style={{ padding: 20 }}>
              {leadershipBoard?.month?.map((e, index) => {
                return (
                  <View
                    key={`${index}_month`}
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      paddingVertical: 8,
                      borderBottomWidth: 1,
                      borderColor: "#e4e4e499",
                    }}
                  >
                    <ApText
                      style={{
                        width: "10%",
                        color: "#F37021",
                        fontWeight: "500",
                      }}
                    >
                      {index + 1}
                    </ApText>
                    <ApText style={{ width: "50%", fontWeight: "500" }}>
                      {e.nickname}
                    </ApText>
                    <ApText
                      style={{
                        width: "30%",
                        fontWeight: "500",
                        color: "#F37021",
                      }}
                    >
                      {toCurrency(+e.month_self_pnl_amt)}
                    </ApText>

                    <ApText style={{ width: "10%" }}>
                      {index < 3 &&
                        (index == 0 ? (
                          <Image
                            source={require("../../assets/users/Champion.png")}
                            style={{ width: 25, height: 18 }}
                          />
                        ) : index == 1 ? (
                          <Image
                            source={require("../../assets/users/Silver.png")}
                            style={{ width: 25, height: 18 }}
                          />
                        ) : (
                          <Image
                            source={require("../../assets/users/Bronze.png")}
                            style={{ width: 25, height: 18 }}
                          />
                        ))}
                    </ApText>
                  </View>
                );
              })}
            </View>
          )}
        </View>
      </ScrollView>
    );
  };

  return (
    <ApSafeAreaView>
      {renderHeader()}
      {loading ? <ApLoader /> : renderContent()}
    </ApSafeAreaView>
  );
};

export default IncomeRanking;
