import { LinearGradient } from "expo-linear-gradient";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Image,
  ImageBackground,
  ScrollView,
  TouchableOpacity,
  View,
} from "react-native";
import { components } from "../components";
import {
  ApEmptyState,
  ApEmptyStateSvg,
  ApLoader,
  ApRow,
  ApSafeAreaView,
} from "../components/v1";
import { ApText } from "../components/v1/typography";
import { theme } from "../constants";
import { useProfileState, useTransactionState } from "../context";
import {
  IWalletTransaction,
  IWalletTransactionSummary,
} from "../models/transaction.interface";
import { TransactionService } from "../services/Transaction";
import { svg } from "../svg";
import { toCurrency } from "../utils";

const MyAssets: React.FC = ({ navigation }: any) => {
  const { getProfileData, profileData } = useProfileState();
  const {
    walletTransaction,
    getWalletTransaction,
    loading,
    loadMoreBtnDisplay,
    setBackInitialState,
    sortUSDT,
    sortCreditPoint,
    sortDLPoint,
    sortStakedDDCoin,
    sortDDCoin,
    sortBonusPoint,
    summaryUSDT,
    summaryCreditPoint,
    summaryDLPoint,
    summaryStakedDDCoin,
    summaryDDCoin,
    summaryBonusPoint,
    getUSDT,
    getCreditPoint,
    getStakedDDCoin,
    getDDCoin,
    getDLPoint,
    getBonusPoint,
    setSelectedWallet,
  } = useTransactionState();

  //frozen USDT
  const [sortFrozenUSDT, setSortFrozenUSDT] =
    useState<Record<string, IWalletTransaction[]>>();

  const { i18n, t } = useTranslation();
  const [selected, setSelected] = useState("CreditBalance");

  const getFrozenUSDT = useCallback(async () => {
    getWalletTransaction({}, "comm_wallet").then((rs) => {
      setSortFrozenUSDT(
        TransactionService.sortWalletTransactionByDate(
          rs as unknown as IWalletTransaction[]
        )
      );
    });
  }, []);

  useEffect(() => {
    setBackInitialState();
    getProfileData();
    getCreditPoint();
  }, []);

  const loadMore = () => {
    if (selected === "CreditBalance") {
      getCreditPoint(1);
    }
    if (selected === "USDT") {
      getUSDT(1);
    }
    if (selected === "BONUS") {
      getBonusPoint(1);
    }
    if (selected === "FROZENUSDT") {
      getFrozenUSDT(1);
    }
    if (selected === "DDCOIN") {
      getDDCoin(1);
    }
    if (selected === "DLPOINT") {
      getDLPoint(1);
    }
    if (selected === "STAKED_DDCOIN") {
      getStakedDDCoin(1);
    }
  };

  const renderHeader = () => {
    return (
      <components.Header
        title={"MyAssets"}
        titleTranlateKey="MyAssets"
        goBack={true}
      />
    );
  };

  const renderContent = () => {
    return (
      <View style={{ flex: 1 }}>
        <View
          style={{ backgroundColor: "#fff", padding: 20, paddingBottom: 0 }}
        >
          <ScrollView>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                borderRadius: 50,
                padding: 3,
                backgroundColor: theme.COLORS.white,
                marginBottom: 20,
              }}
            >
              <ScrollView
                horizontal={true}
                showsHorizontalScrollIndicator={false}
              >
                <TouchableOpacity
                  onPress={() => {
                    setSelected("CreditBalance");
                    getCreditPoint();
                  }}
                >
                  <LinearGradient
                    colors={[
                      selected === "CreditBalance"
                        ? "transparent"
                        : "transparent",
                      selected === "CreditBalance"
                        ? "transparent"
                        : "transparent",
                    ]}
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 50,
                      paddingVertical: 3,
                      paddingRight: 25,
                    }}
                    start={{ x: 0, y: 0 }}
                    end={{ x: 1, y: 0 }}
                  >
                    <ApText
                      style={{
                        ...theme.FONTS.Mulish_400Regular,
                        fontSize: 14,
                        color:
                          selected === "CreditBalance" ? "#F37021" : "#F37021",
                        lineHeight: 14 * 1.6,
                        fontWeight:
                          selected === "CreditBalance" ? "bold" : "normal",
                        borderBottomWidth: selected === "CreditBalance" ? 2 : 2,
                        borderBottomColor:
                          selected === "CreditBalance" ? "#F37021" : "#fff",
                      }}
                      translateKey="CreditPoint"
                    />
                  </LinearGradient>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => {
                    setSelected("USDT");
                    getUSDT();
                  }}
                >
                  <LinearGradient
                    colors={[
                      selected === "USDT" ? "transparent" : "transparent",
                      selected === "USDT" ? "transparent" : "transparent",
                    ]}
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 50,
                      paddingVertical: 3,
                      paddingRight: 25,
                    }}
                    start={{ x: 0, y: 0 }}
                    end={{ x: 1, y: 0 }}
                  >
                    <ApText
                      style={{
                        ...theme.FONTS.Mulish_400Regular,
                        fontSize: 14,
                        color: selected === "USDT" ? "#F37021" : "#F37021",
                        lineHeight: 14 * 1.6,
                        fontWeight: selected === "USDT" ? "bold" : "normal",
                        borderBottomWidth: selected === "USDT" ? 2 : 2,
                        borderBottomColor:
                          selected === "USDT" ? "#F37021" : "#fff",
                      }}
                    >
                      USDT
                    </ApText>
                  </LinearGradient>
                </TouchableOpacity>

                <TouchableOpacity
                  onPress={() => {
                    setSelected("BONUS");
                    getBonusPoint();
                  }}
                >
                  <LinearGradient
                    colors={[
                      selected === "BONUS" ? "transparent" : "transparent",
                      selected === "BONUS" ? "transparent" : "transparent",
                    ]}
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 50,
                      paddingVertical: 3,
                      paddingRight: 25,
                    }}
                    start={{ x: 0, y: 0 }}
                    end={{ x: 1, y: 0 }}
                  >
                    <ApText
                      style={{
                        ...theme.FONTS.Mulish_400Regular,
                        fontSize: 14,
                        color: selected === "BONUS" ? "#F37021" : "#F37021",
                        lineHeight: 14 * 1.6,
                        fontWeight: selected === "BONUS" ? "bold" : "normal",
                        borderBottomWidth: selected === "BONUS" ? 2 : 2,
                        borderBottomColor:
                          selected === "BONUS" ? "#F37021" : "#fff",
                      }}
                      translateKey="Bonus"
                    />
                  </LinearGradient>
                </TouchableOpacity>
                {/* <TouchableOpacity onPress={() => setSelected("FROZENUSDT")}>
                  <LinearGradient
                    colors={[
                      selected === "FROZENUSDT" ? "transparent" : "transparent",
                      selected === "FROZENUSDT" ? "transparent" : "transparent",
                    ]}
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 50,
                      paddingVertical: 3,
                      paddingRight: 25,
                    }}
                    start={{ x: 0, y: 0 }}
                    end={{ x: 1, y: 0 }}
                  >
                    <ApText
                      style={{
                        ...theme.FONTS.Mulish_400Regular,
                        fontSize: 14,
                        color:
                          selected === "FROZENUSDT" ? "#F37021" : "#F37021",
                        lineHeight: 14 * 1.6,
                        fontWeight:
                          selected === "FROZENUSDT" ? "bold" : "normal",
                        borderBottomWidth: selected === "FROZENUSDT" ? 2 : 2,
                        borderBottomColor:
                          selected === "FROZENUSDT" ? "#F37021" : "#fff",
                      }}
                    >
                      {t("FrozenUSDT")}
                    </ApText>
                  </LinearGradient>
                </TouchableOpacity> */}
                <TouchableOpacity
                  onPress={() => {
                    setSelected("DDCOIN");
                    getDDCoin();
                  }}
                >
                  <LinearGradient
                    colors={[
                      selected === "DDCOIN" ? "transparent" : "transparent",
                      selected === "DDCOIN" ? "transparent" : "transparent",
                    ]}
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 50,
                      paddingVertical: 3,
                      paddingRight: 25,
                    }}
                    start={{ x: 0, y: 0 }}
                    end={{ x: 1, y: 0 }}
                  >
                    <ApText
                      style={{
                        ...theme.FONTS.Mulish_400Regular,
                        fontSize: 14,
                        color: selected === "DDCOIN" ? "#F37021" : "#F37021",
                        lineHeight: 14 * 1.6,
                        fontWeight: selected === "DDCOIN" ? "bold" : "normal",
                        borderBottomWidth: selected === "DDCOIN" ? 2 : 2,
                        borderBottomColor:
                          selected === "DDCOIN" ? "#F37021" : "#fff",
                      }}
                      translateKey="DDToken"
                    />
                  </LinearGradient>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => {
                    setSelected("DLPOINT");
                    getDLPoint();
                  }}
                >
                  <LinearGradient
                    colors={[
                      selected === "DLPOINT" ? "transparent" : "transparent",
                      selected === "DLPOINT" ? "transparent" : "transparent",
                    ]}
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 50,
                      paddingVertical: 3,
                      paddingRight: 25,
                    }}
                    start={{ x: 0, y: 0 }}
                    end={{ x: 1, y: 0 }}
                  >
                    <ApText
                      style={{
                        ...theme.FONTS.Mulish_400Regular,
                        fontSize: 14,
                        color: selected === "DLPOINT" ? "#F37021" : "#F37021",
                        lineHeight: 14 * 1.6,
                        fontWeight: selected === "DLPOINT" ? "bold" : "normal",
                        borderBottomWidth: selected === "DLPOINT" ? 2 : 2,
                        borderBottomColor:
                          selected === "DLPOINT" ? "#F37021" : "#fff",
                      }}
                      translateKey="DLPoint"
                    />
                  </LinearGradient>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => {
                    setSelected("STAKED_DDCOIN");
                    getStakedDDCoin();
                  }}
                >
                  <LinearGradient
                    colors={[
                      selected === "STAKED_DDCOIN"
                        ? "transparent"
                        : "transparent",
                      selected === "STAKED_DDCOIN"
                        ? "transparent"
                        : "transparent",
                    ]}
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 50,
                      paddingVertical: 3,
                    }}
                    start={{ x: 0, y: 0 }}
                    end={{ x: 1, y: 0 }}
                  >
                    <ApText
                      style={{
                        ...theme.FONTS.Mulish_400Regular,
                        fontSize: 14,
                        color:
                          selected === "STAKED_DDCOIN" ? "#F37021" : "#F37021",
                        lineHeight: 14 * 1.6,
                        fontWeight:
                          selected === "STAKED_DDCOIN" ? "bold" : "normal",
                        borderBottomWidth: selected === "STAKED_DDCOIN" ? 2 : 2,
                        borderBottomColor:
                          selected === "STAKED_DDCOIN" ? "#F37021" : "#fff",
                      }}
                      translateKey="StakedDDCoin"
                    />
                  </LinearGradient>
                </TouchableOpacity>
              </ScrollView>
            </View>
          </ScrollView>
        </View>
        <View>
          {selected == "CreditBalance" ? (
            <>
              <ImageBackground
                style={{ marginHorizontal: 20 }}
                source={require("../../assets/assets-bg.png")}
                imageStyle={{
                  borderRadius: 12,
                  width: "100%",
                  backgroundColor: "#fff5f0ba",
                  resizeMode: "contain",
                }}
              >
                <View
                  style={{
                    width: "100%",
                    borderRadius: 10,
                    padding: 20,
                  }}
                >
                  <ApText
                    style={{ textAlign: "right", fontSize: 14 }}
                    translateKey="CreditBalance"
                  />
                  <ApText
                    style={{
                      textAlign: "right",
                      color: "#F37021",
                      fontWeight: "bold",
                      fontSize: 28,
                    }}
                  >
                    {toCurrency(+profileData?.credit_wallet, "", 4)}
                  </ApText>
                </View>
              </ImageBackground>

              <View
                style={{
                  backgroundColor: "#FFD9CF",
                  padding: 12,
                  borderRadius: 10,
                  marginTop: 15,
                  marginBottom: 5,
                  flexDirection: "row",
                  marginHorizontal: 20,
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <ApText
                  style={{
                    color: "#F37021",
                    fontSize: 13,
                    flex: 1,
                    flexWrap: "wrap",
                  }}
                  translateKey="CreditPointPlaceholder"
                />
                {/* <View
                  style={{
                    flexDirection: "row",
                  }}
                >

                  {/* <TouchableOpacity
                    style={{
                      borderRadius: 6,
                      paddingVertical: 4,
                      paddingHorizontal: 10,
                      backgroundColor: "#E45F50",
                    }}
                    onPress={() => {
                      setSelectedWallet("credit_wallet")
                      navigation.navigate("Convert")
                    }}
                  >
                    <ApText
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        fontSize: 12,
                      }}
                    >
                      {t("Convert")}
                    </ApText>
                  </TouchableOpacity> 
                </View> */}

                <Image
                  source={require("../../assets/icons/assets-hint.png")}
                  style={{ width: 60, height: 53 }}
                />
              </View>

              <RenderHistoryList
                transactions={sortCreditPoint}
                summary={summaryCreditPoint}
                emptyMessage="You do not have credit point history"
              />
            </>
          ) : selected == "USDT" ? (
            <>
              <ImageBackground
                style={{ marginHorizontal: 20 }}
                source={require("../../assets/assets-bg.png")}
                imageStyle={{
                  borderRadius: 12,
                  width: "100%",
                  backgroundColor: "#fff5f0ba",
                  resizeMode: "contain",
                }}
              >
                <View
                  style={{
                    width: "100%",
                    borderRadius: 10,
                    padding: 15,
                  }}
                >
                  <ApText
                    style={{ textAlign: "right", fontSize: 14 }}
                    translateKey="USDTBalance"
                  />
                  <ApText
                    style={{
                      textAlign: "right",
                      color: "#F37021",
                      fontWeight: "bold",
                      fontSize: 28,
                    }}
                  >
                    {toCurrency(+profileData?.cash_wallet, "", 4)}
                  </ApText>
                  <View
                    style={{
                      flexDirection: "row",
                      paddingTop: 16,
                      justifyContent: "space-between",
                    }}
                  >
                    <TouchableOpacity
                      style={{
                        borderRadius: 6,
                        paddingVertical: 4,
                        paddingHorizontal: 8,
                        backgroundColor: "#E45F50",
                      }}
                      onPress={() => {
                        setSelectedWallet("cash_wallet");
                        navigation.navigate(`Deposit`, { type: "usdt" });
                      }}
                    >
                      <ApText
                        style={{
                          color: "#fff",
                          textAlign: "center",
                          fontSize: 12,
                        }}
                      >
                        {t("Deposit")}
                      </ApText>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={{
                        borderRadius: 6,
                        paddingVertical: 4,
                        paddingHorizontal: 8,
                        backgroundColor: "#E45F50",
                      }}
                      onPress={() => {
                        setSelectedWallet("cash_wallet");
                        navigation.navigate("Transfer");
                      }}
                    >
                      <ApText
                        style={{
                          color: "#fff",
                          textAlign: "center",
                          fontSize: 12,
                        }}
                      >
                        {t("Transfer")}
                      </ApText>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={{
                        borderRadius: 6,
                        paddingVertical: 4,
                        paddingHorizontal: 8,
                        backgroundColor: "#E45F50",
                      }}
                      onPress={() => {
                        setSelectedWallet("cash_wallet");
                        navigation.navigate("Convert");
                      }}
                    >
                      <ApText
                        style={{
                          color: "#fff",
                          textAlign: "center",
                          fontSize: 12,
                        }}
                      >
                        {t("Convert")}
                      </ApText>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={{
                        borderRadius: 6,
                        paddingVertical: 4,
                        paddingHorizontal: 8,
                        backgroundColor: "#E45F50",
                      }}
                      onPress={() => {
                        setSelectedWallet("cash_wallet");
                        navigation.navigate("Withdraw");
                      }}
                    >
                      <ApText
                        style={{
                          color: "#fff",
                          textAlign: "center",
                          fontSize: 12,
                        }}
                      >
                        {t("Withdraw")}
                      </ApText>
                    </TouchableOpacity>
                  </View>
                </View>
              </ImageBackground>

              <RenderHistoryList
                transactions={sortUSDT}
                summary={summaryUSDT}
                emptyMessage=""
              />
            </>
          ) : selected == "BONUS" ? (
            <>
              <ImageBackground
                style={{ marginHorizontal: 20 }}
                source={require("../../assets/assets-bg.png")}
                imageStyle={{
                  borderRadius: 12,
                  width: "100%",
                  backgroundColor: "#fff5f0ba",
                  resizeMode: "contain",
                }}
              >
                <View
                  style={{
                    width: "100%",
                    borderRadius: 10,
                    padding: 20,
                  }}
                >
                  <ApText
                    style={{ textAlign: "right", fontSize: 14 }}
                    translateKey="BonusPoint"
                  />
                  <ApText
                    style={{
                      textAlign: "right",
                      color: "#F37021",
                      fontWeight: "bold",
                      fontSize: 28,
                    }}
                  >
                    {toCurrency(+profileData?.bonus_wallet, "", 4)}
                  </ApText>
                </View>
              </ImageBackground>

              <View
                style={{
                  backgroundColor: "#FFD9CF",
                  padding: 12,
                  borderRadius: 10,
                  marginTop: 15,
                  marginBottom: 5,
                  flexDirection: "row",
                  marginHorizontal: 20,
                  alignItems: "center",
                }}
              >
                <ApText
                  style={{ color: "#F37021", width: "85%", fontSize: 13 }}
                  translateKey="BonusPointPlaceholder"
                />
                <Image
                  source={require("../../assets/icons/assets-hint.png")}
                  style={{ width: 60, height: 53 }}
                />
              </View>
              <RenderHistoryList
                transactions={sortBonusPoint}
                summary={summaryBonusPoint}
                emptyMessage="NoTransactionFound"
              />
            </>
          ) : // : selected == "FROZENUSDT" ? (
          //   <>
          //     <ImageBackground
          //       style={{ marginHorizontal: 20 }}
          //       source={require("../../assets/assets-bg.png")}
          //       imageStyle={{
          //         borderRadius: 12,
          //         width: "100%",
          //         backgroundColor: "#fff5f0ba",
          //         resizeMode: "contain",
          //       }}
          //     >
          //       <View
          //         style={{
          //           width: "100%",
          //           borderRadius: 10,
          //           padding: 20,
          //         }}
          //       >
          //         <ApText style={{ textAlign: "right", fontSize: 14 }}>
          //           Frozen USDT Balance
          //         </ApText>
          //         <ApText
          //           style={{
          //             textAlign: "right",
          //             color: "#F37021",
          //             fontWeight: "bold",
          //             fontSize: 28,
          //           }}
          //         >
          //           {profileData?.comm_wallet}
          //         </ApText>
          //       </View>
          //       <TouchableOpacity
          //         style={{
          //           borderRadius: 6,
          //           paddingVertical: 8,
          //           paddingHorizontal: 10,
          //           backgroundColor: "#E45F50",
          //         }}
          //         onPress={() => {
          //           navigation.navigate("Convert", {
          //             convertType: "commToCash",
          //           });
          //         }}
          //       >
          //         <ApText
          //           style={{ color: "#fff", textAlign: "center", fontSize: 13 }}
          //         >
          //           {t("Convert")}
          //         </ApText>
          //       </TouchableOpacity>
          //     </ImageBackground>
          //     <View
          //       style={{
          //         backgroundColor: "#FFD9CF",
          //         padding: 12,
          //         borderRadius: 10,
          //         marginTop: 15,
          //         marginBottom: 5,
          //         flexDirection: "row",
          //         marginHorizontal: 20,
          //       }}
          //     >
          //       <ApText
          //         style={{ color: "#F37021", width: "85%", fontSize: 12 }}
          //       >
          //         The profit requirement and the amount will be unlocked depends
          //         on rank, click to the{" "}
          //         <TouchableOpacity
          //           onPress={() => {
          //             navigation.navigate("Ranking");
          //           }}
          //         >
          //           <ApText
          //             style={{
          //               color: "#E45F50",
          //               textAlign: "center",
          //               fontSize: 13,
          //               textDecorationLine: "underline",
          //             }}
          //           >
          //             {t("Rank Page")}
          //           </ApText>
          //         </TouchableOpacity>{" "}
          //         to read more about the details.
          //       </ApText>
          //       <Image
          //         source={require("../../assets/icons/assets-hint.png")}
          //         style={{ width: 60, height: 53 }}
          //       />
          //     </View>

          //     <RenderHistoryList
          //       transactions={sortFrozenUSDT}
          //       emptyMessage="NoTransactionFound"
          //     />
          //   </> )
          selected == "DDCOIN" ? (
            <>
              <ImageBackground
                style={{ marginHorizontal: 20 }}
                source={require("../../assets/assets-bg.png")}
                imageStyle={{
                  borderRadius: 12,
                  width: "100%",
                  backgroundColor: "#fff5f0ba",
                  resizeMode: "contain",
                }}
              >
                <View
                  style={{
                    width: "100%",
                    borderRadius: 10,
                    padding: 15,
                  }}
                >
                  <ApText
                    style={{ textAlign: "right", fontSize: 14 }}
                    translateKey="DDCoinBalance"
                  />
                  <ApText
                    style={{
                      textAlign: "right",
                      color: "#F37021",
                      fontWeight: "bold",
                      fontSize: 28,
                    }}
                  >
                    {toCurrency(+profileData?.ddb_wallet, "", 4)}
                  </ApText>
                  <View
                    style={{
                      flexDirection: "row",
                      paddingTop: 16,
                      justifyContent: "flex-end",
                    }}
                  >
                    <TouchableOpacity
                      style={{
                        marginRight: 10,
                        borderRadius: 6,
                        paddingVertical: 4,
                        paddingHorizontal: 14,
                        backgroundColor: "#E45F50",
                      }}
                      onPress={() => {
                        setSelectedWallet("ddb_wallet");
                        navigation.navigate("Deposit", { type: "ddb" });
                      }}
                    >
                      <ApText
                        style={{
                          color: "#fff",
                          textAlign: "center",
                          fontSize: 12,
                        }}
                      >
                        {t("Deposit")}
                      </ApText>
                    </TouchableOpacity>

                    <TouchableOpacity
                      style={{
                        marginRight: 10,
                        borderRadius: 6,
                        paddingVertical: 4,
                        paddingHorizontal: 14,
                        backgroundColor: "#E45F50",
                      }}
                      onPress={() => {
                        setSelectedWallet("ddb_wallet");
                        navigation.navigate("Stake");
                      }}
                    >
                      <ApText
                        style={{
                          color: "#fff",
                          textAlign: "center",
                          fontSize: 12,
                        }}
                      >
                        {t("Stake")}
                      </ApText>
                    </TouchableOpacity>
                    {/* <TouchableOpacity
                      style={{
                        marginRight: 10,
                        borderRadius: 6,
                        paddingVertical: 4,
                        paddingHorizontal: 10,
                        backgroundColor: "#E45F50",
                      }}
                      onPress={() => {
                        setSelectedWallet("ddb_wallet");
                        navigation.navigate("Transfer");
                      }}
                    >
                      <ApText
                        style={{
                          color: "#fff",
                          textAlign: "center",
                          fontSize: 12,
                        }}
                      >
                        {t("Transfer")}
                      </ApText>
                    </TouchableOpacity> */}
                    <TouchableOpacity
                      style={{
                        borderRadius: 6,
                        paddingVertical: 4,
                        paddingHorizontal: 10,
                        backgroundColor: "#E45F50",
                      }}
                      onPress={() => {
                        setSelectedWallet("ddb_wallet");
                        navigation.navigate("Withdraw");
                      }}
                    >
                      <ApText
                        style={{
                          color: "#fff",
                          textAlign: "center",
                          fontSize: 12,
                        }}
                      >
                        {t("Withdraw")}
                      </ApText>
                    </TouchableOpacity>
                  </View>
                </View>
              </ImageBackground>
              <RenderHistoryList
                transactions={sortDDCoin}
                summary={summaryDDCoin}
                emptyMessage="NoTransactionFound"
              />
            </>
          ) : selected == "STAKED_DDCOIN" ? (
            <>
              <ImageBackground
                style={{ marginHorizontal: 20 }}
                source={require("../../assets/assets-bg.png")}
                imageStyle={{
                  borderRadius: 12,
                  width: "100%",
                  backgroundColor: "#fff5f0ba",
                  resizeMode: "contain",
                }}
              >
                <View
                  style={{
                    width: "100%",
                    borderRadius: 10,
                    padding: 15,
                  }}
                >
                  <ApText
                    style={{ textAlign: "right", fontSize: 14 }}
                    translateKey="StakedDDCoinBalance"
                  />
                  <ApText
                    style={{
                      textAlign: "right",
                      color: "#F37021",
                      fontWeight: "bold",
                      fontSize: 28,
                    }}
                  >
                    {toCurrency(+profileData?.stake_wallet, "", 4)}
                  </ApText>
                  <View
                    style={{
                      flexDirection: "row",
                      paddingTop: 16,
                      justifyContent: "flex-end",
                    }}
                  >
                    <TouchableOpacity
                      style={{
                        borderRadius: 6,
                        paddingVertical: 4,
                        paddingHorizontal: 14,
                        backgroundColor: "#E45F50",
                      }}
                      onPress={() => {
                        setSelectedWallet("stake_wallet");
                        navigation.navigate("Stake");
                      }}
                    >
                      <ApText
                        style={{
                          color: "#fff",
                          textAlign: "center",
                          fontSize: 12,
                        }}
                        translateKey="Unstake"
                      >
                        {t("Unstake")}
                      </ApText>
                    </TouchableOpacity>
                  </View>
                </View>
              </ImageBackground>

              <View
                style={{
                  backgroundColor: "#FFD9CF",
                  padding: 12,
                  borderRadius: 10,
                  marginTop: 15,
                  marginBottom: 5,
                  flexDirection: "row",
                  marginHorizontal: 20,
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <View
                  style={{ flexDirection: "row", flex: 1, flexWrap: "wrap" }}
                >
                  <ApText
                    style={{
                      color: "#F37021",
                      fontSize: 13,
                    }}
                    translateKey="TradingGasFeeDiscount"
                  />

                  <ApText
                    style={{
                      marginLeft: 5,
                      color: "#F37021",
                      fontSize: 13,
                    }}
                  >
                    {" : "}{" "}
                    {(parseFloat(profileData.gas_fee_discount) * 100).toFixed(
                      2
                    )}
                    {"%"}
                  </ApText>
                </View>

                <Image
                  source={require("../../assets/icons/assets-hint.png")}
                  style={{ width: 55, height: 48 }}
                />
              </View>
              <RenderHistoryList
                transactions={sortStakedDDCoin}
                summary={summaryStakedDDCoin}
                emptyMessage="NoTransactionFound"
              />
            </>
          ) : (
            selected == "DLPOINT" && (
              <>
                <ImageBackground
                  style={{ marginHorizontal: 20 }}
                  source={require("../../assets/assets-bg.png")}
                  imageStyle={{
                    borderRadius: 12,
                    width: "100%",
                    backgroundColor: "#fff5f0ba",
                    resizeMode: "contain",
                  }}
                >
                  <View
                    style={{
                      width: "100%",
                      borderRadius: 10,
                      padding: 20,
                    }}
                  >
                    <ApText
                      style={{ textAlign: "right", fontSize: 14 }}
                      translateKey="DLPOINTBalance"
                    />
                    <ApText
                      style={{
                        textAlign: "right",
                        color: "#F37021",
                        fontWeight: "bold",
                        fontSize: 28,
                      }}
                    >
                      {toCurrency(+profileData?.doli_wallet, "", 4)}
                    </ApText>

                    <View
                      style={{
                        flexDirection: "row",
                        paddingTop: 16,
                        justifyContent: "flex-end",
                      }}
                    >
                      <TouchableOpacity
                        style={{
                          marginRight: 10,
                          borderRadius: 6,
                          paddingVertical: 4,
                          paddingHorizontal: 8,
                          backgroundColor: "#E45F50",
                        }}
                        onPress={() => {
                          setSelectedWallet("doli_wallet");
                          navigation.navigate("Transfer");
                        }}
                      >
                        <ApText
                          style={{
                            color: "#fff",
                            textAlign: "center",
                            fontSize: 12,
                          }}
                        >
                          {t("Transfer")}
                        </ApText>
                      </TouchableOpacity>
                      <TouchableOpacity
                        style={{
                          borderRadius: 6,
                          paddingVertical: 4,
                          paddingHorizontal: 8,
                          backgroundColor: "#E45F50",
                        }}
                        onPress={() => {
                          setSelectedWallet("doli_wallet");
                          navigation.navigate("Convert");
                        }}
                      >
                        <ApText
                          style={{
                            color: "#fff",
                            textAlign: "center",
                            fontSize: 12,
                          }}
                        >
                          {t("Convert")}
                        </ApText>
                      </TouchableOpacity>
                    </View>
                  </View>
                </ImageBackground>
                <RenderHistoryList
                  transactions={sortDLPoint}
                  summary={summaryDLPoint}
                  emptyMessage="NoTransactionFound"
                />
              </>
            )
          )}
        </View>
        {loadMoreBtnDisplay[selected] && (
          <View style={{ backgroundColor: "#fff", padding: 20 }}>
            <TouchableOpacity
              onPress={() => loadMore()}
              style={{
                backgroundColor: "#F37021",
                width: "100%",
                height: 36,
                borderRadius: 5,
                alignItems: "center",
                justifyContent: "center",
                marginTop: 20,
              }}
            >
              <ApText
                style={{ color: theme.COLORS.white, fontWeight: "500" }}
                translateKey="ShowAllUpdates"
              />
            </TouchableOpacity>
          </View>
        )}
      </View>
    );
  };

  return (
    <ApSafeAreaView
      style={{
        paddingBottom: 20,
      }}
    >
      {renderHeader()}
      {loading && <ApLoader />}
      {!loading && renderContent()}
    </ApSafeAreaView>
  );
};

export default MyAssets;

const RenderHistoryList: React.FC<{
  transactions?: Record<string, IWalletTransaction[]>;
  summary?: Record<string, IWalletTransactionSummary[]>;
  emptyMessage: string;
}> = ({ transactions, summary, emptyMessage }) => {
  const status = (transactionType: string, subTransactionType: string) => {
    if (
      transactionType === "transfer" ||
      transactionType === "gas" ||
      transactionType === "wr" ||
      transactionType === "purchase" ||
      transactionType === "package"
    ) {
      return (
        <View
          style={{
            backgroundColor: "#FB7177",
            paddingTop: 10,
            paddingHorizontal: 10,
            borderRadius: 5,
          }}
        >
          <svg.ExportSvg />
        </View>
      );
    } else if (transactionType === "adj" && subTransactionType === "deduct") {
      return (
        <View
          style={{
            backgroundColor: "#FB7177",
            paddingTop: 10,
            paddingHorizontal: 10,
            borderRadius: 5,
          }}
        >
          <svg.ExportSvg />
        </View>
      );
    } else if (
      transactionType === "follow" &&
      subTransactionType === "bp_fee"
    ) {
      return (
        <View
          style={{
            backgroundColor: "#FB7177",
            paddingTop: 10,
            paddingHorizontal: 10,
            borderRadius: 5,
          }}
        >
          <svg.ExportSvg />
        </View>
      );
    } else {
      return (
        <View
          style={{
            backgroundColor: "#05B491",
            paddingTop: 10,
            paddingHorizontal: 10,
            borderRadius: 5,
          }}
        >
          <svg.InportSvg />
        </View>
      );
    }
  };

  return (
    <View style={{ marginBottom: 20 }}>
      {transactions && !!Object.keys(transactions).length ? (
        Object.keys(transactions).map((key, index) => (
          <View key={key}>
            <ApRow>
              <ApText
                style={{
                  color: "#F37021",
                  fontSize: 17,
                  paddingTop: 20,
                  paddingBottom: 10,
                  fontWeight: "500",
                  marginLeft: 20,
                  textAlign: "left",
                }}
              >
                {key}
              </ApText>
              {summary[index]?.daily_sum ? (
                <View
                  style={{
                    flex: 1,
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    paddingTop: 20,
                    paddingBottom: 10,
                    marginRight: 20,
                  }}
                >
                  <ApText
                    style={{
                      color: "grey",
                      fontSize: 12,
                      fontWeight: "500",
                    }}
                    translateKey="Total"
                  />
                  <ApText
                    style={{
                      color: "grey",
                      fontSize: 12,
                      fontWeight: "500",
                    }}
                  >
                    {" "}
                    <span style={{ paddingRight: 5 }}>:</span>
                    {summary[index]?.daily_sum}
                  </ApText>
                </View>
              ) : null}
            </ApRow>
            <View
              style={{
                paddingHorizontal: 10,
                backgroundColor: "#FCF8F6",
                marginHorizontal: 20,
                borderRadius: 10,
                // paddingBottom: 20,
              }}
            >
              {transactions[key].map((e: any, index) => (
                <View
                  key={e.created_at}
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingVertical: 15,
                    borderBottomWidth:
                      index + 1 !== transactions[key].length && 1,
                    borderColor: "#e4e4e499",
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      width: "70%",
                      alignItems: "center",
                    }}
                  >
                    {e?.amount?.includes("-") ? (
                      <View
                        style={{
                          backgroundColor: "#FB7177",
                          padding: 10,
                          borderRadius: 5,
                        }}
                      >
                        <svg.ExportSvg />
                      </View>
                    ) : (
                      <View
                        style={{
                          backgroundColor: "#05B491",
                          padding: 10,
                          borderRadius: 5,
                        }}
                      >
                        <svg.InportSvg />
                      </View>
                    )}
                    {/* {status(e.transaction_type, e.sub_transaction_type)} */}
                    <View style={{ paddingLeft: 10, width: "90%" }}>
                      <ApText style={{ fontWeight: "bold", fontSize: 14 }}>
                        {e.description}
                      </ApText>
                      <ApText
                        style={{
                          color: "#ababab",
                          fontSize: 12,
                          lineHeight: 16,
                          paddingTop: 2,
                        }}
                      >
                        {e.account_name_2}
                      </ApText>
                    </View>
                  </View>

                  <View style={{ alignItems: "flex-end", width: "30%" }}>
                    <ApText style={{ fontWeight: "500" }}>{e.amount}</ApText>
                    <ApText
                      style={{
                        color: "#ababab",
                        fontSize: 12,
                        lineHeight: 16,
                        paddingTop: 2,
                      }}
                    >
                      {e.created_at.split(" ")[1]}
                    </ApText>
                  </View>
                </View>
              ))}
            </View>
          </View>
        ))
      ) : (
        <ApEmptyState
          svg={<ApEmptyStateSvg.List />}
          heading="No Result Found"
          message={emptyMessage}
        />
      )}
    </View>
  );
};
