import { useNavigation } from "@react-navigation/native";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ScrollView, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { components } from "../components";
import { ApButton, ApLoader, ApSafeAreaView } from "../components/v1";
import { ApText } from "../components/v1/typography";
import { useProfileState, useTransactionState } from "../context";
import { IWalletTransaction } from "../models/transaction.interface";

const CommissionDetails: React.FC = (props: any) => {
  const navigation: any = useNavigation();
  const { i18n, t } = useTranslation();
  const [selected, setSelected] = useState("CreditBalance");
  //const [profileData, setProfileData] = useState({});
  const dispatch = useDispatch();
  const hideProfit = useSelector(
    (state: any) => state.hideProfitSlice.hideProfit
  );
  const { profileData, getProfileData } = useProfileState();
  const { getWalletTransaction, loading } = useTransactionState();
  const [commissionDetailList, setCommissionDetailList] = useState<
    IWalletTransaction[]
  >([]);

  useEffect(() => {
    // ApiCall.post("/profile/me").then((response) => {
    //   setProfileData(response.data.data);
    // });
    getProfileData();
    if (props.route.params.date) {
      getWalletTransaction(
        { fromDate: props.route.params.date, toDate: props.route.params.date },
        "",
        "active"
      ).then((res) => {
        setCommissionDetailList(res as unknown as IWalletTransaction[]);
      });
    }
  }, []);

  const renderHeader = () => {
    return (
      <components.Header
        title={"Commission Details"}
        titleTranlateKey="CommissionDetails"
        goBack={true}
      />
    );
  };
  

  const renderContent = () => {
    return (
      <View style={{ flex: 1 }}>
        <View>
          <ApText
            style={{
              color: "#F37021",
              fontSize: 17,
              padding: 15,
              fontWeight: "500",
              paddingTop: 25,
            }}
          >
            {props.route.params.date}
          </ApText>
          <View
            style={{
              paddingHorizontal: 15,
              backgroundColor: "#FCF8F6",
              margin: 15,
              borderRadius: 10,
              paddingBottom: 5,
              marginTop: 0,
            }}
          >
            {commissionDetailList.length > 0 ? (
              commissionDetailList.map((e, index) => (
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingVertical: 15,
                    borderBottomWidth: index + 1 === commissionDetailList.length ? 0 : 1,
                    borderColor: "#e4e4e499",
                  }}
                >
                  <View style={{ width: "50%" }}>
                    <ApText style={{ fontWeight: "500", fontSize: 15 }}>
                      {e.description}
                    </ApText>
                    <ApText
                      style={{
                        color: "#ababab",
                        fontSize: 12,
                        lineHeight: 16,
                        paddingTop: 2,
                      }}
                    >
                      {e.account_name_2}
                    </ApText>
                  </View>

                  <View style={{ width: "50%", alignItems: "flex-end" }}>
                    <ApText
                      style={{
                        fontWeight: "500",
                        color: "#06B491",
                        textAlign: "right",
                      }}
                    >
                      +{e.amount}
                    </ApText>
                    <ApText
                      numberOfLines={1}
                      style={{
                        color: "#ababab",
                        fontSize: 12,
                        lineHeight: 16,
                        paddingTop: 2,
                      }}
                    >
                      {e.created_at.split(" ")[1]}
                    </ApText>
                  </View>
                </View>
              ))
            ) : (
              <ApText
                style={{ marginHorizontal: 5, marginVertical: 10 }}
                translateKey="NoHistoryDetails"
              />
            )}
          </View>

        </View>

        {/* <Dialog
          visible={toggleFilter}
          dialogAnimation={
            new SlideAnimation({
              slideFrom: "bottom",
            })
          }
        >
          <DialogButton
            text="CANCEL"
            onPress={() => {
              setShowBindModal(false);
            }}
          >
            <svg.AccountSvg />
          </DialogButton>
          <TouchableOpacity
            onPress={() => {
              setToggleFilter(false);
            }}
            style={{ alignItems: "flex-end", padding: 15, paddingBottom: 0 }}
          >
            <svg.closeSvg />
          </TouchableOpacity>
          <DialogContent>
            <View style={{ alignItems: "center", paddingHorizontal: 6, flex: "1", flexDirection: "column" }}>
              <ApText style={{ color: "#F37021", fontWeight: "500", marginBottom: 10, fontSize: 18 }}>Filter</ApText>
              <ApText style={{ alignSelf: "flex-start", color: "#737373", marginBottom: 10 }}>Date:</ApText>
              <View style={{ borderBottomColor: "#e3e3e3", paddingBottom: 15, marginBottom: 15, borderBottomWidth: 1 }}>
                {Platform.OS == "web" ? (
                  <>
                    <input style={{ border: "none", backgroundColor: "#FCF8F6", padding: 10, borderRadius: 10 }} type="date" value={"2022-01-11"} />
                    <ApText style={{ color: "#737373", paddingHorizontal: 16, fontSize: 13, alignSelf: "center" }}>to</ApText>
                    <input style={{ border: "none", backgroundColor: "#FCF8F6", padding: 10, borderRadius: 10 }} type="date" value={"2022-01-11"} />
                  </>
                ) : (
                  <>
                    <StartDate />
                    <EndDate />
                  </>
                )}
              </View>
              <View style={{ alignSelf: "flex-start", width: "100%" }}>
                <ApText style={{ color: "#737373", marginBottom: 10 }}>Token List:</ApText>
                <TouchableOpacity
                  onPress={() => {
                    navigation.navigate("TokenList");
                    setToggleFilter(false);
                  }}
                  style={{ flexDirection: "row", justifyContent: "space-between", backgroundColor: "#FCF8F6", padding: 10, borderRadius: 10 }}
                >
                  <ApText>All</ApText>
                  <svg.ArrowThreeSvg />
                </TouchableOpacity>
              </View>
              <View style={{ flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                <TouchableOpacity
                  style={{
                    borderWidth: 1,
                    borderColor: "#F37021",
                    width: theme.SIZES.width / 2.05 - 115,
                    height: 38,
                    borderRadius: 5,
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: 15,
                    marginRight: 10,
                  }}
                  // onPress={() => {
                  //   setToggleFilter(false);
                  //   navigation.navigate("API");
                  // }}
                >
                  <ApText style={{ fontWeight: "500" }}>Reset</ApText>
                </TouchableOpacity>
                <TouchableOpacity
                  style={{
                    backgroundColor: "#F37021",
                    width: theme.SIZES.width / 2.05 - 115,
                    height: 38,
                    borderRadius: 5,
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: 15,
                    marginLeft: 10,
                  }}
                  onPress={() => {
                    setToggleFilter(false);
                  }}
                >
                  <ApText style={{ color: theme.COLORS.white, fontWeight: "500" }}>Confirm</ApText>
                </TouchableOpacity>
              </View>
            </View>
          </DialogContent>
        </Dialog> */}
      </View>
    );
  };

  return (
    <>
      {renderHeader()}
      {loading && <ApLoader />}

      <ScrollView
          contentContainerStyle={{
            //paddingBottom: 5,
            flexGrow: 1,
            justifyContent: "space-between",
          }}
          showsVerticalScrollIndicator
        >
        <ApSafeAreaView >
          {!loading && renderContent()}
        </ApSafeAreaView>
      </ScrollView>

      {!loading && (
        <View style={{width: "100%", paddingTop: 15, paddingBottom: 15, justifyContent: 'space-between', flexDirection: 'row', paddingHorizontal: 20}}>
          <ApButton
            containerHeight={45}
            label="Done"
            containerWidth= "100%"
            style={{height: "100%"}}
            round="lg"
            labelTranslateKey="Done"
            type="primary"
            onPress={() => navigation.navigate("Commission")}
          />
        </View>
      )}
    </>
  );
};

export default CommissionDetails;
