import React from "react";
import ContentLoader, { Rect, Circle, Path } from "react-content-loader/native";

export const ApIncomeRankingLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={476}
      height={500}
      viewBox="0 0 476 500"
      backgroundColor="#f3f3f3"
      foregroundColor="#e6e6e6"
    >
      <Rect x="13" y="10" rx="3" ry="3" width="348" height="249" />
      <Rect x="13" y="265" rx="6" ry="6" width="348" height="60" />
      <Rect x="14" y="328" rx="6" ry="6" width="348" height="60" />
      <Rect x="14" y="393" rx="6" ry="6" width="348" height="60" />
      <Rect x="14" y="457" rx="6" ry="6" width="348" height="60" />
      <Rect x="55" y="57" rx="0" ry="0" width="253" height="24" />
    </ContentLoader>
  );
};
