import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SearchSvg(props) {
  return (
    <Svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path d="M15 15l6 6" stroke="#AAAAAA" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round" />
      <Path d="M17 10a7 7 0 11-14 0 7 7 0 0114 0z" stroke="#AAAAAA" strokeWidth={2} />
    </Svg>
  );
}

export default SearchSvg;
