import React from "react";
import ContentLoader, { Rect, Circle, Path } from "react-content-loader/native";

export const ApDoubouLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={476}
      height={600}
      viewBox="0 0 476 600"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <Rect x="21" y="21" rx="10" ry="10" width="338" height="130" />
      <Rect x="15" y="162" rx="10" ry="10" width="338" height="130" />
      <Rect x="19" y="303" rx="10" ry="10" width="338" height="130" />
      <Rect x="23" y="446" rx="10" ry="10" width="338" height="130" />
    </ContentLoader>
  );
};
