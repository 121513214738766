import React from "react";
import ContentLoader, { Rect } from "react-content-loader/native";
import { useWindowDimensions } from "react-native";

export const ApFolderLoader = () => {
  return (
    <ContentLoader 
    speed={2}
    width={375}
    height={110}
    viewBox="0 0 375 110"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <Rect x="34" y="1" rx="10" ry="10" width="122" height="105" /> 
    <Rect x="234" y="4" rx="10" ry="10" width="122" height="105" />
  </ContentLoader>
  );
};
