import React from "react";
import { StyleSheet, View } from "react-native";
import { ApText } from "../typography";

interface IProps {
  // svg name from svg folder src/svg
  svg?: React.ReactNode;
  heading?: string;
  message?: string;
}

export const ApEmptyState: React.FC<IProps> = ({ svg, heading, message }) => {
  return (
    <View style={style.container}>
      {/* {svg} */}
      <ApText style={style.heading} size="lg" font="bold">
        {/* {heading} */}
      </ApText>
      <ApText color="#9ca3af" font="light" translateKey="NoRecordFound">
        {/* {message} */}No record found.
      </ApText>
    </View>
  );
};

const style = StyleSheet.create({
  container: {
    marginBottom: 20,
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100%",
  },
  heading: {
    marginTop: 20,
    marginBottom: 5,
  },
});
