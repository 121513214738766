import CheckBox from "expo-checkbox";
import React, { useState } from "react";
import { TextStyle, View } from "react-native";
import { ApText } from "../typography"

interface IProps {
  market: any
  selectShort: any
  selectLong: any
  selectAll: any
  default?: any
  onChange?: any
}

export const ApOperationsInput: React.FC<IProps> = ({ market, selectShort, selectLong, onChange, selectAll }) => {
  const [toggle, setToggle] = useState(false);
  const [toggleAll, setToggleAll] = useState(false);
  const [toggleLong, setToggleLong] = useState(false);
  const [toggleShort, setToggleShort] = useState(false);

  return (
    <View
      style={{
        marginTop: 10,
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >

      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          // width: "100%",
        }}
      >
        <CheckBox
          style={{ height: 13, width: 13, borderWidth: !toggleAll ? 1 : 0, borderColor: "#F37021" }}
          value={toggleAll}
          color={toggleAll ? "#F37021" : "transparent"}
          onValueChange={(v) => {
            setToggleAll(!toggleAll);
            setToggleLong(false)
            setToggleShort(false)
            selectAll(v)
          }}
        />
        <ApText style={{marginLeft: 10, fontSize: 14}} translateKey="All" />
      </View>

      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          // width: "100%",
        }}
      >
        <CheckBox
          style={{ height: 13, width: 13, borderWidth: !toggleLong ? 1 : 0, borderColor: "#F37021" }}
          value={toggleLong}
          color={toggleLong ? "#F37021" : "transparent"}
          onValueChange={(v) => {
            setToggleLong(!toggleLong);
            setToggleAll(false);
            setToggleShort(false)
            selectLong(v)
          }}
        />
        <ApText style={{marginLeft: 10, fontSize: 14}} translateKey={market === "1" ? "USDT_Based" : "Long"} />
      </View>

      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          // width: "100%",
        }}
      >
        <CheckBox
          style={{ height: 13, width: 13, borderWidth: !toggleShort ? 1 : 0, borderColor: "#F37021" }}
          value={toggleShort}
          color={toggleShort ? "#F37021" : "transparent"}
          onValueChange={(v) => {
            setToggleShort(!toggleShort);
            setToggleAll(false);
            setToggleLong(false)
            selectShort(v)
          }}
        />
        <ApText style={{marginLeft: 10, fontSize: 14}} translateKey={market === "1" ? "Token_Based" : "Short"} />
      </View>
    </View>
  );
};
