import * as React from "react";
import Svg, { Path } from "react-native-svg";

const LogoSvg: React.FC = () => (
    <Svg width={37} height={29} fill="none">
        <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.779 2.455v1.587c0 2.232-1.832 4.042-4.093 4.042h-.879v-5.63C7.807 1.1 8.92 0 10.293 0c1.373 0 2.486 1.1 2.486 2.455ZM18.425 29c5.86 0 10.636-4.697 10.636-10.458 0-5.762-4.759-10.458-10.618-10.44h-3.189c-1.367 0-2.475 1.093-2.475 2.444 0 1.35 1.108 2.444 2.475 2.444h3.171c3.125 0 5.664 2.496 5.664 5.57 0 3.072-2.539 5.569-5.664 5.569-3.125 0-5.664-2.497-5.664-5.57v-.66c0-2.711-2.226-4.91-4.971-4.91v5.553C7.79 24.303 12.566 29 18.425 29ZM7.807 8.084H2.475C1.108 8.084 0 9.178 0 10.528c0 1.35 1.108 2.444 2.475 2.444h5.332V8.084ZM33.941 18.85c0-.387.318-.7.71-.7.392 0 .71.313.71.7 0 .387-.318.7-.71.7a.705.705 0 0 1-.71-.7Zm3.059 0c0 1.281-1.052 2.32-2.35 2.32-1.297 0-2.348-1.039-2.348-2.32s1.051-2.32 2.349-2.32c1.297 0 2.349 1.039 2.349 2.32Z"
            fill="red"
        />
    </Svg>
);

export default LogoSvg;
