import * as React from "react";
import Svg, { Defs, Path } from "react-native-svg";
/* SVGR has dropped some elements not supported by react-native-svg: style */

function UserWhiteSvg(props) {
  return (
    <Svg width="19px" height="19px" viewBox="0 -8 72 72" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Defs></Defs>
      <Path fill={"#fff"} className="cls-1" d="M12.54 52.05h46.92a2.11 2.11 0 001.6-.7 1.73 1.73 0 00.43-1.35A25.8 25.8 0 0047.9 30.45a16.8 16.8 0 01-23.8 0A25.8 25.8 0 0010.51 50a1.75 1.75 0 00.43 1.38 2.11 2.11 0 001.6.67z" />
      <Path fill={"#fff"} className="cls-1" d="M25.43 28.6c.27.29.56.56.85.82a14.52 14.52 0 0019.43 0 11.1 11.1 0 00.86-.82c.27-.29.54-.58.79-.89a14.6 14.6 0 10-22.72 0c.25.29.52.6.79.89z" />
    </Svg>
  );
}

export default UserWhiteSvg;
