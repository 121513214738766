import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { getLocales } from "expo-localization";
import zh_CN from "./src/translations/zh_CN.json";
import zh_TW from "./src/translations/zh_TW.json";
import en from "./src/translations/en.json";

i18n.use(initReactI18next).init({
  compatibilityJSON: "v3",
  lng: getLocales()[0].languageCode,
  fallbackLng: "en",
  resources: {
    en: {
      translation: en,
    },
    zh_CN: {
      translation: zh_CN,
    },
    zh_TW: {
      translation: zh_TW,
    },
  },
});
export default i18n;
