import React, { useCallback, useEffect, useRef, useState } from "react";
import { View } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

import { components } from "../components";
import { svg } from "../svg";

import { useNavigation } from "@react-navigation/native";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { ApButton, ApRow, ApSafeAreaView } from "../components/v1";
import { ApCheckInputAlt, ApTextInput } from "../components/v1/input";
import { ApText } from "../components/v1/typography";
import { ERROR_MSG } from "../constants/errors";
import {
  useBusinessState,
  useProfileState,
  useTransactionState,
} from "../context";

const FormSchema = Yup.object().shape({
  amount: Yup.number().min(1).required(ERROR_MSG.AmountRequired),
  sec_password: Yup.string().required(ERROR_MSG.SecurityPassRequired),
  agree: Yup.boolean().oneOf([true], ERROR_MSG.AgreeTerms),
});

const SignIn: React.FC = (props: any) => {
  const navigation: any = useNavigation();
  const {
    convertCashToCredit,
    convertCommToCash,
    loading,
    convertDoliToBonus,
  } = useBusinessState();
  // const accessToken = useSelector((state: any) => state.accessToken);
  const { getProfileData, profileData } = useProfileState();
  const { getCreditPoint, getUSDT, selectedWallet, getDLPoint, getBonusPoint } =
    useTransactionState();
  const formRef = useRef<FormikProps<any>>();
  const [isSelected, setSelection] = useState(false);
  // const [amount, setAmount] = useState("");

  useEffect(() => {
    //console.log(props.route.params.convertType)
    getProfileData();
  }, []);

  const convertLabel = useCallback(() => {
    if (selectedWallet === "cash_wallet") {
      return "Convert USDT to Credit";
    } else if (props.route.params.convertType === "commToCash") {
      return "Convert Commission to USDT";
    } else {
      return "Convert";
    }
  }, []);

  const handleConvert = async (value: any) => {
    selectedWallet === "cash_wallet"
      ? convertCashToCredit({
          amount: value.amount,
          sec_password: value.sec_password,
        }).then((rs) => {
          getProfileData();
          getCreditPoint();
          getUSDT();
          formRef.current?.resetForm();
        })
      : convertDoliToBonus({
          amount: value.amount,
          sec_password: value.sec_password,
        }).then((rs) => {
          getProfileData();
          getDLPoint();
          getBonusPoint();
          formRef.current?.resetForm();
        });
    // : convertCommToCash({
    //   amount: value.amount,
    //   sec_password: value.sec_password,
    // }).then(rs => {
    //   getProfileData()
    //   getCreditPoint()
    //   getUSDT()
    //   formRef.current?.resetForm();
    // })

    // if (props.route.params.convertType === "cashToCredit") {
    //   convertCashToCredit({
    // amount: value.amount,
    // sec_password: value.sec_password,
    //   })
    //     .then((rs) => {
    // getProfileData()
    // getCreditPoint()
    // getUSDT()
    //       ApSuccessToast("Success", rs);
    //       navigation.navigate("MyAssets");
    //       formRef.current?.resetForm();
    //     })
    //     .catch((err) => {
    //       console.log("Here");
    //       ApErrorToast("Convert Failed", err);
    //     });
    // } else if (props.route.params.convertType === "commToCash") {
    // convertCommToCash({
    //   amount: value.amount,
    //   sec_password: value.sec_password,
    // })
    //     .then((rs) => {
    //       getProfileData()
    //       ApSuccessToast("Success", rs);
    //       navigation.navigate("MyAssets");
    //       formRef.current?.resetForm();
    //     })
    //     .catch((err) => {
    //       ApErrorToast("Convert Failed", err);
    //     });
    // }
  };

  const renderHeader = () => {
    return (
      <components.Header
        title="Convert"
        titleTranlateKey="Convert"
        goBack={true}
        converHistory={true}
      />
    );
  };

  const renderContent = () => {
    return (
      <Formik
        innerRef={formRef as any}
        validationSchema={FormSchema}
        initialValues={{ amount: "", sec_password: "", agree: false }}
        onSubmit={handleConvert}
      >
        {(props: FormikProps<any>) => (
          <KeyboardAwareScrollView
            contentContainerStyle={{ flexGrow: 1, paddingHorizontal: 20 }}
          >
            <View style={{ paddingTop: 10 }}>
              {/* <components.SmallHeader
                title="Exchange Amount"
                containerStyle={{ marginBottom: 6 }}
              /> */}
              <ApTextInput
                max
                maxAmount={profileData[selectedWallet]}
                containerStyle={{ paddingBottom: 0 }}
                label={"Amount"}
                name="amount"
                labelTranslateKey="Amount"
                keyboardType="numeric"
                // placeholder="Please enter the exchange amount"
                icon={<svg.CheckSvg />}
              />

              <ApRow>
                <ApText
                  size="xs"
                  style={{ marginBottom: 10, marginTop: 10, color: "#F37021" }}
                  translateKey="Balance"
                />
                <ApText
                  size="xs"
                  style={{ marginBottom: 10, marginTop: 10, color: "#F37021" }}
                >
                  : {profileData[selectedWallet]}
                </ApText>
              </ApRow>

              <ApTextInput
                label="Security Password"
                secureTextEntry
                name="sec_password"
                labelTranslateKey="SecPassword"
                // placeholder="Please enter payment password"
                icon={<svg.CheckSvg />}
              />
              <View
                style={{
                  flexDirection: "row",
                  paddingTop: 35,
                  paddingBottom: 20,
                }}
              >
                <ApCheckInputAlt
                  name="agree"
                  label="I understand and agree that the conversion of USDT asset is not refundable."
                  labelTranslateKey="ConvertMsgLabel"
                  labelStyle={{
                    fontSize: 11,
                    color: "#F37021",
                    marginLeft: "10px",
                  }}
                />
                {/* <CheckBox value={isSelected} onValueChange={setSelection} />
                <Text style={{ paddingLeft: 15, fontSize: 12, color: "#F37021" }}>I understand and agree that the membership package is not refundable</Text> */}
              </View>
              {/* <components.Button
                title="Convert"
                onPress={props.handleSubmit}
                containerStyle={{}}
              /> */}
              <ApButton
                loading={loading}
                disabled={loading}
                label="Convert"
                labelTranslateKey="Convert"
                round="lg"
                type="primary"
                onPress={props.handleSubmit}
              />
            </View>
          </KeyboardAwareScrollView>
        )}
      </Formik>
    );
  };

  return (
    <ApSafeAreaView>
      {renderHeader()}
      {renderContent()}
    </ApSafeAreaView>
  );
};

export default SignIn;
