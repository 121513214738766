import { useNavigation } from "@react-navigation/native";
import React from "react";
import { Text, TextInput, TouchableOpacity, View } from "react-native";

import { theme } from "../constants";
import { svg } from "../svg";
import { ApText } from "./v1/typography";

type Props = {
  screenName?: string
  containerStyle?: any;
  goBack?: boolean;
  burgerMenu?: boolean;
  title?: string;
  logo?: boolean;
  search?: boolean;
  bag?: boolean;
  border?: boolean;
  titleStyle?: any;
  arrowColor?: string;
  fileIcon?: boolean;
  goBackColor?: string;
  depositHistory?: boolean;
  profitFilter?: boolean;
  purchaseHistory?: boolean;
  toggleFilter?: boolean;
  transferHistory?: boolean;
  converHistory?: boolean;
  withdrawHistory?: boolean;
  titleTranlateKey?: string;
  ddbConverHistory?: boolean
  ddbWithdrawHistory?: boolean
  TradingBotHistory?: boolean
  customRightIcon?: React.ReactNode
  params?: any;
  setToggleFilter?: () => void;
};

const Header: React.FC<Props> = ({
  customRightIcon,
  screenName,
  containerStyle,
  goBack,
  title,
  logo,
  search,
  bag,
  border,
  titleStyle,
  arrowColor,
  fileIcon,
  goBackColor,
  depositHistory,
  purchaseHistory,
  transferHistory,
  withdrawHistory,
  profitFilter,
  toggleFilter,
  setToggleFilter,
  converHistory,
  ddbConverHistory,
  ddbWithdrawHistory,
  TradingBotHistory,
  titleTranlateKey,
  params
}) => {
  const navigation = useNavigation();
  return (
    <View
      style={{
        elevation: 20,
        shadowOpacity:10,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        height: 47,
        borderBottomWidth: border ? 1 : 0,
        ...containerStyle,
        backgroundColor: "#FCF8F6",
      }}
    >
      {goBack && (
        <View
          style={{
            position: "absolute",
            left: 0,
            alignItems: "center",
          }}
        >
          <TouchableOpacity
            style={{
              paddingHorizontal: 20,
              paddingVertical: 12,
            }}
            onPress={() => {screenName ? navigation.navigate(screenName) : navigation.goBack()}}
          >
            <svg.GoBackSvg goBackColor={goBackColor} />
          </TouchableOpacity>
        </View>
      )}
      {title && (
        <ApText
          translateKey={titleTranlateKey}
          style={{
            textAlign: "center",
            fontSize: 16,
            fontWeight: 500,
            color: "#F37021",
            ...titleStyle,
          }}
        >
          {title}
        </ApText>
      )}
      {search && (
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            width: theme.SIZES.width - 200,
          }}
        >
          <View style={{ marginRight: 7 }}>
            {/* <svg.HeaderSearchSvg /> */}
          </View>

          <TextInput
            placeholder="Search"
            style={{ height: "100%", width: "100%" }}
          />
        </View>
      )}
      {fileIcon && (
        <TouchableOpacity
          style={{
            position: "absolute",
            right: 0,
            height: "100%",
            justifyContent: "center",
            paddingLeft: 20,
          }}
        >
          <View
            style={{
              paddingHorizontal: 20,
              flexDirection: "row",
              paddingVertical: 6,
            }}
          >
            <svg.FileTextSvg />
          </View>
        </TouchableOpacity>
      )}
      {depositHistory && (
        <TouchableOpacity
          style={{
            position: "absolute",
            right: 0,
            height: "100%",
            justifyContent: "center",
            paddingLeft: 20,
          }}
          onPress={() => {
            navigation.navigate("DepositHistory");
          }}
        >
          <View
            style={{
              paddingHorizontal: 20,
              flexDirection: "row",
              paddingVertical: 6,
            }}
          >
            <svg.HistorySvg />
          </View>
        </TouchableOpacity>
      )}
      {purchaseHistory && (
        <TouchableOpacity
          style={{
            position: "absolute",
            right: 0,
            height: "100%",
            justifyContent: "center",
            paddingLeft: 20,
          }}
          onPress={() => {
            navigation.navigate("PurchaseHistory");
          }}
        >
          <View
            style={{
              paddingHorizontal: 20,
              flexDirection: "row",
              paddingVertical: 6,
            }}
          >
            <svg.HistorySvg />
          </View>
        </TouchableOpacity>
      )}
      {transferHistory && (
        <TouchableOpacity
          style={{
            position: "absolute",
            right: 0,
            height: "100%",
            justifyContent: "center",
            paddingLeft: 20,
          }}
          onPress={() => {
            navigation.navigate("TransferHistory");
          }}
        >
          <View
            style={{
              paddingHorizontal: 20,
              flexDirection: "row",
              paddingVertical: 6,
            }}
          >
            <svg.HistorySvg />
          </View>
        </TouchableOpacity>
      )}
      {converHistory && (
        <TouchableOpacity
          style={{
            position: "absolute",
            right: 0,
            height: "100%",
            justifyContent: "center",
            paddingLeft: 20,
          }}
          onPress={() => {
            navigation.navigate("ConverHistory");
          }}
        >
          <View
            style={{
              paddingHorizontal: 20,
              flexDirection: "row",
              paddingVertical: 6,
            }}
          >
            <svg.HistorySvg />
          </View>
        </TouchableOpacity>
      )}
      {ddbConverHistory && (
        <TouchableOpacity
          style={{
            position: "absolute",
            right: 0,
            height: "100%",
            justifyContent: "center",
            paddingLeft: 20,
          }}
          onPress={() => {
            navigation.navigate("ConverHistory", {
              wallet_type: "ddb_wallet"
            });
          }}
        >
          <View
            style={{
              paddingHorizontal: 20,
              flexDirection: "row",
              paddingVertical: 6,
            }}
          >
            <svg.HistorySvg />
          </View>
        </TouchableOpacity>
      )}
      {withdrawHistory && (
        <TouchableOpacity
          style={{
            position: "absolute",
            right: 0,
            height: "100%",
            justifyContent: "center",
            paddingLeft: 20,
          }}
          onPress={() => {
            navigation.navigate("WithdrawHistory");
          }}
        >
          <View
            style={{
              paddingHorizontal: 20,
              flexDirection: "row",
              paddingVertical: 6,
            }}
          >
            <svg.HistorySvg />
          </View>
        </TouchableOpacity>
      )}
      {ddbWithdrawHistory && (
        <TouchableOpacity
          style={{
            position: "absolute",
            right: 0,
            height: "100%",
            justifyContent: "center",
            paddingLeft: 20,
          }}
          onPress={() => {
            navigation.navigate("WithdrawHistory", {
              wallet_type: "ddb_wallet"
            });
          }}
        >
          <View
            style={{
              paddingHorizontal: 20,
              flexDirection: "row",
              paddingVertical: 6,
            }}
          >
            <svg.HistorySvg />
          </View>
        </TouchableOpacity>
      )}
      {TradingBotHistory && (
        <TouchableOpacity
          style={{
            position: "absolute",
            right: 0,
            height: "100%",
            justifyContent: "center",
            paddingLeft: 20,
          }}
          onPress={() => {
            navigation.navigate("TradingBotHistory", {
              market: params?.market
            });
          }}
        >
          <View
            style={{
              paddingHorizontal: 20,
              flexDirection: "row",
              paddingVertical: 6,
            }}
          >
            <svg.HistorySvg />
          </View>
        </TouchableOpacity>
      )}
      {customRightIcon && customRightIcon}
    </View>
  );
};

export default Header;
