import AsyncStorage from "@react-native-async-storage/async-storage";
import React, { useEffect, useState } from "react";
import { StyleProp, Text, View, ViewStyle } from "react-native";
import DropDownPicker, {
  DropDownDirectionType,
} from "react-native-dropdown-picker";
import OutsideClickHandler from "react-outside-click-handler";
import { useTranslation } from "react-i18next";
import { useIsFocused } from "@react-navigation/native";
import CheckTwo from "../../svg/CheckTwo";
import ArrowFourSvg from "../../svg/ArrowFourSvg";
import ArrowFiveSvg from "../../svg/ArrowFiveSvg";
import { ApText } from "../../components/v1/typography";
import { t } from "i18next";
import { ApDropdown } from "../../components/v1";

interface IProps {
  followingStatus?: any;
  setFollowingStatus?: any;
  orderStatus?: any;
  setOrderStatus?: any;
}

export const CopyTradesFilter: React.FC<IProps> = ({
  followingStatus,
  orderStatus,
  setFollowingStatus,
  setOrderStatus,
}) => {
  const [followingOpened, setFollowingOpened] = useState(false);
  const [orderOpened, setOrderOpened] = useState(false);

  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        height: 50,
        zIndex: 9999999,
      }}
    >
      <View
        style={{
          width: "45%",
          display: "flex",
          flexDirection: "column",
          marginRight: 20,
        }}
      >
        <ApText
          size="xs"
          style={{ marginBottom: 10 }}
          translateKey="FollowingStatus"
        />
        <DropDownPicker
          dropDownDirection="BOTTOM"
          onChangeValue={(e: any) => setFollowingStatus(e)}
          style={{
            // zIndex: followingOpened ? 999 : 1,
            width: "100%",
            minWidth: 100,
            backgroundColor: "#FF8B7E",
            borderWidth: 0,
            minHeight: 25,
            borderRadius: 6,
            zIndex: 9999,
          }}
          open={followingOpened}
          value={followingStatus}
          arrowIconStyle={{
            width: 11,
            height: 11,
            borderColor: "white",
          }}
          ArrowDownIconComponent={({ style }) => <ArrowFourSvg />}
          TickIconComponent={({ style }) => <CheckTwo />}
          items={[
            { label: t("All"), value: "2" },
            { label: t("Following"), value: "1" },
            { label: t("NotFollowing"), value: "0" },
          ]}
          setOpen={setFollowingOpened}
          setValue={setFollowingStatus}
          textStyle={{
            fontSize: 12,
            color: "white",
            textAlign: "center",
          }}
          ArrowUpIconComponent={({ style }) => <ArrowFiveSvg />}
          dropDownContainerStyle={
            {
              paddingVertical: 5,
              borderWidth: 0,
              backgroundColor: "#FF8B7E",
              zIndex: followingOpened ? 999 : 1,
              position: "static",
            } as any
          }
          zIndex={3000}
          zIndexInverse={1000}
        />
      </View>

      <View
        style={{
          width: "45%",
          display: "flex",
          flexDirection: "column",
          // position: "absolute",
          // right: 0,
        }}
      >
        <ApText
          translateKey="OrderStatus"
          size="xs"
          style={{ marginBottom: 10 }}
        >
          Order Status
        </ApText>
        <View style={{ zIndex: orderOpened ? 999 : 1 }}>
          <DropDownPicker
            dropDownDirection="BOTTOM"
            onChangeValue={(e: any) => {
              setOrderStatus(e);
            }}
            style={{
              // width: "50%",
              minWidth: 100,
              backgroundColor: "#FF8B7E",
              borderWidth: 0,
              minHeight: 25,
              borderRadius: 6,
            }}
            open={orderOpened}
            value={orderStatus}
            arrowIconStyle={{
              width: 11,
              height: 11,
              borderColor: "white",
            }}
            ArrowDownIconComponent={({ style }) => <ArrowFourSvg />}
            TickIconComponent={({ style }) => <CheckTwo />}
            items={[
              { label: t("All"), value: "3" },
              { label: t("Normal"), value: "0" },
              { label: t("Error"), value: "1" },
            ]}
            setOpen={setOrderOpened}
            setValue={setOrderStatus}
            textStyle={{
              fontSize: 12,
              color: "white",
              textAlign: "center",
            }}
            ArrowUpIconComponent={({ style }) => <ArrowFiveSvg />}
            dropDownContainerStyle={
              {
                paddingVertical: 5,
                borderWidth: 0,
                backgroundColor: "#FF8B7E",
                position: "static",
                zIndex: orderOpened ? 999 : 1,
              } as any
            }
            containerProps={{
              style: { zIndex: 9999 },
            }}
            zIndex={3000}
            zIndexInverse={1000}
          />
        </View>
      </View>
    </View>
  );
};
