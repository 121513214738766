import React, { useCallback, useState } from "react";
import {
  ScrollView,
  TextInput,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from "react-native";
import Modal from "react-native-modal";
import { components } from "../components";
import { theme } from "../constants";
import { svg } from "../svg";
// import Slider from "rn-range-slider";
import { ApRow, ApSafeAreaView } from "../components/v1";
import { ApText } from "../components/v1/typography";

const StartBotDetails: React.FC = ({ navigation }: any) => {
  const [toggle, setToggle] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [masterOrVisa, setMasterOrVisa] = useState("Visa");
  const [paymentOpen, setPaymentOpen] = useState(false);
  const [directOpen, setdirectOpen] = useState(false);
  const [klinemonitor, setklinemonitor] = useState(false);
  const [takeProfitmonitor, setTakeProfitmonitor] = useState(false);
  const [klineMACD, setklineMACD] = useState(false);
  const [klineBB, setklineBB] = useState(false);
  const [klineEMA, setklineEMA] = useState(false);
  const layout = useWindowDimensions();
  const [index, setIndex] = React.useState(0);
  const [shareStrategy, setShareStrategy] = useState(false);
  const [isSelected, setSelection] = useState(false);

  const [paymentValue, setPaymentValue] = useState(null);
  const [paymentType, setPaymentType] = useState([
    {
      value: "1",
      label: "Binance",
    },
    {
      value: "2",
      label: "Ouyi",
    },
  ]);
  const [directionType, setDirectionType] = useState([
    {
      value: "1",
      label: "Long",
    },
    {
      value: "2",
      label: "Short",
    },
    {
      value: "3",
      label: "Both",
    },
  ]);
  const [klineIntervalType, setKlineIntervaltype] = useState([
    {
      value: "1",
      label: "Left Trade",
    },
    {
      value: "2",
      label: "Right Trade",
    },
  ]);
  const [klineBBType, setKlineBBType] = useState([
    {
      value: "1",
      label: "Open",
    },
    {
      value: "2",
      label: "Close",
    },
  ]);
  const [intervalLength, setIntervalLength] = useState([
    {
      value: "0m",
      label: "Never",
    },
    {
      value: "1m",
      label: "Every minute",
    },
    {
      value: "5m",
      label: "Every 5 min",
    },
    {
      value: "15m",
      label: "Every 15 min",
    },
    {
      value: "30m",
      label: "Every 30 min",
    },
    {
      value: "1h",
      label: "Every hour",
    },
    {
      value: "2h",
      label: "Every 2 hour",
    },
    {
      value: "4h",
      label: "Every 4 hour",
    },
    {
      value: "6h",
      label: "Every 6 hour",
    },
    {
      value: "8h",
      label: "Every 8 hour",
    },
  ]);

  const [renderModal, setRenderModal] = useState({
    strategyInfo: false,
    strategyDirection: false,
    profitMethod: false,
    profitSetting: false,
    SurplusWay: false,
  });
  const [botPlan, setBotPlan] = useState({
    labelCode: "",
    market: "",
    direction: "",
    doubleInitFund: "",
    onTrendInitFund: "",
    maxEntries: "",
    entrySize: "",
    entryPriceDiff: "",
    entryTrailing: "",
    waterfallPerc: "",
    klineFrequency: "",
    entryTaMacd: "",
    entryTaBb: "",
    entryTaEma4: "",
    entryTpMode: "",
    entryTpPerc: "",
    entryTpTrailingPerc: "",
    macdTpInterval: "",
    slPerc: "",
    isPeer: "",
    planDesc: "",
    peerFee: "",
    isVisible: "",
    secPassword: "",
    firstOrderMargin: 1000,
  });

  const createBotPlan = () => {
    const body = new URLSearchParams();
    body.append("label_code", botPlan.labelCode);
    body.append("market", botPlan.market);
    body.append("direction", botPlan.direction);
    body.append("double_init_fund", botPlan.doubleInitFund);
    body.append("ontrend_init_fund", botPlan.onTrendInitFund);
    body.append("max_entries", botPlan.maxEntries);
    body.append("entry_size", botPlan.entrySize);
    body.append("entry_price_diff", botPlan.entryPriceDiff);
    body.append("entry_trailing", botPlan.entryTrailing);
    body.append("waterfall_perc", botPlan.waterfallPerc);
    body.append("kline_frequency", botPlan.klineFrequency);
    body.append("entry_ta_macd", botPlan.entryTaMacd);
    body.append("entry_ta_bb", botPlan.entryTaBb);
    body.append("entry_ta_ema4", botPlan.entryTaEma4);
    body.append("entry_tp_mode", botPlan.entryTpMode);
    body.append("entry_tp_perc", botPlan.entryTpPerc);
    body.append("entry_tp_trailing_perc", botPlan.entryTpTrailingPerc);
    body.append("macd_tp_interval", botPlan.macdTpInterval);
    body.append("sl_perc", botPlan.slPerc);
    body.append("is_peer", botPlan.isPeer);
    body.append("plan_desc", botPlan.planDesc);
    body.append("peer_fee", botPlan.peerFee);
    body.append("is_visible", botPlan.isVisible);
    body.append("sec_password", botPlan.secPassword);

    // ApiCall.post("auth/registerMember", body)
    //   .then((response) => {
    //     if (response.data.status == "1") {
    //       alert("Registration Succesful");

    //       navigation.navigate("SignIn");
    //     } else {
    //       alert("Invalid password or username");
    //     }
    //   })
    //   .catch((error) => {});
  };

  // renderModal.StrategyType
  // setShowInfoModal(false)
  // setRenderModal({...renderModal, StrategyType: true})
  // setRenderModal({...renderModal, setting: true})

  const [routes] = React.useState([
    { key: "first", title: "Whole" },
    { key: "second", title: "In execution" },
  ]);

  const renderHeader = () => {
    return <components.Header title="Strategy Details" goBack={true} />;
  };

  const renderStrategyInfo = () => {
    return (
      <Modal
        isVisible={renderModal.strategyInfo}
        onBackdropPress={() =>
          setRenderModal({ ...renderModal, strategyInfo: false })
        }
        hideModalContentWhileAnimating={true}
        backdropTransitionOutTiming={0}
        style={{ margin: 0 }}
      >
        <View
          style={{
            backgroundColor: theme.COLORS.bgColor,
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
            position: "absolute",
            width: theme.SIZES.width,
            bottom: 0,
            paddingHorizontal: 20,
            paddingTop: 30,
            paddingBottom: 54,
          }}
        >
          <ApText
            style={{
              textTransform: "capitalize",
              marginBottom: 20,
              textAlign: "center",
              ...theme.FONTS.H4,
              color: theme.COLORS.mainDark,
            }}
          >
            Select Strategy
          </ApText>
        </View>
      </Modal>
    );
  };
  const renderStrategyDirection = () => {
    return (
      <Modal
        isVisible={renderModal.strategyDirection}
        onBackdropPress={() =>
          setRenderModal({ ...renderModal, strategyDirection: false })
        }
        hideModalContentWhileAnimating={true}
        backdropTransitionOutTiming={0}
        style={{ margin: 0 }}
      >
        <View
          style={{
            backgroundColor: theme.COLORS.bgColor,
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
            position: "absolute",
            width: theme.SIZES.width,
            bottom: 0,
            paddingHorizontal: 20,
            paddingTop: 30,
            paddingBottom: 54,
          }}
        >
          <ApText
            style={{
              textTransform: "capitalize",
              marginBottom: 20,
              textAlign: "center",
              ...theme.FONTS.H4,
              color: theme.COLORS.mainDark,
            }}
          >
            Select Strategy
          </ApText>
        </View>
      </Modal>
    );
  };
  const renderSurplusWay = () => {
    return (
      <Modal
        isVisible={renderModal.SurplusWay}
        onBackdropPress={() =>
          setRenderModal({ ...renderModal, SurplusWay: false })
        }
        hideModalContentWhileAnimating={true}
        backdropTransitionOutTiming={0}
        style={{ margin: 0 }}
      >
        <View
          style={{
            backgroundColor: theme.COLORS.bgColor,
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
            position: "absolute",
            width: theme.SIZES.width,
            bottom: 0,
            paddingHorizontal: 20,
            paddingTop: 30,
            paddingBottom: 54,
          }}
        >
          <ApText
            style={{
              textTransform: "capitalize",
              marginBottom: 20,
              textAlign: "center",
              ...theme.FONTS.H4,
              color: theme.COLORS.mainDark,
            }}
          >
            选择止盈方式
          </ApText>
        </View>
      </Modal>
    );
  };
  const onPaymentOpen = useCallback(() => {
    setPaymentOpen(false);
  }, []);

  const renderContent = () => {
    return (
      <ScrollView style={{ flex: 1, padding: 20 }}>
        <ApText
          style={{
            fontSize: 14,
            color: "#F37021",
            fontWeight: "500",
            paddingBottom: 10,
          }}
        >
          Execution Settings
        </ApText>
        <View
          style={{
            marginBottom: 20,
            padding: 15,
            backgroundColor: "#FCF8F6",
            borderRadius: 10,
          }}
        >
          <View
            style={{
              borderBottomWidth: 1,
              paddingBottom: 12,
              borderBottomColor: "#ced6e182",
            }}
          >
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {/* <Image
              source={require("../../assets/other-icons/16.png")}
              style={{ width: 16, height: 16, marginRight: 10 }}
            /> */}
              <ApText
                style={{
                  ...theme.FONTS.H6,
                  color: theme.COLORS.mainDark,
                }}
              >
                Symbol
              </ApText>

              <TouchableOpacity
                onPress={() => navigation.navigate("TokenList")}
                style={{
                  backgroundColor: "#F37021",
                  paddingVertical: 3,
                  width: 80,
                  borderRadius: 4,
                }}
              >
                <ApText
                  style={{ textAlign: "center", fontSize: 12, color: "white" }}
                >
                  Select
                </ApText>
              </TouchableOpacity>
            </View>

            <View style={{ paddingTop: 7, flexDirection: "row" }}>
              <View
                style={{
                  backgroundColor: "#05B491",
                  width: "fit-content",
                  paddingHorizontal: 10,
                  paddingVertical: 2,
                  borderRadius: 5,
                  flexDirection: "row",
                  alignItems: "center",
                  marginRight: 7,
                }}
              >
                <ApText style={{ color: "white", fontSize: 10 }}>
                  AVVE/USDT
                </ApText>{" "}
                <TouchableOpacity style={{ paddingLeft: 5 }}>
                  <svg.CloseWhiteSvg />
                </TouchableOpacity>
              </View>
              <View
                style={{
                  backgroundColor: "#05B491",
                  width: "fit-content",
                  paddingHorizontal: 10,
                  paddingVertical: 2,
                  borderRadius: 5,
                  flexDirection: "row",
                  alignItems: "center",
                  marginRight: 7,
                }}
              >
                <ApText style={{ color: "white", fontSize: 10 }}>
                  GMT/USDT
                </ApText>{" "}
                <TouchableOpacity style={{ paddingLeft: 5 }}>
                  <svg.CloseWhiteSvg />
                </TouchableOpacity>
              </View>
              <View
                style={{
                  backgroundColor: "#05B491",
                  width: "fit-content",
                  paddingHorizontal: 10,
                  paddingVertical: 2,
                  borderRadius: 5,
                  flexDirection: "row",
                  alignItems: "center",
                  marginRight: 7,
                }}
              >
                <ApText style={{ color: "white", fontSize: 10 }}>
                  BTC/USDT
                </ApText>{" "}
                <TouchableOpacity style={{ paddingLeft: 5 }}>
                  <svg.CloseWhiteSvg />
                </TouchableOpacity>
              </View>
            </View>
          </View>

          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              borderBottomWidth: 1,
              paddingVertical: 12,
              borderBottomColor: "#ced6e182",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <ApText
              style={{
                ...theme.FONTS.H6,
                color: theme.COLORS.mainDark,
                width: "50%",
              }}
            >
              First Order Margin
            </ApText>

            <TextInput
              // placeholder="1000 Times"
              value={botPlan.firstOrderMargin + " Times"}
              onChange={(e: any) => {
                setBotPlan({
                  ...botPlan,
                  firstOrderMargin: e.target.defaultValue,
                });
              }}
              textAlign="center"
              placeholderTextColor={"#F37021"}
              style={{
                backgroundColor: "#DFE3EA",
                width: 100,
                color: "#F37021",
                textAlign: "center",
                fontSize: 13,
                padding: 3,
                borderRadius: 6,
              }}
            />
          </View>
          <View>
            <View
              style={{
                borderBottomWidth: 1,
                paddingVertical: 12,
                borderBottomColor: "#ced6e182",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <ApText
                  style={{
                    ...theme.FONTS.H6,
                    color: theme.COLORS.mainDark,
                    width: "50%",
                  }}
                >
                  Leverage
                </ApText>
                <TextInput
                  placeholder="20X"
                  textAlign="center"
                  placeholderTextColor={"#F37021"}
                  style={{
                    backgroundColor: "#DFE3EA",
                    width: 100,
                    color: "#F37021",
                    textAlign: "center",
                    fontSize: 13,
                    padding: 3,
                    borderRadius: 6,
                  }}
                />
              </View>
              <ApRow>
                <ApText
                  style={{ fontSize: 11, color: "#F37021", paddingTop: 5 }}
                  translateKey="MaximumFund"
                />
                <ApText
                  style={{ fontSize: 11, color: "#F37021", paddingTop: 5 }}
                >
                  : 3600.02 USDT
                </ApText>
              </ApRow>
            </View>
          </View>

          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              paddingVertical: 12,
              paddingBottom: 0,
              borderBottomColor: "#ced6e182",
              justifyContent: "space-between",
            }}
          >
            <ApText
              style={{
                ...theme.FONTS.H6,
                color: theme.COLORS.mainDark,
                width: "50%",
              }}
              translateKey="ExecutionRound"
            />
            <TextInput
              placeholder="50.00 Times"
              textAlign="center"
              placeholderTextColor={"#F37021"}
              style={{
                backgroundColor: "#DFE3EA",
                width: 100,
                color: "#F37021",
                textAlign: "center",
                fontSize: 13,
                padding: 3,
                borderRadius: 6,
              }}
            />
          </View>
        </View>

        <ApText
          style={{
            fontSize: 14,
            color: "#F37021",
            fontWeight: "500",
            paddingBottom: 10,
          }}
        >
          Trader Comment
        </ApText>
        <View
          style={{
            marginBottom: 20,
            padding: 15,
            backgroundColor: "#FCF8F6",
            borderRadius: 10,
          }}
        >
          <components.InputField
            containerStyle={{ backgroundColor: "white", height: 60 }}
            placeholder="Comment..."
          />
        </View>
        <TouchableOpacity
          // onPress={() => navigation.navigate("StartBotDetails")}
          style={{
            backgroundColor: "#F37021",
            marginVertical: 30,
            marginTop: 18,
            height: 45,
            borderRadius: 5,
            alignItems: "center",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <ApText style={{ color: theme.COLORS.white }}>Save</ApText>
        </TouchableOpacity>
      </ScrollView>
    );
  };

  return (
    <ApSafeAreaView>
      {renderHeader()}

      {renderContent()}
      {renderStrategyInfo()}
      {renderSurplusWay()}
      {renderStrategyDirection()}
    </ApSafeAreaView>
  );
};

export default StartBotDetails;
