import { View, TextInput, TouchableOpacity } from "react-native";
import React, { useState } from "react";

import { theme } from "../constants";

export type Props = {
  placeholder?: string;
  secureTextEntry?: boolean;
  icon?: any;
  keyboardType?: "default" | "email-address" | "numeric" | "phone-pad";
  containerStyle?: any;
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  onChange?: any;
  defaultValue?: string;
  value?: string;
  showSoftInputOnFocus?: boolean;
};

const InputField: React.FC<Props> = ({ placeholder, containerStyle, secureTextEntry, keyboardType, leftIcon, rightIcon, onChange, value, defaultValue, showSoftInputOnFocus }) => {
  const [isFocused, setIsFocused] = useState(0);

  return (
    <View
      style={{
        height: 45,
        width: "100%",
        borderRadius: 10,
        borderWidth: 1,
        borderColor: isFocused ? theme.COLORS.mainDark : theme.COLORS.transparent,
        flexDirection: "row",
        alignItems: "center",
        ...containerStyle,
      }}
    >
      {leftIcon && leftIcon}
      <TextInput
        placeholder={placeholder}
        style={{
          flex: 1,
          height: "100%",
          width: "100%",
          flexDirection: "row",
          justifyContent: "space-between",
          fontSize: 14,
          lineHeight: 16 * 1,
          ...theme.FONTS.Mulish_400Regular,
        }}
        secureTextEntry={secureTextEntry}
        placeholderTextColor={"#c6c6c6"}
        keyboardType={keyboardType}
        numberOfLines={1}
        onFocus={() => setIsFocused(1)}
        onBlur={() => setIsFocused(0)}
        onChange={onChange}
        value={value}
        defaultValue={defaultValue}
        showSoftInputOnFocus={showSoftInputOnFocus}
      />
      {rightIcon && rightIcon}
    </View>
  );
};

export default InputField;
