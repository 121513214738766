import { Formik, FormikProps } from "formik";
import React, { useRef } from "react";
import { View } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import * as Yup from "yup";
import { components } from "../components";
import { ApButton, ApSafeAreaView, ApTextInput } from "../components/v1";
import { ApText } from "../components/v1/typography";
import { ERROR_MSG } from "../constants/errors";
import {
  useBusinessState,
  useProfileState,
  useTransactionState,
} from "../context";
import { svg } from "../svg";

const FormSchema = Yup.object().shape({
  amount: Yup.number().min(1).required(ERROR_MSG.AmountRequired),
  sec_password: Yup.string().required(ERROR_MSG.SecurityPassRequired),
});

const Stake: React.FC = ({ navigation, route }: any) => {
  const wallet = route.params?.wallet && route.params?.wallet;
  const { profileData, getProfileData } = useProfileState();
  const { loading, stakeDDCoin, unStakeDDCoin } = useBusinessState();
  const { selectedWallet, getDDCoin, getStakedDDCoin } = useTransactionState();
  const formRef = useRef<FormikProps<any>>();

  const renderHeader = () => {
    return (
      <components.Header
        title={selectedWallet === "ddb_wallet" ? "DD Coin Staking" : "Unstake"}
        goBack={true}
        converHistory={true}
        titleTranlateKey={selectedWallet === "ddb_wallet" ? "Stake" : "Unstake"}
      />
    );
  };

  const handleStake = (values: any) => {
    selectedWallet === "ddb_wallet"
      ? stakeDDCoin(values).then((rs) => {
          getProfileData();
          getDDCoin();
          getStakedDDCoin();
        })
      : unStakeDDCoin(values).then((rs) => {
          getProfileData();
          getDDCoin();
          getStakedDDCoin();
        });
  };

  return (
    <ApSafeAreaView>
      {renderHeader()}

      <Formik
        innerRef={formRef as any}
        validationSchema={FormSchema}
        initialValues={{ amount: "", sec_password: "" }}
        onSubmit={handleStake}
      >
        {(props: FormikProps<any>) => (
          <KeyboardAwareScrollView
            contentContainerStyle={{ flexGrow: 1, paddingHorizontal: 20 }}
          >
            <View style={{ paddingTop: 10 }}>
              <ApTextInput
                max
                maxAmount={profileData[selectedWallet]}
                containerStyle={{ paddingBottom: 0 }}
                label={"Amount"}
                name="amount"
                labelTranslateKey="Amount"
                keyboardType="numeric"
                placeHolderTranslateKey={
                  selectedWallet === "ddb_wallet"
                    ? "PleaseEnterStakeAmount"
                    : "PleaseEnterUnStakeAmount"
                }
                placeholder={
                  selectedWallet === "ddb_wallet"
                    ? "Please enter the stake amount"
                    : "Please enter the amount to be unstaked"
                }
                icon={<svg.CheckSvg />}
              />
              <ApText
                size="xs"
                style={{
                  paddingLeft: 5,
                  marginBottom: 10,
                  marginTop: 10,
                  color: "#F37021",
                }}
              >
                <ApText size="xs" translateKey="Balance" />
                {" : "}
                {profileData[selectedWallet]}
              </ApText>

              <ApTextInput
                label="Security Password"
                secureTextEntry
                name="sec_password"
                labelTranslateKey="SecPassword"
                placeholder="Please enter payment password"
                placeHolderTranslateKey="PleaseEnterSecPassword"
                icon={<svg.CheckSvg />}
              />
              {/* 
              <View style={{ flexDirection: "row" }}>
                <ApText
                  style={{ fontSize: 12, fontWeight: "500", color: "grey", marginRight: 3 }}
                  translateKey="Available Balance : "
                />
                <ApText
                  style={{ fontSize: 12, fontWeight: "bold", color: "#F37021" }}
                >
                  {
                    selectedWallet === "ddb_wallet" ?
                    profileData.ddb_wallet
                    ? profileData.ddb_wallet
                    : "Fetching" :
                    profileData.stake_wallet
                    ? profileData.stake_wallet
                    : "Fetching"
                  }{" "}
                </ApText>
              </View> */}

              <ApButton
                loading={loading}
                disabled={loading}
                label={selectedWallet === "ddb_wallet" ? "Stake" : "Unstake"}
                round="lg"
                labelTranslateKey={
                  selectedWallet === "ddb_wallet" ? "Stake" : "Unstake"
                }
                type="primary"
                onPress={props.handleSubmit}
              />
            </View>
          </KeyboardAwareScrollView>
        )}
      </Formik>
    </ApSafeAreaView>
  );
};

export default Stake;
