import React from "react";
import { ScrollView, View, useWindowDimensions } from "react-native";
import { components } from "../components";
import { ApHtmlText, ApText } from "../components/v1/typography";
import { useProfileState } from "../context";

const AnnouncementDetails: React.FC = ({ navigation, route }: any) => {
  const { width } = useWindowDimensions();
  const { selectedAnnoucement } = useProfileState()

  const renderHeader = () => {
    return (
      <components.Header
        title="Announcement Details"
        titleTranlateKey="AnnouncementDetails"
        goBack={true}
      />
    );
  };

  const renderContent = () => {
    return (
      <View
        style={{
          // backgroundColor: "#fff5f0ba",
          borderRadius: 10,
          paddingVertical: 20,
          paddingHorizontal: 20,
        }}
      >
        <View style={{ marginBottom: 2 }}>
          <ApText style={{ fontWeight: "bold", fontSize: 14 }}>
            {selectedAnnoucement.title}
          </ApText>
        </View>

        <ApText style={{color: "#F37021", fontSize: 14, marginBottom: 10}}>
            {selectedAnnoucement.created_at.split(' ')[0]}
        </ApText>

        <ApHtmlText  style={{color: "#8e8e8e"}} content={route.params.content} width={width - 30} />
      </View>
    );
  };

  return (
    <ScrollView
      contentContainerStyle={{
        flexGrow: 1,
      }}
      showsVerticalScrollIndicator={false}
      bounces={false}
    >
      {renderHeader()}
      {renderContent()}
    </ScrollView>
  );
};

export default AnnouncementDetails;
