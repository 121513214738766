import Header from "./Header";
import ProfileCategory from "./ProfileCategory";
import StrategyCategory from "./StrategyCategory";
import Button from "./Button";
import InputField from "./InputField";
import LoginHeader from "./LoginHeader";
import InputFiedLogin from "./InputFieldLogin";
import LoginButton from "./LoginButton";
import Transaction from "./Transaction";
import AnnouncementNav from "./AnnouncementNav";
import TeofinShadow from "./TeofinShadow";
import SmallHeader from "./SmallHeader";
import LoginRegisterButton from "./LoginRegisterButton";
import SearchInputField from "./SearchInputField";
import CustomHeader from "./CustomHeader";

const components = {
  Header,
  LoginRegisterButton,
  ProfileCategory,
  LoginButton,
  LoginHeader,
  StrategyCategory,
  InputFiedLogin,
  Button,
  InputField,
  Transaction,
  AnnouncementNav,
  TeofinShadow,
  SmallHeader,
  SearchInputField,
  CustomHeader,
};

export { components };
