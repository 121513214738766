import React, { useState } from "react";
import { ApiService } from "../services";

interface IFollowState {
  loading: boolean;
  copyStrategyBotPlans: any[];
  filteredCopyStrategyBotPlans: any[];
  activeBotPlan: any;
  setActiveBotP: (botPlan: any) => Promise<void>;
  getCopyStrategyBotPlans: (
    platform: string,
    market: string,
    init?: boolean
  ) => void;
}

const FollowContext = React.createContext<IFollowState>({
  loading: false,
  copyStrategyBotPlans: [],
  filteredCopyStrategyBotPlans: [],
  activeBotPlan: {},

  setActiveBotP() {
    return null;
  },
  getCopyStrategyBotPlans() {},
});

const useFollowContext = () => {
  const context = React.useContext(FollowContext);
  if (context === undefined) {
    throw new Error("app dispatch must be used within app global provider");
  }

  return context;
};
interface IProps {
  children: React.ReactNode;
}

const FollowContextProvider: React.FC<IProps> = ({ children }) => {
  const [copyStrategyBotPlans, setCopyStrategyBotPlans] = useState<any[]>([]);
  const [filteredCopyStrategyBotPlans, setFilteredCopyStrategyBotPlans] =
    useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [activeBotPlan, setActiveBotPlan] = useState<any>();

  const getCopyStrategyBotPlans = (
    platform: string,
    market: string,
    init?: boolean
  ) => {
    setLoading(true);
    if (init) {
      ApiService.get("/trading/getCopyStrategyBotPlans")
        .then((rs) => {
          setCopyStrategyBotPlans(rs.data?.data);
        })
        .finally(() => setLoading(false));
    } else {
      setFilteredCopyStrategyBotPlans(
        copyStrategyBotPlans
          .filter((item) => item?.trader?.platform === platform)
          .filter((item) => item?.market === market)
      );
      setLoading(false);
    }
  };

  const setActiveBotP = (botPlan: any) => {
    let myPromise = new Promise<void>((resolve, reject) => {
      setActiveBotPlan(botPlan);
      resolve(botPlan);
    });

    return myPromise;
  };

  return (
    <FollowContext.Provider
      value={{
        filteredCopyStrategyBotPlans,
        activeBotPlan,
        setActiveBotP,
        loading,
        getCopyStrategyBotPlans,
        copyStrategyBotPlans,
      }}
    >
      {children}
    </FollowContext.Provider>
  );
};

export { FollowContextProvider, useFollowContext };
