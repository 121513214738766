import { useNavigation } from "@react-navigation/native";
import React, { Dispatch, SetStateAction, useCallback, useState } from "react";
import {
  IConvertHistory,
  IDepositHistory,
  IincomeSummary,
  ITransfer,
  IWalletOptions,
  IWalletTransaction,
  IWalletTransactionSummary,
  IWithdrawHistory,
  IWithdrawRequest,
  TtxType,
  TWalletType,
} from "../models/transaction.interface";
import { ApErrorToast, ApiService, ApSuccessToast } from "../services";
import { TransactionService } from "../services/Transaction";
import { objToQueryStr } from "../utils";

interface ITransactionState {
  loading: boolean;
  loadMoreBtnDisplay: {};
  walletTransaction: IWalletTransaction[];
  walletTransactionSummary: IWalletTransactionSummary[];
  depositHistory: IDepositHistory[];
  withdrawHistory: IWithdrawHistory[];
  convertHistory: IConvertHistory[];
  transferHistory: IWalletTransaction[];
  incomeSummary: IincomeSummary;
  setBackInitialState: () => Promise<unknown>;
  sortUSDT: Record<string, IWalletTransaction[]>
  sortCreditPoint: Record<string, IWalletTransaction[]>
  sortStakedDDCoin: Record<string, IWalletTransaction[]>
  sortDDCoin: Record<string, IWalletTransaction[]>
  sortDLPoint: Record<string, IWalletTransaction[]>
  sortBonusPoint: Record<string, IWalletTransaction[]>
  summaryUSDT: Record<string, IWalletTransactionSummary[]>
  summaryCreditPoint: Record<string, IWalletTransactionSummary[]>
  summaryStakedDDCoin: Record<string, IWalletTransactionSummary[]>
  summaryDDCoin: Record<string, IWalletTransactionSummary[]>
  summaryDLPoint: Record<string, IWalletTransactionSummary[]>
  summaryBonusPoint: Record<string, IWalletTransactionSummary[]>
  selectedWallet: TWalletType
  setSelectedWallet: Dispatch<SetStateAction<TWalletType>>
  getWalletTransaction: (
    options: IWalletOptions,
    walletType?: TWalletType,
    txType?: TtxType
  ) => Promise<unknown>;
  getWalletTransactionSummary: (
    options: IWalletOptions,
    walletType?: TWalletType,
    txType?: TtxType
  ) => Promise<unknown>;
  getDepositHistory: () => Promise<unknown>;
  getWithdrawHistory: () => Promise<unknown>;
  getConvertHistory: (payload?: { wallet_type: string }) => Promise<unknown>;
  getTransferHistory: (wallet_type: TWalletType) => Promise<unknown>;
  withdrawRequest: (payload: IWithdrawRequest) => Promise<void>;
  transfer: (payload: ITransfer) => Promise<string>;
  getIncomeSummary: (wallet_type: TWalletType) => void;
  getUSDT: (loadmore?: number) => Promise<void>
  getCreditPoint: (loadmore?: number) => Promise<void>
  getStakedDDCoin: (loadmore?: number) => Promise<void>
  getDDCoin: (loadmore?: number) => Promise<void>
  getDLPoint: (loadmore?: number) => Promise<void>
  getBonusPoint: (loadmore?: number) => Promise<void>
}

const TransactionContext = React.createContext<ITransactionState>({
  loading: false,
  loadMoreBtnDisplay: {},
  incomeSummary: {},
  walletTransaction: [],
  walletTransactionSummary: [],
  depositHistory: [],
  withdrawHistory: [],
  convertHistory: [],
  transferHistory: [],
  setBackInitialState(){},
  sortUSDT: [],
  sortCreditPoint: [],
  sortStakedDDCoin: [],
  sortDDCoin: [],
  sortDLPoint: [],
  sortBonusPoint: [],
  summaryUSDT: [],
  summaryCreditPoint: [],
  summaryStakedDDCoin: [],
  summaryDDCoin: [],
  summaryDLPoint: [],
  summaryBonusPoint: [],
  selectedWallet: null,
  setSelectedWallet(){},
  getWalletTransaction(
    options: IWalletOptions,
    walletType?: TWalletType,
    txType?: TtxType
  ) {
    return null;
  },
  getWalletTransactionSummary(
    options: IWalletOptions,
    walletType?: TWalletType,
    txType?: TtxType
  ) {
    return null;
  },
  getDepositHistory() {
    return null;
  },
  getWithdrawHistory() {
    return null;
  },
  getConvertHistory() {
    return null;
  },
  getTransferHistory() {
    return null;
  },
  withdrawRequest(payload: IWithdrawRequest) {
    return null;
  },
  transfer(payload: ITransfer) {
    return null;
  },
  getUSDT(){},
  getCreditPoint(){},
  getStakedDDCoin(){},
  getDDCoin(){},
  getDLPoint(){},
  getBonusPoint(){},
  getIncomeSummary() {
    return null;
  },
} as unknown as ITransactionState);

const useTransactionState = () => {
  const context = React.useContext(TransactionContext);
  if (context === undefined) {
    throw new Error("app dispatch must be used within app global provider");
  }
  return context;
};

interface IProps {
  children: React.ReactNode;
}

const TransactionContextProvider: React.FC<IProps> = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const navigation: any = useNavigation();

  const [walletTransaction, setWalletTransaction] = useState<
    IWalletTransaction[]
  >([]);
  const [walletTransactionSummary, setWalletTransactionSummary] = useState<
    IWalletTransactionSummary[]
  >([]);
  const [depositHistory, setDepositHistory] = useState<IDepositHistory[]>([]);
  const [withdrawHistory, setWithdrawHistory] = useState<IWithdrawHistory[]>(
    []
  );
  const [convertHistory, setConvertHistory] = useState<IConvertHistory[]>([]);
  const [transferHistory, setTransferHistory] = useState<IWalletTransaction[]>(
    []
  );
  const [incomeSummary, setIncomeSumary] = useState({});
  const [selectedWallet, setSelectedWallet] = useState<TWalletType>()
  const [pageLimit, setPageLimit] = useState(30)
  const [loadMoreBtnDisplay, setLoadMoreBtnDisplay] = useState({
    USDT:true,
    CreditBalance:true,
    DLPOINT:true,
    BONUS:true,
    DDCOIN:true,
    STAKED_DDCOIN:true,
  })
  const [currentPage, setCurrentPage] = useState({
    cash_wallet:1,
    credit_wallet:1,
    doli_wallet:1,
    bonus_wallet:1,
    ddb_wallet:1,
    stake_wallet:1,
  })
  const [loadedRs, setLoadedRs] = useState({
    cash_wallet:[],
    credit_wallet:[],
    doli_wallet:[],
    bonus_wallet:[],
    ddb_wallet:[],
    stake_wallet:[],
  })
  const [loadedSummary, setLoadedSummary] = useState({
    cash_wallet:[],
    credit_wallet:[],
    doli_wallet:[],
    bonus_wallet:[],
    ddb_wallet:[],
    stake_wallet:[],
  })

  const [sortUSDT, setSortUSDT] =
  useState<Record<string, IWalletTransaction[]>>();

  const [sortCreditPoint, setSortCreditPoint] =
    useState<Record<string, IWalletTransaction[]>>();

  const [sortStakedDDCoin, setSortStakedDDCoin] =
    useState<Record<string, IWalletTransaction[]>>();

  const [sortDDCoin, setSortDDCoin] =
    useState<Record<string, IWalletTransaction[]>>();

  const [sortDLPoint, setSortDLPoint] =
    useState<Record<string, IWalletTransaction[]>>();

  const [sortBonusPoint, setSortBonusPoint] =
    useState<Record<string, IWalletTransaction[]>>();

  const [summaryUSDT, setSummaryUSDT] =
  useState<Record<string, IWalletTransactionSummary[]>>();

  const [summaryCreditPoint, setSummaryCreditPoint] =
    useState<Record<string, IWalletTransactionSummary[]>>();

  const [summaryStakedDDCoin, setSummaryStakedDDCoin] =
    useState<Record<string, IWalletTransactionSummary[]>>();

  const [summaryDDCoin, setSummaryDDCoin] =
    useState<Record<string, IWalletTransactionSummary[]>>();

  const [summaryDLPoint, setSummaryDLPoint] =
    useState<Record<string, IWalletTransactionSummary[]>>();

  const [summaryBonusPoint, setSummaryBonusPoint] =
    useState<Record<string, IWalletTransactionSummary[]>>();

  const setBackInitialState = async () => {
        setSortUSDT({});
        setSortCreditPoint({});
        setSortStakedDDCoin({});
        setSortDDCoin({});
        setSortDLPoint({});
        setSortBonusPoint({});
        setSummaryUSDT({});
        setSummaryCreditPoint({});
        setSummaryStakedDDCoin({});
        setSummaryDDCoin({});
        setSummaryDLPoint({});
        setSummaryBonusPoint({});
        loadMoreBtnDisplay.USDT = true;
        loadMoreBtnDisplay.CreditBalance = true;
        loadMoreBtnDisplay.DLPOINT = true;
        loadMoreBtnDisplay.BONUS = true;
        loadMoreBtnDisplay.DDCOIN = true;
        loadMoreBtnDisplay.STAKED_DDCOIN = true;
        loadedRs.cash_wallet = [];
        loadedRs.credit_wallet = [];
        loadedRs.doli_wallet = [];
        loadedRs.bonus_wallet = [];
        loadedRs.ddb_wallet = [];
        loadedRs.stake_wallet = [];
        loadedSummary.cash_wallet = [];
        loadedSummary.credit_wallet = [];
        loadedSummary.doli_wallet = [];
        loadedSummary.bonus_wallet = [];
        loadedSummary.ddb_wallet = [];
        loadedSummary.stake_wallet = [];
  }

  const getUSDT = async (loadmore) => {
    var page = 1;
    if(loadmore){
      page = currentPage.cash_wallet + 1;
    }else{
      if(loadedRs.cash_wallet.length){
        return;
      }
      var page = 1;
      loadedRs.cash_wallet = [];
    }
    return getWalletTransaction({page:page}, "cash_wallet").then((rs: any[]) => {
      if(rs.length < pageLimit){
        loadMoreBtnDisplay.USDT = false;
      }

      var def_rs = TransactionService.sortWalletTransactionByDate(
        rs as unknown as IWalletTransaction[]
      )

      rs = [...loadedRs.cash_wallet, ...rs];
      loadedRs.cash_wallet = rs;

      var sortedrs = TransactionService.sortWalletTransactionByDate(
        rs as unknown as IWalletTransaction[]
      );
      setSortUSDT(
        sortedrs
      );

      var sortedkeys = Object.keys(def_rs);
      var fromDate = sortedkeys[sortedkeys.length - 1];
      var toDate = sortedkeys[0];
      getWalletTransactionSummary({fromDate:fromDate, toDate:toDate}, "cash_wallet").then((rs: any[]) => {
        var loadedSum = [...loadedSummary.cash_wallet, ...rs];
        loadedSum = loadedSum.filter((entry, index, self) => {
          return index === self.findIndex(item => item.date === entry.date);
        });
        loadedSum = filterObj(loadedSum);
        loadedSummary.cash_wallet = filterObj(loadedSum);
        setSummaryUSDT(
          loadedSum
        );
      })
      currentPage.cash_wallet = page;
    });
  }
  
  const getCreditPoint = async (loadmore) => {
    if(loadmore){
      var page = currentPage.credit_wallet+ 1;
    }else{
      if(loadedRs.credit_wallet.length){
        return;
      }
      var page = 1;
      loadedRs.credit_wallet = [];
    }
    return getWalletTransaction({page:page}, "credit_wallet").then((rs: any[]) => {
      if(rs.length < pageLimit){
        loadMoreBtnDisplay.CreditBalance = false;
      }

      var def_rs = TransactionService.sortWalletTransactionByDate(
        rs as unknown as IWalletTransaction[]
      )

      rs = [...loadedRs.credit_wallet, ...rs];
      loadedRs.credit_wallet = rs;

      var sortedrs = TransactionService.sortWalletTransactionByDate(
        rs as unknown as IWalletTransaction[]
      )
      setSortCreditPoint(
        sortedrs
      );

      var sortedkeys = Object.keys(def_rs);
      var fromDate = sortedkeys[sortedkeys.length - 1];
      var toDate = sortedkeys[0];
      getWalletTransactionSummary({fromDate:fromDate, toDate:toDate}, "credit_wallet").then((rs: any[]) => {
        var loadedSum = [...loadedSummary.credit_wallet, ...rs];
        loadedSum = loadedSum.filter((entry, index, self) => {
          return index === self.findIndex(item => item.date === entry.date);
        });
        loadedSummary.credit_wallet = loadedSum;
        setSummaryCreditPoint(
          loadedSum
        );
      })
      currentPage.credit_wallet = page;
    });
  }

  const getDLPoint = async (loadmore) => {
    var page = 1;
    if(loadmore){
      page = currentPage.doli_wallet + 1;
    }else{
      if(loadedRs.doli_wallet.length){
        return;
      }
      var page = 1;
      loadedRs.doli_wallet = [];
    }
    return getWalletTransaction({page:page}, "doli_wallet").then((rs: any[]) => {
      if(rs.length < pageLimit){
        loadMoreBtnDisplay.DLPOINT = false;
      }

      var def_rs = TransactionService.sortWalletTransactionByDate(
        rs as unknown as IWalletTransaction[]
      )

      rs = [...loadedRs.doli_wallet, ...rs];
      loadedRs.doli_wallet = rs;
      
      var sortedrs = TransactionService.sortWalletTransactionByDate(
        rs as unknown as IWalletTransaction[]
      )
      setSortDLPoint(
        sortedrs
      );

      var sortedkeys = Object.keys(def_rs);
      var fromDate = sortedkeys[sortedkeys.length - 1];
      var toDate = sortedkeys[0];
      getWalletTransactionSummary({fromDate:fromDate, toDate:toDate}, "doli_wallet").then((rs: any[]) => {
        var loadedSum = [...loadedSummary.doli_wallet, ...rs];
        loadedSum = loadedSum.filter((entry, index, self) => {
          return index === self.findIndex(item => item.date === entry.date);
        });
        loadedSummary.doli_wallet = loadedSum;
        setSummaryDLPoint(
          loadedSum
        );
      })
      currentPage.doli_wallet = page;
    });
  }
  const getBonusPoint = async (loadmore) => {
    var page = 1;
    if(loadmore){
      page = currentPage.bonus_wallet + 1;
    }else{
      if(loadedRs.bonus_wallet.length){
        return;
      }
      var page = 1;
      loadedRs.bonus_wallet = [];
    }
    return getWalletTransaction({page:page}, "bonus_wallet").then((rs: any[]) => {
      if(rs.length < pageLimit){
        loadMoreBtnDisplay.BONUS = false;
      }

      var def_rs = TransactionService.sortWalletTransactionByDate(
        rs as unknown as IWalletTransaction[]
      )
      
      rs = [...loadedRs.bonus_wallet, ...rs];
      loadedRs.bonus_wallet = rs;
      
      var sortedrs = TransactionService.sortWalletTransactionByDate(
        rs as unknown as IWalletTransaction[]
      )
      setSortBonusPoint(
        sortedrs
      );

      var sortedkeys = Object.keys(def_rs);
      var fromDate = sortedkeys[sortedkeys.length - 1];
      var toDate = sortedkeys[0];
      getWalletTransactionSummary({fromDate:fromDate, toDate:toDate}, "bonus_wallet").then((rs: any[]) => {
        var loadedSum = [...loadedSummary.bonus_wallet, ...rs];
        loadedSum = loadedSum.filter((entry, index, self) => {
          return index === self.findIndex(item => item.date === entry.date);
        });
        loadedSummary.bonus_wallet = loadedSum;
        setSummaryBonusPoint(
          loadedSum
        );
      })
      currentPage.bonus_wallet = page;
    });
  }

  const getDDCoin = async (loadmore) => {
    var page = 1;
    if(loadmore){
      page = currentPage.ddb_wallet + 1;
    }else{
      if(loadedRs.ddb_wallet.length){
        return;
      }
      var page = 1;
      loadedRs.ddb_wallet = [];
    }
    return getWalletTransaction({page:page}, "ddb_wallet").then((rs: any[]) => {
      if(rs.length < pageLimit){
        loadMoreBtnDisplay.DDCOIN = false;
      }

      var def_rs = TransactionService.sortWalletTransactionByDate(
        rs as unknown as IWalletTransaction[]
      )
      
      rs = [...loadedRs.ddb_wallet, ...rs];
      loadedRs.ddb_wallet = rs;
      
      var sortedrs = TransactionService.sortWalletTransactionByDate(
        rs as unknown as IWalletTransaction[]
      )
      setSortDDCoin(
        sortedrs
      );

      var sortedkeys = Object.keys(def_rs);
      var fromDate = sortedkeys[sortedkeys.length - 1];
      var toDate = sortedkeys[0];
      getWalletTransactionSummary({fromDate:fromDate, toDate:toDate}, "ddb_wallet").then((rs: any[]) => {
        var loadedSum = [...loadedSummary.ddb_wallet, ...rs];
        loadedSum = loadedSum.filter((entry, index, self) => {
          return index === self.findIndex(item => item.date === entry.date);
        });
        loadedSummary.ddb_wallet = loadedSum;
        setSummaryDDCoin(
          loadedSum
        );
      })
      currentPage.ddb_wallet = page;
    });
  }

  const getStakedDDCoin = async (loadmore) => {
    var page = 1;
    if(loadmore){
      page = currentPage.stake_wallet + 1;
    }else{
      if(loadedRs.stake_wallet.length){
        return;
      }
      var page = 1;
      loadedRs.stake_wallet = [];
    }
    return getWalletTransaction({page:page}, "stake_wallet").then((rs: any[]) => {
      if(rs.length < pageLimit){
        loadMoreBtnDisplay.STAKED_DDCOIN = false;
      }

      var def_rs = TransactionService.sortWalletTransactionByDate(
        rs as unknown as IWalletTransaction[]
      )
      
      rs = [...loadedRs.stake_wallet, ...rs];
      loadedRs.stake_wallet = rs;
      
      var sortedrs = TransactionService.sortWalletTransactionByDate(
        rs as unknown as IWalletTransaction[]
      )
      setSortStakedDDCoin(
        sortedrs
      );

      var sortedkeys = Object.keys(def_rs);
      var fromDate = sortedkeys[sortedkeys.length - 1];
      var toDate = sortedkeys[0];
      getWalletTransactionSummary({fromDate:fromDate, toDate:toDate}, "stake_wallet").then((rs: any[]) => {
        var loadedSum = [...loadedSummary.stake_wallet, ...rs];
        loadedSum = loadedSum.filter((entry, index, self) => {
          return index === self.findIndex(item => item.date === entry.date);
        });
        setSummaryStakedDDCoin(
          loadedSum
        );
      })
      currentPage.stake_wallet = page;
    });
  }

  const filterObj = (obj) => {
    // Declare a new array
    let newArray = [];
 
    // Declare an empty object
    let uniqueObject = {};

    // Loop for the array elements
    for (let i in obj) {

        // Extract the title
        var objDate = obj[i]['date'];

        // Use the title as the index
        uniqueObject[objDate] = obj[i];
    }

    // Loop to push unique object into array
    for (let i in uniqueObject) {
        newArray.push(uniqueObject[i]);
    }

    return newArray;
  }

  const getWalletTransaction = (
    options: IWalletOptions = {},
    walletType?: TWalletType,
    txType?: TtxType
  ) => {
    // setLoading(true)
    let newProm = new Promise(function (resolve, reject) {
      ApiService.get(
        `/transaction/getWltTx?page=${options.page ? options.page : 1}&limit=${
          options.limit ? options.limit : pageLimit
        }&wallet_type=${walletType ? walletType : ""}&tx_type=${
          txType ? txType : ""
        }&from_date=${options.fromDate ? options.fromDate : ""}&to_date=${
          options.toDate ? options.toDate : ""
        }`
      )
        .then((res) => {
          setWalletTransaction(res.data.data);
          resolve(res.data.data);
        })
        .catch((err) => {
          ApErrorToast("", err);
          throw err;
        })
        .finally(() => {
          setLoading(false);
        });
    });

    return newProm;
  };

  const getWalletTransactionSummary = (
    options: IWalletOptions = {},
    walletType?: TWalletType,
    txType?: TtxType
  ) => {
    setLoading(true);
    let newProm = new Promise(function (resolve, reject) {
      ApiService.get(
        `/transaction/getWltTxSummary?page=${
          options.page ? options.page : 1
        }&limit=${options.limit ? options.limit : 30}&wallet_type=${
          walletType ? walletType : ""
        }&tx_type=${txType ? txType : ""}&from_date=${
          options.fromDate ? options.fromDate : ""
        }&to_date=${options.toDate ? options.toDate : ""}`
      )
        .then((res) => {
          setWalletTransactionSummary(res.data.data);
          resolve(res.data.data);
        })
        .catch((err) => {
          ApErrorToast("", err);
          throw err;
        })
        .finally(() => {
          setLoading(false);
        });
    });

    return newProm;
  };

  const getDepositHistory = () => {
    setLoading(true);
    const newProm = new Promise(function (resolve, reject) {
      ApiService.get(`/transaction/getDepositHistory`)
        .then((res) => {
          setDepositHistory(res?.data?.data);
          resolve(res.data.data);
        })
        .catch((err) => {
          ApErrorToast("", err);
          throw err;
        })
        .finally(() => {
          setLoading(false);
        });
    });

    return newProm;
  };

  const getWithdrawHistory = () => {
    setLoading(true);
    const newProm = new Promise(function (resolve, reject) {
      ApiService.get(`/transaction/getWrHistory`)
        .then((res) => {
          setWithdrawHistory(res?.data?.data);
          resolve(res.data.data);
        })
        .catch((err) => {
          ApErrorToast("", err);
          throw err;
        })
        .finally(() => {
          setLoading(false);
        });
    });

    return newProm;
  };

  const getConvertHistory = (payload?: { wallet_type: string }) => {
    setLoading(true);
    const promise = payload
      ? ApiService.get(
          `/transaction/getConversionHistory?${objToQueryStr(payload)}`
        )
      : ApiService.get(`/transaction/getConversionHistory`);

    return promise
      .then((res) => {
        setConvertHistory(res?.data?.data);
        return res?.data?.data;
      })
      .catch((err) => {
        ApErrorToast("", err);
        throw err;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getTransferHistory = (wallet_type: TWalletType) => {
    setLoading(true);
    const newProm = new Promise(function (resolve, reject) {
      ApiService.get(`/transaction/getWltTx?tx_type=transfer&wallet_type=${wallet_type}`)
        .then((res) => {
          setTransferHistory(res?.data?.data);
          resolve(res.data.data);
        })
        .catch((err) => {
          ApErrorToast("", err);
          throw err;
        })
        .finally(() => {
          setLoading(false);
        });
    });

    return newProm;
  };

  const withdrawRequest = async (payload: IWithdrawRequest) => {
    setLoading(true);
    return ApiService.post("transaction/createWithdrawRequest", payload)
      .then((rs) => {
        ApSuccessToast("", rs.data?.msg);
        navigation.navigate("WithdrawHistory");
        return rs?.data?.msg
      })
      .catch((err) => {ApErrorToast("Error", err); throw err;})
      .finally(() => setLoading(false));
  };

  const transfer = async (payload: ITransfer) => {
    setLoading(true);
    return ApiService.post(`transaction/transfer`, payload)
      .then((rs) => {
        ApSuccessToast("", rs.data?.msg);
        navigation.navigate("TransferHistory");
        return rs?.data?.msg;
      })
      .catch((err) => {
        ApErrorToast("", err);
        throw err;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getIncomeSummary = (wallet_type: TWalletType) => {
    ApiService.get(`transaction/getIncomeSummary?wallet_type=${wallet_type}`)
      .then((rs) => {
        setIncomeSumary(rs?.data?.data);
      })
      .catch((err) => {
        ApErrorToast("", err);
        throw err;
      });
  };

  return (
    <TransactionContext.Provider
      value={{
        setBackInitialState,
        sortBonusPoint,
        sortDLPoint,
        sortUSDT,
        sortStakedDDCoin,
        sortCreditPoint,
        sortDDCoin,
        summaryBonusPoint,
        summaryDLPoint,
        summaryUSDT,
        summaryStakedDDCoin,
        summaryCreditPoint,
        summaryDDCoin,
        getUSDT,
        getDLPoint,
        getBonusPoint,
        getStakedDDCoin,
        getCreditPoint,
        getDDCoin,
        setSelectedWallet,
        selectedWallet,
        loading,
        loadMoreBtnDisplay,

        incomeSummary,
        walletTransaction,
        walletTransactionSummary,
        depositHistory,
        withdrawHistory,
        convertHistory,
        transferHistory,

        getWalletTransaction,
        getWalletTransactionSummary,
        getDepositHistory,
        getWithdrawHistory,
        getConvertHistory,
        getTransferHistory,
        getIncomeSummary,
        withdrawRequest,
        transfer,
      }}
    >
      {children}
    </TransactionContext.Provider>
  );
};

export { TransactionContextProvider, useTransactionState };
