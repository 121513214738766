import { IWalletTransaction } from "../models/transaction.interface";
import { parse, format } from 'date-fns';

export class TransactionService {
  public static sortWalletTransactionByDate = (
    dataObjects: IWalletTransaction[]
  ): Record<string, IWalletTransaction[]> => {
    const sortedDataObjects: Record<string, IWalletTransaction[]> = {};

    dataObjects.forEach((dataObject) => {
      const dateObject = parse(dataObject.created_at, 'yyyy-MM-dd HH:mm:ss', new Date());

      // Format the date as `yyyy-MM-dd`
      const dateKey = format(dateObject, 'yyyy-MM-dd');

      // If the sortedDataObjects object doesn't have an array for this date, create one
      if (!sortedDataObjects[dateKey]) {
        sortedDataObjects[dateKey] = [];
      }

      // Add the current data object to the array for this date
      sortedDataObjects[dateKey].push(dataObject);
    });

    return sortedDataObjects;
  };
}
