
export const toCurrency = (val: number, currency = "", fixed?: number) => {
  if (val === null || val === undefined) return `${currency} 0.0`;
  return `${currency}` + val?.toFixed(fixed ? fixed : 2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
};

export const objToQueryStr = (obj: any) => {
  const params: URLSearchParams = new URLSearchParams();
  Object.entries(obj).forEach(([key, value]: any) => {
    if (Array.isArray(value) && !!value?.length) {
      value.forEach((value: number[]) => params.append(key, value.toString()));
    } else if (!Array.isArray(value) && obj[key]) {
      params.append(key, value.toString());
    }
  });

  return params.toString();
};


export const formatAmt = (amt: number | string, precision = 6) => {
  if (typeof amt !== "number" && typeof amt !== "string") {
    return "0.00";
    // throw new Error(
    //   "amt must be a number or a string that can be converted to a number"
    // );
  }

  let amtFloat = parseFloat(amt as any);
  if (isNaN(amtFloat)) {
    return "0.00";
    // throw new Error(
    //   "amt must be a number or a string that can be converted to a number"
    // );
  }

  let sciNotation = amtFloat.toPrecision(precision);
  let regularNotation = parseFloat(sciNotation).toString();

  // If the regular notation ends with '.0', remove that part.
  if (regularNotation.endsWith(".0")) {
    regularNotation = regularNotation.slice(0, -2);
  }

  return regularNotation.length < sciNotation.length
    ? regularNotation
    : sciNotation;
};
