import React, { useEffect, useState } from "react";
import {
  StyleProp,
  Text,
  TextInput,
  TextStyle,
  View,
  ViewStyle,
} from "react-native";
import { theme } from "../../../constants";
import { FormikProps, useField } from "formik";
import { ApText } from "../typography";

type Props = {
  width?: string | number
  height?: string | number
  textArea?: boolean;
  disabled?: boolean;
  defaultValue?: any;
  noBorder?: boolean;
  onChange?: any;
  name: string;
  label?: string;
  placeholder?: string;
  secureTextEntry?: boolean;
  icon?: any;
  keyboardType?: "default" | "email-address" | "numeric" | "phone-pad";
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  showSoftInputOnFocus?: boolean;
  containerStyle?: StyleProp<ViewStyle>;
  inputStyle?: StyleProp<TextStyle>;
  labelStyle?: TextStyle;
};

export const ApSettingsInput: React.FC<Props> = ({
  width,
  height,
  textArea,
  disabled,
  defaultValue,
  noBorder,
  onChange,
  name,
  label,
  placeholder,
  containerStyle,
  secureTextEntry,
  keyboardType,
  leftIcon,
  rightIcon,
  inputStyle,
  labelStyle,
  showSoftInputOnFocus,
}) => {
  const [isFocused, setIsFocused] = useState(0);
  const [field, meta, { setValue }] = useField(name);

  return (
    <>
      <View
        style={[
          {
            flexDirection: "row",
            alignItems: "center",
            borderBottomWidth: noBorder ? 0 : 1,
            // paddingVertical: 10,
            borderBottomColor: "#ced6e182",
            width: "100%",
            justifyContent: "space-between",
          },
          containerStyle,
        ]}
      >

        {/* {
          !noBorder ? (
            <ApText size="sm" style={labelStyle}>{label}</ApText>
          ) : (
            <View style={{width: "100%"}}>
            {label && <View style={{width: '100%', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}> 
              <ApText size="sm" style={labelStyle}>{label}</ApText>
              {meta.touched && meta.error && (
                <ApText
                  size="xs"
                  style={{
                    color: "red",
                  }}
                >
                  {meta.error}
                </ApText>
              )}
              </View>
            }
            </View>
          )
        } */}

        <View
          style={[
            {
              paddingHorizontal: 5,
             // backgroundColor: "#FCF8F6",
              // marginTop: 10,
              width: "100%",
              height: height ? height : 45,
              borderRadius: 5,
              //borderWidth: 1,
              //borderColor: '#eee',
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            },
          ]}
        >
          {leftIcon && leftIcon}
          <TextInput
           editable={disabled}
           selectTextOnFocus={disabled}
            placeholder={placeholder}
            style={[
              {
                color: "#F37021",
                flex: 1,
                height: "100%",
                width: 50,
                flexDirection: "row",
                justifyContent: "space-between",
                fontSize: 14,
                lineHeight: 16 * 1,
                ...theme.FONTS.Mulish_400Regular,
              },
              inputStyle,
            ]}
            secureTextEntry={secureTextEntry}
            placeholderTextColor={"#c6c6c6"}
            keyboardType={keyboardType}
            multiline={textArea}
            numberOfLines={textArea ? 5 : 1}
            onFocus={() => setIsFocused(1)}
            onBlur={() => setIsFocused(0)}
            onChangeText={(e) => {
              setValue(e);
              onChange && onChange(e);
            }}
            value={field.value}
            defaultValue={defaultValue}
            showSoftInputOnFocus={showSoftInputOnFocus}
          />
          {rightIcon && rightIcon}
        </View>
      </View>

      {meta.touched && meta.error && (
        <Text
          style={{
            fontSize: 14,
            color: "red",
            marginBottom: 14,
            marginTop: 14,
          }}
        >
          {meta.error}
        </Text>
      )}
    </>
  );
};
