import moment from "moment";
import React, { useEffect, useState } from "react";
import { Platform, StyleProp, View, ViewStyle } from "react-native";
import DateTimePickerModal, {
  DateTimePickerProps,
} from "react-native-modal-datetime-picker";
import { ApButton } from "../button";
import { useField } from "formik";
import { ApText } from "../typography";
import { t } from "i18next";

const DATE_FORMAT = "YYYY-MM-DD";

interface IProps extends DateTimePickerProps {
  name: string;
  label?: string;
  containerStyle?: StyleProp<ViewStyle>;
  translateKey?: string;
}

export const ApDatePicker: React.FC<IProps> = (props: IProps) => {
  const { containerStyle, translateKey } = props;
  const [field, meta, { setValue }] = useField(props.name);
  const [date, setDate] = useState<string>();
  useEffect(() => {
    const dt = field.value
      ? moment(field.value?.toString()).format(DATE_FORMAT)
      : null;
    setDate(dt);
    setValue(dt);
  }, []);

  return (
    <View style={[{ display: "flex" }, containerStyle]}>
      {translateKey && (
        <ApText color="#737373" size="sm">
          {translateKey ? t(translateKey) : props.label}
        </ApText>
      )}
      {Platform.OS == "web" ? (
        <input
          style={{
            border: "none",
            backgroundColor: "#FCF8F6",
            padding: 10,
            borderRadius: 10,
          }}
          type="date"
          value={field.value}
          onChange={(e) => {
            const dt = moment(e.currentTarget.value).format(DATE_FORMAT);
            setDate(dt);
            setValue(dt);
          }}
        />
      ) : (
        <>
          <ApButton type="link" label={date as any} />
          <DateTimePickerModal
            mode="date"
            isVisible
            date={field.value}
            {...props}
            onChange={(d) => {
              setDate(moment(d).format(DATE_FORMAT));
              setValue(moment(d).format(DATE_FORMAT));
            }}
          />
        </>
      )}
      {meta.touched && meta.error && (
        <ApText
          style={{
            fontSize: 14,
            color: "red",
            marginBottom: 14,
            marginTop: 14,
          }}
        >
          {meta.error}
        </ApText>
      )}
    </View>
  );
};
