import React, { useEffect, useState } from "react";
import { Image, ImageBackground, TouchableOpacity, View } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

import { useRoute } from "@react-navigation/native";
import * as Clipboard from "expo-clipboard";
import QRCode from "react-native-qrcode-svg";
import { components } from "../components";
import { ApLoader, ApSafeAreaView } from "../components/v1";
import { ApText } from "../components/v1/typography";
import { theme } from "../constants";
import { useProfileState } from "../context";
import { svg } from "../svg";

const Deposit: React.FC = ({ navigation }: any) => {
  const { depositAddress, getDepositAddress, loading } = useProfileState();
  const { params }: any = useRoute();
  const [showCopied, setShowCopied] = useState<boolean>(false);

  useEffect(() => {
    getDepositAddress(params?.type);
  }, [params?.type]);

  const renderHeader = () => {
    return (
      <components.Header
        title="Deposit"
        titleTranlateKey="Deposit"
        goBack={true}
        depositHistory={params.type === "usdt"}
      />
    );
  };

  const renderContent = () => {
    return (
      <ImageBackground
        source={require("../../assets/bg/08.png")}
        imageStyle={{
          width: "100%",
          backgroundColor: "#fff5f0ba",
        }}
        style={{ flex: 1 }}
      >
        <KeyboardAwareScrollView
          contentContainerStyle={{ flexGrow: 1, paddingHorizontal: 20 }}
        >
          <View style={{ paddingTop: theme.SIZES.height * 0.05 }}>
            <Image
              source={require("../../assets/referral-image.png")}
              style={{
                width: 130,
                height: 130,
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
            <View
              style={{
                backgroundColor: theme.COLORS.white,
                borderRadius: 10,

                paddingHorizontal: 20,
                paddingTop: 14,
                paddingBottom: 20,
                marginBottom: 20,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  marginBottom: 8,
                }}
              >
                <View style={{ flex: 1 }}>
                  <ApText
                    style={{
                      fontSize: 20,
                      color: "#F37021",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {params?.type == "usdt" ? "USDT-TRC20" : "DDC"}
                  </ApText>
                  <View
                    style={{
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginVertical: 15,
                      borderColor: "#F8CCC6",
                      borderWidth: 2,
                      padding: 10,
                    }}
                  >
                    <QRCode value={depositAddress.deposit_address} size={130} />
                  </View>

                  <ApText
                    style={{
                      fontSize: 13,
                      color: "#ccc",
                      fontWeight: "500",
                      textAlign: "center",
                    }}
                    translateKey="ScanCode"
                  />
                  <View style={{ paddingTop: 30 }}>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "flex-end",
                        marginBottom: 5,
                      }}
                    >
                      <ApText
                        style={{
                          fontSize: 15,
                          color: "#F37021",
                          fontWeight: "bold",
                          marginBottom: 5,
                        }}
                        translateKey="DepositAddress"
                      />

                      {showCopied ? (
                        <View
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            backgroundColor: "#FCF8F6",
                            paddingHorizontal: 8,
                            paddingVertical: 2,
                            borderRadius: 10,
                          }}
                        >
                          <svg.CheckSvg strokeColor="#F37021" />
                          <ApText
                            style={{
                              fontSize: 12,
                              color: "#F37021",
                              marginLeft: 8,
                            }}
                          >
                            Copied
                          </ApText>
                        </View>
                      ) : (
                        <TouchableOpacity
                          style={{ flexDirection: "row", alignItems: "center" }}
                          onPress={() =>
                            Clipboard.setStringAsync(
                              depositAddress.deposit_address as string
                            ).then((rs) => {
                              setShowCopied(true);
                              setTimeout(() => {
                                setShowCopied(false);
                              }, 1000);
                            })
                          }
                        >
                          <svg.CopySvg />
                          <ApText style={{ fontSize: 12, marginLeft: 10 }}>
                            Copy
                          </ApText>
                        </TouchableOpacity>
                      )}
                    </View>

                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <ApText
                        style={{
                          fontSize: 14,
                          color: "#ccc",
                          fontWeight: "500",
                          alignSelf: "center",
                          width: "100%",
                        }}
                        numberOfLines={2}
                      >
                        {depositAddress.deposit_address
                          ? depositAddress.deposit_address
                          : "Not Available"}
                      </ApText>
                      {/* <TouchableOpacity
                        onPress={() =>
                          Clipboard.setStringAsync(
                            depositAddress.deposit_address as string
                          )
                        }
                        style={{
                          borderRadius: 4,
                          paddingVertical: 6,
                          paddingHorizontal: 20,
                          backgroundColor: "#F37021",
                        }}
                      >
                        <ApText
                          style={{ color: "#fff", textAlign: "center" }}
                          translateKey="Copy"
                        />
                      </TouchableOpacity> */}
                    </View>
                    <View style={{ paddingTop: 35 }}>
                      <ApText
                        style={{
                          fontSize: 15,
                          color: "#ee0d29",
                          fontWeight: "bold",
                          marginBottom: 10,
                        }}
                        translateKey="Reminders"
                      />
                      <View
                        style={{
                          backgroundColor: "#ffd9cfad",
                          padding: 12,
                          borderRadius: 10,
                          marginBottom: 5,
                          flexDirection: "row",
                        }}
                      >
                        <ApText
                          style={{
                            color: "#ee0d29",
                            fontWeight: "bold",
                            width: "85%",
                            fontSize: 13,
                          }}
                          // translateKey="DepositPageNote"
                          translateKey={params?.type == "usdt" ? "DepositPageNote" : "DepositDDCPageNote"}
                        />

                        <Image
                          source={require("../../assets/icons/account-hint.png")}
                          style={{ width: 86, height: 57 }}
                        />
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </KeyboardAwareScrollView>
      </ImageBackground>
    );
  };

  return (
    <ApSafeAreaView>
      {renderHeader()}
      {loading && <ApLoader />}
      {!loading && renderContent()}
    </ApSafeAreaView>
  );
};

export default Deposit;
