import { useNavigation } from "@react-navigation/native";
import { useField } from "formik";
import React, { useEffect } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { theme } from "../../../constants";
import { useStrategyState } from "../../../context";
import { svg } from "../../../svg";
import { ApText } from "../typography";

interface IProps {
  name: string;
}

export const ApSymbolSelection: React.FC<IProps> = ({ name }) => {
  const navigation = useNavigation();
  const { symbols, setSymbols } = useStrategyState();
  const [field, meta, { setValue }] = useField(name);

  useEffect(() => {
    setValue(symbols);
  }, [symbols]);

  const handleDelete = (s: any) => {
    setSymbols(symbols?.filter((x) => x.key !== s.key));
  };

  return (
    <View
      style={{
        borderBottomWidth: 1,
        paddingBottom: 12,
        borderBottomColor: "#ced6e182",
      }}
    >
      {meta.touched && meta.error && (
        <ApText
          size="xs"
          style={{
            textAlign: "left",
            color: "red",
            marginTop: 10,
          }}
        >
          {meta.error}
        </ApText>
      )}
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <ApText
          translateKey="Symbol"
          style={{
            ...theme.FONTS.H6,
            color: theme.COLORS.mainDark,
          }}
        >
          Symbol
        </ApText>

        <TouchableOpacity
          onPress={() => navigation.navigate("TokenList" as never)}
          style={{
            backgroundColor: "#F37021",
            paddingVertical: 3,
            width: 80,
            borderRadius: 4,
          }}
        >
          <ApText
            translateKey="Select"
            style={{ textAlign: "center", fontSize: 12, color: "white" }}
          >
            Select
          </ApText>
        </TouchableOpacity>
      </View>

      <View
        style={{
          paddingTop: 7,
          flexDirection: "row",
          flex: 1,
          flexWrap: "wrap",
        }}
      >
        {symbols?.map((s: any) => (
          <View
            key={s.key}
            style={{
              marginTop: 10,
              backgroundColor: "#05B491",
              width: "fit-content",
              paddingHorizontal: 10,
              paddingVertical: 2,
              borderRadius: 5,
              flexDirection: "row",
              alignItems: "center",
              marginRight: 7,
            }}
          >
            <Text style={{ color: "white", fontSize: 10 }}>{s.value}</Text>
            <TouchableOpacity
              style={{ paddingLeft: 5 }}
              onPress={() => handleDelete(s)}
            >
              <svg.CloseWhiteSvg />
            </TouchableOpacity>
          </View>
        ))}
      </View>
    </View>
  );
};
