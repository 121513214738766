import React from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";

interface IProps {
  children: React.ReactNode;
  style?: StyleProp<ViewStyle>;
  direction?: "row" | "column";
  justify?:
    | "flex-start"
    | "flex-end"
    | "center"
    | "space-between"
    | "space-around"
    | "space-evenly"
    | undefined;
}

export const ApRow: React.FC<IProps> = ({
  children,
  direction = "row",
  justify="space-between",
  style
}) => {
  return (
    <View
      style={[
        styles.container,
        { flexDirection: direction, justifyContent: justify },
        style
      ]}
    >
      {children}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    // width: "100%",
    alignItems: "center",
  },
});
