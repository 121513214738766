import React from "react";
import { View, TouchableOpacity, Image, StyleSheet } from "react-native";
import { ApText } from "../../../../components/v1/typography";
import { useTradingContext } from "../../../../context";
import { ApRow } from "../../../../components/v1";

export const PolicyOperations = ({
  bex_id,
  setShowTermModal,
  setShowNextEntryModal,
}) => {
  const {
    updateBotExecutorStatus,
    updateBotExecutorReentryStatus,
    botExecutorStrategySummary,
  } = useTradingContext();

  return (
    <View style={{ backgroundColor: "#FFF8F4", padding: 15 }}>
      <ApText
        style={{
          color: "#F37021",
          fontSize: 16,
          fontWeight: "500",
          paddingBottom: 15,
        }}
        translateKey="PolicyOperations"
      />

      <ApRow style={{ flexWrap: "wrap" }} justify="flex-start">
        <TouchableOpacity
          style={styles?.item}
          onPress={() => {
            updateBotExecutorStatus({ bex_ids: bex_id, status: "1" });
          }}
        >
          <View style={{ justifyContent: "center", alignItems: "center" }}>
            <Image
              source={require("../../../../../assets/icons/Running.png")}
              style={{ width: 40, height: 40 }}
            />
            <ApText
              style={{ fontSize: 11, paddingTop: 1 }}
              translateKey="Run"
            />
          </View>
        </TouchableOpacity>
        <TouchableOpacity
          style={styles?.item}
          onPress={() => {
            updateBotExecutorStatus({ bex_ids: bex_id, status: "0" });
          }}
        >
          <View style={{ alignItems: "center" }}>
            <Image
              source={require("../../../../../assets/icons/Paused.png")}
              style={{ width: 40, height: 40 }}
            />
            <ApText
              style={{ fontSize: 11, paddingTop: 1 }}
              translateKey="Pause"
            />
          </View>
        </TouchableOpacity>
        {botExecutorStrategySummary?.plan_type !== "1" && (
          <TouchableOpacity
            style={styles?.item}
            onPress={() => {
              updateBotExecutorReentryStatus({
                bex_ids: bex_id,
                is_reentry: "1",
              });
            }}
          >
            <View style={{ alignItems: "center" }}>
              <Image
                source={require("../../../../../assets/icons/TurnOn.png")}
                style={{ width: 40, height: 40 }}
              />
              <ApText
                style={{ fontSize: 11, paddingTop: 1 }}
                translateKey="TurnOn"
              />
            </View>
          </TouchableOpacity>
        )}
        {botExecutorStrategySummary?.plan_type !== "1" && (
          <TouchableOpacity
            style={styles?.item}
            onPress={() => {
              updateBotExecutorReentryStatus({
                bex_ids: bex_id,
                is_reentry: "0",
              });
            }}
          >
            <View style={{ alignItems: "center" }}>
              <Image
                source={require("../../../../../assets/icons/TurnOff.png")}
                style={{ width: 40, height: 40 }}
              />
              <ApText
                style={{ fontSize: 11, paddingTop: 1 }}
                translateKey="TurnOff"
              />
            </View>
          </TouchableOpacity>
        )}
        {botExecutorStrategySummary?.plan_type !== "1" && (
          <TouchableOpacity
            style={styles?.item}
            onPress={() => {
              setShowNextEntryModal(true);
            }}
          >
            <View style={{ alignItems: "center" }}>
              <Image
                source={require("../../../../../assets/icons/entry.png")}
                style={{ width: 40, height: 40 }}
              />
              <ApText
                style={{ fontSize: 11, paddingTop: 1 }}
                translateKey="EntryNow"
              />
            </View>
          </TouchableOpacity>
        )}
        <TouchableOpacity
          style={styles?.item}
          onPress={() => {
            setShowTermModal(true);
          }}
        >
          <View style={{ alignItems: "center" }}>
            <Image
              source={require("../../../../../assets/icons/Terminate.png")}
              style={{ width: 40, height: 40 }}
            />
            <ApText
              style={{ fontSize: 11, paddingTop: 1 }}
              translateKey="Terminate"
            />
          </View>
        </TouchableOpacity>
      </ApRow>
    </View>
  );
};

const styles = StyleSheet.create({
  item: {
    width: "25%",
    marginTop: 10,
    // alignItems:"flex-start"
  },
});
