import { FormikProps } from "formik";
import React, { useEffect, useState } from "react";
import { useAuthState } from "../../../context/auth";
import { ApButton } from "../button";
import { ApText } from "../typography";
import { ApTextInput } from "./text";
import { ApErrorToast } from "../../../services";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { LANGUAGES } from "../../../constants/master";

interface IProps {
  name: string;
  label?: string;
  type: string;
  propKey: string;
  props: FormikProps<any>;
}

export const ApTacInput: React.FC<IProps> = ({
  name,
  propKey,
  props,
  type,
}) => {
  const { generateTac } = useAuthState();
  const [timeLeft, setTimeLeft] = useState(null);

  const handleSend = () => {
    const account_name = props.values[propKey];

    if (!account_name) {
      ApErrorToast(``, "Account email is required");
      return;
    }

    AsyncStorage.getItem("language").then(rs => {
      if(rs === null)
      {
        generateTac({
          account_name,
          lang: "english",
          type,
        }).then((res: any) => {setTimeLeft(60)});
      }
      else{ 
        generateTac({
          account_name,
          lang: LANGUAGES.filter(item => item.id === rs)[0].languageCode,
          type,
        }).then((res: any) => {setTimeLeft(60)});
      }
    })
  };

  useEffect(() => {
    if(timeLeft===0){
       setTimeLeft(null)
    }
    if (!timeLeft) return;
    const intervalId = setInterval(() => {

      setTimeLeft(timeLeft - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timeLeft]);


  return (
    <ApTextInput
      label="Verification Code"
      labelTranslateKey="VerificationCode"
      placeHolderTranslateKey="PlaceholderEnterVerificationCode"
      placeholder="112567"
      name={name}
      containerStyle={{
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
      }}
      inputStyle={{
        paddingLeft: 7,
        paddingRight: 7,
        width: "100%",
      }}
      rightIcon={
        timeLeft ? (
          <>
            <ApText
                style={{
                  color: "grey",
                  alignSelf: "center",
                  fontSize: 12,
                  padding: 3,
                  paddingRight: 8
                }}
              >
                Wait for {timeLeft} seconds
            </ApText>
          </>
        ) : (
          <>
          {!props.dirty || Object.keys(props?.errors).includes(propKey) ? (
            <ApText
              style={{
                color: "grey",
                alignSelf: "center",
                fontSize: 12,
                padding: 3,
                paddingRight: 10
              }}
              translateKey="SendCode"
            >
              Send Code
            </ApText>
          ) : (
            <ApButton
              type="light"
              label="Send Code"
              labelTranslateKey="SendCode"
              round="md"
              disabled={Object.keys(props?.errors).includes(propKey)}
              style={{
                backgroundColor: "rgb(252, 248, 246)",
              }}
              textStyle={{ color: "#F37021", fontSize: 12, padding: 3 }}
              onPress={handleSend}
            />
          )}
        </>
        )
      }
    />
  );
};
