import React, { useEffect, useState } from "react";
import {
  StyleProp,
  Text,
  TextInput,
  TextStyle,
  View,
  ViewStyle,
} from "react-native";
import { theme } from "../../../constants";
import { FormikProps, useField } from "formik";
import { ApText } from "../typography";
import { useTranslation } from "react-i18next";
import { ApButton } from "../button";

type Props = {
  hintTranslateKey?: string
  hintTranslateKeys?: string[]
  onMaxClick?: any;
  max?: boolean;
  maxAmount?: string | number;
  innerText?: string | number;
  border?: boolean;
  width?: string | number;
  height?: string | number;
  textArea?: boolean;
  disabled?: boolean;
  defaultValue?: any;
  value?: any;
  onChange?: any;
  name: string;
  label?: string;
  suffix?: string;
  placeholder?: string;
  secureTextEntry?: boolean;
  icon?: any;
  keyboardType?: "default" | "email-address" | "numeric" | "phone-pad";
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  showSoftInputOnFocus?: boolean;
  containerStyle?: StyleProp<ViewStyle>;
  inputStyle?: StyleProp<TextStyle>;
  labelStyle?: TextStyle;
  labelIcon?: React.ReactNode;
  labelTranslateKey?: string;
  placeHolderTranslateKey?: string;
  onFocus?: any;
  onBlur?: any;
};

export const ApTextInput: React.FC<Props> = ({
  max,
  maxAmount,
  innerText,
  onMaxClick,
  border,
  width,
  height,
  textArea,
  disabled,
  defaultValue,
  onChange,
  name,
  label,
  value,
  suffix,
  placeholder,
  containerStyle,
  secureTextEntry,
  keyboardType,
  leftIcon,
  rightIcon,
  inputStyle,
  labelStyle,
  labelIcon,
  labelTranslateKey,
  placeHolderTranslateKey,
  showSoftInputOnFocus,
  onFocus,
  onBlur,
  hintTranslateKey,
  hintTranslateKeys
}) => {
  const { t } = useTranslation();
  const [isFocused, setIsFocused] = useState(0);
  const [field, meta, { setValue }] = useField(name);

  const handleMax = () => {
    setValue(maxAmount);
    // onMaxClick && onMaxClick(maxAmount)
  };

  return (
    <>
      <View 
        style={[
          {
            borderBottomWidth: border ? 1 : 0,
            paddingVertical: 15,
            paddingBottom: (hintTranslateKey || hintTranslateKeys) ? 10 : 15,
            borderBottomColor: "#ced6e182",
            width: "100%",
          },
          containerStyle,
        ]}
      >
        <View
          style={[
            {
              width: "100%",
              flexDirection: border ? "row" : "column",
              alignItems: border ? "center" : "flex-start",
              justifyContent: border ? "space-between" : "flex-start",
            },
          ]}
        >
          {border ? (
            <View
              style={[
                { flexDirection: "row", alignItems: "center", width: "auto" },
                labelStyle,
              ]}
            >
              <ApText
                size="sm"
                style={labelStyle}
                translateKey={labelTranslateKey}
              >
                {label}
              </ApText>
              {labelIcon && <View style={{ marginLeft: 8 }}>{labelIcon}</View>}
            </View>
          ) : (
            <View style={{ width: "100%", marginBottom: 10 }}>
              <View
                style={{
                  width: "100%",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <ApText
                  size="sm"
                  style={labelStyle}
                  translateKey={labelTranslateKey}
                >
                  {label}
                </ApText>
                {meta.touched && meta.error && (
                  <ApText
                    size="xs"
                    style={{
                      color: "red",
                    }}
                  >
                    {t(`FormErrors.${meta.error}`)}
                  </ApText>
                )}
              </View>
            </View>
          )}

          <View
            style={[
              {
                backgroundColor: "#FCF8F6",
                width: width ? width : "100%",
                height: height ? height : 45,
                borderRadius: 5,
                //borderWidth: 1,
                //borderColor: '#eee',
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              },
            ]}
          >
            {leftIcon && leftIcon}
            <TextInput
              autoComplete="off"
              editable={!disabled}
              selectTextOnFocus={!disabled}
              placeholder={
                placeHolderTranslateKey ? t(placeHolderTranslateKey) : placeholder
              }
              style={[
                { outline: "none" } as any,
                {
                  paddingLeft: border ? 0 : 7,
                  paddingRight: border ? 0 : 7,
                  color: "#F37021",
                  flex: 1,
                  height: "100%",
                  width: "100%",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  fontSize: 14,
                  lineHeight: 16 * 1,
                  ...theme.FONTS.Mulish_400Regular,
                },
                inputStyle,
              ]}
              secureTextEntry={secureTextEntry}
              placeholderTextColor={"#c6c6c6"}
              keyboardType={keyboardType}
              multiline={textArea}
              numberOfLines={textArea ? 5 : 1}
              onFocus={onFocus}
              onBlur={onBlur}
              onChangeText={(e) => {
                setValue(suffix ? e?.replace(suffix, "") : e);
                onChange && onChange(e);
              }}
              value={field.value ? field.value : ""}
              // value={`${field.value}${suffix || ""}`}
              defaultValue={defaultValue}
              showSoftInputOnFocus={showSoftInputOnFocus}
            />

            {rightIcon && rightIcon}
            {max ? (
              <ApButton
                type="light"
                label="Max"
                round="md"
                labelTranslateKey="Max"
                style={{
                  backgroundColor: "rgb(252, 248, 246)",
                }}
                textStyle={{ color: "#F37021", fontSize: 12, padding: 3 }}
                onPress={handleMax}
              />
            ) : (
              <></>
            )}
          </View>
        </View>

        {hintTranslateKey && (
          <ApText
            translateKey={hintTranslateKey}
            style={{ fontSize: 11, color: "#F37021" }}
          />
        )}

        {hintTranslateKeys && (
          <View style={{}}>
            {hintTranslateKeys.map((key, i) => (
              <ApText
                key={i}
                translateKey={key}
                style={{ fontSize: 11, color: "#F37021"}}
              />
            ))}
          </View>
        )}
      </View>
      {/* {!noBorder && meta.touched && meta.error && (
        <Text
          style={{
            fontSize: 14,
            color: "red",
            marginBottom: 14,
            marginTop: 14,
          }}
        >
          {meta.error}
        </Text>
      )} */}
    </>
  );
};
