import * as React from "react";
import Svg, { G, Path, Circle } from "react-native-svg";

function AccountSvg(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width={28} height={24} viewBox="0 0 28 24" {...props}>
      <G data-name="Account Icon" transform="translate(-44 -210)">
        <Path
          d="M24.786 12.245V8.634L13.393 2.06 2 8.634v3.612h2.279v9.114H2v2.279h22.786v-2.28h-2.279v-9.114zM4.279 9.967L13.393 4.7l9.114 5.263zm2.278 11.392v-9.114h2.279v9.114zm4.557 0v-9.114h4.557v9.114zm9.114 0H17.95v-9.114h2.279z"
          transform="translate(42 207.94)"
          fill="#7a8390"
        />
        <Circle data-name="Ellipse 15" cx={7.034} cy={7.034} r={7.034} transform="translate(57.932 219.932)" fill="#F37021" />
        <G data-name="Group 33">
          <G data-name="Group 32">
            <Path
              data-name="Path 36"
              d="M159.732 157.587v2.145h-2.145a.687.687 0 000 1.374h2.145v2.145a.685.685 0 101.37 0v-2.145h2.145a.687.687 0 000-1.374H161.1v-2.145a.685.685 0 10-1.37 0z"
              transform="translate(61.449 223.447) translate(-156.9 -156.9)"
              fill="#fff"
            />
          </G>
        </G>
      </G>
    </Svg>
  );
}

export default AccountSvg;
