import React from "react";
import { Image, ScrollView, View } from "react-native";

import { components } from "../components";
import { ApSafeAreaView } from "../components/v1";
import { ApText } from "../components/v1/typography";
import { theme } from "../constants";

const PrivacyPolicy: React.FC = () => {
  const renderHeader = () => {
    return (
      <View style={{ height: 179 }}>
        <ApSafeAreaView>
          <components.Header
            goBack={true}
            containerStyle={{ marginBottom: 20 }}
            titleTranlateKey="PrivacyPolicy"
            title="Privacy Policy"
          />
          <View style={{ paddingHorizontal: 20 }}>
            <ApText
              style={{
                ...theme.FONTS.H2,
                color: theme.COLORS.mainDark,
              }}
              translateKey="PrivacyPolicy"
            />
          </View>
        </ApSafeAreaView>
        <Image
          source={require("../../assets/bg-01.png")}
          style={{
            height: 350,
            width: theme.SIZES.width,
            position: "absolute",
            zIndex: -1,
          }}
        />
      </View>
    );
  };

  const renderContent = () => {
    return (
      <ScrollView
        contentContainerStyle={{
          flexGrow: 1,
          paddingHorizontal: 20,
        }}
      >
        <ApText
          style={{
            ...theme.FONTS.H4,
            color: theme.COLORS.mainDark,
            marginBottom: 14,
          }}
          translateKey="Terms"
        />
        <ApText
          style={{
            ...theme.FONTS.Mulish_400Regular,
            fontSize: 16,
            lineHeight: 16 * 1.6,
            color: theme.COLORS.bodyTextColor,
            marginBottom: 30,
          }}
          translateKey="TermsAndCondition"
        />

        <ApText
          style={{
            ...theme.FONTS.H4,
            color: theme.COLORS.mainDark,
            marginBottom: 14,
          }}
          translateKey="UseLicence"
        />
        <ApText
          style={{
            ...theme.FONTS.Mulish_400Regular,
            fontSize: 16,
            lineHeight: 16 * 1.6,
            color: theme.COLORS.bodyTextColor,
            marginBottom: 20,
          }}
          translateKey="Copyright"
        />
      </ScrollView>
    );
  };

  return (
    <View style={{ flex: 1, backgroundColor: theme.COLORS.bgColor }}>
      {renderHeader()}
      {renderContent()}
    </View>
  );
};

export default PrivacyPolicy;
