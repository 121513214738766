import React from "react";
import { ScrollView, TouchableOpacity } from "react-native";

import { components } from "../components";
import { ApSafeAreaView } from "../components/v1";
import { ApText } from "../components/v1/typography";
import { theme } from "../constants";
import { svg } from "../svg";

const NewPassword: React.FC = ({ navigation }: any) => {
  const renderHeader = () => {
    return <components.Header title="New password" goBack={true} />;
  };

  const renderContent = () => {
    return (
      <ScrollView
        contentContainerStyle={{
          flexGrow: 1,
          paddingHorizontal: 20,
        }}
        showsVerticalScrollIndicator={false}
      >
        <ApText
          style={{
            ...theme.FONTS.Mulish_400Regular,
            fontSize: 16,
            color: theme.COLORS.bodyTextColor,
            lineHeight: 16 * 1.7,
            marginBottom: 20,
            marginTop: 30,
          }}
        >
          Enter new password and confirm.
        </ApText>
        <components.InputField
          placeholder="••••••••"
          containerStyle={{ marginBottom: 10 }}
          icon={
            <TouchableOpacity>
              <svg.EyeOffSvg />
            </TouchableOpacity>
          }
        />
        <components.InputField
          placeholder="••••••••"
          containerStyle={{ marginBottom: 14 }}
          icon={
            <TouchableOpacity>
              <svg.EyeOffSvg />
            </TouchableOpacity>
          }
        />
        <components.Button
          title="Change Password"
          onPress={() => navigation.navigate("ForgotPasswordSentEmail")}
        />
      </ScrollView>
    );
  };

  return (
    <ApSafeAreaView>
      {renderHeader()}
      {renderContent()}
    </ApSafeAreaView>
  );
};

export default NewPassword;
