import { BN_PRICE_LIST_API, PRICE_LIST_API } from "../constants/master";
import { IPriceListParams } from "../models";

export class MarketService {
  public static async getBnPriceList(): Promise<any> {
    try {
      const les = await fetch(BN_PRICE_LIST_API);
      const data = await les.json();
      const getPriceJSON = data;
      return (
        getPriceJSON
          .filter(
            (price: any) => price.symbol.endsWith("USDT") && price.lastPrice > 0 
            && price.symbol != "USDCUSDT" && price.symbol != "BUSDUSDT" && price.symbol != "TUSDUSDT"
          )
          .sort((a, b) => {
            const valueTradedA =
            +parseFloat(a.volume).toFixed(0) * parseFloat(a.lastPrice);
            const valueTradedB =
              +parseFloat(b.volume).toFixed(0) * parseFloat(b.lastPrice);
              
            return  valueTradedB - valueTradedA
            // parseFloat(b.volume) - parseFloat(a.volume)
          })
          
          // .filter((e: any, index: number) => index < 10)
          // .filter((e: any, index: number) => symbols.includes(e.symbol))

          // .sort(function compare(a: any, b: any) {
            // const valueTradedA =
            //   +parseFloat(a.volume).toFixed(0) * parseFloat(a.lastPrice);
            // const valueTradedB =
            //   +parseFloat(b.volume).toFixed(0) * parseFloat(b.lastPrice);
          //   if (valueTradedA < valueTradedB) {
          //     return -1;
          //   }
          //   if (valueTradedA > valueTradedB) {
          //     return 1;
          //   }
          //   return 0;
          // })
      );
    } catch (error) {}
  }
  public static async getBddPriceList(params: IPriceListParams): Promise<any> {
    try {
      const les = await fetch(`${PRICE_LIST_API}?platform=${params.platform}&market=${params.market}`);
      const data = await les.json();

      const rs =  Object.entries(data)
        .map((k, v) => ({ symbol: k[0], lastPrice: k[1] }))
        .filter(
          (price: any) => price.symbol.endsWith("USDT") && price.lastPrice > 0
        );

        return rs;
    } catch (error) {}
  }
}
