import moment from "moment";
import React, { useEffect, useState } from "react";
import { Image, ScrollView, TouchableOpacity, View } from "react-native";
import { RefreshControl } from "react-native-web-refresh-control";
import { components } from "../components";
import {
  ApButton,
  ApConfirmModal,
  ApLoader,
  ApRow,
  ApSafeAreaView,
} from "../components/v1";
import { ApText } from "../components/v1/typography";
import { theme } from "../constants";
import { useProfileState } from "../context";
import { useApiBindingState } from "../context/apiBinding";
import { svg } from "../svg";

const platforms = [
  {
    id: "1",
    title: "Binance",
    type: "bn",
    icon: require("../../assets/icons/09.png"),
  },
  {
    id: "2",
    title: "OKX",
    type: "okx",
    icon: require("../../assets/icons/okx.png"),
  },
  {
    id: "3",
    title: "TriiiX",
    type: "triiix",
    icon: require("../../assets/icons/triiix.png"),
  },
];

const otherPlatforms = [
  {
    id: "1",
    title: "FXTRADING",
    icon: require("../../assets/icons/fxtrading.png"),
    link: "fxtrading",
  },
  {
    id: "2",
    title: "TriiiX",
    icon: require("../../assets/icons/triiix.png"),
    link: "triiix",
  },
];

const API: React.FC = ({ navigation }: any) => {
  const [refreshing, setRefreshing] = useState(false);
  const { loading, profileData, getProfileData } = useProfileState();
  const { unbindApi } = useApiBindingState();
  const [confirmModal, setConfirmModal] = useState<{
    show: boolean;
    data?: any;
  }>({ show: false });
  useEffect(() => {
    getProfileData();
  }, []);

  const handleDelete = (type: any) => {
    unbindApi(type).then(() => {
      getProfileData();
      setConfirmModal({ show: false });
    });
  };

  const handleRefresh = () => {
    setRefreshing(true);
    getProfileData().finally(() => {
      setRefreshing(false);
    });
  };

  const renderHeader = () => {
    return (
      <components.Header title="API" titleTranlateKey="API" goBack={true} />
    );
  };

  const renderContent = () => {
    return (
      <ScrollView
        contentContainerStyle={{
          flexGrow: 1,
          paddingHorizontal: 20,
        }}
        refreshControl={
          <RefreshControl refreshing={loading} onRefresh={handleRefresh} />
        }
      >
        <View
          style={{
            backgroundColor: "#FFD9CF",
            padding: 12,
            borderRadius: 10,
            marginTop: 15,
            marginBottom: 20,
            flexDirection: "row",
          }}
        >
          <ApText
            style={{ color: "#F37021", width: "80%", fontSize: 13 }}
            translateKey="APIPageNote2"
          />

          <Image
            source={require("../../assets/icons/account-hint.png")}
            style={{ width: 86, height: 57 }}
          />
        </View>

        <>
          {!loading && platforms.length === 0 ? (
            <View
              style={{
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <svg.AccountSvg style={{ width: "50%", height: "50%" }} />
              <ApText
                style={{
                  marginVertical: "4px",
                  textAlign: "center",
                }}
                translateKey="AccountAPIEmpty"
              />
            </View>
          ) : (
            <>
              <View
                style={{
                  width: "100%",
                  backgroundColor: "#fff5f0ba",
                  borderRadius: 10,
                  flexDirection: "row",
                  alignItems: "center",
                  padding: 20,
                  paddingVertical: 20,
                  marginBottom: 20,
                  justifyContent: "space-between",
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Image
                    source={otherPlatforms[0].icon}
                    style={{
                      borderRadius: 50,
                      width: 40,
                      height: 40,
                      marginRight: 14,
                    }}
                  />
                  <ApText
                    style={{
                      color: theme.COLORS.mainDark,
                      fontWeight: "500",
                    }}
                    size="sm"
                  >
                    {otherPlatforms[0].title}
                  </ApText>
                </View>
                <View>
                  <ApButton
                    label="FXTradingAccounts"
                    type="success"
                    labelTranslateKey="FXTradingAccounts"
                    round="md"
                    textStyle={{ fontSize: 14, color: "#05B491" }}
                    style={{
                      backgroundColor: "transparent",
                      borderColor: "#05B491",
                      borderWidth: 1,
                      borderRadius: 6,
                      height: 30,
                      margin: 0,
                      paddingHorizontal: 15,
                    }}
                    onPress={() => {
                      navigation.navigate("FXTradingAccounts");
                    }}
                  />
                  {/* <TouchableOpacity
                  disabled
                  onPress={() => {
                    //Linking.openURL(plt);
                  }}
                  style={{
                    borderRadius: 6,
                    paddingVertical: 3,
                    paddingHorizontal: 2,
                    backgroundColor: "#ddd",
                  }}
                >
                  <ApText
                    style={{
                      color: "#333",
                      textAlign: "center",
                      fontSize: 13,
                    }}
                    translateKey="Unavailable"
                  />
                </TouchableOpacity> */}
                </View>
              </View>

              {platforms?.map((item, index) => {
                return (
                  <>
                    <View
                      key={index}
                      style={{
                        width: "100%",
                        backgroundColor: "#fff5f0ba",
                        borderRadius: 10,
                        padding: 20,
                        paddingVertical: 20,
                        // profileData[`${item.type}_api_key`] !== "" &&
                        //   profileData[`${item.type}_expiry`] === null ? 20 : 15
                        marginBottom: 20,
                      }}
                    >
                      <View
                        style={{
                          alignItems: "center",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            width: "70%",
                          }}
                        >
                          <Image
                            source={item.icon}
                            style={{
                              borderRadius: 50,
                              width: 40,
                              height: 40,
                              marginRight: 14,
                            }}
                          />
                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <ApText
                              style={{
                                color: theme.COLORS.mainDark,
                                fontWeight: "500",
                                marginRight: 20,
                              }}
                            >
                              {item.title}
                            </ApText>

                            {profileData[`${item.type}_api_key`] !== "" ? (
                              <View
                                style={{
                                  backgroundColor: theme.COLORS.green,
                                  borderRadius: 3,
                                  padding: 2,
                                  paddingLeft: 10,
                                  paddingRight: 10,
                                }}
                              >
                                <ApText
                                  size="xs"
                                  color={theme.COLORS.white}
                                  translateKey="Bound"
                                />
                              </View>
                            ) : (
                              <ApText
                                size="xs"
                                color={theme.COLORS.primary}
                                translateKey="Unbound"
                              />
                            )}
                          </View>
                        </View>
                        {profileData[`${item.type}_api_key`] !== "" ? (
                          <TouchableOpacity
                            style={{ width: "15%", alignItems: "center" }}
                            onPress={() =>
                              setConfirmModal({
                                show: true,
                                data: item.type,
                              })
                            }
                          >
                            <View
                              style={{
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <svg.RycleBinSvg />
                            </View>
                          </TouchableOpacity>
                        ) : (
                          <ApButton
                            label="Connect"
                            type="success"
                            labelTranslateKey="Connect"
                            round="md"
                            textStyle={{ fontSize: 14, color: "#05B491" }}
                            style={{
                              backgroundColor: "transparent",
                              borderColor: "#05B491",
                              borderWidth: 1,
                              borderRadius: 6,
                              height: 30,
                              margin: 0,
                              paddingHorizontal: 15,
                            }}
                            onPress={() => {
                              navigation.navigate("UpdateAPIBinding", {
                                title: item.title,
                                type: item.type,
                              });
                            }}
                          />
                        )}
                      </View>

                      <View
                        style={{
                          marginTop:
                            profileData[`${item.type}_api_key`] !== "" &&
                            profileData[`${item.type}_expiry`] === null
                              ? 0
                              : 10,
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        {profileData[`${item.type}_api_key`] !== "" ? (
                          <View>
                            <>
                              {profileData[`${item.type}_expiry`] === null ? (
                                <></>
                              ) : (
                                <>
                                  <ApRow>
                                    <ApText
                                      size="xs"
                                      translateKey="ValidUntil"
                                    />
                                    <ApText size="xs">
                                      {" "}
                                      :{" "}
                                      {
                                        (profileData as any)[
                                          `${item.type}_expiry`
                                        ]
                                      }
                                    </ApText>
                                  </ApRow>
                                  <ApRow>
                                    <ApText
                                      size="xs"
                                      translateKey="RemainingDays"
                                    />
                                    <ApText size="xs">
                                      {" "}
                                      :{" "}
                                      {` ${
                                        (profileData as any)[
                                          `${item.type}_expiry`
                                        ] &&
                                        moment(
                                          (profileData as any)[
                                            `${item.type}_expiry`
                                          ]
                                        ).diff(new Date(), "days")
                                      } `}{" "}
                                    </ApText>

                                    <ApText size="xs" translateKey="Days" />
                                  </ApRow>
                                </>
                              )}
                            </>
                          </View>
                        ) : (
                          <ApRow justify="space-between">
                            <ApText size="xs" translateKey="BindApiMsg" />
                          </ApRow>
                        )}
                      </View>
                    </View>
                  </>
                );
              })}
            </>
          )}

          {/* <View>
            <View
              style={{
                width: "100%",
                backgroundColor: "#fff5f0ba",
                borderRadius: 10,
                flexDirection: "row",
                alignItems: "center",
                padding: 20,
                paddingVertical: 20,
                marginBottom: 20,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  width: "70%",
                }}
              >
                <Image
                  source={otherPlatforms[1].icon}
                  style={{
                    borderRadius: 50,
                    width: 40,
                    height: 40,
                    marginRight: 14,
                  }}
                />
                <ApText
                  style={{
                    color: theme.COLORS.mainDark,
                    fontWeight: "500",
                  }}
                >
                  {otherPlatforms[1].title}
                </ApText>
              </View>
              <View
                style={{
                  width: "30%",
                }}
              >
                <TouchableOpacity
                  disabled
                  onPress={() => {
                    //Linking.openURL(plt);
                  }}
                  style={{
                    borderRadius: 6,
                    paddingVertical: 3,
                    paddingHorizontal: 2,
                    backgroundColor: "#ddd",
                  }}
                >
                  <ApText
                    style={{
                      color: "#333",
                      textAlign: "center",
                      fontSize: 14,
                    }}
                    translateKey="Unavailable"
                  />
                </TouchableOpacity>
              </View>
            </View>
          </View> */}
        </>
      </ScrollView>
    );
  };

  return (
    <ApSafeAreaView style={{ flex: 1, backgroundColor: theme.COLORS.bgColor }}>
      {renderHeader()}
      {loading ? <ApLoader /> : renderContent()}
      <ApConfirmModal
        show={confirmModal.show}
        messageTranslateKey="ConfirmDeleteAPIBinding"
        message="Are you sure you want to delete this api binding ?"
        onDimiss={(val) => {
          if (val) {
            handleDelete(confirmModal.data);
          } else setConfirmModal({ show: false });
        }}
      />
    </ApSafeAreaView>
  );
};

export default API;
