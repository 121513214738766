import React from "react";
import { View, TouchableOpacity } from "react-native";
import { ApText } from "../../../../components/v1/typography";
import { theme } from "../../../../constants";
import { useTradingContext } from "../../../../context";

export const ExtraActions = ({ setShowModal, bex_id }) => {
  const {
    updateBotExecutorStatus,
    updateBotExecutorReentryStatus,
    botExecutorByID,
  } = useTradingContext();

  return (
    <View
      style={{
        paddingVertical: 20,
        width: "100%",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <TouchableOpacity
        onPress={() => setShowModal(true)}
        style={{
          width: "47%",
          height: 40,
          borderRadius: 8,
          borderWidth: 1,
          borderColor: "#F37021",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ApText
          style={{ color: "#F37021", fontWeight: "500" }}
          translateKey="Delete"
        />
      </TouchableOpacity>

      <TouchableOpacity
        onPress={() => {
          updateBotExecutorStatus({
            bex_ids: bex_id,
            status: botExecutorByID.status === "0" ? "1" : "0",
          });
        }}
        style={{
          backgroundColor: "#F37021",
          width: "47%",
          height: 40,
          borderRadius: 8,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ApText style={{ color: theme.COLORS.white, fontWeight: "500" }}>
          {botExecutorByID.status === "0" ? (
            <ApText translateKey="Resume" />
          ) : (
            <ApText translateKey="Pause" />
          )}
        </ApText>
      </TouchableOpacity>
    </View>
  );
};
