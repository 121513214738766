import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { RefreshControl } from "react-native-web-refresh-control";
import { components } from "../components";
import {
  ApEmptyState,
  ApLoader,
  ApRow,
  ApSafeAreaView,
} from "../components/v1";
import { ApText } from "../components/v1/typography";
import { useMembershipState } from "../context/membership";
import { svg } from "../svg";
import { toCurrency } from "../utils";

const PurchaseHistory: React.FC = ({ navigation }: any) => {
  const { i18n, t } = useTranslation();
  const [refreshing, setRefreshing] = useState(false);
  const { getMembership, membershipSubscriptions, loading } =
    useMembershipState();

  useEffect(() => {
    getMembership();
  }, []);

  const handleRefresh = () => {
    setRefreshing(true);
    getMembership().finally(() => {
      setRefreshing(false);
    });
  };

  const renderHeader = () => {
    return (
      <components.Header
        title={t("Purchase History")}
        titleTranlateKey="PurchaseHistory"
        goBack={true}
      />
    );
  };

  const renderContent = () => {
    return (
      <ScrollView
        style={{ flex: 1 }}
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={handleRefresh} />
        }
      >
        <View style={{ marginHorizontal: 15, marginVertical: 15 }}>
          {membershipSubscriptions.length > 0 ? (
            membershipSubscriptions.map((m, i) => (
              <View
                key={m.id}
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingHorizontal: 10,
                  borderBottomWidth: 1,
                  paddingVertical: 15,
                  borderColor: "#e4e4e499",
                  backgroundColor: "#FCF8F6",
                  borderRadius: 10,
                }}
              >
                <View style={{ flexDirection: "row" }}>
                  <View
                    style={{
                      backgroundColor: "#FB7177",
                      padding: 8,
                      borderRadius: 5,
                    }}
                  >
                    <svg.ExportSvg />
                  </View>
                  <View style={{ paddingLeft: 10 }}>
                    <ApText
                      style={{ fontWeight: "500" }}
                      translateKey="Membership"
                    />
                    <ApRow>
                      <ApText
                        style={{
                          color: "#ababab",
                          fontSize: 12,
                          lineHeight: 16,
                          paddingTop: 2,
                        }}
                      >
                        {moment(m.created_at).format("YYYY-MM-DD")}
                      </ApText>
                    </ApRow>
                  </View>
                </View>
                <View style={{ alignItems: "flex-end" }}>
                  <ApText style={{ fontWeight: "500" }}>
                    -{toCurrency(+m.payment_amt)} USDT
                  </ApText>
                  <ApText
                    style={{
                      color: "#ababab",
                      fontSize: 12,
                      lineHeight: 16,
                      paddingTop: 2,
                    }}
                  >
                    {moment(m.created_at).format("HH:MM:SS")}
                  </ApText>
                </View>
              </View>
            ))
          ) : (
            <ApEmptyState />
          )}
        </View>
      </ScrollView>
    );
  };

  return (
    <ApSafeAreaView>
      {renderHeader()}
      {loading && <ApLoader />}
      {!loading && renderContent()}
    </ApSafeAreaView>
  );
};

export default PurchaseHistory;
