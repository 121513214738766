import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Image, ScrollView, View } from "react-native";
import { RefreshControl } from "react-native-web-refresh-control";
import { components } from "../components";
import { ApButton, ApLoader, ApRow, ApSafeAreaView } from "../components/v1";
import { ApText } from "../components/v1/typography";
import { useProfileState } from "../context";
import { useMembershipState } from "../context/membership";
import { svg } from "../svg";

const PurchaseSuccess: React.FC = ({ navigation }: any) => {
  const [refreshing, setRefreshing] = useState(false);
  const { t } = useTranslation();
  const { profileData, getProfileData } = useProfileState();
  const { loading, membershipSubscriptions, getMembership } =
    useMembershipState();
  const [remainingDays, setRemainingDays] = useState<string>("-");
  useEffect(() => {
    getProfileData();
    getMembership();
  }, []);

  useEffect(() => {
    const remain = profileData?.subscription_expiry
      ? moment(profileData?.subscription_expiry).diff(moment(), "day")
      : -1;
    setRemainingDays(+remain < 0 ? "-" : `${remain} ${t(" Days")}`);
  }, [profileData]);

  const handleRefresh = () => {
    setRefreshing(true);
    getMembership().finally(() => {
      setRefreshing(false);
    });
  };

  const renderHeader = () => {
    return (
      <components.Header
        title="Membership Purchase"
        goBack={true}
        purchaseHistory={true}
        titleTranlateKey="AccountActivation"
      />
    );
  };

  const RenderMemberShip = () => (
    <View
      style={{
        borderRadius: 12,

        backgroundColor: "#FCF8F6",
        marginTop: 15,
      }}
    >
      <Image
        source={require("../../assets/purchase-bg.png")}
        style={{
          // opacity: "0.6",
          width: 115,
          height: 104,
          position: "absolute",
          bottom: 0,
          right: 0,
        }}
      />
      <View style={{ padding: 15 }}>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            paddingVertical: 5,
            marginBottom: 10,
          }}
        >
          <ApText
            style={{ color: "#F37021", fontWeight: "bold", fontSize: 16 }}
            translateKey="MembershipPackage"
          />
          {profileData?.subscription_expiry &&
          moment(profileData?.subscription_expiry).isAfter(new Date()) ? (
            <ApText style={{ color: "#05B491" }} translateKey="Active" />
          ) : (
            <ApText style={{ color: "red" }} translateKey="Inactive" />
          )}
        </View>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            paddingVertical: 3,
          }}
        >
          <ApRow>
            <ApText style={{ fontWeight: "500" }} translateKey="ExpiredOn" />
            <ApText>:</ApText>
            <ApText style={{ paddingLeft: 3, fontWeight: "500" }}>
              {moment(profileData?.subscription_expiry).format("YYYY-MMM-DD")}
            </ApText>
          </ApRow>
        </View>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            paddingVertical: 5,
          }}
        >
          <ApRow>
            <ApText translateKey="RemainingDay(s)" />
            <ApText>:</ApText>
            <ApText style={{ paddingLeft: 5, fontWeight: "500" }}>
              {remainingDays}{" "}
            </ApText>
          </ApRow>
        </View>
      </View>
    </View>
  );

  const RenderEmptyState = () => (
    <View style={{ alignItems: "center" }}>
      <svg.ExclamationSymbolSvg style={{ paddingBottom: 15, paddingTop: 70 }} />
      <ApText
        style={{ color: "#C2C2C2", textAlign: "center" }}
        translateKey="HaventActivateMembership"
      />
    </View>
  );

  const renderContent = () => {
    return (
      <View style={{ flex: 1 }}>
        <ScrollView
          contentContainerStyle={{ flexGrow: 1, paddingHorizontal: 20 }}
          refreshControl={
            <RefreshControl refreshing={loading} onRefresh={handleRefresh} />
          }
        >
          <View
            style={{
              backgroundColor: "#FFD9CF",
              padding: 12,
              borderRadius: 10,
              marginTop: 15,
              marginBottom: 5,
              flexDirection: "row",
            }}
          >
            <ApText
              style={{
                color: "#F37021",
                width: "80%",
                fontSize: 12,
                lineHeight: 20,
              }}
              translateKey="MembershipPageNote"
            />
            <Image
              source={require("../../assets/icons/account-hint.png")}
              style={{ width: 86, height: 57 }}
            />
          </View>

          {!!membershipSubscriptions?.length && !loading && (
            <RenderMemberShip />
          )}

          {!loading && !membershipSubscriptions?.length && <RenderEmptyState />}
        </ScrollView>

        <View
          style={{
            paddingHorizontal: 20,
            paddingVertical: 20,
            width: "100%",
            flex: 0.08,
            position: "absolute",
            bottom: 0,
            backgroundColor: "white",
          }}
        >
          <ApButton
            round="lg"
            label={membershipSubscriptions?.length ? "Renew" : "Purchase"}
            labelTranslateKey={
              membershipSubscriptions?.length ? "Renew" : "Purchase"
            }
            onPress={() => {
              navigation.navigate("MemberPurchase");
            }}
          />
          {/* <TouchableOpacity
            style={{
              backgroundColor: "#F37021",
              width: "100%",
              height: 40,
              borderRadius: 8,
              alignItems: "center",
              justifyContent: "center",
            }}
            onPress={() => {
              navigation.navigate("MemberPurchase");
            }}
          >
            <ApText style={{ color: theme.COLORS.white, fontWeight: "500" }}>
              {membershipSubscriptions?.length ? "Renew" : "Purchase"}
            </ApText>
          </TouchableOpacity> */}
        </View>
      </View>
    );
  };

  return (
    <ApSafeAreaView>
      {renderHeader()}
      {loading ? <ApLoader /> : renderContent()}
    </ApSafeAreaView>
  );
};

export default PurchaseSuccess;
