import * as React from "react";
import Svg, { Path } from "react-native-svg";

function HomeFillSvg(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width={15.764} height={20} viewBox="0 0 15.764 20" {...props}>
      <Path
        data-name="Path 14890"
        d="M1.809 20.25h12.546a1.649 1.649 0 001.609-1.609V7.06a1.32 1.32 0 00-.536-1.072L8.565.411a1.162 1.162 0 00-1.18 0L.736 5.88A1.32 1.32 0 00.2 6.952v11.689a1.649 1.649 0 001.609 1.609z"
        fill="#f37021"
        transform="translate(-.2 -.25)"
      />
      <Path data-name="Path 14891" d="M11.318 14.7H4.746A1.191 1.191 0 013.5 13.6a1.191 1.191 0 011.246-1.1h6.571a1.191 1.191 0 011.246 1.1 1.128 1.128 0 01-1.245 1.1z" transform="translate(-.2 -.25) translate(0 1.005)" fill="#fff" />
    </Svg>
  );
}

export default HomeFillSvg;
