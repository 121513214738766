import AsyncStorage from "@react-native-async-storage/async-storage";
import React, { useState } from "react";
import { IGenerateTac, ILogin, IResetMemberPassword, ISignUp } from "../models";
import { ApErrorToast, ApSuccessToast } from "../services";
import { ApiService } from "../services/ApiService";

export const OAuthProviders = "facebook" || "google";

interface IAuthState {
  loading: boolean;
  error: any;
  authenticated: boolean;
  isAuthenticated: () => Promise<boolean>;
  login: (payload: ILogin) => Promise<string>;
  resetMemberPassword: (payload: IResetMemberPassword) => Promise<string>;
  signUp: (payload: ISignUp) => Promise<string>;
  generateTac: (payload: IGenerateTac) => Promise<string>;
}

const AuthContext = React.createContext<IAuthState>({
  loading: true,
  error: "",
  authenticated: false,
  isAuthenticated() {
    return false;
  },
  login(payload: ILogin) {
    return null;
  },
  resetMemberPassword(payload: IResetMemberPassword) {
    return null;
  },
  signUp(payload: ISignUp) {
    return null;
  },
  generateTac() {
    return null;
  },
} as unknown as IAuthState);

const useAuthState = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error("app dispatch must be used within app global provider");
  }

  return context;
};

interface IProps {
  children: React.ReactNode;
}

const AuthContextProvider: React.FC<IProps> = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState();
  const [authenticated, setAuthenticated] = useState(false);

  const login = async (payload: ILogin): Promise<string> => {
    setLoading(true);
    return await ApiService.post("/auth/getToken", payload)
      .then(async (rs) => {
        await AsyncStorage.setItem("accessToken", rs?.data?.data?.jwt);
        setAuthenticated(true);
        return rs?.data?.data;
      })
      .catch(handleError)
      .finally(() => {
        setLoading(false);
      });
  };

  const isAuthenticated = async () => {
    const isAuth = (await AsyncStorage.getItem("accessToken")) !== null;
    setAuthenticated(isAuth);
    return isAuth;
  };

  const resetMemberPassword = async (
    payload: IResetMemberPassword
  ): Promise<string> => {
    setLoading(true);
    return await ApiService.post(`/auth/resetMemberPassword`, payload)
      .then((rs) => {
        ApSuccessToast(``, rs?.data?.msg);
        return rs?.data?.data?.msg;
      })
      .catch(handleError)
      .finally(() => {
        setLoading(false);
      });
  };

  const signUp = async (payload: ISignUp): Promise<string> => {
    setLoading(true);
    return await ApiService.post(`/auth/registerMember`, payload)
      .then((rs) => {
        ApSuccessToast(``, rs?.data?.msg);
        return rs?.data?.data?.msg;
      })
      .catch(handleError)
      .finally(() => {
        setLoading(false);
      });
  };

  const generateTac = (payload: IGenerateTac): Promise<string> => {
    setLoading(true);
    return ApiService.post(`/auth/generateTAC`, payload)
      .then((rs) => {
        ApSuccessToast("", rs?.data?.msg);
        return rs?.data?.msg;
      })
      .catch(handleError)
      .finally(() => {
        setLoading(false);
      });
  };

  const handleError = (err: any) => {
    ApErrorToast("", err);
    throw err;
  };

  return (
    <AuthContext.Provider
      value={{
        loading,
        error,
        login,
        signUp,
        authenticated,
        isAuthenticated,
        resetMemberPassword,
        generateTac,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContextProvider, useAuthState };
