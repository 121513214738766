import React, { useEffect, useState } from "react";
import { Image, ScrollView, TouchableOpacity, View } from "react-native";
import { RefreshControl } from "react-native-web-refresh-control";
import { components } from "../components";
import {
  ApButton,
  ApConfirmModal,
  ApEmptyState,
  ApLoader,
  ApSafeAreaView,
} from "../components/v1";
import { ApText } from "../components/v1/typography";
import { usePackageState, useProfileState } from "../context";
import { toCurrency } from "../utils";

const payments = [
  {
    id: "1",
    title: "Binance",
    icon: require("../../assets/icons/09.png"),
  },
  {
    id: "2",
    title: "Ouyi",
    icon: require("../../assets/icons/10.png"),
  },
];

const Payments: React.FC = ({ navigation }: any) => {
  const [refreshing, setRefreshing] = useState(false);
  const [showBindModal, setShowBindModal] = useState<{
    show: boolean;
    data?: any;
  }>({ show: false, data: null });
  const { getPackages, purchasePackage, packages, packageLoading, loading } =
    usePackageState();

  const { profileData, getProfileData } = useProfileState();

  useEffect(() => {
    getProfileData();
    getPackages();
  }, []);

  const handleRefresh = () => {
    setRefreshing(true);
    getPackages().finally(() => {
      setRefreshing(false);
    });
  };

  const renderHeader = () => {
    return (
      <components.Header
        title="Duobao Card"
        titleTranlateKey="DuobaoCard"
        goBack={true}
      />
    );
  };

  const renderContent = () => {
    return (
      <ScrollView
        contentContainerStyle={{
          flexGrow: 1,
          paddingHorizontal: 20,
          paddingTop: 10,
        }}
        refreshControl={
          <RefreshControl
            refreshing={packageLoading}
            onRefresh={handleRefresh}
          />
        }
      >
        <View
          style={{
            backgroundColor: "#FFD9CF",
            padding: 12,
            borderRadius: 10,
            marginTop: 15,
            marginBottom: 5,
            flexDirection: "row",
          }}
        >
          <ApText
            translateKey="DuobaoPageNote"
            style={{ color: "#F37021", width: "80%", fontSize: 13 }}
          >
            DDB package details.
          </ApText>
          <Image
            source={require("../../assets/icons/account-hint.png")}
            style={{ width: 86, height: 57 }}
          />
        </View>
        <View style={{ padding: 0 }}></View>
        {packages?.length > 0 ? (
          packages?.map((pkg, i) => (
            <View
              key={i}
              style={{
                backgroundColor: "#fff5f0ba",
                borderRadius: 10,
                padding: 10,
                marginTop: i === 0 ? 10 : 0,
                marginBottom: i + 1 === packages.length ? 0 : 20,
              }}
            >
              <View>
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    width: "85%",
                  }}
                >
                  <ApText
                    style={{
                      color: "#F37021",
                      fontWeight: "500",
                    }}
                  >
                    {profileData.preferred_lang === "simplified-chinese"
                      ? pkg.name_cn
                      : profileData.preferred_lang === "english"
                      ? pkg.name_en
                      : pkg.name_cnt}
                  </ApText>
                </View>
                <ApText style={{ fontSize: 12, lineHeight: 20, color: "#222" }}>
                  {
                    profileData.preferred_lang === "simplified-chinese"
                      ? pkg.desc_cn
                      : profileData.preferred_lang === "english"
                      ? pkg.desc_en
                      : pkg.desc_cnt
                    // pkg.desc_en !== "" ?
                    // <ApText style={{fontSize: 12, lineHeight: 20, color: "#222"}}>{pkg.desc_en}</ApText> :
                    // pkg.desc_cn !== "" ?
                    // <ApText style={{fontSize: 12, lineHeight: 20, color: "#222"}}>{pkg.desc_cn}</ApText> :
                    // pkg.desc_cnt !== "" ?
                    // <ApText style={{fontSize: 12, lineHeight: 20, color: "#222"}}>{pkg.desc_cnt}</ApText> : <></>
                  }
                </ApText>
              </View>
              <View style={{ paddingTop: 10 }}>
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "flex-end",
                    justifyContent: "space-between",
                  }}
                >
                  <View>
                    <View style={{ flexDirection: "row" }}>
                      <ApText
                        translateKey="DDCoinReleaseRound"
                        style={{ color: "#222", fontSize: 12 }}
                      />
                      <ApText style={{ color: "#222", fontSize: 12 }}>
                        {" : " + pkg.ddb_roi_count}
                      </ApText>
                    </View>

                    <View style={{ display: "flex", flexDirection: "row" }}>
                      <ApText
                        translateKey="ReleasedDDCoinPerRound"
                        style={{ color: "#222", fontSize: 12 }}
                      />
                      <ApText style={{ color: "#222", fontSize: 12 }}>
                        {" : " + toCurrency(parseFloat(pkg.ddb_roi_amt))}
                      </ApText>
                    </View>

                    <View style={{ display: "flex", flexDirection: "row" }}>
                      <ApText
                        translateKey="DLPointReleaseRound"
                        style={{ color: "#222", fontSize: 12 }}
                      />
                      <ApText style={{ color: "#222", fontSize: 12 }}>
                        {" : " + pkg.doli_roi_count}
                      </ApText>
                    </View>

                    <View style={{ display: "flex", flexDirection: "row" }}>
                      <ApText
                        translateKey="ReleasedDLPointPerRound"
                        style={{ color: "#222", fontSize: 12 }}
                      />
                      <ApText style={{ color: "#222", fontSize: 12 }}>
                        {" : " + toCurrency(parseFloat(pkg.doli_roi_amt))}
                      </ApText>
                    </View>

                    <View style={{ display: "flex", flexDirection: "row" }}>
                      <ApText
                        translateKey="RemainingAvailableDBCard"
                        style={{ color: "#222", fontSize: 12 }}
                      />
                      <ApText style={{ color: "#222", fontSize: 12 }}>
                        {" : "}
                        {`${pkg.global_bought} / ${pkg.max_global_buy}`}
                      </ApText>
                    </View>

                    <View style={{ display: "flex", flexDirection: "row" }}>
                      <ApText
                        translateKey="AllowedPurchasePerAccount"
                        style={{ color: "#222", fontSize: 12 }}
                      />
                      <ApText style={{ color: "#222", fontSize: 12 }}>
                        {" : "}
                        {`${pkg.personal_bought} / ${pkg.max_personal_buy}`}
                      </ApText>
                    </View>

                    <View style={{ display: "flex", flexDirection: "row" }}>
                      <ApText
                        translateKey="Fee"
                        style={{ color: "#222", fontSize: 12 }}
                      />
                      <ApText style={{ color: "#222", fontSize: 12 }}>
                        {" : "}
                        {`${pkg.cost} USDT`}
                      </ApText>
                    </View>
                  </View>
                  <TouchableOpacity
                    style={{
                      borderRadius: 4,
                      paddingVertical: 3,
                      paddingHorizontal: 2,
                      backgroundColor: "#05B491",
                      borderColor: "#05B491",
                      minWidth: 60,
                      borderWidth: 1,
                    }}
                    onPress={() => {
                      setShowBindModal({ show: true, data: pkg });
                    }}
                  >
                    <ApText
                      translateKey="Buy"
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        fontSize: 13,
                      }}
                    />
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          ))
        ) : (
          <ApEmptyState />
        )}
      </ScrollView>
    );
  };

  return (
    <ApSafeAreaView>
      {renderHeader()}
      {packageLoading && <ApLoader />}
      {!packageLoading && renderContent()}
      {!packageLoading && (
        <View style={{ paddingHorizontal: 20, paddingVertical: 15 }}>
          <ApButton
            style={{ marginTop: 0 }}
            round="lg"
            label="My Packages"
            labelTranslateKey="MyPackages"
            onPress={() => navigation.navigate("MyPackages")}
          />
        </View>
      )}

      <ApConfirmModal
        show={showBindModal.show}
        messageTranslateKey={"ConfirmPurchaseMsg"}
        message={"Are you sure you want to confirm purchase?"}
        onDimiss={(rs) => {
          if (rs) {
            purchasePackage(showBindModal.data?.id);
            // navigation.navigate("MyPackages");
          }
          setShowBindModal({ show: false });
        }}
      />
    </ApSafeAreaView>
  );
};

export default Payments;
