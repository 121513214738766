import * as React from "react";
import Svg, { Rect, Path, Defs, Use, Image, Polygon } from "react-native-svg";

const HomeTestSvg: React.FC = () => (
    <Svg width={30} height={50} x="0px" y="0px" viewBox="0 0 26.39 26.39">
    <Path fill="#ffffff" d="M3.588,24.297c0,0-0.024,0.59,0.553,0.59c0.718,0,6.652-0.008,6.652-0.008l0.01-5.451c0,0-0.094-0.898,0.777-0.898h2.761
			c1.031,0,0.968,0.898,0.968,0.898l-0.012,5.434c0,0,5.628,0,6.512,0c0.732,0,0.699-0.734,0.699-0.734V14.076L13.33,5.913
			l-9.742,8.164C3.588,14.077,3.588,24.297,3.588,24.297z"/>
		<Path fill="#ffffff" d="M0,13.317c0,0,0.826,1.524,2.631,0l10.781-9.121l10.107,9.064c2.088,1.506,2.871,0,2.871,0L13.412,1.504L0,13.317z"/>
        <Polygon points="23.273,4.175 20.674,4.175 20.685,7.328 23.273,9.525 		"/>
</Svg> 
);

export default HomeTestSvg;
