import * as React from "react";
import Svg, { G, Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" style={{marginLeft: -2}} width={20} height={20} viewBox="0 0 19.965 21.073" {...props}>
      <G fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}>
        <Path data-name="Path 82" d="M21.026 17.161a2.3 2.3 0 11-.676-1.626 2.3 2.3 0 01.676 1.626z" stroke="#000" transform="translate(-8.741 -6.623)" />
        <Path
          data-name="Path 83"
          d="M27.356 19.566l-1.8-1.729c.021-.223.035-.449.035-.676s-.013-.454-.035-.676l1.8-1.742a1.123 1.123 0 00.192-1.374l-1.13-1.96a1.132 1.132 0 00-1.293-.522l-2.4.7a6.493 6.493 0 00-1.169-.674l-.608-2.43a1.125 1.125 0 00-1.1-.856h-2.264a1.13 1.13 0 00-1.1.86l-.6 2.423a6.5 6.5 0 00-1.169.674l-2.405-.689a1.128 1.128 0 00-1.288.523l-1.129 1.957a1.135 1.135 0 00.2 1.382l1.8 1.73c-.021.223-.035.445-.035.672s.013.458.035.68l-1.8 1.738a1.123 1.123 0 00-.193 1.374l1.13 1.965a1.13 1.13 0 001.293.518l2.4-.693a6.747 6.747 0 001.169.672l.608 2.432a1.128 1.128 0 001.1.852h2.262a1.131 1.131 0 001.1-.856l.6-2.427a6.461 6.461 0 001.164-.674l2.41.689a1.128 1.128 0 001.288-.522l1.135-1.96a1.13 1.13 0 00-.2-1.379zm-8.634-.1a2.3 2.3 0 11.882-.175 2.3 2.3 0 01-.882.175z"
          stroke="#7a8390"
          transform="translate(-8.741 -6.623)"
        />
        <Path data-name="Path 84" d="M21.026 17.161a2.3 2.3 0 11-.676-1.626 2.3 2.3 0 01.676 1.626z" stroke="#F37021" transform="translate(-8.741 -6.623)" />
      </G>
    </Svg>
  );
}

export default SvgComponent;
