import React, { useEffect, useState } from "react";
import { TouchableOpacity, View } from "react-native";
import { components } from "../components";
import { ApSafeAreaView } from "../components/v1";
import { ApText } from "../components/v1/typography";
import ApiCall from "../utils/ApiCall";

const StrategyProfit: React.FC = ({ navigation }: any) => {
  const [selected, setSelected] = useState("CreditBalance");
  const [profileData, setProfileData] = useState({});

  useEffect(() => {
    ApiCall.post("/profile/me").then((response) => {
      setProfileData(response.data.data);
    });
  }, []);

  const renderHeader = () => {
    return <components.Header title="Strategy Profit Details" goBack={true} />;
  };

  const renderContent = () => {
    return (
      <View style={{ flex: 1 }}>
        <View style={{ backgroundColor: "#fff" }}>
          <ApText style={{ color: "#F37021", fontSize: 14, padding: 15 }}>
            2023-01-11
          </ApText>
          <View
            style={{
              padding: 15,
              backgroundColor: "#FCF8F6",
              marginHorizontal: 15,
              borderRadius: 10,
            }}
          >
            <TouchableOpacity>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingVertical: 10,
                  borderBottomWidth: 1,
                  borderColor: "#e4e4e499",
                }}
              >
                <View style={{ width: "40%" }}>
                  <ApText style={{ fontWeight: "500" }}>GMT/USDT</ApText>
                  <ApText
                    style={{
                      color: "#ababab",
                      fontSize: 12,
                      fontWeight: "500",
                      lineHeight: 16,
                    }}
                  >
                    00:00:00
                  </ApText>
                  <ApText
                    style={{
                      color: "#ababab",
                      fontSize: 12,
                      fontWeight: "500",
                      lineHeight: 16,
                    }}
                  >
                    Stable Strategy
                  </ApText>
                </View>

                <ApText
                  style={{
                    width: "50%",
                    fontWeight: "bold",
                    color: "#222",
                    alignSelf: "center",
                    fontSize: 13,
                    textAlign: "right",
                  }}
                >
                  +290.00 USDT
                </ApText>
                {/* <View style={{ width: "10%", marginLeft: "auto", alignSelf: "center", alignItems: "flex-end" }}>
                  <Image source={require("../assets/other-icons/14.png")} style={{ width: 6, height: 9 }} />
                </View> */}
              </View>
            </TouchableOpacity>
            <TouchableOpacity>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingVertical: 10,
                  borderBottomWidth: 1,
                  borderColor: "#e4e4e499",
                }}
              >
                <View style={{ width: "40%" }}>
                  <ApText style={{ fontWeight: "500" }}>GMT/USDT</ApText>
                  <ApText
                    style={{
                      color: "#ababab",
                      fontSize: 12,
                      fontWeight: "500",
                      lineHeight: 16,
                    }}
                  >
                    00:00:00
                  </ApText>
                  <ApText
                    style={{
                      color: "#ababab",
                      fontSize: 12,
                      fontWeight: "500",
                      lineHeight: 16,
                    }}
                  >
                    Stable Strategy
                  </ApText>
                </View>

                <ApText
                  style={{
                    width: "50%",
                    fontWeight: "bold",
                    color: "#222",
                    alignSelf: "center",
                    fontSize: 13,
                    textAlign: "right",
                  }}
                >
                  +290.00 USDT
                </ApText>
                {/* <View style={{ width: "10%", marginLeft: "auto", alignSelf: "center", alignItems: "flex-end" }}>
                  <Image source={require("../assets/other-icons/14.png")} style={{ width: 6, height: 9 }} />
                </View> */}
              </View>
            </TouchableOpacity>
            <TouchableOpacity>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingVertical: 10,
                  borderBottomWidth: 1,
                  borderColor: "#e4e4e499",
                }}
              >
                <View style={{ width: "40%" }}>
                  <ApText style={{ fontWeight: "500" }}>GMT/USDT</ApText>
                  <ApText
                    style={{
                      color: "#ababab",
                      fontSize: 12,
                      fontWeight: "500",
                      lineHeight: 16,
                    }}
                  >
                    00:00:00
                  </ApText>
                  <ApText
                    style={{
                      color: "#ababab",
                      fontSize: 12,
                      fontWeight: "500",
                      lineHeight: 16,
                    }}
                  >
                    Stable Strategy
                  </ApText>
                </View>

                <ApText
                  style={{
                    width: "50%",
                    fontWeight: "bold",
                    color: "#222",
                    alignSelf: "center",
                    fontSize: 13,
                    textAlign: "right",
                  }}
                >
                  +290.00 USDT
                </ApText>
                {/* <View style={{ width: "10%", marginLeft: "auto", alignSelf: "center", alignItems: "flex-end" }}>
                  <Image source={require("../assets/other-icons/14.png")} style={{ width: 6, height: 9 }} />
                </View> */}
              </View>
            </TouchableOpacity>
            <TouchableOpacity>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingVertical: 10,
                  borderBottomWidth: 1,
                  borderColor: "#e4e4e499",
                }}
              >
                <View style={{ width: "40%" }}>
                  <ApText style={{ fontWeight: "500" }}>GMT/USDT</ApText>
                  <ApText
                    style={{
                      color: "#ababab",
                      fontSize: 12,
                      fontWeight: "500",
                      lineHeight: 16,
                    }}
                  >
                    00:00:00
                  </ApText>
                  <ApText
                    style={{
                      color: "#ababab",
                      fontSize: 12,
                      fontWeight: "500",
                      lineHeight: 16,
                    }}
                  >
                    Stable Strategy
                  </ApText>
                </View>

                <ApText
                  style={{
                    width: "50%",
                    fontWeight: "bold",
                    color: "#222",
                    alignSelf: "center",
                    fontSize: 13,
                    textAlign: "right",
                  }}
                >
                  +290.00 USDT
                </ApText>
                {/* <View style={{ width: "10%", marginLeft: "auto", alignSelf: "center", alignItems: "flex-end" }}>
                  <Image source={require("../assets/other-icons/14.png")} style={{ width: 6, height: 9 }} />
                </View> */}
              </View>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    );
  };

  return (
    <ApSafeAreaView>
      {renderHeader()}
      {renderContent()}
    </ApSafeAreaView>
  );
};

export default StrategyProfit;
