import React, { useRef } from "react";
import { ScrollView, TouchableOpacity, View } from "react-native";

import { Form, Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { components } from "../components";
import { ApButton, ApSafeAreaView } from "../components/v1";
import { ApTextInput } from "../components/v1/input";
import { ApText } from "../components/v1/typography";
import { useProfileState } from "../context";
import { useApiBindingState } from "../context/apiBinding";

const ValidateForm = (type: string) => {
  return Yup.object().shape({
    api_key: Yup.string().required("Api key is required"),
    api_secret: Yup.string().required("Api secrete is required"),
    passphrase:
      type === "okx" && Yup.string().required("Api passphrase is required"),
  });
};

const Payments: React.FC = ({ navigation, route }: any) => {
  const formRef = useRef<FormikProps<any>>();
  const { getProfileData } = useProfileState();
  const { bindApi, loading } = useApiBindingState();

  const renderHeader = () => {
    return (
      <components.Header
        title={"Bind " + route?.params?.title + " API"}
        titleTranlateKey={
          route?.params?.type === "okx"
            ? "BindOkxApi"
            : route?.params?.type === "bn"
            ? "BindBinanceApi"
            : "BindTriiixApi"
        }
        goBack={true}
      />
    );
  };

  const handleBindApi = (values: any) => {
    //  console.log(values);

    bindApi({ ...values, type: route?.params?.type }).then((rs) => {
      getProfileData();
      navigation.goBack();
    });
  };

  const renderContent = () => {
    return (
      <ScrollView
        contentContainerStyle={{ flexGrow: 1, paddingHorizontal: 20 }}
      >
        <Formik
          innerRef={formRef as any}
          validationSchema={ValidateForm(route?.params?.type)}
          initialValues={{
            api_key: "",
            api_secret: "",
            passphrase: "",
          }}
          onSubmit={handleBindApi}
        >
          {(props: FormikProps<any>) => (
            <Form>
              <View
                style={{
                  backgroundColor: "#FFD9CF",
                  padding: 12,
                  borderRadius: 10,
                  marginTop: 15,
                  marginBottom: 5,
                  flexDirection: "row",
                }}
              >
                <ApText
                  style={{ color: "#F37021", width: "80%", fontSize: 13 }}
                  translateKey={
                    route?.params?.type === "okx"
                      ? "UpdateOkxApiNote"
                      : "UpdateBinanceApiNote"
                  }
                >
                  1. Get Binance API key; use synchronize position transaction
                  data. Enter or copy and paste to your API key
                </ApText>
              </View>

              <View
                style={{
                  // padding: 15,
                  marginTop: 15,
                  borderRadius: 10,
                  //backgroundColor: "#FCF8F6",
                }}
              >
                <ApTextInput
                  label="API Key"
                  name="api_key"
                  labelTranslateKey="ApiKey"
                  placeholder="Please enter API key"
                  placeHolderTranslateKey="PleaseEnterAPIKey"
                />

                <ApTextInput
                  label="Secret Key"
                  labelTranslateKey="SecretKey"
                  name="api_secret"
                  placeholder="Please enter secret key"
                  placeHolderTranslateKey="PleaseEnterSecretKey"
                />

                {route?.params?.type == "okx" && (
                  <ApTextInput
                    label="Passphrase"
                    labelTranslateKey="Passphrase"
                    name="passphrase"
                    placeholder="Please enter passphrase"
                    placeHolderTranslateKey="PleaseEnterPassphraseKey"
                  />
                )}

                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <ApText
                    style={{ fontSize: 11, color: "red" }}
                    translateKey="HowToGetApiKey"
                  />
                </View>

                <View style={{ marginTop: 50 }}>
                  <ApButton
                    style={{ margin: 0, padding: 0 }}
                    round="xl"
                    label="Bind Api"
                    labelTranslateKey="BindApi"
                    disabled={loading}
                    loading={loading}
                    onPress={props.handleSubmit}
                  />
                </View>
              </View>
            </Form>
          )}
        </Formik>
      </ScrollView>
    );
  };

  return (
    <ApSafeAreaView>
      {renderHeader()}
      {renderContent()}
    </ApSafeAreaView>
  );
};

export default Payments;

const RenderIPAddress = () => {
  return (
    <>
      {" "}
      <View
        style={{
          padding: 15,
          margin: 15,
          borderRadius: 10,
          backgroundColor: "#FCF8F6",
        }}
      >
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <ApText
            style={{
              fontSize: 13,
              color: "#F37021",
              fontWeight: "500",
              alignSelf: "center",
              width: "70%",
            }}
          >
            IP : 34.112.1255.123.32.432.12.4654.765.34.534.654
          </ApText>
          <TouchableOpacity
            style={{
              borderRadius: 4,
              paddingVertical: 6,
              paddingHorizontal: 20,
              backgroundColor: "#F37021",
              height: 32,
            }}
          >
            <ApText
              style={{ color: "#fff", textAlign: "center" }}
              translateKey="Copy"
            />
          </TouchableOpacity>
        </View>
      </View>
    </>
  );
};
