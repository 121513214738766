import React, { useEffect, useState } from "react";
import {
  ScrollView,
  View
} from "react-native";
// import Swiper from "react-native-swiper";
import { components } from "../../components";
import { ApLoader, ApSafeAreaView } from "../../components/v1";
import { ApText } from "../../components/v1/typography";
import { useCopyState } from "../../context/copy";

const CopyTradeActionLog: React.FC = ({ navigation, route }: any) => {
  const copy_trade_id = route?.params?.copy_trade_id;
  const provider_id = route?.params?.provider_id;

  const [botactionlog, setbotactionlog] = useState([]);
  const { initLoading, actionLogs, getActionLogs } = useCopyState();

  useEffect(() => {
    getActionLogs(copy_trade_id, provider_id);
  }, []);

  const renderHeader = () => {
    return (
      <components.Header
        title="Copy Trade Action Log"
        titleTranlateKey="CopyTradeActionLog"
        goBack={true}
      />
    );
  };

  const renderContent = () => {
    return (
      <View
        style={{
          paddingHorizontal: 15,
          borderRadius: 10,
          paddingVertical: 10,
        }}
      >
        {initLoading ? (
          <ApLoader />
        ) : (
          <ScrollView>
            {actionLogs.map((e: any) => (
                <View 
                    style={{
                        paddingVertical: 15,
                        borderBottomWidth: 1,
                        borderColor: "#e4e4e499",
                    }}
                >
                    <View
                        style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                        }}
                    >
                        <View >
                            <ApText style={{ fontSize: 13 }}>{e.created_at}</ApText>
                        </View>

                        <View
                        style={{
                            // width: "20%",
                            marginLeft: "auto",
                            alignSelf: "center",
                            alignItems: "flex-end",
                        }}
                        >
                        <ApText style={{ color: "#F37021", fontSize: 13 }}>
                            {e?.label}
                        </ApText>
                        </View>
                    </View>

                    <ApText style={{ textAlign: "right", fontSize: 13 }}>{e.desc1}</ApText>
                </View>
            ))}
          </ScrollView>
        )}
      </View>
    );
  };

  return (
    <ApSafeAreaView>
      <ScrollView
        contentContainerStyle={{
          flexGrow: 1,
        }}
        showsVerticalScrollIndicator={false}
        bounces={false}
      >
        {renderHeader()}
        {renderContent()}
      </ScrollView>
    </ApSafeAreaView>
  );
};

export default CopyTradeActionLog;
