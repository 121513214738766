import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  hideProfit: false,
};

const hideProfitSlice = createSlice({
  name: "hideProfit",
  initialState,
  reducers: {
    setHideProfit: (state, action) => {
      state.hideProfit = !state.hideProfit;
    },
  },
});

export const { setHideProfit } = hideProfitSlice.actions;

export default hideProfitSlice.reducer;
