import * as React from "react";
import Svg, { Path } from "react-native-svg";

function FilterSvg(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width={15} height={15} viewBox="0 0 15 15" {...props}>
      <Path
        d="M101.171 135.111v7.294a.577.577 0 00.835.516l3.461-1.731a.577.577 0 00.318-.516v-5.564l5.062-6.185a.577.577 0 10-.893-.732l-5.192 6.346a.577.577 0 00-.13.366v5.412l-2.308 1.154v-6.566a.577.577 0 00-.13-.366l-4.421-5.4h9.167a.577.577 0 100-1.154H96.556a.577.577 0 00-.447.943z"
        transform="translate(-95.978 -127.983)"
        fill="#F37021"
      />
    </Svg>
  );
}

export default FilterSvg;
