import { useNavigation } from "@react-navigation/native";
import React, { useEffect, useRef, useState } from "react";
import { TextInput, TouchableOpacity, View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import Icon from "react-native-vector-icons/FontAwesome";
import { components } from "../../components";
import {
  ApButton,
  ApCheckInput,
  ApEmptyState,
  ApLoader,
  ApPlatformTabs,
  ApRow,
  ApSafeAreaView,
} from "../../components/v1";
import { ApText } from "../../components/v1/typography";
import { theme } from "../../constants";
import { useTradingContext } from "../../context";
import { svg } from "../../svg";
import { useCopyState } from "../../context/copy";
import { ICopyTrade } from "../../models/copy.interface";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import { RefreshControl } from "react-native-web-refresh-control";
import moment from "moment";
import InfoSvg from "../../svg/InfoSvg";
import { ApModalAlt } from "../../components/Modal";
import Checkbox from "expo-checkbox";
import { CopyTradesFilter } from "./filter";

const CopyTrading = () => {
  const {
    getCopyTrades,
    loading,
    copyTrades,
    selectedCopyTrades,
    setSelectedCopyTrades,
  } = useCopyState();
  const { setPlatform, platform } = useTradingContext();
  const searchInputRef = useRef<TextInput>(null);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [query, setQuery] = useState<any>();
  const { t } = useTranslation();
  const [showFollowing, setShowFollowing] = useState<boolean>(false);
  const [showNotFollowing, setShowNotFollowing] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  // const [selectedCopyTrades, setSelectedCopyTrades] = useState<any[]>([])
  const [openOperation, setOpenOperation] = useState(false);
  const [selected, setSelected] = useState<"following" | "not-following">();
  const navigation: any = useNavigation();
  const [followingStatus, setFollowingStatus] = useState<any>("2");
  const [orderStatus, setOrderStatus] = useState<any>("3");

  useEffect(() => {
    setSelectedCopyTrades([]);
    getCopyTrades();
  }, []);

  const renderHeader = () => {
    return (
      <components.Header
        goBack={true}
        title="Copy Trading"
        titleTranlateKey="CopyTrading"
      />
    );
  };

  const selectFollowing = (v) => {
    if (v === false && selected === "following") {
      setSelectedCopyTrades([]);
    } else {
      if (isSearching) {
        let following = copyTrades?.filter((item) => item.is_following == 1 && (orderStatus == 3 || item.is_close_failed == orderStatus));

        setSelectedCopyTrades(
          following.filter(
            (item) =>
              item?.label.toLowerCase().includes(query.toLowerCase()) ||
              item?.description.toLowerCase().includes(query.toLowerCase())
          )
        );
      } else {
        let following = copyTrades?.filter((item) => item.is_following == 1 && (orderStatus == 3 || item.is_close_failed == orderStatus));
        setSelectedCopyTrades(following);
      }
      setSelected("following");
    }
  };

  const selectNotFollowing = (v) => {
    if (v === false && selected === "not-following") {
      setSelectedCopyTrades([]);
    } else {
      if (isSearching) {
        let not_following = copyTrades?.filter(
          (item) => item.is_following == 0 && (orderStatus == 3 || item.is_close_failed == orderStatus)
        );

        setSelectedCopyTrades(
          not_following.filter(
            (item) =>
              item?.label.toLowerCase().includes(query.toLowerCase()) ||
              item?.description.toLowerCase().includes(query.toLowerCase())
          )
        );
      } else {
        let not_following = copyTrades?.filter(
          (item) => item.is_following == 0 && (orderStatus == 3 || item.is_close_failed == orderStatus)
        );
        setSelectedCopyTrades(not_following);
      }
      setSelected("not-following");
    }
  };

  const filterTrade = (copyTradesList: ICopyTrade[]) => {
    const result = copyTradesList?.filter((item) => {
      let followRs = true;
      let statusRs = true;
      let queryRs = true;

      if (+followingStatus !== 2 && item?.is_following !== +followingStatus) {
        followRs = false;
      }

      if (+orderStatus !== 3 && item?.is_close_failed !== +orderStatus) {
        statusRs = false;
      }

      if (query) {
        queryRs =
          item?.label?.toLowerCase().includes(query?.toLowerCase()) ||
          item?.description?.toLowerCase().includes(query?.toLowerCase());
      }

      return followRs && statusRs && queryRs;
    });

    return result;
  };

  return (
    <ApSafeAreaView style={{}}>
      {renderHeader()}
      {loading ? (
        <ApLoader />
      ) : (
        <>
          <ScrollView
            refreshControl={
              <RefreshControl
                refreshing={loading}
                onRefresh={() => getCopyTrades()}
              />
            }
          >
            <View style={{ marginHorizontal: 15, marginVertical: 10 }}>
              {/* <ApPlatformTabs selected={platform} onSelect={setPlatform} /> */}
              <View
                style={{
                  flexDirection: "row",
                }}
              >
                <View
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Icon name="warning" size={40} color="#fccc4d" />
                    <ApText
                      style={{
                        color: "#F37021",
                        fontSize: 10,
                        marginLeft: 5,
                        textAlign: "justify",
                        lineHeight: 14,
                      }}
                      translateKey="FollowPageNote"
                    />
                  </View>

                  <ApText
                    style={{
                      color: "red",
                      fontSize: 10,
                      textAlign: "justify",
                      lineHeight: 14,
                    }}
                    translateKey="FollowPageNote2"
                  />

                  <ApText
                    style={{
                      color: "red",
                      fontSize: 10,
                      textAlign: "justify",
                      lineHeight: 14,
                    }}
                    translateKey="CopyTradePageHint"
                  />
                </View>
              </View>

              <Formik initialValues={{}} onSubmit={() => {}}>
                <View>
                  <components.SearchInputField
                    inputRef={searchInputRef}
                    onChange={(e: any) => {
                      e.target.value === ""
                        ? setIsSearching(false)
                        : setIsSearching(true);
                      setQuery(e.target.value);
                    }}
                    containerStyle={{ marginVertical: 20 }}
                    leftIcon={<svg.SearchSvg />}
                    placeholder={t("CopyStrategySearchPlaceholder")}
                  />

                  <CopyTradesFilter
                    followingStatus={followingStatus}
                    orderStatus={orderStatus}
                    setFollowingStatus={setFollowingStatus}
                    setOrderStatus={setOrderStatus}
                  />
                </View>
              </Formik>
            </View>

            <View style={{ paddingHorizontal: 15, zIndex: -1 }}>
              {filterTrade(copyTrades)?.map((t, i) => (
                <CopyTradingItem
                  openOperation={openOperation}
                  selectedCopyTrades={selectedCopyTrades}
                  setSelectedCopyTrades={setSelectedCopyTrades}
                  trade={t}
                  key={i}
                />
              ))}

              {filterTrade(copyTrades)?.length === 0 && (
                <ApEmptyState heading="Follow" message="No records found" />
              )}
            </View>
          </ScrollView>

          <Operations
            orderStatus={orderStatus}
            isSearching={isSearching}
            query={query}
            copyTrades={filterTrade(copyTrades)}
            followingStatus={followingStatus}
            openOperation={openOperation}
            setOpenOperation={setOpenOperation}
            setSelectedCopyTrades={setSelectedCopyTrades}
            selectFollowing={selectFollowing}
            selectNotFollowing={selectNotFollowing}
            selectedCopyTrades={selectedCopyTrades}
            selected={selected}
            onProceed={() => {
              selectedCopyTrades?.length > 0 &&
                navigation.navigate("CopyTradeDetails", {
                  copy_trade_ids: selectedCopyTrades
                    ?.map((item) => item?.id)
                    ?.toString(),
                  platform: platform,
                });
            }}
          />
        </>
      )}
    </ApSafeAreaView>
  );
};

export default CopyTrading;

interface IProps {
  trade: ICopyTrade;
  setSelectedCopyTrades: any;
  selectedCopyTrades: ICopyTrade[];
  openOperation: boolean;
}

const CopyTradingItem: React.FC<IProps> = ({
  openOperation,
  trade,
  selectedCopyTrades,
  setSelectedCopyTrades,
}) => {
  const navigation: any = useNavigation();
  const { platform } = useTradingContext();
  const [backTestResult, showBackTestResult] = useState(false);
  const { setSelectedTrade } = useCopyState();
  const [showHint, setShowHint] = useState(false);
  const { t } = useTranslation();

  return (
    <View
      style={{
        justifyContent: "space-between",
        borderRadius: 50,
        backgroundColor: "#FFF6F1",
      }}
    >
      <View
        style={{
          padding: 10,
          paddingTop: 15,
          paddingBottom: 0,
          backgroundColor: "#FFF6F1",
          borderRadius: 10,
        }}
      >
        <View
          // key={`${index}_follow`}
          style={{
            padding: 15,
            borderRadius: 10,
            marginBottom: 20,
            borderWidth: 2,
            borderColor: "#F37021",
          }}
        >
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <ApText
              style={{
                fontSize: 13,
                fontWeight: "500",
                lineHeight:18,
                flex:2,
              }}
            >
              {trade.label}
            </ApText>
            <View
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
                flex: 3,
                justifyContent: "flex-end",
              }}
            >
              <ApText
                style={{
                  fontSize: 13,
                  textAlign: "right",
                  color: "#222",
                  lineHeight:18,
                }}
              >
                {trade.description}
              </ApText>

              {selectedCopyTrades[0] &&
              selectedCopyTrades[0]?.is_following !== trade?.is_following ? (
                <></>
              ) : (
                openOperation && (
                  <TouchableOpacity
                    onPress={() => {
                      if (selectedCopyTrades.find((d) => d?.id == trade?.id)) {
                        setSelectedCopyTrades(
                          selectedCopyTrades?.filter((d) => d?.id !== trade?.id)
                        );
                      } else {
                        setSelectedCopyTrades(
                          selectedCopyTrades.concat([trade])
                        );
                      }
                    }}
                    style={{
                      marginLeft: 5,
                      width: 13,
                      height: 13,
                      borderWidth: 1,
                      borderRadius: 13,
                      borderColor: "#949494",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {selectedCopyTrades.find((d) => d?.id == trade?.id) && (
                      <View
                        style={{
                          width: 7,
                          height: 7,
                          borderRadius: 10 / 2,
                          backgroundColor: "#F37021",
                        }}
                      />
                    )}
                  </TouchableOpacity>
                )
              )}
            </View>
          </View>

          <ApRow>
            <View
              style={{
                flexDirection: "row",
                paddingTop: 5,
                paddingBottom: 10,
              }}
            >
              <ApText
                style={{
                  fontSize: 11,
                  color: "#878787",
                  marginRight: 5,
                }}
              >
                {trade.follower_count}
              </ApText>
              <ApText
                translateKey="Followers"
                style={{ fontSize: 11, color: "#878787" }}
              />
            </View>
            <View
              style={{
                flexDirection: "row",
                paddingTop: 5,
                paddingBottom: 10,
              }}
            >
              <ApText
                translateKey="datePLPerc"
                style={{ fontSize: 11, color: "#222", marginRight: 5 }}
              />
              <ApText
                style={{
                  fontSize: 11,
                  color: "#222",
                }}
              >
                {moment(trade.renew_datetime).format("YYYY-MM-DD")}
              </ApText>
            </View>

            {trade?.is_close_failed == 1 ? (
              <ApText
                style={{ fontSize: 11, color: "red", marginBottom: 5 }}
                translateKey="CloseOrderFailed"
              />
            ) : (
              trade?.is_following == 1 && (
                <ApText
                  style={{
                    fontSize: 11,
                    color: theme.COLORS.green,
                    marginBottom: 5,
                  }}
                  translateKey="Following"
                />
              )
            )}
          </ApRow>

          <View
            style={{
              flexDirection: "row",
              alignItems: "flex-start",
              borderBottomWidth: 1,
              borderBottomColor: "#CED6E1",
            }}
          />
          <View
            style={{
              flexDirection: "row",
              alignItems: "flex-start",
            }}
          >
            <View style={{ flex: 1 }}>
              <View style={{ flexDirection: "row", marginTop: 12 }}>
                <ApText
                  translateKey="new30dPLPerc"
                  style={{
                    ...theme.FONTS.Mulish_400Regular,
                    fontSize: 12,
                    lineHeight: 12 * 1.6,
                    color: theme.COLORS.bodyTextColor,
                    marginRight: 5,
                  }}
                />
                <TouchableOpacity onPress={() => setShowHint(true)}>
                  <InfoSvg strokeColor="#F37021" />
                </TouchableOpacity>
              </View>
              <ApText
                style={{
                  marginBottom: 12,
                  fontWeight: "500",
                  color: theme.COLORS.mainDark,
                  fontSize: 13,
                }}
              >
                {trade.pnl_perc_30d}%
              </ApText>
              <View style={{ flexDirection: "row" }}>
                <ApText
                  translateKey="totalPLPerc"
                  style={{
                    ...theme.FONTS.Mulish_400Regular,
                    fontSize: 12,
                    lineHeight: 12 * 1.6,
                    color: theme.COLORS.bodyTextColor,
                    marginRight: 5,
                  }}
                />
                <TouchableOpacity onPress={() => setShowHint(true)}>
                  <InfoSvg strokeColor="#F37021" />
                </TouchableOpacity>
              </View>
              <ApText
                style={{
                  fontWeight: "500",
                  color: theme.COLORS.mainDark,
                  fontSize: 13,
                }}
              >
                {trade.pnl_perc_total}%
              </ApText>
            </View>

            <View style={{ flex: 1 }}>
              <View style={{ flexDirection: "row", marginTop: 12 }}>
                <ApText
                  translateKey="90dPLPerc"
                  style={{
                    ...theme.FONTS.Mulish_400Regular,
                    fontSize: 12,
                    lineHeight: 12 * 1.6,
                    color: theme.COLORS.bodyTextColor,
                    marginRight: 5,
                  }}
                />
                <TouchableOpacity onPress={() => setShowHint(true)}>
                  <InfoSvg strokeColor="#F37021" />
                </TouchableOpacity>
              </View>
              <ApText
                style={{
                  marginBottom: 12,
                  fontWeight: "500",
                  color: theme.COLORS.mainDark,
                  fontSize: 13,
                }}
              >
                {trade.pnl_perc_90d}%
              </ApText>
              {/* <ApText
                translateKey="30dPLTrades"
                style={{
                  ...theme.FONTS.Mulish_400Regular,
                  fontSize: 12,
                  lineHeight: 12 * 1.6,
                  color: theme.COLORS.bodyTextColor,
                }}
              />
              <ApText
                style={{
                  fontWeight: "500",
                  color: theme.COLORS.mainDark,
                  fontSize: 13,
                }}
              >
                {trade.trades_30d}
              </ApText> */}
            </View>
          </View>

          {backTestResult && <BackTestResult trade={trade} />}

          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <TouchableOpacity
              onPress={() => showBackTestResult(!backTestResult)}
              style={{ flexDirection: "row" }}
            >
              <ApText
                size="xs"
                color="#F37021"
                translateKey="BackTestResult"
                font="bold"
              />

              <View
                style={{
                  marginLeft: !backTestResult ? 12 : 8,
                  marginTop: -3,
                  transform: [{ rotate: !backTestResult ? "-90deg" : "90deg" }],
                }}
              >
                <svg.GoBackSvg width={6} height={12} goBackColor="#F37021" />
              </View>
            </TouchableOpacity>

            <TouchableOpacity
              onPress={() => {
                setSelectedTrade(trade);
                navigation.navigate("CopyTradeDetails", {
                  copy_trade_id: trade.id,
                  platform: platform,
                });
              }}
              style={{
                backgroundColor: "#F37021",
                width: 100,
                height: 30,
                borderRadius: 5,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ApText
                translateKey="CopyTradeDetails"
                style={{
                  fontSize: 13,
                  color: theme.COLORS.white,
                  fontWeight: "500",
                }}
              />
            </TouchableOpacity>
          </View>
        </View>
      </View>

      {showHint && (
        <ApModalAlt
          fallbackRoute="CopyTrading"
          transparent={true}
          visible={showHint}
        >
          <TouchableOpacity
            onPress={() => {
              setShowHint(false);
            }}
            style={{ alignItems: "flex-end", padding: 15, paddingBottom: 0 }}
          >
            <svg.closeSvg />
          </TouchableOpacity>
          <View
            style={{
              width: "100%",
              paddingTop: 0,
              paddingVertical: 20,
              paddingHorizontal: 20,
            }}
          >
            <ApText style={{ marginLeft: 3, fontSize: 14 }}>
              {t("contentPLPerc")}
            </ApText>
          </View>
        </ApModalAlt>
      )}
    </View>
  );
};

interface IBackTestProps {
  trade: ICopyTrade;
}

const BackTestResult: React.FC<IBackTestProps> = ({ trade }) => {
  return (
    <View
      style={{
        padding: 8,
        borderRadius: 10,
        marginVertical: 15,
        borderWidth: 2,
        borderColor: "#F37021",
      }}
    >
      <ApRow justify="flex-start">
        <ApText size="xs" translateKey="BackTestPeriod" />

        <ApText size="xs" color="#F37021" font="bold">
          {" : "}
          {trade?.backtest_period}
        </ApText>
      </ApRow>

      <ApRow style={{ marginVertical: 10 }}>
        <View style={{ alignItems: "center", width: "33%" }}>
          <ApText style={{ fontSize: 11 }} translateKey="TotalNetProfit" />

          <ApText size="xs" color="#F37021" font="bold">
            {trade?.backtest_pnl}%
          </ApText>
        </View>

        <View style={{ alignItems: "center", width: "33%" }}>
          <ApText style={{ fontSize: 11 }} translateKey="MaxDrawdown" />

          <ApText size="xs" color="#F37021" font="bold">
            {trade?.backtest_drawdown}%
          </ApText>
        </View>

        <View style={{ alignItems: "center", width: "33%" }}>
          <ApText style={{ fontSize: 11 }} translateKey="TestedCurrency" />

          <ApText size="xs" color="#F37021" font="bold">
            {trade?.backtest_symbol}
          </ApText>
        </View>
      </ApRow>

      <View>
        <ApText
          size="xs"
          style={{ marginBottom: 5 }}
          translateKey="BackTestResultFirstLabel"
        />
        <ApText
          size="xs"
          style={{ marginBottom: 5 }}
          translateKey="BackTestResultSecondLabel"
        />
        <ApText
          size="xs"
          style={{ marginBottom: 5 }}
          translateKey="BackTestResultThirdLabel"
        />
        <ApText size="xs" translateKey="BackTestResultFourthLabel" />
      </View>
    </View>
  );
};

const Operations = ({
  orderStatus,
  isSearching,
  query,
  followingStatus,
  openOperation,
  setOpenOperation,
  setSelectedCopyTrades,
  selectFollowing,
  selectNotFollowing,
  onProceed,
  copyTrades,
  selectedCopyTrades,
  selected
}) => {
  const [toggleFollowing, setToggleFollowing] = useState(false);
  const [toggleNotFollowing, setToggleNotFollowing] = useState(false);
  const [toggleRemove, setToggleRemove] = useState(false);

  useEffect(() => {
    if (isSearching) {
      setSelectedCopyTrades(selectedCopyTrades.filter(s =>
        copyTrades.some(c => c.id === s.id)
      ));
      // const c = selectedCopyTrades.filter((item) =>
      //   orderStatus !== "3"
      //     ? (item?.is_close_failed == orderStatus &&
      //         item?.label.toLowerCase().includes(query.toLowerCase())) ||
      //       item?.description.toLowerCase().includes(query.toLowerCase())
      //     : item?.label.toLowerCase().includes(query.toLowerCase()) ||
      //       item?.description.toLowerCase().includes(query.toLowerCase())
      // );

      // console.log("here..");
      // setSelectedCopyTrades(
      //   selectedCopyTrades.filter((x) =>
      //     copyTrades.map((x) => x.id).includes(x.id)
      //   )
      // );

      // setSelectedCopyTrades(c);
    } else {
      if (toggleFollowing) {
        selectFollowing(true);
      }

      if (toggleNotFollowing) {
        selectNotFollowing(true);
      }
    }
    
    if(!toggleFollowing && !toggleNotFollowing) {
      setSelectedCopyTrades(selectedCopyTrades.filter(s =>
        copyTrades.some(c => c.id === s.id)
      ));
    }

  }, [isSearching, query, followingStatus, orderStatus]);

  useEffect(() => {
    if (followingStatus == "0" && toggleNotFollowing == false) {
      setToggleFollowing(false);
    }

    if (followingStatus == "1" && toggleFollowing == false) {
      setToggleNotFollowing(false);
    }

    // setSelectedCopyTrades(
    //   selectedCopyTrades.filter((x) =>
    //     copyTrades.map((x) => x.id).includes(x.id)
    //   )
    // );

    if(followingStatus == "0" && selected == "following") {
      setSelectedCopyTrades([]);
    }

    if(followingStatus == "1" && selected == "not-following") {
      setSelectedCopyTrades([]);
    }

  }, [followingStatus, orderStatus]);

  useEffect(() => {
    if (selectedCopyTrades.length === 0) {
      setToggleFollowing(false);
      setToggleNotFollowing(false);
      setToggleRemove(false);
    }
  }, [selectedCopyTrades]);

  return (
    <View
      style={{
        elevation: 10,
        shadowOpacity: 10,
        paddingHorizontal: 15,
        paddingVertical: 10,
      }}
    >
      {openOperation && (
        <View
          style={{
            marginBottom: 10,
            borderRadius: 6,
            backgroundColor: "#FFF8F4",
            padding: 10,
            width: "100%",
          }}
        >
          <View
            style={{
              marginBottom: 10,
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <ApText
              style={{
                color: "#F37021",
                fontSize: 12,
                fontWeight: "500",
              }}
              translateKey="BatchOperation"
            />

            <View style={{ display: "flex", flexDirection: "row" }}>
              <ApText
                style={{
                  fontSize: 12,
                  color: "#F37021",
                  fontWeight: "500",
                  textAlign: "right",
                }}
                translateKey="SelectedCopyTrades"
              />
              <ApText> </ApText>
              <ApText
                style={{
                  fontSize: 12,
                  color: "#F37021",
                  fontWeight: "500",
                  textAlign: "right",
                }}
              >
                {selectedCopyTrades.length}
              </ApText>
            </View>
          </View>

          <View
            style={{
              marginTop: 10,
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            {followingStatus !== "0" && (
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  // width: "100%",
                }}
              >
                <Checkbox
                  style={{
                    height: 13,
                    width: 13,
                    borderWidth: !toggleFollowing ? 1 : 0,
                    borderColor: "#F37021",
                  }}
                  value={toggleFollowing}
                  color={toggleFollowing ? "#F37021" : "transparent"}
                  onValueChange={(v) => {
                    setToggleFollowing(!toggleFollowing);
                    setToggleNotFollowing(false);
                    setToggleRemove(false);
                    selectFollowing(v);
                  }}
                />
                <ApText
                  style={{ marginLeft: 10, fontSize: 14 }}
                  translateKey="Following"
                />
              </View>
            )}

            {followingStatus !== "1" && (
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  // width: "100%",
                }}
              >
                <Checkbox
                  style={{
                    height: 13,
                    width: 13,
                    borderWidth: !toggleNotFollowing ? 1 : 0,
                    borderColor: "#F37021",
                  }}
                  value={toggleNotFollowing}
                  color={toggleNotFollowing ? "#F37021" : "transparent"}
                  onValueChange={(v) => {
                    setToggleNotFollowing(!toggleNotFollowing);
                    setToggleFollowing(false);
                    setToggleRemove(false);
                    selectNotFollowing(v);
                  }}
                />

                <ApText
                  style={{ marginLeft: 10, fontSize: 14 }}
                  translateKey="NotFollowing"
                />
              </View>
            )}

            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                // width: "100%",
              }}
            >
              <Checkbox
                style={{
                  height: 13,
                  width: 13,
                  borderWidth: !toggleRemove ? 1 : 0,
                  borderColor: "#F37021",
                }}
                value={toggleRemove}
                color={toggleRemove ? "#F37021" : "transparent"}
                onValueChange={(v) => {
                  setSelectedCopyTrades([]);
                  setToggleRemove(!toggleRemove);
                  setToggleFollowing(false);
                  setToggleNotFollowing(false);
                }}
              />

              <ApText
                style={{ marginLeft: 10, fontSize: 14 }}
                translateKey="RemoveAll"
              />
            </View>
          </View>
        </View>
      )}

      {!openOperation ? (
        <ApButton
          style={{ width: "100%", marginTop: 0 }}
          round="lg"
          labelTranslateKey="BatchOperation"
          label="Batch Selection"
          onPress={() => {
            openOperation ? setSelectedCopyTrades([]) : "";
            setOpenOperation(!openOperation);
          }}
        />
      ) : (
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <ApButton
            style={{ marginTop: 0 }}
            containerWidth="48%"
            round="lg"
            labelTranslateKey="Cancel"
            label="Cancel"
            onPress={() => {
              openOperation ? setSelectedCopyTrades([]) : "";
              setOpenOperation(!openOperation);
            }}
          />

          <ApButton
            style={{ marginTop: 0 }}
            containerWidth="48%"
            round="lg"
            labelTranslateKey="Proceed"
            label="Proceed"
            onPress={onProceed}
          />
        </View>
      )}
    </View>
  );
};
