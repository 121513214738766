import React, { useEffect, useState } from "react";
import { TextInput, View } from "react-native";
import { theme } from "../../../constants";
import { useTranslation } from "react-i18next";

export type Props = {
  placeholder?: string;
  icon?: any;
  containerStyle?: any;
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  defaultValue?: string;
  value?: string;
  onChange?: (val: string) => void;
};

export const ApSearchInput: React.FC<Props> = ({
  placeholder,
  containerStyle,
  leftIcon,
  rightIcon,
  onChange,
  value,
  defaultValue,
}) => {
  const [val, setVal] = useState<string>("");
  const {t} = useTranslation()

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (onChange) onChange(val?.toLowerCase());
    }, 200);

    return () => {
      clearTimeout(timeout);
    };
  }, [val]);

  return (
    <View
      style={{
        height: 36,
        width: "100%",
        borderRadius: 4,
        borderWidth: 0,
        flexDirection: "row",
        alignItems: "center",
        ...containerStyle,
        paddingHorizontal: 15,
        backgroundColor: "#f2f2f2",
      }}
    >
      {leftIcon && leftIcon}
      <TextInput
        placeholder={placeholder || t("Search")}
        style={{
          flex: 1,
          height: "100%",
          width: "100%",
          flexDirection: "row",
          justifyContent: "space-between",
          fontSize: 13,
          lineHeight: 16 * 1,
          ...theme.FONTS.Mulish_400Regular,
          backgroundColor: "#F2F2F2",
          paddingHorizontal: 12,
          borderWidth: 0,
        }}
        placeholderTextColor={"#c6c6c6"}
        numberOfLines={1}
        onChange={(e) => {
          setVal(e.nativeEvent.text as any);
        }}
        value={value}
        defaultValue={defaultValue}
      />
      {rightIcon && rightIcon}
    </View>
  );
};
