import * as React from "react";
import Svg, { G, Path } from "react-native-svg";

function TutorialSvg(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width={24} height={22.41} viewBox="0 0 24 22.41" {...props}>
      <G data-name="board-performance-profit-svgrepo-com (1)" transform="translate(-1 -3.326)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth={2}>
        <Path data-name="Rectangle 146" transform="translate(4 4.326)" stroke="#7a8390" d="M0 0H19V13H0z" />
        <Path data-name="Path 58" d="M13.238 23.307v-5.619L7.619 24.11" stroke="#7a8390" />
        <Path data-name="Line 1" transform="translate(13 17.326)" stroke="#7a8390" d="M6 7L0 0" />
        <Path data-name="Line 2" transform="translate(2 4.326)" stroke="#7a8390" d="M0 0L22 0" />
        <Path data-name="Path 59" d="M6.816 13.675l3.211-3.211h3.211l3.211 1.605 3.211-4.816" stroke="#F37021" />
      </G>
    </Svg>
  );
}

export default TutorialSvg;
