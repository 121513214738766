import * as React from "react";
import Svg, { Path, G } from "react-native-svg";

function ReferralSvg(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" style={{marginLeft: -5}} width={25} height={28} viewBox="0 0 28 28" {...props}>
      <Path
        d="M28 0v28H0V0zM14.692 27.134h-.014l-.083.041H14.555l-.083-.041a.025.025 0 00-.028.006v.013l-.02.5.006.024.013.014.121.086h.031l.121-.086.015-.019v-.019l-.02-.5a.025.025 0 00-.019-.021zM15 27h-.016l-.215.108-.012.012v.013l.021.5.006.015.01.008.234.108a.029.029 0 00.033-.009v-.016l-.04-.717A.03.03 0 0015 27zm-.835 0a.027.027 0 00-.032.006l-.007.016-.04.717a.027.027 0 00.02.027h.018l.234-.108.011-.009v-.014l.021-.5v-.015l-.011-.01z"
        fill="none"
      />
      <Path
        d="M19.262 3a3.439 3.439 0 013.446 3.22l.006.2v4.96l.683-.3a1.165 1.165 0 011.61.909l.008.133v9.122a2.289 2.289 0 01-2.13 2.274l-.172.006H4.3a2.293 2.293 0 01-2.3-2.11v-9.292a1.162 1.162 0 011.493-1.089l.126.047.683.3V6.421a3.434 3.434 0 013.249-3.415L7.754 3zm3.452 10.876l-8.271 3.642a2.322 2.322 0 01-1.87 0L4.3 13.876v7.367h18.414zm-3.452-8.6H7.754A1.146 1.146 0 006.6 6.421v5.973l6.9 3.041 6.9-3.041V6.421a1.146 1.146 0 00-1.138-1.141z"
        transform="translate(.492 .738)"
        fill="#7a8390"
        fillRule="evenodd"
      />
      <G data-name="Group 33">
        <G data-name="Group 32">
          <Path
            data-name="Path 36"
            d="M159.2 157.457v1.74h-1.74a.557.557 0 000 1.114h1.74v1.74a.556.556 0 101.111 0v-1.74h1.74a.557.557 0 000-1.114h-1.74v-1.74a.556.556 0 10-1.111 0z"
            transform="translate(11.148 7.515) translate(-156.9 -156.9)"
            fill="#F37021"
          />
        </G>
      </G>
    </Svg>
  );
}

export default ReferralSvg;
