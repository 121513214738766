import * as React from "react";
import Svg, { G, Path, Circle } from "react-native-svg";

function ChatSvg(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width={23.743} height={22} viewBox="0 0 23.743 22" {...props}>
      <G data-name="Group 95">
        <Path
          d="M328.067 290.811a1.362 1.362 0 01-1.362-1.362v-2.1h-2.918a3.627 3.627 0 01-3.623-3.623v-9.3a3.626 3.626 0 013.623-3.623h14.5a3.626 3.626 0 013.623 3.623v9.3a3.627 3.627 0 01-3.623 3.623h-4.7l-4.756 3.223a1.367 1.367 0 01-.764.239z"
          fill="none"
          stroke="#7a8390"
          strokeWidth={2}
          transform="translate(-319.167 -269.811)"
        />
        <G data-name="Group 94" transform="translate(-319.167 -269.811) translate(325.568 279.001)" fill="#F37021">
          <Circle data-name="Ellipse 26" cx={0.912} cy={0.912} r={0.912} />
          <Circle data-name="Ellipse 27" cx={0.912} cy={0.912} r={0.912} transform="translate(4.558)" />
          <Circle data-name="Ellipse 28" cx={0.912} cy={0.912} r={0.912} transform="translate(9.116)" />
        </G>
      </G>
    </Svg>
  );
}

export default ChatSvg;
