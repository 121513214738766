import React, { useEffect, useRef, useState } from "react";
import {
  ScrollView,
  StyleSheet,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import Modal from "react-native-modal";
import { components } from "../components";
import { theme } from "../constants";
// import Slider from "rn-range-slider";
import { Formik, FormikProps } from "formik";
import { ApModalAlt } from "../components/Modal";
import { ApButton, ApRow, ApSafeAreaView } from "../components/v1";
import {
  ApCheckInput,
  ApDropdown,
  ApTextInput,
  ApToggleInput,
} from "../components/v1/input";
import { ApLeverageInput } from "../components/v1/input/leverageInput";
import { ApText } from "../components/v1/typography";
// import { MasterData } from "../constants/master";
import { useTranslation } from "react-i18next";
import { useProfileState, useTradingContext } from "../context";
import { useMasterData } from "../hooks/master";
import { svg } from "../svg";
import InfoSvg from "../svg/InfoSvg";
import { formatAmt } from "../utils";
import KlineSetting from "./KlineSetting";
import MarginSetting from "./MarginSetting";
import OnTrendMargin from "./OnTrendMargin";
import TakeProfitModeSetting from "./TakeProfitModeSettings";
import TakeProfitSettings from "./TakeProfitSettings";
import MacdModeSetting from "./MacdModeSetting";
import OrderSettings from "./OrderSettings";
import RsiSetting from "./RsiSettings";
import EmaSetting from "./EmaSettings";
import KdjSetting from "./KdjSettings";

const EditStrategy: React.FC = ({ navigation, route }: any) => {
  const bex_id = route.params?.bex_id && route.params?.bex_id;
  const formRef = useRef<FormikProps<any>>();
  const { MasterData } = useMasterData();
  const [strategyName, setStrategyName] = useState<string>();
  const [shareStrategy, setShareStrategy] = useState<boolean>(false);
  const [labelCode, setLabelCode] = useState<string>();
  const [showModal, setShowModal] = useState(false);
  const [showHint, setShowHint] = useState<{
    show: boolean;
    type?:
      | "exit_settings_take_profit_macd"
      | "exit_settings_stop_loss"
      | "exit_settings_take_profit_mode"
      | "exit_settings_take_profit"
      | "commission_perc"
      | "direction"
      | "leverage"
      | "order_settings"
      | "on_trend_margin_multiplier"
      | "rearrange_entry_count"
      | "enable_tp_perc"
  }>({ show: false });
  const [showOnTrendModal, setShowOnTrendModal] = useState(false);
  const [showMarginModal, setShowMarginModal] = useState(false);
  const [showOrderSettings, setShowOrderSettings] = useState(false);
  const [showTpModal, setShowTpModal] = useState(false);
  const [showTpModeModal, setShowTpModeModal] = useState(false);
  const [showKlineModal, setShowKlineModal] = useState(false);
  const [showMacdTypeModal, setShowMacdTypeModal] = useState(false);
  const [formData, setFormData] = useState<any>();
  const [showOvrwModal, setShowOvrwModal] = useState<boolean>(false);
  const [submitOperation, setSubmitOperation] = useState<
    "save-new" | "overwrite"
  >();
  const [items, setItems] = useState<any[]>();
  const [initVol, setInitVol] = useState<boolean>(false);
  const [allowTargetProfit, setAllowTargetProfit] = useState(false);
  const [showRsiModal, setShowRsiModal] = useState(false);
  const [showEmaModal, setShowEmaModal] = useState(false);
  const [showKdjModal, setShowKdjModal] = useState(false);

  const {
    orderSettings,
    generateOrderSettings,
    maxSets,
    setMaxSets,
    loading,
    createBotExecutor,
    platform,
    market,
    setActive_EMA4,
    setExtraSetting,
    setExtraSettings,
    botPlans,
    setSelectedStrategy,
    selectedStrategy,
    strategies,
    botExecutorStrategySummary,
    setActive_MACD,
    active_EMA4,
    active_MACD,
    numOfReentry,
    setNumOfReentry,
    updateBotPlan,
    ontrend_init_fund,
    entry_size,
    entry_price_diff,
    entry_trailing,
    entry_ta_ema4,
    entry_macd_type,
    entry_ta_macd,
    entry_tp_mode,
    entry_tp_perc,
    entry_tp_trailing_perc,
    isVolatility,
    setVolatility,
    createBotPlan,
    setEvalOnTrendValues,
    entry_ta_ema,
    entry_ta_kdj,
    entry_ta_rsi
  } = useTradingContext();

  const { profileData } = useProfileState();
  const [submitted, setSubmitted] = useState(false);

  const { t } = useTranslation();
  const [renderModal, setRenderModal] = useState({
    strategyInfo: false,
    strategyDirection: false,
    profitMethod: false,
    profitSetting: false,
    SurplusWay: false,
  });

  useEffect(() => {
    setStrategyName(selectedStrategy?.label_code);
    // setActive_MACD(selectedStrategy?.entry_ta_macd?.split(',')[0])
    // setActive_EMA4(selectedStrategy?.entry_ta_ema4?.split(',')[0])
    if (parseFloat(selectedStrategy?.waterfall_perc) > 0) {
      setVolatility(true);
      setInitVol(true);
    } else {
      setVolatility(false);
      setInitVol(false);
    }

    setShareStrategy(
      bex_id
        ? botExecutorStrategySummary.is_peer === "1"
        : selectedStrategy.is_peer === "1"
    );

    bex_id &&
      setAllowTargetProfit(
        botExecutorStrategySummary.target_pnl == "0" &&
          botExecutorStrategySummary.target_pnl_max == "0"
          ? false
          : true
      );
  }, []);


  useEffect(() => {
    if (bex_id) {
      setItems(
        strategies
          .filter((item) => item.market === market)
          .filter(
            (item) => item.plan_type === botExecutorStrategySummary.plan_type
          )
      );
    } else {
      setItems(strategies.filter((item) => item.market === market));
    }
  }, [strategies]);  

  const renderHeader = () => {
    return (
      <components.Header
        title="Edit Strategy"
        titleTranlateKey="StrategyDetails"
        goBack={true}
      />
    );
  };

  const renderStrategyInfo = () => {
    return (
      <Modal
        isVisible={renderModal.strategyInfo}
        onBackdropPress={() =>
          setRenderModal({ ...renderModal, strategyInfo: false })
        }
        hideModalContentWhileAnimating={true}
        backdropTransitionOutTiming={0}
        style={{ margin: 0 }}
      >
        <View
          style={{
            backgroundColor: theme.COLORS.bgColor,
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
            position: "absolute",
            width: theme.SIZES.width,
            bottom: 0,
            paddingHorizontal: 20,
            paddingTop: 30,
            paddingBottom: 54,
          }}
        >
          <ApText
            style={{
              textTransform: "capitalize",
              marginBottom: 20,
              textAlign: "center",
              ...theme.FONTS.H4,
              color: theme.COLORS.mainDark,
            }}
          >
            Select Strategy
          </ApText>
        </View>
      </Modal>
    );
  };

  const renderStrategyDirection = () => {
    return (
      <Modal
        isVisible={renderModal.strategyDirection}
        onBackdropPress={() =>
          setRenderModal({ ...renderModal, strategyDirection: false })
        }
        hideModalContentWhileAnimating={true}
        backdropTransitionOutTiming={0}
        style={{ margin: 0 }}
      >
        <View
          style={{
            backgroundColor: theme.COLORS.bgColor,
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
            position: "absolute",
            width: theme.SIZES.width,
            bottom: 0,
            paddingHorizontal: 20,
            paddingTop: 30,
            paddingBottom: 54,
          }}
        >
          <ApText
            style={{
              textTransform: "capitalize",
              marginBottom: 20,
              textAlign: "center",
              ...theme.FONTS.H4,
              color: theme.COLORS.mainDark,
            }}
          >
            Select Strategy
          </ApText>
        </View>
      </Modal>
    );
  };

  const renderSurplusWay = () => {
    return (
      <Modal
        isVisible={renderModal.SurplusWay}
        onBackdropPress={() =>
          setRenderModal({ ...renderModal, SurplusWay: false })
        }
        hideModalContentWhileAnimating={true}
        backdropTransitionOutTiming={0}
        style={{ margin: 0 }}
      >
        <View
          style={{
            backgroundColor: theme.COLORS.bgColor,
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
            position: "absolute",
            width: theme.SIZES.width,
            bottom: 0,
            paddingHorizontal: 20,
            paddingTop: 30,
            paddingBottom: 54,
          }}
        >
          <ApText
            style={{
              textTransform: "capitalize",
              marginBottom: 20,
              textAlign: "center",
              ...theme.FONTS.H4,
              color: theme.COLORS.mainDark,
            }}
          >
            选择止盈方式
          </ApText>
        </View>
      </Modal>
    );
  };

  const handleSave = (values: any) => {
    setSubmitted(true);
    if (values?.is_peer && !values?.is_peer_agreed) return;

    setShowOvrwModal(false);
    // console.log(values);

    let double_init_fund;
    if (values.double_init_fund === undefined) {
      double_init_fund = botExecutorStrategySummary.double_init_fund;
    } else if (values.double_init_fund === false) {
      double_init_fund = "0";
    } else {
      double_init_fund = "1";
    }

    // let symbols: any[] = []
    // values.symbols.map((item: any) => {
    //   symbols.push(item?.value)
    // })

    let w_perc = values.waterfall_perc / 100;
    let entry_size_v = ["1", ...entry_size];
    let entry_price_diff_v = ["0", ...entry_price_diff];
    let entry_trailing_v = ["0", ...entry_trailing];

    let entry_ta_bb: any[] = [];
    for (let i = 0; i < parseInt(values.max_entries) + 1; i++) {
      entry_ta_bb.push("0");
    }
    let spot_ontrend_init_fund: any[] = [];
    for (let i = 0; i < parseInt(values.max_entries) + 1; i++) {
      spot_ontrend_init_fund.push("1");
    }

    let payload = {
      bp_id: selectedStrategy?.id,
      label_code: selectedStrategy?.label_code,
      direction: values.direction,
      double_init_fund: double_init_fund,
      ontrend_init_fund:
        selectedStrategy.market === "1"
          ? spot_ontrend_init_fund.toString()
          : [
              "1",
              ...ontrend_init_fund.filter((item) => item !== null),
            ].toString(),
      plan_type: selectedStrategy.plan_type,
      max_entries: (parseInt(values.max_entries) + 1).toString(),
      max_sets:
        selectedStrategy.plan_type == "1"
          ? values.max_sets
          : selectedStrategy.max_sets,
      set_return:
        selectedStrategy.plan_type == "1"
          ? orderSettings.curr.set_return
              .filter((item) => item !== null)
              .toString()
          : selectedStrategy.set_return,
      set_sizes_1:
        selectedStrategy.plan_type == "1"
          ? orderSettings.curr.set_sizes_1
              .filter((item) => item !== null)
              .toString()
          : selectedStrategy.set_sizes_1,
      set_sizes_2:
        selectedStrategy.plan_type == "1"
          ? orderSettings.curr.set_sizes_2
              .filter((item) => item !== null)
              .toString()
          : selectedStrategy.set_sizes_2,
      entry_size: entry_size_v.filter((item) => item !== null).toString(),
      entry_price_diff: entry_price_diff_v
        .filter((item) => item !== null)
        .toString(),
      entry_trailing: entry_trailing_v
        .filter((item) => item !== null)
        .toString(),
      waterfall_perc: !isVolatility ? "0" : w_perc.toString(),
      kline_frequency: values.kline_frequency,
      macd_tp_kline_frequency: values.macd_tp_kline_frequency,
      // macd_type: values.macd_type,
      entry_macd_type: entry_macd_type.toString(),
      entry_ta_macd: entry_ta_macd.toString(),
      entry_ta_ema4: entry_ta_ema4.toString(),
      entry_tp_mode: entry_tp_mode.toString(),
      entry_ta_ema: entry_ta_ema.toString(),
      entry_ta_kdj: entry_ta_kdj.toString(),
      entry_ta_rsi: entry_ta_rsi.toString(),
      rsi_type: values?.rsi_type,
      rsi_low: values?.rsi_low,
      rsi_high: values?.rsi_high,
      ema_type: values?.ema_type,
      ema_low: values?.ema_low,
      ema_high: values?.ema_high,
      kdj_type: values?.kdj_type,
      kdj_low: values?.kdj_low,
      kdj_high: values?.kdj_high,
      entry_tp_perc: entry_tp_perc.filter((item) => item !== null).toString(),
      entry_tp_trailing_perc: entry_tp_trailing_perc
        .filter((item) => item !== null)
        .toString(),
      macd_tp_interval: values.macd_tp_interval,
      entry_ta_bb: entry_ta_bb.toString(),
      sl_perc: (+values.sl_perc / 100).toString(),
      tp_perc: (+values.tp_perc / 100).toString(),
      tp_trailing_perc: (+values.tp_trailing_perc / 100).toString(),
      rearrange_entry_count: values.rearrange_entry_count == false ? "0" : "1",
      enable_tp_perc: values.enable_tp_perc == false ? "0" : "1",
      is_peer:
        profileData.is_trader === 1
          ? !shareStrategy
            ? "0"
            : values.is_peer === true
            ? "1"
            : "0"
          : "0",
      plan_desc: profileData.is_trader === 1 ? values.plan_desc : "",
      peer_fee:
        profileData.is_trader === 1
          ? (parseFloat(values.peer_fee) / 100).toString()
          : "",
    };

    let bexPayload = {
      bex_id: bex_id,
      exec_max: `${+values.exec_max + +botExecutorStrategySummary?.exec_count}`,
      init_fund: values.init_fund,
      leverage: values.leverage,
      bp_id: selectedStrategy.id,
      target_pnl_max: `${
        +values.target_pnl_max + +botExecutorStrategySummary?.target_pnl_count
      }`,
      target_pnl: values.target_pnl,
      init_upper_boundary: values.init_upper_boundary,
      init_lower_boundary: values.init_lower_boundary,
    };

    if (bex_id) {
      updateBotPlan(payload, bexPayload);
    } else {
      updateBotPlan(payload);
    }
    setSubmitted(false);
  };

  const create = (values: any) => {
    setSubmitted(true);
    if (values?.is_peer && !values?.is_peer_agreed) return;

    if (!labelCode) {
      return;
    }

    setShowModal(false);
    //console.log(values)
    let double_init_fund;
    if (values.double_init_fund === undefined) {
      double_init_fund = botExecutorStrategySummary.double_init_fund;
    } else if (values.double_init_fund === false) {
      double_init_fund = "0";
    } else {
      double_init_fund = "1";
    }

    // let symbols: any[] = []
    // values.symbols.map((item: any) => {
    //   symbols.push(item?.value)
    // })

    let w_perc = values.waterfall_perc / 100;
    let entry_size_v = ["1", ...entry_size];
    let entry_price_diff_v = ["0", ...entry_price_diff];
    let entry_trailing_v = ["0", ...entry_trailing];

    let entry_ta_bb: any[] = [];
    for (let i = 0; i < parseInt(values.max_entries) + 1; i++) {
      entry_ta_bb.push("0");
    }
    let spot_ontrend_init_fund: any[] = [];
    for (let i = 0; i < parseInt(values.max_entries) + 1; i++) {
      spot_ontrend_init_fund.push("1");
    }

    let payload = {
      label_code: labelCode,
      market: market,
      direction: values.direction,
      double_init_fund: double_init_fund,
      ontrend_init_fund:
        selectedStrategy.market === "1"
          ? spot_ontrend_init_fund.toString()
          : [
              "1",
              ...ontrend_init_fund.filter((item) => item !== null),
            ].toString(),
      plan_type: selectedStrategy.plan_type,
      max_entries: (parseInt(values.max_entries) + 1).toString(),
      max_sets:
        selectedStrategy.plan_type == "1"
          ? values.max_sets
          : selectedStrategy.max_sets,
      set_return:
        selectedStrategy.plan_type == "1"
          ? orderSettings.curr.set_return
              .filter((item) => item !== null)
              .toString()
          : selectedStrategy.set_return,
      set_sizes_1:
        selectedStrategy.plan_type == "1"
          ? orderSettings.curr.set_sizes_1
              .filter((item) => item !== null)
              .toString()
          : selectedStrategy.set_sizes_1,
      set_sizes_2:
        selectedStrategy.plan_type == "1"
          ? orderSettings.curr.set_sizes_2
              .filter((item) => item !== null)
              .toString()
          : selectedStrategy.set_sizes_2,
      entry_size: entry_size_v.filter((item) => item !== null).toString(),
      entry_price_diff: entry_price_diff_v
        .filter((item) => item !== null)
        .toString(),
      entry_trailing: entry_trailing_v
        .filter((item) => item !== null)
        .toString(),
      waterfall_perc: !isVolatility ? "0" : w_perc.toString(),
      kline_frequency: values.kline_frequency,
      macd_tp_kline_frequency: values.macd_tp_kline_frequency,
      // macd_type: values.macd_type,
      entry_macd_type: entry_macd_type.toString(),
      entry_ta_macd: entry_ta_macd.toString(),
      entry_ta_ema4: entry_ta_ema4.toString(),
      entry_tp_mode: entry_tp_mode.toString(),
      entry_ta_ema: entry_ta_ema.toString(),
      entry_ta_kdj: entry_ta_kdj.toString(),
      entry_ta_rsi: entry_ta_rsi.toString(),
      rsi_type: values?.rsi_type,
      rsi_low: values?.rsi_low,
      rsi_high: values?.rsi_high,
      ema_type: values?.ema_type,
      ema_low: values?.ema_low,
      ema_high: values?.ema_high,
      kdj_type: values?.kdj_type,
      kdj_low: values?.kdj_low,
      kdj_high: values?.kdj_high,
      entry_tp_perc: entry_tp_perc.filter((item) => item !== null).toString(),
      entry_tp_trailing_perc: entry_tp_trailing_perc
        .filter((item) => item !== null)
        .toString(),
      macd_tp_interval: values.macd_tp_interval,
      entry_ta_bb: entry_ta_bb.toString(),
      sl_perc: (+values.sl_perc / 100).toString(),
      tp_perc: (+values.tp_perc / 100).toString(),
      tp_trailing_perc: (+values.tp_trailing_perc / 100).toString(),
      rearrange_entry_count: values.rearrange_entry_count == false ? "0" : "1",
      enable_tp_perc: values.enable_tp_perc == false ? "0" : "1",
      is_peer:
        profileData.is_trader === 1
          ? !shareStrategy
            ? "0"
            : values.is_peer === true
            ? "1"
            : "0"
          : "0",
      plan_desc: profileData.is_trader === 1 ? values.plan_desc : "",
      peer_fee:
        profileData.is_trader === 1
          ? (parseFloat(values.peer_fee) / 100).toString()
          : "",
    };

    let bexPayload = {
      bex_id: bex_id,
      exec_max: `${+values.exec_max + +botExecutorStrategySummary?.exec_count}`,
      init_fund: values.init_fund,
      leverage: values.leverage,
      bp_id: selectedStrategy.id,
      init_upper_boundary: "0",
      init_lower_boundary: "0",
    };

    // let bexPayload = {
    //   bp_id: selectedStrategy.id,
    //   exec_max: values.exec_max,
    //   init_fund: values.init_fund,
    //   leverage: values.leverage,
    //   platform: platform,
    //   market: market,
    //   symbol: botExecutorStrategySummary.symbol,
    // };

    if (bex_id) {
      createBotPlan(payload, bexPayload);
    } else {
      createBotPlan(payload);
    }
    // createBotPlan(payload);
    setSubmitted(false);
  };

  const handleSelectedStrategy = (e: any) => {
    const item = [...botPlans.default, ...botPlans.my].find(
      (itm) => itm?.value === e
    );

    if (item) {
      setNumOfReentry(parseInt(item.max_entries));
      setSelectedStrategy(item);
      setStrategyName(item?.label_code);
      setShareStrategy(item?.is_peer === "0" ? false : true);
      // setShareStrategy(
      //   bex_id
      //     ? botExecutorStrategySummary.is_peer === "1"
      //     : selectedStrategy.is_peer === "1"
      // );
      setExtraSettings(item?.max_entries, true, item);
      setActive_MACD(item?.entry_ta_macd?.split(",")[0]);
      setActive_EMA4(item?.entry_ta_ema4?.split(",")[0]);

      formRef.current.setValues({
        ...formRef.current.values,
        // macd_type: item?.macd_type,
        label_code: item?.id,
        active_MACD: item?.entry_ta_macd?.split(",")[0],
        entry_ta_ema4: item?.entry_ta_ema4?.split(",")[0],
        entry_ta_macd: item?.entry_ta_macd?.split(",")[0],
        direction: item?.direction,
        // direction: selectedStrategy.market === "1" ? MasterData?.SPOT_DIRECTION_TYPES : MasterData?.DIRECTION_TYPES,
        kline_frequency: item.kline_frequency,
        macd_tp_kline_frequency: item.macd_tp_kline_frequency,
        macd_tp_interval: item?.macd_tp_interval,
      });
    }

    // all.forEach((item) => {
    //   if (item?.label_code === e) {
    //     setNumOfReentry(parseInt(item.max_entries))
    //     setSelectedStrategy(item);
    //     setStrategyName(item?.label_code)
    //     setShareStrategy(item?.is_peer === "0" ? false : true)
    //     setExtraSettings(item?.max_entries, true, item)
    //     setActive_MACD(item?.entry_ta_macd?.split(',')[0])
    //     setActive_EMA4(item?.entry_ta_ema4?.split(',')[0])
    //   }
    // });
    //console.log(e)
  };

  const handleSubmit = (values: any) => {
    setSubmitted(true);
    if (values?.is_peer && !values?.is_peer_agreed) return;

    if (submitOperation === "save-new") {
      setShowModal(true);
    } else if (submitOperation === "overwrite") {
      setShowOvrwModal(true);
    }

    setSubmitted(false);
  };

  const renderContent = () => {
    return (
      <ScrollView style={{ flex: 1, padding: 20 }}>
        <Formik
          enableReinitialize
          innerRef={formRef as any}
          //validationSchema={FormSchema}
          initialValues={{
            double_init_fund:
              selectedStrategy?.double_init_fund === "0" ? false : true,
            // macd_type: selectedStrategy?.macd_type
            //   ? selectedStrategy?.macd_type
            //   : MasterData?.MACD_TYPES[0].value,
            is_peer: selectedStrategy.is_peer === "1",
            is_peer_agreed: selectedStrategy.is_peer === "1",
            macd_tp_interval: selectedStrategy?.macd_tp_interval,
            sl_perc: formatAmt(+selectedStrategy?.sl_perc * 100),
            kline_frequency: selectedStrategy?.kline_frequency,
            macd_tp_kline_frequency: selectedStrategy?.macd_tp_kline_frequency,
            label_code: selectedStrategy.id,
            target_pnl_max: (
              parseInt(botExecutorStrategySummary.target_pnl_max) -
              parseInt(botExecutorStrategySummary.target_pnl_count)
            ).toString(),
            target_pnl: botExecutorStrategySummary.target_pnl,
            direction: selectedStrategy?.direction,
            init_fund: botExecutorStrategySummary.init_fund,
            // selectedStrategy.market === "1" ? "15" : "1",
            leverage: botExecutorStrategySummary.leverage,
            exec_max:
              botExecutorStrategySummary?.exec_max ===
              botExecutorStrategySummary?.exec_count
                ? "0"
                : +botExecutorStrategySummary?.exec_max -
                  +botExecutorStrategySummary?.exec_count,
            max_entries: parseInt(selectedStrategy?.max_entries) - 1,
            waterfall_perc: formatAmt(
              parseFloat(selectedStrategy.waterfall_perc) * 100
            ),
            symbols: [],
            entry_ta_ema4: selectedStrategy?.entry_ta_ema4?.split(",")[0],
            entry_ta_macd: selectedStrategy?.entry_ta_macd?.split(",")[0],
            plan_desc: selectedStrategy.plan_desc,
            peer_fee: formatAmt(parseFloat(selectedStrategy.peer_fee) * 100),
            enable_tp_perc: selectedStrategy?.enable_tp_perc === "0" ? false : true,
            rearrange_entry_count: selectedStrategy?.rearrange_entry_count === "0" ? false : true,
            tp_perc: formatAmt(+selectedStrategy?.tp_perc * 100),
            tp_trailing_perc: formatAmt(+selectedStrategy?.tp_trailing_perc * 100),
            rsi_type: selectedStrategy?.rsi_type,
            rsi_low: selectedStrategy?.rsi_low,
            rsi_high: selectedStrategy?.rsi_high,
            ema_type: selectedStrategy?.ema_type,
            ema_low: selectedStrategy?.ema_low,
            ema_high: selectedStrategy?.ema_high,
            kdj_type: selectedStrategy?.kdj_type,
            kdj_low: selectedStrategy?.kdj_low,
            kdj_high: selectedStrategy?.kdj_high,
            init_upper_boundary: formatAmt(botExecutorStrategySummary.init_upper_boundary),
            init_lower_boundary: formatAmt(botExecutorStrategySummary.init_lower_boundary),
          }}
          onSubmit={handleSubmit}
        >
          {(props: FormikProps<any>) => (
            <>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  borderBottomColor: "#ced6e182",
                  borderBottomWidth: 1.5,
                  alignItems: "center",
                  marginBottom: 10,
                  zIndex: 99999,
                }}
              >
                <View style={{ borderBottomColor: "#ced6e182" }}>
                  <ApText
                    translateKey="Strategy"
                    style={{
                      fontSize: 14,
                      color: "#F37021",
                      fontWeight: "500",
                    }}
                  >
                    Strategy
                  </ApText>
                </View>

                <>
                  {strategyName ? (
                    <ApDropdown
                      containerStyle={{
                        flexWrap: "nowrap",
                        flexDirection: "column",
                      }}
                      noBorder
                      style={{ borderBottomWidth: 0 }}
                      onChange={(e: any) => {
                        handleSelectedStrategy(e);
                      }}
                      name="label_code"
                      placeholder={t("ChooseAStrategy")}
                      items={items.filter((itm) => itm.account_name)}
                    />
                  ) : (
                    <></>
                  )}
                </>
              </View>

              {bex_id && (
                <>
                  <ApText
                    translateKey="ExecutionSettings"
                    style={{
                      paddingTop: 10,
                      fontSize: 14,
                      color: "#F37021",
                      fontWeight: "500",
                      paddingBottom: 10,
                    }}
                  />

                  <View
                    style={{
                      marginBottom: 20,
                      padding: 15,
                      backgroundColor: "#FCF8F6",
                      borderRadius: 10,
                    }}
                  >
                    <View
                      style={{
                        borderBottomWidth: 1,
                        paddingBottom: 12,
                        borderBottomColor: "#ced6e182",
                      }}
                    >
                      <View
                        style={{
                          alignItems: "flex-start",
                        }}
                      >
                        <ApText
                          translateKey="Symbol"
                          style={{
                            ...theme.FONTS.H6,
                            color: theme.COLORS.mainDark,
                            marginBottom: 10,
                          }}
                        >
                          Symbol
                        </ApText>

                        <ApText
                          style={{
                            backgroundColor: "#F37021",
                            color: "#fff",
                            paddingVertical: 1,
                            paddingHorizontal: 10,
                            fontSize: 11,
                            borderRadius: 5,
                          }}
                        >
                          {botExecutorStrategySummary.symbol}
                        </ApText>
                      </View>
                    </View>

                    <ApTextInput
                      border
                      width={130}
                      height={25}
                      labelTranslateKey="FirstOrderMargin"
                      label="First Order Margin"
                      labelStyle={style.inputLabel}
                      name="init_fund"
                      inputStyle={{
                        ...style.textInput,
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                      }}
                      rightIcon={
                        <ApText
                          translateKey="USDT"
                          style={{
                            backgroundColor: "#F37021",
                            color: "#FFF",
                            fontSize: 10,
                            paddingHorizontal: 8,
                            paddingVertical: 0.5,
                            borderTopRightRadius: 6,
                            borderBottomRightRadius: 6,
                          }}
                        >
                          USDT
                        </ApText>
                      }
                    />

                    {market !== "1" && (
                      <ApTextInput
                        labelIcon={
                          <TouchableOpacity
                            onPress={() =>
                              setShowHint({ show: true, type: "leverage" })
                            }
                          >
                            <InfoSvg strokeColor="#F37021" />
                          </TouchableOpacity>
                        }
                        rightIcon={
                          <ApText
                            style={{
                              backgroundColor: "#F37021",
                              color: "#FFF",
                              fontSize: 10,
                              paddingHorizontal: 10,
                              paddingVertical: 0.5,
                              borderTopRightRadius: 6,
                              borderBottomRightRadius: 6,
                            }}
                          >
                            X
                          </ApText>
                        }
                        border
                        width={130}
                        height={25}
                        label="Leverage"
                        labelTranslateKey="Leverage"
                        labelStyle={style.inputLabel}
                        name="leverage"
                        inputStyle={{
                          ...style.textInput,
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                        }}
                      />
                    )}

                    <ApTextInput
                      rightIcon={
                        <ApText
                          translateKey="Rounds"
                          style={{
                            backgroundColor: "#F37021",
                            color: "#FFF",
                            fontSize: 10,
                            paddingHorizontal: 8,
                            paddingVertical: 0.5,
                            borderTopRightRadius: 6,
                            borderBottomRightRadius: 6,
                          }}
                        />
                      }
                      border
 
                      width={130}
                      height={25}
                      label="Execution Round"
                      labelTranslateKey="ExecutionsRemaining"
                      labelStyle={style.inputLabel}
                      name="exec_max"
                      inputStyle={{
                        ...style.textInput,
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                      }}
                    />

                    <ApTextInput
                      border
                      width={130}
                      height={25}
                      labelTranslateKey="UpFirstOrderLimit"
                      label="First Order Margin"
                      labelStyle={style.inputLabel}
                      name="init_upper_boundary"
                      inputStyle={{
                        ...style.textInput,
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                      }}
                      rightIcon={
                        <ApText
                          translateKey="USDT"
                          style={{
                            backgroundColor: "#F37021",
                            color: "#FFF",
                            fontSize: 10,
                            paddingHorizontal: 8,
                            paddingVertical: 0.5,
                            borderTopRightRadius: 6,
                            borderBottomRightRadius: 6,
                          }}
                        >
                          USDT
                        </ApText>
                      }
                    />

                    <ApTextInput
                      border
                      width={130}
                      height={25}
                      containerStyle={{
                        borderBottomColor: "#FCF8F6",
                        paddingBottom: 0,
                      }}
                      labelTranslateKey="DownFirstOrderLimit"
                      label="First Order Margin"
                      labelStyle={style.inputLabel}
                      name="init_lower_boundary"
                      inputStyle={{
                        ...style.textInput,
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                      }}
                      rightIcon={
                        <ApText
                          translateKey="USDT"
                          style={{
                            backgroundColor: "#F37021",
                            color: "#FFF",
                            fontSize: 10,
                            paddingHorizontal: 8,
                            paddingVertical: 0.5,
                            borderTopRightRadius: 6,
                            borderBottomRightRadius: 6,
                          }}
                        >
                          USDT
                        </ApText>
                      }
                    />
                  </View>
                </>
              )}

              <ApText
                translateKey="EntrySettings"
                style={{
                  fontSize: 14,
                  color: "#F37021",
                  fontWeight: "500",
                  paddingBottom: 10,
                }}
              />
              <View
                style={{
                  marginBottom: 20,
                  padding: 15,
                  paddingTop: 0,
                  backgroundColor: "#FCF8F6",
                  borderRadius: 10,
                }}
              >
                <View style={{ zIndex: 9999 }}>
                  <ApDropdown
                    label="Direction"
                    name="direction"
                    placeholder="Direction"
                    labelTranslateKey="Direction"
                    items={
                      selectedStrategy.market === "1"
                        ? MasterData?.SPOT_DIRECTION_TYPES
                        : MasterData?.DIRECTION_TYPES
                    }
                  />
                </View>

                <ApToggleInput
                  noBorder={selectedStrategy.market === "1" ? true : false}
                  labelTranslateKey="DoubleMarginorFirstOrder"
                  defaultValue={
                    selectedStrategy?.double_init_fund === "0" ? false : true
                  }
                  label="Double Margin or First Order"
                  name="double_init_fund"
                  labelStyle={style.inputLabel}
                />

                {selectedStrategy.market === "1" ? (
                  <></>
                ) : (
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      paddingVertical: 12,
                      paddingBottom: 0,
                      justifyContent: "space-between",
                      borderBottomColor: "#ced6e182",
                    }}
                  >
                    <ApRow>
                      <ApText
                        translateKey="OnTrendMarginMultiper"
                        style={{
                          ...theme.FONTS.H6,
                          color: theme.COLORS.mainDark,
                          marginRight: 5,
                        }}
                      />
                      <TouchableOpacity
                        onPress={() =>
                          setShowHint({
                            show: true,
                            type: "on_trend_margin_multiplier",
                          })
                        }
                      >
                        <InfoSvg strokeColor="#F37021" />
                      </TouchableOpacity>
                    </ApRow>

                    <TouchableOpacity
                      onPress={() => {
                        setExtraSettings(numOfReentry - 1).then((rs) => {
                          setEvalOnTrendValues(null);
                          setShowOnTrendModal(true);
                        });
                      }}
                      style={{
                        backgroundColor: "#F37021",
                        paddingVertical: 3,
                        width: 80,
                        borderRadius: 4,
                      }}
                    >
                      <ApText
                        translateKey="Select"
                        style={{
                          textAlign: "center",
                          fontSize: 12,
                          color: "white",
                        }}
                      />
                    </TouchableOpacity>
                  </View>
                )}
              </View>

              <ApText
                translateKey="ReEntrySettings"
                style={{
                  fontSize: 14,
                  color: "#F37021",
                  fontWeight: "500",
                  paddingBottom: 10,
                }}
              />
              <View
                style={{
                  marginBottom: 20,
                  padding: 15,
                  paddingTop: 0,
                  backgroundColor: "#FCF8F6",
                  borderRadius: 10,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <ApTextInput
                  rightIcon={
                    <ApText
                      translateKey="Orders"
                      style={{
                        backgroundColor: "#F37021",
                        color: "#FFF",
                        fontSize: 10,
                        paddingHorizontal: 8,
                        paddingVertical: 0.5,
                        borderTopRightRadius: 6,
                        borderBottomRightRadius: 6,
                      }}
                    />
                  }
                  border
                  width={130}
                  height={25}
                  onChange={(e: any) => {
                    if (!isNaN(+e)) {
                      setNumOfReentry(+e + 1);
                    } else props.setFieldValue("max_entries", parseInt(e));
                  }}
                  label="Number of Re-entry"
                  labelTranslateKey="NumberofReEntry"
                  labelStyle={style.inputLabel}
                  name="max_entries"
                  inputStyle={{
                    ...style.textInput,
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                  }}
                />

                <ApToggleInput
                  defaultValue={
                    parseFloat(selectedStrategy.waterfall_perc) > 0
                      ? true
                      : false
                  }
                  onToggle={(e: any) => setVolatility(e)}
                  label="Volatility Control"
                  labelTranslateKey="VolatilityControl"
                  name="waterfall_perc_toggle"
                  labelStyle={style.inputLabel}
                />

                {!isVolatility ? (
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      borderBottomWidth: 1,
                      paddingVertical: 15,
                      borderBottomColor: "#ced6e182",
                    }}
                  >
                    <ApText
                      translateKey="VolatilityPricePercentage"
                      size="sm"
                      style={{ ...style.inputLabel, marginTop: 1.2 }}
                    />

                    <View
                      style={{
                        backgroundColor: "#DFE3EA",
                        width: 120,
                        height: 25,
                        borderRadius: 6,
                      }}
                    >
                      <ApText
                        style={{
                          color: "#F37021",
                          textAlign: "center",
                          fontSize: 13,
                        }}
                      >
                        0
                      </ApText>
                    </View>
                  </View>
                ) : (
                  <ApTextInput
                    rightIcon={
                      <ApText
                        style={{
                          backgroundColor: "#F37021",
                          color: "#FFF",
                          fontSize: 11,
                          paddingHorizontal: 10,
                          paddingVertical: 0.5,
                          borderTopRightRadius: 6,
                          borderBottomRightRadius: 6,
                        }}
                      >
                        %
                      </ApText>
                    }
                    border
                    width={125}
                    height={25}
                    defaultValue={formatAmt(
                      parseFloat(selectedStrategy.waterfall_perc) * 100
                    )}
                    label="Volatility Price Percentage"
                    labelTranslateKey="VolatilityPricePercentage"
                    labelStyle={style.inputLabel}
                    name="waterfall_perc"
                    inputStyle={{
                      ...style.textInput,
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                    }}
                  />
                )}

                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    paddingVertical: 12,
                    paddingBottom: 0,
                    justifyContent: "space-between",
                    borderBottomColor: "#ced6e182",
                  }}
                >
                  <ApText
                    translateKey="ReEntryConditionSettings"
                    style={{
                      ...theme.FONTS.H6,
                      color: theme.COLORS.mainDark,
                    }}
                  />

                  <TouchableOpacity
                    onPress={() => {
                      setExtraSettings(numOfReentry - 1).then((rs) => {
                        setShowMarginModal(true);
                      });
                    }}
                    style={{
                      backgroundColor: "#F37021",
                      paddingVertical: 3,
                      width: 80,
                      borderRadius: 4,
                    }}
                  >
                    <ApText
                      translateKey="Select"
                      style={{
                        textAlign: "center",
                        fontSize: 12,
                        color: "white",
                      }}
                    />
                  </TouchableOpacity>
                </View>
              </View>

              <ApText
                translateKey="IndicatorSettings"
                style={{
                  fontSize: 14,
                  color: "#F37021",
                  fontWeight: "500",
                  paddingBottom: 10,
                }}
              />
              <View
                style={{
                  marginBottom: 20,
                  padding: 15,
                  paddingTop: 0,
                  backgroundColor: "#FCF8F6",
                  borderRadius: 10,
                }}
              >
                <View style={{ zIndex: 300 }}>
                  <ApDropdown
                    label="Kline Monitoring Interval for MACD"
                    name="kline_frequency"
                    labelTranslateKey="KlineMonitoringIntervalforMACD"
                    labelStyle={{ width: "50%" }}
                    items={MasterData?.INTERVAL_FREQUENCY_TYPES}
                  />
                </View>

                <View
                  style={{
                    paddingBottom: 13,
                    borderBottomWidth: 1,
                    flexDirection: "row",
                    alignItems: "center",
                    paddingVertical: 12,
                    justifyContent: "space-between",
                    borderBottomColor: "#ced6e182",
                  }}
                >
                  <ApText
                    translateKey="MACDMode"
                    style={{
                      ...theme.FONTS.H6,
                      color: theme.COLORS.mainDark,
                    }}
                  />

                  <TouchableOpacity
                    onPress={() => {
                      setExtraSettings(numOfReentry - 1).then((rs) => {
                        setShowMacdTypeModal(true);
                      });
                    }}
                    style={{
                      backgroundColor: "#F37021",
                      paddingVertical: 3,
                      width: 80,
                      borderRadius: 4,
                    }}
                  >
                    <ApText
                      translateKey="Select"
                      style={{
                        textAlign: "center",
                        fontSize: 12,
                        color: "white",
                      }}
                    />
                  </TouchableOpacity>
                </View>

                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    paddingVertical: 12,
                    paddingBottom: 13,
                    justifyContent: "space-between",
                    borderBottomColor: "#ced6e182",
                    borderBottomWidth: 1,
                  }}
                >
                  <ApText
                    translateKey="IndividualKlineIntervalSettings"
                    style={{
                      ...theme.FONTS.H6,
                      color: theme.COLORS.mainDark,
                    }}
                  />

                  <TouchableOpacity
                    onPress={() => {
                      setExtraSettings(numOfReentry - 1).then((rs) => {
                        setShowKlineModal(true);
                      });
                    }}
                    style={{
                      backgroundColor: "#F37021",
                      paddingVertical: 3,
                      width: 80,
                      borderRadius: 4,
                    }}
                  >
                    <ApText
                      translateKey="Select"
                      style={{
                        textAlign: "center",
                        fontSize: 12,
                        color: "white",
                      }}
                    />
                  </TouchableOpacity>
                </View>

                <View
                  style={{
                    paddingBottom: 13,
                    borderBottomWidth: 1,
                    flexDirection: "row",
                    alignItems: "center",
                    paddingVertical: 12,
                    justifyContent: "space-between",
                    borderBottomColor: "#ced6e182",
                  }}
                >
                  <ApText
                    translateKey="EMA"
                    style={{
                      ...theme.FONTS.H6,
                      color: theme.COLORS.mainDark,
                    }}
                  />

                  <TouchableOpacity
                    onPress={() => {
                      setExtraSettings(numOfReentry - 1).then((rs) => {
                        setShowEmaModal(true);
                      });
                    }}
                    style={{
                      backgroundColor: "#F37021",
                      paddingVertical: 3,
                      width: 80,
                      borderRadius: 4,
                    }}
                  >
                    <ApText
                      translateKey="Select"
                      style={{
                        textAlign: "center",
                        fontSize: 12,
                        color: "white",
                      }}
                    />
                  </TouchableOpacity>
                </View>

                <View
                  style={{
                    paddingBottom: 13,
                    borderBottomWidth: 1,
                    flexDirection: "row",
                    alignItems: "center",
                    paddingVertical: 12,
                    justifyContent: "space-between",
                    borderBottomColor: "#ced6e182",
                  }}
                >
                  <ApText
                    translateKey="RSI"
                    style={{
                      ...theme.FONTS.H6,
                      color: theme.COLORS.mainDark,
                    }}
                  />

                  <TouchableOpacity
                    onPress={() => {
                      setExtraSettings(numOfReentry - 1).then((rs) => {
                        setShowRsiModal(true);
                      });
                    }}
                    style={{
                      backgroundColor: "#F37021",
                      paddingVertical: 3,
                      width: 80,
                      borderRadius: 4,
                    }}
                  >
                    <ApText
                      translateKey="Select"
                      style={{
                        textAlign: "center",
                        fontSize: 12,
                        color: "white",
                      }}
                    />
                  </TouchableOpacity>
                </View>

                <View
                  style={{
                    // borderBottomWidth: 1,
                    flexDirection: "row",
                    alignItems: "center",
                    paddingVertical: 12,
                    paddingBottom: 0,
                    justifyContent: "space-between",
                    borderBottomColor: "#ced6e182",
                  }}
                >
                  <ApText
                    translateKey="KDJ"
                    style={{
                      ...theme.FONTS.H6,
                      color: theme.COLORS.mainDark,
                    }}
                  />

                  <TouchableOpacity
                    onPress={() => {
                      setExtraSettings(numOfReentry - 1).then((rs) => {
                        setShowKdjModal(true);
                      });
                    }}
                    style={{
                      backgroundColor: "#F37021",
                      paddingVertical: 3,
                      width: 80,
                      borderRadius: 4,
                    }}
                  >
                    <ApText
                      translateKey="Select"
                      style={{
                        textAlign: "center",
                        fontSize: 12,
                        color: "white",
                      }}
                    />
                  </TouchableOpacity>
                </View>
              </View>

              <ApText
                translateKey="ExitSettings"
                style={{
                  fontSize: 14,
                  color: "#F37021",
                  fontWeight: "500",
                  paddingBottom: 10,
                }}
              />
              <View
                style={{
                  zIndex: -100,
                  marginBottom: 20,
                  padding: 15,
                  paddingTop: 0,
                  backgroundColor: "#FCF8F6",
                  borderRadius: 10,
                }}
              >
                <View style={{ zIndex: 300 }}>
                  <ApDropdown
                    label="Kline Monitoring for MACD Close"
                    name="macd_tp_kline_frequency"
                    labelTranslateKey="KlineMonitoringForMACDClose"
                    labelStyle={{ width: "50%" }}
                    items={MasterData?.INTERVAL_FREQUENCY_TYPES}
                  />
                </View>

                <View style={{ zIndex: 100 }}>
                  <ApDropdown
                    labelIcon={
                      <TouchableOpacity
                        onPress={() =>
                          setShowHint({
                            show: true,
                            type: "exit_settings_take_profit_macd",
                          })
                        }
                      >
                        <InfoSvg strokeColor="#F37021" />
                      </TouchableOpacity>
                    }
                    label="Take-Profit MACD Interval"
                    labelTranslateKey="TakeProfitMACDInterval"
                    name="macd_tp_interval"
                    labelStyle={{ width: "50%" }}
                    items={MasterData?.INTERVALS}
                  />
                </View>
                
                <ApToggleInput
                  labelTranslateKey="EnableFullTP"
                  defaultValue={
                    bex_id ? botExecutorStrategySummary?.enable_tp_perc == "1" ? true : false
                    : selectedStrategy?.enable_tp_perc == "1" ? true : false
                  }
                  label="Enable Full TP"
                  name="enable_tp_perc"
                  labelStyle={style.inputLabel}
                  labelIcon={
                    <TouchableOpacity
                      onPress={() =>
                        setShowHint({
                          show: true,
                          type: "enable_tp_perc",
                        })
                      }
                    >
                      <InfoSvg strokeColor="#F37021" />
                    </TouchableOpacity>
                  }
                />
                
                {props?.values?.enable_tp_perc == true && (
                  <>                  
                    <ApTextInput
                      rightIcon={
                        <ApText
                          style={{
                            backgroundColor: "#F37021",
                            color: "#FFF",
                            fontSize: 11,
                            paddingHorizontal: 10,
                            paddingVertical: 0.5,
                            borderTopRightRadius: 6,
                            borderBottomRightRadius: 6,
                          }}
                        >
                          %
                        </ApText>
                      }
                      border
                      width={130}
                      height={25}
                      defaultValue={
                        bex_id
                          ? formatAmt(+botExecutorStrategySummary.tp_perc * 100)
                          : formatAmt(+selectedStrategy?.tp_perc * 100)
                      }
                      label="Full TP Percentage"
                      labelTranslateKey="FullTPPercentage"
                      labelStyle={style.inputLabel}
                      name="tp_perc"
                      inputStyle={{
                        ...style.textInput,
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                      }}
                    />
    
                    <ApTextInput
                      rightIcon={
                        <ApText
                          style={{
                            backgroundColor: "#F37021",
                            color: "#FFF",
                            fontSize: 11,
                            paddingHorizontal: 10,
                            paddingVertical: 0.5,
                            borderTopRightRadius: 6,
                            borderBottomRightRadius: 6,
                          }}
                        >
                          %
                        </ApText>
                      }
                      border
                      width={130}
                      height={25}
                      defaultValue={
                        bex_id
                          ? formatAmt(+botExecutorStrategySummary.tp_trailing_perc * 100)
                          : formatAmt(+selectedStrategy?.tp_trailing_perc * 100)
                      }
                      label="Full Trailing TP"
                      labelTranslateKey="FullTrailingTP"
                      labelStyle={style.inputLabel}
                      name="tp_trailing_perc"
                      inputStyle={{
                        ...style.textInput,
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                      }}
                    />
                  </>
                )}

                <ApTextInput
                  labelIcon={
                    <TouchableOpacity
                      onPress={() =>
                        setShowHint({
                          show: true,
                          type: "exit_settings_stop_loss",
                        })
                      }
                    >
                      <InfoSvg strokeColor="#F37021" />
                    </TouchableOpacity>
                  }
                  rightIcon={
                    <ApText
                      style={{
                        backgroundColor: "#F37021",
                        color: "#FFF",
                        fontSize: 11,
                        paddingHorizontal: 10,
                        paddingVertical: 0.5,
                        borderTopRightRadius: 6,
                        borderBottomRightRadius: 6,
                      }}
                    >
                      %
                    </ApText>
                  }
                  border
                  width={130}
                  height={25}
                  defaultValue={
                    bex_id
                      ? formatAmt(+botExecutorStrategySummary.sl_perc * 100)
                      : formatAmt(+selectedStrategy?.sl_perc * 100)
                  }
                  label="Stop Loss"
                  labelTranslateKey="StopLoss"
                  labelStyle={style.inputLabel}
                  name="sl_perc"
                  inputStyle={{
                    ...style.textInput,
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                  }}
                />

                <ApToggleInput
                  labelTranslateKey="RearrangeEntryCount"
                  defaultValue={
                    bex_id ? botExecutorStrategySummary?.rearrange_entry_count == "1" ? true : false
                    : selectedStrategy?.rearrange_entry_count == "1" ? true : false
                  }
                  label="Rearrange Entry Count"
                  name="rearrange_entry_count"
                  labelStyle={style.inputLabel}
                  labelIcon={
                    <TouchableOpacity
                      onPress={() =>
                        setShowHint({
                          show: true,
                          type: "rearrange_entry_count",
                        })
                      }
                    >
                      <InfoSvg strokeColor="#F37021" />
                    </TouchableOpacity>
                  }
                />

                <View
                  style={{
                    paddingBottom: 13,
                    borderBottomWidth: 1,
                    flexDirection: "row",
                    alignItems: "center",
                    paddingVertical: 12,
                    justifyContent: "space-between",
                    borderBottomColor: "#ced6e182",
                  }}
                >
                  <ApRow>
                    <ApText
                      translateKey="TakeProfitMode"
                      style={{
                        ...theme.FONTS.H6,
                        color: theme.COLORS.mainDark,
                        marginRight: 5,
                      }}
                    />
                    <TouchableOpacity
                      onPress={() =>
                        setShowHint({
                          show: true,
                          type: "exit_settings_take_profit_mode",
                        })
                      }
                    >
                      <InfoSvg strokeColor="#F37021" />
                    </TouchableOpacity>
                  </ApRow>

                  <TouchableOpacity
                    onPress={() => {
                      setExtraSettings(numOfReentry - 1).then((rs) => {
                        setShowTpModeModal(true);
                      });
                    }}
                    style={{
                      backgroundColor: "#F37021",
                      paddingVertical: 3,
                      width: 80,
                      borderRadius: 4,
                    }}
                  >
                    <ApText
                      translateKey="Select"
                      style={{
                        textAlign: "center",
                        fontSize: 12,
                        color: "white",
                      }}
                    />
                  </TouchableOpacity>
                </View>

                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    paddingVertical: 12,
                    paddingBottom: 0,
                    justifyContent: "space-between",
                    borderBottomColor: "#ced6e182",
                  }}
                >
                  <ApRow>
                    <ApText
                      translateKey="TakeProfit"
                      style={{
                        ...theme.FONTS.H6,
                        color: theme.COLORS.mainDark,
                        marginRight: 5,
                      }}
                    />{" "}
                    <TouchableOpacity
                      onPress={() =>
                        setShowHint({
                          show: true,
                          type: "exit_settings_take_profit",
                        })
                      }
                    >
                      <InfoSvg strokeColor="#F37021" />
                    </TouchableOpacity>
                  </ApRow>

                  <TouchableOpacity
                    onPress={() => {
                      setExtraSettings(numOfReentry - 1).then((rs) => {
                        setShowTpModal(true);
                      });
                    }}
                    style={{
                      backgroundColor: "#F37021",
                      paddingVertical: 3,
                      width: 80,
                      borderRadius: 4,
                    }}
                  >
                    <ApText
                      translateKey="Select"
                      style={{
                        textAlign: "center",
                        fontSize: 12,
                        color: "white",
                      }}
                    />
                  </TouchableOpacity>
                </View>
              </View>

              <RenderTraderSetting props={props} />

              <>
                {bex_id ? (
                  <View
                    style={{
                      width: "100%",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <TouchableOpacity
                      onPress={() => {
                        setExtraSettings(numOfReentry - 1).then((rs) => {
                          setSubmitOperation("overwrite");
                          props.handleSubmit();
                          setFormData(props.values);
                        });
                      }}
                      style={{
                        width: "47%",
                        height: 40,
                        borderRadius: 8,
                        borderWidth: 1,
                        borderColor: "#F37021",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <ApText
                        translateKey="Overwrite"
                        style={{ color: "#F37021", fontWeight: "500" }}
                      />
                    </TouchableOpacity>
                    <TouchableOpacity
                      onPress={() => {
                        setSubmitOperation("save-new");
                        props.handleSubmit();
                        setFormData(props.values);
                      }}
                      style={{
                        backgroundColor: "#F37021",
                        width: "47%",
                        height: 40,
                        borderRadius: 8,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <ApText
                        translateKey="SaveNew"
                        style={{
                          color: theme.COLORS.white,
                          fontWeight: "500",
                        }}
                      />
                    </TouchableOpacity>
                  </View>
                ) : (
                  // <ApButton
                  //   onPress={() => {
                  //     setSubmitOperation("overwrite");
                  //     props.handleSubmit();
                  //     setFormData(props.values);
                  //   }}
                  //   round="lg"
                  //   labelTranslateKey="Update"
                  //   label="Update"
                  //   loading={loading}
                  //   disabled={loading}
                  // />
                  <View style={{ width: "100%" }}>
                    {selectedStrategy?.account_name === "" ? (
                      <ApButton
                        labelTranslateKey="SaveNew"
                        loading={loading && !showModal ? loading : false}
                        disabled={loading && !showModal ? loading : false}
                        style={{ width: "100%", marginTop: 0 }}
                        round="lg"
                        label="Save New"
                        onPress={() => {
                          setSubmitOperation("save-new");
                          props.handleSubmit();
                          setFormData(props.values);
                        }}
                      />
                    ) : (
                      <View
                        style={{
                          width: "100%",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <TouchableOpacity
                          onPress={() => {
                            setExtraSettings(numOfReentry - 1).then((rs) => {
                              setSubmitOperation("overwrite");
                              props.handleSubmit();
                              setFormData(props.values);
                            });
                          }}
                          style={{
                            width: "47%",
                            height: 40,
                            borderRadius: 8,
                            borderWidth: 1,
                            borderColor: "#F37021",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <ApText
                            translateKey="Overwrite"
                            style={{ color: "#F37021", fontWeight: "500" }}
                          />
                        </TouchableOpacity>

                        <TouchableOpacity
                          onPress={() => {
                            setExtraSettings(numOfReentry - 1).then((rs) => {
                              setSubmitOperation("save-new");
                              props.handleSubmit();
                              setFormData(props.values);
                            });
                          }}
                          style={{
                            backgroundColor: "#F37021",
                            width: "47%",
                            height: 40,
                            borderRadius: 8,
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <ApText
                            translateKey="SaveNew"
                            style={{
                              color: theme.COLORS.white,
                              fontWeight: "500",
                            }}
                          />
                        </TouchableOpacity>
                      </View>
                    )}
                  </View>
                )}
              </>
            </>
          )}
        </Formik>
      </ScrollView>
    );
  };

  const renderContent2 = () => {
    return (
      <ScrollView style={{ flex: 1, padding: 20 }}>
        <Formik
          enableReinitialize
          innerRef={formRef as any}
          initialValues={{
            double_init_fund:
              selectedStrategy?.double_init_fund === "0" ? false : true,
            is_peer: selectedStrategy.is_peer === "1",
            is_peer_agreed: selectedStrategy.is_peer === "1",
            macd_tp_interval: selectedStrategy?.macd_tp_interval,
            sl_perc: formatAmt(+selectedStrategy?.sl_perc * 100),
            kline_frequency: selectedStrategy?.kline_frequency,
            macd_tp_kline_frequency: selectedStrategy?.macd_tp_kline_frequency,
            label_code: selectedStrategy.id,
            target_pnl_max: (
              parseInt(botExecutorStrategySummary.target_pnl_max) -
              parseInt(botExecutorStrategySummary.target_pnl_count)
            ).toString(),
            target_pnl: botExecutorStrategySummary.target_pnl,
            direction: selectedStrategy?.direction,
            init_fund: botExecutorStrategySummary.init_fund,
            leverage: botExecutorStrategySummary.leverage,
            exec_max:
              botExecutorStrategySummary?.exec_max ===
              botExecutorStrategySummary?.exec_count
                ? "0"
                : +botExecutorStrategySummary?.exec_max -
                  +botExecutorStrategySummary?.exec_count,
            max_entries: parseInt(selectedStrategy?.max_entries) - 1,
            max_sets: selectedStrategy?.max_sets,
            waterfall_perc: formatAmt(
              parseFloat(selectedStrategy.waterfall_perc) * 100
            ),
            symbols: [],
            entry_ta_ema4: selectedStrategy?.entry_ta_ema4?.split(",")[0],
            entry_ta_macd: selectedStrategy?.entry_ta_macd?.split(",")[0],
            plan_desc: selectedStrategy.plan_desc,
            peer_fee: formatAmt(parseFloat(selectedStrategy.peer_fee) * 100),
            enable_tp_perc: selectedStrategy?.enable_tp_perc === "0" ? false : true,
            rearrange_entry_count: selectedStrategy?.rearrange_entry_count === "0" ? false : true,
            tp_perc: formatAmt(+selectedStrategy?.tp_perc * 100),
            tp_trailing_perc: formatAmt(+selectedStrategy?.tp_trailing_perc * 100),
            rsi_type: selectedStrategy?.rsi_type,
            rsi_low: selectedStrategy?.rsi_low,
            rsi_high: selectedStrategy?.rsi_high,
            ema_type: selectedStrategy?.ema_type,
            ema_low: selectedStrategy?.ema_low,
            ema_high: selectedStrategy?.ema_high,
            kdj_type: selectedStrategy?.kdj_type,
            kdj_low: selectedStrategy?.kdj_low,
            kdj_high: selectedStrategy?.kdj_high,
            init_upper_boundary: formatAmt(botExecutorStrategySummary.init_upper_boundary),
            init_lower_boundary: formatAmt(botExecutorStrategySummary.init_lower_boundary),
          }}
          onSubmit={handleSubmit}
        >
          {(props: FormikProps<any>) => (
            <>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  borderBottomColor: "#ced6e182",
                  borderBottomWidth: 1.5,
                  alignItems: "center",
                  marginBottom: 10,
                  zIndex: 99999,
                }}
              >
                <View style={{ borderBottomColor: "#ced6e182" }}>
                  <ApText
                    translateKey="Strategy"
                    style={{
                      fontSize: 14,
                      color: "#F37021",
                      fontWeight: "500",
                    }}
                  >
                    Strategy
                  </ApText>
                </View>

                <>
                  {strategyName ? (
                    <ApDropdown
                      containerStyle={{
                        flexWrap: "nowrap",
                        flexDirection: "column",
                        // wordBreak: "break-word",
                      }}
                      noBorder
                      style={{ borderBottomWidth: 0 }}
                      onChange={(e: any) => {
                        handleSelectedStrategy(e);
                      }}
                      // defaultValue={strategyName}
                      //placeholder="Stable Strategy"
                      // label={strategyName}
                      name="label_code"
                      placeholder={t("ChooseAStrategy")}
                      items={items.filter((itm) => itm.account_name)}
                    />
                  ) : (
                    <></>
                  )}
                </>
              </View>

              {bex_id && (
                <>
                  <ApText
                    translateKey="ExecutionSettings"
                    style={{
                      paddingTop: 10,
                      fontSize: 14,
                      color: "#F37021",
                      fontWeight: "500",
                      paddingBottom: 10,
                    }}
                  />

                  <View
                    style={{
                      marginBottom: 20,
                      padding: 15,
                      backgroundColor: "#FCF8F6",
                      borderRadius: 10,
                    }}
                  >
                    <View
                      style={{
                        borderBottomWidth: 1,
                        paddingBottom: 12,
                        borderBottomColor: "#ced6e182",
                      }}
                    >
                      <View
                        style={{
                          alignItems: "flex-start",
                        }}
                      >
                        <ApText
                          translateKey="Symbol"
                          style={{
                            ...theme.FONTS.H6,
                            color: theme.COLORS.mainDark,
                            marginBottom: 10,
                          }}
                        >
                          Symbol
                        </ApText>

                        <ApText
                          style={{
                            backgroundColor: "#F37021",
                            color: "#fff",
                            paddingVertical: 1,
                            paddingHorizontal: 10,
                            fontSize: 11,
                            borderRadius: 5,
                          }}
                        >
                          {botExecutorStrategySummary.symbol}
                        </ApText>
                      </View>
                    </View>

                    <ApTextInput
                      border
                      width={130}
                      height={25}
                      labelTranslateKey="FirstOrderMargin"
                      label="First Order Margin"
                      labelStyle={style.inputLabel}
                      name="init_fund"
                      inputStyle={{
                        ...style.textInput,
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                      }}
                      rightIcon={
                        <ApText
                          translateKey="USDT"
                          style={{
                            backgroundColor: "#F37021",
                            color: "#FFF",
                            fontSize: 10,
                            paddingHorizontal: 8,
                            paddingVertical: 0.5,
                            borderTopRightRadius: 6,
                            borderBottomRightRadius: 6,
                          }}
                        >
                          USDT
                        </ApText>
                      }
                    />

                    {market !== "1" && (
                      <ApLeverageInput
                        rightIcon={
                          <ApText
                            style={{
                              backgroundColor: "#F37021",
                              color: "#FFF",
                              fontSize: 10,
                              paddingHorizontal: 10,
                              paddingVertical: 0.5,
                              borderTopRightRadius: 6,
                              borderBottomRightRadius: 6,
                            }}
                          >
                            X
                          </ApText>
                        }
                        border
                        width={130}
                        height={25}
                        label="Leverage"
                        labelTranslateKey="Leverage"
                        labelStyle={style.inputLabel}
                        name="leverage"
                        inputStyle={{
                          ...style.textInput,
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                        }}
                      />
                    )}

                    <ApToggleInput
                      onToggle={(val) => {
                        setAllowTargetProfit(val);
                      }}
                      noBorder={!allowTargetProfit ? true : false}
                      labelTranslateKey="AllowTargetProfit"
                      defaultValue={allowTargetProfit}
                      label="Allow Target Profit"
                      name="atp"
                      labelStyle={style.inputLabel}
                    />

                    {allowTargetProfit && (
                      <>
                        <ApTextInput
                          rightIcon={
                            <ApText
                              translateKey="USDT"
                              style={{
                                backgroundColor: "#F37021",
                                color: "#FFF",
                                fontSize: 10,
                                paddingHorizontal: 8,
                                paddingVertical: 0.5,
                                borderTopRightRadius: 6,
                                borderBottomRightRadius: 6,
                              }}
                            />
                          }
                          border
                          width={130}
                          height={25}
                          labelTranslateKey="TargetProfit"
                          label="Target Profit"
                          labelStyle={style.inputLabel}
                          name="target_pnl"
                          inputStyle={{
                            ...style.textInput,
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                          }}
                        />

                        <ApTextInput
                          rightIcon={
                            <ApText
                              translateKey="Rounds"
                              style={{
                                backgroundColor: "#F37021",
                                color: "#FFF",
                                fontSize: 10,
                                paddingHorizontal: 8,
                                paddingVertical: 0.5,
                                borderTopRightRadius: 6,
                                borderBottomRightRadius: 6,
                              }}
                            />
                          }
                          border
                          width={130}
                          height={25}
                          labelTranslateKey="RemainingTargetProfit"
                          label="Remaining Target Profit"
                          labelStyle={style.inputLabel}
                          name="target_pnl_max"
                          containerStyle={{
                            borderBottomColor: "#FCF8F6",
                            paddingBottom: 0,
                          }}
                          inputStyle={{
                            ...style.textInput,
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                          }}
                        />
                      </>
                    )}
                  </View>
                </>
              )}

              <ApText
                translateKey="EntrySettings"
                style={{
                  fontSize: 14,
                  color: "#F37021",
                  fontWeight: "500",
                  paddingBottom: 10,
                }}
              />
              <View
                style={{
                  marginBottom: 20,
                  padding: 15,
                  paddingVertical: 0,
                  backgroundColor: "#FCF8F6",
                  borderRadius: 10,
                }}
              >
                <View style={{ zIndex: 9999 }}>
                  <ApDropdown
                    labelIcon={
                      <TouchableOpacity
                        onPress={() =>
                          setShowHint({
                            show: true,
                            type: "direction",
                          })
                        }
                      >
                        <InfoSvg strokeColor="#F37021" />
                      </TouchableOpacity>
                    }
                    noBorder
                    label="Direction"
                    name="direction"
                    placeholder="Direction"
                    labelTranslateKey="Direction"
                    items={
                      selectedStrategy.market === "1"
                        ? MasterData?.SPOT_DIRECTION_TYPES
                        : selectedStrategy.plan_type == "1"
                        ? MasterData?.PT_ONE_DIRECTION_TYPES
                        : MasterData?.DIRECTION_TYPES
                    }
                  />
                </View>
              </View>

              <ApText
                translateKey="OrderSettings"
                style={{
                  fontSize: 14,
                  color: "#F37021",
                  fontWeight: "500",
                  paddingBottom: 10,
                }}
              />
              <View
                style={{
                  marginBottom: 20,
                  padding: 15,
                  paddingTop: 0,
                  backgroundColor: "#FCF8F6",
                  borderRadius: 10,
                  display: "flex",
                  flexDirection: "column",
                  zIndex: -1,
                }}
              >
                <ApTextInput
                  rightIcon={
                    <ApText
                      translateKey="Orders"
                      style={{
                        backgroundColor: "#F37021",
                        color: "#FFF",
                        fontSize: 10,
                        paddingHorizontal: 8,
                        paddingVertical: 0.5,
                        borderTopRightRadius: 6,
                        borderBottomRightRadius: 6,
                      }}
                    />
                  }
                  border
                  width={130}
                  height={25}
                  onChange={(e: any) => {
                    if (!isNaN(+e)) {
                      setMaxSets(+e);
                    } else props.setFieldValue("max_sets", parseInt(e));
                  }}
                  labelTranslateKey="NumberOfOrders"
                  labelStyle={style.inputLabel}
                  name="max_sets"
                  inputStyle={{
                    ...style.textInput,
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                  }}
                />

                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    paddingVertical: 12,
                    paddingBottom: 0,
                    justifyContent: "space-between",
                    borderBottomColor: "#ced6e182",
                  }}
                >
                  <ApRow>
                    <ApText
                      translateKey="OrderSettings"
                      style={{
                        ...theme.FONTS.H6,
                        color: theme.COLORS.mainDark,
                        marginRight: 5,
                      }}
                    />{" "}
                    <TouchableOpacity
                      onPress={() =>
                        setShowHint({
                          show: true,
                          type: "order_settings",
                        })
                      }
                    >
                      <InfoSvg strokeColor="#F37021" />
                    </TouchableOpacity>
                  </ApRow>

                  <TouchableOpacity
                    onPress={() => {
                      generateOrderSettings(
                        maxSets || selectedStrategy?.max_sets,
                        selectedStrategy as any
                      ).then((rs) => {
                        setShowOrderSettings(true);
                      });
                    }}
                    style={{
                      backgroundColor: "#F37021",
                      paddingVertical: 3,
                      width: 80,
                      borderRadius: 4,
                    }}
                  >
                    <ApText
                      translateKey="Select"
                      style={{
                        textAlign: "center",
                        fontSize: 12,
                        color: "white",
                      }}
                    />
                  </TouchableOpacity>
                </View>
              </View>

              <ApText
                translateKey="IndicatorSettings"
                style={{
                  fontSize: 14,
                  color: "#F37021",
                  fontWeight: "500",
                  paddingBottom: 10,
                }}
              />
              <View
                style={{
                  marginBottom: 20,
                  padding: 15,
                  paddingTop: 0,
                  backgroundColor: "#FCF8F6",
                  borderRadius: 10,
                }}
              >
                <View style={{ zIndex: 300 }}>
                  <ApDropdown
                    label="Kline Monitoring Interval for MACD"
                    name="kline_frequency"
                    labelTranslateKey="KlineMonitoringIntervalforMACD"
                    labelStyle={{ width: "50%" }}
                    items={MasterData?.INTERVAL_FREQUENCY_TYPES}
                  />
                </View>

                <View
                  style={{
                    paddingBottom: 13,
                    borderBottomWidth: 1,
                    flexDirection: "row",
                    alignItems: "center",
                    paddingVertical: 12,
                    justifyContent: "space-between",
                    borderBottomColor: "#ced6e182",
                  }}
                >
                  <ApText
                    translateKey="MACDMode"
                    style={{
                      ...theme.FONTS.H6,
                      color: theme.COLORS.mainDark,
                    }}
                  />

                  <TouchableOpacity
                    onPress={() => {
                      setExtraSettings(numOfReentry - 1).then((rs) => {
                        setShowMacdTypeModal(true);
                      });
                    }}
                    style={{
                      backgroundColor: "#F37021",
                      paddingVertical: 3,
                      width: 80,
                      borderRadius: 4,
                    }}
                  >
                    <ApText
                      translateKey="Select"
                      style={{
                        textAlign: "center",
                        fontSize: 12,
                        color: "white",
                      }}
                    />
                  </TouchableOpacity>
                </View>

                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    paddingVertical: 12,
                    paddingBottom: 13,
                    justifyContent: "space-between",
                    borderBottomColor: "#ced6e182",
                    borderBottomWidth: 1,
                  }}
                >
                  <ApText
                    translateKey="IndividualKlineIntervalSettings"
                    style={{
                      ...theme.FONTS.H6,
                      color: theme.COLORS.mainDark,
                    }}
                  />

                  <TouchableOpacity
                    onPress={() => {
                      setExtraSettings(numOfReentry - 1).then((rs) => {
                        setShowKlineModal(true);
                      });
                    }}
                    style={{
                      backgroundColor: "#F37021",
                      paddingVertical: 3,
                      width: 80,
                      borderRadius: 4,
                    }}
                  >
                    <ApText
                      translateKey="Select"
                      style={{
                        textAlign: "center",
                        fontSize: 12,
                        color: "white",
                      }}
                    />
                  </TouchableOpacity>
                </View>

                <View
                  style={{
                    paddingBottom: 13,
                    borderBottomWidth: 1,
                    flexDirection: "row",
                    alignItems: "center",
                    paddingVertical: 12,
                    justifyContent: "space-between",
                    borderBottomColor: "#ced6e182",
                  }}
                >
                  <ApText
                    translateKey="EMA"
                    style={{
                      ...theme.FONTS.H6,
                      color: theme.COLORS.mainDark,
                    }}
                  />

                  <TouchableOpacity
                    onPress={() => {
                      setExtraSettings(numOfReentry - 1).then((rs) => {
                        setShowEmaModal(true);
                      });
                    }}
                    style={{
                      backgroundColor: "#F37021",
                      paddingVertical: 3,
                      width: 80,
                      borderRadius: 4,
                    }}
                  >
                    <ApText
                      translateKey="Select"
                      style={{
                        textAlign: "center",
                        fontSize: 12,
                        color: "white",
                      }}
                    />
                  </TouchableOpacity>
                </View>

                <View
                  style={{
                    paddingBottom: 13,
                    borderBottomWidth: 1,
                    flexDirection: "row",
                    alignItems: "center",
                    paddingVertical: 12,
                    justifyContent: "space-between",
                    borderBottomColor: "#ced6e182",
                  }}
                >
                  <ApText
                    translateKey="RSI"
                    style={{
                      ...theme.FONTS.H6,
                      color: theme.COLORS.mainDark,
                    }}
                  />

                  <TouchableOpacity
                    onPress={() => {
                      setExtraSettings(numOfReentry - 1).then((rs) => {
                        setShowRsiModal(true);
                      });
                    }}
                    style={{
                      backgroundColor: "#F37021",
                      paddingVertical: 3,
                      width: 80,
                      borderRadius: 4,
                    }}
                  >
                    <ApText
                      translateKey="Select"
                      style={{
                        textAlign: "center",
                        fontSize: 12,
                        color: "white",
                      }}
                    />
                  </TouchableOpacity>
                </View>

                <View
                  style={{
                    // borderBottomWidth: 1,
                    flexDirection: "row",
                    alignItems: "center",
                    paddingVertical: 12,
                    paddingBottom: 0,
                    justifyContent: "space-between",
                    borderBottomColor: "#ced6e182",
                  }}
                >
                  <ApText
                    translateKey="KDJ"
                    style={{
                      ...theme.FONTS.H6,
                      color: theme.COLORS.mainDark,
                    }}
                  />

                  <TouchableOpacity
                    onPress={() => {
                      setExtraSettings(numOfReentry - 1).then((rs) => {
                        setShowKdjModal(true);
                      });
                    }}
                    style={{
                      backgroundColor: "#F37021",
                      paddingVertical: 3,
                      width: 80,
                      borderRadius: 4,
                    }}
                  >
                    <ApText
                      translateKey="Select"
                      style={{
                        textAlign: "center",
                        fontSize: 12,
                        color: "white",
                      }}
                    />
                  </TouchableOpacity>
                </View>
              </View>

              <ApText
                translateKey="ExitSettings"
                style={{
                  fontSize: 14,
                  color: "#F37021",
                  fontWeight: "500",
                  paddingBottom: 10,
                }}
              />
              <View
                style={{
                  zIndex: -100,
                  marginBottom: 20,
                  padding: 15,
                  paddingTop: 0,
                  backgroundColor: "#FCF8F6",
                  borderRadius: 10,
                }}
              >
                {/* <ApToggleInput
                  labelTranslateKey="EnableFullTP"
                  defaultValue={
                    bex_id ? botExecutorStrategySummary?.enable_tp_perc == "1" ? true : false
                    : selectedStrategy?.enable_tp_perc == "1" ? true : false
                  }
                  label="Enable Full TP"
                  name="enable_tp_perc"
                  labelStyle={style.inputLabel}
                  labelIcon={
                    <TouchableOpacity
                      onPress={() =>
                        setShowHint({
                          show: true,
                          type: "enable_tp_perc",
                        })
                      }
                    >
                      <InfoSvg strokeColor="#F37021" />
                    </TouchableOpacity>
                  }
                /> */}
                
                {props?.values?.enable_tp_perc == true && (
                  <>                  
                    <ApTextInput
                      rightIcon={
                        <ApText
                          style={{
                            backgroundColor: "#F37021",
                            color: "#FFF",
                            fontSize: 11,
                            paddingHorizontal: 10,
                            paddingVertical: 0.5,
                            borderTopRightRadius: 6,
                            borderBottomRightRadius: 6,
                          }}
                        >
                          %
                        </ApText>
                      }
                      border
                      width={130}
                      height={25}
                      defaultValue={
                        bex_id
                          ? formatAmt(+botExecutorStrategySummary.tp_perc * 100)
                          : formatAmt(+selectedStrategy?.tp_perc * 100)
                      }
                      label="Full TP Percentage"
                      labelTranslateKey="FullTPPercentage"
                      labelStyle={style.inputLabel}
                      name="tp_perc"
                      inputStyle={{
                        ...style.textInput,
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                      }}
                    />
    
                    <ApTextInput
                      rightIcon={
                        <ApText
                          style={{
                            backgroundColor: "#F37021",
                            color: "#FFF",
                            fontSize: 11,
                            paddingHorizontal: 10,
                            paddingVertical: 0.5,
                            borderTopRightRadius: 6,
                            borderBottomRightRadius: 6,
                          }}
                        >
                          %
                        </ApText>
                      }
                      border
                      width={130}
                      height={25}
                      defaultValue={
                        bex_id
                          ? formatAmt(+botExecutorStrategySummary.tp_trailing_perc * 100)
                          : formatAmt(+selectedStrategy?.tp_trailing_perc * 100)
                      }
                      label="Full Trailing TP"
                      labelTranslateKey="FullTrailingTP"
                      labelStyle={style.inputLabel}
                      name="tp_trailing_perc"
                      inputStyle={{
                        ...style.textInput,
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                      }}
                    />
                  </>
                )}

                <ApTextInput
                  labelIcon={
                    <TouchableOpacity
                      onPress={() =>
                        setShowHint({
                          show: true,
                          type: "exit_settings_stop_loss",
                        })
                      }
                    >
                      <InfoSvg strokeColor="#F37021" />
                    </TouchableOpacity>
                  }
                  rightIcon={
                    <ApText
                      style={{
                        backgroundColor: "#F37021",
                        color: "#FFF",
                        fontSize: 11,
                        paddingHorizontal: 10,
                        paddingVertical: 0.5,
                        borderTopRightRadius: 6,
                        borderBottomRightRadius: 6,
                      }}
                    >
                      %
                    </ApText>
                  }
                  border
                  width={130}
                  height={25}
                  defaultValue={
                    bex_id
                      ? formatAmt(+botExecutorStrategySummary.sl_perc * 100)
                      : formatAmt(+selectedStrategy?.sl_perc * 100)
                  }
                  label="Stop Loss"
                  labelTranslateKey="StopLoss"
                  labelStyle={style.inputLabel}
                  name="sl_perc"
                  inputStyle={{
                    ...style.textInput,
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                  }}
                />

                {/* <ApToggleInput
                  labelTranslateKey="RearrangeEntryCount"
                  defaultValue={
                    bex_id ? botExecutorStrategySummary?.rearrange_entry_count == "1" ? true : false
                    : selectedStrategy?.rearrange_entry_count == "1" ? true : false
                  }
                  label="Rearrange Entry Count"
                  name="rearrange_entry_count"
                  labelStyle={style.inputLabel}
                  labelIcon={
                    <TouchableOpacity
                      onPress={() =>
                        setShowHint({
                          show: true,
                          type: "rearrange_entry_count",
                        })
                      }
                    >
                      <InfoSvg strokeColor="#F37021" />
                    </TouchableOpacity>
                  }
                /> */}

                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    paddingVertical: 12,
                    paddingBottom: 0,
                    justifyContent: "space-between",
                    borderBottomColor: "#ced6e182",
                  }}
                >
                  <ApRow>
                    <ApText
                      translateKey="TakeProfit"
                      style={{
                        ...theme.FONTS.H6,
                        color: theme.COLORS.mainDark,
                        marginRight: 5,
                      }}
                    />{" "}
                    <TouchableOpacity
                      onPress={() =>
                        setShowHint({
                          show: true,
                          type: "exit_settings_take_profit",
                        })
                      }
                    >
                      <InfoSvg strokeColor="#F37021" />
                    </TouchableOpacity>
                  </ApRow>

                  <TouchableOpacity
                    onPress={() => {
                      setExtraSettings(numOfReentry - 1).then((rs) => {
                        setShowTpModal(true);
                      });
                    }}
                    style={{
                      backgroundColor: "#F37021",
                      paddingVertical: 3,
                      width: 80,
                      borderRadius: 4,
                    }}
                  >
                    <ApText
                      translateKey="Select"
                      style={{
                        textAlign: "center",
                        fontSize: 12,
                        color: "white",
                      }}
                    />
                  </TouchableOpacity>
                </View>
              </View>

              <RenderTraderSetting props={props}/>
              <>
                {bex_id ? (
                  <View
                    style={{
                      width: "100%",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <TouchableOpacity
                      onPress={() => {
                        generateOrderSettings(
                          maxSets || selectedStrategy?.max_sets,
                          selectedStrategy as any
                        ).then((rs) => {
                          setSubmitOperation("overwrite");
                          props.handleSubmit();
                          setFormData(props.values);
                        });
                      }}
                      style={{
                        width: "47%",
                        height: 40,
                        borderRadius: 8,
                        borderWidth: 1,
                        borderColor: "#F37021",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <ApText
                        translateKey="Overwrite"
                        style={{ color: "#F37021", fontWeight: "500" }}
                      />
                    </TouchableOpacity>
                    <TouchableOpacity
                      onPress={() => {
                        generateOrderSettings(
                          maxSets || selectedStrategy?.max_sets,
                          selectedStrategy as any
                        ).then((rs) => {
                          setSubmitOperation("save-new");
                          props.handleSubmit();
                          setFormData(props.values);
                        });
                      }}
                      style={{
                        backgroundColor: "#F37021",
                        width: "47%",
                        height: 40,
                        borderRadius: 8,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <ApText
                        translateKey="SaveNew"
                        style={{
                          color: theme.COLORS.white,
                          fontWeight: "500",
                        }}
                      />
                    </TouchableOpacity>
                  </View>
                ) : (
                  <View style={{ width: "100%" }}>
                    {selectedStrategy?.account_name === "" ? (
                      <ApButton
                        labelTranslateKey="SaveNew"
                        loading={loading && !showModal ? loading : false}
                        disabled={loading && !showModal ? loading : false}
                        style={{ width: "100%", marginTop: 0 }}
                        round="lg"
                        label="Save New"
                        onPress={() => {
                          generateOrderSettings(
                            maxSets || selectedStrategy?.max_sets,
                            selectedStrategy as any
                          ).then((rs) => {
                            setSubmitOperation("save-new");
                            props.handleSubmit();
                            setFormData(props.values);
                          });
                        }}
                      />
                    ) : (
                      <View
                        style={{
                          width: "100%",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <TouchableOpacity
                          onPress={() => {
                            generateOrderSettings(
                              maxSets || selectedStrategy?.max_sets,
                              selectedStrategy as any
                            ).then((rs) => {
                              setSubmitOperation("overwrite");
                              props.handleSubmit();
                              setFormData(props.values);
                            });
                          }}
                          style={{
                            width: "47%",
                            height: 40,
                            borderRadius: 8,
                            borderWidth: 1,
                            borderColor: "#F37021",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <ApText
                            translateKey="Overwrite"
                            style={{ color: "#F37021", fontWeight: "500" }}
                          />
                        </TouchableOpacity>

                        <TouchableOpacity
                          onPress={() => {
                            generateOrderSettings(
                              maxSets || selectedStrategy?.max_sets,
                              selectedStrategy as any
                            ).then((rs) => {
                              setSubmitOperation("save-new");
                              props.handleSubmit();
                              setFormData(props.values);
                            });
                          }}
                          style={{
                            backgroundColor: "#F37021",
                            width: "47%",
                            height: 40,
                            borderRadius: 8,
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <ApText
                            translateKey="SaveNew"
                            style={{
                              color: theme.COLORS.white,
                              fontWeight: "500",
                            }}
                          />
                        </TouchableOpacity>
                      </View>
                    )}
                  </View>
                )}
              </>
            </>
          )}
        </Formik>
      </ScrollView>
    );
  };

  const RenderTraderSetting = ({props}) => (
    <>
      {profileData.is_trader === 1 && (
        <>
          <ApText
            translateKey="TraderSetting"
            style={{
              fontSize: 14,
              color: "#F37021",
              fontWeight: "500",
              paddingBottom: 10,
            }}
          />
          <View
            style={{
              marginBottom: 20,
              padding: 15,
              paddingTop: 10,
              backgroundColor: "#FCF8F6",
              borderRadius: 10,
            }}
          >
            <ApText
              style={{
                color: "#F37021",
                fontWeight: "500",
                fontSize: 12,
                marginBottom: 20,
              }}
              translateKey="TraderSettingsNote"
            />
            <View
              style={{
                flex: 1,
                flexDirection: "row",
                width: "100%",
                marginBottom: 20,
                justifyContent: "center",
              }}
            >
              <ApTextInput
                defaultValue={
                  bex_id
                    ? botExecutorStrategySummary.plan_desc
                    : selectedStrategy.plan_desc
                }
                textArea
                placeHolderTranslateKey="TraderComment"
                placeholder="Comment..."
                name="plan_desc"
                inputStyle={{
                  backgroundColor: "#DFE3EA",
                  width: "100%",
                  height: 120,
                  color: "#F37021",
                  textAlign: "left",
                  padding: 15,
                  fontSize: 13,
                  borderRadius: 6,
                }}
              />
            </View>

            <ApToggleInput
              defaultValue={selectedStrategy.is_peer === "1"}
              onToggle={(e: any) => {
                setShareStrategy(e);
              }}
              label="Share Strategy"
              name="is_peer"
              labelTranslateKey="ShareStrategy"
              labelStyle={style.inputLabel}
            />

            {shareStrategy ? (
              <View
                style={{
                  marginTop: 10,
                }}
              >
                <ApCheckInput
                  defaultValue={selectedStrategy.is_peer === "1"}
                  name="is_peer_agreed"
                  labelTranslateKey="AgreeShareStrategy"
                  label="I hereby acknowledged and agreed to allow all users in this
                  platform to use my shared strategies"
                  labelStyle={{
                    fontSize: 11,
                    color: "#F37021",
                    marginLeft: "10px",
                  }}
                />
                {props?.values?.is_peer &&
                !props?.values?.is_peer_agreed &&
                submitted ? (
                  <ApText size="xs" color="red" translateKey="AgreeTerms" />
                ) : (
                  <></>
                )}
              </View>
            ) : (
              <></>
            )}

            <ApRow>
              <ApRow style={{ marginTop: 10 }}>
                <ApText
                  translateKey="CommissionPercentage"
                  style={{
                    textAlign: "center",
                    fontSize: 12,
                    color: "#F37021",
                    marginRight: 5,
                  }}
                />
                <TouchableOpacity
                  onPress={() =>
                    setShowHint({ show: true, type: "commission_perc" })
                  }
                >
                  <InfoSvg strokeColor="#F37021" />
                </TouchableOpacity>
              </ApRow>

              <ApTextInput
                containerStyle={{
                  borderBottomColor: "#FCF8F6",
                  paddingBottom: 0,
                  width: "unset",
                }}
                rightIcon={
                  <ApText
                    style={{
                      backgroundColor: "#F37021",
                      color: "#FFF",
                      fontSize: 11,
                      paddingHorizontal: 10,
                      paddingVertical: 0.5,
                      borderTopRightRadius: 6,
                      borderBottomRightRadius: 6,
                    }}
                  >
                    %
                  </ApText>
                }
                defaultValue={formatAmt(
                  parseFloat(selectedStrategy.peer_fee) * 100
                )}
                border
                width={130}
                height={25}
                labelStyle={{ display: "none" }}
                name="peer_fee"
                inputStyle={{
                  ...style.textInput,
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                }}
              />
            </ApRow>
          </View>
        </>
      )}
    </>
  );

  return (
    <View>
      <View style={{ flex: 1 }}>
        <ApSafeAreaView>
          {renderHeader()}
          {selectedStrategy.plan_type !== "1"
            ? renderContent()
            : renderContent2()}
          {renderStrategyInfo()}
          {renderSurplusWay()}
          {renderStrategyDirection()}
        </ApSafeAreaView>
      </View>

      {showHint && (
        <ApModalAlt
          fallbackRoute="EditStrategy"
          transparent={true}
          visible={showHint.show}
        >
          <>
            <TouchableOpacity
              onPress={() => {
                setShowHint({ show: false });
              }}
              style={{ alignItems: "flex-end", padding: 15, paddingBottom: 0 }}
            >
              <svg.closeSvg />
            </TouchableOpacity>

            <View
              style={{
                width: "100%",
                paddingTop: 0,
                paddingVertical: 20,
                paddingHorizontal: 20,
              }}
            >
              {showHint.type === "exit_settings_take_profit_macd" && (
                <>
                  <ApText
                    style={{
                      fontSize: 16,
                      textAlign: "center",
                      color: "#F37021",
                      fontWeight: "500",
                      marginBottom: 10,
                    }}
                    translateKey="TPMIHeadText"
                  />

                  <View>
                    <ApText
                      style={{ fontSize: 14, marginBottom: 10 }}
                      translateKey="TPMITextOne"
                    />
                    <View
                      style={{ flexDirection: "row", alignItems: "center" }}
                    >
                      <ApText style={{ marginTop: 0 }}>{"\u2022"}</ApText>
                      <ApText
                        style={{ marginTop: 5, marginLeft: 3, fontSize: 12 }}
                        translateKey="TPMITextTwo"
                      />
                    </View>

                    <View
                      style={{ flexDirection: "row", alignItems: "center" }}
                    >
                      <ApText style={{ marginTop: 0 }}>{"\u2022"}</ApText>
                      <ApText
                        style={{ marginTop: 5, marginLeft: 3, fontSize: 12 }}
                        translateKey="TPMITextThree"
                      />
                    </View>
                    <ApText
                      style={{ fontSize: 14, marginTop: 10, marginBottom: 10 }}
                      translateKey="TPMITextFour"
                    />
                  </View>
                </>
              )}
              <ApText style={{ marginTop: 5, marginLeft: 3, fontSize: 14 }}>
                {showHint.type === "exit_settings_take_profit_mode" &&
                  t("TakeProfitModeHint")}
                {showHint.type === "exit_settings_take_profit" &&
                  t("TakeProfitHint")}
                {showHint.type === "commission_perc" &&
                  t("CommissionPercentageHint")}
                {showHint.type === "order_settings" && t("OrderSettingsHint")}
                {showHint.type === "direction" && t("DirectionHint")}
                {showHint.type === "leverage" && t("LeverageHint")}
                {showHint.type === "rearrange_entry_count" && t("RearrangeEntryCountHint")}
                {showHint.type === "enable_tp_perc" && t("EnableFullTPHint")}
                {showHint.type === "exit_settings_stop_loss" &&
                  t("StopLossHint")}
                {showHint.type === "on_trend_margin_multiplier" &&
                  t("OnTrendMarginHint")}
              </ApText>
            </View>
          </>
        </ApModalAlt>
      )}

      {showModal && (
        <ApModalAlt
          fallbackRoute="EditStrategy"
          transparent={true}
          visible={showModal}
        >
          <>
            <TouchableOpacity
              onPress={() => {
                setShowModal(false);
              }}
              style={{ alignItems: "flex-end", padding: 15, paddingBottom: 0 }}
            >
              <svg.closeSvg />
            </TouchableOpacity>

            <View
              style={{
                width: "100%",
                paddingTop: 0,
                paddingVertical: 20,
                paddingHorizontal: 20,
              }}
            >
              <ApText
                size="xl"
                style={{
                  textAlign: "center",
                  color: "#F37021",
                  fontWeight: "500",
                  marginBottom: 20,
                }}
                translateKey="NewStrategyName"
              />
              <View
                style={[
                  {
                    borderColor: theme.COLORS.transparent,
                    // height: 45,
                    // width: "100%",
                    borderRadius: 10,
                    borderWidth: 1,
                    flexDirection: "row",
                    alignItems: "center",
                  },
                ]}
              >
                <TextInput
                  placeholder={t("PlaceHolderNewStrategyName")}
                  style={[
                    {
                      height: 45,
                      borderColor: "#DFE3EA",
                      backgroundColor: "#FCF8F6",
                      width: "100%",
                      color: "#F37021",
                      textAlign: "center",
                      fontSize: 13,
                      padding: 3,
                      borderRadius: 6,
                      ...theme.FONTS.Mulish_400Regular,
                    },
                  ]}
                  placeholderTextColor={"#c6c6c6"}
                  keyboardType="default"
                  numberOfLines={1}
                  onChangeText={(e) => {
                    setLabelCode(e);
                  }}
                />
              </View>

              <View
                style={{
                  width: "100%",
                  marginTop: 20,
                  flexDirection: "row",
                  //paddingVertical: 20,
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <TouchableOpacity
                  style={{
                    borderWidth: 1,
                    borderColor: "#F37021",
                    width: "47%",
                    height: 40,
                    borderRadius: 5,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onPress={() => {
                    setShowModal(false);
                  }}
                >
                  <ApText
                    style={{ color: "#F37021", fontWeight: "500" }}
                    translateKey="Cancel"
                  />
                </TouchableOpacity>

                <TouchableOpacity
                  style={{
                    backgroundColor: "#F37021",
                    width: "47%",
                    height: 40,
                    borderRadius: 5,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onPress={() => {
                    create(formData);
                  }}
                >
                  <ApText
                    style={{ color: theme.COLORS.white, fontWeight: "500" }}
                    translateKey="Save"
                  />
                </TouchableOpacity>
              </View>
            </View>
          </>
        </ApModalAlt>
      )}

      {showOvrwModal && (
        <ApModalAlt
          fallbackRoute="EditStrategy"
          transparent={true}
          visible={showOvrwModal}
        >
          <>
            <TouchableOpacity
              onPress={() => {
                setShowOvrwModal(false);
              }}
              style={{ alignItems: "flex-end", padding: 15, paddingBottom: 0 }}
            >
              <svg.closeSvg />
            </TouchableOpacity>

            <View
              style={{
                paddingTop: 0,
                paddingVertical: 20,
                alignItems: "center",
                paddingHorizontal: 20,
              }}
            >
              <ApText
                size="lg"
                style={{
                  marginTop: -5,
                  color: "#F37021",
                  fontWeight: "bold",
                  marginBottom: 5,
                }}
                translateKey="Notice"
              >
                Notice
              </ApText>
              <ApText
                size="sm"
                style={{ textAlign: "center" }}
                translateKey="OverwriteStrategyNote"
              ></ApText>

              <View
                style={{
                  width: "100%",
                  marginTop: 30,
                  flexDirection: "row",
                  //paddingVertical: 20,
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <TouchableOpacity
                  style={{
                    marginRight: 10,
                    borderWidth: 1,
                    borderColor: "#F37021",
                    width: "47%",
                    height: 38,
                    borderRadius: 5,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onPress={() => {
                    setShowOvrwModal(false);
                  }}
                >
                  <ApText
                    style={{ color: "#F37021", fontWeight: "500" }}
                    translateKey="Cancel"
                  />
                </TouchableOpacity>

                <TouchableOpacity
                  style={{
                    backgroundColor: "#F37021",
                    width: "47%",
                    height: 38,
                    borderRadius: 5,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onPress={() => {
                    handleSave(formData);
                  }}
                >
                  <ApText
                    style={{ color: theme.COLORS.white, fontWeight: "500" }}
                    translateKey="Confirm"
                  />
                </TouchableOpacity>
              </View>
            </View>
          </>
        </ApModalAlt>
      )}

      {showOrderSettings && (
        <OrderSettings
          setVisible={setShowOrderSettings}
          visible={showOrderSettings}
        />
      )}

      {showKlineModal && (
        <KlineSetting setVisible={setShowKlineModal} visible={showKlineModal} />
      )}

      {showMacdTypeModal && (
        <MacdModeSetting
          setVisible={setShowMacdTypeModal}
          visible={showMacdTypeModal}
        />
      )}

      {showRsiModal && (
        <RsiSetting
          setVisible={setShowRsiModal}
          visible={showRsiModal}
          mainForm={formRef}
        />
      )}

      {showEmaModal && (
        <EmaSetting
          setVisible={setShowEmaModal}
          visible={showEmaModal}
          mainForm={formRef}
        />
      )}

      {showKdjModal && (
        <KdjSetting
          setVisible={setShowKdjModal}
          visible={showKdjModal}
          mainForm={formRef}
        />
      )}

      {showOnTrendModal && (
        <OnTrendMargin
          setVisible={setShowOnTrendModal}
          visible={showOnTrendModal}
        />
      )}

      {showMarginModal && (
        <MarginSetting
          setVisible={setShowMarginModal}
          visible={showMarginModal}
        />
      )}

      {showTpModal && (
        <TakeProfitSettings setVisible={setShowTpModal} visible={showTpModal} />
      )}

      {showTpModeModal && (
        <TakeProfitModeSetting
          setVisible={setShowTpModeModal}
          visible={showTpModeModal}
        />
      )}
    </View>
  );
};

export default EditStrategy;

const style = StyleSheet.create({
  inputLabel: {
    ...theme.FONTS.H6,
    color: theme.COLORS.mainDark,
    // width: "50%",
  },
  textInput: {
    backgroundColor: "#DFE3EA",
    width: "20%",
    color: "#F37021",
    textAlign: "center",
    fontSize: 13,
    borderRadius: 6,
  },
});
