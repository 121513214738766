import { useField } from "formik";
import React, { useEffect, useState } from "react";
import { TextStyle, TouchableOpacity, View } from "react-native";
import { theme } from "../../../constants";
import { ApText } from "../typography";

interface IProps {
  noBorder?: boolean
  defaultValue?: boolean
  onToggle?:any
  name: string;
  labelIcon?: React.ReactNode;
  label?: string;
  placeholder?: string;
  labelStyle?: TextStyle;
  labelTranslateKey?: string;
  disabled?: boolean
}

export const ApToggleInput: React.FC<IProps> = ({
  noBorder,
  defaultValue,
  onToggle,
  label,
  name,
  labelStyle,
  labelTranslateKey,
  disabled,
  labelIcon
}) => {
  const [toggle, setToggle] = useState(false);
  const [field, meta, { setValue }] = useField(name);

  useEffect(() => {
    setToggle(defaultValue ? defaultValue : false)
    setValue(defaultValue)
  }, [defaultValue])

  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        borderBottomWidth: noBorder ? 0 : 1,
        paddingVertical: 12,
        paddingBottom: noBorder ? 0 : 12,
        borderBottomColor: "#ced6e182",
        width: "100%",
        justifyContent: "space-between",
      }}
    >
      {label && (
        <View
          style={[
            { flexDirection: "row", alignItems: "center", width: "auto" },
            labelStyle,
          ]}
        >
          <ApText translateKey={labelTranslateKey} style={labelStyle}>{label}</ApText>

          {labelIcon && (
            <View style={{ marginLeft: 8 }}>{labelIcon}</View>
          )}
        </View>
      )}

      <TouchableOpacity
        style={{
          width: 40,
          marginLeft: "auto",
          backgroundColor: toggle ? theme.COLORS.green : theme.COLORS.grey1,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: toggle ? "flex-end" : "flex-start",
          padding: 2,
          borderRadius: 20,
        }}
        disabled={disabled}
        onPress={() => {
          setToggle(!toggle);
          setValue(!field.value);
          onToggle && onToggle(!toggle)
        }}
        activeOpacity={0.8}
      >
        <View
          style={{
            width: 20,
            height: 20,
            borderRadius: 12,
            backgroundColor: theme.COLORS.white,
          }}
        />
      </TouchableOpacity>
    </View>
  );
};
