import * as React from "react";
import Svg, { Path } from "react-native-svg";

function EyeOnSvg(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width={11.5} height={7.618} viewBox="0 0 11.5 7.618" {...props}>
      <Path
        d="M5.75 330.983a5.737 5.737 0 01-5.039-3.174 5.737 5.737 0 015.039-3.174 5.737 5.737 0 015.039 3.174 5.737 5.737 0 01-5.039 3.174zm5.68-3.316A6.385 6.385 0 005.75 324a6.385 6.385 0 00-5.68 3.667l-.07.142.07.142a6.385 6.385 0 005.68 3.667 6.385 6.385 0 005.68-3.667l.07-.142zm-5.68 1.411a1.27 1.27 0 111.27-1.27 1.271 1.271 0 01-1.27 1.27m0-3.174a1.9 1.9 0 101.9 1.9 1.907 1.907 0 00-1.9-1.9"
        transform="translate(0 -324)"
        fill="#7a8390"
        fillRule="evenodd"
      />
    </Svg>
  );
}

export default EyeOnSvg;
