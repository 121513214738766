import React from "react";
import { View } from "react-native";
import { ApText } from "../../../../components/v1/typography";
import { useTradingContext } from "../../../../context";

export const AboutStrategy = ({}) => {
  const { botExecutorStrategySummary, botExecutorByID } = useTradingContext();

  return (
    <View
      style={{
        backgroundColor: "#FFF8F4",
        padding: 12,
        marginBottom: 20,
      }}
    >
      <ApText
        style={{
          color: "#F37021",
          fontSize: 16,
          fontWeight: "500",
          paddingBottom: 15,
        }}
        translateKey="AboutStrategy"
      />
      {botExecutorStrategySummary.is_peer_bp === "1" ? (
        <>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <ApText style={{ fontSize: 12 }} translateKey="Trader" />
            <ApText style={{ fontSize: 12, marginHorizontal: 2 }}> : </ApText>
            <ApText style={{ fontSize: 12, color: "#F37021" }}>
              {botExecutorStrategySummary?.trader_code}
            </ApText>
          </View>

          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <ApText
              style={{ fontSize: 12 }}
              translateKey="CommissionPercentage"
            />
            <ApText style={{ fontSize: 12, marginHorizontal: 2 }}> : </ApText>
            <ApText style={{ fontSize: 12, color: "#F37021" }}>
              {parseFloat(botExecutorStrategySummary.peer_fee) * 100 + " %"}
            </ApText>
          </View>

          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <ApText style={{ fontSize: 12 }} translateKey="TraderComment" />
            <ApText style={{ fontSize: 12, marginHorizontal: 2 }}> : </ApText>
            <ApText style={{ fontSize: 12, color: "#F37021" }}>
              {botExecutorStrategySummary.plan_desc}
            </ApText>
          </View>
        </>
      ) : (
        <>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              paddingBottom: 3,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                width: "48%",
              }}
            >
              <ApText
                style={{ fontSize: 12 }}
                translateKey="FirstOrderMargin"
              />
              <ApText style={{ fontSize: 12, color: "#F37021" }}>
                {botExecutorStrategySummary.init_fund}
              </ApText>
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                width: "48%",
              }}
            >
              <ApText style={{ fontSize: 12 }} translateKey="TakeProfitRatio" />
              <ApText style={{ fontSize: 12, color: "#F37021" }}>
                {+botExecutorStrategySummary?.bep?.last_entry > 0
                  ? `${(
                      parseFloat(
                        botExecutorStrategySummary?.entry_tp_perc?.split(",")[
                          parseInt(
                            botExecutorStrategySummary?.bep?.last_entry
                          ) - 1
                        ]
                      ) * 100
                    ).toFixed(2)} %`
                  : "-"}
              </ApText>
            </View>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              paddingBottom: 3,
            }}
          >
            {botExecutorStrategySummary?.plan_type !== "1" && (
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "48%",
                }}
              >
                <ApText
                  style={{ fontSize: 12 }}
                  translateKey="PriceDifference"
                />
                <ApText style={{ fontSize: 12, color: "#F37021" }}>
                  {(
                    parseFloat(
                      botExecutorStrategySummary?.entry_price_diff?.split(",")[
                        parseInt(botExecutorStrategySummary?.bep?.last_entry)
                      ]
                    ) * 100
                  ).toFixed(2) || "-"}{" "}
                  %
                </ApText>
              </View>
            )}
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                width: "48%",
              }}
            >
              <ApText
                style={{ fontSize: 12 }}
                translateKey="TrailingTakeProfit"
              />
              <ApText style={{ fontSize: 12, color: "#F37021" }}>
                {+botExecutorStrategySummary?.bep?.last_entry > 0
                  ? `${(
                      parseFloat(
                        botExecutorStrategySummary?.entry_tp_trailing_perc?.split(
                          ","
                        )[
                          parseInt(
                            botExecutorStrategySummary?.bep?.last_entry
                          ) - 1
                        ]
                      ) * 100
                    ).toFixed(2)} %`
                  : "-"}
              </ApText>
            </View>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              paddingBottom: 3,
            }}
          >
            {botExecutorStrategySummary?.plan_type !== "1" && (
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "48%",
                }}
              >
                <ApText
                  style={{ fontSize: 12 }}
                  translateKey="TrailingReEntry"
                />
                <ApText style={{ fontSize: 12, color: "#F37021" }}>
                  {(
                    parseFloat(
                      botExecutorStrategySummary?.entry_trailing?.split(",")[
                        parseInt(botExecutorStrategySummary?.bep?.last_entry)
                      ]
                    ) * 100
                  ).toFixed(2) || "-"}{" "}
                  %
                </ApText>
              </View>
            )}
            {botExecutorStrategySummary?.plan_type !== "1" && (
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "48%",
                }}
              >
                <ApText
                  style={{ fontSize: 12 }}
                  translateKey="TakeProfitMode"
                />
                <ApText style={{ fontSize: 12, color: "#F37021" }}>
                  {+botExecutorStrategySummary?.bep?.last_entry > 0
                    ? botExecutorStrategySummary?.entry_tp_mode?.split(",")[
                        parseInt(botExecutorStrategySummary?.bep?.last_entry) -
                          1
                      ]
                    : "-"}
                </ApText>
              </View>
            )}
            {botExecutorStrategySummary?.plan_type === "1" && (
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "48%",
                }}
              >
                <ApText style={{ fontSize: 12 }} translateKey="ReturnState" />
                <ApText style={{ fontSize: 12, color: "#F37021" }}>
                  {botExecutorStrategySummary?.set_return?.split(",")[
                    botExecutorStrategySummary?.curr_set_round
                  ] || "-"}
                </ApText>
              </View>
            )}
          </View>
        </>
      )}
    </View>
  );
};
