import React, { useEffect, useRef, useState } from "react";
import { ApButton, ApSafeAreaView, ApTextInput } from "../components/v1";
import { theme } from "../constants";
import { components } from "../components";
import { ScrollView, View } from "react-native";
import { Form, Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { ApText } from "../components/v1/typography";
import { useApiBindingState } from "../context/apiBinding";
import { IFXTradingAccount } from "../models";

const ValidateForm = () => {
  return Yup.object().shape({
    label: Yup.string().required("Label is required"),
    login: Yup.string().required("Login ID is required"),
    password: Yup.string().required("Password is required"),
  });
};

const ValidateForm2 = () => {
  return Yup.object().shape({
    label: Yup.string().required("Label is required"),
  });
};

const FXTradingAccount: React.FC = ({ navigation, route }: any) => {
  const formRef = useRef<FormikProps<any>>();
  const { createFXTradingAccount, loading, fXTradingAccounts, updateFXTradingAccount } = useApiBindingState();
  const [account, setAccount] = useState<IFXTradingAccount>()

  useEffect(() => {
    if(route?.params?.id){
      let a = fXTradingAccounts?.filter((item) => item.id == route?.params?.id)[0]
      setAccount(a)
    }
  }, []);

  const renderHeader = () => {
    return (
      <components.Header
        title="FXTrading Account"
        titleTranlateKey="FXTradingAccount"
        goBack={true}
      />
    );
  };

  const handleSubmit = (val: any) => {
    route?.params?.id ? updateFXTradingAccount({id: account.id, label: val.label})
    : createFXTradingAccount({...val, platform: '21'});
  };

  const renderContent = () => {
    return (
      <ScrollView
        contentContainerStyle={{ flexGrow: 1, paddingHorizontal: 20 }}
      >
        <Formik
          innerRef={formRef as any}
          validationSchema={route?.params?.id ? ValidateForm2 : ValidateForm}
          initialValues={{
            label: route?.params?.id ? account?.label : "",
            login: "",
            password: "",
          }}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {(props: FormikProps<any>) => (
            <Form>
              {!route?.params?.id && (
                <View
                  style={{
                    backgroundColor: "#FFD9CF",
                    padding: 12,
                    borderRadius: 10,
                    marginTop: 15,
                    marginBottom: 5,
                    flexDirection: "row",
                  }}
                >
                  <ApText
                    style={{ color: "#F37021", width: "80%", fontSize: 13 }}
                    translateKey="FXTradingAccountHint"
                  />
                </View>
              )}

              <View
                style={{
                  // padding: 15,
                  marginTop: 15,
                  borderRadius: 10,
                  //backgroundColor: "#FCF8F6",
                }}
              >
                <ApTextInput
                  label="Account Label"
                  name="label"
                  labelTranslateKey="AccountLabel"
                  placeholder="Give a name to your account"
                  placeHolderTranslateKey="AccountNamePlaceHolder"
                />

                {!route?.params?.id && (
                  <ApTextInput
                    label="MT5 Account Login ID"
                    labelTranslateKey="AccountLoginID"
                    name="login"
                  />
                )}

                {!route?.params?.id && (
                  <ApTextInput
                    label="MT5 Account Login Password"
                    labelTranslateKey="AccountLoginPassword"
                    name="password"
                    secureTextEntry
                  />
                )}

                <View style={{ marginTop: 50 }}>
                  <ApButton
                    style={{ margin: 0, padding: 0 }}
                    round="xl"
                    label="Confirm"
                    labelTranslateKey="Confirm"
                    disabled={loading}
                    loading={loading}
                    onPress={props.handleSubmit}
                  />
                </View>
              </View>
            </Form>
          )}
        </Formik>
      </ScrollView>
    );
  };

  return (
    <ApSafeAreaView style={{ flex: 1, backgroundColor: theme.COLORS.bgColor }}>
      {renderHeader()}
      {renderContent()}
      {/* {loading ? <ApLoader /> : renderContent()} */}
    </ApSafeAreaView>
  );
};

export default FXTradingAccount;