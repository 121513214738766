import { useFocusEffect, useNavigation } from "@react-navigation/native";
import { LinearGradient } from "expo-linear-gradient";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ScrollView, TextInput, TouchableOpacity, View } from "react-native";
import { RefreshControl } from "react-native-web-refresh-control";
import { components } from "../../../components";
import {
  ApButton,
  ApEmptyState,
  ApLoader,
  ApRow,
  ApSafeAreaView,
} from "../../../components/v1";
import { ApText } from "../../../components/v1/typography";
import {
  useConfigState,
  useStrategyState,
  useTradingContext,
} from "../../../context";
import { useCheckEnv, useDeviceBackListner } from "../../../hooks";
import { useCalcPnl } from "../../../hooks/master";
import { svg } from "../../../svg";
import { BotExecutor, Operations, TerminateBotModal } from "./components";
import { theme } from "../../../constants";

export const TradingBots: React.FC = ({ route }: any) => {
  const tab = route.params.tab;
  const navigation: any = useNavigation();
  const [openOperation, setOpenOperation] = useState(false);
  const [selectedStrategy, setSelectedStrategy] = useState<any[]>([]);
  // const [market, setMarket] = useState<"1"|"2"|string>("2");
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [query, setQuery] = useState<any>();
  const searchInputRef = useRef<TextInput>(null);
  const [refreshing, setRefreshing] = useState(false);
  const [showTermModal, setShowTermModal] = useState(false);
  const { isBrowser } = useCheckEnv();
  const { backHandler, clearBackHandler } = useDeviceBackListner();
  const { setSymbols } = useStrategyState();
  const { t } = useTranslation();
  // const [status, setStatus] = useState<string>("1");
  const [showAll, setShowAll] = useState<boolean>(true);
  const [showLive, setShowLive] = useState<boolean>(false);
  const [initFund, setInitFund] = useState(0);
  const [totalFirstOrderMargin, setTotalFirstOrderMargin] = useState<{
    long: number
    short: number
  }>({long: 0.00, short: 0.00})

  const {
    status,
    setStatus,
    market,
    setMarket,
    spotUtilisedFund,
    terminateBot,
    futureUtilisedFund,
    assetInfo,
    loading,
    platform,
    assetInfoStatus,
    getAssetInfo,
    getBotExecutors,
    botExecutors,
  } = useTradingContext();
  const { priceList, getPriceList } = useConfigState();
  const { calcPNLAmount, calcPNLPerc } = useCalcPnl();

  useEffect(() => {
    // setMarket(tab);
    getAssetInfo();
    getPriceList();
    getBotExecutors(market, true);
  }, []);

  useEffect(() => {
    let filtered = botExecutors
    .filter((item) =>
      showLive
        ? item.market === market &&
          parseFloat(item.bep.pos_size) != 0 &&
          item.status !== "2"
        : item.market === market &&
          item.status === status
    )

    let long = filtered?.filter((item) => item.pos_side == "1")
    ?.map((item) => item.init_fund)

    let short = filtered?.filter((item) => item.pos_side == "-1")
    ?.map((item) => item.init_fund)

    setTotalFirstOrderMargin({
      long: long.reduce((a, b) => parseFloat(a) + parseFloat(b), 0),
      short: short.reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    })
  }, [botExecutors, status, market, showLive])

  useFocusEffect(
    useCallback(() => {
      getPriceList({ from: "bdd", market, platform });
      const interval = setInterval(() => {
        getPriceList({ from: "bdd", market, platform });
      }, 8000);
      return () => clearInterval(interval);
    }, [market])
  );

  const handleRefresh = () => {
    setRefreshing(true);
    setMarket(`2`);
    Promise.all([getAssetInfo(), getBotExecutors(`2`, true)]).finally(() => {
      setRefreshing(false);
    });
  };

  const renderHeader = () => {
    return (
      <components.Header
        title="Trading Bots"
        titleTranlateKey="TradingBots"
        goBack={true}
        TradingBotHistory={true}
        params={{ market: market}}
        screenName="Home"
      />
    );
  };

  const termBot = (payload) => {
    terminateBot(payload)
      .then(() => {
        getBotExecutors(market);
      })
      .catch(() => {
        getBotExecutors(market);
      });
  };

  useEffect(() => {
    if (isSearching) {
      const bot = botExecutors
        .filter((item) =>
          showLive
            ? item.market === market && parseFloat(item.bep.pos_size) != 0 && item.status !== "2"
            : item.market === market && item.status === status
        )
        .filter((item) =>
          item?.symbol.toLowerCase().includes(query.toLowerCase())
        );
      setInitFund(bot[0]?.init_fund);
    } else {
      const bot = botExecutors.filter((item) =>
        showLive
          ? item.market === market && parseFloat(item.bep.pos_size) != 0 && item.status !== "2"
          : item.market === market && item.status === status
      );
      setInitFund(bot[0]?.init_fund);
    }
  }, [botExecutors, isSearching, initFund]);

  
  const renderContent = () => {
    return (
      <View style={{ flex: 1 }}>
        <ScrollView
          refreshControl={
            <RefreshControl
              progressBackgroundColor="white"
              refreshing={loading}
              onRefresh={handleRefresh}
            />
          }
        >
          <View style={{ flex: 1 }}>
            <ScrollView style={{ paddingHorizontal: 15 }}>
              <components.SearchInputField
                inputRef={searchInputRef}
                onChange={(e: any) => {
                  e.target.value === ""
                    ? setIsSearching(false)
                    : setIsSearching(true);
                  setQuery(e.target.value);
                }}
                containerStyle={{ marginTop: 20 }}
                leftIcon={<svg.SearchSvg />}
                placeholder={t("BotExecutorSearchPlaceholder")}
              />

              <View
                style={{
                  marginTop: 15,
                  borderRadius: 10,
                  backgroundColor: "#FCF8F6",
                  padding: 10,
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      borderRadius: 5,
                      padding: 2,
                      marginBottom: 5,
                    }}
                  >
                    <TouchableOpacity
                      onPress={() => {
                        searchInputRef.current?.clear();
                        setIsSearching(false);
                        setOpenOperation(false);
                        setStatus("1");
                        setSelectedStrategy([]);
                        setMarket("2");
                        setInitFund(0);
                      }}
                    >
                      <LinearGradient
                        colors={[
                          market === "2" ? "none" : "none",
                          market === "1" ? "none" : "none",
                        ]}
                        style={{
                          width: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: 5,
                          paddingVertical: 3,
                        }}
                        start={{ x: 0, y: 0 }}
                        end={{ x: 1, y: 0 }}
                      >
                        <ApRow direction="row">
                          <ApText
                            translateKey="Future"
                            style={{
                              fontWeight: "500",
                              fontSize: 14,
                              color: market === "2" ? "#F37021" : "#D0CFCE",
                              lineHeight: 14 * 1.6,
                              paddingRight: 5,
                            }}
                          />

                          <ApText
                            style={{
                              paddingRight: 10,
                              borderRightWidth: 2,
                              color: market === "2" ? "#F37021" : "#D0CFCE",
                              borderRightColor: "#D0CFCE",
                            }}
                          >
                            ({" "}
                            {isSearching && market === "2"
                              ? botExecutors
                                  .filter((item) => item.market === "2" && item.status !== "2")
                                  .filter((item) =>
                                    (item?.symbol.toLowerCase()
                                    .includes(query.toLowerCase())) || (
                                      item?.label_code.toLowerCase()
                                      .includes(query.toLowerCase())
                                    ) || (item?.plan_type.includes(query.toLowerCase()))
                                  ).length
                              : botExecutors.filter(
                                  (item) => item.market === "2" && item.status !== "2"
                                ).length}{" "}
                            )
                          </ApText>
                        </ApRow>
                      </LinearGradient>
                    </TouchableOpacity>
                    <TouchableOpacity
                      onPress={() => {
                        searchInputRef.current?.clear();
                        setIsSearching(false);
                        setOpenOperation(false);
                        setShowLive(false);
                        setStatus("1");
                        setSelectedStrategy([]);
                        setMarket("1");
                        setInitFund(0);
                      }}
                    >
                      <LinearGradient
                        colors={[
                          market === "1" ? "none" : "none",
                          market === "1" ? "none" : "none",
                        ]}
                        style={{
                          width: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: 5,
                          paddingVertical: 3,
                        }}
                        start={{ x: 0, y: 0 }}
                        end={{ x: 1, y: 0 }}
                      >
                        <ApRow>
                          <ApText
                            translateKey="Spot"
                            style={{
                              fontWeight: "500",
                              fontSize: 14,
                              color: market === "1" ? "#F37021" : "#D0CFCE",
                              lineHeight: 14 * 1.6,
                              paddingLeft: 15,
                            }}
                          />
                          <ApText
                            style={{
                              marginLeft: 5,
                              color: market === "1" ? "#F37021" : "#D0CFCE",
                            }}
                          >
                            ({" "}
                            {isSearching && market === "1"
                              ? botExecutors
                                  .filter((item) => item.market === "1" && item.status !== "2")
                                  .filter((item) =>
                                    (item?.symbol.toLowerCase()
                                    .includes(query.toLowerCase())) || (
                                      item?.label_code.toLowerCase()
                                      .includes(query.toLowerCase())
                                    ) || (item?.plan_type.includes(query.toLowerCase()))
                                  ).length
                              : botExecutors.filter(
                                  (item) => item.market === "1" && item.status !== "2"
                                ).length}{" "}
                            )
                          </ApText>
                        </ApRow>
                      </LinearGradient>
                    </TouchableOpacity>
                  </View>

                  <ApButton
                    containerHeight={30}
                    style={{
                      height: "100%",
                      padding: 5,
                      paddingHorizontal: 10,
                    }}
                    labelStyle={{ fontSize: 12 }}
                    //containerWidth= "47%"
                    round="md"
                    labelTranslateKey="CreateBot"
                    onPress={() => {
                      setSymbols([]);
                      navigation.navigate("BotSettings");
                    }}
                  />
                </View>
                    
                 <View style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent:'space-between', marginTop:10}}>
                  <View style={{display: 'flex', flexDirection: 'row'}}>
                    <ApText size="xs" style={{marginRight: 5, marginTop: 10}}>
                      {t('TotalFirstOrderMargin')}:
                    </ApText>

                    <ApText size="xs" style={{marginRight: 5, marginTop: 10}} color={theme.COLORS.primary}>
                      {market === "2" ? t('Long') : t('USDT-based')}: {totalFirstOrderMargin.long.toFixed(2)}
                    </ApText>

                    <ApText size="xs" style={{marginRight: 5, marginTop: 10}} color={theme.COLORS.primary}>
                    {market === "2" ? t('Short') : t('Token-based')}: {totalFirstOrderMargin.short.toFixed(2)}
                    </ApText>
                  </View>
                 </View>

                <View style={{}}>
                  <View
                    style={{
                      justifyContent:
                        market === "2" ? "space-between" : "flex-start",
                      marginTop: 10,
                      flexDirection: "row",
                      alignItems: "center",
                      borderRadius: 5,
                      padding: 2,
                      marginBottom: 5,
                    }}
                  >
                    {/* <TouchableOpacity
                      onPress={() => {
                        searchInputRef.current?.clear();
                        setIsSearching(false);
                        setOpenOperation(false);
                        setShowAll(true)
                      }}
                    >
                      <LinearGradient
                        colors={[
                          showAll ? "none" : "none",
                          showAll ? "none" : "none",
                        ]}
                        style={{
                          width: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                          // borderRadius: 5,
                          // paddingVertical: 3,
                        }}
                        start={{ x: 0, y: 0 }}
                        end={{ x: 1, y: 0 }}
                      >
                        <ApRow direction="row">
                          <ApText
                            translateKey="All"
                            style={{
                              fontWeight: "500",
                              fontSize: 14,
                              color: showAll ? "#F37021" : "#D0CFCE",
                              lineHeight: 14 * 1.6,
                              // paddingRight: 5,
                            }}
                          />

                          <ApText
                            style={{
                              // paddingRight: 10,
                              // borderRightWidth: 2,
                              fontSize: 14,
                              color: showAll ? "#F37021" : "#D0CFCE",
                              // borderRightColor: "#D0CFCE",
                            }}
                          >
                            ({" "}
                            {isSearching
                              ? botExecutors
                                  .filter((item) =>
                                  item.market === market &&
                                    item?.symbol
                                      .toLowerCase()
                                      .includes(query.toLowerCase())
                                  ).length
                              : botExecutors.filter(item => item.market === market).length}{" "}
                            )
                          </ApText>
                        </ApRow>
                      </LinearGradient>
                    </TouchableOpacity>

                    <ApText style={{color: '#D0CFCE', marginHorizontal: market === "1" ? 10 : 0}}>
                        |
                    </ApText> */}

                    <TouchableOpacity
                      onPress={() => {
                        searchInputRef.current?.clear();
                        setIsSearching(false);
                        setOpenOperation(false);
                        setSelectedStrategy([]);
                        setShowLive(false);
                        setStatus("1");
                        setInitFund(0);
                      }}
                    >
                      <LinearGradient
                        colors={[
                          status === "1" && !showLive ? "none" : "none",
                          status === "1" && !showLive ? "none" : "none",
                        ]}
                        style={{
                          width: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                          // borderRadius: 5,
                          // paddingVertical: 3,
                        }}
                        start={{ x: 0, y: 0 }}
                        end={{ x: 1, y: 0 }}
                      >
                        <ApRow direction="row">
                          <ApText
                            translateKey="Running"
                            style={{
                              fontWeight: "500",
                              fontSize: 14,
                              color:
                                status === "1" && !showLive
                                  ? "#F37021"
                                  : "#D0CFCE",
                              lineHeight: 14 * 1.6,
                              marginRight: 5,
                              // paddingRight: 5,
                            }}
                          />

                          <ApText
                            style={{
                              // paddingRight: 10,
                              // borderRightWidth: 2,
                              fontSize: 14,
                              color:
                                status === "1" && !showLive
                                  ? "#F37021"
                                  : "#D0CFCE",
                              // borderRightColor: "#D0CFCE",
                            }}
                          >
                            ({" "}
                            {isSearching
                              ? botExecutors
                                  .filter(
                                    (item) =>
                                      item.market === market &&
                                      item.status === "1"
                                  )
                                  .filter((item) =>
                                    (item?.symbol.toLowerCase()
                                    .includes(query.toLowerCase())) || (
                                      item?.label_code.toLowerCase()
                                      .includes(query.toLowerCase())
                                    ) || (item?.plan_type.includes(query.toLowerCase()))
                                  ).length
                              : botExecutors.filter(
                                  (item) =>
                                    item.market === market &&
                                    item.status === "1"
                                ).length}{" "}
                            )
                          </ApText>
                        </ApRow>
                      </LinearGradient>
                    </TouchableOpacity>

                    <ApText
                      style={{
                        color: "#D0CFCE",
                        marginHorizontal: market === "1" ? 10 : 0,
                      }}
                    >
                      |
                    </ApText>

                    <TouchableOpacity
                      onPress={() => {
                        searchInputRef.current?.clear();
                        setIsSearching(false);
                        setOpenOperation(false);
                        setSelectedStrategy([]);
                        setShowLive(false);
                        setStatus("0");
                        setInitFund(0);
                      }}
                    >
                      <LinearGradient
                        colors={[
                          status === "0" && !showLive ? "none" : "none",
                          status === "0" && !showLive ? "none" : "none",
                        ]}
                        style={{
                          width: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                          // borderRadius: 5,
                          // paddingVertical: 3,
                        }}
                        start={{ x: 0, y: 0 }}
                        end={{ x: 1, y: 0 }}
                      >
                        <ApRow direction="row">
                          <ApText
                            translateKey="Paused"
                            style={{
                              fontWeight: "500",
                              fontSize: 14,
                              color:
                                status === "0" && !showLive
                                  ? "#F37021"
                                  : "#D0CFCE",
                              lineHeight: 14 * 1.6,
                              marginRight: 5,
                              // paddingRight: 5,
                            }}
                          />

                          <ApText
                            style={{
                              // paddingRight: 10,
                              // borderRightWidth: 2,
                              fontSize: 14,
                              color:
                                status === "0" && !showLive
                                  ? "#F37021"
                                  : "#D0CFCE",
                              // borderRightColor: "#D0CFCE",
                            }}
                          >
                            ({" "}
                            {isSearching
                              ? botExecutors
                                  .filter(
                                    (item) =>
                                      item.market === market &&
                                      item.status === "0"
                                  )
                                  .filter((item) =>
                                    (item?.symbol.toLowerCase()
                                    .includes(query.toLowerCase())) || (
                                      item?.label_code.toLowerCase()
                                      .includes(query.toLowerCase())
                                    ) || (item?.plan_type.includes(query.toLowerCase()))
                                  ).length
                              : botExecutors.filter(
                                  (item) =>
                                    item.market === market &&
                                    item.status === "0"
                                ).length}{" "}
                            )
                          </ApText>
                        </ApRow>
                      </LinearGradient>
                    </TouchableOpacity>

                    {market === "1" ? (
                      <></>
                    ) : (
                      <ApText style={{ color: "#D0CFCE" }}>|</ApText>
                    )}

                    {market === "1" ? (
                      <></>
                    ) : (
                      <TouchableOpacity
                        onPress={() => {
                          searchInputRef.current?.clear();
                          setIsSearching(false);
                          setOpenOperation(false);
                          setSelectedStrategy([]);
                          setShowLive(true);
                          setInitFund(0);
                        }}
                      >
                        <LinearGradient
                          colors={[
                            showLive ? "none" : "none",
                            showLive ? "none" : "none",
                          ]}
                          style={{
                            width: "100%",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: 5,
                            paddingVertical: 3,
                          }}
                          start={{ x: 0, y: 0 }}
                          end={{ x: 1, y: 0 }}
                        >
                          <ApRow direction="row">
                            <ApText
                              translateKey="Live"
                              style={{
                                fontWeight: "500",
                                fontSize: 14,
                                color: showLive ? "#F37021" : "#D0CFCE",
                                lineHeight: 14 * 1.6,
                                paddingRight: 5,
                              }}
                            />

                            <ApText
                              style={{
                                paddingRight: 10,
                                fontSize: 14,
                                color: showLive ? "#F37021" : "#D0CFCE",
                                borderRightColor: "#D0CFCE",
                              }}
                            >
                              ({" "}
                              {isSearching
                                ? botExecutors
                                    .filter(
                                      (item) =>
                                        item.market === market &&
                                        parseFloat(item.bep.pos_size) != 0 &&
                                        item.status !== "2"
                                    )
                                    .filter((item) =>
                                      (item?.symbol.toLowerCase()
                                      .includes(query.toLowerCase())) || (
                                        item?.label_code.toLowerCase()
                                        .includes(query.toLowerCase())
                                      ) || (item?.plan_type.includes(query.toLowerCase()))
                                    ).length
                                : botExecutors.filter(
                                    (item) =>
                                      item.market === market &&
                                        parseFloat(item.bep.pos_size) != 0 &&
                                        item.status !== "2"
                                  ).length}{" "}
                              )
                            </ApText>
                          </ApRow>
                        </LinearGradient>
                      </TouchableOpacity>
                    )}
                  </View>

                  <View>
                    <View>
                      {botExecutors.filter(
                        (item) =>
                          item.market === market && item.status === status && item.status !== "2"
                      ).length === 0 ? (
                        <ApEmptyState />
                      ) : (
                        <>
                          {isSearching
                            ? botExecutors
                              .filter((item) =>
                                showLive
                                  ? item.market === market &&
                                    parseFloat(item.bep.pos_size) != 0 &&
                                    item.status !== "2"
                                  : item.market === market &&
                                    item.status === status
                              )
                              .filter((item) =>
                                (item?.symbol.toLowerCase()
                                .includes(query.toLowerCase())) || (
                                  item?.label_code.toLowerCase()
                                  .includes(query.toLowerCase())
                                ) || (item?.plan_type.includes(query.toLowerCase()))
                              )
                              .map((e, i) => (
                                <BotExecutor
                                  key={`ss_${i}`}
                                  calculatePNLPerc={calcPNLPerc}
                                  calculatePNLAmount={calcPNLAmount}
                                  market={market}
                                  priceList={priceList}
                                  e={e}
                                  openOperation={openOperation}
                                  selectedStrategy={selectedStrategy}
                                  setSelectedStrategy={setSelectedStrategy}
                                />
                              ))

                            : botExecutors
                              .filter((item) =>
                                showLive
                                  ? item.market === market &&
                                    parseFloat(item.bep.pos_size) != 0 &&
                                    item.status !== "2"
                                  : item.market === market &&
                                    item.status === status
                              )
                              .map((e, i) => (
                                <BotExecutor
                                  key={`ss_${i}`}
                                  calculatePNLPerc={calcPNLPerc}
                                  calculatePNLAmount={calcPNLAmount}
                                  market={market}
                                  priceList={priceList}
                                  e={e}
                                  openOperation={openOperation}
                                  selectedStrategy={selectedStrategy}
                                  setSelectedStrategy={setSelectedStrategy}
                                />
                          ))}
                        </>
                      )}
                    </View>
                  </View>
                </View>
              </View>
            </ScrollView>
          </View>
        </ScrollView>

        {loading &&
        botExecutors.filter((item) => item.market === market).length === 0 ? (
          <></>
        ) : (
          <Operations
            showLive={showLive}
            setSelectedStrategy={setSelectedStrategy}
            openOperation={openOperation}
            setOpenOperation={setOpenOperation}
            setShowTermModal={setShowTermModal}
            market={market}
            selectedStrategy={selectedStrategy}
            isSearching={isSearching}
            botExecutors={botExecutors}
            query={query}
            status={status}
            initFund={initFund}
          />
        )}

        <TerminateBotModal
          selectedStrategy={selectedStrategy}
          setShowTermModal={setShowTermModal}
          showTermModal={showTermModal}
          terminateBot={termBot}
          botExecutors={botExecutors}
        />
      </View>
    );
  };

  return (
    <ApSafeAreaView>
      {renderHeader()}
      {loading ? <ApLoader /> : renderContent()}
    </ApSafeAreaView>
  );
};
