import * as React from "react";
import Svg, { Path } from "react-native-svg";

type Props = {
    color?: string;
};

const MoreSvg: React.FC<Props> = ({ color = "#4C4C60" }) => (
    <Svg width={22} height={22} fill="none">
        <Path
            d="M18.792 11.458h-5.959a1.375 1.375 0 0 0-1.375 1.375v5.959a1.375 1.375 0 0 0 1.375 1.375h5.959a1.375 1.375 0 0 0 1.375-1.375v-5.959a1.375 1.375 0 0 0-1.375-1.375Zm.458 7.334a.459.459 0 0 1-.458.458h-5.959a.458.458 0 0 1-.458-.458v-5.959a.458.458 0 0 1 .458-.458h5.959a.458.458 0 0 1 .458.458v5.959ZM10.542 18.792v-5.959a1.375 1.375 0 0 0-1.375-1.375H3.208a1.375 1.375 0 0 0-1.375 1.375v5.959a1.375 1.375 0 0 0 1.375 1.375h5.959a1.375 1.375 0 0 0 1.375-1.375Zm-7.792 0v-5.959a.458.458 0 0 1 .458-.458h5.959a.458.458 0 0 1 .458.458v5.959a.458.458 0 0 1-.458.458H3.208a.458.458 0 0 1-.458-.458ZM19.708 5.73h-3.437V2.291a.458.458 0 1 0-.917 0v3.437h-3.437a.458.458 0 1 0 0 .917h3.437v3.437a.459.459 0 0 0 .917 0V6.646h3.437a.458.458 0 1 0 0-.917ZM3.208 10.542h5.959a1.375 1.375 0 0 0 1.375-1.375V3.208a1.375 1.375 0 0 0-1.375-1.375H3.208a1.375 1.375 0 0 0-1.375 1.375v5.959a1.375 1.375 0 0 0 1.375 1.375ZM2.75 3.208a.458.458 0 0 1 .458-.458h5.959a.458.458 0 0 1 .458.458v5.959a.458.458 0 0 1-.458.458H3.208a.458.458 0 0 1-.458-.458V3.208Z"
            fill={color}
        />
    </Svg>
);

export default MoreSvg;
