import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TouchableOpacity, View } from "react-native";
import { components } from "../components";
import { ApSafeAreaView } from "../components/v1";
import { ApText } from "../components/v1/typography";
import { theme } from "../constants";
import ApiCall from "../utils/ApiCall";
const ProfitMode: React.FC = ({ navigation }: any) => {
  const { i18n, t } = useTranslation();
  const [selected, setSelected] = useState("CreditBalance");
  const [profileData, setProfileData] = useState({});

  useEffect(() => {
    ApiCall.post("/profile/me").then((response) => {
      setProfileData(response.data.data);
    });
  }, []);

  const renderHeader = () => {
    return (
      <components.Header
        title={t("Take Profit Mode Settings")}
        titleTranlateKey="TakeProfitModeSettings"
        goBack={true}
      />
    );
  };

  const renderContent = () => {
    return (
      <View style={{ flex: 1 }}>
        <View
          style={{
            margin: 15,
            borderRadius: 10,
            paddingBottom: 20,
            marginTop: 15,
            flex: 0.92,
          }}
        >
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              paddingBottom: 5,
            }}
          >
            <ApText
              style={{ color: "#959595", fontSize: 13 }}
              translateKey="OrderList"
            />

            <ApText
              style={{ color: "#959595", fontSize: 13 }}
              translateKey="TakeProfitMode"
            />
          </View>
          <View>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                paddingVertical: 12,
                borderBottomColor: "#e6e6e6",
                borderBottomWidth: 1,
              }}
            >
              <ApText
                style={{ color: "#F37021", fontSize: 13 }}
                translateKey="Order#1"
              />

              <ApText
                style={{ color: "#222", fontSize: 13 }}
                translateKey="FullPosition"
              />
            </View>
          </View>
        </View>
        <View
          style={{
            paddingHorizontal: 15,
            paddingVertical: 20,
            width: "100%",
            flex: 0.08,
            position: "absolute",
            bottom: 0,
            backgroundColor: "white",
          }}
        >
          <TouchableOpacity
            style={{
              backgroundColor: "#F37021",
              width: "100%",
              height: 40,
              borderRadius: 8,
              alignItems: "center",
              justifyContent: "center",
            }}
            onPress={() => navigation.navigate("")}
          >
            <ApText
              style={{ color: theme.COLORS.white, fontWeight: "500" }}
              translateKey="Save"
            />
          </TouchableOpacity>
        </View>
      </View>
    );
  };

  return (
    <ApSafeAreaView>
      {renderHeader()}
      {renderContent()}
    </ApSafeAreaView>
  );
};

export default ProfitMode;
