import React, { useEffect, useState } from "react";
import { components } from "../components";
import { ApButton, ApConfirmModal, ApLoader, ApSafeAreaView } from "../components/v1";
import { theme } from "../constants";
import { Image, ScrollView, TouchableOpacity, View } from "react-native";
import { useTranslation } from "react-i18next";
import { svg } from "../svg";
import { ApText } from "../components/v1/typography";
import { useApiBindingState } from "../context/apiBinding";
import { RefreshControl } from "react-native-web-refresh-control";

const platforms = [
  {
    id: "1",
    title: "Binance",
    type: "bn",
    icon: require("../../assets/icons/09.png"),
  },
  {
    id: "2",
    title: "OKX",
    type: "okx",
    icon: require("../../assets/icons/okx.png"),
  },
  {
    id: "3",
    title: "TriiiX",
    type: "triiix",
    icon: require("../../assets/icons/triiix.png"),
  },
];

const FXTradingAccounts: React.FC = ({navigation}: any) => {
  const { t } = useTranslation();
  const { fXTradingAccounts, getFXTradingAccounts, loading, deleteFXTradingAccount } =
    useApiBindingState();

  const [searchQuery, setSearchQuery] = useState(null);
  const [confirmModal, setConfirmModal] = useState<{
    show: boolean;
    data?: any;
  }>({ show: false });

  useEffect(() => {
    getFXTradingAccounts();
  }, []);

  const renderHeader = () => {
    return (
      <components.Header
        title="FXTrading Accounts"
        titleTranlateKey="FXTradingAccounts"
        goBack={true}
      />
    );
  };

  const handleDelete = (id: string) => {
    setConfirmModal({ show: false });
    deleteFXTradingAccount(id)
  }
  const handleRefresh = () => {
    getFXTradingAccounts()
  };

  const renderContent = () => {
    return (
      <ScrollView
        contentContainerStyle={{
          flexGrow: 1,
          paddingHorizontal: 20,
        }}
        refreshControl={
          <RefreshControl refreshing={loading} onRefresh={handleRefresh} />
        }
      >
        <components.SearchInputField
          onChange={(e: any) =>
            e.target.value === ""
              ? setSearchQuery(null)
              : setSearchQuery(e.target.value)
          }
          containerStyle={{ marginTop: 20 }}
          leftIcon={<svg.SearchSvg />}
          placeholder={t("FXTradingAccountsSearchPlaceholder")}
        />
        <View
          style={{
            marginVertical: 20,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <ApText
            size="sm"
            translateKey="MyMetaTraderAccounts"
            color={theme.COLORS.primary}
          />
          <ApButton
            labelTranslateKey="AddNewAccount"
            onPress={() => {
                navigation.navigate("FXTradingAccount");
            }}
            round="lg"
            style={{
              height: 30,
            }}
            textStyle={{
              fontSize: 12,
            }}
          />
        </View>

        {fXTradingAccounts?.length > 0 ? (
          <View>
            {(searchQuery
              ? fXTradingAccounts.filter((item) =>
                  item.label.toLowerCase().includes(searchQuery.toLowerCase())
                )
              : fXTradingAccounts
            )?.map((item, index) => (
              <TouchableOpacity
                key={index}
                style={{
                  width: "100%",
                  backgroundColor: "#fff5f0ba",
                  borderRadius: 10,
                  paddingLeft: 10,
                  paddingRight: 15,
                  paddingVertical: 20,
                  marginBottom: 20,
                }}
                onPress={() => {
                  navigation.navigate("FXTradingAccount", {
                    id: item.id
                  });
                }}
              >
                <View
                  style={{
                    alignItems: "center",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      width: "70%",
                    }}
                  >
                    <Image
                      source={require("../../assets/users/stable-str.png")}
                      style={{
                        width: 60,
                        height: 45,
                      }}
                    />
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <ApText
                        style={{
                          color: theme.COLORS.mainDark,
                          fontWeight: "500",
                          marginLeft: 5,
                          marginRight: 10
                        }}
                        size="sm"
                      >
                        {item.label}
                      </ApText>

                      <View
                        style={{
                          backgroundColor: theme.COLORS.green,
                          borderRadius: 3,
                          padding: 2,
                          paddingLeft: 5,
                          paddingRight: 5,
                        }}
                      >
                        <ApText size="xs" color={theme.COLORS.white}>
                          {item.login}
                        </ApText>
                      </View>
                    </View>
                  </View>

                  <TouchableOpacity
                    style={{justifyContent: 'center', alignItems: "center" }}
                    onPress={() =>
                      setConfirmModal({
                        show: true,
                        data: item.id,
                      })
                    }
                  >
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <svg.RecyleBinSvg />
                    </View>
                  </TouchableOpacity>
                </View>
              </TouchableOpacity>
            ))}
          </View>
        ) : (
          <></>
        )}
      </ScrollView>
    );
  };

  return (
    <ApSafeAreaView style={{ flex: 1, backgroundColor: theme.COLORS.bgColor }}>
      {renderHeader()}
      {loading ? <ApLoader /> : renderContent()}
      <ApConfirmModal
        show={confirmModal.show}
        messageTranslateKey="ConfirmDeleteFXTradingAccount"
        message="Are you sure you want to delete this FXTrading Account ?"
        messageStyle={{textAlign: 'center'}}
        onDimiss={(val) => {
          if (val) {
            handleDelete(confirmModal.data);
          } else setConfirmModal({ show: false });
        }}
      />
    </ApSafeAreaView>
  );
};

export default FXTradingAccounts;