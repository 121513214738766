import CheckBox from "expo-checkbox";
import React, { ReactNode, useEffect, useState } from "react";
import { StyleProp, TextStyle, TouchableOpacity, View, ViewStyle } from "react-native";
import { ApText } from "../typography";

interface IProps {
  onChange?: any;
  defaultValue?: boolean;
  noBorder?: boolean;
  name: string;
  label?: string;
  style?: StyleProp<ViewStyle>;
  labelTranslateKey?: string;
  placeholder?: string;
  labelStyle?: TextStyle;
  customLabel?: ReactNode
}

export const ApRegCheckInput: React.FC<IProps> = ({
  onChange,
  defaultValue,
  noBorder,
  label,
  name,
  style,
  labelStyle,
  labelTranslateKey,
  customLabel
}) => {
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    defaultValue && setToggle(defaultValue);
  }, []);

  return (
    <TouchableOpacity style={style} onPress={()=>{
      setToggle(!toggle);
      onChange && onChange(!toggle);
    }}>
      <CheckBox
        style={{ borderWidth: !toggle ? 1 : 0, borderColor: "#9B9999" }}
        value={toggle}
        color={toggle ? "#F37021" : "transparent"}
        // onValueChange={(v) => {
        //   setToggle(!toggle);
        //   onChange && onChange(v);
        // }}
        
      />
      {label && (
        <ApText
          translateKey={labelTranslateKey || label}
          size="xs"
          style={labelStyle}
        >
          {label}
        </ApText>
      )}
      {customLabel && customLabel}
    </TouchableOpacity>
  );
};
