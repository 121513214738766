import * as React from "react";
import Svg, { G, Path } from "react-native-svg";

function HistorySvg(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width={16.009} height={14.893} viewBox="0 0 15.009 13.893" {...props}>
      <G data-name="Group 2737">
        <Path data-name="Path 109" d="M4230.736 2745.015v-3.348h11.44v12.556h-4.743" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="bevel" strokeWidth={1} transform="translate(-4227.667 -2741.167)" />
        <Path data-name="Path 110" d="M4233.527 2745.015h6.417" fill="none" stroke="#707070" strokeLinecap="round" strokeWidth={1} transform="translate(-4227.667 -2741.167)" />
        <Path data-name="Path 111" d="M4236.456 2747.945h3.488" fill="none" stroke="#707070" strokeLinecap="round" strokeWidth={1} transform="translate(-4227.667 -2741.167)" />
        <G data-name="history-svgrepo-com (1)" fill="#f37021">
          <Path data-name="Path 112" d="M3.976 7.952a3.976 3.976 0 113.976-3.976 3.981 3.981 0 01-3.976 3.976zm0-7.29A3.314 3.314 0 107.29 3.976 3.317 3.317 0 003.976.662z" transform="translate(-4227.667 -2741.167) translate(4227.667 2747.108)" />
          <Path
            data-name="Path 113"
            d="M470.626 343.951a.329.329 0 01-.212-.077l-.994-.829a.332.332 0 01-.12-.255v-1.159a.332.332 0 11.663 0v1l.874.729a.331.331 0 01-.212.585z"
            transform="translate(-4227.667 -2741.167) translate(4227.667 2747.108) translate(-465.656 -338.65)"
          />
        </G>
      </G>
    </Svg>
  );
}

export default HistorySvg;
