import * as Clipboard from "expo-clipboard";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TouchableOpacity, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { components } from "../components";
import {
  ApEmptyState,
  ApEmptyStateSvg,
  ApLoader,
  ApSafeAreaView,
} from "../components/v1";
import { ApText } from "../components/v1/typography";
import { useProfileState, useTransactionState } from "../context";
import { IDepositHistory } from "../models/transaction.interface";
import { svg } from "../svg";

const DepositHistory: React.FC = ({ navigation }: any) => {
  const { profileData, getProfileData } = useProfileState();
  const { depositHistory, getDepositHistory, loading } = useTransactionState();
  const [sortDepositHistory, setSortDepositHistory] = useState([]);
  const [sort, setSort] = useState<Record<string, IDepositHistory[]>>({});
  const { i18n, t } = useTranslation();
  const [selected, setSelected] = useState("CreditBalance");
  //const [profileData, setProfileData] = useState({});
  const dispatch = useDispatch();
  const hideProfit = useSelector(
    (state: any) => state.hideProfitSlice.hideProfit
  );

  useEffect(() => {
    getDepositHistory().then((res) => {
      setSort(sortDepositHistoryByDate(res as unknown as IDepositHistory[]));
    });
  }, []);

  const sortDepositHistoryByDate = (
    dataObjects: IDepositHistory[]
  ): Record<string, IDepositHistory[]> => {
    const sortedDataObjects: Record<string, IDepositHistory[]> = {};

    dataObjects.forEach((dataObject) => {
      dataObject.date = new Date(dataObject.created_at);
      // Get the year, month, and day of the current data object's date property
      const year = dataObject.date.getFullYear().toString();
      const month = (dataObject.date.getMonth() + 1)
        .toString()
        .padStart(2, "0");
      const day = dataObject.date.getDate().toString().padStart(2, "0");
      const dateKey = `${year}-${month}-${day}`;

      // If the sortedDataObjects object doesn't have an array for this date, create one
      if (!sortedDataObjects[dateKey]) {
        sortedDataObjects[dateKey] = [];
      }

      // Add the current data object to the array for this date
      sortedDataObjects[dateKey].push(dataObject);
    });

    return sortedDataObjects;
  };

  useEffect(() => {
    const dates = Object.keys(sort);
    setSortDepositHistory(dates as any);
  }, [sort]);

  const renderHeader = () => {
    return (
      <components.Header
        title={"Deposit History"}
        titleTranlateKey="DepositHistory"
        goBack={true}
      />
    );
  };

  const renderContent = () => {
    return (
      <View style={{ flex: 1 }}>
        {sortDepositHistory.length > 0 ? (
          sortDepositHistory.map((date) => (
            <View key={date}>
              <ApText
                style={{
                  color: "#F37021",
                  fontSize: 17,
                  padding: 15,
                  fontWeight: "500",
                  paddingTop: 25,
                }}
              >
                {date}
              </ApText>
              <View
                style={{
                  paddingHorizontal: 15,
                  backgroundColor: "#FCF8F6",
                  margin: 15,
                  borderRadius: 10,
                  paddingBottom: 20,
                  marginTop: 0,
                }}
              >
                {sort[date].map((e, index) => (
                  <View key={e.id}>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        paddingVertical: 15,
                        borderBottomWidth:
                          index + 1 === sort[date].length ? 0 : 1,
                        paddingBottom: index + 1 === sort[date].length ? 0 : 15,
                        borderColor: "#e4e4e499",
                      }}
                    >
                      <View style={{ width: "40%" }}>
                        <ApText style={{ fontWeight: "500", marginBottom: 10 }}>
                          {e.created_at.split(" ")[1]}
                        </ApText>
                        <ApText
                          style={{
                            color: "#ababab",
                            fontSize: 12,
                          }}
                          translateKey="TxHash"
                        />
                        {/* <ApText
                          style={{
                            color: "#ababab",
                            fontSize: 12,
                            lineHeight: 16,
                            paddingTop: 2,
                          }}
                          translateKey="FromAddress"
                        />
                        <ApText
                          style={{
                            color: "#ababab",
                            fontSize: 12,
                            lineHeight: 16,
                            paddingTop: 2,
                          }}
                          translateKey="ToAddress"
                        /> */}
                      </View>
                      <View style={{ width: "60%", alignItems: "flex-end" }}>
                        <ApText
                          numberOfLines={1}
                          style={{
                            fontWeight: "500",
                            color: "#222",
                            textAlign: "right",
                            marginBottom: 10,
                          }}
                        >
                          +{e.amount} USDT
                        </ApText>
                        {/* <ApText
                          numberOfLines={1}
                          style={{
                            color: "#ababab",
                            fontSize: 12,
                            lineHeight: 16,
                            paddingTop: 2,
                          }}
                          translateKey="&nbsp;"
                        /> */}

                        <View
                          style={{ flexDirection: "row", alignItems: "center" }}
                        >
                          <ApText
                            style={{
                              color: "#ababab",
                              fontSize: 12,
                              lineHeight: 16,
                              paddingTop: 2,
                            }}
                          >
                            {e.tx_hashing.slice(0, 4)}.....
                            {e.tx_hashing.slice(-4)}
                          </ApText>

                          <TouchableOpacity
                            onPress={() =>
                              Clipboard.setStringAsync(e.tx_hashing)
                            }
                            style={{ paddingLeft: 5 }}
                          >
                            <svg.CopySvg />
                          </TouchableOpacity>
                        </View>
                        {/* <ApText
                          numberOfLines={1}
                          style={{
                            color: "#ababab",
                            fontSize: 12,
                            lineHeight: 16,
                            paddingTop: 2,
                          }}
                        >
                          {e.tx_hashing}{" "}
                          
                          <TouchableOpacity
                            onPress={() =>
                              Clipboard.setStringAsync(e.tx_hashing)
                            }
                            style={{ paddingLeft: 5 }}
                          >
                            <svg.CopySvg />
                          </TouchableOpacity> 
                        </ApText> */}
                        {/* <ApText
                          numberOfLines={1}
                          style={{
                            color: "#ababab",
                            fontSize: 12,
                            lineHeight: 16,
                            paddingTop: 2,
                          }}
                        >
                          {e.from_wallet}
                          <TouchableOpacity
                            onPress={() =>
                              Clipboard.setStringAsync(e.from_wallet)
                            }
                            style={{ paddingLeft: 5 }}
                          >
                            <svg.CopySvg />
                          </TouchableOpacity>
                        </ApText>
                        <ApText
                          numberOfLines={1}
                          style={{
                            color: "#ababab",
                            fontSize: 12,
                            lineHeight: 16,
                            paddingTop: 2,
                          }}
                        >
                          {e.to_wallet}
                          <TouchableOpacity
                            onPress={() =>
                              Clipboard.setStringAsync(e.to_wallet)
                            }
                            style={{ paddingLeft: 5 }}
                          >
                            <svg.CopySvg />
                          </TouchableOpacity>
                        </ApText> */}
                      </View>
                    </View>
                  </View>
                ))}
              </View>
            </View>
          ))
        ) : (
          <ApEmptyState
            svg={<ApEmptyStateSvg.List />}
            heading="No Result Found"
            message="Deposit History is empty"
          />
        )}
      </View>
    );
  };

  return (
    <ApSafeAreaView>
      {renderHeader()}
      {loading && (
        <View style={{ paddingTop: 10 }}>
          <ApLoader />
        </View>
      )}
      {!loading && renderContent()}
    </ApSafeAreaView>
  );
};

export default DepositHistory;
