import * as React from "react";
import Svg, { Path } from "react-native-svg";

type Props = {
    color?: string;
};

const SafeDepositSvg: React.FC<Props> = ({ color = "#4C4C60" }) => (
    <Svg width={22} height={22} fill="none">
        <Path
            d="M16.183 9.645h-.668c-.07-.9-.429-1.721-.984-2.37l.474-.473a.322.322 0 1 0-.455-.456l-.474.474a4.125 4.125 0 0 0-2.369-.983v-.668a.322.322 0 1 0-.645 0v.668c-.9.07-1.72.428-2.368.983l-.474-.474a.322.322 0 0 0-.456.456l.474.474a4.125 4.125 0 0 0-.983 2.369h-.668a.322.322 0 1 0 0 .644h.668c.07.9.428 1.72.983 2.369l-.474.474a.322.322 0 1 0 .456.455l.474-.473a4.125 4.125 0 0 0 2.368.983v.668a.322.322 0 0 0 .645 0v-.668c.9-.07 1.72-.429 2.369-.983l.474.473a.321.321 0 0 0 .455 0 .322.322 0 0 0 0-.455l-.474-.474a4.125 4.125 0 0 0 .983-2.369h.669a.322.322 0 1 0 0-.644Zm-4.798 3.82a3.502 3.502 0 0 1-3.498-3.498 3.502 3.502 0 0 1 3.498-3.498 3.502 3.502 0 0 1 3.498 3.498 3.502 3.502 0 0 1-3.498 3.498Z"
            fill={color}
        />
        <Path
            d="M13.592 9.645h-.568a1.674 1.674 0 0 0-1.64-1.35c-.921 0-1.67.75-1.67 1.672 0 .921.749 1.67 1.67 1.67.812 0 1.49-.58 1.64-1.348h.568a.322.322 0 0 0 0-.644Zm-2.208 1.348a1.028 1.028 0 0 1 0-2.053 1.028 1.028 0 0 1 0 2.053Z"
            fill={color}
        />
        <Path
            d="M19.57 0H2.43c-.77 0-1.397.626-1.397 1.396v7.868a.322.322 0 1 0 .645 0V8.196h1.306v3.541H1.678V10.67a.322.322 0 1 0-.645 0v7.868c0 .77.627 1.396 1.397 1.396h.554v1.315c0 .415.337.752.752.752h1.906a.753.753 0 0 0 .752-.752v-1.315h9.212v1.315c0 .415.337.752.752.752h1.906a.753.753 0 0 0 .752-.752v-1.315h.554c.77 0 1.397-.626 1.397-1.396v-2.32a.322.322 0 0 0-.645 0v2.32a.753.753 0 0 1-.752.752H2.43a.753.753 0 0 1-.752-.752v-2.7h1.306v1.394c0 .415.337.752.752.752h14.528a.753.753 0 0 0 .752-.752v-4.85h1.306v2.431a.322.322 0 0 0 .645 0V1.396C20.967.626 20.34 0 19.57 0ZM5.75 21.248c0 .06-.049.107-.108.107H3.736a.108.108 0 0 1-.108-.107v-1.315H5.75v1.315Zm12.622 0c0 .06-.048.107-.108.107h-1.906a.108.108 0 0 1-.107-.107v-1.315h2.12v1.315ZM1.678 4.741h2.314c.06 0 .108.049.108.108v2.595c0 .06-.049.108-.108.108H1.678v-2.81Zm0 7.64h2.314c.06 0 .108.049.108.108v2.596c0 .059-.049.107-.108.107H1.678v-2.81Zm16.694 4.85c0 .06-.048.107-.108.107H3.736a.107.107 0 0 1-.108-.107v-1.394h.364a.753.753 0 0 0 .752-.752v-2.596a.753.753 0 0 0-.752-.752h-.364v-3.54h.364a.753.753 0 0 0 .752-.753V4.85a.753.753 0 0 0-.752-.752h-.364V2.702c0-.059.048-.107.108-.107h4.51a.322.322 0 1 0 0-.645h-4.51a.753.753 0 0 0-.752.752v1.395H1.678v-2.7c0-.415.337-.752.752-.752h17.14c.415 0 .752.337.752.751v6.156h-1.306v-4.85a.753.753 0 0 0-.752-.752H9.651a.322.322 0 1 0 0 .645h8.613c.06 0 .108.048.108.107v14.53Zm.644-5.494v-3.54h1.306v3.54h-1.306Z"
            fill={color}
        />
    </Svg>
);

export default SafeDepositSvg;
