import React, { useState } from "react";
import { TextInput, TouchableOpacity, View } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

import { components } from "../components";
import { ApSafeAreaView } from "../components/v1";
import { ApText } from "../components/v1/typography";
import { theme } from "../constants";

const ConfirmationCode: React.FC = ({ navigation }: any) => {
  const [isFocused, setIsFocused] = useState(0);

  const Number = () => {
    return (
      <View
        style={{
          backgroundColor: theme.COLORS.white,
          width: 60,
          height: 60,
          borderRadius: 10,
        }}
      >
        <TextInput
          style={{
            textAlign: "center",
            flex: 1,
          }}
          keyboardType="number-pad"
          maxLength={1}
        />
      </View>
    );
  };

  const renderHeader = () => {
    return (
      <components.Header title={"Verify your phone number"} goBack={true} />
    );
  };

  const renderContent = () => {
    return (
      <KeyboardAwareScrollView
        contentContainerStyle={{
          flexGrow: 1,
          paddingHorizontal: 20,
          paddingTop: 10,
        }}
        enableOnAndroid={true}
        showsVerticalScrollIndicator={false}
      >
        <View
          style={{
            paddingTop: 30,
            paddingBottom: 50,
          }}
        >
          <ApText
            style={{
              ...theme.FONTS.Mulish_400Regular,
              fontSize: 16,
              color: theme.COLORS.bodyTextColor,
              marginBottom: 30,
            }}
            translateKey="EnterOTP"
          />
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: 30,
            }}
          >
            <Number />
            <Number />
            <Number />
            <Number />
            <Number />
          </View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <ApText
              style={{
                ...theme.FONTS.Mulish_400Regular,
                fontSize: 16,
                lineHeight: 16 * 1.7,
                color: theme.COLORS.bodyTextColor,
              }}
              translateKey="DidntRecieveOTP"
            />
            <TouchableOpacity>
              <ApText
                style={{
                  ...theme.FONTS.Mulish_400Regular,
                  fontSize: 16,
                  lineHeight: 16 * 1.7,
                  color: theme.COLORS.linkColor,
                }}
                translateKey="Resend"
              />
            </TouchableOpacity>
          </View>
        </View>
      </KeyboardAwareScrollView>
    );
  };

  return (
    <ApSafeAreaView>
      {renderHeader()}
      {renderContent()}
    </ApSafeAreaView>
  );
};

export default ConfirmationCode;
