import { Formik, FormikProps } from "formik";
import React, {
  Dispatch,
  SetStateAction,
  memo,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { ApModalAlt } from "../components/Modal";
import { ApDropdown } from "../components/v1/input";
import { ApText } from "../components/v1/typography";
import { theme } from "../constants";
import { useTradingContext } from "../context";
import { svg } from "../svg";
import { ApButton } from "../components/v1";
import { useMasterData } from "../hooks/master";

interface IProps {
  setVisible?: Dispatch<SetStateAction<boolean>>;
  visible?: boolean;
  // takeProfitModeSettings: any
  // setTakeProfitModeSettings: any
  // set_entry_tp_mode: any
}

const TakeProfitModeSetting: React.FC<IProps> = memo(
  ({ setVisible, visible }) => {
    const { i18n, t } = useTranslation();
    const formRef = useRef<FormikProps<any>>();
    const [initValues, setInitValues] = useState<object>();
    const { MasterData } = useMasterData();

    const {
      saveOriginal,
      takeProfitModeSettings,
      setTakeProfitModeSettings,
      set_entry_tp_mode,
    } = useTradingContext();

    useEffect(() => {
      let obj: any;
      takeProfitModeSettings.map((item: any) => {
        obj = {
          ...obj,
          [item.name]: item.entry_tp_mode,
        };
      });
      setInitValues(obj);
    }, []);

    const renderHeader = () => {
      return (
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            height: 47,
            backgroundColor: "#FCF8F6",
          }}
        >
          <View
            style={{
              position: "absolute",
              left: 0,
              alignItems: "center",
            }}
          >
            <TouchableOpacity
              style={{
                paddingHorizontal: 20,
                paddingVertical: 12,
              }}
              onPress={() => setVisible(false)}
            >
              <svg.GoBackSvg goBackColor="#F37021" />
            </TouchableOpacity>
          </View>

          <ApText
            translateKey="TakeProfitModeSettings"
            style={{
              textAlign: "center",
              fontSize: 16,
              color: "#F37021",
            }}
          >
            Take Profit Mode Settings
          </ApText>
        </View>
      );
    };

    const handleSave = (val: any) => {
      let itms = [...val.items];
      let takeProfitModeVal: any = [];
      itms?.forEach((item: any) => {
        item.entry_tp_mode = item[`${item.name}`];
        takeProfitModeVal = [...takeProfitModeVal, item.entry_tp_mode];
      });

      setTakeProfitModeSettings(itms);
      set_entry_tp_mode(takeProfitModeVal);
      saveOriginal({
        TakeProfitModeSettings: itms,
        entry_tp_mode: takeProfitModeVal,
      });
      setVisible(false);
    };

    const renderContent = () => {
      return (
        <View style={{ flex: 1 }}>
          <View
            style={{
              margin: 15,
              borderRadius: 10,
              marginTop: 15,
              flex: 0.92,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                paddingBottom: 5,
                alignItems: "center",
                width: "100%",
              }}
            >
              <ApText
                translateKey="OrderList"
                style={{ color: "#959595", fontSize: 13 }}
              >
                Order List
              </ApText>
              <ApText
                translateKey="TakeProfitMode"
                style={{ color: "#959595", fontSize: 13, textAlign: "center" }}
              />
            </View>
            <Formik
              enableReinitialize
              innerRef={formRef as any}
              //validationSchema={FormSchema}
              initialValues={{
                items: takeProfitModeSettings.map((item) => ({
                  ...item,
                  [item?.name]: item?.entry_tp_mode
                    ? item?.entry_tp_mode
                    : MasterData?.TAKE_PROFIT_MODE[0].value,
                })),
              }}
              onSubmit={(val) => handleSave(val)}
            >
              {(props: FormikProps<any>) => (
                <>
                  <View>
                    {props?.values?.items?.map((item: any, index: number) => {
                      return (
                        <View
                          key={index}
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                            paddingBottom: 8,
                            borderBottomColor: "#e6e6e6",
                            borderBottomWidth: 1,
                            zIndex: 4000 - index,
                            elevation: 4000 - index,
                          }}
                        >
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginTop: 18,
                            }}
                          >
                            <ApText
                              translateKey="Order"
                              style={{ color: "#F37021", fontSize: 12 }}
                            >
                              Order
                            </ApText>
                            <ApText style={{ color: "#F37021", fontSize: 12 }}>
                              {" "}
                              #{item?.orderList}
                            </ApText>
                          </View>
                          <View style={{ zIndex: 4000 - index, marginTop: 10 }}>
                            <ApDropdown
                              contPropsHeight={150}
                              useContPropsHeight={
                                index + 1 === takeProfitModeSettings.length
                                  ? true
                                  : false
                              }
                              noBorder
                              name={`items[${index}].take_profit_mode_${
                                index + 1
                              }`}
                              items={MasterData?.TAKE_PROFIT_MODE}
                            />
                          </View>
                        </View>
                      );
                    })}
                  </View>

                  <View style={{ marginTop: 20, zIndex: -99 }}>
                    <ApButton
                      round="lg"
                      label="Save"
                      labelTranslateKey="Save"
                      onPress={() => props.handleSubmit()}
                    />
                  </View>
                </>
              )}
            </Formik>
          </View>
        </View>
      );
    };

    return (
      <ApModalAlt fallbackRoute="EditStrategy" visible={visible}>
        <ScrollView style={{ flex: 1 }}>
          {renderHeader()}
          {renderContent()}
        </ScrollView>
      </ApModalAlt>
    );
  }
);

export default TakeProfitModeSetting;

const style = StyleSheet.create({
  inputLabel: {
    ...theme.FONTS.H6,
    color: theme.COLORS.mainDark,
    // width: "50%",
  },
  textInput: {
    backgroundColor: "#DFE3EA",
    width: 100,
    color: "#F37021",
    textAlign: "center",
    fontSize: 13,
    padding: 3,
    borderRadius: 6,
  },
});
