import React, { useEffect, useState } from "react";
import { View } from "react-native";
import { ApText } from "../../../../components/v1/typography";
import { theme } from "../../../../constants";
import { useTradingContext } from "../../../../context";
import { formatAmt, toCurrency } from "../../../../utils";

export const SecondInfo = ({
  calculatePNLPerc,
  calculatePNLAmount,
  priceList,
}) => {
  const { botExecutorStrategySummary, botExecutorByID } = useTradingContext();
  const [pnlAmount, setPnlAmount] = useState<string>();
  const [pnlPerc, setPnlPerc] = useState<string>();

  useEffect(() => {
    setPnlAmount(calculatePNLAmount(botExecutorStrategySummary));
    setPnlPerc(calculatePNLPerc(botExecutorStrategySummary));
  }, []);

  return (
    <View
      style={{
        marginTop: 20,
        justifyContent: "space-between",
        flexDirection: "row",
        alignItems: "flex-start",
      }}
    >
      <View>
        <ApText
          style={{
            ...theme.FONTS.Mulish_400Regular,
            fontSize: 12,
            lineHeight: 12 * 1.6,
            color: theme.COLORS.bodyTextColor,
          }}
          translateKey="Qty"
        />
        <ApText
          style={{
            marginBottom: 12,
            fontWeight: "500",
            color: theme.COLORS.mainDark,
            fontSize: 13,
          }}
        >
          {botExecutorStrategySummary?.bep
            ? formatAmt(
                Math.abs(parseFloat(botExecutorStrategySummary?.bep?.pos_size))
              )
            : "-"}
        </ApText>
        <ApText
          style={{
            ...theme.FONTS.Mulish_400Regular,
            fontSize: 12,
            lineHeight: 12 * 1.6,
            color: theme.COLORS.bodyTextColor,
          }}
          translateKey={
            botExecutorStrategySummary?.plan_type === "1"
              ? "PositionPrice"
              : "AvgPrice"
          }
        />
        <ApText
          style={{
            marginBottom: 12,
            fontWeight: "500",
            color: theme.COLORS.mainDark,
            fontSize: 13,
          }}
        >
          {botExecutorStrategySummary?.bep
            ? formatAmt(botExecutorStrategySummary?.bep?.pos_price)
            : "-"}
        </ApText>

        <ApText
          style={{
            ...theme.FONTS.Mulish_400Regular,
            fontSize: 12,
            lineHeight: 12 * 1.6,
            color: theme.COLORS.bodyTextColor,
          }}
          translateKey="UpFirstOrderLimit"
        />
        <ApText
          style={{
            marginBottom: 12,
            fontWeight: "500",
            color: theme.COLORS.mainDark,
            fontSize: 13,
          }}
        >
          {formatAmt(botExecutorStrategySummary.init_upper_boundary)}
          {" USDT"}
        </ApText>
      </View>

      <View>
        <ApText
          style={{
            ...theme.FONTS.Mulish_400Regular,
            fontSize: 12,
            lineHeight: 12 * 1.6,
            color: theme.COLORS.bodyTextColor,
          }}
          translateKey="FundUtilised"
        />
        <ApText
          style={{
            marginBottom: 12,
            fontWeight: "500",
            color: theme.COLORS.mainDark,
            fontSize: 13,
          }}
        >
          {botExecutorStrategySummary?.bep
            ? toCurrency(
                parseFloat(botExecutorStrategySummary?.bep.pos_fund) /
                  parseFloat(botExecutorStrategySummary?.leverage || "1"),
                ""
              )
            : "-"}
        </ApText>
        <ApText
          style={{
            ...theme.FONTS.Mulish_400Regular,
            fontSize: 12,
            lineHeight: 12 * 1.6,
            color: theme.COLORS.bodyTextColor,
          }}
          translateKey="LastPrice"
        />
        <ApText
          style={{
            marginBottom: 12,
            fontWeight: "500",
            color: theme.COLORS.mainDark,
            fontSize: 13,
          }}
        >
          {/* {!!priceList?.length
            ? parseFloat(
                priceList?.filter(
                  (item) => item?.symbol === botExecutorByID.symbol
                )[0]?.lastPrice
              ).toFixed(2)
            : "0.00"}
          {" USDT"} */}

          {!!priceList?.length
            ? formatAmt(
                parseFloat(
                  priceList?.filter(
                    (item) => item?.symbol === botExecutorByID.symbol
                  )[0]?.lastPrice
                )
              )
            : "0.00"}
          {" USDT"}
        </ApText>

        <ApText
          style={{
            ...theme.FONTS.Mulish_400Regular,
            fontSize: 12,
            lineHeight: 12 * 1.6,
            color: theme.COLORS.bodyTextColor,
          }}
          translateKey="DownFirstOrderLimit"
        />
        <ApText
          style={{
            marginBottom: 12,
            fontWeight: "500",
            color: theme.COLORS.mainDark,
            fontSize: 13,
          }}
        >
          {formatAmt(botExecutorStrategySummary.init_lower_boundary)}
          {" USDT"}
        </ApText>
      </View>

      <View>
        <ApText
          style={{
            ...theme.FONTS.Mulish_400Regular,
            fontSize: 12,
            lineHeight: 12 * 1.6,
            color: theme.COLORS.bodyTextColor,
          }}
          translateKey={
            botExecutorStrategySummary?.plan_type === "1"
              ? "PositionState"
              : "EntryLevel"
          }
        />
        <ApText
          style={{
            marginBottom: 12,
            fontWeight: "500",
            color: theme.COLORS.mainDark,
            fontSize: 13,
          }}
        >
          {botExecutorStrategySummary?.plan_type === "1" ? (
            `${+botExecutorStrategySummary?.curr_set_round + 1}-${
              +botExecutorStrategySummary?.curr_set_count + 1
            }`
          ) : (
            <>
              {botExecutorStrategySummary?.plan_type !== "1" &&
              botExecutorStrategySummary?.bep
                ? botExecutorStrategySummary?.bep.last_entry
                : "-"}
              / {botExecutorStrategySummary?.max_entries}
            </>
          )}
        </ApText>

        <ApText
          style={{
            ...theme.FONTS.Mulish_400Regular,
            fontSize: 12,
            color: theme.COLORS.bodyTextColor,
            lineHeight: 12 * 1.6,
          }}
          translateKey="FloatingP&L"
        />
        <ApText
          style={{
            fontWeight: "500",
            color: theme.COLORS.mainDark,
            fontSize: 13,
          }}
        >
          {pnlAmount && pnlPerc ? (
            <ApText
              style={{
                color: pnlAmount?.includes("-") ? "#e30000" : "green",
                fontWeight: "bold",
                paddingRight: 5,
                fontSize: 13,
              }}
            >
              {!pnlAmount?.includes("-") ? "+" : ""}
              {pnlAmount + " "}({pnlPerc} {"%"})
            </ApText>
          ) : (
            <></>
          )}
        </ApText>
      </View>
    </View>
  );
};
