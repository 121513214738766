import * as React from "react";
import Svg, { Path } from "react-native-svg";

function FollowFillSvg(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width={18.199} height={20} viewBox="0 0 18.199 20" {...props}>
      <Path data-name="Path 14886" d="M.5 1.543v17.914A1.052 1.052 0 001.543 20.5h16.113a1.052 1.052 0 001.044-1.043V9.6A9.1 9.1 0 009.6.5H1.543A1.052 1.052 0 00.5 1.543z" fill="#f37021" transform="translate(-.5 -.5)" />
      <Path data-name="Path 14887" d="M12.5 7.3h-8a.9.9 0 01-.9-.9.9.9 0 01.9-.9h8a.9.9 0 01.9.9.9.9 0 01-.9.9z" transform="translate(-.5 -.5) translate(-.33 -.285)" fill="#fff" />
      <Path data-name="Path 14888" d="M9.1 11H4.5a.9.9 0 01-.9-.9.9.9 0 01.9-.9h4.6a.9.9 0 01.9.9.9.9 0 01-.9.9z" transform="translate(-.5 -.5) translate(-.242 -.496)" fill="#fff" />
    </Svg>
  );
}

export default FollowFillSvg;
