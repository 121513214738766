import * as React from "react";
import Svg, { Path } from "react-native-svg";

interface IProps
{
    strokeColor?: string
}

const CheckSvg: React.FC<IProps> = ({ strokeColor }) => (
    <Svg width={14} height={10} fill="none">
        <Path
            d="M12.333 1 5 8.333 1.667 5"
            stroke={strokeColor ? strokeColor : "#1B1D4D"}
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Svg>
);

export default CheckSvg;
