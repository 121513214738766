import React from "react";
import { Modal, View, TouchableOpacity, TextStyle } from "react-native";
import Dialog, {
  DialogContent,
  SlideAnimation,
} from "react-native-popup-dialog";
import { theme } from "../../../constants";
import { ApText } from "../typography";
import { svg } from "../../../svg";

interface IProps {
  show: boolean;
  message: string;
  messageTranslateKey?: string,
  onDimiss: (val: boolean) => void;
  messageStyle?: TextStyle
}

export const ApConfirmModal: React.FC<IProps> = ({
  show,
  message,
  onDimiss,
  messageTranslateKey,
  messageStyle
}) => {
  return (
    <Dialog
      dialogStyle={{ width: "90%", backgroundColor:"white" }}
      visible={show}
      dialogAnimation={
        new SlideAnimation({
          slideFrom: "bottom",
        })
      }
    >
      <TouchableOpacity
        onPress={() => {
          onDimiss(false);
        }}
        style={{ alignItems: "flex-end", padding: 15, paddingBottom: 0 }}
      >
        <svg.closeSvg />
      </TouchableOpacity>
      <DialogContent>
        <View style={{ alignItems: "center", paddingHorizontal: 6 }}>
          <ApText
            translateKey="Notice"
            size="lg"
            style={{
              color: "#F37021",
              fontWeight: "500",
              marginBottom: 5,
            }}
         />
          <ApText style={messageStyle} translateKey={messageTranslateKey} size="sm">{message}</ApText>

          <View
            style={{
              width: "100%",
              marginTop: 30,
              flexDirection: "row",
              //paddingVertical: 20,
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <TouchableOpacity
              style={{
                marginRight: 10,
                borderWidth: 1,
                borderColor: "#F37021",
                width: "50%",
                height: 38,
                borderRadius: 5,
                alignItems: "center",
                justifyContent: "center",
              }}
              onPress={() => {
                onDimiss(false);
              }}
            >
              <ApText translateKey="Cancel" style={{ color: "#F37021", fontWeight: "500" }}>
                Cancel
              </ApText>
            </TouchableOpacity>

            <TouchableOpacity
              style={{
                backgroundColor: "#F37021",
                width: "50%",
                height: 38,
                borderRadius: 5,
                alignItems: "center",
                justifyContent: "center",
              }}
              onPress={() => {
                onDimiss(true);
              }}
            >
              <ApText translateKey="Confirm" style={{ color: theme.COLORS.white, fontWeight: "500" }}>
                Confirm
              </ApText>
            </TouchableOpacity>
          </View>
        </View>
      </DialogContent>
    </Dialog>
  //   <Modal
  //     presentationStyle="fullScreen"
  //     animationType="slide"
  //     transparent={true}
  //     visible={show}
  //   >
  //     <View
  //       style={{
  //         paddingHorizontal: 15,
  //         position: 'relative',
  //         flex: 1,
  //         justifyContent: 'center',
  //         alignItems: 'center',
  //       }}
  //     >
  //       <View
  //         style={{
  //           opacity: 0.5,
  //           position: 'absolute',
  //           width: '100%',
  //           height: '100%',
  //           backgroundColor: 'black'
  //         }}
  //       >
  //       </View>

  //       <View
  //         style={{
  //           width: '100%',
  //           borderRadius: 10,
  //           backgroundColor: '#fff'
  //         }}
  //       >
  //           <TouchableOpacity
  //             onPress={() => {
  //               onDimiss(false);
  //             }}
  //             style={{ alignItems: "flex-end", padding: 15, paddingBottom: 0 }}
  //           >
  //             <svg.closeSvg />
  //           </TouchableOpacity>
  //         <View style={{ alignItems: "center", paddingHorizontal: 6 }}>
  //         <ApText
  //           translateKey="Notice"
  //           size="lg"
  //           style={{
  //             color: "#F37021",
  //             fontWeight: "500",
  //             marginBottom: 5,
  //           }}
  //        />
  //         <ApText translateKey={messageTranslateKey} size="sm">{message}</ApText>

  //         <View
  //           style={{
  //             width: "100%",
  //             marginTop: 30,
  //             flexDirection: "row",
  //             //paddingVertical: 20,
  //             alignItems: "center",
  //             justifyContent: "space-between",
  //           }}
  //         >
  //           <TouchableOpacity
  //             style={{
  //               marginRight: 10,
  //               borderWidth: 1,
  //               borderColor: "#F37021",
  //               width: "50%",
  //               height: 38,
  //               borderRadius: 5,
  //               alignItems: "center",
  //               justifyContent: "center",
  //             }}
  //             onPress={() => {
  //               onDimiss(false);
  //             }}
  //           >
  //             <ApText translateKey="Cancel" style={{ color: "#F37021", fontWeight: "500" }}>
  //               Cancel
  //             </ApText>
  //           </TouchableOpacity>

  //           <TouchableOpacity
  //             style={{
  //               backgroundColor: "#F37021",
  //               width: "50%",
  //               height: 38,
  //               borderRadius: 5,
  //               alignItems: "center",
  //               justifyContent: "center",
  //             }}
  //             onPress={() => {
  //               onDimiss(true);
  //             }}
  //           >
  //             <ApText translateKey="Confirm" style={{ color: theme.COLORS.white, fontWeight: "500" }}>
  //               Confirm
  //             </ApText>
  //           </TouchableOpacity>
  //         </View>
  //         </View>
  //       </View>
  //     </View>
  // </Modal>
  );
};