import React from 'react';
import ContentLoader, {Rect, Circle, Path} from 'react-content-loader/native';
import { useWindowDimensions } from 'react-native';

export const ApPostLoader = () => {
  const {width} = useWindowDimensions();
  return (
    <ContentLoader
      speed={2}
      width={width}
      height={200}
      viewBox="30 5 500 200"
      //   backgroundColor="#f3f3f3"
      //   foregroundColor="#ecebeb"
    >
      <Circle cx="31" cy="31" r="15" />
      <Rect x="58" y="18" rx="20" ry="20" width="140" height="10" />
      <Rect x="58" y="34" rx="2" ry="2" width="140" height="10" />
      <Rect x="0" y="60" rx="2" ry="2" width={width} height="250" />
    </ContentLoader>
  );
};
