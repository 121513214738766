import React from "react";
import { Image, StyleProp, StyleSheet, TextStyle, View, ViewStyle } from "react-native";
import { toCurrency } from "../../utils";
import { ApText } from "./typography";

interface IProps{
    leadershipBoard: any[];
    amountStyle?: StyleProp<TextStyle>;
    nickNameStyle?: StyleProp<TextStyle>;
}

export const LeadBoard: React.FC<IProps> = ({ leadershipBoard, nickNameStyle,amountStyle }) => {

  return (
    <View
      style={{
        marginBottom: 25,
        borderRadius: 10,
        flexDirection: "row",
        justifyContent: "space-between",
        paddingHorizontal: 10,
        alignContent: "flex-end",
        alignItems: "flex-end",
      }}
    >
      <View style={{ alignItems: "center" }}>
        <Image
          source={require("../../../assets/users/Silver.png")}
          style={{ width: 23, height: 16, bottom: -1 }}
        />
        <Image
          source={require("../../../assets/users/09.png")}
          style={{ width: 55, height: 55 }}
        />
        <ApText
          style={[styles.nickname,nickNameStyle]}
          numberOfLines={1}
        >
          {leadershipBoard[0]?.nickname}
        </ApText>
        <ApText
          style={[styles.amount,amountStyle]}
        >
          {toCurrency(+leadershipBoard[0]?.amount)}
        </ApText>
      </View>
      <View style={{ alignItems: "center" }}>
        <Image
          source={require("../../../assets/users/Champion.png")}
          style={{ width: 25, height: 18, bottom: -1 }}
        />
        <Image
          source={require("../../../assets/users/08.png")}
          style={{ width: 75, height: 75 }}
        />
        <ApText
          style={[styles.nickname,nickNameStyle]}
          numberOfLines={1}
        >
          {leadershipBoard[1]?.nickname}
        </ApText>
        <ApText
          style={[styles.amount,amountStyle]}
        >
          {toCurrency(+leadershipBoard[1]?.amount)}
        </ApText>
      </View>
      <View style={{ alignItems: "center" }}>
        <Image
          source={require("../../../assets/users/Bronze.png")}
          style={{ width: 23, height: 16, bottom: -1 }}
        />
        <Image
          source={require("../../../assets/users/10.png")}
          style={{ width: 55, height: 55 }}
        />
        <ApText
          style={[styles.nickname,nickNameStyle]}
          numberOfLines={1}
        >
          {leadershipBoard[2]?.nickname}
        </ApText>
        <ApText
          style={[styles.amount,amountStyle]}
        >
          {toCurrency(+leadershipBoard[2]?.amount)}
        </ApText>
      </View>
    </View>
  );
};


const styles = StyleSheet.create({
  nickname:{
    fontWeight: "400",
    paddingTop: 5,
    fontSize: 13,
    color: "#fff",
    width: 80,
    textAlign: "center",
  },amount:{
    fontWeight: "bold",
    paddingTop: 3,
    fontSize: 13,
    color: "#fff",
  }
})