import { LinearGradient } from "expo-linear-gradient";
import { t } from "i18next";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { ScrollView, TouchableOpacity, View } from "react-native";
import { RefreshControl } from "react-native-web-refresh-control";
import { components } from "../components";
import {
  ApEmptyState,
  ApEmptyStateSvg,
  ApLoader,
  ApSafeAreaView,
} from "../components/v1";
import { ApText } from "../components/v1/typography";
import { theme } from "../constants";
import { useProfileState } from "../context";
import { IDirectDls } from "../models/profile.interface";
import { svg } from "../svg";

const Payments: React.FC = ({ navigation }: any) => {
  const { genealogyData, getGenealogyData, loading } = useProfileState();
  const [selected, setSelected] = useState("Direct");
  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    getGenealogyData();
  }, [selected]);

  const handleRefresh = () => {
    setRefreshing(true);
    getGenealogyData().finally(() => {
      setRefreshing(false);
    });
  };

  const renderHeader = () => {
    return (
      <components.Header
        title="Community"
        titleTranlateKey="Community"
        goBack={true}
      />
    );
  };

  const renderContent = () => {
    return (
      <ScrollView
        refreshControl={
          <RefreshControl refreshing={loading} onRefresh={handleRefresh} />
        }
      >
        <View style={{ flex: 1, padding: 20 }}>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              borderRadius: 5,
              padding: 2,
              marginBottom: 5,
            }}
          >
            <TouchableOpacity onPress={() => setSelected("Direct")}>
              <LinearGradient
                colors={[
                  selected === "Direct" ? "none" : "none",
                  selected === "Direct" ? "none" : "none",
                ]}
                style={{
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 5,
                  paddingVertical: 3,
                }}
                start={{ x: 0, y: 0 }}
                end={{ x: 1, y: 0 }}
              >
                <View style={{ flexDirection: "row" }}>
                  <ApText
                    style={{
                      fontWeight: "500",
                      fontSize: 14,
                      color: selected === "Direct" ? "#F37021" : "#D0CFCE",
                      lineHeight: 14 * 1.6,
                    }}
                    translateKey="Direct"
                  />
                  <ApText
                    style={{
                      fontWeight: "500",
                      fontSize: 14,
                      color: selected === "Direct" ? "#F37021" : "#D0CFCE",
                      lineHeight: 14 * 1.6,
                      borderRightWidth: 2,
                      borderRightColor: "#D0CFCE",
                      paddingRight: 15,
                      paddingLeft: 3,
                    }}
                  >
                    ({genealogyData.direct_dls?.length})
                  </ApText>
                </View>
              </LinearGradient>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => setSelected("Community")}>
              <LinearGradient
                colors={[
                  selected === "Community" ? "none" : "none",
                  selected === "Direct" ? "none" : "none",
                ]}
                style={{
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 5,
                  paddingVertical: 3,
                }}
                start={{ x: 0, y: 0 }}
                end={{ x: 1, y: 0 }}
              >
                <ApText
                  style={{
                    fontWeight: "500",
                    fontSize: 14,
                    color: selected === "Community" ? "#F37021" : "#D0CFCE",
                    lineHeight: 14 * 1.6,
                    paddingLeft: 15,
                  }}
                  translateKey="Community"
                />
              </LinearGradient>
            </TouchableOpacity>
          </View>
          {selected == "Direct" ? (
            <>
              <View
                style={{
                  padding: 15,
                  borderRadius: 10,
                  marginVertical: 10,
                  borderWidth: 2,
                  borderColor: "#F37021",
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "flex-start",
                  }}
                >
                  <View style={{ flex: 1 }}>
                    <ApText
                      style={{
                        ...theme.FONTS.Mulish_400Regular,
                        fontSize: 12,
                        lineHeight: 12 * 1.6,
                        color: theme.COLORS.bodyTextColor,
                      }}
                      translateKey="TotalActive"
                    />
                    <ApText
                      style={{
                        marginBottom: 12,
                        fontWeight: "500",
                        color: theme.COLORS.mainDark,
                        fontSize: 13,
                      }}
                    >
                      {genealogyData.direct?.active}
                    </ApText>
                  </View>
                  <View style={{ flex: 1 }}>
                    <ApText
                      style={{
                        ...theme.FONTS.Mulish_400Regular,
                        fontSize: 12,
                        lineHeight: 12 * 1.6,
                        color: theme.COLORS.bodyTextColor,
                      }}
                      translateKey="TotalInActive"
                    />
                    <ApText
                      style={{
                        marginBottom: 12,
                        fontWeight: "500",
                        color: theme.COLORS.mainDark,
                        fontSize: 13,
                      }}
                    >
                      {genealogyData.direct?.inactive}
                    </ApText>
                  </View>
                  <View style={{ flex: 1 }}>
                    <ApText
                      style={{
                        ...theme.FONTS.Mulish_400Regular,
                        fontSize: 12,
                        lineHeight: 12 * 1.6,
                        color: theme.COLORS.bodyTextColor,
                      }}
                      translateKey="TotalP&L"
                    />
                    <ApText
                      style={{
                        marginBottom: 12,
                        fontWeight: "500",
                        color: theme.COLORS.mainDark,
                        fontSize: 13,
                      }}
                    >
                      {genealogyData.direct?.total_pnl}
                    </ApText>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "flex-start",
                  }}
                >
                  <View style={{ flex: 1 }}>
                    <ApText
                      style={{
                        ...theme.FONTS.Mulish_400Regular,
                        fontSize: 12,
                        lineHeight: 12 * 1.6,
                        color: theme.COLORS.bodyTextColor,
                        marginTop: 12,
                      }}
                      translateKey="YTDP&L"
                    />
                    <ApText
                      style={{
                        fontWeight: "500",
                        color: theme.COLORS.mainDark,
                        fontSize: 13,
                      }}
                    >
                      {genealogyData.direct?.ytd_pnl}
                    </ApText>
                  </View>
                  <View style={{ flex: 1 }}>
                    <ApText
                      style={{
                        ...theme.FONTS.Mulish_400Regular,
                        fontSize: 12,
                        lineHeight: 12 * 1.6,
                        color: theme.COLORS.bodyTextColor,
                        marginTop: 12,
                      }}
                      translateKey="30DP&L"
                    />
                    <ApText
                      style={{
                        fontWeight: "500",
                        color: theme.COLORS.mainDark,
                        fontSize: 13,
                      }}
                    >
                      {genealogyData.direct?.month_pnl}
                    </ApText>
                  </View>
                  <View style={{ flex: 1 }}></View>
                </View>
              </View>
              {genealogyData.status === "1" ? (
                (genealogyData.direct_dls as IDirectDls[]).map((e) => {
                  return (
                    <View key={e.id}>
                      <View
                        style={{
                          backgroundColor: "#FFF5F0",
                          marginTop: 15,
                          padding: 15,
                          borderRadius: 10,
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <View
                            style={{
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <ApText
                              style={{ fontWeight: "bold", fontSize: 11 }}
                            >
                              {e.nickname}
                            </ApText>
                            <View
                              style={{
                                flexDirection: "row",
                                marginLeft: 10,
                                borderRadius: 3,
                                height: 16,
                                marginTop: 5,
                                backgroundColor: "#F37021",
                              }}
                            >
                              <ApText
                                style={{
                                  fontSize: 12,
                                  height: 10,
                                  paddingHorizontal: 5,
                                  color: "white",
                                  fontWeight: "900",
                                  lineHeight: 18,
                                }}
                              >
                                {t(`Ranks.${e.rank}`)}
                              </ApText>
                            </View>
                          </View>
                          <ApText
                            style={{
                              fontSize: 10,
                              fontWeight: "500",
                              color: "#808080a1",
                              paddingLeft: 5,
                            }}
                          >
                            {moment(e.created_at).format("YYYY-MM-DD")}
                          </ApText>
                        </View>

                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <View>
                            {/* <View style={{ flexDirection: "row" }}>
                              <ApText
                                style={{
                                  fontSize: 11,
                                  fontWeight: "500",
                                  paddingBottom: 5,
                                }}
                                translateKey="Sales"
                              />
                              <ApText
                                style={{
                                  fontSize: 11,
                                  fontWeight: "500",
                                  paddingBottom: 5,
                                }}
                              >
                                : {e.group_invt_amt}
                              </ApText>
                            </View> */}
                            <View style={{ flexDirection: "row" }}>
                              <ApText
                                style={{
                                  fontSize: 11,
                                  fontWeight: "500",
                                  paddingBottom: 5,
                                }}
                                translateKey="YTDP&L"
                              />
                              <ApText
                                style={{
                                  fontSize: 11,
                                  fontWeight: "500",
                                  paddingBottom: 5,
                                }}
                              >
                                : {e.ytd_self_pnl_amt}
                              </ApText>
                            </View>
                            <View style={{ flexDirection: "row" }}>
                              <ApText
                                style={{
                                  fontSize: 11,
                                  fontWeight: "500",
                                }}
                                translateKey="30DP&L"
                              />
                              <ApText
                                style={{
                                  fontSize: 11,
                                  fontWeight: "500",
                                  paddingBottom: 5,
                                }}
                              >
                                : {e.month_self_pnl_amt}
                              </ApText>
                            </View>
                          </View>
                          <View
                            style={{
                              alignSelf: "flex-end",
                              backgroundColor: "#F37021",
                              minWidth: 65,
                              borderRadius: 4,
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <ApText
                              style={{
                                fontSize: 13,
                                color: "#fff",
                                textAlign: "right",
                                paddingVertical: 5,
                                paddingRight: 5,
                              }}
                            >
                              {e.group_count}
                            </ApText>
                            <svg.UserWhiteSvg />
                          </View>
                        </View>
                      </View>
                    </View>
                  );
                })
              ) : (
                <ApEmptyState
                  svg={<ApEmptyStateSvg.List />}
                  heading="No Result Found"
                  message="Genealogy data is empty"
                />
              )}
            </>
          ) : selected == "Community" ? (
            <>
              <View
                style={{
                  padding: 15,
                  borderRadius: 10,
                  marginVertical: 10,
                  borderWidth: 2,
                  borderColor: "#F37021",
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "flex-start",
                  }}
                >
                  <View style={{ flex: 1 }}>
                    <ApText
                      style={{
                        ...theme.FONTS.Mulish_400Regular,
                        fontSize: 12,
                        lineHeight: 12 * 1.6,
                        color: theme.COLORS.bodyTextColor,
                      }}
                      translateKey="TotalActive"
                    />
                    <ApText
                      style={{
                        marginBottom: 12,
                        fontWeight: "500",
                        color: theme.COLORS.mainDark,
                        fontSize: 13,
                      }}
                    >
                      {genealogyData.group?.active}
                    </ApText>
                  </View>
                  <View style={{ flex: 1 }}>
                    <ApText
                      style={{
                        ...theme.FONTS.Mulish_400Regular,
                        fontSize: 12,
                        lineHeight: 12 * 1.6,
                        color: theme.COLORS.bodyTextColor,
                      }}
                      translateKey="TotalInActive"
                    />
                    <ApText
                      style={{
                        marginBottom: 12,
                        fontWeight: "500",
                        color: theme.COLORS.mainDark,
                        fontSize: 13,
                      }}
                    >
                      {genealogyData.group?.inactive}
                    </ApText>
                  </View>
                  <View style={{ flex: 1 }}>
                    <ApText
                      style={{
                        ...theme.FONTS.Mulish_400Regular,
                        fontSize: 12,
                        lineHeight: 12 * 1.6,
                        color: theme.COLORS.bodyTextColor,
                      }}
                      translateKey="TotalP&L"
                    />
                    <ApText
                      style={{
                        marginBottom: 12,
                        fontWeight: "500",
                        color: theme.COLORS.mainDark,
                        fontSize: 13,
                      }}
                    >
                      {genealogyData.group?.total_pnl}
                    </ApText>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "flex-start",
                  }}
                >
                  <View style={{ flex: 1 }}>
                    <ApText
                      style={{
                        ...theme.FONTS.Mulish_400Regular,
                        fontSize: 12,
                        lineHeight: 12 * 1.6,
                        color: theme.COLORS.bodyTextColor,
                        marginTop: 12,
                      }}
                      translateKey="YTDP&L"
                    />
                    <ApText
                      style={{
                        fontWeight: "500",
                        color: theme.COLORS.mainDark,
                        fontSize: 13,
                      }}
                    >
                      {genealogyData.group?.ytd_pnl}
                    </ApText>
                  </View>
                  <View style={{ flex: 1 }}>
                    <ApText
                      style={{
                        ...theme.FONTS.Mulish_400Regular,
                        fontSize: 12,
                        lineHeight: 12 * 1.6,
                        color: theme.COLORS.bodyTextColor,
                        marginTop: 12,
                      }}
                      translateKey="30DP&L"
                    />
                    <ApText
                      style={{
                        fontWeight: "500",
                        color: theme.COLORS.mainDark,
                        fontSize: 13,
                      }}
                    >
                      {genealogyData.group?.month_pnl}
                    </ApText>
                  </View>
                  <View style={{ flex: 1 }}></View>
                </View>
              </View>
            </>
          ) : (
            <></>
          )}
        </View>
      </ScrollView>
    );
  };

  return (
    <ApSafeAreaView>
      {renderHeader()}
      {loading ? <ApLoader /> : renderContent()}
    </ApSafeAreaView>
  );
};

export default Payments;
