import { useNavigation } from "@react-navigation/native";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { View, TouchableOpacity } from "react-native";
import { ApText } from "../../../../components/v1/typography";
import { theme } from "../../../../constants";
import { formatAmt, toCurrency } from "../../../../utils";
import { StyleSheet } from "react-native";

export const BotExecutor = ({
  market,
  priceList,
  e,
  openOperation,
  selectedStrategy,
  setSelectedStrategy,
  calculatePNLAmount,
  calculatePNLPerc,
}) => {
  const navigation: any = useNavigation();
  const [pnlAmount, setPnlAmount] = useState<string>();
  const [pnlPerc, setPnlPerc] = useState<string>();

  useEffect(() => {
    setPnlAmount(calculatePNLAmount(e));
    setPnlPerc(calculatePNLPerc(e));
  }, []);

  return (
    <View>
      <View
        style={{
          paddingVertical: 10,
          borderBottomColor: "#d3d3d3",
          borderBottomWidth: 1,
        }}
      >
        <View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <View style={{ flex: 1, width: "20%" }}>
              <ApText
                style={{
                  fontSize: 11,
                  textAlign: "right",
                  color: "#a1a1a1",
                }}
              >
                {moment(e?.created_at).format("YYYY-MM-DD HH:mm:ss")}
              </ApText>
            </View>
          </View>

          <View
            style={{
              marginTop: 5,
              flex: 1,
              width: "100%",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {openOperation && (
              <TouchableOpacity
                onPress={() => {
                  if (selectedStrategy.find((d) => d?.bex_id == e?.bex_id)) {
                    setSelectedStrategy(
                      selectedStrategy?.filter((d) => d?.bex_id !== e?.bex_id)
                    );
                  } else {
                    setSelectedStrategy(selectedStrategy.concat([e]));
                  }
                }}
                style={{
                  marginRight: 5,
                  width: 13,
                  height: 13,
                  borderWidth: 1,
                  borderRadius: 13,
                  borderColor: "#949494",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {selectedStrategy.find((d) => d?.bex_id == e?.bex_id) && (
                  <View
                    style={{
                      width: 7,
                      height: 7,
                      borderRadius: 10 / 2,
                      backgroundColor: "#F37021",
                    }}
                  />
                )}
              </TouchableOpacity>
            )}
            <View
              style={{
                flex: 1,
                width: "100%",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                flexWrap: "wrap",
                marginBottom: 5,
              }}
            >
              <ApText
                style={{
                  fontSize: 16,
                  fontWeight: "500",
                }}
              >
                <ApText
                  style={{
                    color: "#F37021",
                    fontSize: 17,
                    fontWeight: "bold",
                  }}
                >
                  {e?.symbol?.split("USDT")[0]}
                </ApText>
                <ApText style={{ fontSize: 14, color: "#222" }}> / USDT</ApText>
              </ApText>

              <ApText
                style={{
                  fontSize: 15,
                }}
              >
                {e?.label_code}
              </ApText>
            </View>
          </View>

          <View
            style={{
              flexDirection: "row",
              paddingTop: 5,
              paddingBottom: 10,
            }}
          >
            {market === "1" ? (
              <></>
            ) : (
              <ApText
                style={{
                  borderRadius: 3,
                  fontSize: 11,
                  marginRight: 3,
                  paddingHorizontal: 10,
                  backgroundColor: "#F37021",
                  color: "white",
                }}
              >
                {e?.leverage}X
              </ApText>
            )}
            {market === "1" ? (
              <ApText
                style={{
                  marginRight: 3,
                  borderRadius: 3,
                  fontSize: 11,
                  //paddingVertical: 1,
                  paddingHorizontal: 8,
                  backgroundColor:
                    e?.pos_side == "1" ? theme.COLORS.green : "#FF8B7E",
                  color: "white",
                }}
              >
                {e?.pos_side == "1" ? (
                  <ApText size="xs" translateKey="USDT-based" />
                ) : (
                  <ApText size="xs" translateKey="Token-based" />
                )}
              </ApText>
            ) : (
              <ApText
                style={{
                  marginHorizontal: 3,
                  borderRadius: 3,
                  fontSize: 11,
                  //paddingVertical: 1,
                  paddingHorizontal: 8,
                  backgroundColor:
                    e?.pos_side == "1" ? theme.COLORS.green : "#FF8B7E",
                  color: "white",
                }}
              >
                {e?.pos_side == "1" ? (
                  <ApText size="xs" translateKey="Long" />
                ) : (
                  <ApText size="xs" translateKey="Short" />
                )}
              </ApText>
            )}
            <ApText
              style={{
                marginHorizontal: 3,
                borderRadius: 3,
                fontSize: 11,
                // paddingVertical: 1,
                paddingHorizontal: 8,
                backgroundColor: e?.status === "0" ? "#F37021" : "#05B491",
                color: "white",
              }}
            >
              {e?.status === "0" ? (
                <ApText size="xs" translateKey="Paused" />
              ) : (
                <ApText size="xs" translateKey="Running" />
              )}
            </ApText>
            {e.plan_type !== "1" && (
              <ApText
                style={{
                  marginHorizontal: 3,
                  borderRadius: 3,
                  fontSize: 11,
                  //  paddingVertical: 1,
                  paddingHorizontal: 8,
                  backgroundColor: e?.is_reentry === "0" ? "red" : "blue",
                  color: "white",
                }}
              >
                {e?.is_reentry === "0" ? (
                  <ApText size="xs" translateKey="ReEntryOff" />
                ) : (
                  <ApText size="xs" translateKey="ReEntryOn" />
                )}
              </ApText>
            )}

            {e?.is_peer_bp === "1" ? (
              <ApText
                style={{
                  marginHorizontal: 3,
                  borderRadius: 3,
                  fontSize: 11,
                  //  paddingVertical: 1,
                  paddingHorizontal: 8,
                  backgroundColor: "#BF40BF",
                  color: "white",
                }}
              >
                <ApText size="xs" translateKey="Follow" />
              </ApText>
            ) : (
              <></>
            )}

            {e.plan_type == "1" && (
              <View style={styles.botPlanTypeOneLabel}>
                <ApText size="xs" color="#fff" translateKey="BotPlanTypeOneLabel"/>
              </View>
            )}
          </View>

          <TouchableOpacity
            onPress={() => {
              navigation.navigate("BotDetails", {
                bex_id: e?.bex_id,
              });
            }}
            style={{
              padding: 12,
              borderRadius: 10,
              marginVertical: 7,
              borderWidth: 2,
              borderColor: "#F37021",
            }}
          >
            <View
              style={{
                flexDirection: "row",
                alignItems: "flex-start",
              }}
            ></View>
            <View
              style={{
                justifyContent: "space-between",
                flexDirection: "row",
                alignItems: "flex-start",
              }}
            >
              <View>
                <ApText
                  translateKey="Qty"
                  style={{
                    ...theme.FONTS.Mulish_400Regular,
                    fontSize: 12,
                    lineHeight: 12 * 1.6,
                    color: theme.COLORS.bodyTextColor,
                  }}
                />
                <ApText
                  style={{
                    marginBottom: 12,
                    fontWeight: "500",
                    color: theme.COLORS.mainDark,
                    fontSize: 13,
                  }}
                >
                  {formatAmt(Math.abs(parseFloat(e?.bep?.pos_size)))}
                </ApText>
                <ApText
                  translateKey={
                    e.plan_type === "1" ? "PositionPrice" : "AvgPrice"
                  }
                  style={{
                    ...theme.FONTS.Mulish_400Regular,
                    fontSize: 12,
                    lineHeight: 12 * 1.6,
                    color: theme.COLORS.bodyTextColor,
                  }}
                />
                <ApText
                  style={{
                    marginBottom: 12,
                    fontWeight: "500",
                    color: theme.COLORS.mainDark,
                    fontSize: 13,
                  }}
                >
                  {formatAmt(parseFloat(e?.bep.pos_price))}
                </ApText>
                <ApText
                  translateKey={
                    e.plan_type === "1" ? "ReturnState" : "ReEntryPrice"
                  }
                  style={{
                    ...theme.FONTS.Mulish_400Regular,
                    fontSize: 12,
                    lineHeight: 12 * 1.6,
                    color: theme.COLORS.bodyTextColor,
                  }}
                />
                <ApText
                  style={{
                    fontWeight: "500",
                    color: theme.COLORS.mainDark,
                    fontSize: 13,
                  }}
                >
                  {e.plan_type === "1" ? (
                    e?.set_return?.split(",")[e.curr_set_round] || '-'
                  ) : (
                    <>
                      {e?.pos_side == "1" ? "≤ " : "≥ "}
                      {formatAmt(parseFloat(e?.next_entry_price))}
                    </>
                  )}
                </ApText>
              </View>
              <View>
                <ApText
                  translateKey="FirstOrderMargin"
                  style={{
                    ...theme.FONTS.Mulish_400Regular,
                    fontSize: 12,
                    lineHeight: 12 * 1.6,
                    color: theme.COLORS.bodyTextColor,
                  }}
                />
                <ApText
                  style={{
                    marginBottom: 12,
                    fontWeight: "500",
                    color: theme.COLORS.mainDark,
                    fontSize: 13,
                  }}
                >
                  {e?.init_fund}
                </ApText>
                <ApText
                  translateKey="LastPrice"
                  style={{
                    ...theme.FONTS.Mulish_400Regular,
                    fontSize: 12,
                    lineHeight: 12 * 1.6,
                    color: theme.COLORS.bodyTextColor,
                  }}
                />
                <ApText
                  style={{
                    marginBottom: 12,
                    fontWeight: "500",
                    color: theme.COLORS.mainDark,
                    fontSize: 13,
                  }}
                >
                  {/* {!!priceList?.length
                    ? parseFloat(
                        priceList?.filter(
                          (item) => item?.symbol === e?.symbol
                        )[0]?.lastPrice
                      ).toFixed(2)
                    : "0.00"}
                  {" USDT"} */}

                  {!!priceList?.length
                    ? formatAmt(
                        parseFloat(
                          priceList?.filter(
                            (item) => item?.symbol === e?.symbol
                          )[0]?.lastPrice
                        )
                      )
                    : "0.00"}
                  {" USDT"}
                </ApText>

                <ApText
                  translateKey="Profit"
                  style={{
                    ...theme.FONTS.Mulish_400Regular,
                    fontSize: 12,
                    color: theme.COLORS.bodyTextColor,
                    lineHeight: 12 * 1.6,
                  }}
                />
                <ApText
                  style={{
                    fontWeight: "500",
                    color: theme.COLORS.mainDark,
                    fontSize: 13,
                  }}
                >
                  {pnlAmount && pnlPerc ? (
                    <ApText
                      style={{
                        color: calculatePNLAmount(e)?.includes("-")
                          ? "#e30000"
                          : "green",
                        fontWeight: "bold",
                        paddingRight: 5,
                        fontSize: 13,
                      }}
                    >
                      {!calculatePNLAmount(e)?.includes("-") ? "+" : ""}
                      {calculatePNLAmount(e) + " "}
                    </ApText>
                  ) : (
                    <></>
                  )}
                  {/* <ApText
                    style={{
                      fontWeight: "500",
                      fontSize: 13,
                    }}
                    translateKey="USDT"
                  />*/}
                </ApText>
              </View>
              <View>
                <ApText
                  translateKey="FundUtilised"
                  style={{
                    ...theme.FONTS.Mulish_400Regular,
                    fontSize: 12,
                    lineHeight: 12 * 1.6,
                    color: theme.COLORS.bodyTextColor,
                  }}
                />
                <ApText
                  style={{
                    marginBottom: 12,
                    fontWeight: "500",
                    color: theme.COLORS.mainDark,
                    fontSize: 13,
                  }}
                >
                  {toCurrency(
                    parseFloat(e?.bep.pos_fund) /
                      parseFloat(e?.leverage || "1"),
                    ""
                  )}
                </ApText>
                <ApText
                  translateKey={
                    e.plan_type === "1" ? "PositionState" : "EntryLevel"
                  }
                  style={{
                    ...theme.FONTS.Mulish_400Regular,
                    fontSize: 12,
                    lineHeight: 12 * 1.6,
                    color: theme.COLORS.bodyTextColor,
                  }}
                />
                <ApText
                  style={{
                    marginBottom: 12,
                    fontWeight: "500",
                    color: theme.COLORS.mainDark,
                    fontSize: 13,
                  }}
                >
                  {e.plan_type === "1"
                    ? `${+e.curr_set_round + 1} - ${+e.curr_set_count + 1}`
                    : ` ${e?.bep.last_entry}/${e?.max_entries}`}
                  {/* {e?.bep.last_entry} / {e?.max_entries} */}
                </ApText>
                <ApText
                  translateKey="ReturnRate"
                  style={{
                    ...theme.FONTS.Mulish_400Regular,
                    fontSize: 12,
                    color: theme.COLORS.bodyTextColor,
                    lineHeight: 12 * 1.6,
                  }}
                />
                <ApText
                  style={{
                    fontWeight: "500",
                    color: theme.COLORS.mainDark,
                    fontSize: 13,
                  }}
                >
                  {pnlAmount && pnlPerc ? (
                    <ApText
                      style={{
                        color: calculatePNLAmount(e)?.includes("-")
                          ? "#e30000"
                          : "green",
                        fontWeight: "bold",
                        paddingRight: 5,
                        fontSize: 13,
                      }}
                    >
                      {calculatePNLPerc(e)} {"%"}
                    </ApText>
                  ) : (
                    <></>
                  )}
                </ApText>
              </View>
            </View>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};


const styles = StyleSheet.create({
  botPlanTypeOneLabel: {
    marginLeft: 5,
    backgroundColor: 'red',
    paddingHorizontal: 15,
    borderRadius: 50,
    paddingVertical: 3
  }
})