import * as React from "react";
import Svg, { G, Path } from "react-native-svg";

function MaterialSvg(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width={18.246} height={22} viewBox="0 0 18.246 22" {...props}>
      <G data-name="file-svgrepo-com (1)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}>
        <Path data-name="Path 74" d="M28.383 13.886h-6.261V7.625z" stroke="#F37021" transform="translate(-11.138 -6.625)" />
        <Path data-name="Path 75" d="M28.384 13.873v12.088a1.664 1.664 0 01-1.664 1.664H13.8a1.664 1.664 0 01-1.664-1.664V9.289A1.664 1.664 0 0113.8 7.625h8.334z" stroke="#7a8390" transform="translate(-11.138 -6.625)" />
      </G>
    </Svg>
  );
}

export default MaterialSvg;
