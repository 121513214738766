import { View, Text, TouchableOpacity, Image, FlatList } from "react-native";
import React from "react";

import TeofinShadow from "../components/TeofinShadow";
import { theme } from "../constants";

type Props = {
  arrow: string;
  AnnouncementNavType: string;
  AnnouncementTitle: string;
  onPress?: () => void;
  containerStyle?: any;
  deposit?: boolean;
  transfer?: boolean;
  bell?: boolean;
  amazon?: boolean;
  payPal?: boolean;
};

const AnnouncementNav: React.FC<Props> = ({ arrow, AnnouncementNavType, AnnouncementTitle, onPress, containerStyle, deposit, transfer, amazon, bell, payPal }) => {
  return (
    <TouchableOpacity
      style={{
        ...containerStyle,
        height: 50,
        flexDirection: "row",
        alignItems: "center",
        paddingHorizontal: 15,
        borderRadius: 10,
        backgroundColor: "#fff5f0",
      }}
      onPress={onPress}
    >
      {transfer && <Image source={require("../../assets/payment/01.png")} style={{ width: 40, height: 40, marginRight: 14 }} />}
      {bell && <Image source={require("../../assets/payment/bell.svg")} style={{ width: 15, height: 20, marginRight: 18 }} />}
      {amazon && <Image source={require("../../assets/payment/02.png")} style={{ width: 40, height: 40, marginRight: 14 }} />}
      {payPal && <Image source={require("../../assets/payment/03.png")} style={{ width: 40, height: 40, marginRight: 14 }} />}
      <View>
        <Text
          style={{
            ...theme.FONTS.H6,
            color: theme.COLORS.mainDark,
          }}
        >
          {AnnouncementTitle}
        </Text>
      </View>
      {/* <View style={{flex:1}}>
                <Text style={{textAlign:"right",...theme.FONTS.H6,}}>123</Text>
            </View> */}
      <View style={{ marginLeft: "auto" }}>
        <Image source={require("../../assets/payment/right-2-svgrepo-com.svg")} style={{ width: 20, height: 20 }} />
      </View>
    </TouchableOpacity>
  );
};

export default AnnouncementNav;
