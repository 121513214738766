import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { components } from "../components";
import {
  ApEmptyState,
  ApEmptyStateSvg,
  ApLoader,
  ApSafeAreaView,
} from "../components/v1";
import { ApText } from "../components/v1/typography";
import { useProfileState, useTransactionState } from "../context";
import { IWalletTransaction } from "../models/transaction.interface";

const TransferHistory: React.FC = ({ navigation }: any) => {
  const { profileData, getProfileData } = useProfileState();
  const { transferHistory, getTransferHistory, selectedWallet, loading } =
    useTransactionState();
  const [sortTransferHistory, setSortTransferHistory] = useState([]);
  const [sort, setSort] = useState<Record<string, IWalletTransaction[]>>({});
  const { i18n, t } = useTranslation();
  const [selected, setSelected] = useState("CreditBalance");
  //const [profileData, setProfileData] = useState({});
  const dispatch = useDispatch();
  const hideProfit = useSelector(
    (state: any) => state.hideProfitSlice.hideProfit
  );

  useEffect(() => {
    getTransferHistory(selectedWallet).then((res) => {
      setSort(
        sortTransferHistoryByDate(res as unknown as IWalletTransaction[])
      );
    });
  }, []);

  const sortTransferHistoryByDate = (
    dataObjects: IWalletTransaction[]
  ): Record<string, IWalletTransaction[]> => {
    const sortedDataObjects: Record<string, IWalletTransaction[]> = {};

    dataObjects.forEach((dataObject) => {
      dataObject.date = new Date(dataObject.created_at);
      // Get the year, month, and day of the current data object's date property
      const year = dataObject.date.getFullYear().toString();
      const month = (dataObject.date.getMonth() + 1)
        .toString()
        .padStart(2, "0");
      const day = dataObject.date.getDate().toString().padStart(2, "0");
      const dateKey = `${year}-${month}-${day}`;

      // If the sortedDataObjects object doesn't have an array for this date, create one
      if (!sortedDataObjects[dateKey]) {
        sortedDataObjects[dateKey] = [];
      }

      // Add the current data object to the array for this date
      sortedDataObjects[dateKey].push(dataObject);
    });

    return sortedDataObjects;
  };

  useEffect(() => {
    const dates = Object.keys(sort);
    setSortTransferHistory(dates as any);
  }, [sort]);

  const renderHeader = () => {
    return (
      <components.Header
        title={"TransferHistory"}
        titleTranlateKey="TransferHistory"
        goBack={true}
        screenName="MyAssets"
      />
    );
  };

  const renderContent = () => {
    return (
      <View style={{ flex: 1 }}>
        {sortTransferHistory.length > 0 ? (
          sortTransferHistory.map((date) => (
            <View key={date}>
              <ApText
                style={{
                  color: "#F37021",
                  fontSize: 17,
                  padding: 15,
                  fontWeight: "500",
                  paddingTop: 25,
                }}
              >
                {date}
              </ApText>
              <View
                style={{
                  paddingHorizontal: 15,
                  backgroundColor: "#FCF8F6",
                  margin: 15,
                  borderRadius: 10,
                  marginTop: 0,
                }}
              >
                {sort[date].map((e, index) => (
                  <View
                    style={{
                      paddingVertical: 15,
                      borderBottomWidth:
                        index + 1 === sort[date].length ? 0 : 1,
                      borderColor: "#e4e4e499",
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginBottom: 10,
                      }}
                    >
                      <ApText style={{ fontWeight: "500" }}>
                        {e.created_at.split(" ")[1]}
                      </ApText>
                      <ApText
                        style={{
                          fontWeight: "500",
                          color: "#222",
                          textAlign: "right",
                        }}
                      >
                        {e.amount}
                        {selectedWallet === "ddb_wallet"
                          ? " DDC"
                          : selectedWallet === "cash_wallet"
                          ? " USDT"
                          : " DL Point"}
                      </ApText>
                    </View>

                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <ApText style={{ color: "#ababab", fontSize: 13 }}>
                        To Account :
                      </ApText>
                      <ApText style={{ color: "#ababab", fontSize: 13 }}>
                        {e.account_name_2}
                      </ApText>
                    </View>
                  </View>
                ))}
              </View>
            </View>
          ))
        ) : (
          <ApEmptyState
            svg={<ApEmptyStateSvg.List />}
            heading="No Result Found"
            message="Transfer history is empty"
          />
        )}
      </View>
    );
  };

  return (
    <ApSafeAreaView>
      {renderHeader()}
      {loading && (
        <View style={{ paddingTop: 10 }}>
          <ApLoader />
        </View>
      )}
      {!loading && renderContent()}
    </ApSafeAreaView>
  );
};

export default TransferHistory;
