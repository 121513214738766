import React from "react";
import { FlatList, FlatListProps } from "react-native";

export const ApFlatList: React.FC<FlatListProps<any>> = (props: any) => {
  return (
    <FlatList
      onEndReachedThreshold={0.5}
      {...props}
      showsVerticalScrollIndicator={false}
      showsHorizontalScrollIndicator={false}
      // contentContainerStyle={{ paddingBottom: 32, paddingTop: 32, backgroundColor: "#fff", minHeight: "100%" }}
    />
  );
};
