import React, { useEffect, useRef } from "react";
import { View } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

import { components } from "../components";
import { theme } from "../constants";

import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { ApButton, ApSafeAreaView } from "../components/v1";
import { ApPasswordInput, ApTacInput } from "../components/v1/input";
import { ERROR_MSG } from "../constants/errors";
import { useProfileState } from "../context";
import { useAuthState } from "../context/auth";
import { ApErrorToast, ApSuccessToast } from "../services";

const FormSchema = Yup.object().shape({
  tac: Yup.string()
    .required(ERROR_MSG.VerificationCodeRequired)
    .min(6, ERROR_MSG.VerificationCodeMin),
  new_password: Yup.string()
    .min(6, ERROR_MSG.MinSecurityPass)
    .required(ERROR_MSG.SecurityPassRequired),
  confirm_password: Yup.string()
    .min(6, ERROR_MSG.MinSecurityPass)
    .required(ERROR_MSG.ConfirmSecurityPassRequired)
    .oneOf([Yup.ref("new_password")], ERROR_MSG.SecurityPassMismatch),
});

const ChangeSecurityPin: React.FC = ({ navigation }: any) => {
  const { profileData, getProfileData } = useProfileState();
  const { resetMemberPassword } = useAuthState();
  const formRef = useRef<FormikProps<any>>();

  useEffect(() => {
    getProfileData();
  }, []);

  const handleChangeSecurityPin = async (value: any) => {
    resetMemberPassword({ ...value, type: "usp" })
      .then((rs: any) => {
        ApSuccessToast("success", rs);
        navigation.navigate("Profile");
        formRef.current?.resetForm();
      })
      .catch((err) => {
        ApErrorToast("Security Password Change Failed", err);
      });
  };

  const renderHeader = () => {
    return (
      <components.Header
        title={"Change Security Password"}
        titleTranlateKey="ChangeSecurityPin"
        goBack={true}
      />
    );
  };

  const renderContent = () => {
    return (
      <Formik
        innerRef={formRef as any}
        validationSchema={FormSchema}
        initialValues={{
          tac: "",
          new_password: "",
          confirm_password: "",
          account_name: profileData.account_name,
        }}
        onSubmit={handleChangeSecurityPin}
      >
        {(props: FormikProps<any>) => (
          <KeyboardAwareScrollView
            contentContainerStyle={{
              flexGrow: 1,
              paddingHorizontal: 20,
              paddingTop: 10,
            }}
            enableOnAndroid={true}
            showsVerticalScrollIndicator={false}
          >
            <ApPasswordInput
              label="New Security Password"
              name="new_password"
              placeHolderTranslateKey="NewSecurityPassword"
              labelTranslateKey="NewSecurityPassword"
            />

            <ApPasswordInput
              label="Confirm Security Password"
              name="confirm_password"
              placeHolderTranslateKey="ConfirmNewSecurityPassword"
              labelTranslateKey="ConfirmNewSecurityPassword"
            />

            {/* <ApTextInput
              label="New Security Password"
              secureTextEntry
              name="new_password"
              // placeholder="New security password"
              labelTranslateKey="NewSecurityPassword"
              icon={<svg.CheckSvg />}
            /> */}

            {/* <ApTextInput
              label="Confirm Security Password"
              secureTextEntry
              name="confirm_password"
              // placeholder="Confirm new security password"
              labelTranslateKey="ConfirmNewSecurityPassword"
              icon={<svg.CheckSvg />}
            /> */}

            <ApTacInput
              type="usp"
              props={props}
              name="tac"
              propKey="account_name"
            />

            <View style={{ marginTop: 20 }}>
              <ApButton
                label="Save"
                round="lg"
                labelTranslateKey="Save"
                type="primary"
                onPress={props.handleSubmit}
              />
            </View>
          </KeyboardAwareScrollView>
        )}
      </Formik>
    );
  };

  return (
    <ApSafeAreaView>
      {renderHeader()}
      {renderContent()}
    </ApSafeAreaView>
  );
};

export default ChangeSecurityPin;
