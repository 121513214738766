import React, { useState } from 'react'
import { TouchableOpacity } from 'react-native'
import { svg } from '../../../svg'
import { ApTextInput } from './text'

interface Props {
    label?: string
    name?: string
    labelTranslateKey?: string
    placeholder?: string
    placeHolderTranslateKey?: string
}

export const ApPasswordInput: React.FC<Props> = ({
    label, name, labelTranslateKey, placeHolderTranslateKey, placeholder
}) => {

    const [togglePassword, setTogglePassword] = useState<boolean>(true)

  return (
    <ApTextInput
        label={label}
        name={name}
        labelTranslateKey={labelTranslateKey}
        placeholder={placeholder ? placeholder : "••••••"}
        placeHolderTranslateKey={placeHolderTranslateKey && placeHolderTranslateKey}
        secureTextEntry={togglePassword}
        rightIcon={
            <TouchableOpacity 
                style={{marginRight: 10}} 
                onPress={() => setTogglePassword(!togglePassword)}
            >
                {
                    togglePassword ? <svg.EyeOffSvg /> : <svg.EyeOnSvg />
                }
            </TouchableOpacity>
        }
        containerStyle={{
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
        }}
        inputStyle={{
            paddingLeft: 7,
            paddingRight: 7,
        }}
    />      
  )
}
