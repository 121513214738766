import React, { useEffect, useRef } from "react";
import { View } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

import { components } from "../components";
import { theme } from "../constants";
import { svg } from "../svg";

import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { ApButton, ApSafeAreaView } from "../components/v1";
import { ApTacInput, ApTextInput } from "../components/v1/input";
import { ApText } from "../components/v1/typography";
import { ERROR_MSG } from "../constants/errors";
import { useProfileState, useTransactionState } from "../context";

const FormSchema = Yup.object().shape({
  tac: Yup.string()
    .required(ERROR_MSG.VerificationCodeRequired)
    .min(6, ERROR_MSG.VerificationCodeMin),
  target_id: Yup.string().required(ERROR_MSG.ReceiverAccountRequired),
  amount: Yup.string().required(ERROR_MSG.AmountRequired),
  sec_password: Yup.string().required(ERROR_MSG.PasswordRequired),
});

const SignIn: React.FC = ({ navigation, route }: any) => {
  const wallet = route.params?.wallet && route.params?.wallet;
  const { profileData, getProfileData } = useProfileState();
  const formRef = useRef<FormikProps<any>>();
  const { transfer, loading, selectedWallet, getDDCoin, getUSDT, getDLPoint } =
    useTransactionState();

  useEffect(() => {
    getProfileData();
  }, []);

  const handleTransfer = async (value: any) => {
    transfer({
      amount: value.amount,
      sec_password: value.sec_password,
      tac: value.tac,
      target_id: value.target_id,
      wallet_type: selectedWallet,
    }).then((rs) => {
      formRef.current?.resetForm();
      getProfileData();
      selectedWallet === "ddb_wallet"
        ? getDDCoin()
        : selectedWallet === "doli_wallet"
        ? getDLPoint()
        : getUSDT();
    });
  };

  const renderHeader = () => {
    return (
      <components.Header
        title="Transfer"
        titleTranlateKey="Transfer"
        goBack={true}
        transferHistory={true}
      />
    );
  };

  const renderContent = () => {
    return (
      <Formik
        innerRef={formRef as any}
        validationSchema={FormSchema}
        initialValues={{
          tac: "",
          target_id: "",
          amount: "",
          sec_password: "",
          account_name: profileData.email,
          usdt_quantity: profileData[selectedWallet]
            ? profileData[selectedWallet]
            : "Fetching",
        }}
        onSubmit={handleTransfer}
      >
        {(props: FormikProps<any>) => (
          <KeyboardAwareScrollView
            contentContainerStyle={{
              flexGrow: 1,
              paddingHorizontal: 20,
            }}
          >
            <View
              style={{
                paddingTop: 10,
                paddingBottom: theme.SIZES.height * 0.03,
                maxWidth: "100%",
              }}
            >
              <ApTextInput
                name="target_id"
                labelTranslateKey="ReceivingAccount"
                label="Receiving Account"
                placeholder="Please enter the account number"
                placeHolderTranslateKey="PlaceholderEnterAccountNumber"
                icon={<svg.CheckSvg />}
              />

              <ApTextInput
                max
                maxAmount={profileData[selectedWallet]}
                containerStyle={{ paddingBottom: 0 }}
                labelTranslateKey="Amount"
                name="amount"
                label="Amount"
                keyboardType="numeric"
                placeholder="Enter the transfer of amount"
                placeHolderTranslateKey="PlaceholderEnterTransferAmount"
                icon={<svg.CheckSvg />}
              />
              <ApText
                size="xs"
                style={{
                  paddingLeft: 5,
                  marginBottom: 10,
                  marginTop: 10,
                  color: "#F37021",
                }}
              >
                <ApText size="xs" translateKey="Balance" />
                {" : "}
                {profileData[selectedWallet]}
              </ApText>

              <ApTextInput
                secureTextEntry
                labelTranslateKey="SecPassword"
                label="Security password"
                name="sec_password"
                placeholder="Please enter security password"
                placeHolderTranslateKey="PlaceholderEnterSecPassword"
                icon={<svg.CheckSvg />}
              />

              <ApTacInput
                type="tr"
                props={props}
                name="tac"
                propKey="account_name"
              />

              {/* <ApTextInput
                disabled
                //labelTranslateKey="USDTBalance"
                label={selectedWallet === 'ddb_wallet' ? "DD Coin Balance" :
                selectedWallet === 'doli_wallet' ? "DL Point Balance" : "USDT Balance"}
                name="usdt_quantity"
                placeholder=""
                icon={<svg.CheckSvg />}
              /> */}

              <View
                style={{
                  backgroundColor: "#FFD9CF",
                  padding: 12,
                  borderRadius: 10,
                  marginTop: 30,
                  marginBottom: 10,
                  flexDirection: "row",
                }}
              >
                <ApText
                  style={{ color: "#F37021", width: "80%", fontSize: 13 }}
                  translateKey={selectedWallet === "doli_wallet"? "TransferDoliPageNote" : "TransferUSDTPageNote"}
                />
              </View>

              <ApButton
                loading={loading}
                disabled={loading}
                labelTranslateKey={loading ? "Loading" : "Transfer"}
                label="Transfer"
                round="lg"
                type="primary"
                onPress={props.handleSubmit}
              />
            </View>
          </KeyboardAwareScrollView>
        )}
      </Formik>
    );
  };

  return (
    <ApSafeAreaView>
      {renderHeader()}
      {renderContent()}
    </ApSafeAreaView>
  );
};

export default SignIn;
