import * as React from "react";
import Svg, { G, Path } from "react-native-svg";

function ApiSvg(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width={30.024} height={18.717} viewBox="0 0 30.024 18.717" {...props}>
      <G data-name="API Icon-01">
        <Path
          data-name="Path 18"
          d="M62.533 52.434a5.413 5.413 0 00-4.158-4.56.632.632 0 01-.544-.572 6.527 6.527 0 00-7.195-5.036 6.426 6.426 0 00-4.556 2.781.248.248 0 01-.3.123 7.965 7.965 0 00-1.038-.137 5.235 5.235 0 00-5.182 3.641c-.082.261-.137.526-.2.778-.361.05-.723.069-1.07.146a4.657 4.657 0 00.091 9.13 25.276 25.276 0 004.78.165c1.688-.009 3.376-.05 5.063-.027q3.629.048 7.263-.023c.457-.009.91-.023 1.354-.05a5.919 5.919 0 004.231-1.7 5.454 5.454 0 001.461-4.659zm-18.8-1.669z"
          fill="none"
          stroke="#7a8390"
          strokeWidth={2}
          transform="translate(-33.603 -41.201)"
        />
        <G data-name="Group 23" fill="#F37021">
          <Path
            data-name="Path 19"
            d="M182.423 228.837a5.026 5.026 0 00.525 0 .483.483 0 01.549.334c.573 1.252 1.155 2.5 1.732 3.752.053.11.1.224.153.334.072.151.01.247-.153.247a27.43 27.43 0 01-1.217 0 .387.387 0 01-.22-.119.409.409 0 01-.091-.16c-.076-.174-.1-.42-.239-.507s-.4-.037-.606-.037h-1.408a.217.217 0 00-.243.155c-.32.809-.22.658-1.05.672h-.477c-.267 0-.315-.078-.21-.306l1.188-2.564c.224-.484.458-.969.673-1.458a.48.48 0 01.539-.343 3.752 3.752 0 00.555 0zm-.038 1.344l-.616 1.38h1.217c-.172-.42-.339-.818-.506-1.22-.014-.028-.038-.06-.095-.16z"
            transform="translate(-33.603 -41.201) translate(41.24 50.656) translate(-179.417 -228.82)"
          />
          <Path
            data-name="Path 20"
            d="M317.632 232.572v.978c0 .21-.081.311-.31.306-.31-.009-.62 0-.931 0-.2 0-.291-.087-.291-.274v-4.108c0-.2.1-.288.31-.288.888 0 1.78-.009 2.668.009a2.6 2.6 0 011.494.457 1.529 1.529 0 01-.062 2.509 2.512 2.512 0 01-1.346.4c-.496.024-.997.011-1.532.011zm0-1.056c.453 0 .878.014 1.308 0a.63.63 0 00.64-.539.553.553 0 00-.439-.663c-.5-.069-1-.064-1.5-.087-.004.457-.004.859-.004 1.289z"
            transform="translate(-33.603 -41.201) translate(41.24 50.656) translate(-309.99 -229.167)"
          />
          <Path
            data-name="Path 21"
            d="M432.589 230.338H432c-.2 0-.31-.091-.306-.292s0-.379 0-.571a.241.241 0 01.272-.274h2.787a.246.246 0 01.282.247 5.729 5.729 0 010 .64c-.01.174-.124.256-.3.256h-.582v2.4h.558c.248.009.325.082.329.32v.526c0 .192-.1.288-.31.288h-2.739c-.2 0-.3-.082-.3-.27v-.571c0-.192.105-.292.306-.292h.587z"
            transform="translate(-33.603 -41.201) translate(41.24 50.656) translate(-420.308 -229.182)"
          />
        </G>
      </G>
    </Svg>
  );
}

export default ApiSvg;
