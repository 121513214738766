import React, { Dispatch, SetStateAction, useState } from "react";
import { IConfig, IPriceListParams } from "../models";
import { ApiService } from "../services/ApiService";
import { MarketService } from "../services/MarketService";

export const OConfigProviders = "facebook" || "google";

interface IConfigState {
  priceList: any[];
  loading: boolean;
  error: any;
  config: Partial<IConfig>;
  getConfig: () => Promise<any>;
  getPriceList: (params?:IPriceListParams) => Promise<void>;
  setPriceList: Dispatch<SetStateAction<any[]>>;
}



const ConfigContext = React.createContext<IConfigState>({
  priceList: [],
  loading: true,
  error: "",
  config: {},
  getPriceList(params?:IPriceListParams) {
    return null;
  },
  getConfig() {
    return null;
  },
  setPriceList() {
    return null;
  },
} as IConfigState);

const useConfigState = () => {
  const context = React.useContext(ConfigContext);
  if (context === undefined) {
    throw new Error("app dispatch must be used within app global provider");
  }

  return context;
};

interface IProps {
  children: React.ReactNode;
}

const ConfigContextProvider: React.FC<IProps> = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState();
  const [config, setConfig] = useState<Partial<IConfig>>({});
  const [priceList, setPriceList] = useState<any[]>([]);

  const getConfig = () => {
    return new Promise((resolve, reject) => {
      ApiService.get(`config/getConfig`).then((rs) => {
        resolve(rs.data.data)
        setConfig(rs?.data?.data);
      }).catch(err => {
        reject(err)
      })
    })
  };

  const getPriceList = (params?:IPriceListParams): Promise<void> => {
    const promise =
      params?.from == "bdd"
        ? MarketService.getBddPriceList(params)
        : MarketService.getBnPriceList();
    return promise.then((rs) => {
      setPriceList(rs);
      return;
    });
  };

  return (
    <ConfigContext.Provider
      value={{
        loading,
        error,
        config,
        priceList,
        getConfig,
        getPriceList,
        setPriceList,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

export { ConfigContextProvider, useConfigState };
