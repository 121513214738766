import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { API_KEY, BASE_URL, RequestStatus } from "../constants/master";
// const { setupCache } = require('axios-cache-interceptor');

const api = axios.create({
  baseURL: `${BASE_URL}/api`,
});

// setupCache(api, {});

const interceptRequest = () => {
  api.interceptors.request.use(
    async function (config) {
      const accessToken = await AsyncStorage.getItem("accessToken");
      // config.baseURL = `${BASE_URL}/api`;
      // config.baseURL = "http://localhost/bddt/api";

      console.log(config.headers["skipContentType"]);

      if (!config.headers["skipContentType"])
        config.headers["Content-Type"] =
          "application/x-www-form-urlencoded; charset=UTF-8";
      if (accessToken != undefined) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }

      console.log(config.headers["Content-Type"]);
      config.data = { ...config.data, app_key: API_KEY };

      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );
};

const interceptResponse = () => {
  try {
    api.interceptors.response.use(
      (response) => {
        if (response.data?.status === RequestStatus.ERROR) {
          return Promise.reject(response.data?.msg);
        }

        return response;
      },
      async function (error) {
        return Promise.reject(error);
      }
    );
  } catch (error) {}
};

interceptRequest();
interceptResponse();

export class ApiService {
  public static get get() {
    return api.get;
  }
  public static get post() {
    return api.post;
  }
  public static get put() {
    return api.put;
  }
  public static get delete() {
    return api.delete;
  }
  public static get postForm() {
    return api.postForm;
  }
  public static get axios() {
    return api;
  }
}
