import { Formik, FormikProps } from "formik";
import { t } from "i18next";
import React, { useRef } from "react";
import { ScrollView, View } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import * as Yup from "yup";
import { components } from "../components";
import { ApButton, ApSafeAreaView, ApTextInput } from "../components/v1";
import { ApText } from "../components/v1/typography";
import { ERROR_MSG } from "../constants/errors";
import { useProfileState } from "../context";

const FormSchema = Yup.object().shape({
  credit_alert_amount: Yup.string().required(ERROR_MSG.AmountRequired),
});

const CreditAlert = () => {
  const formRef = useRef<FormikProps<any>>();
  const { loading, updateCreditAlertAmount, profileData } = useProfileState();

  const renderHeader = () => {
    return <components.Header title={t("CreditAlert")} goBack={true} />;
  };

  const handleSubmit = (val: any) => {
    updateCreditAlertAmount(val);
  };

  const renderContent = () => {
    return (
      <ScrollView
        contentContainerStyle={{
          flexGrow: 1,
          paddingHorizontal: 20,
        }}
      >
        <View
          style={{
            backgroundColor: "#FFD9CF",
            padding: 12,
            borderRadius: 10,
            marginTop: 20,
            marginBottom: 20,
            flexDirection: "row",
          }}
        >
          <ApText
            style={{ color: "#F37021", width: "80%", fontSize: 13 }}
            translateKey="CreditAlertMainHint"
          />
        </View>

        <Formik
          innerRef={formRef as any}
          validationSchema={FormSchema}
          initialTouched={{ account_name: true }}
          initialValues={{
            credit_alert_amount: profileData?.credit_alert_amount || "",
          }}
          onSubmit={handleSubmit}
        >
          {(props: FormikProps<any>) => (
            <KeyboardAwareScrollView
              enableOnAndroid={true}
              showsVerticalScrollIndicator={false}
            >
              <ApTextInput
                label="Amount"
                name="credit_alert_amount"
                placeHolderTranslateKey="AmountPlaceholder"
                labelTranslateKey="Amount"
              />

              <View style={{ marginVertical: 20 }}>
                <ApButton
                  loading={loading}
                  label="Save"
                  labelTranslateKey="Save"
                  round="lg"
                  type="primary"
                  onPress={props.handleSubmit}
                />
              </View>

              <View
                style={{
                  backgroundColor: "#FFD9CF",
                  padding: 12,
                  borderRadius: 10,
                  marginTop: 20,
                  marginBottom: 20,
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    marginBottom: 5,
                  }}
                >
                  <ApText style={{ color: "#F37021", marginTop: 0 }}>
                    {"\u2022"}
                  </ApText>
                  <ApText
                    style={{
                      color: "#F37021",
                      fontSize: 13,
                      marginLeft: 5,
                    }}
                    translateKey="CreditAlertBulletHintOne"
                  />
                </View>

                <View
                  style={{
                    flexDirection: "row",
                    marginBottom: 5,
                  }}
                >
                  <ApText style={{ color: "#F37021", marginTop: 0 }}>
                    {"\u2022"}
                  </ApText>
                  <ApText
                    style={{
                      color: "#F37021",
                      fontSize: 13,
                      marginLeft: 5,
                    }}
                    translateKey="CreditAlertBulletHintTwo"
                  />
                </View>

                <View 
                  style={{ 
                    flexDirection: "row",
                    marginBottom: 5, }}
                >
                  <ApText style={{ color: "#F37021", marginTop: 0 }}>
                    {"\u2022"}
                  </ApText>
                  <ApText
                    style={{
                      color: "#F37021",
                      fontSize: 13,
                      marginLeft: 5,
                    }}
                    translateKey="CreditAlertBulletHintThree"
                  />
                </View>

                <View 
                  style={{ 
                    flexDirection: "row" }}
                >
                  <ApText style={{ color: "#F37021", marginTop: 0 }}>
                    {"\u2022"}
                  </ApText>
                  <ApText
                    style={{
                      color: "#F37021",
                      fontSize: 13,
                      marginLeft: 5,
                    }}
                    translateKey="CreditAlertBulletHintFour"
                  />
                </View>
              </View>
            </KeyboardAwareScrollView>
          )}
        </Formik>
      </ScrollView>
    );
  };

  return (
    <ApSafeAreaView>
      {renderHeader()}
      {renderContent()}
    </ApSafeAreaView>
  );
};

export default CreditAlert;
