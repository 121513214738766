import { useNavigation } from "@react-navigation/native";
import React, { useEffect, useState } from "react";
import { Image, ScrollView, TouchableOpacity, View } from "react-native";
import { RefreshControl } from "react-native-web-refresh-control";
import { components } from "../components";
import { ApFlatList, ApLoader, ApSafeAreaView } from "../components/v1";
import { ApText } from "../components/v1/typography";
import { theme } from "../constants";
import { useProfileState } from "../context";

const AnnouncementList: React.FC = () => {
  const { announcementList, getAnnouncementList, loading, setSelectedAnnoucement } = useProfileState();
  const [refreshing, setRefreshing] = useState(false);
  const navigation: any = useNavigation();

  useEffect(() => {
    getAnnouncementList();
  }, []);

  const handleRefresh = () => {
    setRefreshing(true);
    getAnnouncementList().finally(() => {
      setRefreshing(false);
    });
  };

  const renderHeader = () => {
    return (
      <components.Header
        title="Announcement List"
        titleTranlateKey="AnnouncementList"
        goBack={true}
      />
    );
  };

  const RenderItem = ({ item, index }: any) => (
    <TouchableOpacity
      onPress={() => {
        setSelectedAnnoucement(item)
        navigation.navigate("AnnouncementDetails", {
          content: item?.content,
        });
      }}
    >
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          paddingVertical: 15,
          borderBottomWidth: index + 1 !== announcementList.length && 1,
          borderColor: "#e4e4e499",
        }}
      >
        <View style={{ width: "90%" }}>
          <ApText numberOfLines={1} style={{ fontWeight: "bold" }}>
            {item?.title}
          </ApText>
          {/* <ApText
            numberOfLines={1}
            style={{
              color: "#8e8e8e",
              fontSize: 14,
              lineHeight: 18,
              paddingTop: 5,
            }}
          >
            {item?.content}
          </ApText> */}
        </View>

        <View
          style={{
            width: "10%",
            marginLeft: "auto",
            alignSelf: "center",
            alignItems: "flex-end",
          }}
        >
          <Image
            source={require("../../assets/other-icons/14.png")}
            style={{ width: 6, height: 9 }}
          />
        </View>
      </View>
    </TouchableOpacity>
  );

  const renderContent = () => {
    return (
      <ScrollView
        contentContainerStyle={{
          flexGrow: 1,
        }}
        showsVerticalScrollIndicator={false}
        bounces={false}
        refreshControl={
          <RefreshControl refreshing={loading} onRefresh={handleRefresh} />
        }
      >
        <View
          style={{
            flex: 1,
            marginHorizontal: 15,
            paddingHorizontal: 15,
            backgroundColor: "#fff5f0ba",
            borderRadius: 10,
            marginVertical: 25,
            paddingVertical: 15,
          }}
        >
          <ApFlatList
            renderItem={RenderItem}
            keyExtractor={(item) => item?._id}
            data={announcementList}
            // refreshing={refreshing}
            // onRefresh={handleRefresh}
            ListEmptyComponent={
              <ApText
                style={{ marginBottom: 6 }}
                translateKey="NoAnnouncements"
              />
            }
          />
          {/* {announcementList.length > 0 ? (
            announcementList.map((e, index) => (
              <TouchableOpacity
                key={index}
                onPress={() => {
                  navigation.navigate("AnnouncementDetails", {
                    content: e?.content,
                  });
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingVertical: 15,
                    borderBottomWidth: index + 1 !== announcementList.length && 1,
                    borderColor: "#e4e4e499",
                  }}
                >
                  <View style={{ width: "90%" }}>
                    <ApText numberOfLines={1} style={{ fontWeight: "bold" }}>
                      {e?.title}
                    </ApText>
                    <ApText
                      numberOfLines={1}
                      style={{
                        color: "#8e8e8e",
                        fontSize: 14,
                        lineHeight: 18,
                        paddingTop: 5,
                      }}
                    >
                      {e?.content}
                    </ApText>
                  </View>

                  <View
                    style={{
                      width: "10%",
                      marginLeft: "auto",
                      alignSelf: "center",
                      alignItems: "flex-end",
                    }}
                  >
                    <Image
                      source={require("../../assets/other-icons/14.png")}
                      style={{ width: 6, height: 9 }}
                    />
                  </View>
                </View>
              </TouchableOpacity>
            ))
          ) : (
            <ApText style={{ marginBottom: 6 }}>No Announcements</ApText>
          )} */}
        </View>
      </ScrollView>
    );
  };

  return (
    <ApSafeAreaView style={{ flex: 1, backgroundColor: theme.COLORS.bgColor }}>
      {renderHeader()}
      {loading ? <ApLoader /> : renderContent()}
    </ApSafeAreaView>
  );
};

export default AnnouncementList;
