import React from "react";
import { ScrollView, View, useWindowDimensions } from "react-native";
import { components } from "../components";
import { ApHtmlText } from "../components/v1/typography";

const TutorialDetails: React.FC = ({ navigation, route }: any) => {
  const { width } = useWindowDimensions();

  const renderHeader = () => {
    return <components.Header title="Tutorial Details" titleTranlateKey="TutorialDetails" goBack={true} />;
  };

  const renderContent = () => {
    return (
      <View
        style={{
          marginHorizontal: 15,
          paddingHorizontal: 15,
          backgroundColor: "#fff5f0ba",
          borderRadius: 10,
          marginVertical: 25,
          paddingVertical: 15,
        }}
      >
        <ApHtmlText content={route.params.id} width={width} />
      </View>
    );
  };

  return (
    <ScrollView
      contentContainerStyle={{
        flexGrow: 1,
      }}
      showsVerticalScrollIndicator={false}
      bounces={false}
    >
      {renderHeader()}
      {renderContent()}
    </ScrollView>
  );
};

export default TutorialDetails;
