import * as React from "react";
import Svg, { Path } from "react-native-svg";

type Props = {
  goBackColor?: string;
  width?: number
  height?: number
};

const GoBackSvg: React.FC<Props> = ({ goBackColor = "#F37021", height, width }) => (
  <Svg width={width || 8} height={height || 14} fill="none">
    <Path d="M7 13 1 7l6-6" stroke={goBackColor} strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
  </Svg>
);

export default GoBackSvg;
