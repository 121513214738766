

export const ERROR_MSG = {
    EmailRequired:"EmailRequired",
    InvalidEmail:"InvalidEmail",
    VerificationCodeRequired:"VerificationCodeRequired",
    VerificationCodeMin:"VerificationCodeMin",
    SecurityPassRequired:"SecurityPassRequired",
    ConfirmSecurityPassRequired:"ConfirmSecurityPassRequired",
    MinSecurityPass:"MinSecurityPass",
    SecurityPassMismatch:"SecurityPassMismatch",
    PasswordRequired:"PasswordRequired",
    ConfirmPasswordRequired:"ConfirmPasswordRequired",
    MinPassword:"MinPassword",
    PasswordMismatch:"PasswordMismatch",
    AmountRequired:"AmountRequired",
    ReceiverAccountRequired:"ReceiverAccountRequired",
    AgreeTerms:"AgreeTerms",
    AgreeTermsAndPolicies: "AgreeTermsAndPolicies",
    WalletAddressRequired:"WalletAddressRequired",
}