import React from "react";
import { View } from "react-native";
import { ApText } from "../../../../components/v1/typography";
import { toCurrency } from "../../../../utils";

const Balance = ({
  assetInfoStatus,
  market,
  assetInfo,
  futureUtilisedFund,
  spotUtilisedFund,
}) => {
  return (
    <View
      style={{
        paddingBottom: 7,
        borderBottomColor: "#d3d3d3",
        borderBottomWidth: 1,
      }}
    >
      {market === "2" ? (
        <ApText style={{ lineHeight: 22 }}>
          <ApText translateKey="AssetBalance" />
          {" : "}
          {assetInfoStatus === "-1"
            ? " 0.00"
            : assetInfo?.futureBal
            ? toCurrency(parseFloat(assetInfo?.futureBal), " ")
            : " 0.00"}{" "}
          USDT
        </ApText>
      ) : (
        <ApText style={{ lineHeight: 22 }}>
          <ApText translateKey="Balance" />
          {" : "}
          {assetInfoStatus === "-1"
            ? " 0.00"
            : assetInfo?.spotBal
            ? toCurrency(parseFloat(assetInfo?.spotBal), " ")
            : " 0.00"}{" "}
          USDT
        </ApText>
      )}

      {market === "1" ? (
        <ApText>
          <ApText translateKey="SpotUtilisedFund" />
          {" : "}
          {assetInfoStatus === "-1" ? " 0.00" : toCurrency(spotUtilisedFund)} USDT
        </ApText>
      ) : (
        <></>
      )}

      {/* {market === "1" ? (
        <ApText>
          <ApText translateKey="UtilisedFund" />
          {" : "}
          {assetInfoStatus === "-1"
            ? " 0.00"
            : market === "2"
            ? toCurrency(futureUtilisedFund, " ")
            : toCurrency(spotUtilisedFund, " ")}{" "}
          USDT
        </ApText>
      ) : (
        <></>
      )} */}

      {market === "2" ? (
        <>
          <ApText style={{ lineHeight: 22 }}>
            <ApText translateKey="FutureFloatPnl" />
            {" : "}
            {assetInfoStatus === "-1"
              ? " 0.00"
              : assetInfo?.futureFloatPnl
              ? toCurrency(parseFloat(assetInfo?.futureFloatPnl), " ")
              : " 0.00 "}{" "}
            USDT
          </ApText>

          <ApText style={{ lineHeight: 22 }}>
            <ApText translateKey="FutureMarginBal" />
            {" : "}
            {assetInfoStatus === "-1"
              ? " 0.00"
              : assetInfo?.futureMarginBal
              ? toCurrency(parseFloat(assetInfo?.futureMarginBal), " ")
              : " 0.00 "}{" "}
            USDT
          </ApText>

          <ApText style={{ lineHeight: 22 }}>
            <ApText translateKey="AvailableMarginBal" />
            {" : "}
            {assetInfoStatus === "-1"
              ? " 0.00"
              : assetInfo?.futureAvaiBal
              ? toCurrency(parseFloat(assetInfo?.futureAvaiBal), " ")
              : " 0.00 "}{" "}
            USDT
          </ApText>
        </>
      ) : (
        <></>
      )}
    </View>
  );
};

export default Balance;
