import * as React from "react";
import Svg, { Path } from "react-native-svg";

function ProfileFillSvg(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width={17.212} height={20} viewBox="0 0 17.212 20" {...props}>
      <Path
        data-name="Path 14889"
        d="M48.485 427.24a5.081 5.081 0 002.249-4.167 5.567 5.567 0 00-11.11 0 5.083 5.083 0 002.248 4.167 8.066 8.066 0 00-5.3 7.433v3.2h17.213v-3.2a8.066 8.066 0 00-5.3-7.433z"
        transform="translate(-36.573 -417.876)"
        fill="#f37021"
      />
    </Svg>
  );
}

export default ProfileFillSvg;
