import * as React from "react";
import Svg, { Path } from "react-native-svg";

function ExclamationSymbolSvg(props) {
  return (
    <Svg fill="#c2c2c2" height="80px" width="80px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.963 27.963" xmlSpace="preserve" {...props}>
      <Path d="M13.983 0C6.261 0 .001 6.259.001 13.979c0 7.724 6.26 13.984 13.982 13.984s13.98-6.261 13.98-13.984C27.963 6.259 21.705 0 13.983 0zm0 26.531c-6.933 0-12.55-5.62-12.55-12.553 0-6.93 5.617-12.548 12.55-12.548 6.931 0 12.549 5.618 12.549 12.548-.001 6.933-5.619 12.553-12.549 12.553z" />
      <Path d="M15.579 17.158L16.191 4.579 11.804 4.579 12.414 17.158z" />
      <Path d="M13.998 18.546c-1.471 0-2.5 1.029-2.5 2.526 0 1.443.999 2.528 2.444 2.528h.056c1.499 0 2.469-1.085 2.469-2.528-.026-1.497-.999-2.526-2.469-2.526z" />
    </Svg>
  );
}

export default ExclamationSymbolSvg;
