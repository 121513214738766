import GoBackSvg from "./GoBackSvg";
import UserOneSvg from "./UserOneSvg";
import ArrowOneSvg from "./ArrowOneSvg";
import ArrowThreeSvg from "./ArrowThreeSvg";
import MessageSvg from "./MessageSvg";
import LogOutSvg from "./LogOutSvg";
import FaceIdSvg from "./FaceIdSvg";
import EditPhotoSvg from "./EditPhotoSvg";
import CalendarSvg from "./CalendarSvg";
import FileTextSvg from "./FileTextSvg";
import LogoSvg from "./LogoSvg";
import CheckSvg from "./CheckSvg";
import EyeOffSvg from "./EyeOffSvg";
import FacebookSvg from "./FacebookSvg";
import TwitterSvg from "./TwitterSvg";
import GoogleSvg from "./GoogleSvg";
import ReportSvg from "./ReportSvg";
import SafeDepositSvg from "./SafeDepositSvg";
import WalletSvg from "./WalletSvg";
import NotificationSvg from "./NotificationSvg";
import MoreSvg from "./MoreSvg";
import UserSvg from "./UserSvg";
import CreditCardSvg from "./CreditCardSvg";
import TransferSvg from "./TransferSvg";
import AmazonSvg from "./AmazonSvg";
import TypeCardSvg from "./TypeCardSvg";
import HomeTestSvg from "./HomeTestSvg";
import RankingSvg from "./RankingSvg";
import PiggyBankSvg from "./PiggyBankSvg";
import InfoSvg from "./InfoSvg";
import LoanWalletSvg from "./LoanWalletSvg";
import QuestionOpenSvg from "./QuestionOpenSvg";
import QuestionCloseSvg from "./QuestionCloseSvg";
import AssetSvg from "./AssetSvg";
import CommissionSvg from "./CommissionSvg";
import TeamSvg from "./TeamSvg";
import LogSvg from "./LogSvg";
import ReferralSvg from "./ReferralSvg";
import LanguageSvg from "./LanguageSvg";
import HelpSvg from "./HelpSvg";
import SettingSvg from "./SettingSvg";
import AccountSvg from "./AccountSvg";
import ApiSvg from "./ApiSvg";
import PurchaseSvg from "./PurchaseSvg";
import DuobaoSvg from "./DuobaoSvg";
import TutorialSvg from "./TutorialSvg";
import MaterialSvg from "./MaterialSvg";
import ChatSvg from "./ChatSvg";
import ArrowTwoSvg from "./ArrowTwoSvg";
import VerifySvg from "./VerifySvg";
import StarSvg from "./StarSvg";
import EyeOnSvg from "./EyeOnSvg";
import ExportSvg from "./ExportSvg";
import InportSvg from "./InportSvg";
import HomeSvg from "./HomeSvg";
import HomeFillSvg from "./HomeFillSvg";
import TradeSvg from "./TradeSvg";
import TradeFillSvg from "./TradeFillSvg";
import FollowSvg from "./FollowSvg";
import FollowFillSvg from "./FollowFillSvg";
import ProfileSvg from "./ProfileSvg";
import ProfileFillSvg from "./ProfileFillSvg";
import ArrowFourSvg from "./ArrowFourSvg";
import ArrowFiveSvg from "./ArrowFiveSvg";
import CheckTwo from "./CheckTwo";
import SearchSvg from "./SearchSvg";
import ExclamationSymbolSvg from "./ExclamationSymbolSvg";
import RycleBinSvg from "./RycleBinSvg";
import closeSvg from "./closeSvg";
import ArrowSixSvg from "./ArrowSixSvg";
import ArrowSevenSvg from "./ArrowSevenSvg";
import EyeOffWhiteSvg from "./EyeOffWhiteSvg";
import EyeOnWhiteSvg from "./EyeOnWhiteSvg";
import CloseWhiteSvg from "./CloseWhiteSvg";
import HistorySvg from "./HistorySvg";
import CopySvg from "./CopySvg";
import UserWhiteSvg from "./UserWhiteSvg";
import FilterSvg from "./FilterSvg";
import CancelSvg from "./CancelSvg"
import RecyleBinSvg from "./RecycleBin"

const svg = {
  RecyleBinSvg,
  CancelSvg,
  InportSvg,
  ArrowFourSvg,
  ArrowFiveSvg,
  CheckTwo,
  HomeSvg,
  HomeFillSvg,
  FollowSvg,
  FollowFillSvg,
  TradeSvg,
  TradeFillSvg,
  ProfileSvg,
  ProfileFillSvg,
  VerifySvg,
  ExportSvg,
  EyeOnSvg,
  ArrowThreeSvg,
  StarSvg,
  AccountSvg,
  ArrowTwoSvg,
  ApiSvg,
  PurchaseSvg,
  DuobaoSvg,
  TutorialSvg,
  MaterialSvg,
  ChatSvg,
  GoBackSvg,
  UserOneSvg,
  ArrowOneSvg,
  RankingSvg,
  MessageSvg,
  LogOutSvg,
  FaceIdSvg,
  EditPhotoSvg,
  CalendarSvg,
  FileTextSvg,
  LogoSvg,
  CheckSvg,
  EyeOffSvg,
  FacebookSvg,
  TwitterSvg,
  GoogleSvg,
  ReportSvg,
  SafeDepositSvg,
  WalletSvg,
  NotificationSvg,
  MoreSvg,
  UserSvg,
  CreditCardSvg,
  TransferSvg,
  AmazonSvg,
  TypeCardSvg,
  PiggyBankSvg,
  InfoSvg,
  LoanWalletSvg,
  QuestionOpenSvg,
  QuestionCloseSvg,
  HomeTestSvg,
  AssetSvg,
  CommissionSvg,
  TeamSvg,
  LogSvg,
  ReferralSvg,
  LanguageSvg,
  HelpSvg,
  SettingSvg,
  SearchSvg,
  ExclamationSymbolSvg,
  RycleBinSvg,
  closeSvg,
  ArrowSixSvg,
  ArrowSevenSvg,
  EyeOffWhiteSvg,
  EyeOnWhiteSvg,
  CloseWhiteSvg,
  HistorySvg,
  CopySvg,
  FilterSvg,
  UserWhiteSvg,
};

export { svg };
