import React from "react";
import { Platform, SafeAreaView, StyleProp, ViewStyle } from "react-native";

interface IProps {
  children?: React.ReactNode;
  style?: StyleProp<ViewStyle>;
}

export const ApSafeAreaView: React.FC<IProps> = ({ children, style }) => {
  return (
    <SafeAreaView
      style={[
        {
          flex:1,
          // backgroundColor: "#FCF8F6",
          marginTop: Platform.OS === "ios" ? -15 : 0,
        },
        style,
      ]}
    >
      {children}
    </SafeAreaView>
  );
};
