import * as React from "react";
import Svg, { G, Path, Circle } from "react-native-svg";

function closeSvg(props) {
  return (
    <Svg width="25px" height="25px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Path fillRule="nonzero" d="M0 0H24V24H0z" />
        <Circle stroke="#7A8390" strokeWidth={2} strokeLinecap="round" cx={12} cy={12} r={9} />
        <Path stroke="#7A8390" strokeWidth={2} strokeLinecap="round" d="M14.1213 9.87866L9.8787 14.1213" />
        <Path stroke="#7A8390" strokeWidth={2} strokeLinecap="round" d="M9.87866 9.87866L14.1213 14.1213" />
      </G>
    </Svg>
  );
}

export default closeSvg;
