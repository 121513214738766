import React from "react";
import ContentLoader, { Rect, Circle, Path } from "react-content-loader/native";
import { useWindowDimensions } from "react-native";

export const ApProfileLoader = () => {
  const dim = useWindowDimensions();
  const width = dim.width - 30;
  return (
    <ContentLoader
      speed={2}
      // width={476}
      height={600}
      // viewBox="0 0 476 600"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <Rect x="16" y="165" rx="26" ry="26" width={width} height="41" />
      <Circle cx="62" cy="103" r="45" />
      <Rect x="19" y="220" rx="0" ry="0" width="65" height="54" />
      <Rect x="107" y="221" rx="0" ry="0" width="65" height="54" />
      <Rect x="196" y="220" rx="0" ry="0" width="65" height="54" />
      <Rect x="281" y="219" rx="0" ry="0" width="65" height="54" />
      <Rect x="19" y="287" rx="0" ry="0" width={width} height="53" />
      <Rect x="19" y="352" rx="0" ry="0" width={width} height="53" />
      <Rect x="19" y="419" rx="0" ry="0" width={width} height="53" />
      <Rect x="20" y="484" rx="0" ry="0" width={width} height="53" />
    </ContentLoader>
  );
};
