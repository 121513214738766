import React, { useEffect, useState } from "react";
import { Image, ScrollView, TouchableOpacity, View } from "react-native";
import { components } from "../components";
import { ApRow, ApSafeAreaView } from "../components/v1";
import { ApText } from "../components/v1/typography";
import { theme } from "../constants";
import ApiCall from "../utils/ApiCall";

const PurchaseSuccess: React.FC = ({ navigation }: any) => {
  const [exchangeRateInfo, setExchangeRateInfo] = useState();

  useEffect(() => {
    ApiCall.get("/profile/getExchangeRefInfo").then((response) => {
      setExchangeRateInfo(response.data.data);
    });
  }, []);

  const renderHeader = () => {
    return (
      <components.Header
        title="Membership Purchase"
        goBack={true}
        purchaseHistory={true}
      />
    );
  };

  const renderContent = () => {
    return (
      <View style={{ flex: 1 }}>
        <ScrollView
          contentContainerStyle={{ flexGrow: 1, paddingHorizontal: 20 }}
        >
          <View
            style={{
              backgroundColor: "#FFD9CF",
              padding: 12,
              borderRadius: 10,
              marginTop: 15,
              marginBottom: 5,
              flexDirection: "row",
            }}
          >
            <ApText
              style={{ color: "#F37021", width: "80%", fontSize: 13 }}
              translateKey="TradeNotepage"
            />
            <Image
              source={require("../../assets/icons/account-hint.png")}
              style={{ width: 86, height: 57 }}
            />
          </View>
          <View
            style={{
              borderRadius: 12,
              backgroundColor: "#FCF8F6",
              marginTop: 15,
            }}
          >
            <Image
              source={require("../../assets/purchase-bg.png")}
              style={{
                opacity: "0.6",
                width: 115,
                height: 104,
                position: "absolute",
                bottom: 0,
                right: 0,
              }}
            />
            <View style={{ padding: 15 }}>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingVertical: 10,
                }}
              >
                <ApText
                  style={{ fontWeight: "bold", fontSize: 16 }}
                  translateKey="MembershipPackage"
                />
                <ApText style={{ color: "#05B491" }} translateKey="Active">
                  {" "}
                  Active
                </ApText>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingVertical: 5,
                }}
              >
                <ApRow>
                  <ApText translateKey="NextExpiryDate" />
                  <ApText style={{ fontWeight: "500" }}>
                    : 2024-01-11 16:28
                  </ApText>
                </ApRow>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingVertical: 5,
                }}
              >
                <ApRow>
                  <ApText translateKey="RemainingDay(s)" />
                  <ApText
                    style={{
                      paddingRight: 2,
                      paddingLeft: 3,
                      fontWeight: "500",
                    }}
                  >
                    : 356
                  </ApText>
                  <ApText translateKey="Days" />
                </ApRow>
              </View>
            </View>
          </View>
        </ScrollView>
        <View
          style={{
            paddingHorizontal: 20,
            paddingVertical: 20,
            width: "100%",
            flex: 0.08,
            position: "absolute",
            bottom: 0,
            backgroundColor: "white",
          }}
        >
          <TouchableOpacity
            style={{
              backgroundColor: "#F37021",
              width: "100%",
              height: 40,
              borderRadius: 8,
              alignItems: "center",
              justifyContent: "center",
            }}
            onPress={() => {
              navigation.navigate("MemberPurchase2");
            }}
          >
            <ApText
              style={{ color: theme.COLORS.white, fontWeight: "500" }}
              translateKey="Renew"
            />
          </TouchableOpacity>
        </View>
      </View>
    );
  };

  return (
    <ApSafeAreaView>
      {renderHeader()}
      {renderContent()}
    </ApSafeAreaView>
  );
};

export default PurchaseSuccess;
