import { useEffect, useState } from "react";
import { useCheckEnv } from "./env";
import { BackHandler } from "react-native";

let nativeHandler: any = null;
export const useDeviceBackListner = () => {
  const { isNative } = useCheckEnv();

  const getHandler = (callback) => {
    if (isNative) {
      nativeHandler = BackHandler.addEventListener(
        "hardwareBackPress",
        callback
      );
    } else {
      window.addEventListener("popstate", callback);
    }
  };

  const clearHandler = () => {
    if (isNative) {
      if (nativeHandler) {
        nativeHandler.remove();
      }
    } else window.removeEventListener("popstate", () => {});
  };

  return {
    backHandler: getHandler,
    clearBackHandler: clearHandler,
  };
};
