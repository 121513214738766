import React, { useState, useEffect } from "react";
import { TouchableOpacity, View, TextInput, StyleSheet } from "react-native";
import { ApModalAlt } from "../../../../components/Modal";
import { ApText } from "../../../../components/v1/typography";
import { theme } from "../../../../constants";
import { useTradingContext } from "../../../../context";
import { svg } from "../../../../svg";

export const NextEntryModal = ({
  setShowNextEntryModal,
  showNextEntryModal,
  bex_id,
}) => {
  const { triggerNextEntry, botExecutorByID } = useTradingContext();
  const [amount, setAmount] = useState("1");

  useEffect(() => {
    setAmount(botExecutorByID.next_entry_amount);
  }, [botExecutorByID]);

  const handleChangeText = (text) => {
    setAmount(text);
  };

  return (
    <ApModalAlt transparent={true} visible={showNextEntryModal}>
      <>
        <TouchableOpacity
          onPress={() => {
            setShowNextEntryModal(false);
          }}
          style={{ alignItems: "flex-end", padding: 15, paddingBottom: 0 }}
        >
          <svg.closeSvg />
        </TouchableOpacity>

        <View
          style={{
            paddingTop: 0,
            paddingVertical: 20,
            alignItems: "center",
            paddingHorizontal: 20,
          }}
        >
          <ApText
            size="lg"
            style={{
              marginTop: -5,
              color: "#F37021",
              fontWeight: "bold",
              marginBottom: 5,
            }}
            translateKey="Notice"
          />
          <ApText
            size="sm"
            style={{ textAlign: "center" }}
            translateKey="ConfirmNextEntry"
          />
          <TextInput
            textAlign="center"
            placeholderTextColor={theme.COLORS.white}
            style={{...style.textInput}}
            value={amount}
            onChangeText={handleChangeText}
          />

          <View
            style={{
              width: "100%",
              marginTop: 30,
              flexDirection: "row",
              //paddingVertical: 20,
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <TouchableOpacity
              style={{
                marginRight: 10,
                borderWidth: 1,
                borderColor: "#F37021",
                width: "47%",
                height: 38,
                borderRadius: 5,
                alignItems: "center",
                justifyContent: "center",
              }}
              onPress={() => {
                setShowNextEntryModal(false);
              }}
            >
              <ApText
                style={{ color: "#F37021", fontWeight: "500" }}
                translateKey="Cancel"
              />
            </TouchableOpacity>

            <TouchableOpacity
              style={{
                backgroundColor: "#F37021",
                width: "47%",
                height: 38,
                borderRadius: 5,
                alignItems: "center",
                justifyContent: "center",
              }}
              onPress={() => {
                triggerNextEntry(botExecutorByID.bex_id, amount)
                setShowNextEntryModal(false);
              }}
            >
              <ApText
                style={{ color: theme.COLORS.white, fontWeight: "500" }}
                translateKey="Confirm"
              />
            </TouchableOpacity>
          </View>
        </View>
      </>
    </ApModalAlt>
  );
};

const style = StyleSheet.create({
  textInput: {
    backgroundColor: "#DFE3EA",
    color: "#F37021",
    textAlign: "center",
    fontSize: 18,
    borderRadius: 6,
    padding:5,
    marginTop:5,
  },
});
