import moment from "moment";
import React, { useState } from "react";
import _ from "underscore";
import {
  IPnlDetail,
  IPnlDetailPayload,
  IPnlFilter,
  IPnlResponse,
} from "../models";
import { ApiService } from "../services";
import { objToQueryStr } from "../utils";
interface IPnlState {
  loading: boolean;
  filter: IPnlFilter;
  pnlResponse?: IPnlResponse;
  pnlDetail: Record<string, IPnlDetail[]>;
  setFilter: (filter: IPnlFilter) => void;
  getPnl: (market: "1" | "2") => Promise<IPnlResponse>;
  getPnlDetail: (payload: IPnlDetailPayload) => Promise<IPnlDetail[]>;
}

const PnlContext = React.createContext<IPnlState>({
  loading: false,
  pnlResponse: null,
  pndDetail: null,
  filter: null,
  setFilter(filter: IPnlFilter) {},
  getPnl(market: "1" | "2") {
    return null;
  },
  getPnlDetail(payload: IPnlDetailPayload) {
    return null;
  },
} as unknown as IPnlState);

const usePnlState = () => {
  const context = React.useContext(PnlContext);
  if (context === undefined) {
    throw new Error("app dispatch must be used within app global provider");
  }
  return context;
};

interface IProps {
  children: React.ReactNode;
}

const PnlContextProvider: React.FC<IProps> = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [pnlResponse, setPnlResponse] = useState<IPnlResponse>(
    {} as IPnlResponse
  );
  const [filter, setFilter] = useState<IPnlFilter>(null as any);
  const [pnlDetail, setPnlDetail] = useState<Record<string, IPnlDetail[]>>(
    null as any
  );

  const getPnl = (market: "1" | "2"): Promise<IPnlResponse> => {
    setLoading(true);
    return ApiService.get(`/transaction/getPnl?market=${market}`)
      .then((res) => {
        setPnlResponse(res.data.data);
        return res.data.data;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getPnlDetail = (payload: IPnlDetailPayload): Promise<IPnlDetail[]> => {
    setLoading(true);
    return ApiService.get(`/trading/getPnlDetails?${objToQueryStr(payload)}`)
      .then((res) => {
        setPnlDetail(
          _.groupBy(
            res?.data?.data?.map((d) => ({
              ...d,
              date: moment(d.created_at).format("YYYY-MM-DD"),
            })),
            "date"
          )
        );
        return res?.data?.data;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <PnlContext.Provider
      value={{
        loading,
        filter,
        setFilter,
        pnlResponse,
        pnlDetail,
        getPnl,
        getPnlDetail,
      }}
    >
      {children}
    </PnlContext.Provider>
  );
};

export { PnlContextProvider, usePnlState };
