import CheckBox from "expo-checkbox";
import { useField } from "formik";
import React, { useEffect, useState } from "react";
import { TextStyle, View } from "react-native";
import { ApText } from "../typography";

interface IProps {
  defaultValue?: boolean;
  noBorder?: boolean;
  name: string;
  label?: string;
  disabled?: boolean;
  labelTranslateKey?: string;
  placeholder?: string;
  labelStyle?: TextStyle;
  notCheckable?: boolean;
  onChange?:(val:boolean)=>void
}

export const ApCheckInput: React.FC<IProps> = ({
  defaultValue,
  noBorder,
  label,
  name,
  disabled,
  labelStyle,
  notCheckable,
  onChange,
  labelTranslateKey,
}) => {
  const [field, meta, { setValue }] = useField(name);
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    if(defaultValue !== null || defaultValue !== undefined)
    {
      defaultValue  && setToggle(defaultValue);
      defaultValue && setValue(defaultValue)
    }
  }, [defaultValue]);

  return (
    <View
      style={{
        width: "100%",
        borderBottomWidth: noBorder ? 0 : 1,
        borderBottomColor: "#ced6e182",
      }}
    >
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          width: "100%",
          marginBottom: "10px",
        }}
      >
        <CheckBox
          disabled={disabled}
          style={{ borderWidth: !toggle ? 1 : 0, borderColor: "#9B9999" }}
          value={toggle}
          color={toggle ? "#F37021" : "transparent"}
          onValueChange={(v) => {
            if(notCheckable) return;
            setToggle(!toggle);
            setValue(!toggle);
            if(onChange) onChange(!toggle);
          }}
        />
        {label && (
          <ApText
            translateKey={labelTranslateKey || label}
            size="xs"
            style={labelStyle}
          >
            {label}
          </ApText>
        )}
      </View>
      <>
        {meta.touched && meta.error && (
          <ApText
            style={{
              fontSize: 14,
              color: "red",
              marginBottom: 14,
              marginTop: 14,
            }}
          >
            {meta.error}
          </ApText>
        )}
      </>
    </View>
  );
};
