import React from 'react';
import ContentLoader from 'react-content-loader/native';
import {Rect} from 'react-native-svg';
export const ApListThumbnailLoader = () => {
  return (
    <ContentLoader 
    speed={2}
    width={400}
    height={110}
    viewBox="0 0 400 110"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <Rect x="10" y="19" rx="0" ry="0" width="122" height="105" /> 
    <Rect x="145" y="20" rx="0" ry="0" width="121" height="13" /> 
    <Rect x="144" y="48" rx="0" ry="0" width="167" height="12" /> 
    <Rect x="145" y="72" rx="0" ry="0" width="212" height="10" /> 
    <Rect x="147" y="93" rx="0" ry="0" width="211" height="16" />
  </ContentLoader>
  );
};
