import * as React from "react";
import Svg, { Path } from "react-native-svg";

function ArrowFiveSvg(props) {
  return (
    <Svg fill="#fff" width="20px" height="20px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path d="M264.8 604.7l61.8 61.8L512 481.1l185.4 185.4 61.8-61.8L512 357.5z" />
    </Svg>
  );
}

export default ArrowFiveSvg;
