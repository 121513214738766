import * as React from "react";
import Svg, { Path } from "react-native-svg";

function CancelSvg(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#F37021" style={{ height: 20, width: 20}}  {...props}>
    <Path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
    </Svg>
  );
}

export default CancelSvg;