import * as React from "react";
import Svg, { G, Path } from "react-native-svg";

function EyeOffSvg(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width={11.5} height={9.191} viewBox="0 0 11.5 9.191" {...props}>
      <G fill="#7a8390">
        <Path data-name="Path 132" d="M11.329 10.345l-.753.663 9.688 8.529.753-.663z" transform="translate(596.962 156.121) translate(-596.962 -156.121) translate(-10.047 -10.345)" fillRule="evenodd" />
        <Path
          data-name="Path 133"
          d="M10.267 15.12l.7.624A7.453 7.453 0 009.2 17.682c1.1 1.636 2.84 3.183 5.039 2.948a4.724 4.724 0 001.653-.5l.709.631a5.355 5.355 0 01-3.341.715A7.351 7.351 0 018.1 17.7a8.61 8.61 0 012.167-2.58zm1.592-.924a5.6 5.6 0 012-.4c.04 0 .51.02.723.051.134.02.267.044.4.075A7.764 7.764 0 0119.6 17.7a8.48 8.48 0 01-1.585 1.985l-.679-.6a7.2 7.2 0 001.173-1.38 8.134 8.134 0 00-.54-.78q-.223-.265-.469-.513c-.13-.13-.633-.572-.753-.664a4.645 4.645 0 00-2.88-1.091 4.274 4.274 0 00-1.26.213z"
          transform="translate(596.962 156.121) translate(-596.962 -156.121) translate(-8.102 -13.057)"
        />
        <Path
          data-name="Path 134"
          d="M24.252 24.7l.513.513a1.036 1.036 0 001.308 1.308l.513.513a1.716 1.716 0 01-2.334-2.334zm1.466-.878h.032a1.716 1.716 0 011.716 1.716v.032z"
          transform="translate(596.962 156.121) translate(-596.962 -156.121) translate(-20 -20.934)"
          fillRule="evenodd"
        />
      </G>
    </Svg>
  );
}

export default EyeOffSvg;
