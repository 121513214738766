import * as React from "react";
import Svg, { Path } from "react-native-svg";

const PiggyBankSvg: React.FC = () => (
    <Svg width={22} height={22} fill="none">
        <Path
            d="M16.844 7.563a1.032 1.032 0 0 0 0 2.062 1.032 1.032 0 0 0 0-2.063Zm0 1.375a.344.344 0 1 1 0-.689.344.344 0 0 1 0 .688Z"
            fill="#4C4C60"
        />
        <Path
            d="M20.625 8.594h-.548c-.619-1.497-1.738-2.818-3.233-3.8V1.032A.344.344 0 0 0 16.5.688a3.098 3.098 0 0 0-3.066 2.679A11.075 11.075 0 0 0 11 3.094c-2.56 0-4.97.851-6.786 2.397-.94.8-1.661 1.738-2.143 2.76l-.009-.001a1.377 1.377 0 0 1-1.1-2.2.344.344 0 1 0-.55-.413A2.065 2.065 0 0 0 1.795 8.92a7.199 7.199 0 0 0-.419 2.425c0 2.708 1.538 5.226 4.125 6.771v1.823c0 .758.617 1.375 1.375 1.375h.688c.758 0 1.375-.617 1.375-1.375v-.535a11.3 11.3 0 0 0 4.124 0v.535c0 .758.617 1.375 1.376 1.375h.687c.758 0 1.375-.617 1.375-1.375v-1.826c1.43-.854 2.546-2.002 3.255-3.33h.87c.758 0 1.375-.618 1.375-1.376V9.97c0-.758-.617-1.375-1.375-1.375Zm.688 4.812c0 .38-.309.688-.688.688h-.547c.127-.308.232-.623.316-.945a.344.344 0 0 0-.665-.173 6.739 6.739 0 0 1-.504 1.333c-.672 1.335-1.792 2.485-3.252 3.315a.344.344 0 0 0-.16.395v1.919c0 .379-.309.687-.688.687h-.688a.688.688 0 0 1-.687-.688v-.955a.344.344 0 0 0-.42-.335 10.496 10.496 0 0 1-4.66-.002.343.343 0 0 0-.42.335v.957c0 .38-.308.688-.688.688h-.687a.688.688 0 0 1-.688-.688v-2.02a.344.344 0 0 0-.173-.298c-2.474-1.41-3.952-3.757-3.952-6.275 0-4.17 4.01-7.563 8.938-7.563a10.4 10.4 0 0 1 2.406.285v.403a.344.344 0 0 0 .688 0v-.58a.342.342 0 0 0 0-.176 2.41 2.41 0 0 1 2.062-2.314v3.42a.344.344 0 0 0 .13.436c1.531.953 2.656 2.278 3.227 3.781l.015.042c.078.208.145.419.2.633a.344.344 0 1 0 .666-.173 6.932 6.932 0 0 0-.073-.257h.304c.38 0 .688.309.688.688v3.437Z"
            fill="#4C4C60"
        />
        <Path
            d="M11 8.938c.625 0 1.203.297 1.345.693.02.054.03.11.03.166a1.032 1.032 0 0 0 2.063 0 2.55 2.55 0 0 0-.152-.866c-.339-.939-1.18-1.644-2.276-1.923a1.033 1.033 0 0 0-1.01-.82c-.496 0-.91.35-1.01.818a3.55 3.55 0 0 0-1.643.934c-.506.521-.784 1.18-.784 1.857 0 1.611 1.542 2.922 3.437 2.922.649 0 1.375.367 1.375.86 0 .491-.726.858-1.375.858s-1.375-.367-1.375-.859a1.032 1.032 0 0 0-2.063 0c0 .677.28 1.336.785 1.857a3.55 3.55 0 0 0 1.644.934c.098.467.513.819 1.009.819.496 0 .91-.352 1.01-.819a3.55 3.55 0 0 0 1.643-.934c.506-.521.784-1.18.784-1.857 0-1.611-1.542-2.922-3.437-2.922-.649 0-1.375-.367-1.375-.86 0-.491.726-.858 1.375-.858Zm0 2.406c1.516 0 2.75 1.002 2.75 2.234 0 1.026-.894 1.94-2.127 2.174a.344.344 0 0 0-.28.338v.066a.344.344 0 0 1-.687 0v-.066a.344.344 0 0 0-.28-.338c-1.232-.234-2.126-1.148-2.126-2.174a.344.344 0 0 1 .688 0c0 .853.925 1.547 2.062 1.547 1.137 0 2.063-.694 2.063-1.547s-.926-1.547-2.063-1.547c-1.516 0-2.75-1.002-2.75-2.234 0-1.026.894-1.94 2.127-2.174a.344.344 0 0 0 .28-.338V7.22a.344.344 0 0 1 .687 0v.07c0 .166.118.308.28.338.966.18 1.738.77 2.015 1.537.074.205.111.418.111.633a.344.344 0 0 1-.688 0c0-.136-.023-.27-.07-.4C12.749 8.722 11.929 8.25 11 8.25c-1.137 0-2.063.694-2.063 1.547s.926 1.547 2.063 1.547ZM7.077 5.296c-1.092.493-2.054 1.2-2.78 2.042a.344.344 0 1 0 .52.449C5.48 7.02 6.358 6.375 7.36 5.922a.344.344 0 1 0-.283-.626ZM8.467 5.5c.03 0 .062-.004.093-.013.256-.072.52-.132.783-.181a.344.344 0 1 0-.124-.676 9.556 9.556 0 0 0-.845.195.344.344 0 0 0 .093.675Z"
            fill="#4C4C60"
        />
    </Svg>
);

export default PiggyBankSvg;
