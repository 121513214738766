import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ScrollView, Text, TouchableOpacity, View } from "react-native";
import DropDownPicker from "react-native-dropdown-picker";
import Timeline from "react-native-timeline-flatlist";
import { RefreshControl } from "react-native-web-refresh-control";
import { components } from "../components";
import {
  ApButton,
  ApEmptyState,
  ApEmptyStateSvg,
  ApLoader,
  ApSafeAreaView,
} from "../components/v1";
import { ApText } from "../components/v1/typography";
import { theme } from "../constants";
import { TRADING_PLATFORMS } from "../constants/master";
import { useTradingContext } from "../context";
import ArrowSevenSvg from "../svg/ArrowSevenSvg";
import ArrowSixSvg from "../svg/ArrowSixSvg";

let dataList: any = [];
const LogDetails: React.FC = ({ navigation }: any) => {
  const { orderLogs, getOrderLogs, loading } = useTradingContext();
  const [refreshing, setRefreshing] = useState(false);
  const { i18n, t } = useTranslation();
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [platform, setPlatform] = useState<"all" | "1" | "3" | "9" | "21">(
    "all"
  );
  const [items, setItems] = useState([
    { label: t("All"), value: "all" },
    ...TRADING_PLATFORMS,
  ]);
  const [loadingMoreRecords, setLoadingMoreRecords] = useState(false)

  useEffect(() => {
    fetchData();
  }, [platform]);

  const fetchData = async () => {
    const logs = await getOrderLogs(30, 1, platform);
    setPage(1);
    setData(logs);
  };

  const handleRefresh = async () => {
    setRefreshing(true);
    const refreshLogs = await getOrderLogs(30, 1);
    setPage(1);
    setPlatform(null);
    setData(refreshLogs);
    setRefreshing(false);
  };

  const loadMore = async () => {
    setLoadingMoreRecords(true)
    const nextPage = page + 1;
    const newLogs = await getOrderLogs(30, nextPage, platform, true);
    setLoadingMoreRecords(false)
    setPage(nextPage);
    setData((prevData) => [...prevData, ...newLogs]);
  };

  const renderHeader = () => {
    return (
      <components.Header title="Log" titleTranlateKey="Log" goBack={true} />
    );
  };

  const renderDetail = (data) => {
    const color =
      data.status == 2 ? "green" : data.status == 3 ? "red" : "black";
    return (
      <View>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            paddingBottom: 10,
          }}
        >
          <Text style={{ color: "grey", fontSize: 12 }}>{data.id}</Text>
          <Text style={{ color: "grey", fontSize: 12 }}>{data.created_at}</Text>
        </View>
        <Text
          style={{
            ...theme.FONTS.Mulish_700Bold,
            color: theme.COLORS.mainDark,
            fontSize: 16,
          }}
        >
          {data.display_title_1}
        </Text>
        <Text
          style={{
            ...theme.FONTS.Mulish_700Bold,
            color: theme.COLORS.mainDark,
            fontSize: 16,
          }}
        >
          {data.display_title_2}
        </Text>
        <Text style={{ color: "grey", paddingVertical: 10 }}>
          {data.display_content_1}
        </Text>
        <Text style={{ color: color }}>{data.display_status}</Text>
      </View>
    );
  };

  const renderContent = () => {
    return (
      // <ScrollView contentContainerStyle={{ flexGrow: 1, paddingHorizontal: 20 }}>
      <View style={{ paddingVertical: 20, paddingHorizontal: 20 }}>
        <View style={{ marginBottom: 10, zIndex: 999 }}>
          <DropDownPicker
            open={open}
            value={platform}
            items={items}
            setOpen={setOpen}
            setValue={setPlatform}
            setItems={setItems}
            ArrowDownIconComponent={({ style }) => <ArrowSixSvg />}
            ArrowUpIconComponent={({ style }) => <ArrowSevenSvg />}
            dropDownContainerStyle={{ borderWidth: 0 }}
            textStyle={{
              fontSize: 18,
              color: "#F37021",
              fontWeight: "500",
            }}
            // multiple={true}
            style={{
              backgroundColor: "none",
              borderWidth: 0,
              paddingHorizontal: 0,
            }}
            mode="SIMPLE"
            badgeDotColors={[
              "#e76f51",
              "#00b4d8",
              "#e9c46a",
              "#e76f51",
              "#8ac926",
              "#00b4d8",
              "#e9c46a",
            ]}
          />
        </View>
        {data.length > 0 ? (
          <Timeline
            data={data}
            renderDetail={renderDetail}
            showTime={false}
            circleSize={15}
            circleColor="#FF8B7E"
            lineColor="#FF8B7E"
            titleStyle={{ fontWeight: "500", fontSize: 15 }}
            timeContainerStyle={{ minWidth: 52, marginTop: -5 }}
            timeStyle={{
              textAlign: "center",
              backgroundColor: "#ff9797",
              color: "white",
              padding: 5,
              borderRadius: 13,
            }}
            detailContainerStyle={{
              backgroundColor: "#FCF8F6",
              marginBottom: 26,
              marginRight: 5,
              borderRadius: 12,
              shadowColor: "#ffc9c9",
              padding: 15,
              shadowOffset: { width: 2, height: 2 },
              shadowOpacity: 0.7,
              shadowRadius: 8,
            }}
            descriptionStyle={{ color: "gray", fontSize: 13 }}
            options={{
              style: { paddingTop: 5 },
            }}
          />
        ) : (
          <View
            style={{
              marginVertical: "50%",
            }}
          >
            <ApEmptyState
              svg={<ApEmptyStateSvg.List />}
              heading="No Result Found"
              message="No order logs"
            />
          </View>
        )}
        {data?.length >= 30 && (
          <ApButton
            onPress={() => loadMore()}
            loading={loadingMoreRecords}
            disabled={loadingMoreRecords}
            labelTranslateKey="ShowAllUpdates"
            round="lg"
            style={{marginTop: 10}}
          />
        )}
      </View>
    );
  };

  return (
    <ApSafeAreaView>
      {renderHeader()}
      {loading && <ApLoader />}
      <ScrollView
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={handleRefresh} />
        }
      >
        {!loading && renderContent()}
      </ScrollView>
    </ApSafeAreaView>
  );
};

export default LogDetails;
