import React, { useCallback, useState } from "react";
import { View } from "react-native";
import { components } from "../components";
import { ApSafeAreaView } from "../components/v1";
import { ApText } from "../components/v1/typography";
import { theme } from "../constants";

const TradeHistory: React.FC = ({ navigation }: any) => {
  const [selected, setSelected] = useState("All");
  const [paymentOpen, setPaymentOpen] = useState(false);

  const [routes] = React.useState([
    { key: "first", title: "Whole" },
    { key: "second", title: "In execution" },
  ]);
  const renderHeader = () => {
    return <components.Header title="交易记录" goBack={true} />;
  };

  const onPaymentOpen = useCallback(() => {
    setPaymentOpen(false);
  }, []);

  const renderContent = () => {
    return (
      <View>
        <View style={{ flex: 1, padding: 20 }}>
          <View
            style={{
              padding: 20,
              paddingTop: 18,
              marginBottom: 20,
              backgroundColor: theme.COLORS.white,
              borderRadius: 10,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: 14,
                paddingBottom: 2,
                borderBottomWidth: 1,
                borderBottomColor: "#CED6E1",
              }}
            >
              <View style={{ flex: 1 }}>
                <ApText
                  style={{
                    fontSize: 16,
                    paddingBottom: 8,
                    fontWeight: "500",
                  }}
                >
                  AVAX/USDT(合约)
                </ApText>
              </View>
              <View style={{ flex: 1 }}>
                <ApText
                  style={{
                    fontSize: 12,
                    paddingBottom: 8,
                    textAlign: "right",
                    color: "red",
                  }}
                >
                  Paused
                </ApText>
              </View>
            </View>

            <View
              style={{
                flexDirection: "row",
                alignItems: "flex-start",
              }}
            >
              <View style={{ flex: 1 }}>
                <ApText
                  style={{
                    ...theme.FONTS.Mulish_400Regular,
                    fontSize: 12,
                    lineHeight: 12 * 1.6,
                    color: theme.COLORS.bodyTextColor,
                  }}
                >
                  交易数
                </ApText>
                <ApText
                  style={{
                    marginBottom: 16,
                    ...theme.FONTS.H6,
                    color: theme.COLORS.mainDark,
                  }}
                >
                  34
                </ApText>
                <ApText
                  style={{
                    ...theme.FONTS.Mulish_400Regular,
                    fontSize: 12,
                    lineHeight: 12 * 1.6,
                    color: theme.COLORS.bodyTextColor,
                  }}
                >
                  持仓单数
                </ApText>
                <ApText
                  style={{
                    marginBottom: 16,
                    ...theme.FONTS.H6,
                    color: theme.COLORS.mainDark,
                  }}
                >
                  0/4
                </ApText>
              </View>
              <View style={{ flex: 1, alignItems: "center" }}>
                <ApText
                  style={{
                    ...theme.FONTS.Mulish_400Regular,
                    fontSize: 12,
                    lineHeight: 12 * 1.6,
                    color: theme.COLORS.bodyTextColor,
                  }}
                >
                  累计买入
                </ApText>
                <ApText
                  style={{
                    marginBottom: 16,
                    ...theme.FONTS.H6,
                    color: theme.COLORS.mainDark,
                  }}
                >
                  21771.542
                </ApText>
                <ApText
                  style={{
                    ...theme.FONTS.Mulish_400Regular,
                    fontSize: 12,
                    lineHeight: 12 * 1.6,
                    color: theme.COLORS.bodyTextColor,
                  }}
                >
                  持加仓价
                </ApText>
                <ApText
                  style={{
                    marginBottom: 16,
                    ...theme.FONTS.H6,
                    color: theme.COLORS.mainDark,
                  }}
                >
                  0
                </ApText>
              </View>
              <View style={{ flex: 1, alignItems: "flex-end" }}>
                <ApText
                  style={{
                    ...theme.FONTS.Mulish_400Regular,
                    fontSize: 12,
                    lineHeight: 12 * 1.6,
                    color: theme.COLORS.bodyTextColor,
                  }}
                >
                  累计卖出
                </ApText>
                <ApText
                  style={{
                    marginBottom: 16,
                    ...theme.FONTS.H6,
                    color: theme.COLORS.mainDark,
                  }}
                >
                  27117.542
                </ApText>
                <ApText
                  style={{
                    ...theme.FONTS.Mulish_400Regular,
                    fontSize: 12,
                    lineHeight: 12 * 1.6,
                    color: theme.COLORS.bodyTextColor,
                  }}
                >
                  持加仓量(U)
                </ApText>
                <ApText
                  style={{
                    marginBottom: 16,
                    ...theme.FONTS.H6,
                    color: theme.COLORS.mainDark,
                  }}
                >
                  0
                </ApText>
              </View>
            </View>
          </View>

          <View
            style={{
              padding: 20,
              paddingTop: 18,
              marginBottom: 20,
              backgroundColor: theme.COLORS.white,
              borderRadius: 10,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: 14,
                paddingBottom: 2,
                borderBottomWidth: 1,
                borderBottomColor: "#CED6E1",
              }}
            >
              <View style={{ flex: 1 }}>
                <ApText
                  style={{
                    fontSize: 16,
                    paddingBottom: 8,
                    fontWeight: "500",
                  }}
                >
                  交易记录
                </ApText>
              </View>
              <View style={{ flex: 1 }}>
                <ApText
                  style={{
                    fontSize: 12,
                    paddingBottom: 8,
                    textAlign: "right",
                    color: "black",
                  }}
                >
                  Filter
                </ApText>
              </View>
            </View>
            <View
              style={{
                backgroundColor: "#9f9f9f1a",
                padding: "12",
                borderRadius: "12",
                marginBottom: "12",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingBottom: 2,
                  marginBottom: 8,
                }}
              >
                <View style={{ flex: 1 }}>
                  <ApText
                    style={{
                      fontSize: 12,
                      paddingBottom: 8,
                      fontWeight: "500",
                    }}
                  >
                    2022-10-29 &nbsp; &nbsp; 01:36:35
                  </ApText>
                </View>
                <View style={{ flex: 1 }}>
                  <ApText
                    style={{
                      fontSize: 12,
                      paddingBottom: 8,
                      textAlign: "right",
                      color: "red",
                    }}
                  >
                    清仓
                  </ApText>
                </View>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "flex-start",
                }}
              >
                <View style={{ flex: 1 }}>
                  <ApText
                    style={{
                      ...theme.FONTS.Mulish_400Regular,
                      fontSize: 12,
                      lineHeight: 12 * 1.6,
                      color: theme.COLORS.bodyTextColor,
                    }}
                  >
                    交易数
                  </ApText>
                  <ApText
                    style={{
                      marginBottom: 16,
                      ...theme.FONTS.H6,
                      color: theme.COLORS.mainDark,
                    }}
                  >
                    34
                  </ApText>
                  <ApText
                    style={{
                      ...theme.FONTS.Mulish_400Regular,
                      fontSize: 12,
                      lineHeight: 12 * 1.6,
                      color: theme.COLORS.bodyTextColor,
                    }}
                  >
                    持仓单数
                  </ApText>
                  <ApText
                    style={{
                      marginBottom: 16,
                      ...theme.FONTS.H6,
                      color: theme.COLORS.mainDark,
                    }}
                  >
                    0/4
                  </ApText>
                </View>
                <View style={{ flex: 1, alignItems: "center" }}>
                  <ApText
                    style={{
                      ...theme.FONTS.Mulish_400Regular,
                      fontSize: 12,
                      lineHeight: 12 * 1.6,
                      color: theme.COLORS.bodyTextColor,
                    }}
                  >
                    累计买入
                  </ApText>
                  <ApText
                    style={{
                      marginBottom: 16,
                      ...theme.FONTS.H6,
                      color: theme.COLORS.mainDark,
                    }}
                  >
                    21771.542
                  </ApText>
                  <ApText
                    style={{
                      ...theme.FONTS.Mulish_400Regular,
                      fontSize: 12,
                      lineHeight: 12 * 1.6,
                      color: theme.COLORS.bodyTextColor,
                    }}
                  >
                    持加仓价
                  </ApText>
                  <ApText
                    style={{
                      marginBottom: 16,
                      ...theme.FONTS.H6,
                      color: theme.COLORS.mainDark,
                    }}
                  >
                    0
                  </ApText>
                </View>
                <View style={{ flex: 1, alignItems: "flex-end" }}>
                  <ApText
                    style={{
                      ...theme.FONTS.Mulish_400Regular,
                      fontSize: 12,
                      lineHeight: 12 * 1.6,
                      color: theme.COLORS.bodyTextColor,
                    }}
                  >
                    累计卖出
                  </ApText>
                  <ApText
                    style={{
                      marginBottom: 16,
                      ...theme.FONTS.H6,
                      color: theme.COLORS.mainDark,
                    }}
                  >
                    27117.542
                  </ApText>
                  <ApText
                    style={{
                      ...theme.FONTS.Mulish_400Regular,
                      fontSize: 12,
                      lineHeight: 12 * 1.6,
                      color: theme.COLORS.bodyTextColor,
                    }}
                  >
                    持加仓量(U)
                  </ApText>
                  <ApText
                    style={{
                      marginBottom: 16,
                      ...theme.FONTS.H6,
                      color: theme.COLORS.mainDark,
                    }}
                  >
                    0
                  </ApText>
                </View>
              </View>
            </View>
            <View
              style={{
                backgroundColor: "#9f9f9f1a",
                padding: "12",
                borderRadius: "12",
                marginBottom: "12",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingBottom: 2,
                  marginBottom: 8,
                }}
              >
                <View style={{ flex: 1 }}>
                  <ApText
                    style={{
                      fontSize: 12,
                      paddingBottom: 8,
                      fontWeight: "500",
                    }}
                  >
                    2022-10-29 &nbsp; &nbsp; 01:36:35
                  </ApText>
                </View>
                <View style={{ flex: 1 }}>
                  <ApText
                    style={{
                      fontSize: 12,
                      paddingBottom: 8,
                      textAlign: "right",
                      color: "green",
                    }}
                  >
                    开仓
                  </ApText>
                </View>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "flex-start",
                }}
              >
                <View style={{ flex: 1 }}>
                  <ApText
                    style={{
                      ...theme.FONTS.Mulish_400Regular,
                      fontSize: 12,
                      lineHeight: 12 * 1.6,
                      color: theme.COLORS.bodyTextColor,
                    }}
                  >
                    交易数
                  </ApText>
                  <ApText
                    style={{
                      marginBottom: 16,
                      ...theme.FONTS.H6,
                      color: theme.COLORS.mainDark,
                    }}
                  >
                    34
                  </ApText>
                  <ApText
                    style={{
                      ...theme.FONTS.Mulish_400Regular,
                      fontSize: 12,
                      lineHeight: 12 * 1.6,
                      color: theme.COLORS.bodyTextColor,
                    }}
                  >
                    持仓单数
                  </ApText>
                  <ApText
                    style={{
                      marginBottom: 16,
                      ...theme.FONTS.H6,
                      color: theme.COLORS.mainDark,
                    }}
                  >
                    0/4
                  </ApText>
                </View>
                <View style={{ flex: 1, alignItems: "center" }}>
                  <ApText
                    style={{
                      ...theme.FONTS.Mulish_400Regular,
                      fontSize: 12,
                      lineHeight: 12 * 1.6,
                      color: theme.COLORS.bodyTextColor,
                    }}
                  >
                    累计买入
                  </ApText>
                  <ApText
                    style={{
                      marginBottom: 16,
                      ...theme.FONTS.H6,
                      color: theme.COLORS.mainDark,
                    }}
                  >
                    21771.542
                  </ApText>
                  <ApText
                    style={{
                      ...theme.FONTS.Mulish_400Regular,
                      fontSize: 12,
                      lineHeight: 12 * 1.6,
                      color: theme.COLORS.bodyTextColor,
                    }}
                  >
                    持加仓价
                  </ApText>
                  <ApText
                    style={{
                      marginBottom: 16,
                      ...theme.FONTS.H6,
                      color: theme.COLORS.mainDark,
                    }}
                  >
                    0
                  </ApText>
                </View>
                <View style={{ flex: 1, alignItems: "flex-end" }}>
                  <ApText
                    style={{
                      ...theme.FONTS.Mulish_400Regular,
                      fontSize: 12,
                      lineHeight: 12 * 1.6,
                      color: theme.COLORS.bodyTextColor,
                    }}
                  >
                    累计卖出
                  </ApText>
                  <ApText
                    style={{
                      marginBottom: 16,
                      ...theme.FONTS.H6,
                      color: theme.COLORS.mainDark,
                    }}
                  >
                    27117.542
                  </ApText>
                  <ApText
                    style={{
                      ...theme.FONTS.Mulish_400Regular,
                      fontSize: 12,
                      lineHeight: 12 * 1.6,
                      color: theme.COLORS.bodyTextColor,
                    }}
                  >
                    持加仓量(U)
                  </ApText>
                  <ApText
                    style={{
                      marginBottom: 16,
                      ...theme.FONTS.H6,
                      color: theme.COLORS.mainDark,
                    }}
                  >
                    0
                  </ApText>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            position: "sticky",
            bottom: "0",
            backgroundColor: "#E45F50",
            height: "50",
          }}
        >
          <View style={{ flex: 1 }}>
            <ApText
              style={{
                fontSize: 14,
                paddingLeft: 20,
                fontWeight: "500",
                color: "white",
              }}
            >
              合计盈利:417.528 &nbsp; USDT
            </ApText>
          </View>
          <View style={{ flex: 1 }}>
            <ApText
              style={{
                fontSize: 14,
                textAlign: "right",
                paddingRight: 20,
                color: "white",
              }}
            >
              盈利单数: &nbsp; 16单
            </ApText>
          </View>
        </View>
      </View>
    );
  };

  return (
    <ApSafeAreaView>
      {renderHeader()}
      {renderContent()}
    </ApSafeAreaView>
  );
};

export default TradeHistory;
