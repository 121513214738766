import * as React from "react";
import Svg, { Rect, Path, Defs, Use, Image, Polygon,G } from "react-native-svg";

const HomeTestSvg: React.FC = () => (
    <Svg width="108.000000pt" height="106.000000pt" viewBox="0 0 108.000000 106.000000">
	<G transform="translate(0.000000,106.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
   <Path d="M425 920 c-40 -10 -93 -35 -130 -60 -37 -25 -21 -27 20 -2 54 33 138
56 230 62 l90 7 -90 0 c-49 1 -103 -3 -120 -7z"/>
<Path d="M415 806 c-16 -8 -39 -21 -50 -30 -15 -12 -7 -11 30 5 28 11 68 24
90 29 l40 8 -40 1 c-22 0 -53 -6 -70 -13z"/>
<Path d="M360 275 c0 -11 105 -55 130 -55 16 1 -3 12 -50 30 -83 33 -80 32
-80 25z"/>
<Path d="M315 175 c35 -30 124 -65 163 -65 18 0 2 9 -45 25 -39 13 -88 34
-110 45 l-38 21 30 -26z"/>
</G>
</Svg> 
);

export default HomeTestSvg;
