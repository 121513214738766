import * as React from "react";
import Svg, { G, Circle, Rect } from "react-native-svg";

function TradeFillSvg(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" {...props}>
      <G transform="translate(-.24 -.36)" fill="#f37021">
        <Circle data-name="Ellipse 6776" cx={10} cy={10} r={10} transform="translate(.24 .36)" />
        <Rect data-name="Rectangle 424" width={7.193} height={7.193} rx={1} transform="rotate(-45 15.08 -1.031)" stroke="#fff" strokeMiterlimit={10} strokeWidth={2} />
      </G>
    </Svg>
  );
}

export default TradeFillSvg;
