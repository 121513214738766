import React, { useEffect, useState } from "react";
import { ScrollView, TouchableOpacity, View } from "react-native";
import { AlphabetList } from "react-native-section-alphabet-list";
import { components } from "../components";
import { ApButton, ApSafeAreaView } from "../components/v1";
import { ApSearchInput } from "../components/v1/input";
import { ApRegCheckInput } from "../components/v1/input/reg_check";
import { theme } from "../constants";
import {
  useConfigState,
  useStrategyState,
  useTradingContext,
} from "../context";
import { ISymbol } from "../models";
import { svg } from "../svg";
import { useTranslation } from "react-i18next";
import { ApText } from "../components/v1/typography";

interface IProps {
  navigation?: any;
  selectedItems?: any[];
  multiSelect?: boolean;
  onDimiss?: (seleted: any) => void;
}

let dataList: any = [];
const TokenList: React.FC<IProps> = ({
  navigation,
  onDimiss,
  selectedItems,
  multiSelect = true,
}) => {
  const [selected, setSelected] = useState<any[]>([]);
  const { config, getConfig } = useConfigState();
  const [data, setData] = useState([]);
  const { symbols, setSymbols } = useStrategyState();
  const { market, platform } = useTradingContext();
  const { t } = useTranslation();

  useEffect(() => {
    // getConfig()
    // .then(rs => {
    //   let filter =
    //   rs?.tps?.filter((item) => item.market.toString() === market).
    //   filter((item) => item.platform.toString() === platform)

    //   dataList =
    //     filter?.map((tps) => ({
    //       key: tps.id,
    //       value: tps.symbol,
    //     })) || ([] as any);
    //     setData(dataList);
    // })

    let filter = config?.tps
      ?.filter((item) => item.market.toString() === market)
      .filter((item) => item.platform.toString() === platform);

    dataList =
      filter?.map((tps) => ({
        key: tps.id,
        value: tps.symbol,
        indicator: tps.indicator
      })) || ([] as any);

    setData(dataList);
    setSelected(!!symbols.length ? symbols : selectedItems || []);
  }, []);

  const handleSelected = (item: any) => {
    if (!multiSelect) {
      setSelected([item]);
      handleConfirm([item]);
    }
    if (isSelected(item))
      setSelected(selected.filter((s: any) => s.key !== item.key));
    else setSelected([...selected, item]);
  };

  const isSelected = (item: any) => {
    if (!selected?.length) return false;

    return (
      selected.find(
        (s: any) => s.key === item.key || s.value === item.value
      ) !== undefined
    );
  };

  const handleConfirm = (slt?: ISymbol[]) => {
    setSymbols(!!slt?.length ? slt : selected);

    if (onDimiss) {
      onDimiss(!!slt?.length ? slt : selected);
    }

    if (navigation && navigation.canGoBack()) navigation.goBack();
  };

  const handleSearch = (val: string) => {
    setData(
      dataList.filter((d: any) => d?.value?.toLowerCase().search(val) !== -1)
    );
  };

  const renderHeader = () => {
    return (
      <components.Header
        title={"Token List"}
        goBack={true}
        titleTranlateKey="TokenList"
        customRightIcon={
          <TouchableOpacity
            style={{
              position: "absolute",
              right: 0,
              height: "100%",
              justifyContent: "center",
              paddingLeft: 20,
            }}
            onPress={() => {
              handleConfirm();
            }}
          >
            <View
              style={{
                paddingHorizontal: 20,
                flexDirection: "row",
                paddingVertical: 6,
              }}
            >
              <svg.CheckSvg strokeColor={theme.COLORS.primary} />
            </View>
          </TouchableOpacity>
        }
      />
    );
  };

  const renderContent = () => {
    return (
      <ScrollView
        contentContainerStyle={{
          flexGrow: 1,
        }}
      >
        <View style={{ flex: 1, marginVertical: 10 }}>
          <View style={{ marginHorizontal: 15, marginTop: 10 }}>
            <ApSearchInput onChange={handleSearch} />
          </View>

          <View
            style={{
              margin: 15,
              borderRadius: 10,
              // paddingBottom: 20,
              marginTop: 10,
            }}
          >
            <AlphabetList
              data={data}
              indexLetterStyle={{
                color: "#ababab",
                fontSize: 11,
              }}
              indexLetterContainerStyle={{ marginVertical: 3 }}
              renderCustomItem={(item: any) => (
                <ApRegCheckInput
                  style={{
                    flex: 1,
                    flexDirection: "row",
                    paddingHorizontal: 15,
                    paddingVertical: 15,
                    borderBottomColor: "#8080801f",
                    borderBottomWidth: 1,
                    alignItems: "center",
                  }}
                  customLabel={
                    <View style={{display: 'flex', flexDirection: 'row', marginLeft: 10}}>
                      <ApText
                        translateKey={item.value}
                        size="xs"
                        style={{marginRight: 10}}
                      >
                        {item.value}
                      </ApText>

                      {!item.indicator && (                        
                        <ApText 
                          color={theme.COLORS.primary} 
                          size="xs" 
                        >
                          ({t('NoIndicator')})
                        </ApText> 
                      )}
                    </View>
                  }          
                  defaultValue={isSelected(item)}
                  onChange={(val) => handleSelected(item)}
                  name="token"
                />
              )}
              renderCustomSectionHeader={(section) => (
                <View style={{ display: "none" }}></View>
              )}
            />
          </View>
        </View>
      </ScrollView>
    );
  };

  return (
    <ApSafeAreaView>
      {!onDimiss && renderHeader()}
      {renderContent()}
      {!!config?.tps?.length && (
        <View style={{ paddingHorizontal: 20, paddingVertical: 10 }}>
          <ApButton
            round="lg"
            label="Confirm"
            labelTranslateKey="Confirm"
            onPress={() => handleConfirm()}
          />
        </View>
      )}
    </ApSafeAreaView>
  );
};

export default TokenList;
