import CheckBox from "expo-checkbox";
import { useField } from "formik";
import React, { useState } from "react";
import { StyleProp, TextStyle, View } from "react-native";
import { ApText } from "../typography";
import { useTranslation } from "react-i18next";

interface IProps {
  noBorder?: boolean;
  name: string;
  label?: string;
  labelTranslateKey?: string;
  placeholder?: string;
  labelStyle?: StyleProp<TextStyle>;
  checkBoxStyle?: StyleProp<TextStyle>;
}

export const ApCheckInputAlt: React.FC<IProps> = ({
  noBorder,
  label,
  name,
  labelStyle,
  checkBoxStyle,
  labelTranslateKey,
}) => {
  const { t } = useTranslation();
  const [field, meta, { setValue }] = useField(name);
  const [toggle, setToggle] = useState(false);
  return (
    <View
      style={{
        width: "100%",
        borderBottomWidth: noBorder ? 0 : 1,
        borderBottomColor: "#ced6e182",
      }}
    >
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          width: "100%",
          marginBottom: "10px",
        }}
      >
        <CheckBox
          value={toggle}
          color={toggle ? "rgb(243, 112, 33)" : undefined}
          style={checkBoxStyle}
          onValueChange={(v) => {
            setToggle(!toggle);
            setValue(!toggle);
          }}
        />
        {(labelTranslateKey || label) && (
          <ApText
            translateKey={labelTranslateKey}
            size="xs"
            style={labelStyle}
          />
        )}
      </View>
      <>
        {meta.touched && meta.error && (
          <ApText
            style={{
              fontSize: 11,
              color: "red",
              marginBottom: 5,
              marginTop: 5,
            }}
          >
            {t(meta.error)}
          </ApText>
        )}
      </>
    </View>
  );
};
