import { useNavigation } from "@react-navigation/native";
import React from "react";
import { View, TouchableOpacity } from "react-native";
import { ApText } from "../../../../components/v1/typography";
import {
  useStrategyState,
  useConfigState,
  useTradingContext,
} from "../../../../context";

export const DetailsNav = ({ bex_id }) => {
  const navigation: any = useNavigation();
  const { setSymbols } = useStrategyState();
  const { config } = useConfigState();
  const {
    botExecutorByID,
    market,
    setExtraSettings,
    setSelectedStrategy,
    botPlans,
    setNumOfReentry,
    botExecutorStrategySummary,
    generateOrderSettings,
  } = useTradingContext();

  const handleUpdate = () => {
    setSymbols([]);
    navigation.navigate("BotSettings", {
      bex_id
    })
  };

  const handleFollowNav = () => {
    let filter = config?.tps?.filter(
      (item) =>
        item.market.toString() === botExecutorStrategySummary.market &&
        item.platform.toString() === botExecutorStrategySummary.platform &&
        item.symbol === botExecutorStrategySummary.symbol 
    );
    let dataList =
      filter?.map((tps) => ({
        key: tps.id,
        value: tps.symbol,
      })) || ([] as any);
    setSymbols(dataList);

    navigation.navigate("StrategyDetails", {
      bex_id: botExecutorByID.bex_id,
    })
  }
  
  const handleNavigate = () => {
    // setActive_MACD(null)
    // setActive_EMA4(null)
    let all = [...botPlans.default, ...botPlans.my];
    let item = all.filter(item => item.id == botExecutorStrategySummary.bp_id)[0]

    setSelectedStrategy(item);
    setNumOfReentry(parseInt(item.max_entries));

    setExtraSettings(item.max_entries, true, item).then((rs) => {
      if(item.plan_type == "1"){
        generateOrderSettings(item.max_sets, item, true)
        .then(() => {
          navigation.navigate("EditStrategy", {
            bex_id: botExecutorStrategySummary.id
          });
        })
      } else {
        navigation.navigate("EditStrategy", {
          bex_id: botExecutorStrategySummary.id
        }); 
      }
    });
  };

  
  return (
    <View
      style={{
        flexDirection: "row",
        paddingVertical: 20,
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <TouchableOpacity
        onPress={() =>
          navigation.navigate("BotActionLog", {
            bex_id: bex_id,
          })
        }
        style={{
          height: 32,
          minWidth: 100,
          borderRadius: 8,
          borderWidth: 1,
          borderColor: "#F37021",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ApText
          style={{ color: "#F37021", fontWeight: "500" }}
          translateKey="Action"
        />
      </TouchableOpacity>

      <TouchableOpacity
        onPress={() =>
          navigation.navigate("BotOrder", {
            bex_id: bex_id,
          })
        }
        style={{
          height: 32,
          minWidth: 100,
          borderRadius: 8,
          borderWidth: 1,
          borderColor: "#F37021",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ApText
          style={{ color: "#F37021", fontWeight: "500" }}
          translateKey="Order"
        />
      </TouchableOpacity>

      {botExecutorStrategySummary.status !== "2" ?
        <TouchableOpacity
          onPress={() => {
            botExecutorStrategySummary.is_peer_bp == "1" ?
            handleFollowNav() : handleNavigate()
          }}
          style={{
            height: 32,
            minWidth: 100,
            borderRadius: 8,
            borderWidth: 1,
            borderColor: "#F37021",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ApText
            style={{ color: "#F37021", fontWeight: "500" }}
            translateKey="Update"
          />
        </TouchableOpacity>
        : <></>
      }
    </View>
  );
};
