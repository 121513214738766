import { useNavigation } from "@react-navigation/native";
import moment from "moment";
import React, { Dispatch, SetStateAction, useState } from "react";
import {
  IAnnouncement,
  IDashboardContent,
  IDepositAddress,
  IEditProfile,
  IExchangeRefInfo,
  IGenealogy,
  IMaterial,
  IPnlLeadershipBoard,
  IProfile,
  IRankProgressInfo,
  ITutorial,
  IUploadProfilePic,
} from "../models/profile.interface";
import { ApErrorToast, ApSuccessToast, ApiService } from "../services";

interface IProfileState {
  loading: boolean;
  profileData: Partial<IProfile>;
  rankProgressInfo: Partial<IRankProgressInfo>;
  genealogyData: Partial<IGenealogy>;
  announcementList: IAnnouncement[];
  dashboardData: Partial<IDashboardContent>;
  leadershipBoard: Partial<IPnlLeadershipBoard>;
  materialList: IMaterial[];
  tutorialList: ITutorial[];
  depositAddress: Partial<IDepositAddress>;
  exchangeRateInfo: Partial<IExchangeRefInfo>;
  newNickName: string | null;
  selectedAnnoucement: IAnnouncement;
  setSelectedAnnoucement: Dispatch<SetStateAction<IAnnouncement>>;
  setLang: (lang: string) => Promise<void>;
  getProfileData: () => Promise<void>;
  getRankProgressInfo: () => Promise<void>;
  getGenealogyData: () => Promise<void>;
  getAnnouncementList: () => Promise<void>;
  getDashboardData: () => Promise<void>;
  getLeadershipBoard: () => Promise<void>;
  getMaterialList: () => Promise<void>;
  getTutorialList: () => Promise<void>;
  getDepositAddress: (symbol: "usdt" | "ddb") => Promise<void>;
  updateAllowedDeductionWallets: (payload: {
    allowed_deduction_wallets: string;
  }) => Promise<void>;
  updateCreditAlertAmount: (payload: {credit_alert_amount: string}) => Promise<void>;
  getExchangeRateInfo: () => Promise<void>;
  editProfile: (payload: IEditProfile) => Promise<string>;
  uploadProfilePic: (payload: IUploadProfilePic) => Promise<string>;
}

const ProfileContext = React.createContext<IProfileState>({
  newNickName: null,
  loading: false,
  profileData: {},
  rankProgressInfo: {},
  genealogyData: {
    direct: {},
    direct_dls: [],
    group: {},
    status: "",
  },
  dashboardData: {
    banners: [],
    new: [],
    pnl_leadership: [],
    popup_news: [],
    self_pnl_amt: "",
    today_self_pnl_amt: "",
  },
  announcementList: [],
  leadershipBoard: {
    day: [],
    month: [],
  },
  materialList: [],
  tutorialList: [],
  membershipSubscription: [],
  depositAddress: {},
  exchangeRateInfo: {},
  getProfileData() {},
  getRankProgressInfo() {},
  getGenealogyData() {},
  getAnnouncementList() {},
  getDashboardData() {},
  getLeadershipBoard() {},
  getMaterialList() {},
  getTutorialList() {},
  setLang(lang: string) {},
  getMembershipSubscription() {},
  getDepositAddress(symbol: "usdt" | "ddb") {},
  updateAllowedDeductionWallets() {},
  updateCreditAlertAmount(){},
  getExchangeRateInfo() {},
  editProfile(payload: IEditProfile) {
    return null;
  },
  uploadProfilePic(payload: IUploadProfilePic) {
    return null;
  },
} as unknown as IProfileState);

const useProfileState = () => {
  const context = React.useContext(ProfileContext);
  if (context === undefined) {
    throw new Error("app dispatch must be used within app global provider");
  }
  return context;
};

interface IProps {
  children: React.ReactNode;
}

const ProfileContextProvider: React.FC<IProps> = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [profileData, setProfileData] = useState<Partial<IProfile>>({});
  const [newNickName, setNewNickName] = useState<string | null>(null);
  const [selectedAnnoucement, setSelectedAnnoucement] =
    useState<IAnnouncement>();
  const navigation: any = useNavigation();

  const [rankProgressInfo, setRankProgressInfo] = useState<
    Partial<IRankProgressInfo>
  >({});
  const [genealogyData, setGenealogyData] = useState<Partial<IGenealogy>>({
    direct: {},
    direct_dls: [],
    group: {},
    status: "",
  });
  const [announcementList, setAnnouncementList] = useState<IAnnouncement[]>([]);
  const [dashboardData, setDashboardData] = useState<
    Partial<IDashboardContent>
  >({
    banners: [],
    new: [],
    pnl_leadership: [],
    popup_news: [],
    self_pnl_amt: "",
    today_self_pnl_amt: "",
  });
  const [leadershipBoard, setLeadershipBoard] = useState<
    Partial<IPnlLeadershipBoard>
  >({
    day: [],
    month: [],
  });
  const [materialList, setMaterialList] = useState<IMaterial[]>([]);
  const [tutorialList, setTutorialList] = useState<ITutorial[]>([]);

  const [depositAddress, setDepositAddress] = useState<
    Partial<IDepositAddress>
  >({});
  const [exchangeRateInfo, setExchangeRateInfo] = useState<
    Partial<IExchangeRefInfo>
  >({});
  const getProfileData = async () => {
    setLoading(true);
    return ApiService.get(`/profile/me`)
      .then((rs) => {
        const subDate = rs?.data?.data?.subscription_expiry;
        setProfileData({
          ...rs?.data?.data,
          active_profile: moment(moment(subDate).format("YYYY-MM-DD")).isAfter(
            moment()
          ),
        });
        return rs?.data?.data;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getRankProgressInfo = async (): Promise<void> => {
    setLoading(true);
    return ApiService.get(`/profile/getRankProgressInfo`)
      .then((rs) => {
        setRankProgressInfo(rs?.data?.data);
        return rs?.data?.data;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getGenealogyData = async (): Promise<void> => {
    setLoading(true);
    return ApiService.get(`/profile/genealogy`)
      .then((rs) => {
        setGenealogyData({
          status: rs?.data?.status,
          direct: rs?.data?.data?.direct,
          group: rs?.data?.data?.group,
          direct_dls: rs?.data?.data?.direct_dls,
        });
        return rs?.data;
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getAnnouncementList = async (): Promise<void> => {
    setLoading(true);
    return ApiService.get(`/profile/getAllAnnouncements`)
      .then((rs) => {
        setAnnouncementList(rs?.data?.data);
        return rs?.data?.data;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getDashboardData = async () => {
    setLoading(true);
    ApiService.get(`/profile/getContents`)
      .then((rs) => {
        setDashboardData({
          ...rs?.data?.data,
          pnl_leadership: rs?.data?.data?.pnl_leadership?.map((p) => ({
            ...p,
            amount: p.self_pnl_amt,
          })),
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getLeadershipBoard = async () => {
    setLoading(true);
    return ApiService.get(`/profile/getPnlLeadershipBoard`)
      .then((rs) => {
        setLeadershipBoard(rs?.data?.data);
        return rs?.data?.data;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getMaterialList = async () => {
    setLoading(true);
    return ApiService.get(`/profile/getAllMaterials`)
      .then((rs) => {
        setMaterialList(rs?.data?.data);
        return rs?.data?.data;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getTutorialList = async () => {
    setLoading(true);
    return ApiService.get(`/profile/getAllTutorials`, {})
      .then((rs) => {
        setTutorialList(rs?.data?.data);
        return rs?.data?.data;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateAllowedDeductionWallets = async (payload: {
    allowed_deduction_wallets: string;
  }) => {
    setLoading(true);
    return ApiService.post(`/profile/updateAllowedDeductionWallets`, payload)
      .then((rs) => {
        ApSuccessToast("", rs?.data?.msg);
        getProfileData();
        navigation.navigate("AccountSetting");
        return rs?.data?.msg;
      })
      .catch((err) => {
        ApErrorToast("", err);
        throw err;
      })
      .finally(() => setLoading(false));
  };

  const updateCreditAlertAmount = async (payload: {credit_alert_amount: string}) => {
    setLoading(true);
    return ApiService.post(`/profile/updateCreditAlertAmount`, payload)
    .then((rs) => {      
      ApSuccessToast("", rs?.data?.msg);
      navigation.navigate("AccountSetting");
      getProfileData();
      return rs?.data?.msg;
    })
    .catch((err) => {
      ApErrorToast("", err);
      throw err;
    })
    .finally(() => setLoading(false));
  }

  const getDepositAddress = async (symbol: "usdt" | "ddb") => {
    setLoading(true);

    return ApiService.get(
      `/profile/${
        symbol === "usdt" ? "getDepositAddress" : "getDepositAddressDdb"
      }`
    )
      .then((rs) => {
        setDepositAddress(rs?.data?.data);
        return rs?.data?.data;
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getExchangeRateInfo = async () => {
    setLoading(true);
    return ApiService.get(`/profile/getExchangeRefInfo`)
      .then((rs) => {
        setExchangeRateInfo(rs?.data?.data);
        return rs?.data?.data;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const setLang = async (language: string): Promise<void> => {
    return ApiService.post(`/profile/setLanguage`, { language })
      .then((rs) => {
        setExchangeRateInfo(rs?.data?.data);
        getProfileData();
        if (!!tutorialList.length) setTutorialList([]);
        return;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const editProfile = async (payload: IEditProfile) => {
    return await ApiService.post(`/profile/editProfile`, payload)
      .then((rs) => {
        setNewNickName(payload.nickname);
        setProfileData({ ...profileData, nickname: payload.nickname });
        return rs?.data?.data;
      })
      .catch((err) => {
        ApErrorToast("", err);
        throw err;
      });
  };

  const uploadProfilePic = async (payload: IUploadProfilePic): Promise<any> => {
    return await ApiService.post(`/profile/uploadProfilePic`, payload)
      .then((rs) => {
        //setProfileData({ ...profileData, nickname: payload.nickname });
        return rs?.data?.data;
      })
      .catch((err) => {
        ApErrorToast("", err);
        throw err;
      });
  };

  // const uploadProfilePic = async (payload: IUploadProfilePic): Promise<any> => {
  //   const form = new FormData();
  //   form.append("img_file", "");
  //   // form.append("img_file", ApFileService.dataURItoBlob(payload.img_file));
  //   form.append("img_file", "/Users/idrissar/Downloads/no_data.png");
  //   const accessToken = await AsyncStorage.getItem("accessToken");
  //   // return await ApiService.axios.request(`/profile/uploadProfilePic`, form, {
  //   //   headers: {
  //   //     "Content-Type": "multipart/form-data",
  //   //     skipContentType: true,
  //   //   },
  //   // })
  //   return await fetch(`${BASE_URL}/api/profile/uploadProfilePic`, {
  //     method: "POST",
  //     headers: {
  //       Accept: "*/*",
  //       "Content-Type": "multipart/form-data",
  //       Authorization: `Bearer ${accessToken}`,
  //     },
  //     body: form,
  //   })
  //     .then(async (rs) => {
  //       console.log(await rs.json(), "response..");
  //       // return rs?.data?.data;
  //       return rs;
  //     })
  //     .catch((err) => {
  //       ApErrorToast("", err);
  //       throw err;
  //     });
  // };

  return (
    <ProfileContext.Provider
      value={{
        selectedAnnoucement,
        setSelectedAnnoucement,
        updateAllowedDeductionWallets,
        updateCreditAlertAmount,
        newNickName,
        profileData,
        loading,
        rankProgressInfo,
        genealogyData,
        announcementList,
        dashboardData,
        leadershipBoard,
        materialList,
        tutorialList,
        depositAddress,
        exchangeRateInfo,
        setLang,
        getProfileData,
        getRankProgressInfo,
        getGenealogyData,
        getAnnouncementList,
        getDashboardData,
        getLeadershipBoard,
        getMaterialList,
        getTutorialList,
        getDepositAddress,
        getExchangeRateInfo,
        editProfile,
        uploadProfilePic,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

export { ProfileContextProvider, useProfileState };
