import { View, Text } from "react-native";
import React from "react";
import { ApText } from "../components/v1/typography";

const OpenMoneybox: React.FC = () => {
  return (
    <View>
      <ApText>OpenMoneybox</ApText>
    </View>
  );
};

export default OpenMoneybox;
