import React from "react";
import ContentLoader, { Rect, Circle, Path } from "react-content-loader/native";

export const ApBlockLoader = () => (
  <ContentLoader
    speed={2}
    width={476}
    height={600}
    viewBox="0 0 476 600"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <Rect x="18" y="8" rx="0" ry="0" width="340" height="640" />
  </ContentLoader>
);
