import { LinearGradient } from "expo-linear-gradient";
import React from "react";
import {
  StyleProp,
  StyleSheet,
  TextStyle,
  TouchableOpacity,
  ViewStyle,
  ActivityIndicator,
  View
} from "react-native";
import { ApText } from "../typography";

interface IProps {
  containerWidth?: string
  containerHeight?: string | number
  loading?:boolean
  labelStyle?: StyleProp<TextStyle>;
  label?: string;
  disabled?: boolean;
  labelTranslateKey?: string;
  style?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  round?: "" | "sm" | "md" | "lg" | "xl" | "xxl";
  type?:
    | "primary"
    | "secondary"
    | "link"
    | "black"
    | "success"
    | "danger"
    | "light";
  onPress?: () => void;
}

export const ApButton: React.FC<IProps> = ({
  containerWidth,
  containerHeight,
  loading,
  labelStyle,
  label,
  onPress,
  disabled,
  style,
  textStyle,
  round = "",
  type = "primary",
  labelTranslateKey,
}) => {
  return (
    <TouchableOpacity onPress={onPress} disabled={disabled} style={{width: containerWidth ? containerWidth : "", height: containerHeight ? containerHeight : ""}}>
      <LinearGradient
        colors={[]}
        style={[
          styles.button,
          styles[`round_${round}`],
          styles1[type].button,
          style,
        ]}
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 0 }}
      >
        {
          loading ? (
            <View
              style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}
            >
              <ApText
                translateKey="Loading"
                style={[styles1[type].text, labelStyle, textStyle, {marginRight: 10}]}
              >
                  Loading
              </ApText>
              
              <ActivityIndicator size="small" color="#fff" />
            </View>
          ) : (
            <ApText
              translateKey={labelTranslateKey}
              style={[styles1[type].text, labelStyle, textStyle]}
            >
              {label}
            </ApText>
          )
        }
      </LinearGradient>
    </TouchableOpacity>
  );
};

const styles1 = {
  primary: StyleSheet.create({
    button: {
      backgroundColor: "#F37021",
    },
    text: {
      color: "white",
      //fontWeight: "bold",
    },
  }),
  secondary: StyleSheet.create({
    button: {
      backgroundColor: "rgb(1, 147, 179)",
      fontWeight: "bold",
    },
    text: {
      color: "white",
    },
  }),
  success: StyleSheet.create({
    button: {
      backgroundColor: "#05B491",
      fontWeight: "bold",
    },
    text: {
      color: "white",
    },
  }),
  link: StyleSheet.create({
    button: {
      backgroundColor: "white",
      marginTop: 0,
    },
    text: {
      color: "blue",
      fontWeight: "bold",
    },
  }),
  light: StyleSheet.create({
    button: {
      backgroundColor: "#F5F7FB",
      marginTop: 0,
    },
    text: {
      color: "black",
      fontWeight: "bold",
    },
  }),
  black: StyleSheet.create({
    button: {
      backgroundColor: "white",
      marginTop: 0,
      borderWidth: 1,
      borderColor: "grey",
      padding: 4,
      height: 35,
      marginLeft: 6,
    },
    text: {
      color: "grey",
    },
  }),
  danger: StyleSheet.create({
    button: {
      backgroundColor: "red",
    },
    text: {
      color: "white",
    },
  }),
};

const styles = StyleSheet.create({
  container: { padding: 5 },
  text: {
    textAlign: "center",
    color: "white",
  },
  button: {
    padding: 8,
    height: 45,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  round_: {},
  round_sm: {
    borderRadius: 2,
  },
  round_md: {
    borderRadius: 4,
  },
  round_lg: {
    borderRadius: 6,
  },
  round_xl: {
    borderRadius: 10,
  },
  round_xxl: {
    borderRadius: 50,
  },
});
