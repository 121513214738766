import { useFocusEffect, useNavigation } from "@react-navigation/native";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Dimensions,
  Image,
  ImageBackground,
  Linking,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";
import { SwiperFlatList } from "react-native-swiper-flatlist";
import { useDispatch, useSelector } from "react-redux";
import { components } from "../components";
import { ApSafeAreaView } from "../components/v1";
import { LeadBoard } from "../components/v1/leadbord";
import { ApText } from "../components/v1/typography";
import { theme } from "../constants";
import { BASE_URL } from "../constants/master";
import { useProfileState } from "../context";
import { useConfigState } from "../context/config";
import { setHideProfit } from "../store/hideProfitSlice";
import { svg } from "../svg";
import { formatAmt, toCurrency } from "../utils";

const { width } = Dimensions.get("window");
const styles = StyleSheet.create({
  paginationStyle: {
    backgroundColor: "rgba(0,0,0,.2)",
  },
  container: { flex: 1, backgroundColor: "white" },
  child: { width, justifyContent: "center" },
  text: { fontSize: width * 0.5, textAlign: "center" },
});

const Dashboard: React.FC = () => {
  const { dashboardData, getDashboardData } = useProfileState();
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const [refreshing, setRefreshing] = useState(false);
  const { getConfig, config, getPriceList, priceList } = useConfigState();
  const navigation: any = useNavigation();
  const hideProfit = useSelector(
    (state: any) => state.hideProfitSlice.hideProfit
  );

  useEffect(() => {
    getConfig();
    getDashboardData();
    getPriceList();
  }, []);

  useFocusEffect(
    useCallback(() => {
      const interval = setInterval(() => {
        getPriceList();
      }, 8000);

      return () => clearInterval(interval);
    }, [])
  );

  const paymentTypesTwo = [
    {
      id: "4",
      type: t("Referral"),
      icon: <svg.ReferralSvg />,
      onclick: () => navigation.navigate("Referral"),
    },
    {
      id: "5",
      type: t("Tutorials"),
      icon: <svg.TutorialSvg />,
      onclick: () => navigation.navigate("TutorialList"),
    },
    {
      id: "6",
      type: t("Materials"),
      icon: <svg.MaterialSvg />,
      onclick: () => navigation.navigate("Materials"),
    },
    {
      id: "7",
      type: t("Chat"),
      icon: <svg.ChatSvg />,
      onclick: () => Linking.openURL(config.livechat_url),
    },
  ];
  const paymentTypes = [
    {
      id: "1",
      type: t("Account"),
      icon: <svg.AccountSvg />,
      onclick: () => navigation.navigate("OpenAccount"),
    },
    {
      id: "2",
      type: t("API"),
      icon: <svg.ApiSvg />,
      onclick: () => navigation.navigate("API"),
    },
    {
      id: "3",
      type: t("Purchase"),
      icon: <svg.PurchaseSvg />,
      onclick: () => navigation.navigate("Membership"),
    },
    {
      id: "4",
      type: t("Duobao"),
      icon: <svg.DuobaoSvg />,
      onclick: () => navigation.navigate("Duobao"),
    },
  ];

  const renderHeader = () => {
    return (
      <ApSafeAreaView
        style={{
          paddingHorizontal: 10,

          paddingVertical: 10,
        }}
      >
        <View style={styles.container}>
          <SwiperFlatList
            paginationStyle={{ height: 5 }}
            paginationStyleItem={{
              width: 20,
              height: 3,
              margin: 0,
              borderRadius: 0,
            }}
            paginationActiveColor="#ffffffcc"
            paginationDefaultColor="#ffffff57"
            style={{ borderRadius: 12, width: "100%", height: "100%" }}
            autoplay
            autoplayDelay={5}
            autoplayLoop
            index={dashboardData?.banners?.length}
            showPagination
            data={dashboardData?.banners}
            renderItem={({ item }) => (
              <View
                style={[
                  styles.child,
                  styles.paginationStyle,
                  { backgroundColor: item, height: 160 },
                ]}
              >
                <Image
                  source={{
                    uri: `${BASE_URL}/${item.img_url}`,
                  }}
                  style={{ width: "100%", height: "100%" }}
                />
              </View>
            )}
          />
        </View>
      </ApSafeAreaView>
    );
  };

  const handleRefresh = () => {
    setTimeout(() => {
      setRefreshing(false);
    }, 1000);
  };

  const renderCategories = () => {
    return (
      <View style={{ marginHorizontal: 10, borderTopColor: "white" }}>
        <View>
          <components.AnnouncementNav
            AnnouncementTitle={
              !!dashboardData?.popup_news?.length
                ? dashboardData?.popup_news[0]?.title
                : ""
            }
            AnnouncementNavType="Title"
            arrow=">"
            containerStyle={{ marginTop: 10 }}
            bell={true}
            onPress={() => {
              navigation.navigate("AnnouncementList");
            }}
          />
        </View>
        <TouchableOpacity onPress={() => navigation.navigate("Profit")}>
          <View
            style={{
              paddingVertical: 15,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <View style={{ width: "100%" }}>
              <ImageBackground
                source={require("../../assets/bg-04.png")}
                imageStyle={{
                  borderRadius: 12,
                  width: "100%",
                  backgroundColor: "#fff5f0ba",
                }}
              >
                <View
                  style={{
                    width: "100%",
                    borderRadius: 10,
                    paddingHorizontal: 15,
                    paddingVertical: 10,
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <View
                      style={{ flexDirection: "row", alignItems: "center" }}
                    >
                      <ApText
                        translateKey="YesterdayProfitUSDT"
                        style={{
                          fontSize: 14,
                          fontWeight: "500",
                          paddingBottom: 3,
                          paddingRight: 10,
                        }}
                      >
                        Yesterday's Profit (USDT)
                      </ApText>
                      <TouchableOpacity
                        onPress={() => {
                          dispatch(setHideProfit(true));
                        }}
                      >
                        {hideProfit ? <svg.EyeOffSvg /> : <svg.EyeOnSvg />}
                      </TouchableOpacity>
                    </View>

                    <View style={{ marginLeft: "auto" }}>
                      <Image
                        source={require("../../assets/payment/right-2-svgrepo-com.svg")}
                        style={{ width: 20, height: 20 }}
                      />
                    </View>
                  </View>

                  <ApText
                    style={{
                      fontSize: 22,
                      lineHeight: 23,
                      fontWeight: "bold",
                      color: "#F37021",
                    }}
                  >
                    {hideProfit
                      ? "*****"
                      : dashboardData?.ytd_self_pnl_amt
                      ? toCurrency(
                          parseFloat(dashboardData?.ytd_self_pnl_amt),
                          "",
                          2
                        )
                      : ""}
                  </ApText>

                  <View style={{ paddingTop: 10 }}>
                    <ApText
                      translateKey="Past30dProfitUSDT"
                      style={{
                        fontSize: 12,
                        fontWeight: "500",
                        color: "#a3a3a3",
                      }}
                    >
                      Past 30d Profit (USDT)
                    </ApText>

                    <ApText
                      style={{
                        fontSize: 12,
                        fontWeight: "500",
                        color: "#a3a3a3",
                        paddingRight: 25,
                      }}
                    >
                      {hideProfit
                        ? "*****"
                        : dashboardData?.month_self_pnl_amt
                        ? toCurrency(
                            parseFloat(dashboardData?.month_self_pnl_amt),
                            "",
                            2
                          )
                        : ""}
                    </ApText>
                  </View>
                </View>
              </ImageBackground>
            </View>
          </View>
        </TouchableOpacity>
        <View
          style={{
            padding: 15,
            marginBottom: 15,
            backgroundColor: "#fff5f0ba",
            borderRadius: 10,
          }}
        >
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {paymentTypes.map((item, index) => {
              return (
                <TouchableOpacity
                  key={index}
                  activeOpacity={0.8}
                  onPress={item.onclick}
                >
                  <View
                    style={{
                      width: 40,
                      height: 40,
                      borderRadius: 40 / 2,
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "auto",
                    }}
                  >
                    {item.icon}
                  </View>
                  <ApText
                    style={{
                      textAlign: "center",
                      ...theme.FONTS.Mulish_600SemiBold,
                      fontSize: 11,
                      lineHeight: 10 * 1.2,
                      color: theme.COLORS.bodyTextColor,
                    }}
                  >
                    {item.type}
                  </ApText>
                </TouchableOpacity>
              );
            })}
          </View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              paddingTop: 12,
            }}
          >
            {paymentTypesTwo.map((item, index) => {
              return (
                <TouchableOpacity
                  key={index}
                  activeOpacity={0.8}
                  onPress={item.onclick}
                >
                  <View
                    style={{
                      width: 40,
                      height: 40,
                      borderRadius: 40 / 2,
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "auto",
                    }}
                  >
                    {item.icon}
                  </View>
                  <ApText
                    style={{
                      textAlign: "center",
                      ...theme.FONTS.Mulish_600SemiBold,
                      fontSize: 11,
                      lineHeight: 10 * 1.2,
                      color: theme.COLORS.bodyTextColor,
                    }}
                  >
                    {item.type}
                  </ApText>
                </TouchableOpacity>
              );
            })}
          </View>
        </View>
        <TouchableOpacity onPress={() => navigation.navigate("IncomeRanking")}>
          <View
            style={{
              padding: 15,
              backgroundColor: "#fff5f0ba",
              marginBottom: 15,
              borderRadius: 10,
              paddingHorizontal: 10,
            }}
          >
            <View
              style={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <ApText style={{ fontWeight: "500" }}>
                {t("IncomeRanking")}
              </ApText>
              <View style={{ marginLeft: "auto" }}>
                <Image
                  source={require("../../assets/payment/right-2-svgrepo-com.svg")}
                  style={{ width: 20, height: 20 }}
                />
              </View>
            </View>
            {/* <View
              style={{
                backgroundColor: "#fff5f0ba",
                marginBottom: 15,
                borderRadius: 10,
                paddingHorizontal: 10,
                flexDirection: "row",
                justifyContent: "space-between",
                alignContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              {dashboardData?.pnl_leadership &&
                dashboardData?.pnl_leadership.map((u, ui) => (
                  <RenderLeaderBoad index={ui} user={u} key={ui} />
                ))}
            </View> */}
            <LeadBoard
              leadershipBoard={[
                dashboardData?.pnl_leadership[1],
                dashboardData?.pnl_leadership[0],
                dashboardData?.pnl_leadership[2],
              ]}
              nickNameStyle={{
                fontWeight: "500",
                paddingTop: 5,
                fontSize: 13,
                maxWidth: 80,
                color: "black",
              }}
              amountStyle={{
                fontWeight: "bold",
                paddingTop: 3,
                fontSize: 13,
                color: "#F37021",
              }}
            />
          </View>
        </TouchableOpacity>
      </View>
    );
  };

  const renderContent = () => {
    return (
      <View
        style={{
          marginHorizontal: 10,
          paddingHorizontal: 15,
          backgroundColor: "#fff5f0ba",
          borderRadius: 10,
          marginBottom: 25,
          paddingBottom: 15,
        }}
      >
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: 20,
            paddingTop: 20,
          }}
        >
          <ApText
            style={{
              fontWeight: "500",
            }}
          >
            {t("CurrencyQuotes")}
          </ApText>
        </View>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: 8,
          }}
        >
          <ApText
            style={{
              fontWeight: "500",
              color: "grey",
              fontSize: 11,
              width: "35%",
            }}
          >
            {t("Currency")}
          </ApText>
          <ApText
            style={{
              fontWeight: "500",
              color: "grey",
              fontSize: 11,
              width: "35%",
              textAlign: "right",
            }}
          >
            {t("LatestPrice")}
          </ApText>
          <ApText
            style={{
              fontWeight: "500",
              color: "grey",
              fontSize: 11,
              width: "30%",
              textAlign: "right",
            }}
          >
            {t("Change")}
          </ApText>
        </View>
        <ScrollView style={{ height: 300 }}>
          <View>
            {priceList
              ?.filter((e: any, index: number) => index < 10)
              .map((d, index) => (
                <components.Transaction
                  key={index}
                  transactionType=""
                  paymentType={d.symbol}
                  type={d?.symbol.split("USDT")[0].toLowerCase()}
                  amount={d.priceChangePercent}
                  containerStyle={{ marginBottom: 6 }}
                  deposit={parseFloat(d?.priceChangePercent) < 0}
                  latestPrice={formatAmt(d?.lastPrice)}
                  onPress={() => navigation.navigate("TransactionDetails")}
                />
              ))}
          </View>
        </ScrollView>
      </View>
    );
  };

  return (
    <ScrollView
      contentContainerStyle={{ flexGrow: 1, marginBottom: 70 }}
      showsVerticalScrollIndicator={false}
      bounces={false}
    >
      {renderHeader()}
      {renderCategories()}
      {renderContent()}
    </ScrollView>
  );
};

export default Dashboard;
