import * as React from "react";
import Svg, { Defs, LinearGradient, Stop, G, Circle, Path, Text, TSpan } from "react-native-svg";

function StarSvg(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={15} height={15.04} viewBox="0 0 15 15.04" {...props}>
      <Defs>
        <LinearGradient id="b" x1={0.5} y1={1} x2={0.5} gradientUnits="objectBoundingBox">
          <Stop offset={0} stopColor="#f7e6b3" />
          <Stop offset={1} stopColor="#fb8a08" />
        </LinearGradient>
        <LinearGradient id="c" x1={0.75} y1={0.933} x2={0.25} y2={0.067} gradientUnits="objectBoundingBox">
          <Stop offset={0} stopColor="#ffe69d" />
          <Stop offset={1} stopColor="#fee283" />
        </LinearGradient>
        <LinearGradient id="a" x1={0.151} y1={0.958} x2={0.849} y2={0.223} gradientUnits="objectBoundingBox">
          <Stop offset={0} stopColor="#fffdea" />
          <Stop offset={1} stopColor="#f9e4bb" />
        </LinearGradient>
        <LinearGradient id="d" x1={0.002} y1={0.498} x2={0.998} y2={0.498} xlinkHref="#a" />
        <LinearGradient id="e" x1={0.005} y1={0.502} x2={1} y2={0.502} xlinkHref="#a" />
        <LinearGradient id="f" x1={0.002} y1={0.5} x2={0.997} y2={0.5} xlinkHref="#a" />
        <LinearGradient id="g" x1={0.003} y1={0.5} x2={0.998} y2={0.5} xlinkHref="#a" />
      </Defs>
      <G data-name="Star Medal-01" transform="translate(-47.831 -46.017)">
        <Circle data-name="Ellipse 6775" cx={7.5} cy={7.5} r={7.5} transform="rotate(180 31.415 30.529)" fill="url(#b)" />
        <Circle data-name="Ellipse 6768" cx={6.943} cy={6.943} r={6.943} transform="translate(48.388 46.614)" fill="url(#b)" />
        <Circle data-name="Ellipse 6769" cx={6.235} cy={6.235} r={6.235} transform="translate(49.096 47.322)" fill="url(#c)" />
        <Path
          data-name="Path 14885"
          d="M66.277 57.4l1.968 3.985 4.409.644-3.192 3.107.757 4.381-3.942-2.067-3.942 2.067.757-4.381-3.192-3.107 4.409-.644z"
          transform="translate(-10.946 -10.397)"
          stroke="#edb745"
          strokeMiterlimit={10}
          strokeWidth={0.5}
          fill="url(#a)"
        />
        <Circle data-name="Ellipse 6770" cx={0.616} cy={0.616} r={0.616} transform="translate(54.715 46.267)" stroke="#edb745" strokeMiterlimit={10} strokeWidth={0.5} fill="url(#d)" />
        <Circle data-name="Ellipse 6771" cx={0.616} cy={0.616} r={0.616} transform="translate(61.248 51.108)" stroke="#edb745" strokeMiterlimit={10} strokeWidth={0.5} fill="url(#e)" />
        <Circle data-name="Ellipse 6772" cx={0.616} cy={0.616} r={0.616} transform="translate(48.225 51.108)" stroke="#edb745" strokeMiterlimit={10} strokeWidth={0.5} fill="url(#e)" />
        <Circle data-name="Ellipse 6773" cx={0.616} cy={0.616} r={0.616} transform="translate(50.631 58.66)" stroke="#edb745" strokeMiterlimit={10} strokeWidth={0.5} fill="url(#f)" />
        <Circle data-name="Ellipse 6774" cx={0.616} cy={0.616} r={0.616} transform="translate(58.799 58.66)" stroke="#edb745" strokeMiterlimit={10} strokeWidth={0.5} fill="url(#g)" />
        <Text
          data-name={3}
          transform="translate(53.919 54.724)"
          fill="#fa9218"
          fontSize={4}
          fontFamily="Inter-Medium, Inter"
          fontWeight={500}
          letterSpacing="0.017em"
          style={{
            isolation: "isolate",
          }}
        >
          <TSpan x={0} y={0}>
            {"3"}
          </TSpan>
        </Text>
      </G>
    </Svg>
  );
}

export default StarSvg;
