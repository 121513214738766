import * as React from "react";
import Svg, { G, Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width={35.592} height={38.923} viewBox="0 0 35.592 38.923" {...props}>
      <G data-name="gift-svgrepo-com (1)" fill="none" strokeLinejoin="round" strokeWidth={3}>
        <Path
          data-name="Path 48"
          d="M5 14.915a3.186 3.186 0 012.963-2.963h26.666a3.186 3.186 0 012.963 2.963v5.926h-2.963v14.811a3.186 3.186 0 01-2.963 2.963h-20.74a3.186 3.186 0 01-2.963-2.963V20.841H5z"
          stroke="#7a8390"
          transform="translate(-3.5 -1.196)"
        />
        <Path
          data-name="Path 49"
          d="M7.963 20.841h26.666m-13.333-8.889v26.666m0-26.666C19.321 7.755 15.222.989 11.663 4.545s4.448 7.407 9.633 7.407zm0 0c0-6.667 6.667-11.111 9.63-8.152 3.703 3.708-3.704 8.152-9.63 8.152z"
          stroke="#F37021"
          strokeLinecap="round"
          transform="translate(-3.5 -1.196)"
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
