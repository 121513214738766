import * as React from "react";
import Svg, { G, Path, Rect } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width={31} height={34} viewBox="0 0 31 34" {...props}>
      <G data-name="Group 58" transform="translate(-299 -260)">
        <G data-name="Rectangle 106" fill="#fff" stroke="#7a8390" strokeWidth={3}>
          <Path d="M0 0h11a3 3 0 013 3v16a3 3 0 01-3 3H0V0z" stroke="none" transform="translate(316 272)" />
          <Path d="M1.5 1.5H11A1.5 1.5 0 0112.5 3v16a1.5 1.5 0 01-1.5 1.5H1.5h0v-19h0z" fill="none" transform="translate(316 272)" />
        </G>
        <G data-name="Rectangle 103" transform="translate(299 260)" fill="#fff" stroke="#7a8390" strokeWidth={3}>
          <Rect width={24} height={34} rx={5} stroke="none" />
          <Rect x={1.5} y={1.5} width={21} height={31} rx={3.5} fill="none" />
        </G>
        <Rect data-name="Rectangle 104" width={16} height={3} rx={1.5} transform="translate(303 270)" fill="#F37021" />
        <Rect data-name="Rectangle 105" width={16} height={3} rx={1.5} transform="translate(303 281)" fill="#F37021" />
      </G>
    </Svg>
  );
}

export default SvgComponent;
