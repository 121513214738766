import * as React from "react"
const ListComponentSvg = () => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={64}
    height={64}
    fill="#d9d9d9"
    stroke="#d9d9d9"
    strokeWidth={0.001}
  >
    <g fill="#e6e6e6">
      <path d="M56 0H8C5.789 0 4 1.789 4 4v56c0 2.211 1.789 4 4 4h48c2.211 0 4-1.789 4-4V4c0-2.211-1.789-4-4-4zm2 60a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h48a2 2 0 0 1 2 2v56z" />
      <path d="M49 25H15a1 1 0 1 0 0 2h34a1 1 0 1 0 0-2zM49 19H15a1 1 0 1 0 0 2h34a1 1 0 1 0 0-2zM49 37H15a1 1 0 1 0 0 2h34a1 1 0 1 0 0-2zM49 43H15a1 1 0 1 0 0 2h34a1 1 0 1 0 0-2zM49 49H15a1 1 0 1 0 0 2h34a1 1 0 1 0 0-2zM49 31H15a1 1 0 1 0 0 2h34a1 1 0 1 0 0-2zM15 15h16a1 1 0 1 0 0-2H15a1 1 0 1 0 0 2z" />
    </g>
  </svg>
)
export default ListComponentSvg
