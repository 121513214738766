import { Formik, FormikProps } from "formik";
import React, {
  Dispatch,
  SetStateAction,
  memo,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { ScrollView, StyleSheet, TouchableOpacity, View } from "react-native";
import { ApModalAlt } from "../components/Modal";
import { ApButton } from "../components/v1";
import { ApSettingsInput } from "../components/v1/input";
import { ApText } from "../components/v1/typography";
import { theme } from "../constants";
import { useTradingContext } from "../context";
import { svg } from "../svg";
import { formatAmt } from "../utils";

interface IProps {
  setVisible?: Dispatch<SetStateAction<boolean>>;
  visible?: boolean;
  // marginSettings: any
  // setMarginSettings: any
  // set_entry_size: any
  // set_entry_price_diff: any
  // set_entry_trailing: any
}

const MarginSetting: React.FC<IProps> = memo(({ setVisible, visible }) => {
  const { i18n, t } = useTranslation();
  const formRef = useRef<FormikProps<any>>();
  const [initValues, setInitValues] = useState<any>();

  const {
    saveOriginal,
    entry_size,
    setMarginSettings,
    marginSettings,
    set_entry_size,
    set_entry_price_diff,
    set_entry_trailing,
    setReentryOrderErr,
  } = useTradingContext();

  useEffect(() => {
    let obj: any;
    marginSettings.map((item: any) => {
      obj = {
        ...obj,
        [item.m_name]: item.multiplier,
        [item.pd_name]:
          item.priceDifference !== null
            ? formatAmt(parseFloat(item.priceDifference) * 100)
            : item.priceDifference,
        [item.tr_name]:
          item.traillingReentry !== null
            ? formatAmt(parseFloat(item.traillingReentry) * 100)
            : item.traillingReentry,
      };
    });
    setInitValues(obj);
    //console.log(obj);
  }, []);
  //console.log(entry_size);

  const renderHeader = () => {
    return (
      <View
        style={{
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          height: 47,
          backgroundColor: "#FCF8F6",
        }}
      >
        <View
          style={{
            position: "absolute",
            left: 0,
            alignItems: "center",
          }}
        >
          <TouchableOpacity
            style={{
              paddingHorizontal: 20,
              paddingVertical: 12,
            }}
            onPress={() => setVisible(false)}
          >
            <svg.GoBackSvg goBackColor="#F37021" />
          </TouchableOpacity>
        </View>

        <ApText
          translateKey="MarginSettings"
          style={{
            textAlign: "center",
            fontSize: 16,
            // fontWeight: 500,
            color: "#F37021",
          }}
        >
          Margin Settings
        </ApText>
      </View>
    );
  };

  const handleSave = (val: any) => {
    let names = Object.keys(val);
    let values = Object.values(val);
    // console.log(values);

    // if(values.filter(item => item !== null && item !== '' && item !== undefined).length / 3 !== marginSettings.length)
    // {
    //   setReentryOrderErr(true)
    //   ApErrorToast('Error', 'Please fill all orders')
    // }
    // else
    // {
    const multipliers = names.filter((item) => item.includes("multiplier"));
    const priceDifferences = names.filter((item) =>
      item.includes("price_difference")
    );
    const traillingReentries = names.filter((item) =>
      item.includes("trailling_reentry")
    );

    setReentryOrderErr(false);
    let multiplierVal: any = [];
    let priceDifferenceVal: any = [];
    let traillingReentryVal: any = [];
    let n = marginSettings;

    n.forEach((item: any) => {
      if (multipliers.includes(item?.m_name)) {
        item.multiplier = val[`${item.m_name}`];
        //onsole.log(item.multiplier)
        multiplierVal = [...multiplierVal, item.multiplier];
      }

      if (priceDifferences.includes(item?.pd_name)) {
        if (val[`${item?.pd_name}`] !== null) {
          item.priceDifference = parseFloat(val[`${item?.pd_name}`]) / 100;
          item.priceDifference = item.priceDifference?.toString();
        } else {
          item.priceDifference = val[`${item?.pd_name}`];
        }

        priceDifferenceVal = [...priceDifferenceVal, item.priceDifference];
      }

      if (traillingReentries.includes(item?.tr_name)) {
        if (val[`${item?.tr_name}`] !== null) {
          item.traillingReentry = parseFloat(val[`${item?.tr_name}`]) / 100;
          item.traillingReentry = item.traillingReentry?.toString();
        } else {
          item.traillingReentry = val[`${item?.tr_name}`];
        }

        traillingReentryVal = [...traillingReentryVal, item.traillingReentry];
      }
    });

    setMarginSettings(n);
    set_entry_size(multiplierVal);
    set_entry_price_diff(priceDifferenceVal);
    set_entry_trailing(traillingReentryVal);

    saveOriginal({
      MarginSettings: n,
      entry_size: multiplierVal,
      entry_price_diff: priceDifferenceVal,
      entry_trailing: traillingReentryVal,
    });
    setVisible(false);
    //console.log(n)
    //navigation.goBack();
    // }
  };

  const renderContent = () => {
    return (
      <View style={{ flex: 1 }}>
        <View
          style={{
            margin: 13,
            // paddingRight: 5,
            borderRadius: 10,
            marginTop: 15,
            flex: 0.92,
          }}
        >
          {/* <View
            style={{
              flexDirection: "row",
              justifyContent: "flex-start",
              paddingBottom: 5,
              // alignItems: "center",
              width: "100%",
            }}
          >
            <View style={{ width: "20%" }}>
              <ApText
                translateKey="OrderList"
                style={{ color: "#959595", fontSize: 13, textAlign: "left" }}
              />
            </View>

            <View style={{ width: "27%" }}>
              <ApText
                translateKey="Multiplier"
                style={{
                  color: "#959595",
                  fontSize: 13,
                  textAlign: "left",
                  // marginLeft: -3,
                  //width: "25%",
                }}
              />
            </View>

            <View style={{ width: "27%", paddingLeft: 5 }}>
              <ApText
                translateKey="PriceDrop"
                style={{
                  color: "#959595",
                  fontSize: 13,
                  textAlign: "left",
                  // width: "25%",
                }}
              />
            </View>

            <View style={{ width: "25%" }}>
              <ApText
                translateKey="Trailing"
                style={{
                  color: "#959595",
                  fontSize: 13,
                  textAlign: "left",
                  //width: "25%",
                }}
              />
            </View>
          </View> */}

          {initValues ? (
            <Formik
              enableReinitialize
              innerRef={formRef as any}
              //validationSchema={FormSchema}
              initialValues={{
                ...initValues,
              }}
              onSubmit={(val) => handleSave(val)}
            >
              {(props: FormikProps<any>) => (
                <>
                  {!!marginSettings?.length ? (
                    marginSettings?.map((item: any, index: number) => (
                      <View
                        key={index}
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                          paddingVertical: 20,
                          paddingTop: index == 0 ? 0 : 20,
                          borderBottomColor: "#e6e6e6",
                          borderBottomWidth: 1,
                        }}
                      >
                        <View style={{}}>
                          {index == 0 && (
                            <ApText
                              translateKey="OrderList"
                              style={{
                                color: "#959595",
                                fontSize: 11,
                                marginBottom: 20,
                                alignSelf: "center",
                              }}
                            >
                              Order List
                            </ApText>
                          )}

                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              // width: "20%",
                              // alignItems: "center",
                              // justifyContent: "center",
                            }}
                          >
                            <ApText
                              style={{
                                color: "#F37021",
                                fontSize: 12,
                                // textAlign: "left",
                              }}
                            >
                              <ApText
                                translateKey="Order"
                                style={{
                                  color: "#F37021",
                                  fontSize: 12,
                                  textAlign: "left",
                                }}
                              />{" "}
                              #{parseInt(item?.orderList) + 1}
                            </ApText>
                          </View>
                        </View>

                        <View style={{}}>
                          {index == 0 && (
                            <ApText
                              translateKey="Multiplier"
                              style={{
                                color: "#959595",
                                fontSize: 11,
                                marginBottom: 20,
                                alignSelf: "center",
                              }}
                            />
                          )}

                          <View
                            style={
                              {
                                // flexDirection: "row",
                                // justifyContent: "center",
                                // alignItems: "center",
                                // width: "27%",
                              }
                            }
                          >
                            <ApSettingsInput
                              // width={0}
                              height={25}
                              rightIcon={
                                <ApText
                                  style={{
                                    color: "#F37021",
                                    fontSize: 14,
                                    marginLeft: 4,
                                    textTransform: "lowercase",
                                  }}
                                >
                                  X
                                </ApText>
                              }
                              defaultValue={item?.multiplier}
                              noBorder
                              // containerStyle={{marginLeft: -12}}
                              //labelRight="Volatility Price Percentage"
                              //labelStyle={style.inputLabel}
                              name={item?.m_name}
                              inputStyle={style.textInput}
                            />
                          </View>
                        </View>

                        <View style={{}}>
                          {index == 0 && (
                            <ApText
                              translateKey="PriceDrop"
                              style={{
                                color: "#959595",
                                fontSize: 11,
                                marginBottom: 20,
                                alignSelf: "center",
                              }}
                            />
                          )}

                          <View
                            style={
                              {
                                // flexDirection: "row",
                                // justifyContent: "center",
                                // alignItems: "center",
                                // width: "25%",
                              }
                            }
                          >
                            <ApSettingsInput
                              //width={110}
                              height={25}
                              rightIcon={
                                <ApText
                                  style={{
                                    color: "#F37021",
                                    fontSize: 14,
                                    marginLeft: 4,
                                  }}
                                >
                                  %
                                </ApText>
                              }
                              defaultValue={item?.priceDifference}
                              noBorder
                              //labelRight="Volatility Price Percentage"
                              //labelStyle={style.inputLabel}
                              name={item?.pd_name}
                              inputStyle={style.textInput}
                            />
                          </View>
                        </View>

                        <View style={{}}>
                          {index == 0 && (
                            <ApText
                              translateKey="Trailing"
                              style={{
                                color: "#959595",
                                fontSize: 11,
                                marginBottom: 20,
                                alignSelf: "center",
                              }}
                            />
                          )}

                          <View
                            style={
                              {
                                // flexDirection: "row",
                                // justifyContent: "center",
                                // alignItems: "center",
                                // width: "27%",
                                // paddingLeft: 2
                              }
                            }
                          >
                            <ApSettingsInput
                              // width={110}
                              height={25}
                              rightIcon={
                                <ApText
                                  style={{
                                    color: "#F37021",
                                    fontSize: 14,
                                    marginLeft: 4,
                                  }}
                                >
                                  %
                                </ApText>
                              }
                              defaultValue={item?.traillingReentry}
                              noBorder
                              //labelRight="Volatility Price Percentage"
                              //labelStyle={style.inputLabel}
                              name={item?.tr_name}
                              inputStyle={style.textInput}
                            />
                          </View>
                        </View>
                      </View>
                    ))
                  ) : (
                    <></>
                  )}

                  <View style={{ marginTop: 20 }}>
                    <ApButton
                      round="lg"
                      label="Save"
                      labelTranslateKey="Save"
                      onPress={() => props.handleSubmit()}
                    />
                  </View>
                </>
              )}
            </Formik>
          ) : (
            <></>
          )}
        </View>
      </View>
    );
  };

  return (
    <ApModalAlt fallbackRoute="EditStrategy" visible={visible}>
      <ScrollView style={{ flex: 1 }}>
        {renderHeader()}
        {renderContent()}
      </ScrollView>
    </ApModalAlt>
  );
});

export default MarginSetting;

const style = StyleSheet.create({
  inputLabel: {
    ...theme.FONTS.H6,
    color: theme.COLORS.mainDark,
    // width: "50%",
  },

  textInput: {
    backgroundColor: "#DFE3EA",
    width: 65,
    color: "#F37021",
    textAlign: "center",
    fontSize: 13,
    // padding: 3,
    borderRadius: 6,
  },
});
