import React, { useEffect, useRef, useState } from "react";
import { Dispatch, SetStateAction, memo } from "react";
import { ScrollView, TouchableOpacity, View } from "react-native";
import { ApModalAlt } from "../components/Modal";
import { ApText } from "../components/v1/typography";
import { svg } from "../svg";
import { Formik, FormikProps } from "formik";
import { useTradingContext } from "../context";
import { ApSettingsInput, ApButton } from "../components/v1";
import { StyleSheet } from "react-native";
import { theme } from "../constants";

interface IProps {
    setVisible?: Dispatch<SetStateAction<boolean>>;
    visible?: boolean;
}

const OrderSettings: React.FC<IProps> = memo(({ setVisible, visible }) => {
    const formRef = useRef<FormikProps<any>>();
    const [initValues, setInitValues] = useState<any>();
    const {
      setOrderSettingRows,
      setOrderSettings,
      orderSettingRows,
      orderSettings 
    } = useTradingContext()

    useEffect(() => {
      let obj: any;
      orderSettingRows.curr.map((item: any) => {
        obj = {
          ...obj,
          [item.set_sizes_1_name]: item.set_sizes_1,
          [item.set_sizes_2_name]: item.set_sizes_2,
          [item.set_return_name]: item.set_return,
        };
      });
      setInitValues(obj);
    }, []);

    const renderHeader = () => {
        return (
          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              height: 47,
              backgroundColor: "#FCF8F6",
            }}
          >
            <View
              style={{
                position: "absolute",
                left: 0,
                alignItems: "center",
              }}
            >
              <TouchableOpacity
                style={{
                  paddingHorizontal: 20,
                  paddingVertical: 12,
                }}
                onPress={() => setVisible(false)}
              >
                <svg.GoBackSvg goBackColor="#F37021" />
              </TouchableOpacity>
            </View>
    
            <ApText
              translateKey="OrderSettings"
              style={{
                textAlign: "center",
                fontSize: 16,
                // fontWeight: 500,
                color: "#F37021",
              }}
            />
          </View>
        );
    };

    const handleSave = (val: any) => {
      let names = Object.keys(val);

      const set_return_arr = names.filter((item) => item.includes("set_return"));
      const set_sizes_1_arr = names.filter((item) =>
        item.includes("set_sizes_1")
      );
      const set_sizes_2_arr = names.filter((item) =>
        item.includes("set_sizes_2")
      );
  
      let set_return_val: any = [];
      let set_sizes_1_val: any = [];
      let set_sizes_2_val: any = [];
      let n = orderSettingRows;
  
      n.curr.forEach((item: any) => {
        if (set_return_arr.includes(item?.set_return_name)) {
          item.set_return = val[`${item.set_return_name}`];
          set_return_val = [...set_return_val, item.set_return];
        }

        if (set_sizes_1_arr.includes(item?.set_sizes_1_name)) {
          item.set_sizes_1 = val[`${item.set_sizes_1_name}`];
          set_sizes_1_val = [...set_sizes_1_val, item.set_sizes_1];
        }

        if (set_sizes_2_arr.includes(item?.set_sizes_2_name)) {
          item.set_sizes_2 = val[`${item.set_sizes_2_name}`];
          set_sizes_2_val = [...set_sizes_2_val, item.set_sizes_2];
        }
      });

      setOrderSettingRows({prev: n.curr, curr: n.curr})
      setOrderSettings({
        prev: {
          set_return: set_return_val,
          set_sizes_1: set_sizes_1_val,
          set_sizes_2: set_sizes_2_val
        },
        curr: {
          set_return: set_return_val,
          set_sizes_1: set_sizes_1_val,
          set_sizes_2: set_sizes_2_val
        }
      })
      setVisible(false);
    };

    const renderContent = () => {
      return (
        <View style={{ flex: 1 }}>
          <View
            style={{
              margin: 13,
              borderRadius: 10,
              marginTop: 15,
              flex: 0.92,
            }}
          >  
            {initValues ? (
              <Formik
                enableReinitialize
                innerRef={formRef as any}
                initialValues={{
                  ...initValues,
                }}
                onSubmit={handleSave}
              >
                {(props: FormikProps<any>) => (
                  <>
                    {!!orderSettingRows?.curr.length ? (
                      orderSettingRows?.curr.map((item: any, index: number) => (
                        <View
                          key={index}
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                            paddingVertical: 20,
                            paddingTop: index == 0 ? 0 : 20,
                            borderBottomColor: "#e6e6e6",
                            borderBottomWidth: 1,
                          }}
                        >
                          <View style={{}}>
                            {index == 0 && (
                              <ApText
                                translateKey="OrderList"
                                style={{
                                  color: "#959595",
                                  fontSize: 11,
                                  marginBottom: 20,
                                  alignSelf: "center",
                                }}
                              >
                                Order List
                              </ApText>
                            )}
  
                            <View
                              style={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <ApText
                                style={{
                                  color: "#F37021",
                                  fontSize: 12,
                                }}
                              >
                                <ApText
                                  translateKey="Order"
                                  style={{
                                    color: "#F37021",
                                    fontSize: 12,
                                    textAlign: "left",
                                  }}
                                />{" "}
                                #{parseInt(item?.orderList)}
                              </ApText>
                            </View>
                          </View>
  
                          <View style={{}}>
                            {index == 0 && (
                              <ApText
                                translateKey="Size"
                                style={{
                                  color: "#959595",
                                  fontSize: 11,
                                  marginBottom: 20,
                                  alignSelf: "center",
                                }}
                              />
                            )}
  
                            <View>
                              <ApSettingsInput
                                height={25}
                                // rightIcon={
                                //   <ApText
                                //     style={{
                                //       color: "#F37021",
                                //       fontSize: 14,
                                //       marginLeft: 4,
                                //       textTransform: "lowercase",
                                //     }}
                                //   >
                                //     X
                                //   </ApText>
                                // }
                                defaultValue={item?.set_sizes_1}
                                noBorder
                                name={item?.set_sizes_1_name}
                                inputStyle={style.textInput}
                              />
                            </View>
                          </View>
  
                          <View style={{}}>
                            {index == 0 && (
                              <ApText
                                translateKey="MultiSize"
                                style={{
                                  color: "#959595",
                                  fontSize: 11,
                                  marginBottom: 20,
                                  alignSelf: "center",
                                }}
                              />
                            )}
  
                            <View>
                              <ApSettingsInput
                                height={25}
                                // rightIcon={
                                //   <ApText
                                //     style={{
                                //       color: "#F37021",
                                //       fontSize: 14,
                                //       marginLeft: 4,
                                //     }}
                                //   >
                                //     %
                                //   </ApText>
                                // }
                                defaultValue={item?.set_sizes_2}
                                noBorder
                                name={item?.set_sizes_2_name}
                                inputStyle={style.textInput}
                              />
                            </View>
                          </View>
  
                          <View style={{}}>
                            {index == 0 && (
                              <ApText
                                translateKey="Return"
                                style={{
                                  color: "#959595",
                                  fontSize: 11,
                                  marginBottom: 20,
                                  alignSelf: "center",
                                }}
                              />
                            )}
  
                            <View>
                              <ApSettingsInput
                                height={25}
                                // rightIcon={
                                //   <ApText
                                //     style={{
                                //       color: "#F37021",
                                //       fontSize: 14,
                                //       marginLeft: 4,
                                //     }}
                                //   >
                                //     %
                                //   </ApText>
                                // }
                                defaultValue={item?.set_return}
                                noBorder
                                name={item?.set_return_name}
                                inputStyle={style.textInput}
                              />
                            </View>
                          </View>
                        </View>
                      ))
                    ) : (
                      <></>
                    )}
  
                    <View style={{ marginTop: 20 }}>
                      <ApButton
                        round="lg"
                        label="Save"
                        labelTranslateKey="Save"
                        onPress={() => props.handleSubmit()}
                      />
                    </View>
                  </>
                )}
              </Formik>
            ) : (
              <></>
            )}
          </View>
        </View>
      );
    };
    
    return (
        <ApModalAlt fallbackRoute="EditStrategy" visible={visible}>
            <ScrollView style={{ flex: 1 }}>
                {renderHeader()}
                {renderContent()}
            </ScrollView>
        </ApModalAlt>
    );
})

export default OrderSettings

const style = StyleSheet.create({
  inputLabel: {
    ...theme.FONTS.H6,
    color: theme.COLORS.mainDark,
    // width: "50%",
  },

  textInput: {
    backgroundColor: "#DFE3EA",
    width: 65,
    color: "#F37021",
    textAlign: "center",
    fontSize: 13,
    // padding: 3,
    borderRadius: 6,
  },
});
