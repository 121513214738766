import React from "react";
import { useTranslation } from "react-i18next";
import { StyleProp, StyleSheet, Text, TextStyle } from "react-native";
interface IProps {
  children?: React.ReactNode;
  style?: StyleProp<TextStyle>;
  color?: string;
  font?: "thin" | "light" | "normal" | "medium" | "bold" | "extrabold";
  size?: "xs" | "sm" | "base" | "lg" | "xl" | "2xl" | "3xl"; //;
  numberOfLines?: number;
  translateKey?: string;
}

export const ApText: React.FC<IProps> = ({
  children,
  style,
  color,
  translateKey,
  size = "base",
  font = "normal",
  numberOfLines,
}) => {
  const { t } = useTranslation();

  return (
    <Text
      numberOfLines={numberOfLines}
      style={[
        styles.text,
        styles[`size_${size}`],
        styles[`font_${font}`],
        { color },
        style,
      ]}
    >
      {translateKey ? t(translateKey) : children}
    </Text>
  );
};

const styles = StyleSheet.create({
  text: {
    fontFamily: "Mulish_400Regular",
  },
  size_xs: {
    fontSize: 12,
    lineHeight: 16,
  },
  size_sm: {
    fontSize: 14,
    lineHeight: 20,
  },
  size_base: {
    fontSize: 16,
    lineHeight: 24,
  },
  size_lg: {
    fontSize: 18,
    lineHeight: 28,
  },
  size_xl: {
    fontSize: 20,
    lineHeight: 28,
  },
  size_2xl: {
    fontSize: 24,
    lineHeight: 32,
  },
  size_3xl: {
    fontSize: 30,
    lineHeight: 36,
  },
  font_thin: {
    fontWeight: "100",
  },
  font_light: {
    fontWeight: "300",
    fontFamily: "Mulish_300Light",
  },
  font_normal: {
    fontWeight: "400",
    fontFamily: "Mulish_400Regular",
  },
  font_medium: {
    fontWeight: "500",
    fontFamily: "Mulish_500Medium",
  },
  font_bold: {
    fontWeight: "700",
    fontFamily: "Mulish_700Bold",
  },
  font_extrabold: {
    fontWeight: "800",
  },
});

// font-normal	font-weight: 400;
// font-medium	font-weight: 500;
// font-semibold	font-weight: 600;
// font-bold	font-weight: 700;
// font-extrabold	font-weight: 800;
// font-black	font-weight: 900;
