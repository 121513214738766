import * as React from "react";
import Svg, { Path } from "react-native-svg";

function ProfileSvg(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width={19.212} height={22} viewBox="0 0 19.212 22" {...props}>
      <Path
        data-name="Path 14889"
        d="M12.912 10.364a5.081 5.081 0 002.249-4.167 5.567 5.567 0 00-11.11 0 5.083 5.083 0 002.248 4.167 8.066 8.066 0 00-5.3 7.433v3.2h17.213v-3.2a8.066 8.066 0 00-5.3-7.433z"
        fill="none"
        stroke="#414960"
        strokeWidth={2}
      />
    </Svg>
  );
}

export default ProfileSvg;
