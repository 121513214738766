import React, { useEffect, useState } from "react";
import {
  Dimensions,
  Image,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";
import { svg } from "../svg";
// import Swiper from "react-native-swiper";
import { components } from "../components";
import { ApModalAlt } from "../components/Modal";
import {
  ApButton,
  ApEmptyState,
  ApEmptyStateSvg,
  ApLoader,
  ApRow,
  ApSafeAreaView,
} from "../components/v1";
import { ApText } from "../components/v1/typography";
import { theme } from "../constants";
import { useTradingContext } from "../context";
import { formatAmt, toCurrency } from "../utils";
import { COLORS } from "../constants/theme";

const { width } = Dimensions.get("window");
const styles = StyleSheet.create({
  paginationStyle: {
    backgroundColor: "rgba(0,0,0,.2)",
  },
  container: { flex: 1, backgroundColor: "white" },
  child: { width, justifyContent: "center" },
  text: { fontSize: width * 0.5, textAlign: "center" },
});

const BotActionLog: React.FC = ({ navigation, route }: any) => {
  const bex_id = route?.params?.bex_id;
  const copy_trade_id = route?.params?.copy_trade_id;
  const provider_id = route?.params?.provider_id;

  const [showModal, setShowModal] = useState<{type?: "close" | "delete", open: boolean}>({
    open: false,
  });
  const [payload, setPayload] = useState<any>();
  const { loading, botOrderLogs, closeOrder, deleteOrder, getBotOrderLogs } =
    useTradingContext();
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [loadingMoreRecords, setLoadingMoreRecords] = useState(false)

  useEffect(() => {
    getBotOrderLogs(bex_id, '30', 1, copy_trade_id, false, provider_id);
  }, []);

  useEffect(() => {
    if (page == 1) {
      setData(botOrderLogs);
    } else {
      setData((prevData) => [...prevData, ...botOrderLogs]);
    }
  }, [botOrderLogs]);

  const loadMore = async () => {
    setLoadingMoreRecords(true)
    const nextPage = page + 1;
    getBotOrderLogs(bex_id, '30', nextPage, copy_trade_id, true, provider_id)
    .finally(() => setLoadingMoreRecords(false))
    setPage(nextPage);
  };

  const renderHeader = () => {
    return (
      <components.Header
        title="Bot Order"
        goBack={true}
        titleTranlateKey="BotOrder"
      />
    );
  };
  

  const renderContent = () => {
    return (
      <View
        style={{
          paddingHorizontal: 15,
          borderRadius: 10,
          paddingVertical: 15,
        }}
      >
        {loading ? (
          <ApLoader />
        ) : (
          <>
            {data.length > 0 ? (
              <>
                <ApText
                  style={{
                    fontSize: 16,
                    fontWeight: "500",
                  }}
                >
                  <ApText style={{ color: "#F37021" }}>
                    {data[0]?.symbol}
                  </ApText>
                  <ApText style={{ fontSize: 15, color: "#222" }}></ApText>
                </ApText>

                <View
                  style={{
                    backgroundColor: "#FFF8F4",
                    borderRadius: 10,
                    marginTop: 15,
                    padding: 15,
                    paddingTop: 0,
                  }}
                >
                  {data.map((e, index) => (
                    <ScrollView>
                      <View
                        style={{
                          borderBottomColor: "#6b6b6b7d",
                          borderBottomWidth: index + 1 === data.length ? 0 : 1,
                          paddingVertical: 15,
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                            paddingBottom: e?.err_msg ? 0 : 5,
                          }}
                        >
                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <ApText
                              style={{
                                fontSize: 12,
                                fontWeight: "500",
                                marginRight: 10,
                              }}
                            >
                              {e?.created_at}
                            </ApText>

                            <ApText style={{ fontSize: 12, fontWeight: "500" }}>
                              {e?.label}
                            </ApText>
                          </View>

                          <View
                            style={{
                              // width: "20%",
                              marginLeft: "auto",
                              alignSelf: "center",
                              alignItems: "flex-end",
                            }}
                          > 
                            <ApRow>
                              {e?.close_btn === "1" && (
                                <TouchableOpacity
                                  onPress={() => {
                                    setPayload({ bepo_id: e?.id });
                                    setShowModal({open: true, type: "close"});
                                  }}
                                >
                                  <ApText
                                    style={{ color: "#F37021", fontSize: 12 }}
                                    translateKey="Close"
                                  />
                                </TouchableOpacity>
                              )}

                              {e?.delete_btn === "1" && (
                                <ApRow>
                                  {e?.close_btn === "1" && <ApText style={{ color: "#F37021", fontSize: 12, paddingHorizontal: 5 }}>|</ApText>}
                                  <TouchableOpacity
                                    onPress={() => {
                                      setPayload({ bepo_id: e?.id });
                                      setShowModal({open: true, type: "delete"});
                                    }}
                                  >
                                    <ApText
                                      style={{ color: "#F37021", fontSize: 12 }}
                                      translateKey="Delete"
                                    />
                                  </TouchableOpacity>
                                </ApRow>
                              )}
                            </ApRow>
                          </View>
                        </View>

                        {e?.err_msg && (
                          <View
                            style={{
                              marginBottom: 2,
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <Image
                              source={{
                                uri: require("../../assets/error.png"),
                              }}
                              style={{ marginRight: 5, height: 18, width: 18 }}
                            />
                            <ApText style={{ color: "red", fontSize: 12 }}>
                              {e?.err_msg}
                            </ApText>
                          </View>
                        )}

                        <View
                          style={{
                            paddingTop: 5,
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <View>
                            <View>
                              <ApText
                                style={{ color: "#6b6b6b", fontSize: 13 }}
                                translateKey="Number"
                              />
                              <ApText
                                style={{
                                  color: "#222",
                                  fontSize: 13,
                                  fontWeight: "500",
                                  paddingTop: 2,
                                }}
                              >
                                {e?.entry_count}
                              </ApText>
                            </View>

                            <View style={{ marginTop: 5 }}>
                              <ApText
                                style={{ color: "#6b6b6b", fontSize: 13 }}
                                translateKey="Quantity"
                              />
                              <ApText
                                style={{
                                  color: "#222",
                                  fontSize: 13,
                                  fontWeight: "500",
                                  paddingTop: 2,
                                }}
                              >
                                {e?.qty_filled}
                              </ApText>
                            </View>
                          </View>

                          <View>
                            <View>
                              <ApText
                                style={{ color: "#6b6b6b", fontSize: 13 }}
                                translateKey="AvgPrice"
                              />

                              <ApText
                                style={{
                                  color: "#222",
                                  fontSize: 13,
                                  fontWeight: "500",
                                  paddingTop: 2,
                                }}
                              >
                                {formatAmt(+e?.price_avg)} USDT
                              </ApText>
                            </View>

                            <View style={{ marginTop: 5 }}>
                              <ApText
                                style={{ color: "#6b6b6b", fontSize: 13 }}
                                translateKey="USDT"
                              >
                                USDT
                              </ApText>
                              <ApText
                                style={{
                                  color: "#222",
                                  fontSize: 13,
                                  fontWeight: "500",
                                  paddingTop: 2,
                                }}
                              >
                                {toCurrency(
                                  Math.abs(parseFloat(e?.usdt_filled)),
                                  ""
                                )}
                              </ApText>
                            </View>
                          </View>
                          <View>
                            <View>
                              <ApText
                                style={{ color: "#6b6b6b", fontSize: 13 }}
                                translateKey="P&L"
                              />
                              <ApText
                                style={{
                                  color: e?.pnl?.includes('-') ? COLORS.red : e?.pnl == "0.0000" ? COLORS.primary : COLORS.green,
                                  fontSize: 13,
                                  fontWeight: "500",
                                  paddingTop: 2,
                                }}
                              >
                                {e?.pnl}
                              </ApText>
                            </View>

                            <View style={{ marginTop: 5 }}>
                              <ApText
                                style={{ color: "#6b6b6b", fontSize: 13 }}
                                translateKey="Reference"
                              />
                              <ApText
                                style={{
                                  color: "#222",
                                  fontSize: 13,
                                  fontWeight: "500",
                                  paddingTop: 2,
                                }}
                              >
                                {e?.platform_ref}
                              </ApText>
                            </View>
                          </View>
                        </View>
                      </View>
                    </ScrollView>
                  ))}
                </View>
                {data?.length >= 30 && (
                  <ApButton
                    onPress={() => loadMore()}
                    loading={loadingMoreRecords}
                    disabled={loadingMoreRecords}
                    labelTranslateKey="ShowAllUpdates"
                    round="lg"
                    style={{marginTop: 20}}
                  />
                )}
              </>
            ) : (
              <ApEmptyState svg={<ApEmptyStateSvg.List />} />
            )}
          </>
        )}
      </View>
    );
  };

  return (
    <ApSafeAreaView>
      <ScrollView
        contentContainerStyle={{
          flexGrow: 1,
        }}
        showsVerticalScrollIndicator={false}
        bounces={false}
      >
        {renderHeader()}
        {renderContent()}

        {showModal.open && (
          <ApModalAlt transparent={true} visible={showModal.open}>
            <>
              <TouchableOpacity
                onPress={() => {
                  setShowModal({open: false});
                }}
                style={{
                  alignItems: "flex-end",
                  padding: 15,
                  paddingBottom: 0,
                }}
              >
                <svg.closeSvg />
              </TouchableOpacity>

              <View
                style={{
                  paddingTop: 0,
                  paddingVertical: 20,
                  alignItems: "center",
                  paddingHorizontal: 20,
                }}
              >
                <ApText
                  size="lg"
                  style={{
                    marginTop: -5,
                    color: "#F37021",
                    fontWeight: "bold",
                    marginBottom: 5,
                  }}
                  translateKey="Notice"
                />

                {showModal.type == "close" ? (
                  <ApText
                    size="sm"
                    style={{ textAlign: "center" }}
                    translateKey="ConfirmCloseOrder"
                  />
                ) : (
                  <ApText
                    size="sm"
                    style={{ textAlign: "center" }}
                    translateKey="ConfirmDeleteOrder"
                  />
                )} 

                {showModal.type == "delete" && (
                  <ApText
                    size="sm"
                    style={{ textAlign: "center", marginTop: 5 }}
                    translateKey="ConfirmDeleteOrderDesc"
                  />
                )}

                <View
                  style={{
                    width: "100%",
                    marginTop: 30,
                    flexDirection: "row",
                    //paddingVertical: 20,
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <TouchableOpacity
                    style={{
                      marginRight: 10,
                      borderWidth: 1,
                      borderColor: "#F37021",
                      width: "47%",
                      height: 38,
                      borderRadius: 5,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onPress={() => {
                      setShowModal({open: false});
                    }}
                  >
                    <ApText
                      style={{ color: "#F37021", fontWeight: "500" }}
                      translateKey="Cancel"
                    />
                  </TouchableOpacity>

                  <TouchableOpacity
                    style={{
                      backgroundColor: "#F37021",
                      width: "47%",
                      height: 38,
                      borderRadius: 5,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onPress={() => {
                      showModal.type == "close" ? closeOrder(payload) : deleteOrder(payload)
                      setShowModal({open: false});
                    }}
                  >
                    <ApText
                      style={{ color: theme.COLORS.white, fontWeight: "500" }}
                      translateKey="Confirm"
                    />
                  </TouchableOpacity>
                </View>
              </View>
            </>
          </ApModalAlt>
        )}
      </ScrollView>
    </ApSafeAreaView>
  );
};

export default BotActionLog;
