import * as React from "react";
import Svg, { Path } from "react-native-svg";

const CheckTwo: React.FC = () => (
  <Svg width={12} height={8} fill="none">
    <Path d="M12.333 1 5 8.333 1.667 5" stroke="#fff" strokeWidth={1.3} strokeLinecap="round" strokeLinejoin="round" />
  </Svg>
);

export default CheckTwo;
