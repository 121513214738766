import * as React from "react";
import Svg, { G, Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" style={{marginLeft: -2}} width={20} height={20} viewBox="0 0 19.25 19.25" {...props}>
      <G data-name="Group 128">
        <G data-name="Group 127">
          <Path
            data-name="Path 85"
            d="M23.141 12.983c-.469-1.523-2.567-2.8-5.449-3.425-.629-2.882-1.9-4.98-3.425-5.449V4h-1.284v.109c-1.523.469-2.8 2.567-3.425 5.449-2.882.629-4.98 1.9-5.449 3.425H4v1.283h.109c.469 1.523 2.567 2.8 5.449 3.425.629 2.882 1.9 4.98 3.425 5.449v.109h1.283v-.109c1.523-.469 2.8-2.567 3.425-5.449 2.882-.629 4.98-1.9 5.449-3.425h.109v-1.283zM5.52 14.267h3.628a20.137 20.137 0 00.167 2.053 6.774 6.774 0 01-3.795-2.053zm3.628-1.283H5.52a6.774 6.774 0 013.795-2.054 20.092 20.092 0 00-.167 2.053zm3.835 8.746a6.774 6.774 0 01-2.053-3.795 20.138 20.138 0 002.053.167zm0-4.911a17.876 17.876 0 01-2.33-.222 17.906 17.906 0 01-.222-2.33h2.552zm0-3.835h-2.551a17.876 17.876 0 01.222-2.33 17.906 17.906 0 012.33-.222zm0-3.835a20.137 20.137 0 00-2.053.167 6.774 6.774 0 012.053-3.796zm1.284-3.629a6.774 6.774 0 012.053 3.795 20.138 20.138 0 00-2.053-.167zm0 16.209V18.1a20.137 20.137 0 002.053-.167 6.774 6.774 0 01-2.053 3.797zM16.6 16.6a17.906 17.906 0 01-2.33.222v-2.555h2.552A17.876 17.876 0 0116.6 16.6zm-2.33-3.613v-2.555a17.876 17.876 0 012.33.222 17.906 17.906 0 01.222 2.33zm3.668 3.336a20.137 20.137 0 00.167-2.053h3.625a6.774 6.774 0 01-3.795 2.05zm.167-3.336a20.137 20.137 0 00-.167-2.053 6.773 6.773 0 013.795 2.053z"
            transform="translate(-4 -4) translate(4 4) translate(-4 -4)"
            fill="#F37021"
          />
        </G>
      </G>
      <Path data-name="Path 86" d="M13.625 5.283a8.342 8.342 0 11-8.342 8.342 8.351 8.351 0 018.342-8.342m0-1.283a9.625 9.625 0 109.625 9.625A9.626 9.626 0 0013.625 4z" fill="#7a8390" transform="translate(-4 -4)" />
    </Svg>
  );
}

export default SvgComponent;
