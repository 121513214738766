import { useTranslation } from "react-i18next";
import { MasterData } from "../constants/master";
import { useEffect, useState } from "react";
import { IBotExecutor, IPriceListParams } from "../models";
import { useConfigState, useTradingContext } from "../context";

export const useMasterData = () => {
  const { t } = useTranslation();

  const [mData, setData] = useState<any>();

  useEffect(() => {
    setData({
      BB_TYPES: MasterData.BB_TYPES.map((d) => {
        return {
          ...d,
          label: t(d.label),
        };
      }),
      MACD_TYPES: MasterData.MACD_TYPES.map((d) => {
        return {
          ...d,
          label: t(`MasterData.MACD_TYPES.${d.label}`),
        };
      }),
      TAKE_PROFIT_MODE: MasterData.TAKE_PROFIT_MODE.map((d) => {
        return {
          ...d,
          label: t(`MasterData.TAKE_PROFIT_MODE.${d.label}`),
        };
      }),
      INTERVALS: MasterData.INTERVALS.map((d) => {
        return {
          ...d,
          label: t(`MasterData.INTERVALS.${d.label}`),
        };
      }),
      INTERVAL_TYPES: MasterData.INTERVAL_TYPES.map((d) => {
        return {
          ...d,
          label: t(`MasterData.INTERVAL_TYPES.${d.label}`),
        };
      }),
      INTERVAL_FREQUENCY_TYPES: MasterData.INTERVAL_FREQUENCY_TYPES.map((d) => {
        return {
          ...d,
          label: t(`MasterData.INTERVAL_FREQUENCY_TYPES.${d.label}`),
        };
      }),
      DIRECTION_TYPES: MasterData.DIRECTION_TYPES.map((d) => {
        return { ...d, label: t(`MasterData.DIRECTION_TYPES.${d.label}`) };
      }),
      PT_ONE_DIRECTION_TYPES: MasterData.PT_ONE_DIRECTION_TYPES.map((d) => {
        return { ...d, label: t(`MasterData.PT_ONE_DIRECTION_TYPES.${d.label}`) };
      }),
      SPOT_DIRECTION_TYPES: MasterData.SPOT_DIRECTION_TYPES.map((d) => {
        return { ...d, label: t(`MasterData.SPOT_DIRECTION_TYPES.${d.label}`) };
      }),
      PLATFORMS: MasterData.PLATFORMS.map((d) => {
        return { ...d, label: t(d.label) };
      }),
      RSI_TYPES: MasterData.RSI_TYPES.map((d) => {
        return {
          ...d,
          label: t(`MasterData.RSI_TYPES.${d.label}`),
        };
      }),
      EMA_TYPES: MasterData.EMA_TYPES.map((d) => {
        return {
          ...d,
          label: t(`MasterData.EMA_TYPES.${d.label}`),
        };
      }),
      KDJ_TYPES: MasterData.KDJ_TYPES.map((d) => {
        return {
          ...d,
          label: t(`MasterData.KDJ_TYPES.${d.label}`),
        };
      }),
    });
  }, []);

  return {
    MasterData: mData,
  };
};

export const useCalcPnl = () => {
  const { priceList } = useConfigState();

  const calcPNLAmount = (bex: IBotExecutor) => {
    if (+bex?.bep?.pos_size == 0) return "0.00";

    const bnTicker = priceList?.find((item) => item?.symbol === bex?.symbol);

    if (bnTicker) {
      const marketPrice = parseFloat(bnTicker?.lastPrice);
      const pnlAmount = (
        Math.abs(parseFloat(bex?.bep?.pos_size)) *
        (marketPrice - parseFloat(bex?.bep?.pos_price)) *
        parseInt(bex?.pos_side)
      ).toFixed(2);

      return pnlAmount;
    }

    return "0.00";
  };

  const calcPNLPerc = (bex: IBotExecutor) => {
    if (+bex?.bep?.pos_size == 0) return "0.00";

    const bnTicker = priceList?.find((item) => item?.symbol === bex?.symbol);

    if (bnTicker) {
      const marketPrice = parseFloat(bnTicker?.lastPrice);
      const pnlPerc = (
        ((100 * (marketPrice - parseFloat(bex?.bep?.pos_price))) /
          parseFloat(bex?.bep?.pos_price)) *
        parseInt(bex?.pos_side)
      ).toFixed(2);

      return pnlPerc;
    }

    return "0.00";
  };

  return {
    calcPNLAmount,
    calcPNLPerc,
  };
};
