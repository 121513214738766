import { useNavigation } from "@react-navigation/native";
import React, { useState } from "react";
import { IConvert, ICreateMembership, IStake } from "../models/business.interface";
import { ApSuccessToast, ApiService, ApErrorToast } from "../services";

interface IBusinessState {
  loading: boolean
  convertCashToCredit: (payload: IConvert) => Promise<string>;
  convertCommToCash: (payload: IConvert) => Promise<string>;
  convertDoliToBonus: (payload: IConvert) => Promise<string>;
  createMembership: (payload: ICreateMembership) => Promise<void>;
  stakeDDCoin: (payload: IStake) => Promise<void>
  unStakeDDCoin: (payload: IStake) => Promise<void>
}

const BusinessContext = React.createContext<IBusinessState>({
  loading: false,
  convertCashToCredit(payload: IConvert) {
    return null;
  },
  convertCommToCash(payload: IConvert) {
    return null;
  },
  convertDoliToBonus(payload: IConvert) {
    return null;
  },
  stakeDDCoin(payload: IStake){
    return null;
  },
  unStakeDDCoin(){}
} as unknown as IBusinessState);

const useBusinessState = () => {
  const context = React.useContext(BusinessContext);
  if (context === undefined) {
    throw new Error("app dispatch must be used within app global provider");
  }
  return context;
};

interface IProps {
  children: React.ReactNode;
}

const BusinessContextProvider: React.FC<IProps> = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false)
  const navigation: any = useNavigation()

  const convertCashToCredit = async (payload: IConvert) => {
    setLoading(true)
    return ApiService.post("/business/convertCashToCredit", payload)
    .then(rs => {
      ApSuccessToast("Success", rs.data?.msg)
      navigation.navigate("ConverHistory");
      return rs?.data?.msg
   })
   .catch(err => {ApErrorToast('Error', err); throw err})
   .finally(() => setLoading(false))
  };

  const convertDoliToBonus = async (payload: IConvert) => {
    setLoading(true)
    return ApiService.post("/business/convertDoliToBonus", payload)
    .then(rs => {
      ApSuccessToast("Success", rs.data?.msg)
      navigation.navigate("ConverHistory");
      return rs?.data?.msg
   })
   .catch(err => {ApErrorToast('Error', err); throw err})
   .finally(() => setLoading(false))
  };

  const convertCommToCash = async (payload: IConvert) => {
    setLoading(true)
    return ApiService.post("/business/convertCommToCash", payload)
    .then(rs => {
      ApSuccessToast("Success", rs.data?.msg)
      navigation.navigate("ConverHistory");
      return rs?.data?.msg
   })
   .catch(err => {ApErrorToast('Error', err); throw err})
   .finally(() => setLoading(false))
  };

  const stakeDDCoin = async (payload: IStake) => {
    setLoading(true)
     return ApiService.post("/business/stakeDdb", payload)
     .then(rs => {
        ApSuccessToast("Success", rs.data?.msg)
        navigation.navigate("ConverHistory");
        return rs?.data?.msg
     })
     .catch(err => {ApErrorToast('Error', err); throw err})
     .finally(() => setLoading(false))
  }

  const unStakeDDCoin = async (payload: IStake) => {
    setLoading(true)
     return ApiService.post("/business/unstakeDdb", payload)
     .then(rs => {
        ApSuccessToast("Success", rs.data?.msg)
        navigation.navigate("ConverHistory");
        return rs?.data?.msg
     })
     .catch(err => {ApErrorToast('Error', err); throw err})
     .finally(() => setLoading(false))
  } 

  const createMembership = async (
    payload: ICreateMembership
  ): Promise<void> => {
    const rs = await ApiService.post(
      "/business/createMembershipSubscription",
      payload
    );
    if (rs.data.status === "1") {
      ApSuccessToast("Membership", rs?.data?.msg);
    } else {
      throw new Error(rs?.data?.msg);
    }
  };

  return (
    <BusinessContext.Provider
      value={{
        loading,
        stakeDDCoin,
        unStakeDDCoin,
        convertCashToCredit,
        convertCommToCash,
        convertDoliToBonus,
        createMembership,
      }}
    >
      {children}
    </BusinessContext.Provider>
  );
};

export { BusinessContextProvider, useBusinessState };
